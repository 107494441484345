import axios from "axios";
import { useDispatch } from "react-redux";
import InventoryAddComponent from "./components/inventoryAddComponent";

const createInventoryApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/inventory/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};
const fetchUploads = async (imageId) => {
    const { data } = await axios.get(`/api/uploads/image/${imageId}`, { withCredentials: true });
    return data
}
const fetchLocation = async (abctrl) => {
    const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
    return data
}
const fetchMake = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleMake/", { withCredentials: true })
    return data;
}
const fetchModel = async (make) => {
    const { data } = await axios.get(`/api/vehicleModel?make=${make}`, { withCredentials: true })
    return data;
}
const fetchYear = async (model) => {
    const { data } = await axios.get(`/api/vehicleYear?model=${model}`, { withCredentials: true });
    return data
}
const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/admin", { withCredentials: true });
    return data
}
const fetchUsers = async (abctrl) => {
    const { data } = await axios.get("/api/users", { withCredentials: true });
    return data
}

const InventoryAddPage = () => {
    const dispatch = useDispatch();

    return (
        <InventoryAddComponent
            fetchUsers={fetchUsers }
            fetchTechInfo={fetchTechInfo }
            fetchLocation={fetchLocation }
            fetchUploads={fetchUploads }
            fetchMake={fetchMake}
            fetchModel={fetchModel}
            fetchYear={fetchYear }
            createInventoryApiRequest={createInventoryApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default InventoryAddPage;