import axios from "axios";
import { useDispatch } from "react-redux";
import CreateJobTagPageComponent from "./components/newJobTagComponent";

const createJobTagApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/jobTag/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const CreateJobTagPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateJobTagPageComponent
            createJobTagApiRequest={createJobTagApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default CreateJobTagPage;