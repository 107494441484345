import axios from "axios";
import AdminJobTaxComponent from "./components/adminJobTaxComponent";

const fetchJobTax = async (abctrl) => {
    const { data } = await axios.get("/api/jobTax/dispatch", { withCredentials: true });
    return data
}

const deleteJobTax = async (jobTaxId) => {
    const { data } = await axios.delete(`/api/jobTax/dispatch/${jobTaxId}`, { withCredentials: true });
    return data
}
const fetchCompany = async (abctrl) => {
    const { data } = await axios.get("/api/company/admin", { withCredentials: true });
    return data
}

const AdminTaxPage = () => {

    return <AdminJobTaxComponent fetchCompany={fetchCompany} fetchJobTax={fetchJobTax} deleteJobTax={deleteJobTax} />;
};

export default AdminTaxPage;
