import axios from "axios";
import { useDispatch } from "react-redux";
import CreateJobTypePageComponent from "./components/newJobTypeComponent";

const createJobTypeApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/jobType/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const CreateJobTypePage = () => {
    const dispatch = useDispatch();

    return (
        <CreateJobTypePageComponent
            createJobTypeApiRequest={createJobTypeApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default CreateJobTypePage;