import axios from 'axios'
import { useDispatch } from "react-redux";
import AdminEditDiscountCodesComponent from './components/adminEditDiscountCodesComponent';

const fetchCodes = async (codesId) => {
    const { data } = await axios.get(`/api/discountCodes/admin/${codesId}`, { withCredentials: true });
    return data;
}

const updateCodesApiRequest = async (codesId, formInputs) => {
    const { data } = await axios.put(`/api/discountCodes/admin/${codesId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const AdminEditDiscountCodesPage = () => {

    const reduxDispatch = useDispatch();

    return <AdminEditDiscountCodesComponent fetchCodes={fetchCodes} updateCodesApiRequest={updateCodesApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditDiscountCodesPage;
