import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GoogleAddressSearch from "../../../components/dispatch/googleAddressSearch";
import ProfileLinksComponent from "../../../components/profile/profileLinksComponent";
import ChangeEmailSettingsPopComponent from "../../../components/profile/changeEmailSettingsPopComponent";
import { FaRegThumbsUp } from 'react-icons/fa';
import ConnectStripeButtonCompany from "../../../components/stripe/connectStripeButtonCompany";
import { BsImageAlt } from 'react-icons/bs';
import axios from 'axios';

const UserEditCompanyComponent = ({
    updateCompanyApiRequest,
    fetchCompany,
    fetchUser,
    fetchUploads
}) => {
    const [validated, setValidated] = useState(false);
    const [company, setCompany] = useState([]);
    const [user, setUser] = useState({})
    const [selectedAddress, setSelectedAddress] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedZip, setSelectedZip] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("United States");
    const [selectedEmail, setSelectedEmail] = useState("");
    const [showEmail, setShowEmail] = useState(false);
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false); // New state to track loading state

    const [smtpSettings, setSmtpSettings] = useState({
        name: '',
        host: '',
        port: '',
        user: '',
        pass: '',
    });
    const [updateCompanyResponseState, setUpdateCompanyResponseState] = useState({
        message: "",
        error: "",
    });

    const auth = useSelector(state => state.auth)
    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isSuperAdmin = auth.user?.usertype?.includes("Developer") || false;
    const isAdminOrSuperAdmin = isAdmin || isSuperAdmin;

    useEffect(() => {
        setLoading(true);

        fetchUser(auth.user?._id)
            .then((data) => {
                setUser(data);
                fetchCompany(data.company)
                    .then((res) => {
                        setCompany(res);
                        setSelectedAddress(res.address);
                        setSelectedCity(res.city);
                        setSelectedState(res.state);
                        setSelectedZip(res.zip);
                        setSelectedCountry(res.country);
                        setSelectedEmail(res.email);
                        setSmtpSettings({
                            ...smtpSettings,
                            name: res.smtp.name,
                            host: res.smtp.host,
                            port: res.smtp.port,
                            user: res.smtp.auth.user,
                            pass: res.smtp.auth.pass,
                        });
                    })
                    .catch((er) => console.log(er));
            })
            .catch((er) => console.log(er));

        fetchUploads()
            .then((res) => {
                const filteredUploads = res.filter((item) => auth.user.company === item.company);
                const filteredUploadsFin = filteredUploads.find((item) => item.companyLogo === true);

                if (filteredUploadsFin) {
                    setUploadedLogo(filteredUploadsFin);
                    setLoading(false);
                } else {
                    setUploadedLogo(null);
                    setLoading(false);
                }
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
        );

    }, [user?.company, fetchCompany, auth.user?._id, fetchUser, fetchUploads, auth.user.company, smtpSettings]);

    useEffect(() => {
        if (loading) {

            fetchUser(auth.user?._id)
                .then((data) => {
                    setUser(data);
                    fetchCompany(data.company)
                        .then((res) => {
                            setCompany(res);
                            setSelectedAddress(res.address);
                            setSelectedCity(res.city);
                            setSelectedState(res.state);
                            setSelectedZip(res.zip);
                            setSelectedCountry(res.country);
                            setSelectedEmail(res.email);
                            setSmtpSettings({
                                ...smtpSettings,
                                name: res.smtp.name,
                                host: res.smtp.host,
                                port: res.smtp.port,
                                user: res.smtp.auth.user,
                                pass: res.smtp.auth.pass,
                            });
                        })
                        .catch((er) => console.log(er));
                })
                .catch((er) => console.log(er));

            fetchUploads()
                .then((res) => {
                    const filteredUploads = res.filter((item) => auth.user.company === item.company);
                    const filteredUploadsFin = filteredUploads.find((item) => item.companyLogo === true);

                    if (filteredUploadsFin) {
                        setUploadedLogo(filteredUploadsFin);
                        setLoading(false);
                    } else {
                        setUploadedLogo(null);
                        setLoading(false);
                    }
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
    }, [user?.company, loading, fetchCompany, auth.user?._id, fetchUser, fetchUploads, auth.user.company, smtpSettings]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            contactName: form.contactName.value,
            address: selectedAddress,
            phone: form.phone.value,
            city: selectedCity,
            country: selectedCountry,
            state: selectedState,
            zip: selectedZip,
            email: selectedEmail,
        };

        if (event.currentTarget.checkValidity() === true) {
            updateCompanyApiRequest(user?.company, formInputs)
                .then((data) => {
                    if (data.message === "Company updated") {
                        setLoading(true);
                    };
                })
                .catch((er) =>
                    setUpdateCompanyResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    }

    const handleCancelSettings = () => {
        setShowEmail(false);
    };

    const handleConfirmVerify = () => {
        setUpdateCompanyResponseState({ success: "Company Updated", error: "" });

        setTimeout(() => {
            setUpdateCompanyResponseState({ success: "", error: "" });
        }, 3000);

        setShowEmail(false);
    };

    const handleCloseAlertSuc = () => {
        setUpdateCompanyResponseState({ success: "", error: "" });
    };

    const handleImagePhoto = (e) => {

        if (e.target.files[0]) {
            const companyId = auth.user.company;
            const companyLogo = true;

            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            formData.append('company', companyId);
            formData.append('companyLogo', companyLogo);

            axios.post('/api/uploads/add', formData)
                .then((res) => {
                    console.log(res.data.message); // Log the message from the server
                    if (res.data.message === "File Added") {
                        setLoading(true);
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    const handleImagePhotoChange = (e) => {

        if (uploadedLogo._id) {
            axios.delete(`/api/uploads/${uploadedLogo._id}`)
                .then((res) => {
                    if (res.data.message === 'File deleted') {

                        if (e.target.files[0]) {
                            const companyId = auth.user.company;
                            const companyLogo = true;

                            // Create a FormData object to send the file
                            const formData = new FormData();
                            formData.append('file', e.target.files[0]);
                            formData.append('company', companyId);
                            formData.append('companyLogo', companyLogo);

                            axios.post('/api/uploads/add', formData)
                                .then((res) => {
                                    console.log(res.data.message); // Log the message from the server
                                    if (res.data.message === "File Added") {
                                        setLoading(true);
                                        setShowAlert(true);
                                        setTimeout(() => {
                                            setShowAlert(false);
                                        }, 3000);
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error deleting file:', error);
                });
        }
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <div className="min-vh-100">
            {showAlert && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    File Uploaded Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {updateCompanyResponseState.success === "Company Updated" && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Company Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showEmail && (
                <ChangeEmailSettingsPopComponent
                    setSmtpSettings={setSmtpSettings}
                    smtpSettings={smtpSettings}
                    companyId={auth.user.company}
                    onConfirm={handleConfirmVerify}
                    onCancel={handleCancelSettings}
                />
            )}
            {isAdminOrSuperAdmin ? (
                <div className="container-fluid min-vh-100 m-0 p-0">
                    <div className="row justify-content-md-center min-vh-100 m-0 p-0">
                        <div className="col-sm-2 m-0 p-0">
                            <ProfileLinksComponent />
                        </div>
                        <div className="col-sm-10 m-0 p-0 mt-5">
                            <h1 className="text-start">My Company Info</h1>
                            <div className="row ps-4 justify-content-md-between mt-5">
                                <div className="col-sm-4">
                                    <form validated={validated.toString()} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} className="row mt-4 g-3">
                                        <div>
                                            <label htmlFor="name">Company Name:</label>
                                            <input type="text"
                                                className="form-control"
                                                id="name"
                                                defaultValue={company.name}
                                                name="name"
                                                disabled
                                            />
                                        </div>
                                        <div className="row mt-3 m-0 p-0">
                                            <div className="col">
                                                <label htmlFor="contactName">Contact Name:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="contactName"
                                                    defaultValue={company.contactName}
                                                    name="contactName" />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="phone">Phone:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="phone"
                                                    defaultValue={company.phone}
                                                    name="phone" />
                                            </div>
                                            <div className="row mt-3 m-0 p-0">
                                                <div className="col">
                                                    <label htmlFor="email">Business Email:</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        onChange={(e) => setSelectedEmail(e.target.value)}
                                                        defaultValue={selectedEmail}
                                                        name="email" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3 m-0 p-0">
                                            <div className="col">
                                                <label>Company Address:</label>
                                                <GoogleAddressSearch
                                                    setSelectedAddress={setSelectedAddress}
                                                    setSelectedCity={setSelectedCity}
                                                    setSelectedState={setSelectedState}
                                                    setSelectedZip={setSelectedZip}
                                                    selectedAddress={selectedAddress}
                                                    setSelectedCountry={setSelectedCountry}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3 m-0 p-0">
                                            <div className="col">
                                                <label htmlFor="city">City:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="city"
                                                    onChange={(e) => setSelectedCity(e.target.value)}
                                                    defaultValue={selectedCity}
                                                    name="city" />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="state">State:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="state"
                                                    onChange={(e) => setSelectedState(e.target.value)}
                                                    defaultValue={selectedState}
                                                    name="state" />
                                            </div>
                                        </div>
                                        <div className="row mt-3 m-0 p-0">
                                            <div className="col">
                                                <label htmlFor="country">Country:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="country"
                                                    onChange={(e) => setSelectedCountry(e.target.value)}
                                                    defaultValue={selectedCountry}
                                                    name="country" />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="zip">Zip Code:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="zip"
                                                    onChange={(e) => setSelectedZip(e.target.value)}
                                                    defaultValue={selectedZip}
                                                    name="zip" />
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <button type="submit" className="btn btn-outline-success mb-3 loginButton">Update Company Details</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-sm-5 border">
                                    <h3 className="text-start mt-2 mb-2">Company Settings</h3>
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-sm-4 d-flex justify-content-center">
                                            {uploadedLogo ? (
                                                <div className="text-center">
                                                    <label htmlFor="fileInput1" className="btn btn-sm btn-outline-primary loginButton">Change Company Logo</label>
                                                    <input
                                                        id="fileInput1"
                                                        type="file"
                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                        name="file"
                                                        style={{ display: "none" }}
                                                        onChange={handleImagePhotoChange}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="text-center">
                                                    <label htmlFor="fileInput1" className="btn btn-sm btn-outline-primary loginButton">Upload Company Logo</label>
                                                    <input
                                                        id="fileInput1"
                                                        type="file"
                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                        name="file"
                                                        style={{ display: "none" }}
                                                        onChange={handleImagePhoto}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-sm-6 text-end mt-3 mb-3">
                                            <div className="logo-upload-wrapper">
                                                <label htmlFor="companyLogo" className="logo-upload-label">
                                                    <div className="logo-placeholder" style={{
                                                        backgroundColor: uploadedLogo ? 'transparent' : '#f8f9fa',
                                                        borderRadius: '50%',
                                                        height: '150px',
                                                        width: '150px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        {uploadedLogo ? (
                                                            <img src={uploadedLogo.imageUrl} alt="Company Logo" style={{
                                                                height: '100%',
                                                                width: 'auto',
                                                                borderRadius: '100%',
                                                            }} />
                                                        ) : (
                                                            <BsImageAlt style={{ fontSize: '48px', color: '#ccc' }} />
                                                        )}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-start">
                                        <div className="col-sm-4 text-end mt-3 mb-3">
                                            <span>Change email settings:</span>
                                        </div>
                                        <div className="col-sm-4 text-start mt-3 mb-4">
                                            <button className="btn btn-sm btn-outline-primary loginButton" onClick={() => setShowEmail(true)}>
                                                Change Settings
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-start">
                                        <div className="col-sm-4 text-end mt-3 mb-3">
                                            <span>Change payment settings:</span>
                                        </div>
                                        <div className="col-sm-4 text-start mt-3 mb-4">
                                            <ConnectStripeButtonCompany companyId={auth.user.company} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container mb-5 pb-5">
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-sm-2">
                            <ProfileLinksComponent />
                        </div>
                        <div className="col-sm-10">
                            <div className="row justify-content-md-center mt-5">
                                <div className="col-sm-5">
                                    <h1 className="text-center">My Company Info</h1>
                                    <form validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} className="row mt-4 g-3">
                                        <div>
                                            <label htmlFor="name">Company Name:</label>
                                            <input type="text"
                                                className="form-control"
                                                id="name"
                                                defaultValue={company.name}
                                                name="name"
                                                disabled
                                            />
                                        </div>
                                        <div className="row mt-3 m-0 p-0">
                                            <div className="col">
                                                <label htmlFor="contactName">Contact Name:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="contactName"
                                                    disabled
                                                    defaultValue={company.contactName}
                                                    name="contactName" />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="phone">Phone:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="phone"
                                                    disabled
                                                    defaultValue={company.phone}
                                                    name="phone" />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="address">Company Address:</label>
                                            <input type="text"
                                                className="form-control"
                                                id="address"
                                                disabled
                                                defaultValue={company.address}
                                                name="address" />
                                        </div>
                                        <div className="row mt-3 m-0 p-0">
                                            <div className="col">
                                                <label htmlFor="city">City:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="city"
                                                    disabled
                                                    defaultValue={company.city}
                                                    name="city" />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="state">State:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="state"
                                                    disabled
                                                    defaultValue={company.state}
                                                    name="state" />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="zip">Zip Code:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="zip"
                                                    disabled
                                                    defaultValue={company.zip}
                                                    name="zip" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
};

export default UserEditCompanyComponent;