import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from 'moment';
import { LiaGreaterThanSolid } from 'react-icons/lia';
import { LiaLessThanSolid } from 'react-icons/lia';
import LoadingComponent from "../../../components/loadingComponet";
import { BsPerson } from 'react-icons/bs';
import { BsTelephone } from 'react-icons/bs';
import { BiCalendar } from 'react-icons/bi';
import { BiMap } from 'react-icons/bi';
import { VscTools } from 'react-icons/vsc';
import { PiUserBold } from 'react-icons/pi';
import { LiaTagsSolid } from 'react-icons/lia';
import { PiNotePencil } from 'react-icons/pi';
import { PiPencilSimpleLineLight } from 'react-icons/pi';
import { PiArrowSquareOutLight } from 'react-icons/pi';
import { TfiClose } from 'react-icons/tfi';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CalendarComponent = ({ fetchSchedule, updateDispatchScheduleApiRequest, fetchUsers, fetchTechInfo, updateSchedule, fetchJobType, fetchJobTag }) => {
    const auth = useSelector(state => state.auth)
    const [events, setEvents] = useState([]);
    const [currentView, setCurrentView] = useState('month'); // Initialize with a default view
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [tech, setTech] = useState([]);

    const popupRef = useRef(null);

    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isDispatcher = auth.user?.usertype?.includes('Dispatcher') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;
    const isVerified = isAdmin || isDispatcher || isSuperAdmin;

    useEffect(() => {
        const abctrl = new AbortController();
        setLoading(true);

        fetchUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                console.log("Error fetching users:", error);
            });
        fetchTechInfo()
            .then((res) => {
                //const techJobs = res.find((tech) => tech.user === userInfo._id);
                setTech(res);

            })
            .catch((error) => {
                console.log("Error fetching techs:", error);
            });
        const fetchData = async () => {
            try {
                const [scheduleData, techData, tagData, typeData] = await Promise.all([
                    fetchSchedule(),
                    fetchTechInfo(),
                    fetchJobTag(),
                    fetchJobType(),
                ]);

                if (Array.isArray(scheduleData.schedule)) {

                    const formattedEvents = scheduleData.schedule.map((event) => {
                        if (event.techs) {
                            const technicians = event.techs.map((techId) => {
                                const tech = techData.find((tech) => tech._id === techId);
                                if (tech) {
                                    return {
                                        name: tech.user,
                                        color: tech.color,
                                    };
                                } else {
                                    return [];
                                }
                            });
                            const jobTags = event.jobTags.map((tagId) => {
                                const tags = tagData.find((tag) => tag._id === tagId);

                                return {
                                    name: tags.name,
                                    color: tags.color,
                                };
                            });

                            const typeId = event.jobType;
                            const type = typeData.find((type) => type._id === typeId);

                            return {
                                id: event._id,
                                title: event.title,
                                jobId: event.jobId,
                                deleted: event.deleted,
                                live: event.live,
                                user: event.user,
                                start: new Date(event.start),
                                end: new Date(event.end),
                                techs: event.techs,
                                technicians: technicians,
                                description: event.description || '',
                                count: event.count || '',
                                status: event.status || '',
                                jobType: type.name || '',
                                jobTags: jobTags || '',
                                lead: event.lead || '',
                                address: event.address || '',
                                city: event.city || '',
                                state: event.state || '',
                                zip: event.zip || '',
                                phone: event.phone || '',
                            };
                        } else {
                            return [];
                        }
                    });

                    const techJobs = techData.find((tech) => tech.user === auth.user._id);

                    if (techJobs) {
                        const filteredEvents = formattedEvents.filter((event) => !event.deleted);
                        const filteredEventsUser = filteredEvents.filter((job) => job.techs.includes(techJobs._id));

                        if (isVerified) {
                            setEvents(filteredEvents);
                        } else {
                            setEvents(filteredEventsUser);
                        }
                    } else if (formattedEvents && isVerified) {
                        const filteredEvents = formattedEvents.filter((event) => !event.deleted);

                        setEvents(filteredEvents);
                    } else { 
                        setEvents([])
                    }
                    setLoading(false);
                } else {
                    console.error("Invalid schedule data:", scheduleData);
                }
            } catch (error) {
                console.error("Error fetching schedule or tech info:", error);
            }
        };

        fetchData();

        return () => abctrl.abort();
    }, [ fetchSchedule, fetchTechInfo, fetchJobTag, fetchUsers, fetchJobType, auth.user._id, isAdmin, isSuperAdmin, isDispatcher]);

    const onEventResize = (data) => {
        const updatedEvents = events.map((event) => {
            if (event.id === data.event.id) {
                let updatedEvent;

                if (data.end.getDate() !== data.start.getDate()) {
                    // Extending the event to cover multiple days
                    const startTime = new Date(data.start);
                    startTime.setHours(event.start.getHours());
                    startTime.setMinutes(event.start.getMinutes());

                    const endTime = new Date(data.end);
                    endTime.setHours(event.end.getHours());
                    endTime.setMinutes(event.end.getMinutes());

                    updatedEvent = {
                        ...event,
                        start: startTime,
                        end: endTime,
                    };
                } else {
                    // Lengthening or shortening the event within one day
                    updatedEvent = {
                        ...event,
                        start: data.start,
                        end: data.end,
                    };
                }

                updateEventInApi(updatedEvent);
                return updatedEvent;
            }
            return event;
        });

        setEvents(updatedEvents);
    };

    const onEventDrop = (data) => {
        const updatedEvents = events.map((event) => {
            if (event.id === data.event.id) {
                const updatedEvent = {
                    ...event,
                    start: new Date(data.start.getTime()),
                    end: new Date(data.end.getTime()),
                };
                updateEventInApi(updatedEvent);
                return updatedEvent;
            }
            return event;
        });
        setEvents([...updatedEvents]);
    };

    const updateEventInApi = (updatedEvent) => {

        const timeStart = new Date(updatedEvent.start).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        const timeEnd = new Date(updatedEvent.end).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        const updatedEventData = {
            title: updatedEvent.title,
            start: updatedEvent.start, // Convert to ISO format
            end: updatedEvent.end, // Convert to ISO format
            techs: updatedEvent.techs, // Assuming you want tech names
            user: auth.user._id, // Use the appropriate user identifier
            deleted: false,
        };

        const formInputsSch = {
            timeStart: timeStart,
            timeEnd: timeEnd,
            dateStart: updatedEvent.start,
            dateEnd: updatedEvent.end
        };
        // Make an API request to update the event
        updateSchedule(updatedEvent.id, updatedEventData)
            .then((res) => {
                updateDispatchScheduleApiRequest(res.jobId, formInputsSch)
                    .then((data) => {
                        if (data.message === "Dispatch job updated");
                    })
                    .catch((error) => {
                        // Handle error if needed
                        console.error("Error updating event:", error);
                    });
            })
            .catch((error) => {
                // Handle error if needed
                console.error("Error updating event:", error);
            });
    };

    const eventStyleGetter = (event) => {

        const techColor = tech.filter((tech) => event.techs.includes(tech._id));

        const colors = techColor.map((tech) => tech.color);

        const style = {
            backgroundColor: colors.join(','),
            color: '#000',
        };

        if (event.live === false) {
            style.opacity = 0.4;
            style.color = '#fff';
        }

        return {
            style,
        };
    };

    const handleEventClick = (event, e) => {
        // Calculate the position for the popup relative to the event bar
        const eventBar = e.currentTarget;
        const rect = eventBar.getBoundingClientRect();
        const isDayView = currentView === "day";
        const isWeekView = currentView === "week";

        let top = rect.bottom + window.scrollY;

        // Calculate the initial left position
        let left = isDayView ? "50%" : rect.right + window.scrollX;

        // Check if the popup would bleed off the right edge of the calendar
        const calendar = document.querySelector(".rbc-calendar"); // Adjust this selector to match your calendar component
        const calendarRect = calendar.getBoundingClientRect();

        const popupWidth = 300; // Adjust this value as needed
        if (left + popupWidth > calendarRect.right) {
            // Popup would bleed off the right edge, position it to the left of the event bar
            left = rect.left - popupWidth;
        }
        if (!isDayView && !isWeekView) {
            const eventHeight = rect.height;
            top = rect.top + window.scrollY + eventHeight / 2 - 12;
        }

        // Center the popup vertically in day view
        if (isDayView) {
            const eventHeight = rect.height;
            const popupHeight = 100; // Adjust this value as needed
            top = rect.top + window.scrollY + eventHeight / 2 - popupHeight / 2 + 40;
        }
        if (isWeekView) {
            const eventHeight = rect.height;
            top = rect.top + window.scrollY + eventHeight / 2 - 12;
        }

        // Set the selected event and popup position
        setSelectedEvent(event);
        setPopupPosition({ top, left });
    };

    const closePopup = () => {
        setSelectedEvent(null);
        setPopupPosition({ top: 0, left: 0 });
    };
    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                closePopup();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const CustomToolbar = (toolbar) => {
        const goToBack = () => {
            toolbar.onNavigate("PREV");
        };

        const goToNext = () => {
            toolbar.onNavigate("NEXT");
        };

        const goToToday = () => {
            toolbar.onNavigate("TODAY");
        };

        return (
            <div className="rbc-toolbar mt-4 ps-3 pe-3">
                <span className="rbc-btn-group">
                    <button type="button" onClick={goToToday}>
                        Today
                    </button>
                    <button type="button" className="border-white fs-4 ms-4" onClick={goToBack}>
                        <LiaLessThanSolid />
                    </button>
                    <button type="button" className="border-white fs-4" onClick={goToNext}>
                        <LiaGreaterThanSolid />
                    </button>
                </span>
                <span className="rbc-toolbar-label fw-bold">{toolbar.label}</span>
                <span className="rbc-btn-group">
                    <button
                        type="button"
                        onClick={() => toolbar.onView("day")} // Switch to day view
                    >
                        Day
                    </button>
                    <button
                        type="button"
                        onClick={() => toolbar.onView("week")} // Switch to week view
                    >
                        Week
                    </button>
                    <button
                        type="button"
                        onClick={() => toolbar.onView("month")} // Switch to month view
                    >
                        Month
                    </button>
                </span>
            </div>
        );
    };

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    const EventComponent = ({ event }) => {
        // Function to adjust event data based on the view
        const eventTitleStyle = {
            whiteSpace: "pre-wrap", // Allow text to wrap
            wordWrap: "break-word", // Break words if they are too long
        };
        const adjustEventData = () => {
            if (currentView === "month") {
                const jobTags = event.jobTags.map((tag) => ({ name: tag.name, color: tag.color }));

                return (
                    <div className="event-title text-light">
                        {jobTags.map((jobTag, index) => (
                            <span
                                key={index}
                                className="eventTags me-1"
                                style={{ backgroundColor: jobTag.color }}
                            >
                                {jobTag.name}
                            </span>
                        ))}
                        {event.title} {event.jobType} - {event.address}, {event.city}, {event.state}, {event.zip}
                    </div>
                );
            } else {
                return (
                    <>
                        <div className="event-title" style={eventTitleStyle}>{event.title}</div>
                    </>
                );
            }
        };

        return (
            <div className="custom-event">
                {adjustEventData()}
            </div>
        );
    };

    const minTime = new Date();
    minTime.setHours(7, 0, 0);

    return (
        <div className="calendar-wrapper mt-5">
                <div className="myCustomHeight m-5">
                    {loading && (
                        <div className="loading-overlay">
                            <LoadingComponent />
                        </div>
                    )}
                    <DnDCalendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        onEventDrop={onEventDrop}
                        onEventResize={onEventResize}
                        style={{ height: 800 }}
                        eventPropGetter={eventStyleGetter}
                        components={{
                            toolbar: CustomToolbar,
                            event: (props) => (
                                <div
                                    onClick={(e) => handleEventClick(props.event, e)}
                                    className="event-wrapper"
                                >
                                    <EventComponent {...props} />
                                </div>
                            ),
                        }}
                        onSelectEvent={handleEventClick}
                        onView={handleViewChange}
                        view={currentView}
                        min={currentView === 'day' || currentView === 'week' ? minTime : undefined}
                        timeslots={4}
                        step={15}
                    />
                    {selectedEvent && (
                        <div
                            ref={popupRef} // Ref for the popup element
                            className="popup"
                            style={{
                                top: popupPosition.top,
                                left: popupPosition.left,
                                position: "absolute",
                            }}
                        >
                            <div className="popup-content">
                                <div className="row fs-5">
                                    <div className="ms-0 ps-0 col-sm me-5">
                                        Job #{selectedEvent.count}
                                    </div>
                                    <div className="col-sm d-flex justify-content-end">
                                        <div className="fw-light ms-2 fs-4 pe-auto">
                                            <PiPencilSimpleLineLight />
                                        </div>
                                        <div className="fw-light ms-2 fs-4">
                                            {selectedEvent.jobId ? (
                                                <Link className="iconDark" to={`/dispatch/job/${selectedEvent.jobId}`} target="_blank" rel="noopener noreferrer">
                                                    <PiArrowSquareOutLight />
                                                </Link>
                                            ) : (
                                                <span className="iconDark" style={{ cursor: "not-allowed", opacity: 0.5 }}>
                                                    <PiArrowSquareOutLight />
                                                </span>
                                            )}
                                        </div>
                                        <div className="fw-light ms-2 mt-1 fs-5" role="button">
                                            <TfiClose onClick={() => closePopup()} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="ms-0 ps-0 col-sm-1">
                                        <BsPerson className="fs-5 me-3" />
                                    </div>
                                    <div className="col-sm calfont">
                                        {selectedEvent.title}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="ms-0 ps-0 col-sm-1">
                                        <BsTelephone className="fs-5 me-3" />
                                    </div>
                                    <div className="col-sm calfont">
                                        {selectedEvent.phone}
                                    </div>
                                </div>
                                {moment(selectedEvent.start).isSame(selectedEvent.end, 'day') ? (
                                    <div className="row mt-2">
                                        <div className="ms-0 ps-0 col-sm-1">
                                            <BiCalendar className="fs-5 me-3" />
                                        </div>
                                        <div className="col-sm calfont">
                                            {moment(selectedEvent.start).format('ddd MMM Do')} -{' '}
                                            {moment(selectedEvent.start).format('h:mm A')} to{' '}
                                            {moment(selectedEvent.end).format('h:mm A')}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="row mt-2">
                                            <div className="ms-0 ps-0 col-sm-1">
                                                <BiCalendar className="fs-5 me-3" />
                                            </div>
                                            <div className="col-sm calfont">
                                                {moment(selectedEvent.start).format('ddd MMM Do h:mm A')} - {moment(selectedEvent.end).format('ddd MMM Do h:mm A')}
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="row mt-2">
                                    <div className="ms-0 ps-0 col-sm-1">
                                        <BiMap className="fs-5 me-3" />
                                    </div>
                                    <div className="col-sm calfont">
                                        {selectedEvent.address}, {selectedEvent.city}, {selectedEvent.state}, {selectedEvent.zip}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="ms-0 ps-0 col-sm-1">
                                        <VscTools className="fs-5 me-3" />
                                    </div>
                                    <div className="col-sm calfont">
                                        {selectedEvent.status}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="ms-0 ps-0 col-sm-1">
                                        <PiUserBold className="fs-5 me-3" />
                                    </div>
                                    <div className="col-sm calfont">
                                        {selectedEvent.technicians.map((tech, index) => {
                                            const decodedNames = users
                                                .filter((user) => tech.name === user._id)
                                                .map((user) => user.name);

                                            const joinedNames = decodedNames.join(', '); // Join the names into a single string

                                            return (
                                                <span key={index} className="me-1">
                                                    {joinedNames}
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                                {selectedEvent.jobTags.length !== 0 && (
                                    <div className="row mt-2">
                                        <div className="ms-0 ps-0 col-sm-1">
                                            <LiaTagsSolid className="fs-5 me-3" />
                                        </div>
                                        <div className="col-sm calfont">
                                            {selectedEvent.jobTags.map((jobTag, index) => (
                                                <span
                                                    key={index}
                                                    className="eventTags me-1"
                                                    style={{ backgroundColor: jobTag.color }}
                                                >
                                                    {jobTag.name}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {selectedEvent.description && (
                                    <div className="row mt-2">
                                        <div className="ms-0 ps-0 col-sm-1"> <PiNotePencil className="fs-5 me-3" /></div>
                                        <div className="col-sm-10 calfont">
                                            <textarea className="form-control-plaintext mt-0 pt-0" id="exampleFormControlTextarea1" readOnly rows="3">{selectedEvent.description}</textarea>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
        </div>
    );
};

export default CalendarComponent;