const paths = {
    ROOT: '/',
    VERIFY: '/verify/:token',
    OTPVERIFY: '/verify-otp/:email',
    PASSWORDASSISTANCE: '/password-assistance',
    CUSTCONTACT: '/contact-locksmith',
    SMSPATH: '/sms-test',
    TASKSLIST: '/root/task-list',
    NEWTASK: '/root/new-task',
    REQUESTTIMEOFF: '/root/request-time-off',
    TIMEENTRY: '/root/time-the-clock',
    WORKSCHEDULE: '/root/work-schedule',
    NEWWORKSCHEDULE: '/root/create-work-schedule',
    DISPATCHOPENQUOTES: '/dispatch/root/open-quotes',
    LOCKSMITHVINKEYCODEINFO: '/locksmith/vin/key-code/:id',
    PAYLOCKSMITHREQUEST: '/buy-pincode/purchase/:id',
    LOCKSMITHSEARCH: '/locksmith-search',
    LOCKSMITHHELP: '/locksmith-help/:category',
    LOCKSMITHHELPINDEX: '/locksmith-help/:category/:name',
    DECODEVIN: '/decode-vin',
    MODELSEARCHVIN: '/:name/:model/:year/:vin',
    PRICESEARCHVIN: '/dispatch/:name/:model/:year/:vin',
    ADMINSEARCHVIN: 'admin/:name/:model/:year/:vin',
    BUYKEYCODE: '/buy-pincode',
    BUYKEYCODEVIN: '/buy-pincode/:vin',
    ADMINVINPRICE: '/admin/vin-price/page',
    ADMINNEWVINPRICE: '/admin/new/vin-price/page',
    ADMINEDITVINPRICE: '/admin/edit/vin-price/:id',
    LOGIN: '/login',
    REGISTER: '/register',
    USERPROFILE: '/user',
    INVOICE: '/job-invoice/:id',
    CLIENTESTIMATE: '/clientPortal/estimate/:id',
    CLIENTINVOICE: '/clientPortal/invoice/:id',
    USERCOMPANY: '/company',
    MAKESEARCH: '/:name',
    OTHERJOB: '/other-job-select',
    INVENTORYTOOLS: '/root/inventory/items/tools',
    INVENTORYMANAGERITEMS: '/root/inventory/items',
    INVENTORYMANAGERITEMSTECH: '/root/inventory/tech/items',
    INVENTROYORDER: '/root/inventory/order',
    INVENTORYREQUEST: '/root/inventory/request',
    INVENTORYREQUESTSTATUS: '/root/inventory/request-status',
    INVENTORYINCREASE: '/root/inventory/increase',
    INVENTORYMANGERMAIN: '/root/inventory/',
    INVENTORYADD: '/root/new/inventory',
    INVENTORYEDIT: '/root/edit/inventory/:id',
    MODELSEARCH: '/:name/:model/:year',
    PRICESEARCH: '/dispatch/:name/:model/:year',
    DISPATCHOTHER: '/dispatch/:other',
    DISPATCHESTIMATE: '/root/estimate/:id',
    DISPATCHJOB: '/dispatch/job/:id',
    DISPATCHPANELJOB: '/dispatch/root/job',
    DISPATCHEDITPANELJOB: '/dispatch/root/job/:id',
    ADMINUPDATETIME: '/admin/update-time-clock',
    ADMINCODEREQUESTS: '/admin-code-requests-page',
    ADMINEDITCODEREQUEST: '/admin/edit-code-request/:id',
    ADMINCODES: '/admin-codes-page',
    ADMINNEWCODES: '/admin-new-codes-page',
    ADMINEDITCODES: '/admin/edit-codes/:id',
    ADMINDISCOUNTCODES: '/admin-discount-codes-page',
    ADMINNEWDISCOUNTCODES: '/admin-new-discount-codes-page',
    ADMINEDITDISCOUNTCODES: '/admin/edit-discount-codes/:id',
    ADMINSEARCH: 'admin/:name/:model/:year',
    ADMININSTRUCTION: '/admin-instruction-page',
    ADMINEDITINSTRUCTION: '/admin/edit-instruction/:id',
    ADMINNEWINSTRUCTION: '/admin-new-instruction-page',
    ADMINLOCATION: '/admin-location-page',
    ADMINEDITLOCATION: '/admin/edit-location/:id',
    ADMINNEWLOCATION: '/admin-new-location-page',
    ADMINMAKE: '/admin-make-page',
    ADMINNEWMAKE: '/admin-new-make-page',
    ADMINEDITMAKE: '/admin/edit-make/:id',
    ADMINEDITMODEL: '/admin/edit-model/:id',
    ADMINMODEL: '/admin-model-page',
    ADMINNEWMODEL: '/admin-new-model-page',
    ADMINYEAR: '/admin-year-page',
    ADMINNEWYEAR: '/admin-new-year-page',
    ADMINEDITYEAR: '/admin/edit-year/:id',
    ADMINLOCKSMITHINFO: '/admin-locksmithInfo-page',
    ADMINNEWLOCKSMITHINFO: '/admin-new-locksmithInfo-page',
    ADMINEDITLOCKSMITHINFO: '/admin/edit-locksmithInfo/:id',
    ADMINROOTUSERS: '/root/users',
    ADMINROOTEDITUSER: '/root/edit/users/:id',
    ADMINROOTNEWUSER: '/root/new/users',
    ADMINCOMPANY: '/admin/company',
    ADMINEDITCOMPANY: '/admin/edit-company/:id',
    ADMINNEWCOMPANY: '/admin-new-company-page',
    ADMINTAX: '/admin/tax',
    ADMINEDITTAX: '/admin/edit-tax/:id',
    ADMINNEWTAX: '/admin-new-tax-page',
    DISPATCHCALENDAR: '/dispatch-schedule',
    DISPATCHCUTOMER: '/dispatch-customer-page',
    DISPATCHNEWCUSTOMER: '/dispatch-new-customer-page',
    DISPATCHEDITCUSTOMER: '/dispatch/edit-customer/:id',
    DISPATCHJOBTYPE: '/dispatch-job-type-page',
    DISPATCHNEWJOBTYPE: '/dispatch-new-job-type-page',
    DISPATCHEDITJOBTYPE: '/dispatch/edit-job-type/:id',
    DISPATCHJOBSOURCE: '/dispatch-job-source-page',
    DISPATCHNEWJOBSOURCE: '/dispatch-new-job-source-page',
    DISPATCHEDITJOBSOURCE: '/dispatch/edit-job-source/:id',
    DISPATCHJOBTAG: '/dispatch-job-tag-page',
    DISPATCHNEWJOBTAG: '/dispatch-new-job-tag-page',
    DISPATCHEDITJOBTAG: '/dispatch/edit-job-tag/:id',
    DISPATCHJOBITEM: '/dispatch-job-item-page',
    DISPATCHNEWJOBITEM: '/dispatch-new-job-item-page',
    DISPATCHEDITJOBITEM: '/dispatch/edit-job-item/:id',
    NOTFOUND: '*',
};

export default paths;