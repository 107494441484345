import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
import axios from 'axios';
import { CiCircleRemove } from "react-icons/ci";

const years = [];
for (let year = 1970; year <= 2023; year++) {
    years.push(year);
}

const CreateLocksmithInfoPageComponent = ({
    createLocksmithInfoApiRequest,
    fetchModel,
    fetchMake,
    fetchInstruction,
    fetchYear,
    fetchUploadsImage
}) => {
    const [savedYears, setSavedYears] = useState([]);
    const auth = useSelector(state => state.auth)
    const [validated, setValidated] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValue1, setSelectedValue1] = useState('');
    const [selectedValue2, setSelectedValue2] = useState('');
    const [selectedValue3, setSelectedValue3] = useState('');
    const [selectedValue4, setSelectedValue4] = useState('');
    const [selectedValue5, setSelectedValue5] = useState('');
    const [content, setContent] = useState('');
    const [content1, setContent1] = useState('');
    const [content2, setContent2] = useState('');
    const [selectedMake, setSelectedMake] = useState('');
    const [make, setMake] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedYear, setSelectedYear] = useState([]);
    const [modelDisabled, setModelDisabled] = useState(true);
    const [yearDisabled, setYearDisabled] = useState(true);
    const [filteredModels, setFilteredModels] = useState([]);
    const [imageDescriptions, setImageDescriptions] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [instructionSaved, setInstructionSaved] = useState([]);
    const [instructionDiagnose, setInstructionDiagnose] = useState([]);
    const [instructionDiagnoseSaved, setInstructionDiagnoseSaved] = useState([]);
    const [instructionHardware, setInstructionHardware] = useState([]);
    const [instructionHardwareSaved, setInstructionHardwareSaved] = useState([]);
    const [instructionRekey, setInstructionRekey] = useState([]);
    const [instructionRekeySaved, setInstructionRekeySaved] = useState([]);
    const [instructionOther, setInstructionOther] = useState([]);
    const [instructionOtherSaved, setInstructionOtherSaved] = useState([]);
    const [selectedInstructions, setSelectedInstructions] = useState([]);
    const [createLocksmithInfoResponseState, setCreateLocksmithInfoResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    useEffect(() => {
        fetchModel(selectedMake)
            .then((data) => {
                setFilteredModels(data);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
    }, [selectedMake, fetchModel]);

    useEffect(() => {
        if (selectedMake && selectedModel) {
            fetchYear(selectedModel)
                .then((data) => {
                    const matchingMake = data.filter((item) => item.make === selectedMake);
                    const matchingYears = matchingMake.map((item) => item.year);
                    setSelectedYear(matchingYears[0])
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
    }, [selectedModel, fetchYear, selectedMake]);

    useEffect(() => {
        const abctrl = new AbortController();
        fetchMake(abctrl)
            .then((res) => setMake(res))
            .catch((er) =>
                setMake([
                    { name: er.response.data.message ? er.response.data.message : er.response.data }
                ]),
            );
        return () => abctrl.abort();
    }, [fetchMake]);

    useEffect(() => {
        fetchInstruction()
            .then((data) => {
                const filteredCompany = data.filter((item) => item.company === auth.user.company);
                const filteredInstruc = filteredCompany.filter((item) => item.instructionCatagory === 'Automotive EEPROM');
                const filteredInstrucDiagnose = filteredCompany.filter((item) => item.instructionCatagory === 'Automotive Diagnose');
                const filteredInstrucHardware = filteredCompany.filter((item) => item.instructionCatagory === 'Automotive Hardware Install/Repair');
                const filteredInstrucRekey = filteredCompany.filter((item) => item.instructionCatagory === 'Automotive Rekey');
                const filteredInstrucOther = filteredCompany.filter((item) => item.instructionCatagory === 'Automotive Other');

                setInstructionSaved(filteredInstruc);
                setInstructionDiagnose(filteredInstrucDiagnose);
                setInstructionHardware(filteredInstrucHardware);
                setInstructionRekey(filteredInstrucRekey);
                setInstructionOther(filteredInstrucOther)
            })
            .catch((er) => console.log(er));

    }, [fetchInstruction]);


    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const updatedContent = content.replace(/[\n\r]+\s*/g, '\n');
        const updatedContent1 = content1.replace(/[\n\r]+\s*/g, '\n');
        const form = event.currentTarget.elements;
        const formInputs = {
            make: selectedMake,
            model: selectedModel,
            yearName: updatedContent,
            years: savedYears,
            keyType: form.keyType.value,
            keyType1: form.keyType1.value,
            keyType2: form.keyType2.value,
            keyType3: form.keyType3.value,
            keyType4: form.keyType4.value,
            keyType5: form.keyType5.value,
            im608: form.im608.value,
            im6081: form.im6081.value,
            im6082: form.im6082.value,
            im6083: form.im6083.value,
            im6084: form.im6084.value,
            im6085: form.im6085.value,
            zedfull: form.zedfull.value,
            zedfull1: form.zedfull1.value,
            zedfull2: form.zedfull2.value,
            zedfull3: form.zedfull3.value,
            zedfull4: form.zedfull4.value,
            zedfull5: form.zedfull5.value,
            onBoard: form.onBoard.value,
            onBoard1: form.onBoard1.value,
            onBoard2: form.onBoard2.value,
            onBoard3: form.onBoard3.value,
            onBoard4: form.onBoard4.value,
            onBoard5: form.onBoard5.value,
            smartPro: form.smartPro.value,
            smartPro1: form.smartPro1.value,
            smartPro2: form.smartPro2.value,
            smartPro3: form.smartPro3.value,
            smartPro4: form.smartPro4.value,
            smartPro5: form.smartPro5.value,
            rw4: form.rw4.value,
            rw41: form.rw41.value,
            rw42: form.rw42.value,
            rw43: form.rw43.value,
            rw44: form.rw44.value,
            rw45: form.rw45.value,
            topdon: form.topdon.value,
            topdon1: form.topdon1.value,
            topdon2: form.topdon2.value,
            topdon3: form.topdon3.value,
            topdon4: form.topdon4.value,
            topdon5: form.topdon5.value,
            smartbox: form.smartbox.value,
            smartbox1: form.smartbox1.value,
            smartbox2: form.smartbox2.value,
            smartbox3: form.smartbox3.value,
            smartbox4: form.smartbox4.value,
            smartbox5: form.smartbox5.value,
            numberOfKeys: form.numberOfKeys.value,
            numberOfKeys1: form.numberOfKeys1.value,
            numberOfKeys2: form.numberOfKeys2.value,
            numberOfKeys3: form.numberOfKeys3.value,
            numberOfKeys4: form.numberOfKeys4.value,
            numberOfKeys5: form.numberOfKeys5.value,
            mainContent: updatedContent1,
            color: selectedValue,
            color1: selectedValue1,
            color2: selectedValue2,
            color3: selectedValue3,
            color4: selectedValue4,
            color5: selectedValue5,
            dispatchNote: form.dispatchNote.value,
            image: imageDescriptions.map((item) => ({
                id: item.id,
                description: item.description,
            })),
            lishi: form.lishi.value,
            testBlade: form.testBlade.value,
            keyCode: form.keyCode.value,
            codeSeries: form.codeSeries.value,
            keyInfo: form.keyInfo.value,
            doorCode: form.doorCode.value,
            company: auth.user.company,
            instructionId: selectedInstructions,
            instructionRekeyId: instructionRekeySaved,
            instructionHardwareId: instructionHardwareSaved,
            instructionDiagnoseId: instructionDiagnoseSaved,
            instructionOtherId: instructionOtherSaved,
        };
        if (event.currentTarget.checkValidity() === true) {
            createLocksmithInfoApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Locksmith info created") navigate(`${paths.ADMINLOCKSMITHINFO}`);
                })
                .catch((er) => {
                    setCreateLocksmithInfoResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    }
    const handleEditorChange = (newContent) => {
        setContent(newContent);
    };
    const handleEditorChange1 = (newContent1) => {
        setContent1(newContent1);
    };
    const handleDropdownSelect = (value) => {
        setSelectedValue(value);
    };
    const handleDropdownSelect1 = (value) => {
        setSelectedValue1(value);
    };
    const handleDropdownSelect2 = (value) => {
        setSelectedValue2(value);
    };
    const handleDropdownSelect3 = (value) => {
        setSelectedValue3(value);
    };
    const handleDropdownSelect4 = (value) => {
        setSelectedValue4(value);
    };
    const handleDropdownSelect5 = (value) => {
        setSelectedValue5(value);
    };

    const handleImagePhoto = async (e) => {
        const selectedImage = e.target.files[0];

        if (selectedImage) {

            const imageFormData = new FormData();
            imageFormData.append("file", selectedImage);
            imageFormData.append("company", auth.user.company);

            try {
                const response = await axios.post("/api/uploads/add", imageFormData);
                const data = await fetchUploadsImage(response.data.file._id);

                const newDescription = content2;
                const updatedDescriptions = [...imageDescriptions];

                const updatedPreviews = [...imagePreviews];
                updatedPreviews.push(data.imageUrl);

                updatedDescriptions.push({
                    id: data,
                    description: newDescription,
                });

                setImagePreviews(updatedPreviews);
                setImageDescriptions(updatedDescriptions);

            } catch (error) {
                console.error("Error uploading image:", error);
            }

            setContent2('');

            e.target.value = null;
        }
    };

    const handleDelete = (uploadId) => {

        axios.delete(`/api/uploads/${uploadId}`)
            .then((res) => {
                setImageDescriptions([]);
            })
            .catch((error) => {
                console.error('Error deleting file:', error);
            });

    };
    const handleDescriptionChange = (index, description) => {
        const updatedDescriptions = [...imageDescriptions];
        updatedDescriptions[index].description = description;
        setImageDescriptions(updatedDescriptions);
    };

    const handleInstructionChange = (event) => {
        const selectedId = event.target.value;
        if (selectedId) {
            const selectedOption = instructionSaved.find(item => item._id === selectedId);
            setSelectedInstructions(prev => [...prev, selectedOption]);
            setInstructionSaved(prev => prev.filter(item => item._id !== selectedId));
        }
    };

    const removeInstruction = (id) => {
        const newSelectedInstructions = selectedInstructions.filter(item => item._id !== id);
        setSelectedInstructions(newSelectedInstructions);
        setInstructionSaved([...instructionSaved, selectedInstructions.find(item => item._id === id)]);
    };

    const handleInstructionDiagnoseChange = (event) => {
        const selectedId = event.target.value;

        if (selectedId) {
            const selectedOption = instructionDiagnose.find(item => item._id === selectedId);
            setInstructionDiagnoseSaved(prev => [...prev, selectedOption]);
            setInstructionDiagnose(prev => prev.filter(item => item._id !== selectedId));
        }
    };

    const removeInstructionDiagnose = (id) => {
        const newSelectedInstructions = instructionDiagnoseSaved.filter(item => item._id !== id);
        setInstructionDiagnoseSaved(newSelectedInstructions);
        setInstructionDiagnose([...instructionDiagnose, instructionDiagnoseSaved.find(item => item._id === id)]);
    };

    const handleInstructionRekeyChange = (event) => {
        const selectedId = event.target.value;

        if (selectedId) {
            const selectedOption = instructionRekey.find(item => item._id === selectedId);
            setInstructionRekeySaved(prev => [...prev, selectedOption]);
            setInstructionRekey(prev => prev.filter(item => item._id !== selectedId));
        }
    };

    const removeInstructionRekey = (id) => {
        const newSelectedInstructions = instructionRekeySaved.filter(item => item._id !== id);
        setInstructionRekeySaved(newSelectedInstructions);
        setInstructionRekey([...instructionRekey, instructionRekeySaved.find(item => item._id === id)]);
    };

    const handleInstructionHardwareChange = (event) => {
        const selectedId = event.target.value;

        if (selectedId) {
            const selectedOption = instructionHardware.find(item => item._id === selectedId);
            setInstructionHardwareSaved(prev => [...prev, selectedOption]);
            setInstructionHardware(prev => prev.filter(item => item._id !== selectedId));
        }
    };

    const removeInstructionHardware = (id) => {
        const newSelectedInstructions = instructionHardwareSaved.filter(item => item._id !== id);
        setInstructionHardwareSaved(newSelectedInstructions);
        setInstructionHardware([...instructionHardware, instructionHardwareSaved.find(item => item._id === id)]);
    };

    const handleInstructionOtherChange = (event) => {
        const selectedId = event.target.value;

        if (selectedId) {
            const selectedOption = instructionOther.find(item => item._id === selectedId);
            setInstructionOtherSaved(prev => [...prev, selectedOption]);
            setInstructionOther(prev => prev.filter(item => item._id !== selectedId));
        }
    };

    const removeInstructionOther = (id) => {
        const newSelectedInstructions = instructionOtherSaved.filter(item => item._id !== id);

        setInstructionOtherSaved(newSelectedInstructions);
        setInstructionOther([...instructionOther, instructionOtherSaved.find(item => item._id === id)]);
    };

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-10">
                        <h1 className="text-center">New Locksmith Info</h1>
                        <form validated={validated.toString()} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} className="row pt-4 g-3">
                            <div className="row mt-4">
                                <div className="col-sm-5">
                                    <ReactQuill
                                        value={content}
                                        onChange={handleEditorChange}
                                        placeholder="Write something..."
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline', 'strike'],
                                                ['blockquote', 'code-block'],
                                                [{ 'header': 1 }, { 'header': 2 }],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                                [{ 'direction': 'rtl' }],
                                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                [{ 'color': [] }, { 'background': [] }],
                                                [{ 'font': [] }],
                                                [{ 'align': [] }],
                                                ['clean']
                                            ],
                                        }}
                                        formats={{
                                            clear: false,
                                        }}
                                        preserveWhitespace={false}
                                        normalizeWhitespace={false}
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-3" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <label htmlFor="make">Make:</label>
                                    <select
                                        className="btn btn-outline-light text-dark"
                                        id="make"
                                        name="make"
                                        value={selectedMake}
                                        onChange={(e) => {
                                            setSelectedMake(e.target.value);
                                            setModelDisabled(false);
                                            setYearDisabled(true);
                                            setSelectedModel('');
                                        }}
                                    >
                                        <option value="">Select A Make</option>
                                        {make
                                            .slice()
                                            .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
                                            .map((makes) => (
                                                <option key={makes._id} value={makes.id}>
                                                    {makes.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-sm-3" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <label htmlFor="model">Model:</label>
                                    <select
                                        className="btn btn-outline-light text-dark"
                                        id="model"
                                        name="model"
                                        value={selectedModel}
                                        onChange={(e) => {
                                            setSelectedModel(e.target.value);
                                            setYearDisabled(false);
                                        }}
                                        disabled={modelDisabled}
                                    >
                                        <option value="">Select A Model</option>
                                        {filteredModels
                                            .filter((models) => models.make === selectedMake)
                                            .slice()
                                            .sort((a, b) => a.model.localeCompare(b.model))
                                            .map((models) => (
                                                <option key={models.id} value={models.id}>
                                                    {models.model}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-sm-2 p-0 m-0" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center" }}>
                                    <label htmlFor="year">Year:</label>
                                    <Select
                                        isDisabled={yearDisabled}
                                        isMulti
                                        options={selectedYear.map(year => ({ value: year, label: year }))}
                                        value={savedYears.map(year => ({ value: year, label: year }))}
                                        onChange={selectedOptions => setSavedYears(selectedOptions.map(option => option.value))}
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <label htmlFor="dispatchNote">Dispatcher Notes:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="dispatchNote"
                                        name="dispatchNote"
                                    />
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <label htmlFor="keyType">Key Type</label>
                                    <input type="text"
                                        className="form-control"
                                        id="keyType"
                                        name="keyType"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="im608">IM608</label>
                                    <input type="text"
                                        className="form-control"
                                        id="im608"
                                        name="im608"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="zedfull">Zedfull</label>
                                    <input type="text"
                                        className="form-control"
                                        id="zedfull"
                                        name="zedfull"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="onBoard">On Board</label>
                                    <input type="text"
                                        className="form-control"
                                        id="onBoard"
                                        name="onBoard"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartPro">Smart Pro</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartPro"
                                        name="smartPro"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="rw4">Key Tool</label>
                                    <input type="text"
                                        className="form-control"
                                        id="rw4"
                                        name="rw4"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="topdon">Topdon</label>
                                    <input type="text"
                                        className="form-control"
                                        id="topdon"
                                        name="topdon"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartbox">Smartbox</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartbox"
                                        name="smartbox"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="numberOfKeys"># of Keys:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="numberOfKeys"
                                        name="numberOfKeys"
                                    />
                                </div>
                            </div>
                            <div className="dropdown">
                                <button
                                    className="btn btn-outline-light text-dark dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {selectedValue || 'Select A Color'}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a
                                        className="dropdown-item"
                                        href='/'
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect3('');
                                        }}
                                    >
                                        Select
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect('greenproxbg')
                                        }}
                                    >
                                        Green/Prox
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect('bg-info')
                                        }}
                                    >
                                        Blue/Transponder Key
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect('bg-warning')
                                        }}
                                    >
                                        Yellow/Remote
                                    </a>
                                </div>
                                <input type="hidden" name="dropdownValue" value={selectedValue} />
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <label htmlFor="keyType1">Key Type 1</label>
                                    <input type="text"
                                        className="form-control"
                                        id="keyType1"
                                        name="keyType1"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="im6081">IM608</label>
                                    <input type="text"
                                        className="form-control"
                                        id="im6081"
                                        name="im6081"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="zedfull1">Zedfull</label>
                                    <input type="text"
                                        className="form-control"
                                        id="zedfull1"
                                        name="zedfull1"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="onBoard1">On Board</label>
                                    <input type="text"
                                        className="form-control"
                                        id="onBoard1"
                                        name="onBoard1"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartPro1">Smart Pro</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartPro1"
                                        name="smartPro1"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="rw14">Key Tool</label>
                                    <input type="text"
                                        className="form-control"
                                        id="rw41"
                                        name="rw41"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="topdon1">Topdon</label>
                                    <input type="text"
                                        className="form-control"
                                        id="topdon1"
                                        name="topdon1"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartbox1">Smartbox</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartbox1"
                                        name="smartbox1"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="numberOfKeys1"># of Keys:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="numberOfKeys1"
                                        name="numberOfKeys1"
                                    />
                                </div>
                            </div>
                            <div className="dropdown">
                                <button
                                    className="btn btn-outline-light text-dark dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {selectedValue1 || 'Select A Color'}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect3('')
                                        }}
                                    >
                                        Select
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect1('greenproxbg')
                                        }}
                                    >
                                        Green/Prox
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect1('bg-info')
                                        }}
                                    >
                                        Blue/Transponder Key
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect1('bg-warning')
                                        }}
                                    >
                                        Yellow/Remote
                                    </a>
                                </div>
                                <input type="hidden" name="dropdownValue" value={selectedValue1} />
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <label htmlFor="keyType2">Key Type 2</label>
                                    <input type="text"
                                        className="form-control"
                                        id="keyType2"
                                        name="keyType2"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="im6082">IM608</label>
                                    <input type="text"
                                        className="form-control"
                                        id="im6082"
                                        name="im6082"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="zedfull2">Zedfull</label>
                                    <input type="text"
                                        className="form-control"
                                        id="zedfull2"
                                        name="zedfull2"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="onBoard2">On Board</label>
                                    <input type="text"
                                        className="form-control"
                                        id="onBoard2"
                                        name="onBoard2"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartPro2">Smart Pro</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartPro2"
                                        name="smartPro2"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="rw42">Key Tool</label>
                                    <input type="text"
                                        className="form-control"
                                        id="rw42"
                                        name="rw42"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="topdon2">Topdon</label>
                                    <input type="text"
                                        className="form-control"
                                        id="topdon2"
                                        name="topdon2"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartbox2">Smartbox</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartbox2"
                                        name="smartbox2"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="numberOfKeys2"># of Keys:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="numberOfKeys2"
                                        name="numberOfKeys2"
                                    />
                                </div>
                            </div>
                            <div className="dropdown">
                                <button
                                    className="btn btn-outline-light text-dark dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {selectedValue2 || 'Select A Color'}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect3('')
                                        }}
                                    >
                                        Select
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect2('greenproxbg')
                                        }}
                                    >
                                        Green/Prox
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect2('bg-info')
                                        }}
                                    >
                                        Blue/Transponder Key
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect2('bg-warning')
                                        }}
                                    >
                                        Yellow/Remote
                                    </a>
                                </div>
                                <input type="hidden" name="dropdownValue" value={selectedValue2} />
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <label htmlFor="keyType3">Key Type 3</label>
                                    <input type="text"
                                        className="form-control"
                                        id="keyType3"
                                        name="keyType3"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="im6083">IM608</label>
                                    <input type="text"
                                        className="form-control"
                                        id="im6083"
                                        name="im6083"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="zedfull3">Zedfull</label>
                                    <input type="text"
                                        className="form-control"
                                        id="zedfull3"
                                        name="zedfull3"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="onBoard3">On Board</label>
                                    <input type="text"
                                        className="form-control"
                                        id="onBoard3"
                                        name="onBoard3"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartPro3">Smart Pro</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartPro3"
                                        name="smartPro3"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="rw43">Key Tool</label>
                                    <input type="text"
                                        className="form-control"
                                        id="rw43"
                                        name="rw43"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="topdon3">Topdon</label>
                                    <input type="text"
                                        className="form-control"
                                        id="topdon3"
                                        name="topdon3"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartbox3">Smartbox</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartbox3"
                                        name="smartbox3"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="numberOfKeys3"># of Keys:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="numberOfKeys3"
                                        name="numberOfKeys3"
                                    />
                                </div>
                            </div>
                            <div className="dropdown">
                                <button
                                    className="btn btn-outline-light text-dark dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {selectedValue3 || 'Select A Color'}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect3('')
                                        }}
                                    >
                                        Select
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect3('greenproxbg')
                                        }}
                                    >
                                        Green/Prox
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect3('bg-info')
                                        }}
                                    >
                                        Blue/Transponder Key
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect3('bg-warning')
                                        }}
                                    >
                                        Yellow/Remote
                                    </a>
                                </div>
                                <input type="hidden" name="dropdownValue" value={selectedValue3} />
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <label htmlFor="keyType4">Key Type 4</label>
                                    <input type="text"
                                        className="form-control"
                                        id="keyType4"
                                        name="keyType4"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="im6084">IM608</label>
                                    <input type="text"
                                        className="form-control"
                                        id="im6084"
                                        name="im6084"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="zedfull4">Zedfull</label>
                                    <input type="text"
                                        className="form-control"
                                        id="zedfull4"
                                        name="zedfull4"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="onBoard4">On Board</label>
                                    <input type="text"
                                        className="form-control"
                                        id="onBoard4"
                                        name="onBoard4"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartPro4">Smart Pro</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartPro4"
                                        name="smartPro4"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="rw44">Key Tool</label>
                                    <input type="text"
                                        className="form-control"
                                        id="rw44"
                                        name="rw44"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="topdon4">Topdon</label>
                                    <input type="text"
                                        className="form-control"
                                        id="topdon4"
                                        name="topdon4"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartbox4">Smartbox</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartbox4"
                                        name="smartbox4"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="numberOfKeys4"># of Keys:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="numberOfKeys4"
                                        name="numberOfKeys4"
                                    />
                                </div>
                            </div>
                            <div className="dropdown">
                                <button
                                    className="btn btn-outline-light text-dark dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {selectedValue4 || 'Select A Color'}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a
                                        className="dropdown-item"
                                        href='/'
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect4('');
                                        }}
                                    >
                                        Select
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect4('greenproxbg')
                                        }}
                                    >
                                        Green/Prox
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect4('bg-info')
                                        }}
                                    >
                                        Blue/Transponder Key
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect4('bg-warning')
                                        }}
                                    >
                                        Yellow/Remote
                                    </a>
                                </div>
                                <input type="hidden" name="dropdownValue" value={selectedValue4} />
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <label htmlFor="keyType5">Key Type 5</label>
                                    <input type="text"
                                        className="form-control"
                                        id="keyType5"
                                        name="keyType5"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="im6085">IM608</label>
                                    <input type="text"
                                        className="form-control"
                                        id="im6085"
                                        name="im6085"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="zedfull5">Zedfull</label>
                                    <input type="text"
                                        className="form-control"
                                        id="zedfull5"
                                        name="zedfull5"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="onBoard5">On Board</label>
                                    <input type="text"
                                        className="form-control"
                                        id="onBoard5"
                                        name="onBoard5"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartPro5">Smart Pro</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartPro5"
                                        name="smartPro5"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="rw45">Key Tool</label>
                                    <input type="text"
                                        className="form-control"
                                        id="rw45"
                                        name="rw45"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="topdon5">Topdon</label>
                                    <input type="text"
                                        className="form-control"
                                        id="topdon5"
                                        name="topdon5"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="smartbox5">Smartbox</label>
                                    <input type="text"
                                        className="form-control"
                                        id="smartbox5"
                                        name="smartbox5"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="numberOfKeys5"># of Keys:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="numberOfKeys5"
                                        name="numberOfKeys5"
                                    />
                                </div>
                            </div>
                            <div className="dropdown">
                                <button
                                    className="btn btn-outline-light text-dark dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {selectedValue5 || 'Select A Color'}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a
                                        className="dropdown-item"
                                        href='/'
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect5('');
                                        }}
                                    >
                                        Select
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect5('greenproxbg')
                                        }}
                                    >
                                        Green/Prox
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect5('bg-info')
                                        }}
                                    >
                                        Blue/Transponder Key
                                    </a>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDropdownSelect5('bg-warning')
                                        }}
                                    >
                                        Yellow/Remote
                                    </a>
                                </div>
                                <input type="hidden" name="dropdownValue" value={selectedValue5} />
                            </div>
                            <div className="row m-0 p-0 mt-3 mb-2">
                                <div className="col-sm-4">
                                    <div className="selected-instructions">
                                        {selectedInstructions.map((item) => (
                                            <div key={item._id} className="mt-2">
                                                {item.name}
                                                <span className="ms-1 text-danger" onClick={() => removeInstruction(item._id)}><CiCircleRemove style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }} /></span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="selected-instructions">
                                        {instructionRekeySaved.map((item) => (
                                            <div key={item._id} className="mt-2">
                                                {item.name}
                                                <span className="ms-1 text-danger" onClick={() => removeInstructionRekey(item._id)}><CiCircleRemove style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }} /></span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="selected-instructions">
                                        {instructionHardwareSaved.map((item) => (
                                            <div key={item._id} className="mt-2">
                                                {item.name}
                                                <span className="ms-1 text-danger" onClick={() => removeInstructionHardware(item._id)}><CiCircleRemove style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }} /></span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3 mb-2">
                                <div className="col-sm-4">
                                    <label htmlFor="instruction mb-1">EEPROM Instructions:</label>
                                    <select
                                        className="form-control"
                                        id="instruction"
                                        name="instruction"
                                        value=""
                                        onChange={handleInstructionChange}
                                    >
                                        <option value=''>Select EEPROM Instructions</option>
                                        {instructionSaved.map((item) => (
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="instructionRekey mb-1">Rekey Instructions:</label>
                                    <select
                                        className="form-control"
                                        id="instructionRekey"
                                        name="instructionRekey"
                                        value=""
                                        onChange={handleInstructionRekeyChange}
                                    >
                                        <option value=''>Select Rekey Instructions</option>
                                        {instructionRekey && instructionRekey.map((item) => (
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="instructionHardware mb-1">Hardware Install/Repair Instructions:</label>
                                    <select
                                        className="form-control"
                                        id="instructionHardware"
                                        name="instructionHardware"
                                        value=""
                                        onChange={handleInstructionHardwareChange}
                                    >
                                        <option value=''>Select Hardware Instructions</option>
                                        {instructionHardware && instructionHardware.map((item) => (
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3 mb-2">
                                <div className="col-sm-4">
                                    <div className="selected-instructions">
                                        {instructionDiagnoseSaved.map((item) => (
                                            <div key={item._id} className="mt-2">
                                                {item.name}
                                                <span className="ms-1 text-danger" onClick={() => removeInstructionDiagnose(item._id)}><CiCircleRemove style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }} /></span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="selected-instructions">
                                        {instructionOtherSaved.map((item) => (
                                            <div key={item._id} className="mt-2">
                                                {item.name}
                                                <span className="ms-1 text-danger" onClick={() => removeInstructionOther(item._id)}><CiCircleRemove style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }} /></span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3 mb-2">
                                <div className="col-sm-4">
                                    <label htmlFor="instructionDiagnose mb-1">Diagnose Instructions:</label>
                                    <select
                                        className="form-control"
                                        id="instructionDiagnose"
                                        name="instructionDiagnose"
                                        value=''
                                        onChange={handleInstructionDiagnoseChange}
                                    >
                                        <option value=''>Select Diagnose Instructions</option>
                                        {instructionDiagnose && instructionDiagnose.map((item) => (
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="instructionOther mb-1">Other Instructions:</label>
                                    <select
                                        className="form-control"
                                        id="instructionOther"
                                        name="instructionOther"
                                        value=""
                                        onChange={handleInstructionOtherChange}
                                    >
                                        <option value=''>Select Other Instructions</option>
                                        {instructionOther && instructionOther.map((item) => (
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 mt-4">
                                <ReactQuill
                                    value={content1}
                                    onChange={handleEditorChange1}
                                    placeholder="Write something..."
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'],
                                            ['blockquote', 'code-block'],
                                            [{ 'header': 1 }, { 'header': 2 }],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            [{ 'script': 'sub' }, { 'script': 'super' }],
                                            [{ 'indent': '-1' }, { 'indent': '+1' }],
                                            [{ 'direction': 'rtl' }],
                                            [{ 'size': ['small', false, 'large', 'huge'] }],
                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                            [{ 'color': [] }, { 'background': [] }],
                                            [{ 'font': [] }],
                                            [{ 'align': [] }],
                                            ['clean']
                                        ],
                                    }}
                                    formats={{
                                        clear: false,
                                    }}
                                    preserveWhitespace={false}
                                    normalizeWhitespace={false}
                                />
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <label htmlFor="lishi">Lishi:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="lishi"
                                        name="lishi"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="testBlade">Test Blade:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="testBlade"
                                        name="testBlade"
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <label htmlFor="doorCode">Door Code Location:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="doorCode"
                                        name="doorCode"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="keyCode">Key Code Location:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="keyCode"
                                        name="keyCode"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="codeSeries">Code Series:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="codeSeries"
                                        name="codeSeries"
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <label htmlFor="keyInfo">Mechanical Key Info:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="keyInfo"
                                        name="keyInfo"
                                    />
                                </div>
                            </div>
                            <div className="row mt-5">
                                <form className="row d-flex justify-content-start" encType="multipart/form-data">
                                    <div className="row m-0 ps-0 pe-0">
                                        <div className="col-sm-2 m-0 p-0 ">
                                            <label htmlFor="fileInputLocksmith" className="btn btn-outline-primary btn-sm">Upload Images</label>
                                            <input
                                                id="fileInputLocksmith"
                                                type="file"
                                                accept=".png, .jpg, .jpeg"
                                                name="file"
                                                style={{ display: "none" }}
                                                onChange={handleImagePhoto}
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div className="row w-100 justify-content-start mt-5 mb-5">
                                    {imageDescriptions.map((item, index) => (
                                        <div className="ms-3 card m-0 p-0 position-relative" style={{ width: '20rem' }} key={index}>
                                            <img src={imagePreviews[index]} alt={`Preview ${index}`} style={{ height: '300px' }} className="card-img-top" />

                                            <div className="card-body w-100 mt-2">
                                                <label htmlFor="textDescrip" className="mb-2">Image Description:</label>
                                                <textarea
                                                    id="textDescrip"
                                                    className="w-100"
                                                    placeholder="Enter image description"
                                                    value={item.description}
                                                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                                />
                                            </div>

                                            <div className="image-overlay" onClick={() => handleDelete(imageDescriptions[0].id._id)}>X</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="text-center pt-3">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINLOCKSMITHINFO} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createLocksmithInfoResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateLocksmithInfoPageComponent;