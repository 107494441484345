import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";

const EditJobSourceComponent = ({
    fetchJobSource,
    updateJobSourceApiRequest,
}) => {
    const [validated, setValidated] = useState(false);
    const [jobSource, setJobSource] = useState([]);
    const auth = useSelector(state => state.auth)
    const [updateJobSourceResponseState, setUpdateJobSourceResponseState] = useState({
        message: "",
        error: "",
    });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchJobSource(id)
            .then((data) => {
                setJobSource(data);
            })
            .catch((er) => console.log(er));
    }, [id, fetchJobSource]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            company: auth.user.company,
        };

        if (event.currentTarget.checkValidity() === true) {
            updateJobSourceApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "Job source updated") navigate(`${paths.DISPATCHJOBSOURCE}`);
                })
                .catch((er) =>
                    setUpdateJobSourceResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    return (
        <div className="pt-5 mt-3">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-center">Edit Job Source</h1>
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="row mt-4 g-3">
                            <div className="row mt-4">
                                <div className="col">
                                    <label htmlFor="name">Name:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        defaultValue={jobSource.name}
                                    />
                                </div>
                            </div>
                            <div className="text-center mt-3">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.DISPATCHJOBSOURCE} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {updateJobSourceResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditJobSourceComponent;