import axios from "axios";
import InventoryIncreaseComponent from "./components/inventoryIncreaseComponent";

const fetchInventory = async (abctrl) => {
    const { data } = await axios.get("/api/inventory/all", { withCredentials: true });
    return data
}
const updateInventoryStockApiRequest = async (inventoryId, formInputs) => {
    const { data } = await axios.put(`/api/inventory/admin/status/${inventoryId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const fetchTechInfo = async (techId) => {
    const { data } = await axios.get(`/api/techInfo/graph`, { withCredentials: true });
    return data
}
const fetchUsers = async () => {
    const { data } = await axios.get(`/api/users/inventory/`, { withCredentials: true });
    return data
}
const fetchLocation = async (abctrl) => {
    const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
    return data
}
const fetchRequest = async (abctrl) => {
    const { data } = await axios.get("/api/request/", { withCredentials: true });
    return data
}

const InventoryIncreasePage = () => {

    return <InventoryIncreaseComponent
        fetchTechInfo={fetchTechInfo}
        fetchRequest={fetchRequest }
        fetchUsers={fetchUsers}
        fetchLocation={fetchLocation}
        updateInventoryStockApiRequest={updateInventoryStockApiRequest}
        fetchInventory={fetchInventory}
    />;
};

export default InventoryIncreasePage;