import axios from "axios";
import InventoryRequestStatusComponent from "./components/inventoryRequestStatusComponent";

const fetchInventory = async (abctrl) => {
    const { data } = await axios.get("/api/inventory/all", { withCredentials: true });
    return data
}
const fetchRequest = async (abctrl) => {
    const { data } = await axios.get("/api/request/", { withCredentials: true });
    return data
}
const fetchTechInfo = async (techId) => {
    const { data } = await axios.get(`/api/techInfo/graph`, { withCredentials: true });
    return data
}
const fetchUsers = async () => {
    const { data } = await axios.get(`/api/users/inventory/`, { withCredentials: true });
    return data
}
const fetchLocation = async (abctrl) => {
    const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
    return data
}
const updateRequestApiRequest = async (requestId, formInputs) => {
    const { data } = await axios.put(`/api/request/post/${requestId}`, { ...formInputs }, { withCredentials: true });
    return data;
};
const updateRequestApiRequestDeny = async (requestId, formInputs) => {
    const { data } = await axios.put(`/api/request/post/deny/${requestId}`, { ...formInputs }, { withCredentials: true });
    return data;
};
const InventoryRequestStatusPage = () => {

    return <InventoryRequestStatusComponent
        fetchRequest={fetchRequest}
        updateRequestApiRequestDeny={updateRequestApiRequestDeny }
        updateRequestApiRequest={updateRequestApiRequest}
        fetchInventory={fetchInventory}
        fetchTechInfo={fetchTechInfo}
        fetchUsers={fetchUsers}
        fetchLocation={fetchLocation}
    />;
};

export default InventoryRequestStatusPage;
