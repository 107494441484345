import axios from "axios";
import AdminLocationComponent from "./components/adminLocationComponent";

const fetchLocation = async (abctrl) => {
    const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
    return data
}

const deleteLocation = async (locationId) => {
    const { data } = await axios.delete(`/api/inventoryLocation/${locationId}`, { withCredentials: true });
    return data
}

const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/admin", { withCredentials: true });
    return data
}

const fetchUsers = async (abctrl) => {
    const { data } = await axios.get("/api/users", { withCredentials: true });
    return data
}

const AdminLocationPage = () => {
    return <AdminLocationComponent
        fetchTechInfo={fetchTechInfo}
        fetchUsers={fetchUsers}
        fetchLocation={fetchLocation}
        deleteLocation={deleteLocation} />;
};

export default AdminLocationPage;
