import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import imageBackground from '../../../components/assets/imageBackground.svg';
import { AiOutlinePlus } from 'react-icons/ai';
import '../../../components/assets/estimate.css';
import EstimateItemPanelComponent from '../../../components/dispatch/estimateJobItemComponent';
import { useParams, Link, useNavigate } from "react-router-dom";
import EstimateDescriptionComponent from '../../../components/dispatch/estimateDescriptionComponent';
import { FaRegThumbsUp } from 'react-icons/fa';
import { PiCaretDownBold } from 'react-icons/pi';
import { BsSend } from 'react-icons/bs';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';
import EstimateNotesComponent from '../../../components/dispatch/estimateNotesComponent';
import EstimateFileUploadComponent from '../../../components/dispatch/estimateFileUploadComponent';
import SignaturesComponent from '../../../components/dispatch/signaturesComponent';
import { BsPen } from 'react-icons/bs';
import { HiOutlineWrench } from 'react-icons/hi2';
import { BsTrash } from 'react-icons/bs';
import DeleteJobItemPopModal from '../../../components/dispatch/deleteJobItemPopComponent';
import 'jspdf-autotable';
import { BsDownload } from 'react-icons/bs';
import EmailPopupComponent from '../../../components/dispatch/emailPopupComponent';
import { AiOutlineEye } from 'react-icons/ai';
import { EstimateGeneratePDF } from '../../../components/dispatch/generateEstimatePdfComponent';
import { EstimateSendGeneratePDF } from '../../../components/dispatch/generateEstimatePdfSendComponent';
import EstimatePaymentTabComponent from '../../../components/dispatch/estimarePaymentTabComponent';

const EstimateComponent = ({ fetchPayments, fetchPaymentAll, fetchDispatch, fetchUploadsAll, updateEstimateSign, deletePayment, updateEstimateTotal, updateEstimateDeposit, updateEstimateNotes, deleteEstimate, fetchUploading, deleteJobItem, updateJobItemsApiRequest, updateDispatchItemApiRequest, fetchUploads, createJobItemApiRequest, updateEstimateUpload, fetchCustomer, updateEstimateStatus, fetchCompany, fetchEstimates, updateEstimateDescription, getJobTaxById, fetchJobItem, fetchJobTax, updateEstimateItem, updateEstimateDiscount, updateEstimateTax, }) => {
    const auth = useSelector(state => state.auth)
    const [estimate, setEstimate] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [dispatching, setDispatching] = useState([]);
    const { id } = useParams();
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [description, setDescription] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertEmail, setShowAlertEmail] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [showAlert1, setShowAlert1] = useState(false);
    const [newFile, setNewFile] = useState(null);
    const [uploads, setUploads] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState('');
    const [showNotes, setShowNotes] = useState(false);
    const [showSignAlert, setShowSignAlert] = useState(false);
    const [showAlertDelete, setShowAlertDelete] = useState(false);
    const navigate = useNavigate();
    const [jobItem, setJobItem] = useState([]);
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [signatureData, setSignatureData] = useState([{ signatureName: "", signature: "", dateTime: null }]);
    const [jobLoading, setJobLoading] = useState(false);
    const [payLoading, setPayLoading] = useState(false);
    const [uploadedLogo, setUploadedLogo] = useState(null);

    const [ setCreateEstimateResponseState] =
        useState({
            message: "",
            error: ""
        });

    useEffect(() => {
        const abctrl = new AbortController();

        fetchEstimates(id)
            .then((res) => {
                setEstimate(res);
                setDescription(res.description);
                setSelectedOption(res.status);
                setNotes(res.notes);
                setSignatureData(res.signature);

                fetchJobItem()
                    .then((data) => {
                        const filterJobItem = data.filter((item) => item.estimateId === id);

                        setJobItem(filterJobItem);
                        setLoading(false);
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );
                fetchDispatch(res.jobId)
                    .then((data) => {
                        setDispatching(data);
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );
                if (!res.uploadId) {
                    fetchCustomer(res.customer)
                        .then((data) => {
                            setCustomer(data);
                        })
                        .catch((er) => console.log(er));
                } else {
                    fetchCustomer(res.customer)
                        .then((data) => {
                            setCustomer(data);
                        })
                        .catch((er) => console.log(er));
                    fetchUploads(res.uploadId)
                        .then((res) => {
                            setUploads(res);
                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );
                }
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        fetchUploadsAll(abctrl)
            .then((res) => {
                const filteredUploads = res.filter((item) => item.estimate === id);
                const filterUpload = filteredUploads.find((item) => item.fileName === `estimate+${customer.name}+${customer.lastName}.pdf`);
                const filteredUploadsall = res.filter((item) => auth.user.company === item.company);
                const filteredUploadsFin = filteredUploadsall.find((item) => item.companyLogo === true);

                if (filteredUploadsFin) {
                    setUploadedLogo(filteredUploadsFin);
                } else {
                    setUploadedLogo(null);
                }

                if (filterUpload) {
                    handleDeletePDF(filterUpload._id);
                }
                setLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        fetchCompany()
            .then((res) => {
                const filteredCompany = res.find((item) => item._id === auth.user.company);

                setCompanies(filteredCompany);
                setLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        return () => abctrl.abort();
    }, [fetchEstimates, id, fetchUploadsAll, fetchCompany, fetchJobItem, fetchCustomer, fetchUploads, customer.name, customer.lastName, auth.user.company, fetchDispatch]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (loading) {
            fetchEstimates(id)
                .then((res) => {
                    setEstimate(res);
                    setDescription(res.description);
                    setSelectedOption(res.status);
                    setNotes(res.notes);
                    setSignatureData(res.signature);

                    fetchJobItem()
                        .then((data) => {
                            const filterJobItem = data.filter((item) => item.estimateId === id);

                            setJobItem(filterJobItem);
                            setLoading(false);
                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );

                    fetchDispatch(res.jobId)
                        .then((data) => {

                            setDispatching(data);
                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );

                    if (!res.uploadId) {
                        fetchCustomer(res.customer)
                            .then((data) => {
                                setCustomer(data);
                            })
                            .catch((er) => console.log(er));
                    } else {
                        fetchCustomer(res.customer)
                            .then((data) => {
                                setCustomer(data);
                            })
                            .catch((er) => console.log(er));
                        fetchUploads(res.uploadId)
                            .then((res) => {
                                setUploads(res);
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                    }
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            fetchUploadsAll(abctrl)
                .then((res) => {
                    const filteredUploads = res.filter((item) => item.estimate === id);
                    const filterUpload = filteredUploads.find((item) => item.fileName === `estimate+${customer.name}+${customer.lastName}.pdf`);
                    const filteredUploadsall = res.filter((item) => auth.user.company === item.company);
                    const filteredUploadsFin = filteredUploadsall.find((item) => item.companyLogo === true);

                    if (filteredUploadsFin) {
                        setUploadedLogo(filteredUploadsFin);
                    } else {
                        setUploadedLogo(null);
                    }

                    if (filterUpload) {
                        handleDeletePDF(filterUpload._id);
                    }
                    setLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            fetchCompany()
                .then((res) => {
                    const filteredCompany = res.find((item) => item._id === auth.user.company);

                    setCompanies(filteredCompany);
                    setLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [fetchEstimates, id, fetchUploadsAll, fetchCompany, loading, fetchJobItem, fetchCustomer, fetchUploads, customer.name, customer.lastName, auth.user.company, fetchDispatch]);

    const handleSubmitDescription = (inputValue) => {
        setDescription(inputValue);

        setShowDescriptionModal(false);

        const formInputsDesc = {
            description: inputValue,
            company: auth.user.company,
        };

        updateEstimateDescription(id, formInputsDesc)
            .then((data) => {
                if (data.message === "estimate updated") {
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                }
            })
            .catch((er) => {
                setCreateEstimateResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const handleCancelDescription = () => {
        setShowDescriptionModal(false);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handlePhoto = (e) => {
        setNewFile(e.target.files[0]);
    }

    useEffect(() => {
        const uploadFileToDatabase = () => {
            if (newFile) {
                const companyId = auth.user.company;
                const jobId = estimate.jobId;
                const estimateId = id;
                const estimateImage = true;
                const formData = new FormData();
                formData.append('file', newFile);
                formData.append('company', companyId);
                formData.append('job', jobId);
                formData.append('estimate', estimateId);
                formData.append('estimateImage', estimateImage);

                const formInputsUpload = {
                    uploadId: '',
                };

                axios.post('/api/uploads/add', formData)
                    .then((res) => {
                        if (res.data.message === "File Added") {
                            const newUploadId = res.data._id;
                            const formInputUploadId = {
                                ...formInputsUpload,
                                uploadId: newUploadId,
                            };
                            updateEstimateUpload(id, formInputUploadId)
                                .then((data) => {
                                    if (data.message === "estimate updated") {
                                        setLoading(true);
                                        setShowAlert(true);
                                        setTimeout(() => {
                                            setShowAlert(false);
                                        }, 3000);
                                    }
                                })
                                .catch((er) => {
                                    setCreateEstimateResponseState({
                                        error: er.response.data.message
                                            ? er.response.data.message
                                            : er.response.data
                                    });
                                });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }

        if (newFile) {
            uploadFileToDatabase();
        }
    }, [estimate.jobId, id, newFile, updateEstimateUpload, auth.user.company, setCreateEstimateResponseState]);

    const subtotal = jobItem.reduce((acc, row) => {
        if (!row.optional) {
            const amount = parseFloat(row.quantity * row.price);
            return acc + amount;
        }
        return acc;
    }, 0);

    const handleSelectChange = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedOption(newSelectedOption);

        const formInputsStat = {
            status: newSelectedOption,
        };

        updateEstimateStatus(id, formInputsStat)
            .then((data) => {
                if (data.message === "estimate updated") {
                    setShowAlert1(true);
                    setTimeout(() => {
                        setShowAlert1(false);
                    }, 3000);
                }
            })
            .catch((er) => {
                setCreateEstimateResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });

    };

    const handleDelete = (uploadId) => {

        const formInputsUpload = {
            uploadId: null,
        };

        axios.delete(`/api/uploads/${uploadId}`)
            .then((res) => {
                updateEstimateUpload(id, formInputsUpload)
                    .then((data) => {
                        if (data.message === "estimate updated") {
                            setLoading(true);
                            setUploads('');
                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                            }, 3000);
                        }
                    })
                    .catch((er) => {
                        setCreateEstimateResponseState({
                            error: er.response.data.message
                                ? er.response.data.message
                                : er.response.data
                        });
                    });
            })
            .catch((error) => {
                console.error('Error deleting file:', error);
            });
    };

    const handleDeletePDF = (uploadId) => {

        axios.delete(`/api/uploads/${uploadId}`)
            .then((res) => {
                setLoading(true);
                setUploads('');
            })
            .catch((error) => {
                console.error('Error deleting file:', error);
            });
    };

    const deleteEstimateClick = async () => {
        setShowAlertDelete(true);
    };
    const handleCancelRemoveEstimate = async () => {
        setShowAlertDelete(false);
    };

    const deleteEstimateHandler = async () => {
        const jobId = estimate.jobId;
        const data = await deleteEstimate(id);
        if (data.message === 'Estimate deleted successfully') {
            navigate(`/dispatch/job/${jobId}`)

        }
    };

    function getBase64FromBlob(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    const handleGeneratePDF = async () => {
        try {
            const data = await fetchJobTax();
            const filterJobTax = data.find((item) => item._id === estimate.tax);

            if (!filterJobTax) {
                console.error("Job tax not found for estimate.tax:", estimate.tax);
                return;
            }

            let imgData = null;
            if (uploadedLogo) {
                try {
                    const response = await axios.get(`/api/uploads/download/${uploadedLogo.file}`, { responseType: 'blob' });
                    const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
                    imgData = await getBase64FromBlob(blob);
                } catch (error) {
                    console.error('Error fetching image:', error);
                    // Optionally handle the error (e.g., show a placeholder image)
                }
            }

            EstimateGeneratePDF(
                companies,
                imgData,
                formatDateTime,
                estimate,
                filterJobTax,
                customer,
                jobItem,
                signatureData,
            );
        } catch (error) {
            console.error("Error fetching or processing job tax:", error);
        }
    };

    const handleGeneratePDFSend = async () => {
        try {
            const data = await fetchJobTax();
            const filterJobTax = data.find((item) => item._id === estimate.tax);

            if (!filterJobTax) {
                console.error("Job tax not found for estimate.tax:", estimate.tax);
                return;
            }

            let imgData = null;
            if (uploadedLogo) {
                try {
                    const response = await axios.get(`/api/uploads/download/${uploadedLogo.file}`, { responseType: 'blob' });
                    const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
                    imgData = await getBase64FromBlob(blob);
                } catch (error) {
                    console.error('Error fetching image:', error);
                    // Optionally handle the error (e.g., show a placeholder image)
                }
            }

            EstimateSendGeneratePDF(
                uploadFileToDatabaseEstimate,
                companies,
                imgData,
                formatDateTime,
                estimate,
                filterJobTax,
                customer,
                jobItem,
                signatureData,
            );
            setShowEmailPopup(true);
        } catch (error) {
            console.error("Error fetching or processing job tax:", error);
        }
    };

    function formatDateTime(dateTime) {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };

        return new Date(dateTime).toLocaleDateString(undefined, options);
    }

    const uploadFileToDatabaseEstimate = (pdfBlob) => {

        const companyId = auth.user.company;
        const jobId = estimate.jobId;

        const formData = new FormData();
        formData.append('file', pdfBlob, `estimate+${customer.name}+${customer.lastName}.pdf`);
        formData.append('company', companyId);
        formData.append('job', jobId);

        axios.post('/api/uploads/add', formData)
            .then((res) => {
                if (res.data.message === "File Added") {
                    setSelectedFile(res.data.file);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleSendEmail = (email, subject, description, cc) => {
        if (!selectedFile) {
            console.log('No file selected for email attachment.');
            return;
        }
        const blob = new Blob([selectedFile]);

        const reader = new FileReader();
        reader.onload = (event) => {
            const fileContent = event.target.result.split(',')[1];

            const emailData = {
                to: email,
                subject: subject,
                estimateId: id,
                cc: cc,
                text: description,
                fileName: `estimate+${customer.name}+${customer.lastName}.pdf`,
                attachment: {
                    filename: selectedFile.file,
                    content: fileContent,
                },
            };

            axios.post('/api/email/send-email', emailData)
                .then((response) => {

                    updateEstimateStatus(id, { status: 'Pending' })
                        .then((data) => {
                            if (data.message === "estimate updated") {
                                axios.delete(`/api/uploads/${selectedFile._id}`)
                                    .then((res) => {
                                        setLoading(true);
                                        setShowAlertEmail(true);
                                        setTimeout(() => {
                                            setShowAlertEmail(false);
                                        }, 3000);
                                        setUploads('');
                                    })
                                    .catch((error) => {
                                        console.error('Error deleting file:', error);
                                    });

                            }
                        })
                        .catch((er) => {
                            setCreateEstimateResponseState({
                                error: er.response.data.message
                                    ? er.response.data.message
                                    : er.response.data
                            });
                        });
                })
                .catch((error) => {
                    console.log('Error sending email:', error);
                });
        };

        reader.readAsDataURL(blob);
    };

    return (
        <div className="pt-5 mt-3">
            <div className="container mt-5 mb-5 pb-5">
                {showEmailPopup && (
                    <EmailPopupComponent
                        estimate={estimate}
                        customer={customer}
                        companies={companies}
                        onClose={() => setShowEmailPopup(false)}
                        onSend={handleSendEmail}
                    />
                )}
                {showAlert && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Estimate Updated Successfully
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlertEmail && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Estimate Emailed Successfully
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlertDelete && (
                    <DeleteJobItemPopModal
                        onConfirm={deleteEstimateHandler}
                        onCancel={handleCancelRemoveEstimate}
                    />
                )}
                {showDescriptionModal && (
                    <EstimateDescriptionComponent
                        description={description}
                        onCancel={handleCancelDescription}
                        onConfirm={handleSubmitDescription}
                    />
                )}
                {showAlert1 && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Status Updated
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                <div className="row d-flex justify-content-between">
                    <div className="col-sm-1 m-0 p-0">
                        <ul className="m-0 p-0 text-center">
                            <li className="headerEstimateTextTitleLI mt-3">Estimate {estimate.count}</li>
                        </ul>
                    </div>
                    <div className="col-sm-4 m-0 p-0">
                        <div className="row d-flex justify-content-end w-100">
                            <div className="col-sm-3 m-0 p-0">
                                <div className="btngroupEstimate">
                                    <button id="btnGroupDrop1" type="button" className="btn borderWhite btngroupEstimate dropdowntoggleEstimate" data-bs-toggle="dropdown" aria-expanded="false">
                                        <PiCaretDownBold />  Actions
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-lg-end mt-2" aria-labelledby="btnGroupDrop1">
                                        <li><Link className="dropdown-item" to={`/dispatch/job/${estimate.jobId}`}><HiOutlineWrench className="imgIconSM me-2" /> View Job</Link></li>
                                        <li><Link className="dropdown-item" target="_blank" to={`/clientPortal/estimate/${id}`}><AiOutlineEye className="me-2 imgIconSM" />Preview</Link></li>
                                        <li><Link className="dropdown-item" type="button" onClick={() => handleGeneratePDF()}><BsDownload className="me-2 imgIconSM" /> Download</Link></li>
                                        <li><Link className="dropdown-item dropdownCurser" type="button" onClick={() => setShowSignAlert(true)}><BsPen className="me-2 imgIconSM" /> Sign</Link></li>
                                        <li><Link className="dropdown-item" type="button" onClick={() => deleteEstimateClick()}><BsTrash className="imgIconSM me-2" /> Delete</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <button className="btn btn-outline-primary estimateSendButton ms-3"
                                    onClick={() => {
                                        handleGeneratePDFSend();
                                    }}
                                >
                                    <BsSend className="me-1" />
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="estimateBox1">
                    <div className="estimateBoxFirst">
                        <div className="imageBox">
                            {uploads.length === 0 ? (
                                <img className="imageBackground" src={imageBackground} alt="Background" />
                            ) : (
                                <img className="imageBackground" src={uploads.imageUrl} alt={`Uploaded file ${uploads.fileName}`} />
                            )}
                            {uploads.length === 0 ? (
                                <>
                                    <label htmlFor="fileInput">
                                        <div className="plusLogo">
                                            <AiOutlinePlus className="plusLogoImage" />
                                        </div>
                                    </label>
                                    <form className="row" encType="multipart/form-data">
                                        <input
                                            id="fileInput"
                                            type="file"
                                            accept=".png, .jpg, .jpeg, .pdf"
                                            name="file"
                                            style={{ display: "none" }}
                                            onChange={handlePhoto}
                                        />
                                    </form>
                                </>
                            ) : (
                                <div className="plusLogoX" onClick={() => handleDelete(uploads._id)}>
                                    <AiOutlineClose className="plusLogoImage" />
                                </div>
                            )}
                        </div>
                        <hr className="estimateHRLine" />
                        <div className="descriptionBox">
                            <span className="descriptionText">Description</span>
                            {description ? (
                                <div className="imageBox">
                                    <div className="clientRenderText mb-3">
                                        {description}
                                    </div>
                                    <br />
                                    <div className="addPlus1" onClick={() => setShowDescriptionModal(true)}>
                                        (Edit)
                                    </div>
                                </div>
                            ) : (
                                <div className="imageBox">
                                    <hr className="hrLines1" />
                                    <hr className="hrLines1" />
                                    <hr className="hrLines2" />
                                    <div className="addPlus" onClick={() => setShowDescriptionModal(true)}>
                                        (+ Add)
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="estimateBoxSecond">
                        <div className="estimateBoxSecondBox1">
                            <div className="clientText">
                                Client Details
                            </div>
                            <div className="clientRenderText">
                                {customer.name} {customer.lastName}
                                {customer.companyClient && (
                                    <>
                                        <br />
                                        {customer.companyClient}
                                    </>
                                )}
                                <br />
                                {customer.phone}
                                {customer.email && (
                                    <>
                                        <br />
                                        {customer.email}
                                    </>
                                )}
                            </div>
                        </div>
                        <hr className="estimateHRLine" />
                        <div className="estimateBoxSecondBox1">
                            <div className="clientText">
                                Service Address
                            </div>
                            <div className="clientRenderText">
                                {customer.address}
                                <br />
                                {customer.city}, {customer.state} {customer.zip}
                            </div>
                        </div>
                        <hr className="estimateHRLine" />
                        <div className="estimateDetailsBox">
                            <div className="statusText">
                                <div className="statusText2">
                                    Status:
                                </div>
                                <div className="statusDrop">
                                    <div className="statusDropDown">
                                        <select
                                            id="selectStatus"
                                            className="statusBorder"
                                            onChange={handleSelectChange}
                                            value={selectedOption}
                                        >
                                            <option value="Unsent">Unsent</option>
                                            <option value="Pending">Pending</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Denied">Denied</option>
                                            <option value="Won">Won</option>
                                            <option value="Archived">Archived</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="estimateNoText">
                                <p>
                                    Estimate No.
                                </p>
                                <p className="estimateNoTextNum ps-2">
                                    {dispatching.count}-{estimate.count}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <EstimateItemPanelComponent setPayLoading={setPayLoading} estLoading={loading} customer={customer} fetchUploadsAll={fetchUploadsAll} updateEstimateTotal={updateEstimateTotal} updateEstimateDeposit={updateEstimateDeposit} updateDispatchItemApiRequest={updateDispatchItemApiRequest} deleteJobItem={deleteJobItem} updateJobItemsApiRequest={updateJobItemsApiRequest} createJobItemApiRequest={createJobItemApiRequest} getJobTaxById={getJobTaxById} id={id} estimate={estimate} fetchEstimates={fetchEstimates} fetchJobItem={fetchJobItem} fetchJobTax={fetchJobTax} updateEstimateItem={updateEstimateItem} updateEstimateDiscount={updateEstimateDiscount} updateEstimateTax={updateEstimateTax} />
                </div>
                <div className="notesEstimateWrapper mt-5">
                    <div className="notesWrapper">
                        <div className="notesTitle jobSectionSpan">Notes</div>
                        {showNotes ? (
                            <EstimateNotesComponent setNotes={setNotes} setLoading={setLoading} id={id} setShowNotes={setShowNotes} updateEstimateNotes={updateEstimateNotes} notes={notes} />
                        ) : notes ? (
                            <div className="notesModuleWrapper">
                                <div className="notesText">
                                    {notes}
                                </div>
                                <div className="estimateAddPlus11" onClick={() => setShowNotes(true)}>
                                    (+ Edit)
                                </div>
                            </div>
                        ) : (
                            <div className="notesModuleWrapper">
                                <div className="notesHRWrapper">
                                    <hr className="hrNotes1Module" />
                                    <hr className="hrNotes1Module" />
                                    <hr className="hrNotes2Module" />
                                    <hr className="hrNotes1Module" />
                                    <hr className="hrNotes2Module" />
                                    <div className="estimateAddPlus" onClick={() => setShowNotes(true)}>
                                        (+ Add)
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="depositsWrapper">
                        <div className="depositTitle jobSectionSpan">Payments</div>
                        <EstimatePaymentTabComponent setPayLoading={setPayLoading} setJobLoading={setJobLoading} payLoading={payLoading} fetchEstimates={fetchEstimates} estimate={estimate} subtotal={subtotal} estimateId={id} deletePayment={deletePayment} fetchDispatch={fetchDispatch} jobLoading={jobLoading} fetchPaymentAll={fetchPaymentAll} setCompanies={setCompanies} companies={companies} fetchPayments={fetchPayments} />
                    </div>
                </div>
                <div className="notesEstimateWrapper mt-5 mb-5 pb-5">
                    <div className="notesWrapper">
                        <EstimateFileUploadComponent estimate={estimate} fetchUploading={fetchUploading} id={id} />
                    </div>
                    <div className="depositsWrapper">
                        <SignaturesComponent setShowSignAlert={setShowSignAlert} showSignAlert={showSignAlert} fetchEstimates={fetchEstimates} customer={customer} updateEstimateSign={updateEstimateSign} />
                    </div>
                </div>
                {signatureData.length > 0 && (
                    <div className="row d-none mt-5 p-0 m-0 headerCard justify-content-center">
                        <div className="col-sm-12 p-0 m-0 ">
                            <table className="table table-striped" id="mytable">
                                <thead className="border-top border-bottom border-dark">
                                    <tr>
                                        <th scope="col" style={{ width: '55%' }}>Signature</th>
                                        <th scope="col" className="text-center">Signed By</th>
                                        <th scope="col" className="text-center">Signed</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-light">
                                    {signatureData.map((signatureItem, index) => (
                                        <tr key={index}>
                                            <td>
                                                <img src={signatureItem.signature} className="signatureImage" alt="Signature Data" />
                                            </td>
                                            <td className="text-center">{signatureItem.signatureName}</td>
                                            <td className="text-center">{formatDateTime(signatureItem.dateTime)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EstimateComponent;
