import axios from "axios";
import AdminCompanyComponent from "./components/adminCompanyComponent";

const fetchCompany = async (abctrl) => {
    const { data } = await axios.get("/api/company/admin", { withCredentials: true });
    return data
}

const deleteCompany = async (companyId) => {
    const { data } = await axios.delete(`/api/company/${companyId}`, { withCredentials: true });
    return data
}

const AdminCompanyPage = () => {

    return <AdminCompanyComponent fetchCompany={fetchCompany} deleteCompany={deleteCompany} />;
};

export default AdminCompanyPage;
