import axios from "axios";
import { useDispatch } from "react-redux";
import CreateCompanyPageComponent from "./components/adminNewCompanyComponent";

const createCompanyApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/company/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const AdminCreateCompanyPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateCompanyPageComponent
            createCompanyApiRequest={createCompanyApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreateCompanyPage;