import axios from "axios";
import { useDispatch } from "react-redux";
import CreateModelPageComponent from "./components/adminNewModelComponent";

const createModelApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/vehicleModel/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const fetchMake = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleMake/", { withCredentials: true })
    return data;
}

const AdminCreateModelPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateModelPageComponent
            fetchMake={fetchMake }
            createModelApiRequest={createModelApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreateModelPage;