import axios from "axios";
import UserForgotPasswordComponent from "./components/userForgotPasswordComponent";

const createRestPasswordRequest = async (email) => {
    const { data } = await axios.post('/api/users/forgotPassword', { email });
    return data;
};

const UserForgotPasswordPage = () => {

    return (
        <UserForgotPasswordComponent
            createRestPasswordRequest={createRestPasswordRequest}
        />
    );
};

export default UserForgotPasswordPage;
