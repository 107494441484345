import axios from "axios";
import ClientEstimateComponent from "./components/clientEstimateComponent";

const fetchEstimates = async (estimateId) => {
    const { data } = await axios.get(`/api/estimate/get/${estimateId}`, { withCredentials: true });
    return data;
};
const fetchDispatch = async (dispatchId) => {
    const { data } = await axios.get(`/api/dispatch/get-one/${dispatchId}`, { withCredentials: true });
    return data;
}
const fetchJobItem = async (abctrl) => {
    const { data } = await axios.get("/api/jobItem/estimate", { withCredentials: true });
    return data
}
const fetchJobTax = async () => {
    const { data } = await axios.get(`/api/jobTax/estimate`, { withCredentials: true });
    return data;
}
const fetchPaymentAll = async () => {
    const { data } = await axios.get(`/api/payment`, { withCredentials: true });
    return data
}
const fetchCompany = async (abctrl) => {
    const { data } = await axios.get("/api/company/estimate", { withCredentials: true });
    return data
}
const getJobTaxById = async (taxId) => {
    const { data } = await axios.get(`/api/jobTax/get-one/${taxId}`, { withCredentials: true });
    return data
}
const fetchCustomer = async (customerId) => {
    const { data } = await axios.get(`/api/customer/get-one/estimate/${customerId}`, { withCredentials: true });
    return data;
}
const updateDispatchItemApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/jobItem/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateEstimateItem = async (estimateId, formInputsEst) => {
    const { data } = await axios.put(`/api/estimate/item/${estimateId}`, { ...formInputsEst }, { withCredentials: true });
    return data;
}
const updateEstimateStatus = async (estimateId, formInputsStat) => {
    const { data } = await axios.put(`/api/estimate/status/${estimateId}`, { ...formInputsStat }, { withCredentials: true });
    return data;
}
const updateEstimateSign = async (estimateId, formInputsSign) => {
    const { data } = await axios.put(`/api/estimate/sign/${estimateId}`, { ...formInputsSign }, { withCredentials: true });
    return data;
}
const updateJobItemsApiRequest = async (jobItemsId, formInputs) => {
    const { data } = await axios.put(`/api/jobItem/quanity/${jobItemsId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const fetchUploadsAll = async (abctrl) => {
    const { data } = await axios.get("/api/uploads", { withCredentials: true });
    return data
}

const ClientEstimatePage = () => {

    return <ClientEstimateComponent
        fetchPaymentAll={fetchPaymentAll}
        updateJobItemsApiRequest={updateJobItemsApiRequest}
        updateEstimateSign={updateEstimateSign}
        updateEstimateStatus={updateEstimateStatus}
        updateDispatchItemApiRequest={updateDispatchItemApiRequest}
        fetchCustomer={fetchCustomer}
        fetchCompany={fetchCompany}
        updateEstimateItem={updateEstimateItem}
        getJobTaxById={getJobTaxById}
        fetchJobTax={fetchJobTax}
        fetchJobItem={fetchJobItem}
        fetchDispatch={fetchDispatch}
        fetchEstimates={fetchEstimates}
        fetchUploadsAll={fetchUploadsAll}
    />;
};

export default ClientEstimatePage;
