import React, { useState, useEffect } from "react";

const TechSelectNewComponent = ({ selectedTech, techPhoneNumber, setTechPhoneNumber, setSelectedTech, fetchUsers, fetchTechInfo }) => {
    const [users, setUsers] = useState([]);
    const [tech, setTech] = useState([]);
 
    useEffect(() => {
        const abctrl = new AbortController();
       
        fetchTechInfo(abctrl)
            .then((res) => {
                setTech(res);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        fetchUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                console.log("Error fetching users:", error);
            });

        return () => abctrl.abort();
    }, [fetchUsers, fetchTechInfo]);

    const getTechName = (techId) => {
        const techUser = users.find((user) => user._id === techId);
        return techUser ? techUser.name : "";
    };

    const handleTechChange = (event) => {
        const selectedValue = JSON.parse(event.target.value);

        const techUser = users.find((user) => user._id === selectedValue.user);

        setTechPhoneNumber((prevTechPhoneNumber) => [...prevTechPhoneNumber, techUser.phoneNumber]);

        setSelectedTech((prevSelectedTech) => [...prevSelectedTech, selectedValue._id]);
    };

    const handleRemoveTech = (techId) => {
        setSelectedTech((prevSelectedTech) => prevSelectedTech.filter(id => id !== techId));

        const techIndex = selectedTech.findIndex(id => id === techId);

        if (techIndex !== -1) {
            const updatedTechPhoneNumber = [...techPhoneNumber];

            updatedTechPhoneNumber.splice(techIndex, 1);

            setTechPhoneNumber(updatedTechPhoneNumber);
        }
    };
   
    const getSelectedTechInfo = () => {
        return selectedTech.map((selectedTechId) => {
            const techItem = tech.find((techItem) => techItem._id === selectedTechId);
            if (techItem) {
                const userName = getTechName(techItem.user);
                return { ...techItem, userName };
            }
            return null;
        }).filter(Boolean);
    };

    const availableTechOptions = tech.filter((techItem) => !selectedTech.includes(techItem._id));

    return (
        <div className="col-sm-12">
            <div className="selected-techs">
                {selectedTech.length > 0 && (
                    <div className="d-flex flex-wrap">
                        {getSelectedTechInfo().map((tech, index) => (
                            <div key={tech._id} className="selected-tech mt-2 me-2">
                                <span className="selected-user">
                                    {tech.userName}
                                    <button
                                        type="button"
                                        className="btn btn-link btn-remove-tech"
                                        onClick={() => handleRemoveTech(tech._id)}
                                    >
                                        x
                                    </button>
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <select
                className="form-select mt-3 text-dark"
                id="techs"
                name="techs"
                onChange={handleTechChange}
                defaultValue={""}
            >
                <option defaultValue="">Assign Team Members</option>
                {availableTechOptions.map((techItem) => (
                    <option key={techItem._id} value={JSON.stringify(techItem)}>
                        {getTechName(techItem.user)}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default TechSelectNewComponent;
