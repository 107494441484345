import axios from "axios";
import JobItemComponent from "./components/jobItemComponent";

const fetchJobItem = async (abctrl) => {
    const { data } = await axios.get("/api/jobItem/dispatch", { withCredentials: true });
    return data
}

const deleteJobItem = async (jobItemId) => {
    const { data } = await axios.delete(`/api/jobItem/dispatch/${jobItemId}`, { withCredentials: true });
    return data
}

const JobItemPage = () => {

    return <JobItemComponent fetchJobItem={fetchJobItem} deleteJobItem={deleteJobItem} />;
};

export default JobItemPage;
