import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import KeyCodeComponent from "../../components/keyCode/keycodeComponent";

const PinCodeComponent = ({ fetchVin, fetchVinPrice, sendRequestVin, fetchRequest, fetchRequestCompany }) => {
    const auth = useSelector(state => state.auth);
    const [vinChange, setVinChange] = useState('');
    const [vinMake, setVinMake] = useState('');
    const [vinModel, setVinModel] = useState('');
    const [vinModelYear, setVinModelYear] = useState('');
    const [vinError, setVinError] = useState('');
    const [vinPrice, setVinPrice] = useState('');
    const [vinLength, setVinLength] = useState(true);
    const [seven, setSeven] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [driversLicence, setDriversLicence] = useState(null);
    const [touchedDriversUpload, setTouchedDriversUpload] = useState(false);
    const [carRegistration, setCarRegistration] = useState(null);
    const [registrationUpload, setRegistrationUpload] = useState(false);
    const [licensePlate, setLicensePlate] = useState(null);
    const [licencePlateState, setLicencePlateState] = useState(false);
    const [customerFirstName, setCustomerFirstName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [customerName, setCustomerName] = useState(false);
    const [termsSelect, setTermsSelect] = useState(false);

    const { vin } = useParams();
    const navigate = useNavigate();

    const isTodayAvailable = (dayAvail) => {
        if (dayAvail === 'Monday - Sunday') return true;

        const today = new Date();
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const todayDay = days[today.getDay()];
        return dayAvail.includes(todayDay);
    };

    const isNowInTimeRange = (dayTime) => {
        if (dayTime === '24 Hours') return true;

        const now = new Date();
        const [startTime, endTime] = dayTime.split('-').map(t => t.trim());
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const [endHours, endMinutes] = endTime.split(':').map(Number);

        const startDate = new Date(now.setHours(startHours, startMinutes, 0, 0));
        const endDate = new Date(now.setHours(endHours, endMinutes, 0, 0));
        now.setTime(now.getTime());

        return now >= startDate && now <= endDate;
    };

    useEffect(() => {
        const abctrl = new AbortController();

        const toProperCase = (str) => {
            return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
            });
        };

        if (vin) {
            fetchVin(vin)
                .then((data) => {
                    const newData = data.Results;
                    const findMake = newData.find((item) => item.Variable === "Make");
                    const findModel = newData.find((item) => item.Variable === "Model");
                    const findModelYear = newData.find((item) => item.Variable === "Model Year");
                    const properCaseMake = toProperCase(findMake.Value);
                    const VINNumber = data.SearchCriteria.replace(/^VIN:/i, '');

                    setVinChange(VINNumber);
                    setVinMake(properCaseMake);
                    setVinModel(findModel.Value);
                    setVinModelYear(findModelYear.Value);

                    fetchVinPrice()
                        .then((data) => {
                            const findVinPricesss = data.find((item) => item.years.includes(parseInt(findModelYear.Value)) && (item.make === properCaseMake));

                            if (isTodayAvailable(findVinPricesss.dayAvail) && isNowInTimeRange(findVinPricesss.dayTime)) {
                                setSeven(true);
                            } else {
                                setSeven(false);
                            }
                            setVinPrice(findVinPricesss);
                            setVinLength(false);
                        })
                        .catch((er) =>
                            console.log({
                                error: er.response.data.message
                                    ? er.response.data.message
                                    : er.response.data,
                            })
                        );
                })
                .catch((er) =>
                    console.log({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }
        return () => abctrl.abort();
    }, [fetchVinPrice, fetchVin, vin]);

    const validateVin = (vin) => {
        const hasInvalidChars = /[OoiIqQ]/.test(vin);
        const toProperCase = (str) => {
            return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
            });
        };

        if (vin.length === 17) {
            if (hasInvalidChars) {
                setVinError('VIN cannot contain characters: O, I, or Q.');
                setVinLength(true);
            } else {
                fetchVin(vin)
                    .then((data) => {
                        const newData = data.Results;
                        const findMake = newData.find((item) => item.Variable === "Make");

                        if (findMake.Value === null) {
                            setVinError('The VIN does not return any vehicle manufacture.');
                            setVinLength(true);

                        } else {
                            const findModel = newData.find((item) => item.Variable === "Model");
                            const findModelYear = newData.find((item) => item.Variable === "Model Year");

                            const properCaseMake = toProperCase(findMake.Value);
                            const VINNumber = data.SearchCriteria.replace(/^VIN:/i, '');

                            setVinChange(VINNumber);
                            setVinMake(properCaseMake);
                            setVinModel(findModel.Value);
                            setVinModelYear(findModelYear.Value);

                            fetchVinPrice()
                                .then((data) => {
                                    const findVinPricesss = data.find((item) => item.years.includes(parseInt(findModelYear.Value)) && (item.make === properCaseMake));

                                    if (isTodayAvailable(findVinPricesss.dayAvail) && isNowInTimeRange(findVinPricesss.dayTime)) {
                                        setSeven(true);
                                    } else {
                                        setSeven(false);
                                    }
                                    setVinPrice(findVinPricesss);
                                })
                                .catch((er) =>
                                    console.log({
                                        error: er.response.data.message
                                            ? er.response.data.message
                                            : er.response.data,
                                    })
                                );
                        }
                    })
                    .catch((er) =>
                        console.log({
                            error: er.response.data.message
                                ? er.response.data.message
                                : er.response.data,
                        })
                    );
                setVinLength(false);
            }
        } else {
            setVinError('VIN must be exactly 17 characters long.');
            setVinLength(true);
        }
    };

    useEffect(() => {
        if (vinChange) {
            validateVin(vinChange);
        }
    }, [vinChange]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            const data = await fetchRequest(vinChange, auth.user.company);
            const findStatus = data.find((item) => item.status);

            if (findStatus && findStatus.status) {

                if (findStatus.status === 'Submitted') {
                    navigate(`/buy-pincode/purchase/${findStatus._id}`);
                } else if (findStatus.status === 'Paid') {
                    navigate(`/locksmith/vin/key-code/${findStatus._id}`);
                }

            } else {
                const formInputs = {
                    vin: vinChange,
                    make: vinMake,
                    model: vinModel,
                    year: vinModelYear,
                    vinPrice: vinPrice._id,
                    ownersFirstName: customerFirstName,
                    ownersLastName: customerLastName,
                    driversLicence: driversLicence,
                    carRegistration: carRegistration,
                    licensePlate: licensePlate,
                    status: 'Submitted',
                    terms: agreed,
                    company: auth.user.company,
                };

                const response = await sendRequestVin(formInputs);
                if (response.message === "request created") navigate(`/buy-pincode/purchase/${response._id}`);
            }
        } catch (error) {
            console.log({
                error: error.response?.data?.message || error.response?.data || error.message,
            });
        }
    };

    const handleVinChange = (event) => {
        const newVin = event.target.value;
        setVinChange(newVin);
    };

    const handleAgreementChange = (e) => {
        const agree = e.target.checked;

        if (agree === true) {
            setAgreed(e.target.checked);
            setTermsSelect(false);
        } else {
            setAgreed(e.target.checked);
            setTermsSelect(true);
        }
    };

    const handleFileChangeDrivers = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const companyId = auth.user.company;
            const imageFormData = new FormData();
            imageFormData.append("file", file);
            imageFormData.append("company", companyId);

            const response = await axios.post("/api/uploads/add", imageFormData);
            const fileResponse = response.data.file;

            if (fileResponse) {
                setDriversLicence(fileResponse);
                setTouchedDriversUpload(false);
            } else {
                setDriversLicence([])
                setTouchedDriversUpload(true);
            }
        } else {
            setDriversLicence([])
            setTouchedDriversUpload(true);
        }
    };

    const handleFileChangeRegistration = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const companyId = auth.user.company;
            const imageFormData = new FormData();
            imageFormData.append("file", file);
            imageFormData.append("company", companyId);

            const response = await axios.post("/api/uploads/add", imageFormData);
            const fileResponse = response.data.file;

            if (fileResponse) {
                setCarRegistration(fileResponse);
                setRegistrationUpload(false);
            } else {
                setCarRegistration([]);
                setRegistrationUpload(true);
            }
        } else {
            setCarRegistration([]);
            setRegistrationUpload(true);
        }
    };

    const handleFileChangePlate = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const companyId = auth.user.company;
            const imageFormData = new FormData();
            imageFormData.append("file", file);
            imageFormData.append("company", companyId);

            const response = await axios.post("/api/uploads/add", imageFormData);
            const fileResponse = response.data.file;

            if (fileResponse) {
                setLicensePlate(fileResponse);
                setLicencePlateState(false);
            } else {
                setLicensePlate([]);
                setLicencePlateState(true);
            }
        } else {
            setLicensePlate([]);
            setLicencePlateState(true);
        }
    };

    const handleCustomerFirstName = (e) => {
        const name = e.target.value;

        if (name.length > 0) {
            setCustomerName(false);
            setCustomerFirstName(name);
        } else {
            setCustomerName(true);
        }
    };

    const handleCustomerLastName = (e) => {
        const name = e.target.value;

        if (name.length > 0) {
            setCustomerName(false);
            setCustomerLastName(name);
        } else {
            setCustomerName(true);
        }
    };

    return (
        <>
            <div className="container-fluid margintop1 marginbottom1">
                <div className="row justify-content-center mt-5 mb-5 pb-5">
                    <div className="col-sm-8 col-md-6 col-lg-4">
                        <form onSubmit={handleSubmit} className="mt-5 mb-5">
                            <div className="form-floating mb-2">
                                <input
                                    type="text"
                                    className={`form-control ${vinLength && vinChange ? 'is-invalid' : ''}`}
                                    id="vin"
                                    name="vin"
                                    placeholder="Enter your VIN here...."
                                    value={vinChange}
                                    onChange={handleVinChange}
                                />
                                <label htmlFor="vin">VIN</label>
                            </div>
                            {(vinLength && vinChange) ? (
                                <p className="text-danger text-center mt-2 mb-3">
                                    {vinError}
                                </p>
                            ) : (
                                <>
                                    <div className="row">
                                        <div className="col">
                                            <span className="ms-3 mb-3">{vinMake} {vinModel} {vinModelYear}</span>
                                        </div>
                                    </div>
                                    {vinPrice && !vinLength ? (
                                        <>
                                            {vinPrice.price && vinPrice.dontSupport ? (
                                                <>
                                                    {seven ? (
                                                        <>
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <span className="ms-3 mb-3 fw-bold">
                                                                        Availability:
                                                                    </span>
                                                                    {vinPrice.dayAvail === 'Monday - Sunday' && vinPrice.dayTime === '24 Hours' ? (
                                                                        <span className="ms-2">
                                                                            24/7
                                                                        </span>
                                                                    ) : (
                                                                        <>
                                                                            <span className="ms-3 mb-3 fw-bold">
                                                                                Price:
                                                                            </span>
                                                                            <span className="ms-2">
                                                                                ${vinPrice.price ? vinPrice.price.toFixed(2) : ''}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className="col">
                                                                    <span className="ms-3 mb-3 fw-bold">Wait Time:</span><span className="ms-2">{vinPrice.time}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 mb-4">
                                                                <div className="col">
                                                                    <span className="ms-3 mb-3 fw-bold">Price:</span><span className="ms-2"> ${vinPrice.price ? vinPrice.price.toFixed(2) : ''}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <span className="ms-3 mb-3 fw-bold">Availability:</span><span className="ms-2">{vinPrice.dayAvail} {vinPrice.dayTime}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4 mb-4">
                                                                <div className="col">
                                                                    <span className="ms-3 mb-3 text-danger">Not currently available. Please check availability.</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 mb-4">
                                                                <div className="col">
                                                                    <span className="ms-3 mb-3 fw-bold">Price:</span><span className="ms-2"> ${vinPrice.price ? vinPrice.price.toFixed(2) : ''}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {vinPrice.extraDocs && (
                                                        <>
                                                            <div className="row m-0 p-0">
                                                                <div className="col m-0 p-0">
                                                                    <div className="form-floating mb-2">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${customerFirstName.length === 0 && customerName ? 'is-invalid' : ''}`}
                                                                            id="ownersFirstName"
                                                                            name="ownersFirstName"
                                                                            placeholder="Enter Customers First Name...."
                                                                            defaultValue={customerFirstName}
                                                                            onChange={(e) => handleCustomerFirstName(e)}
                                                                            onBlur={() => setCustomerName(true)}
                                                                            onClick={() => setCustomerName(true)}
                                                                        />
                                                                        <label htmlFor="ownersFirstName">Customers First Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col ms-2 m-0 p-0">
                                                                    <div className="col form-floating mb-2">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${customerLastName.length === 0 && customerName ? 'is-invalid' : ''}`}
                                                                            id="ownersLastName"
                                                                            name="ownersLastName"
                                                                            placeholder="Enter Customers Last Name...."
                                                                            defaultValue={customerLastName}
                                                                            onChange={(e) => handleCustomerLastName(e)}
                                                                            onBlur={() => setCustomerName(true)}
                                                                            onClick={() => setCustomerName(true)}
                                                                        />
                                                                        <label htmlFor="ownersLastName">Customers Last Name</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row m-0 p-0">
                                                                <div className="col m-0 p-0">
                                                                    <div className="mb-3 mt-3">
                                                                        <label className="form-label" htmlFor="fileInput">Upload Drivers Licence:</label>
                                                                        <input
                                                                            className={`form-control ${driversLicence && touchedDriversUpload ? 'is-invalid' : ''}`}
                                                                            type="file"
                                                                            id="fileInput"
                                                                            onBlur={() => setTouchedDriversUpload(true)}
                                                                            onChange={(event) => handleFileChangeDrivers(event)}
                                                                            onClick={() => setTouchedDriversUpload(true)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row m-0 p-0">
                                                                <div className="col m-0 p-0">
                                                                    <div className="mb-3 mt-3">
                                                                        <label className="form-label" htmlFor="fileInput1">Upload Car Registration:</label>
                                                                        <input
                                                                            className={`form-control ${carRegistration && registrationUpload ? 'is-invalid' : ''}`}
                                                                            type="file"
                                                                            id="fileInput1"
                                                                            onChange={(event) => handleFileChangeRegistration(event)}
                                                                            onBlur={() => setRegistrationUpload(true)}
                                                                            onClick={() => setRegistrationUpload(true)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row m-0 p-0">
                                                                <div className="col m-0 p-0">
                                                                    <div className="mb-3 mt-3">
                                                                        <label className="form-label" htmlFor="fileInput2">Upload Licence Plate:</label>
                                                                        <input
                                                                            className={`form-control ${licensePlate && licencePlateState ? 'is-invalid' : ''}`}
                                                                            type="file"
                                                                            id="fileInput2"
                                                                            accept="image/*"
                                                                            onChange={(event) => handleFileChangePlate(event)}
                                                                            onBlur={() => setLicencePlateState(true)}
                                                                            onClick={() => setLicencePlateState(true)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="row mt-4 mb-4">
                                                    <div className="col">
                                                        <span className="ms-3 mb-3">Currently Don't Support</span>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : vinChange ? (
                                        <div className="row mt-4 mb-4">
                                            <div className="col">
                                                <span className="ms-3 mb-3">Currently Don't Support</span>
                                            </div>
                                        </div>
                                    ) : (<></>)}
                                </>
                            )}
                            {(vinLength && vinChange) ? (
                                <div className="row d-flex justify-content-center mt-3">
                                    <div className="col-sm-12 col-md-11 col-lg-7">
                                        <div className="form-check">
                                            <input
                                                className={`form-check-input`}
                                                type="checkbox"
                                                id="agreeTerms"
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor="agreeTerms">I agree to the Terms and Conditions</label>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row d-flex justify-content-center mt-3">
                                    <div className="col-sm-12 col-md-11 col-lg-9">
                                        <div className="form-check">
                                            <input
                                                className={`form-check-input ${termsSelect ? 'is-invalid' : ''}`}
                                                type="checkbox"
                                                id="agreeTerms"
                                                checked={agreed}
                                                onChange={handleAgreementChange}
                                            />
                                            <label className="form-check-label" htmlFor="agreeTerms">I agree to the Terms and Conditions</label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {vinPrice.extraDocs ? (
                                <div className="row d-flex justify-content-center mt-4">
                                    <div className="col-sm-5 col-md-4 col-lg-3">
                                        <button
                                            type="submit"
                                            disabled={carRegistration === null || driversLicence === null || licensePlate === null || customerFirstName.length === 0 || customerLastName.length === 0 || termsSelect || !seven || !vinChange || !vinPrice || !vinPrice.dontSupport}
                                            className="btn btn-sm btn-outline-primary mb-3 loginButton"
                                        >
                                            Place Order
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="row d-flex justify-content-center mt-4">
                                    <div className="col-sm-5 col-md-4 col-lg-3">
                                        <button
                                            type="submit"
                                            disabled={termsSelect || !seven || !vinChange || !vinPrice || !vinPrice.dontSupport}
                                            className="btn btn-sm btn-outline-primary mb-3 loginButton"
                                        >
                                            Place Order
                                        </button>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>

                </div>
                <KeyCodeComponent fetchRequestCompany={fetchRequestCompany} />
            </div>
        </>
    );
};

export default PinCodeComponent;