import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import Select from "react-select";

const years = [];
for (let year = 1970; year <= 2023; year++) {
    years.push(year);
}

const EditYearComponent = ({
    fetchModel,
    updateYearApiRequest,
    fetchMake,
    fetchYear,
    fetchYearModel,
}) => {
    const [validated, setValidated] = useState(false);
    const [selectedMake, setSelectedMake] = useState('');
    const [make, setMake] = useState([]);
    const [savedYears, setSavedYears] = useState([]);
    const [unmatchedYears, setUnmatchedYears] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [filteredModels, setFilteredModels] = useState([]);
    const [updateYearResponseState, setUpdateYearResponseState] = useState({
        message: "",
        error: "",
    });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchModel(selectedMake)
            .then((data) => {
                setFilteredModels(data);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
    }, [selectedMake, fetchModel]);

    useEffect(() => {
        const abctrl = new AbortController();
        fetchMake(abctrl)
            .then((res) => setMake(res))
            .catch((er) =>
                setMake([
                    { name: er.response.data.message ? er.response.data.message : er.response.data }
                ]),
            );
        return () => abctrl.abort();
    }, [fetchMake]);

    useEffect(() => {
        const abctrl = new AbortController();
        fetchYear(id)
            .then((data) => {
                setSelectedMake(data.make);
                setSelectedModel(data.model);

                fetchYearModel(data.model)
                    .then((modelData) => {
                        const matchingYears = modelData.map((item) => item.year);
                        const unmatchedYears = years.filter((year) =>
                            !matchingYears.includes(year)
                        );

                        if (!unmatchedYears.includes(data.year)) {
                            unmatchedYears.push(data.year);
                        }

                        setSavedYears(data.year);
                        setUnmatchedYears(unmatchedYears);
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    )
            })
            .catch((er) => console.log(er));
        return () => abctrl.abort();
    }, [id, fetchYear, years]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            make: selectedMake,
            model: selectedModel,
            year: savedYears,
        };

        if (event.currentTarget.checkValidity() === true) {
            updateYearApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "Year updated") navigate(`${paths.ADMINYEAR}`);
                })
                .catch((er) =>
                    setUpdateYearResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-start">Edit Year</h1>
                        <form noValidate validated={validated} onSubmit={handleSubmit} className="row g-3">
                            <div className="row mt-5">
                                <div className="col-sm-3" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <label htmlFor="make">Make:</label>
                                    <select
                                        className="btn btn-outline-light text-dark"
                                        id="make"
                                        name="make"
                                        value={selectedMake}
                                        onChange={(e) => {
                                            setSelectedMake(e.target.value);
                                        }}                                            >
                                        {make
                                            .slice()
                                            .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
                                            .map((makes) => (
                                                <option key={makes.id} value={makes.id}>
                                                    {makes.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-6" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <label htmlFor="model">Model:</label>
                                    <select
                                        className="btn btn-outline-light text-dark"
                                        id="model"
                                        name="model"
                                        value={selectedModel}
                                        onChange={(e) => setSelectedModel(e.target.value)}
                                    >
                                        <option>Select Model</option>
                                        {filteredModels
                                            .filter((models) => models.make === selectedMake)
                                            .slice()
                                            .sort((a, b) => a.model.localeCompare(b.model))
                                            .map((models) => (
                                                <option key={models.id} value={models.id}>
                                                    {models.model}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-12" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <label htmlFor="year">Years:</label>
                                    <Select
                                        isMulti
                                        options={unmatchedYears.map(year => ({ value: year, label: year }))}
                                        value={savedYears.map(year => ({ value: year, label: year }))}
                                        onChange={selectedOptions => setSavedYears(selectedOptions.map(option => option.value))}
                                    />
                                </div>
                            </div>
                            <div className="text-center pt-5">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINYEAR} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {updateYearResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditYearComponent;
