import axios from "axios";
import NewTaskComponent from "./components/newTaskComponent";

const createTaskApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/task/admin/new`, { ...formInputs }, { withCredentials: true });
    return data;
};
const fetchTechInfo = async () => {
    const { data } = await axios.get(`/api/techInfo/graph`, { withCredentials: true });
    return data
}
const fetchUsers = async () => {
    const { data } = await axios.get(`/api/users/inventory/`, { withCredentials: true });
    return data
}

const NewTaskPage = () => {

    return (
        <NewTaskComponent
            fetchTechInfo={fetchTechInfo}
            fetchUsers={fetchUsers}
            createTaskApiRequest={createTaskApiRequest}
        />
    );
};

export default NewTaskPage;