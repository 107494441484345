import axios from "axios";
import LocksmithHelpComponent from "./components/locksmithHelpComponent";

const LocksmithHelpPage = () => {

    const fetchInstruction = async (Category) => {
        const { data } = await axios.get(`/api/instruction?instructionType=Locksmith&instructionCatagory=${Category}`, { withCredentials: true })
        return data;
    }

    return <LocksmithHelpComponent
        fetchInstruction={fetchInstruction}
    />;
};

export default LocksmithHelpPage;
