import axios from "axios";
import AdminVinPriceComponent from "./components/vinPriceComponent";

const fetchVinPrice = async (abctrl) => {
    const { data } = await axios.get("/api/vinPrice/admin", { withCredentials: true });
    return data
}

const deleteVinPrice = async (vinPriceId) => {
    const { data } = await axios.delete(`/api/vinPrice/admin/delete/${vinPriceId}`, { withCredentials: true });
    return data
}

const AdminVinPricePage = () => {

    return <AdminVinPriceComponent fetchVinPrice={fetchVinPrice} deleteVinPrice={deleteVinPrice} />;
};

export default AdminVinPricePage;
