import axios from 'axios'
import { useDispatch } from "react-redux";
import EditJobTypeComponent from './components/editJobTypeComponent';

const fetchJobType = async (jobTypeId) => {
    const { data } = await axios.get(`/api/jobType/get-one/${jobTypeId}`, { withCredentials: true });
    return data;
}

const updateJobTypeApiRequest = async (jobTypeId, formInputs) => {
    const { data } = await axios.put(`/api/jobType/dispatch/${jobTypeId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const EditJobTypePage = () => {

    const reduxDispatch = useDispatch();


    return <EditJobTypeComponent fetchJobType={fetchJobType} updateJobTypeApiRequest={updateJobTypeApiRequest} reduxDispatch={reduxDispatch} />;
};

export default EditJobTypePage;
