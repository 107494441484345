import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import RowPrintComponent from "../../../components/rowPrintComponent";
import InventoryLinksComponent from "../../../components/inventory/inventoryLinksComponent";
import { FaRegThumbsUp } from 'react-icons/fa';
import InventoryCheckOutComponent from '../../../components/inventory/inventoryCheckOutComponent';
import { MdOutlineInventory2 } from "react-icons/md";
import InventoryEditInfoComponent from "../../../components/inventory/inventoryEditInfoComponent";
import { MdOutlinePersonOff } from "react-icons/md";

const InventoryItemTechComponent = ({ fetchInventory, fetchRequest, fetchLocation, fetchUsers, fetchTechInfo }) => {
    const [inventory, setInventory] = useState([]);
    const auth = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [showAlertSuc, setShowAlertSuc] = useState(false);
    const [locations, setLocations] = useState([]);
    const [tech, setTech] = useState([]);
    const [techs, setTechs] = useState([]);
    const [user, setUser] = useState([]);
    const [techId, setTechId] = useState([]);
    const [request, setRequest] = useState([]);
    const [filterLocations, setFilterLocations] = useState('');
    const [showAlertEdit, setShowAlertEdit] = useState(false);
    const [selectedItemEdit, setSelectedItemEdit] = useState(null);
    const [inventorySet, setInventorySet] = useState([]);

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchInventory(abctrl)
            .then((res) => {
                const filteredInventory = res.filter((item) => item.company === auth.user.company);

                const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                    item.InventoryLocation.some((location) =>
                        location.name1 === locationSelect &&
                        location.quantity <= location.minimunStock1
                    )
                );
                setInventorySet(inventoryWithSelectedLocation);

                fetchUsers()
                    .then((data) => {
                        const filteredUser = data.find((item) => item._id === auth.user._id);

                        setUser(data);

                        fetchTechInfo()
                            .then((resData) => {
                                const filteredTechId = resData.filter((item) => item.company === auth.user.company);
                                const filteredTech = resData.find((item) => item.user === filteredUser._id);
                                setTech(filteredTech);
                                setTechId(filteredTechId);
                                setTechs(resData);

                                fetchLocation()
                                    .then((dataRes) => {
                                        if (filteredTech) {
                                            const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);
                                            setFilterLocations(filteredLocation);

                                            const filteredVan = dataRes.find((item) => item.van === true);
                                            setLocations(dataRes);

                                            if (filteredVan.van === true) {
                                                const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);

                                                if (filteredLocation) {
                                                    const name = `${filteredUser.name} ${filteredLocation.name}`

                                                    if (filteredLocation !== undefined) {
                                                        const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                                                            item.InventoryLocation.some((location) => location.name1 === name)
                                                        );

                                                        setInventory(inventoryWithSelectedLocation);
                                                        setSelectedLocation(name);
                                                    } else {

                                                        setInventory([]);
                                                        setSelectedLocation(null);
                                                    }
                                                }
                                            }
                                        } else {
                                            setLocations(dataRes);
                                            setInventory([]);
                                            setSelectedLocation(null);
                                        }
                                    })
                                    .catch((er) =>
                                        console.log(
                                            er.response.data.message ? er.response.data.message : er.response.data
                                        )
                                    );
                                fetchRequest()
                                    .then((dataResRes) => {
                                        if (dataResRes.message !== 'request not found') {
                                            const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                            setRequest(filteredRequest)
                                        } else {
                                            setRequest([])
                                        }
                                    })
                                    .catch((er) =>
                                        console.log(
                                            er.response.data.message ? er.response.data.message : er.response.data
                                        )
                                    );
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );

                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );

                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [fetchInventory, auth.user.company, selectedLocation, fetchUsers, auth.user._id, fetchTechInfo, fetchLocation, fetchRequest]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchInventory(abctrl)
                .then((res) => {
                    const filteredInventory = res.filter((item) => item.company === auth.user.company);

                    const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                    const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === locationSelect &&
                            location.quantity <= location.minimunStock1
                        )
                    );
                    setInventorySet(inventoryWithSelectedLocation);

                    fetchUsers()
                        .then((data) => {
                            const filteredUser = data.find((item) => item._id === auth.user._id);

                            setUser(data);

                            fetchTechInfo()
                                .then((resData) => {
                                    const filteredTechId = resData.filter((item) => item.company === auth.user.company);
                                    const filteredTech = resData.find((item) => item.user === filteredUser._id);
                                    setTech(filteredTech);
                                    setTechId(filteredTechId);
                                    setTechs(resData);

                                    fetchLocation()
                                        .then((dataRes) => {
                                            if (filteredTech) {
                                                const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);
                                                setFilterLocations(filteredLocation);

                                                const filteredVan = dataRes.find((item) => item.van === true);
                                                setLocations(dataRes);

                                                if (filteredVan.van === true) {
                                                    const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);

                                                    if (filteredLocation) {
                                                        const name = `${filteredUser.name} ${filteredLocation.name}`

                                                        if (filteredLocation !== undefined) {
                                                            const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                                                                item.InventoryLocation.some((location) => location.name1 === name)
                                                            );

                                                            setInventory(inventoryWithSelectedLocation);
                                                            setSelectedLocation(name);
                                                        } else {

                                                            setInventory([]);
                                                            setSelectedLocation(null);
                                                        }
                                                    }
                                                }
                                            } else {
                                                setLocations(dataRes);
                                                setInventory([]);
                                                setSelectedLocation(null);
                                            }
                                        })
                                        .catch((er) =>
                                            console.log(
                                                er.response.data.message ? er.response.data.message : er.response.data
                                            )
                                        );
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                            fetchRequest()
                                .then((dataResRes) => {
                                    if (dataResRes.message !== 'request not found') {
                                        const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                        setRequest(filteredRequest)
                                    } else {
                                        setRequest([])
                                    }
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );

                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );

                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [fetchInventory, auth.user.company, selectedLocation, isLoading, fetchUsers, auth.user._id, fetchTechInfo, fetchLocation, fetchRequest]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };
    const componentRef = useRef(null);

    const removeFromStock = async (id, sourceLocationName, destinationLocationName, quantity) => {
        const parsedQuantity = parseInt(quantity, 10);

        if (isNaN(parsedQuantity) || !Number.isInteger(parsedQuantity) || parsedQuantity <= 0) {
            setShowAlertMessage("Quantity must be a positive integer.");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);

            return;
        }

        try {
            await axios.put(`/api/inventory/${id}/location/${sourceLocationName}/transfer/${destinationLocationName}`, { quantity: parsedQuantity });
            setShowAlertSuc(true);
            setTimeout(() => {
                setShowAlertSuc(false);
            }, 3000);
            setIsLoading(true);

        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === "Invalid quantity to transfer.") {
                setShowAlertMessage("Invalid quantity to transfer.");
            } else {
                setShowAlertMessage(`Error transferring item between locations: ${error.message}`);
            }

            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    const editInventoryItem = async (id, destinationLocationName, cost, locationName, stock, stockKey) => {

        try {
            await axios.put(`/api/inventory/${id}/edit/${destinationLocationName}`,
                {
                    minimunStock1: stock || 0,
                    location1: locationName || '',
                    ourCost1: cost || 0.00,
                    dontStock: stockKey || true,
                });
            setShowAlertSuc(true);
            setTimeout(() => {
                setShowAlertSuc(false);
            }, 3000);
            setIsLoading(true);

        } catch (error) {
            setShowAlertMessage(`Error updating item: ${error.message}`);
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    const handleCheckout = (id, item) => {
        setSelectedItem({ id, item });
        setShowConfirmation(true);
    };

    const handleEditInventory = (id, item) => {
        setSelectedItemEdit({ id, item });
        setShowAlertEdit(true);
    };

    const onConfirmCheckoutEdit = (cost, locationName, stock, stockKey) => {
        editInventoryItem(selectedItemEdit.id._id, selectedItemEdit.item._id, cost, locationName, stock, stockKey);
        setShowAlertEdit(false);
    };

    const onConfirmCheckout = (quantity, destinationLocation) => {
        removeFromStock(selectedItem.id, selectedItem.item._id, destinationLocation, quantity);
        setShowConfirmation(false);
    };

    const onCancelCheckout = () => {
        setShowConfirmation(false);
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleCloseAlertSuc = () => {
        setShowAlertSuc(false);
    };

    const requestDataLenStatus = () => {
        if (filterLocations) {
            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.location && filterLocations.tech === locc.tech));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            if (filteredStatus === undefined) {
                return 0;
            } else {
                return filteredStatus;
            }
        } else {
            return 0;
        }
    }

    const requestDataLenInStatus = () => {
        if (filterLocations) {

            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.toLocation));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            if (filteredStatus === undefined) {
                return 0;
            } else {
                return filteredStatus;
            }
        } else {
            return 0;
        }
    }

    const handleCloseAlertEdit = () => {
        setShowAlertEdit(false);
    };

    return (
        <div className="min-vh-100">
            {showConfirmation && selectedItem && (
                <InventoryCheckOutComponent
                    message={`Enter the quantity to remove (Available stock: ${selectedItem.item.quantity}):`}
                    selectedItem={selectedItem}
                    locations={locations}
                    tech={tech}
                    user={user}
                    techId={techId}
                    onConfirm={onConfirmCheckout}
                    onCancel={onCancelCheckout}
                />
            )}
            {showAlertEdit && selectedItemEdit && (
                <InventoryEditInfoComponent
                    selectedItemEdit={selectedItemEdit}
                    locations={locations}
                    techs={techs}
                    selectedLocation={selectedLocation}
                    user={user}
                    tech={tech}
                    onConfirm={onConfirmCheckoutEdit}
                    onCancel={handleCloseAlertEdit}
                />
            )}
            {showAlert && (
                <div className="alertRed alert-danger" role="alert">
                    {showAlertMessage}
                    <div className="alert-line-container">
                        <div className="alert-lineRed"></div>
                        <span className="alert-closeRed" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertSuc && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>
                            X
                        </span>
                    </div>
                </div>
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <InventoryLinksComponent inventorySet={inventorySet} requestDataLenStatus={requestDataLenStatus} requestDataLenInStatus={requestDataLenInStatus} />
                </div>
                <div className="col-sm-10 ms-0 ps-0 mt-5 pt-5">
                    <div className="row m-0 p-0 w-100 mb-3">
                        <div className="col-sm-10 m-0 p-0">
                            <h1>Inventory List</h1>
                        </div>
                        {inventory.length > 0 && (
                            <div className="col-sm-2 align-end m-0 p-0">
                                <input
                                    type="text"
                                    className="mt-1 me-4 input-group table-filter w-100"
                                    data-table="order-table"
                                    placeholder="Search Inventory.."
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        )}
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <div className="table-responsive">
                            {filterLocations ? (
                                <>
                                    {inventory.length > 0 ? (
                                        <table ref={componentRef} className="table-hover table-bordered order-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-start ps-4">Inventory Name</th>
                                                    <th className="text-start">Location</th>
                                                    <th className="text-start">Stock</th>
                                                    <th className="text-start">Price</th>
                                                    <th className="text-center">AKL Price</th>
                                                    <th className="text-start">Cost</th>
                                                    <th className="text-start">Barcode</th>
                                                    <th className="text-center">Check Out</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inventory
                                                    .filter(
                                                        (inventorys) =>
                                                            inventorys.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                                            inventorys.InventoryLocation.some((location) =>
                                                                location.location1.toLowerCase().includes(searchValue)
                                                            ) ||
                                                            inventorys.barcode.toLowerCase().indexOf(searchValue) !== -1
                                                    )
                                                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                                    .map((inventorys, idx) => (
                                                        <tr key={idx * 22}>
                                                            <td className="align-middle text-center">{inventorys.name}</td>
                                                            {inventorys.InventoryLocation.filter((location) => location.name1 === selectedLocation).map((inv) => (
                                                                <>
                                                                    <td className="align-middle text-center">
                                                                        {inv.location1 !== '' ? (
                                                                            <>
                                                                                {inv.location1}
                                                                            </>
                                                                        ) : (
                                                                            "-"
                                                                        )}
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        {inv.quantity !== '' ? (
                                                                            <>
                                                                                {inv.quantity}
                                                                            </>
                                                                        ) : (
                                                                            "-"
                                                                        )}
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        {Number(inventorys.price).toFixed(2)}
                                                                    </td>
                                                                    {inventorys.allKeysLostPrice && inventory.vehicleInven ? (
                                                                        <td className="align-middle text-center">
                                                                            {Number(inventorys.allKeysLostPrice).toFixed(2)}
                                                                        </td>
                                                                    ) : (
                                                                        <td className="align-middle text-center">
                                                                            ---
                                                                        </td>
                                                                    )}
                                                                    <td className="align-middle text-center">
                                                                        {inv.ourCost1 !== 0 ? (
                                                                            <>
                                                                                {Number(inv.ourCost1).toFixed(2)}
                                                                            </>
                                                                        ) : (
                                                                            "-"
                                                                        )}
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        <RowPrintComponent selectedLocation={selectedLocation} inventory={inventorys} />
                                                                    </td>
                                                                    <td className="fs-6 align-middle text-center">
                                                                        <button
                                                                            className="btn btn-sm btn-outline-primary me-2"
                                                                            onClick={() => handleCheckout(inventorys._id, inv)}
                                                                            disabled={inv.quantity === 0}
                                                                        >
                                                                            Check Out
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-sm btn-outline-success"
                                                                            onClick={() => handleEditInventory(inventorys, inv)}
                                                                        >
                                                                            Edit
                                                                        </button>
                                                                    </td>
                                                                </>
                                                            ))}
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="mt-5">
                                            <div className="row d-flex justify-content-center m-0 p-0">
                                                <div className="col-sm-3 m-0 p-0 align-center">
                                                    <MdOutlineInventory2 className="inventoryLogo fs-1" />
                                                </div>
                                            </div>
                                            <div className="row d-flex justify-content-center m-0 p-0">
                                                <div className="col-sm-4 m-0 p-0 text-start">
                                                    <span>Your inventory is currently empty.</span> <br />
                                                    <span>Start adding items to get started!</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="mt-5 d-flex flex-column align-items-center">
                                    <div className="d-flex justify-content-start align-items-center m-0 p-0">
                                        <div className="col-sm-4 m-0 p-0 text-center">
                                            <MdOutlinePersonOff className="inventoryLogo fs-1" />
                                        </div>
                                    </div>
                                    <div className="d-flex mt-3 justify-content-center m-0 p-0">
                                        <div className="col-sm-7 m-0 p-0 text-center w-100">
                                            <span>
                                                You have not yet been allocated a location.<br />
                                                Please reach out to an administrator to have them assign a location for you to begin.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {inventory.length > 0 && (
                        <div className="d-flex justify-content-center mb-3 pb-4">
                            <button
                                className="btn btn-sm btn-outline-primary pe-2 ps-2 mr-2 p-0 m-0 me-3 loginButton"
                                disabled={page === 1}
                                onClick={handlePrevPage}
                            >
                                Previous
                            </button>
                            <span className="mr-2 me-3">
                                Page {page} of {Math.ceil(inventory
                                    .filter(
                                        (inventorys) =>
                                            inventorys.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                            inventorys.InventoryLocation.some((location) =>
                                                location.location1.toLowerCase().includes(searchValue)
                                            ) ||
                                            inventorys.barcode.toLowerCase().indexOf(searchValue) !== -1
                                    ).length / rowsPerPage)}
                            </span>
                            <button
                                className="btn btn-sm btn-outline-primary pe-4 ps-4 mr-2 p-0 m-0 me-3 loginButton"
                                disabled={inventory
                                    .filter(
                                        (inventorys) =>
                                            inventorys.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                            inventorys.InventoryLocation.some((location) =>
                                                location.location1.toLowerCase().includes(searchValue)
                                            ) ||
                                            inventorys.barcode.toLowerCase().indexOf(searchValue) !== -1
                                    ).length <= page * rowsPerPage}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InventoryItemTechComponent;