import axios from "axios";
import CalendarComponent from "./components/calendarComponent";

const fetchSchedule = async (abctrl) => {
    const { data } = await axios.get("/api/schedule/dispatch", { withCredentials: true });
    return data
}
const updateSchedule = async (scheduleId, updatedEventData) => {
    const { data } = await axios.put(`/api/schedule/dispatch/schedule/${scheduleId}`, { ...updatedEventData }, { withCredentials: true });
    return data
}
const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/dispatch", { withCredentials: true });
    return data
}
const fetchJobType = async (abctrl) => {
    const { data } = await axios.get("/api/jobType", { withCredentials: true });
    return data
}
const fetchJobTag = async (abctrl) => {
    const { data } = await axios.get("/api/jobTag/dispatch", { withCredentials: true });
    return data
}
const fetchUsers = async (abctrl) => {
    const { data } = await axios.get("/api/users", { withCredentials: true });
    return data
}
const updateDispatchScheduleApiRequest = async (dispatchId, formInputsSch) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/schedule/${dispatchId}`, { ...formInputsSch }, { withCredentials: true });
    return data;
}

const CalendarPage = () => {

    return <CalendarComponent updateDispatchScheduleApiRequest={updateDispatchScheduleApiRequest} fetchUsers={fetchUsers} fetchJobType={fetchJobType} fetchJobTag={fetchJobTag} updateSchedule={updateSchedule} fetchTechInfo={fetchTechInfo} fetchSchedule={fetchSchedule} />;
};

export default CalendarPage;
