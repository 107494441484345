import axios from "axios";
import { useDispatch } from "react-redux";
import CreateLocksmithInfoPageComponent from "./components/adminNewLocksmithInfoComponent";

const createLocksmithInfoApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/locksmithInfo/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};
const fetchMake = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleMake/", { withCredentials: true })
    return data;
}
const fetchModel = async (make) => {
    const { data } = await axios.get(`/api/vehicleModel?make=${make}`, { withCredentials: true })
    return data;
}
const fetchYear = async (model) => {
    const { data } = await axios.get(`/api/vehicleYear?model=${model}`, { withCredentials: true });
    return data
}
const fetchUploads = async (uploadId) => {
    const { data } = await axios.get(`/api/uploads/image/${uploadId}`, { withCredentials: true });
    return data
}
const updateLocksmithFileApiRequest = async (locksmithInfoId, formInputsFile) => {
    const { data } = await axios.put(`/api/locksmithInfo/admin/image/${locksmithInfoId}`, { ...formInputsFile }, { withCredentials: true });
    return data;
}
const fetchUploadsImage = async (imageId) => {
    const { data } = await axios.get(`/api/uploads/image/${imageId}`, { withCredentials: true });
    return data
}
const fetchInstruction = async (abctrl) => {
    const { data } = await axios.get("/api/instruction/admin", { withCredentials: true });
    return data
}

const AdminCreateLocksmithInfoPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateLocksmithInfoPageComponent
            fetchInstruction={fetchInstruction}
            fetchUploadsImage={fetchUploadsImage}
            updateLocksmithFileApiRequest={updateLocksmithFileApiRequest }
            fetchUploads={fetchUploads }
            fetchMake={fetchMake}
            fetchYear={fetchYear }
            fetchModel={fetchModel}
            createLocksmithInfoApiRequest={createLocksmithInfoApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreateLocksmithInfoPage;