import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const JobSourceSelectComponent = ({ jobSource, setSelectedJobSource, setJobSourceName, selectedJobSource }) => {
    const [filteredJobSource, setFilteredJobSource] = useState([]);
    const auth = useSelector(state => state.auth)

    const handleJobSourceChange = (event) => {
        const selectedJobSourceId = event.target.value;
        const selectedJobSourceObject = filteredJobSource.find(jobSource => jobSource._id === selectedJobSourceId);

        const selectedJobSourceName = selectedJobSourceObject ? selectedJobSourceObject.name : '';
        setJobSourceName(selectedJobSourceName);
        setSelectedJobSource(selectedJobSourceId);
    };

    useEffect(() => {
        if (Array.isArray(jobSource)) {
            setFilteredJobSource(jobSource.filter((jobSources) => jobSources.company === auth.user.company));
        }
    }, [jobSource, auth.user.company]);

    return (
        <div>
        {
            jobSource.length === 0 ? (
                <p className="mt-3">Loading Job Source...</p>
            ) : (
                <select
                    className="form-select mt-3 text-dark"
                    id="jobSource"
                    name="jobSource"
                    value={selectedJobSource}
                    onChange={handleJobSourceChange}
                >
                    <option value="">Job Source</option>
                    {filteredJobSource.map((jobSources) => (
                        <option key={jobSources._id} value={jobSources._id}>
                            {jobSources.name}
                        </option>
                    ))}
                </select>
            )
            }
        </div>
    );
};

export default JobSourceSelectComponent;
