import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paths from "../../../router/paths";

const EditCompanyComponent = ({
    updateCompanyApiRequest,
    fetchCompany
}) => {
    const [validated, setValidated] = useState(false);
    const [company, setCompany] = useState([]);
    const [updateCompanyResponseState, setUpdateCompanyResponseState] = useState({
        message: "",
        error: "",
    });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchCompany(id)
            .then((data) => {
                setCompany(data);
            })
            .catch((er) => console.log(er));
    }, [id, fetchCompany]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            contactName: form.contactName.value,
            address: form.address.value,
            phone: form.phone.value,
            city: form.city.value,
            state: form.state.value,
            zip: form.zip.value,
            country: form.country.value,
            email: form.email.value,
        };

        if (event.currentTarget.checkValidity() === true) {
            updateCompanyApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "Company updated") navigate(`${paths.ADMINCOMPANY}`);
                })
                .catch((er) =>
                    setUpdateCompanyResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    }

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-center">Edit Company</h1>
                        <form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} className="row pt-5 g-3">
                            <div>
                                <label htmlFor="name">Company Name:</label>
                                <input type="text"
                                    className="form-control"
                                    id="name"
                                    defaultValue={company.name}
                                    name="name" />
                            </div>
                            <div>
                                <label htmlFor="phone">Phone:</label>
                                <input type="text"
                                    className="form-control"
                                    id="phone"
                                    defaultValue={company.phone}
                                    name="phone" />
                            </div>
                            <div>
                                <label htmlFor="email">Email:</label>
                                <input type="text"
                                    className="form-control"
                                    id="email"
                                    defaultValue={company.email}
                                    name="email" />
                            </div>
                            <div>
                                <label htmlFor="contactName">Contact Name:</label>
                                <input type="text"
                                    className="form-control"
                                    id="contactName"
                                    defaultValue={company.contactName}
                                    name="contactName" />
                            </div>
                            <div>
                                <label htmlFor="address">Company Address:</label>
                                <input type="text"
                                    className="form-control"
                                    id="address"
                                    defaultValue={company.address}
                                    name="address" />
                            </div>
                            <div>
                                <label htmlFor="city">City:</label>
                                <input type="text"
                                    className="form-control"
                                    id="city"
                                    defaultValue={company.city}
                                    name="city" />
                            </div>
                            <div>
                                <label htmlFor="state">State:</label>
                                <input type="text"
                                    className="form-control"
                                    id="state"
                                    defaultValue={company.state}
                                    name="state" />
                            </div>
                            <div>
                                <label htmlFor="zip">Zip Code:</label>
                                <input type="text"
                                    className="form-control"
                                    id="zip"
                                    defaultValue={company.zip}
                                    name="zip" />
                            </div>
                            <div>
                                <label htmlFor="country">Country:</label>
                                <input type="text"
                                    className="form-control"
                                    id="country"
                                    defaultValue={company.country}
                                    name="country" />
                            </div>
                            <div className="text-center pt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINCOMPANY} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {updateCompanyResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCompanyComponent;