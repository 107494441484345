import axios from "axios";
import DispatchOpenQuoteComponent from "./components/dispatchOpenQuoteComponent";

const fetchDispatch = async (abctrl) => {
    const { data } = await axios.get("/api/dispatch/dispatch", { withCredentials: true });
    return data
}
const fetchCustomer = async (customerId) => {
    const { data } = await axios.get(`/api/customer/dispatch/${customerId}`, { withCredentials: true });
    return data
}
const deleteDispatch = async (dispatchId) => {
    const { data } = await axios.delete(`/api/dispatch/dispatch/${dispatchId}`, { withCredentials: true });
    return data
}
const fetchJobType = async (jobTypeId) => {
    const { data } = await axios.get(`/api/jobType/dispatch/${jobTypeId}`, { withCredentials: true });
    return data
}
const fetchJobTag = async (jobTagId) => {
    const { data } = await axios.get(`/api/jobTag/dispatch/${jobTagId}`, { withCredentials: true });
    return data
}
const fetchTechInfo = async (techId) => {
    const { data } = await axios.get(`/api/techInfo/dispatch/${techId}`, { withCredentials: true });
    return data
}
const fetchUsers = async (userId) => {
    const { data } = await axios.get(`/api/users/dispatch/${userId}`, { withCredentials: true });
    return data
}
const fetchSchedule = async (scheduleId) => {
    const { data } = await axios.get(`/api/schedule/schedule/${scheduleId}`, { withCredentials: true });
    return data
}

const DispatchOpenQuotePage = () => {

    return <DispatchOpenQuoteComponent fetchSchedule={fetchSchedule} fetchUsers={fetchUsers} fetchTechInfo={fetchTechInfo} fetchJobTag={fetchJobTag} fetchJobType={fetchJobType} fetchCustomer={fetchCustomer} fetchDispatch={fetchDispatch} deleteDispatch={deleteDispatch} />;
};

export default DispatchOpenQuotePage;
