import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { forgetPassword } from '../../../redux/actions/action';
import paths from '../../../router/paths';

const UserForgotPasswordComponent = () => {
    const { loading } = useSelector(state => state.auth);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState('');

    const dispatch = useDispatch()

    const forgetHandler = async () => {

        const responseMessage = await dispatch(forgetPassword(email, () => {
        }));

        setMessage(responseMessage);
    }

    console.log(message)
    return (
        <div className="container-fluid mt-5 pt-5 min-vh-100 w-100">
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row margintop ps-4 pe-4 pt-5 mt-5 justify-content-md-center">
                    <div className="col-sm-6 col-md-4 col-lg-3 p-3 color-white">
                        <div className="text-start pb-3 mb-3">
                            <h1 className="loginTitleFont">Password Assistance</h1>
                            <span className="">or <Link to={paths.LOGIN}>Login </Link></span>
                        </div>
                        {message && message.message === 'Email sent' ? (
                            < p className="text-center">Email Sent Succesfully! See your email for details.<br /> The link will expire in an hour.</p>
                        ) : (
                            <>
                                {message && message === 'User not found!' && <p className="text-center" style={{ color: 'red' }}> No account associated with that email.</p>}
                                <form onSubmit={forgetHandler}>
                                    <div className="mb-3">
                                        <input
                                            type="email"
                                            className="form-control signinFormInput"
                                            placeholder="Email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <button
                                            type="submit"
                                            className="btn btn-outline-primary w-100 loginButton"
                                            disabled={!email}
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default UserForgotPasswordComponent;