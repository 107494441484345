import axios from 'axios';
import {
    loginRequest,
    loginSuccess,
    loginFailure,
    loadUserRequest,
    loadUserSuccess,
    loadUserFailure,
    logoutRequest,
    logoutSuccess,
    logoutFailure,
    forgetPasswordRequest,
    forgetPasswordSuccess,
    forgetPasswordFailure,
    clearMessage,
} from '../reducers/userReducers';
import { persistor } from '../store'; // Adjust the import path based on your setup
import { useNavigate } from 'react-router-dom';
import paths from '../../router/paths';

export const checkToken = async () => {
    try {
        const { data } = await axios.get(`/api/verify/token`, {withCredentials: true });

        if (data.message === 'No token provided') {
            return { isAuthenticated: false };
        } else {
            return { isAuthenticated: true, user: data };
        }
    } catch (error) {
        console.error("Token validation error:", error);
        return { isAuthenticated: false, error: error.response ? error.response.data.message : error.message };
    }
};

export const login = (formData, callback) => async (dispatch) => {
    try {
        dispatch(loginRequest());

        const { data } = await axios.post(`/api/users/login`, formData, { withCredentials: true });

        dispatch(loginSuccess(data));

        if (data) {
            callback();
            return data;
        } else {
            return data;
        }
    } catch (error) {
        dispatch(loginFailure(error.response ? error.response.data.message : error.message));
        return error.response.data.message
    }
};

export const loadUser = () => async (dispatch) => {
    try {
        dispatch(loadUserRequest());

        const { data } = await axios.get(`/api/users/me`, { withCredentials: true });
        dispatch(loadUserSuccess(data));
    } catch (error) {
        dispatch(loadUserFailure(error.response ? error.response.data.message : error.message));
    }
};

export const logout = (callback) => async (dispatch) => {
    try {
        dispatch(logoutRequest());

        const { data } = await axios.get(`/api/users/logout`, { withCredentials: true });

        if (data.message === 'Logged Out!') {
            persistor.purge();
            dispatch(logoutSuccess());
            useNavigate(paths.LOGIN);
            callback(); // Call the callback to navigate
        }
    } catch (error) {
        dispatch(logoutSuccess());
        dispatch(logoutFailure(error.response ? error.response.data.message : error.message));
    }
};

export const register = (formData) => async (dispatch) => {
    try {
        dispatch({ type: "registerRequest" });

        const { data } = await axios.post(`/api/users/register`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
        });
        dispatch({ type: "registerSuccess", payload: data });
    } catch (error) {
        dispatch({
            type: "registerFailure",
            payload: error.response.data.message,
        });
    }
};

export const updatePassword = (formInputs, callback) => async (dispatch) => {
    try {
        dispatch({ type: "updatePasswordRequest" });

        const { data } = await axios.put(`/api/users/updatePassword`, { ...formInputs }, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });

        dispatch({ type: "updatePasswordSuccess", payload: data.message });

        if (data.message === 'Password is updated!') {
            callback();
        }
    } catch (error) {
        dispatch({
            type: "updatePasswordFailure",
            payload: error.response.data.message,
        });
    }
};

export const verify = (formInputs, callback) => async (dispatch) => {
    try {
        dispatch({ type: "verificationRequest" });

        const { data } = await axios.post(`/api/users/2fa`, { ...formInputs }, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });

        dispatch(loginSuccess(data));
        dispatch({ type: "verificationSuccess", payload: data.message });

        if (data.message === "Verified") {
            callback();
        }
    } catch (error) {
        dispatch({
            type: "verificationFailure",
            payload: error.response.data.message,
        });
    }
};

export const forgetPassword = (email, callback) => async (dispatch) => {
    try {
        dispatch(forgetPasswordRequest());

        const { data } = await axios.post(`/api/users/forgotPassword`, { email }, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });

        dispatch(forgetPasswordSuccess({ payload: data.message }));
        if (callback) {
            callback();
            return data;
        }

        dispatch(clearMessage());
    } catch (error) {
        dispatch(forgetPasswordFailure(error.response.data.message));
        return error.response.data.message;
    }
};

export const resetPassword = (otp, newPassword) => async (dispatch) => {
    try {
        dispatch({ type: "resetPasswordRequest" });

        const { data } = await axios.put(`/api/users/resetPassword`, { otp, newPassword }, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        dispatch({ type: "resetPasswordSuccess", payload: data.message });
    } catch (error) {
        dispatch({
            type: "resetPasswordFailure",
            payload: error.response.data.message,
        });
    }
};
