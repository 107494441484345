import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";

const CreateModelPageComponent = ({
    createModelApiRequest,
    fetchMake
}) => {
    const [validated, setValidated] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [make, setMake] = useState([]);
    const [createModelResponseState, setCreateModelResponseState] = useState({
        message: "",
        error: "",
    });

    useEffect(() => {
        const abctrl = new AbortController();
        fetchMake(abctrl)
            .then((res) => setMake(res))
            .catch((er) =>
                setMake([
                    { name: er.response.data.message ? er.response.data.message : er.response.data }
                ]),
            );
        return () => abctrl.abort();
    }, [fetchMake]);

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            make: selectedValue,
            model: form.model.value,
            disabled: disabled,
        };
        if (event.currentTarget.checkValidity() === true) {
            createModelApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "model created") navigate(`${paths.ADMINMODEL}`);
                })
                .catch((er) => {
                    setCreateModelResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const handleCheckboxChange = (event) => {
        setDisabled(event.target.checked);
    };

    return (
        <div className="pt-5 mt-5">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-center">New Vehicle Model</h1>
                        <form noValidate validated={validated} onSubmit={handleSubmit} className="row pt-5 g-3">
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <label htmlFor="make">Make:</label>
                                <select
                                    className="btn btn-outline-light text-dark"
                                    id="make"
                                    name="make"
                                    value={selectedValue} // Set the selected value
                                    onChange={(e) => setSelectedValue(e.target.value)} // Handle the change event
                                >
                                    {/* Map through the makes array and create options */}
                                    {make
                                        .slice()
                                        .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
                                        .map((makes) => (
                                            <option key={makes.id} value={makes.id}>
                                                {makes.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="model">Model:</label>
                                <input type="text"
                                    className="form-control"
                                    id="model"
                                    name="model" />
                            </div>
                            <div className="form-check ms-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="disabled"
                                    id="disabled"
                                    checked={disabled}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="disabled">
                                    Disable Model Link
                                </label>
                            </div>
                            <div className="text-center pt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINMODEL} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createModelResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateModelPageComponent;