import React, { useState } from "react";

const ChangeEmailUserPopComponent = ({ formStateMain, auth, updateUserApiRequest, onConfirm, onCancel }) => {
    const [formState, setFormState] = useState({
        email: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value,
        });

    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleSubmitEmail = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { email } = formState;

        const { name, lastName, phoneNumber, username } = formStateMain;


        const formInputs = {
            name: capitalizeFirstLetter(name),
            lastName: capitalizeFirstLetter(lastName),
            phoneNumber: phoneNumber,
            email: email,
            username: username,
            company: auth.user.company,
        };

        updateUserApiRequest(formInputs, auth.user._id).then(data => {
            onConfirm();
        })
            .catch((er) => {
                console.log({ error: er.response.data.message ? er.response.data.message : er.response.data });
            });
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder deleteWidth mb-4 ">
                        <div className="col-sm fs-5">
                            Update Email Address
                        </div>
                    </div>
                    <form onSubmit={handleSubmitEmail} className="row mt-4 g-3">
                        <div className="row m-0 mt-2 p-0">
                            <div className="col mb-3">
                                <input
                                    className="form-control signinFormInput"
                                    value={formState.email}
                                    placeholder="Email"
                                    type="text"
                                    id="email"
                                    name="email"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                            <div className="col-sm">
                                <button className="btn btn-outline-success loginButton" type="submit">
                                    Update Email
                                </button>
                            </div>
                            <div className="col-sm">
                                <button className="btn btn-outline-primary ps-5 pe-5 loginButton" onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
};

export default ChangeEmailUserPopComponent;