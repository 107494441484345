import React, { useEffect, useState } from "react";
import '../assets/payment.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ConnectStripeButton from "../stripe/connectStripeButton";
import PaymentForm from "../stripe/paymentForm";
import DeletePaymentPopModal from "../stripe/deletePaymentPopup";
import RefundPaymentPopModal from "../stripe/refundPaymentPopup";
import { AiOutlinePlus } from 'react-icons/ai';
import { FcMoneyTransfer } from 'react-icons/fc';
import { BsTrash } from 'react-icons/bs';
import { RiRefund2Fill, RiSecurePaymentFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const stripePromise = loadStripe('pk_test_51NukCVEVP3HcTg1aHpVOHTyiSqxEfA1m4EGAqaFKBJwy5ssADis9ToSjjXCH3cT169Av456ZMWIp8qtT1oGvBORM00hBVNo5te');

const PaymentTabComponent = ({ deletePayment, fetchJobItem, fetchDispatch, jobLoading, setJobLoading, fetchPaymentAll, companies, setCompanies, setTotalPay }) => {
    const auth = useSelector(state => state.auth)
    const [payment, setPayment] = useState([]);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [paymentId, setPaymentId] = useState('');
    const [refundId, setRefundId] = useState('');
    const [refundAmount, setRefundAmount] = useState('');
    const [dispatching, setDispatch] = useState({});
    const [loading, setLoading] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0.00);
    const [paymentType, setPaymentType] = useState('');
    const { id } = useParams();
    const [jobItems, setJobItems] = useState({});

    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isDeveloper = auth.user?.usertype?.includes('Developer') || false;
    const isAdminOrSuperAdmin = isAdmin || isDeveloper;

    const handleAddPaymentClick = () => {
        setShowPaymentForm(true);
    };

    const handlePaymentFormClose = () => {
        setShowPaymentForm(false);
    };

    const handleConfirmRemovePayment = () => {
        deletePayment(paymentId)
            .then((data) => {
                if (data.message === 'payment deleted successfully') {
                    setLoading(true);
                    setShowModal(false);
                }
            });
    };

    const handleCancelRemoveJob = () => {
        setShowModal(false);
    };

    const handleRemovePayment = (paymentId) => {
        setPaymentId(paymentId);
        setShowModal(true);
    };

    const handleCancelRefund = () => {
        setShowRefundModal(false);
    };

    const handleRefundPayment = (paymentId, refundId, amount, paymentType) => {
        if (paymentType === 'Credit Card') {
            setRefundId(refundId);
        } else {
            setRefundId(paymentId)
        }
        setPaymentType(paymentType);
        setRefundAmount(amount);
        setShowRefundModal(true);
    };

    const handleRefund = async (confirmedRefundAmount) => {
        try {
            const response = await fetch('/api/payment/refund', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refundAmount: confirmedRefundAmount,
                    paymentType: paymentType,
                    paymentIntentId: refundId,
                }),
            });

            const data = await response.json();
            if (data.success) {
                setShowRefundModal(false);
                setLoading(true);
            } else {
                alert('Refund failed');
            }
        } catch (error) {
            console.error('Error during refund:', error);
            alert('Refund failed');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                fetchDispatch(id)
                    .then((data) => {
                        setDispatch(data);

                        fetchPaymentAll()
                            .then((res) => {
                                const filterPayments = res.filter((item) => item.jobId === data._id);

                                setPayment(filterPayments);

                                const calculatedTotalAmount = filterPayments.reduce((acc, payment) => {
                                    if (payment.status !== 'Refunded') {
                                        return acc + (payment.amount - payment.refund);
                                    }
                                    return acc;
                                }, 0);

                                setTotalPay((data.total - (calculatedTotalAmount / 100)).toFixed(2));
                                setTotalAmount((data.total - (calculatedTotalAmount / 100)).toFixed(2));
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );

                        fetchJobItem()
                            .then((resData) => {
                                const filterJobItems = resData.filter((item) => data.jobItems.includes(item._id));

                                const totalCost = filterJobItems.reduce((acc, item) => acc + parseFloat(item.cost), 0);
                                setJobItems(totalCost);

                                setLoading(false);
                                setJobLoading(false);

                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );
            } catch (error) {
                console.log(error);
            }
        };

        if (jobLoading) {
            fetchData();
        }
    }, [fetchPaymentAll, fetchDispatch, fetchJobItem, id, setJobLoading, jobLoading, setTotalPay]);


    useEffect(() => {
        if (loading) {
            try {
                fetchDispatch(id)
                    .then((data) => {
                        setDispatch(data);

                        fetchPaymentAll()
                            .then((res) => {
                                const filterPayments = res.filter((item) => item.jobId === data._id);

                                setPayment(filterPayments)

                                const calculatedTotalAmount = filterPayments.reduce((acc, payment) => {
                                    if (payment.status !== 'Refunded') {
                                        return acc + (payment.amount - payment.refund);
                                    }
                                    return acc;
                                }, 0);

                                setTotalPay((data.total - (calculatedTotalAmount / 100)).toFixed(2));
                                setTotalAmount((data.total - (calculatedTotalAmount / 100)).toFixed(2));
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                        fetchJobItem()
                            .then((resData) => {
                                const filterJobItems = resData.filter((item) => data.jobItems.includes(item._id));

                                const totalCost = filterJobItems.reduce((acc, item) => acc + parseFloat(item.cost), 0);
                                setJobItems(totalCost);

                                setLoading(false);
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );
            } catch (error) {
                console.log(error);
            }
        };

    }, [fetchPaymentAll, fetchDispatch, id, setJobLoading, setTotalPay, loading, fetchJobItem]);

    function formatDateTime(dateTime) {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };

        return new Date(dateTime).toLocaleDateString(undefined, options);
    }

    return (
        <Elements stripe={stripePromise}>
            {!companies.stripeAccountId ? (
                <>
                    {isAdminOrSuperAdmin ? (
                        <div className="depositModuleNoPayment mt-5 pt-5 m-0 p-0 row justify-content-center">
                            <div className="col-sm-3 m-0 p-0 text-center">
                                <RiSecurePaymentFill className="depositImageNoPayment" />
                                <h4 className="addPaymentH4"><span>Set up a payment processor below.</span></h4>
                                <ConnectStripeButton companyId={companies._id} onSuccess={() => setCompanies({ ...companies, stripeAccountId: 'pending' })} />
                            </div>
                        </div>
                    ) : (
                        <div className="depositModuleNoPayment mt-5 pt-5 m-0 p-0 row justify-content-center">
                            <div className="col-sm-3 m-0 p-0 text-center">
                                <RiSecurePaymentFill className="depositImageNoPayment" />
                                <h4 className="addPaymentH4"><span>Contact your manager to set up a payment processor.</span></h4>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {payment === null || payment.length === 0 ? (
                        <div className="row mt-5">
                            {showPaymentForm && (
                                <PaymentForm jobItems={jobItems} totalPaid={totalAmount} dispatching={dispatching} companies={companies} companyId={companies._id} onCancel={handlePaymentFormClose} />
                            )}
                            <div className="row ms-5 justify-content-start">
                                <div className="col-sm-4">
                                    <h3 className="ms-5 editPaymentPaymentsBalance">Balance</h3>
                                    <div className="ms-5 ps-3">
                                        <span className="paymentModuleDue">{totalAmount}</span>
                                        <span className="paymentModuleTotal">/{dispatching.total ? dispatching.total.toFixed(2) : '0.00'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 ps-5 pe-5 justify-content-between">
                                <div className="col">
                                    <h4>Job Payments</h4>
                                </div>
                                <div className="col-sm-2 text-center">
                                    <button className="btn btn-outline-primary" type="button" onClick={handleAddPaymentClick}>
                                        <AiOutlinePlus className="mb-1" /> Add Payment
                                    </button>
                                </div>
                            </div>
                            <div className="depositModuleNoPayment m-0 p-0 row justify-content-center">
                                <div className="col-sm-3 m-0 p-0 text-center">
                                    <FcMoneyTransfer className="depositImageNoPayment" />
                                    <h4 className="addPaymentH4"><span>Add Payments</span></h4>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {showModal && (
                                <DeletePaymentPopModal
                                    onConfirm={handleConfirmRemovePayment}
                                    onCancel={handleCancelRemoveJob}
                                />
                            )}
                            {showRefundModal && (
                                <RefundPaymentPopModal
                                    refundAmount={refundAmount}
                                    onConfirm={handleRefund}
                                    onCancel={handleCancelRefund}
                                />
                            )}
                            <div className="row ms-5 justify-content-start">
                                <div className="col-sm-4">
                                    <h3 className="ms-5 editPaymentPaymentsBalance">Balance</h3>
                                    <div className="ms-5 ps-3">
                                        <span className="paymentModuleDue">{totalAmount}</span>
                                        <span className="paymentModuleTotal">/{dispatching.total ? dispatching.total.toFixed(2) : 'NAN'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                {showPaymentForm && (
                                    <PaymentForm jobItems={jobItems} setLoading={setLoading} totalPaid={totalAmount} dispatching={dispatching} companies={companies} companyId={companies._id} onCancel={handlePaymentFormClose} />
                                )}
                                <div className="col-sm-10 mt-5">
                                    <div className="row p-0 m-0 mt-3 justify-content-between">
                                        <div className="col">
                                            <h3>Job Payments</h3>
                                        </div>
                                        <div className="col-sm-2 text-center">
                                            <button className="btn btn-outline-primary" type="button" onClick={handleAddPaymentClick}>
                                                Add Payment
                                            </button>
                                        </div>
                                    </div>
                                    <table className="table table-hover mt-5">
                                        <thead className="border-bottom border-dark">
                                            <tr>
                                                <th scope="col" style={{ width: "10%" }}>Payment</th>
                                                <th scope="col" style={{ width: "8%" }}>Amount</th>
                                                <th scope="col" style={{ width: "20%" }}>Date</th>
                                                <th scope="col" style={{ width: "10%" }}>Status</th>
                                                <th scope="col" style={{ width: "5%" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="border border-bottom border-right border-left border-dotted">
                                            {payment.map((payments, index) => (
                                                <tr key={index}>
                                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                                        <div>
                                                            {payments.type}
                                                        </div>
                                                    </td>
                                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                                        {payments.refund < payments.amount ? (
                                                            <>
                                                                ${((payments.amount - payments.refund) / 100).toFixed(2)}
                                                            </>
                                                        ) : payments.refund === payments.amount ? (
                                                            <>
                                                                ${((payments.amount) / 100).toFixed(2)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                ${((payments.refund) / 100).toFixed(2)}
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                                        {formatDateTime(payments.createdAt)}
                                                    </td>
                                                    <td className="border align-middle text-center border-dotted border-bottom border-right border-left">
                                                        <span
                                                            className={`payment-status ${payments.status === 'Success' ? 'bg-success-subtle p-2 rounded' : ''} ${payments.status === 'Refunded' ? 'bg-info-subtle p-2 rounded' : ''}`}
                                                        >
                                                            <span className="z-3 position-relative text-black">{payments.status}</span>
                                                        </span>
                                                    </td>
                                                    {payments.status !== 'Refunded' ? (
                                                        <td className="border text-center border-dotted border-bottom border-right border-left m-0 p-0 pt-2 pb-2">
                                                            <button
                                                                className="btn btn-sm btn-outline-warning me-2"
                                                                onClick={() => handleRefundPayment(payments._id, payments.paymentId, payments.amount, payments.type)}
                                                            >
                                                                <RiRefund2Fill className="fs-4" />
                                                            </button>
                                                            <button
                                                                className="btn btn-sm btn-outline-danger"
                                                                onClick={() => handleRemovePayment(payments._id)}
                                                            >
                                                                <BsTrash />
                                                            </button>
                                                        </td>
                                                    ) : (
                                                        <td className="border pe-3 text-end border-dotted border-bottom border-right border-left">
                                                            <button
                                                                className="btn btn-sm btn-outline-danger"
                                                                onClick={() => handleRemovePayment(payments._id)}
                                                            >
                                                                <BsTrash />
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </Elements>
    );
};

export default PaymentTabComponent;
