import React, { useState } from "react";

const RefundPaymentPopModal = ({ onConfirm, refundAmount, onCancel }) => {
    const [refundAmountOne, setRefundAmountOne] = useState(
        (refundAmount / 100).toFixed(2)
    );

    const handleInputChange = (event) => {
        setRefundAmountOne(event.target.value);
    };

    const handleInputBlur = () => {
        const inputValue = parseFloat(refundAmountOne);
        if (!isNaN(inputValue)) {
            setRefundAmountOne(inputValue.toFixed(2));
        }
    };

    const handleConfirmClick = () => {
        const inputValue = parseFloat(refundAmountOne);
        const finalRefundAmount = inputValue.toFixed(2);

        onConfirm(finalRefundAmount * 100);
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box-item">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row m-0 p-0 justify-content-center">
                        <div className="col-sm-12 m-0 p-0 text-center pb-4">
                            <div className="row pt-2 pb-2 border-bottom border-dark bg-light rounded-top m-0 p-0">
                                <span className="fw-bold fs-5">Refund Payment?</span>
                            </div>
                            <div className="row pt-2 pb-2 m-0 p-0">
                                <span className="pt-2">
                                    Are you sure you want to refund this payment?
                                </span>
                            </div>
                            <div className="row justify-content-start text-start pt-4 pb-2">
                                <div className="col-sm-6 ms-3">
                                    <div className="mb-3">
                                        <label for="refund" className="form-label">Refund Amount:</label>
                                        <input
                                            type="text"
                                            id="refund"
                                            className="form-control"
                                            placeholder="Enter amount"
                                            value={refundAmountOne}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row deleteWidth bg-light justify-content-center rounded-bottom border-top border-dark">
                        <div className="col-sm">
                            <button
                                className="btn btn-outline-primary ps-2 pe-2"
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col-sm">
                            <button
                                onClick={handleConfirmClick}
                                className="btn btn-outline-danger m-0"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RefundPaymentPopModal;