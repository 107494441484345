import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/userActions";
import '../assets/workSchedule.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditTaskPopupComponent = ({
    onCancel,
    requestEdit,
    updateTaskApiRequest,
    filteredTech,
    onConfirm,
    filteredUsers
}) => {
    const [validated, setValidated] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedPriority, setSelectedPriority] = useState("None");
    const [selectedTech, setSelectedTech] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const auth = useSelector(state => state.auth)

    const dispatch = useDispatch();

    useEffect(() => {
        const abctrl = new AbortController();

        if (requestEdit) {
            setSelectedTech(requestEdit.techId);
            setSelectedUser(requestEdit.user);
            setSelectedDate(new Date(requestEdit.deadline));
            setSelectedPriority(requestEdit.priority);
        }

        return () => abctrl.abort();
    }, [requestEdit]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            title: form.title.value,
            description: form.description.value,
            priority: selectedPriority,
            status: requestEdit.status,
            deadline: selectedDate,
            completed: false,
            techs: selectedTech,
            company: auth.user.company,
            user: selectedUser,
            userSpecific: requestEdit.userSpecific,
            repeat: requestEdit.repeat || false,
        };
        if (event.currentTarget.checkValidity() === true) {
            updateTaskApiRequest(requestEdit._id, formInputs)
                .then((data) => {
                    if (data.message === "task updated") {
                        onConfirm()    
                    };
                })
                .catch((er) => {
                    console.log({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const getTechName = (techId) => {
        const techUser = filteredUsers.find((user) => user._id === techId);
        return techUser ? techUser.name : "";
    };

    const handleTechChange = (event) => {
        const selectedValue = JSON.parse(event.target.value);

        setSelectedTech([...selectedTech, selectedValue]);
    };

    const handleRemoveTech = (techId) => {
        setSelectedTech(selectedTech.filter(id => id !== techId));
    };

    const getSelectedTechInfo = () => {
        return selectedTech.map((selectedTechId) => {
            const techItem = filteredTech.find((techItem) => techItem._id === selectedTechId);
            if (techItem) {
                const userName = getTechName(techItem.user);
                return { ...techItem, userName };
            }
            return null;
        }).filter(Boolean);
    };

    const availableTechOptions = filteredTech.filter((techItem) => !selectedTech.includes(techItem._id));

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            {auth.user?.name ? (
                <div className="confirmation-box-request">
                    <div className="alertWhite alert-light text-start" role="alert">
                        <div>
                            <div className="popup">
                                <h2 className="popup-header text-center">Edit Task Info</h2>
                                <div className="popup-content-scrollable">
                                    <div className="mt-4">
                                        <form validated={validated.toString()} onSubmit={handleSubmit} className="row pt-4 g-3">
                                            <div className="m-0 p-0 mt-4">
                                                <label htmlFor="title">Title:</label>
                                                <input type="text"
                                                    defaultValue={requestEdit.title}
                                                    className="form-control"
                                                    id="title"
                                                    disabled={requestEdit.contactForm === true}
                                                    name="title"
                                                />
                                            </div>
                                            <div className="m-0 p-0 mt-4">
                                                <label htmlFor="description" className="form-label">Description</label>
                                                <textarea
                                                    defaultValue={requestEdit.description}
                                                    className="form-control"
                                                    id="description"
                                                    disabled={requestEdit.contactForm === true}
                                                    name="description"
                                                    rows="15"
                                                >
                                                </textarea>
                                            </div>
                                            
                                            <div className="row m-0 p-0">
                                                <div className="col-sm-4 m-0 ms-2 p-0 mt-4">
                                                    <label htmlFor="priority">Priority:</label>
                                                    <select
                                                        className="form-select"
                                                        id="priority"
                                                        name="priority"
                                                        value={requestEdit.priority}
                                                        onChange={(e) => setSelectedPriority(e.target.value)}
                                                    >
                                                        <option value="High">High</option>
                                                        <option value="Medium">Medium</option>
                                                        <option value="Low">Low</option>
                                                        <option value="None">No Priority</option>
                                                    </select>
                                                </div>
                                                <div className="col ms-2 m-0 p-0 mt-4">
                                                    <label htmlFor="startDate">
                                                        Deadline:
                                                    </label><br />
                                                    <DatePicker
                                                        id="startDate"
                                                        className="date-picker-input-Task"
                                                        selected={new Date(selectedDate)}
                                                        onChange={(date) => setSelectedDate(date.toISOString())}
                                                        dateFormat="MMMM d"
                                                        showTimeInput
                                                    />
                                                </div>
                                            </div>
                                            <div className="selected-techs">
                                                {selectedTech.length > 0 && (
                                                    <div className="d-flex flex-wrap">
                                                        {getSelectedTechInfo().map((tech, index) => (
                                                            <div key={tech._id} className="selected-tech mt-2 me-2">
                                                                <span className="selected-user">
                                                                    {tech.userName}
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link btn-remove-tech"
                                                                        onClick={() => handleRemoveTech(tech._id)}
                                                                    >
                                                                        x
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            <select
                                                className="form-select mt-3 mb-5 text-dark"
                                                id="techs"
                                                name="techs"
                                                onChange={handleTechChange}
                                                defaultValue={""}
                                            >
                                                <option defaultValue="">Assign Team Members</option>
                                                {availableTechOptions.map((techItem) => (
                                                    <option key={techItem._id} value={JSON.stringify(techItem._id)}>
                                                        {getTechName(techItem.user)}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                                                <div className="col-sm-3">
                                                    <button className="btn btn-outline-primary ps-5 pe-5 loginButton" onClick={onCancel}>
                                                        Close
                                                    </button>
                                                </div>
                                                <div className="col-sm-2">
                                                    <button type="submit" className="btn btn-outline-success mb-3 loginButton">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                dispatch(logout())
            )
            }
        </ >
    );
};

export default EditTaskPopupComponent;