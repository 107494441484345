import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";
import axios from "axios";

const AdminUpdateInstructionComponent = ({
    updateInstructionApiRequest,
    fetchInstruction,
    fetchUploads
}) => {
    const [validated, setValidated] = useState(false);
    const auth = useSelector(state => state.auth)
    const [instructionType, setInstructionType] = useState('Locksmith');
    const [instructionCategory, setInstructionCategory] = useState('');
    const [tools, setTools] = useState(['']);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [instructions, setInstrustions] = useState([{ step: '', upload: null }]);
    const [createInstructionResponseState, setCreateInstructionResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const abctrl = new AbortController();

        const fetchData = async () => {
            try {
                const res = await fetchInstruction(id);
                setName(res.name);
                setDescription(res.description);
                setInstructionType(res.instructionType);
                setInstructionCategory(res.instructionCatagory);
                setTools(res.tools);
                const instructionsData = await Promise.all(
                    res.instructionSteps.map(async (instruction) => {

                        if (instruction.uploadId !== null) {
                            const data = await fetchUploads(instruction.uploadId[0]._id);
                            return {
                                step: instruction.step,
                                upload: data,
                            };
                        } else {
                            return {
                                step: instruction.step,
                                upload: null,
                            };
                        }
                    })
                );
                setInstrustions(instructionsData.filter(Boolean));
            } catch (error) {
                console.error([
                    { name: error.response?.data?.message || error.response?.data || error.message },
                ]);
            } finally {
                abctrl.abort();
            }
        };

        fetchData();

        return () => abctrl.abort();
    }, [fetchInstruction, fetchUploads, id]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget.elements;

        const formInputs = {
            name: form.name.value,
            instructionType: instructionType,
            instructionCatagory: instructionCategory,
            description: form.description.value,
            tools: tools,
            instructionSteps: instructions.map(({ step, upload }) => ({ step, upload })),
            company: auth.user.company,
        };
        if (event.currentTarget.checkValidity() === true) {
            updateInstructionApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "Instruction updated") navigate(`${paths.ADMININSTRUCTION}`);
                })
                .catch((er) => {
                    setCreateInstructionResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const handleInstructionTypeChange = (event) => {
        const selectedType = event.target.value;
        setInstructionType(selectedType);
        setInstructionCategory("");
    };

    const handleInstructionCategoryChange = (event) => {
        setInstructionCategory(event.target.value);
    };

    const instructionTypeCategories = {
        Locksmith: [
            "Commercial Rekey",
            "Commercial Diagnose",
            "Commercial Hardware Install/Repair",
            "Commercial Other",
            "Residential Rekey",
            "Residential Hardware Install/Repair",
            "Residential Diagnose",
            "Residential Other",
            "Automotive Diagnose",
            "Automotive Install/Repair",
            "Automotive Rekey",
            "Automotive EEPROM",
            "Automotive Other",
            "Automotive Lockouts",
            "Commercial Lockouts",
            "Residential Lockouts",
            "Other Lockouts",
            "Safe Open/Repair",
            "Access Control",
            "Surveillance",
            "Other",
        ],
        AirDuct: ["CategoryX", "CategoryY", "CategoryZ"],
        Chimney: ["CategoryX", "CategoryY", "CategoryZ"],
        Garage: ["CategoryX", "CategoryY", "CategoryZ"],
    };

    const handleToolsChange = (event, index) => {
        const updatedTools = [...tools];
        updatedTools[index] = event.target.value;
        setTools(updatedTools);
    };

    const handleDeleteTool = (index) => {
        const updatedTools = [...tools];
        updatedTools.splice(index, 1);
        setTools(updatedTools);
    };

    const handleAddTool = () => {
        setTools([...tools, ""]);
    };

    const handleInstructionChange = async (index, field, value) => {
        const updatedInstructions = [...instructions];

        if (field === "upload") {
            const imageFormData = new FormData();
            imageFormData.append("file", value);
            imageFormData.append("company", auth.user.company);

            try {
                const response = await axios.post("/api/uploads/add", imageFormData);
                const data = await fetchUploads(response.data.file._id);
                updatedInstructions[index][field] = data;
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        } else {
            updatedInstructions[index][field] = value;
        }

        setInstrustions(updatedInstructions);
    };

    const handleDeleteInstruction = (index) => {
        const updatedInstructions = [...instructions];
        updatedInstructions.splice(index, 1);
        setInstrustions(updatedInstructions);
    };

    const handleAddInstruction = () => {
        setInstrustions([...instructions, { step: '', upload: null }]);
    };

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-7">
                        <h1 className="text-center">Update Instruction</h1>
                        <form validated={validated.toString()} onSubmit={handleSubmit} className="row pt-4 g-3">
                            <div>
                                <label htmlFor="name">Instruction Name:</label>
                                <input type="text"
                                    className="form-control"
                                    id="name"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    name="name"
                                />
                            </div>
                            <div>
                                <label htmlFor="description">Description:</label>
                                <input type="text"
                                    className="form-control"
                                    id="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    name="description" />
                            </div>
                            <div className="row m-0 p-0 mt-3 mb-2">
                                <div className="col">
                                    <label htmlFor="instructionType">Instruction Type:</label>
                                    <select
                                        className="form-control"
                                        id="instructionType"
                                        name="instructionType"
                                        value={instructionType}
                                        onChange={handleInstructionTypeChange}
                                    >
                                        <option value="Locksmith">Locksmith</option>
                                        <option value="AirDuct">Air Duct</option>
                                        <option value="Chimney">Chimney</option>
                                        <option value="Garage">Garage Door/Gates</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="instructionCategory">Instruction Category:</label>
                                    <select
                                        className="form-control"
                                        id="instructionCategory"
                                        name="instructionCategory"
                                        value={instructionCategory}
                                        onChange={handleInstructionCategoryChange}
                                        disabled={!instructionType}
                                    >
                                        <option value="" disabled>Select Instruction Type</option>
                                        {instructionTypeCategories[instructionType] &&
                                            instructionTypeCategories[instructionType].map((category, index) => (
                                                <option key={index * index} value={category}>{category}</option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="row m-0 p-0 d-flex justify-content-between mb-3">
                                    <div className="col align-middle text-start mt-2">
                                        <label>Tools:</label>
                                    </div>
                                    <div className="col align-middle text-end">
                                        <button type="button" onClick={handleAddTool} className="btn btn-sm btn-outline-primary">Add Tool</button>
                                    </div>
                                </div>
                                {tools.map((tool, index) => (
                                    <>
                                        <div className="input-group mb-3">
                                            <input
                                                value={tool}
                                                onChange={(e) => handleToolsChange(e, index)}
                                                type="text"
                                                className="form-control"
                                                placeholder="Tool Needed"
                                                aria-label="Tool Needed"
                                                aria-describedby="button-addon2" />
                                            <button
                                                className="btn btn-outline-danger"
                                                type="button"
                                                id="button-addon2"
                                                onClick={() => handleDeleteTool(index)}
                                            >
                                                x
                                            </button>
                                        </div>
                                    </>
                                ))}
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="row m-0 p-0 d-flex justify-content-between mb-3">
                                    <div className="col align-middle text-start mt-2">
                                        <label>Instruction Steps:</label>
                                    </div>
                                    <div className="col align-middle text-end">
                                        <button type="button" onClick={handleAddInstruction} className="btn btn-sm btn-outline-primary">Add Step</button>
                                    </div>
                                </div>
                                {instructions.map((instruction, index) => (
                                    <>
                                        <div className="row m-0 p-0" key={index}>
                                            <div className="col input-group mb-3">
                                                <input
                                                    value={instruction.step}
                                                    onChange={(e) => handleInstructionChange(index, 'step', e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Instruction Step"
                                                    aria-label="Instruction Step"
                                                    aria-describedby="button-addon3" />
                                                <button
                                                    className="btn btn-outline-danger"
                                                    type="button"
                                                    id="button-addon3"
                                                    onClick={() => handleDeleteInstruction(index)}
                                                >
                                                    x
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row m-0 p-0 mt-3 mb-3">
                                            {instruction.upload && (
                                                <div className="col-sm-6">
                                                    <img className="img-fluid" alt={instruction.upload.fileName} src={instruction.upload.imageUrl} />
                                                </div>
                                            )}
                                            <div className="col-sm">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="image"
                                                    onChange={(e) => handleInstructionChange(index, 'upload', e.target.files[0])}
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                            <div className="text-center pt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMININSTRUCTION} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createInstructionResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default AdminUpdateInstructionComponent;