import React, { useState } from "react";

const InvoiceDescriptionComponent = ({ description, onConfirm, onCancel }) => {
    const [inputValue, setInputValue] = useState(description);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleApplyDescription = () => {
        onConfirm(inputValue);
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box-estimate">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder bg-light deleteWidth mb-4">
                        <div className="col-sm fs-5">
                            Invoice Description
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-12 mt-2">
                            <div className="mb-3">
                                <textarea onChange={handleInputChange} value={inputValue} className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row  bg-light mt-5 border-top pt-3 pb-3 border-dark">
                        <div className="col-sm">
                            <button onClick={handleApplyDescription} className="btn btn-outline-primary m-0 ps-5 pe-5">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceDescriptionComponent;
