import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const NewTaskComponent = ({
    createTaskApiRequest,
    fetchUsers,
    fetchTechInfo
}) => {
    const [validated, setValidated] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState("None");
    const [selectedTech, setSelectedTech] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [filteredTech, setFilteredTech] = useState([]);
    const [filteredUsersTech, setFilteredUsersTech] = useState([]);
    const [selectedSpecificUser, setSelectedSpecificUser] = useState(true);
    const [selectedRepeat, setSelectedRepeat] = useState(false);
    const [selectedOten, setSelectedOften] = useState("day");
    const [selectedEndDate, setSelectedEndDate] = useState(true);
    const [selectedRepeatDate, setSelectedRepeatDate] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const auth = useSelector(state => state.auth)
    const [createTaskInfoResponseState, setCreateTaskInfoResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    useEffect(() => {
        const abctrl = new AbortController();
        fetchUsers()
            .then((data) => {
                const filteredUserTech = data.filter((user) => user.company === auth.user.company);

                setFilteredUsersTech(filteredUserTech);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        fetchTechInfo()
            .then((data) => {
                const filteredTech = data.filter((tech) => tech.company === auth.user.company);

                setFilteredTech(filteredTech);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        setSelectedDate(new Date().toISOString());

        return () => abctrl.abort();
    }, [fetchUsers, auth, setSelectedDate, fetchTechInfo]);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        setSubmitted(true);

        const form = event.currentTarget.elements;
        const formInputs = {
            title: form.title.value,
            description: form.description.value,
            priority: selectedPriority,
            status: 'Not Started',
            deadline: selectedDate,
            completed: false,
            techs: selectedTech,
            company: auth.user.company,
            user: auth.user._id,
            userSpecific: selectedSpecificUser,
            contactForm: false,
            repeat: selectedRepeat,
            repeatsEvery: selectedOten,
            ends: selectedEndDate,
            endDeadline: selectedRepeatDate,
        };

        let isValid = true;

        if (!form.title.value) {
            form.title.classList.add('is-invalid');
            isValid = false;
        } else {
            form.title.classList.remove('is-invalid');
        }

        if (!form.description.value) {
            form.description.classList.add('is-invalid');
            isValid = false;
        } else {
            form.description.classList.remove('is-invalid');
        }

        if (selectedTech.length === 0) {
            document.getElementById('techs').classList.add('is-invalid');
            isValid = false;
        } else {
            document.getElementById('techs').classList.remove('is-invalid');
        }

        if (isValid) {
            createTaskApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "task created") navigate(`${paths.TASKSLIST}`);
                })
                .catch((er) => {
                    setCreateTaskInfoResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const getTechName = (techId) => {
        const techUser = filteredUsersTech.find((user) => user._id === techId);
        return techUser ? techUser.name : "";
    };

    const handleTechChange = (event) => {
        const selectedValue = JSON.parse(event.target.value);

        setSelectedTech([...selectedTech, selectedValue._id]);
    };

    const getSelectedTechInfo = () => {
        return selectedTech.map((selectedTechId) => {
            const techItem = filteredTech.find((techItem) => techItem._id === selectedTechId);
            if (techItem) {
                const userName = getTechName(techItem.user);
                return { ...techItem, userName };
            }
            return null;
        }).filter(Boolean);
    };

    const handleRemoveTech = (techId) => {
        setSelectedTech(selectedTech.filter(id => id !== techId));
    };

    const handleSpecificUserChange = (e) => {
        const value = e.target.value;

        if (value === "True") {
            setSelectedSpecificUser(true);
        } else {
            setSelectedSpecificUser(false);
        }
    };

    const handleRepeatChange = (e) => {
        const value = e.target.value;

        if (value === "True") {
            setSelectedRepeat(true);
        } else {
            setSelectedRepeat(false);
        }
    };

    const handleEndDateChange = (e) => {
        const value = e.target.value;

        if (value === "True") {
            setSelectedEndDate(true);
        } else {
            setSelectedEndDate(false);
        }
    };

    const availableTechOptions = filteredTech.filter((techItem) => !selectedTech.includes(techItem._id));

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-7">
                        <h1 className="text-center">New Task</h1>
                        <form validated={validated.toString()} onSubmit={handleSubmit} className="row pt-4 g-3">
                            <div className="m-0 p-0 mt-4">
                                <label htmlFor="title">Title:</label>
                                <input type="text"
                                    className="form-control"
                                    id="title"
                                    name="title"
                                />
                            </div>
                            <div className="m-0 p-0 mt-4">
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    rows="3"
                                >
                                </textarea>
                            </div>
                            <div className="row justify-content-between m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 mt-4">
                                    <label htmlFor="repeat">Repeated Task:</label>
                                    <select
                                        className="form-select"
                                        id="repeat"
                                        name="repeat"
                                        value={selectedRepeat ? 'True' : 'False'}
                                        onChange={handleRepeatChange}
                                        required
                                    >
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </div>
                                {selectedRepeat ? (
                                    <>
                                        <div className="col-sm-4 m-0 p-0 mt-4">
                                            <label htmlFor="often">
                                                Repeats Every:
                                            </label>
                                            <select
                                                className="form-select"
                                                id="often"
                                                name="often"
                                                value={selectedOten}
                                                required
                                                onChange={(event) => setSelectedOften(event.target.value)}
                                            >
                                                <option value="Day">Day</option>
                                                <option value="Week">Week</option>
                                                <option value="Month">Month</option>
                                                <option value="Year">Year</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-3 m-0 p-0 align-end mt-4">
                                            <label htmlFor="startDate">
                                                Start Date:
                                            </label>
                                            <br />
                                            <DatePicker
                                                id="startDate"
                                                className="date-picker-input-Task"
                                                selected={new Date(selectedDate)}
                                                onChange={(date) => setSelectedDate(date.toISOString())}
                                                dateFormat="MM/dd/yyyy"
                                                showTimeInput
                                                required
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className="col ms-2 m-0 p-0 mt-4">
                                        <label htmlFor="startDate">
                                            Deadline:
                                        </label>
                                        <br />
                                        <DatePicker
                                            id="startDate"
                                            className="date-picker-input-Task"
                                            selected={new Date(selectedDate)}
                                            onChange={(date) => setSelectedDate(date.toISOString())}
                                            dateFormat="MM/dd/yyyy"
                                                showTimeInput
                                                required
                                        />
                                    </div>
                                )}
                            </div>
                            {selectedRepeat && (
                                <div className="row m-0 p-0">
                                    <div className="col-sm-6 m-0 p-0 mt-4">
                                        <label htmlFor="endDate">Never Ends:</label>
                                        <select
                                            className="form-select"
                                            id="endDate"
                                            name="endDate"
                                            value={selectedEndDate ? 'True' : 'False'}
                                            onChange={handleEndDateChange}
                                            required
                                        >
                                            <option value="True">True</option>
                                            <option value="False">False</option>
                                        </select>
                                    </div>
                                    {!selectedEndDate && (
                                        <div className="col ms-2 m-0 p-0 mt-4">
                                            <label htmlFor="startDate">
                                                End Date:
                                            </label>
                                            <br />
                                            <DatePicker
                                                id="startDate"
                                                className="date-picker-input-Task"
                                                selected={new Date(selectedRepeatDate)}
                                                onChange={(date) => setSelectedRepeatDate(date.toISOString())}
                                                dateFormat="MM/dd/yyyy"
                                                showTimeInput
                                                required
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="row m-0 p-0">
                                <div className="col m-0 p-0 mt-4">
                                    <label htmlFor="userSpecific">Not for everyone:</label>
                                    <select
                                        className="form-select"
                                        id="userSpecific"
                                        name="userSpecific"
                                        value={selectedSpecificUser ? 'True' : 'False'}
                                        onChange={handleSpecificUserChange}
                                        required
                                    >
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </div>
                                <div className="col ms-2 m-0 p-0 mt-4">
                                    <label htmlFor="priority">Priority:</label>
                                    <select
                                        className="form-select"
                                        id="priority"
                                        name="priority"
                                        value={selectedPriority}
                                        onChange={(e) => setSelectedPriority(e.target.value)}
                                        required
                                    >
                                        <option value="High">High</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Low">Low</option>
                                        <option value="None">No Priority</option>
                                    </select>
                                </div>
                            </div>
                            <div className="selected-techs">
                                {selectedTech.length > 0 && (
                                    <div className="d-flex flex-wrap">
                                        {getSelectedTechInfo().map((tech, index) => (
                                            <div key={tech._id} className="selected-tech mt-2 me-2">
                                                <span className="selected-user">
                                                    {tech.userName}
                                                    <button
                                                        type="button"
                                                        className="btn btn-link btn-remove-tech"
                                                        onClick={() => handleRemoveTech(tech._id)}
                                                    >
                                                        x
                                                    </button>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <select
                                className={`form-select mt-3 text-dark ${submitted && selectedTech.length === 0 && 'is-invalid'}`}
                                id="techs"
                                name="techs"
                                onChange={handleTechChange}
                                defaultValue={""}
                            >
                                <option defaultValue="">Assign Team Members</option>
                                {availableTechOptions.map((techItem) => (
                                    <option key={techItem._id} value={JSON.stringify(techItem)}>
                                        {getTechName(techItem.user)}
                                    </option>
                                ))}
                            </select>
                            <div className="text-center pt-3">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.TASKSLIST} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createTaskInfoResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewTaskComponent;