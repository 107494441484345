import axios from 'axios'
import { useDispatch } from "react-redux";
import EditYearComponent from './components/adminEditYearComponent';

const fetchYear = async (yearId) => {
    const { data } = await axios.get(`/api/vehicleYear/get-one/${yearId}`, { withCredentials: true });
    return data;
}

const updateYearApiRequest = async (yearId, formInputs) => {
    const { data } = await axios.put(`/api/vehicleYear/admin/${yearId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const fetchMake = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleMake/admin", { withCredentials: true })
    return data;
}
const fetchModel = async (make) => {
    const { data } = await axios.get(`/api/vehicleModel?make=${make}`, { withCredentials: true })
    return data;
}
const fetchYearModel = async (model) => {
    const { data } = await axios.get(`/api/vehicleYear?model=${model}`, { withCredentials: true });
    return data
}

const AdminEditYearPage = () => {

    const reduxDispatch = useDispatch();


    return <EditYearComponent fetchYearModel={fetchYearModel} fetchModel={fetchModel} fetchMake={fetchMake} fetchYear={fetchYear} updateYearApiRequest={updateYearApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditYearPage;
