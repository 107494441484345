import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import paths from './paths';
import NotFoundComponet from '../pages/notFoundPage';
import Layout from '../components/layout';
import HomePageComponent from '../pages/HomePage';
import LoginPage from '../pages/loginPage';
import ScrollToTop from '../utils/ScrollToTop';
import RegisterPage from '../pages/registerPage';
import UserProfilePage from '../pages/user/UserProfilePage';
import AdminMakePage from '../pages/admin/adminMakePage';
import AdminCreateMakePage from '../pages/admin/adminNewMakePage';
import AdminModelPage from '../pages/admin/adminModelPage';
import AdminCreateModelPage from '../pages/admin/adminNewModelPage';
import AdminEditMakePage from '../pages/admin/adminEditMakePage';
import AdminEditModelPage from '../pages/admin/adminEditModelPage';
import AdminLocksmithInfoPage from '../pages/admin/adminLocksmithInfoPage';
import AdminCreateLocksmithInfoPage from '../pages/admin/adminNewLocksmithInfoPage';
import AdminEditLocksmithInfoPage from '../pages/admin/adminEditLocksmithInfoPage';
import LocksmithInfoPage from '../pages/locksmithInfoPage';
import AdminYearPage from '../pages/admin/adminYearPage';
import AdminCreateYearPage from '../pages/admin/adminNewYearPage';
import AdminEditYearPage from '../pages/admin/adminEditYearPage';
import AdminChoicePage from '../pages/admin/adminChoicePage';
import AdminCompanyPage from '../pages/admin/adminCompanyPage';
import AdminCreateCompanyPage from '../pages/admin/adminNewCompanyPage';
import AdminEditCompanyPage from '../pages/admin/adminEditCompanyComponent';
import UserCompanyPage from '../pages/user/userCompanyPage';
import CustomerPage from '../pages/dispatch/customerPage';
import CreateCustomerPage from '../pages/dispatch/newCustomerPage';
import EditCustomerPage from '../pages/dispatch/editCustomerPage';
import DispatchPage from '../pages/dispatch/dispatchPage';
import JobTypePage from '../pages/dispatch/jobTypePage';
import EditJobTypePage from '../pages/dispatch/jobTypeEditPage';
import CreateJobTypePage from '../pages/dispatch/newJobTypePage';
import JobSourcePage from '../pages/dispatch/jobSourcePage';
import CreateJobSourcePage from '../pages/dispatch/newJobSourcePage';
import EditJobSourcePage from '../pages/dispatch/editJobSourcePage';
import DispatchJobPage from '../pages/dispatch/dispatchJobPage';
import DispatchPanelJobPage from '../pages/dispatch/dispatchPanelJobPage';
import JobTagPage from '../pages/dispatch/jobTagPage';
import CreateJobTagPage from '../pages/dispatch/newJobTagPage';
import EditJobTagPage from '../pages/dispatch/editJobTagPage';
import JobItemPage from '../pages/dispatch/jobItemPage';
import CreateJobItemPage from '../pages/dispatch/newJobItemPage';
import AdminTaxPage from '../pages/admin/adminTaxPage';
import EditJobItemsPage from '../pages/dispatch/editJobItemsPage';
import AdminCreateJobTaxPage from '../pages/admin/adminNewTaxPage';
import AdminEditTaxPage from '../pages/admin/adminEditTaxPage';
import CalendarPage from '../pages/dispatch/calendarPage';
import OtherJobSelectPage from '../pages/otherJobSelect';
import EstimatePage from '../pages/dispatch/estimatePage';
import AdminMainUsersPage from '../pages/admin/adminUserMainPage';
import AdminEditMainUserPage from '../pages/admin/adminEditMainUsersPage';
import AdminNewUserMainPage from '../pages/admin/adminNewUserMainPage';
import ClientEstimatePage from '../pages/clientEstimatePage';
import InvoicePage from '../pages/dispatch/invoicePage';
import ClientInvoicePage from '../pages/clientInvoicePage';
import InventoryItemPage from '../pages/inventory/inventoryItemPage';
import InventoryAddPage from '../pages/inventory/inventoryAddPage';
import InventoryEditPage from '../pages/inventory/inventoryEditPage';
import InventoryOrderPage from '../pages/inventory/inventoryOrderPage';
import InventoryIncreasePage from '../pages/inventory/inventoryIncreasePage';
import AdminLocationPage from '../pages/admin/adminLocationPage';
import AdminNewLocationPage from '../pages/admin/adminNewLocationPage';
import AdminEditLocationPage from '../pages/admin/adminEditLocationPage';
import InventoryItemTechPage from '../pages/inventory/inventoryItemTechPage';
import InventoryRequestPage from '../pages/inventory/inventoryRequestPage';
import LocksmithSelectPage from '../pages/locksmithSelectPage';
import InventoryRequestStatusPage from '../pages/inventory/inventoryRequestStatusPage';
import AdminInstructionPage from '../pages/admin/adminInstructionPage';
import AdminCreateInstructionPage from '../pages/admin/adminNewInstructionPage';
import AdminUpdateInstructionPage from '../pages/admin/adminUpdateInstructionPage';
import LocksmithHelpPage from '../pages/locksmtihHelpPage';
import LocksmithHelpIndexPage from '../pages/locksmithHelpIndexPage';
import VinDecoderPage from '../pages/vinDecoderPage';
import AdminCodesPage from '../pages/admin/adminCodesPage';
import AdminCreateCodesPage from '../pages/admin/adminNewCodesPage';
import AdminEditCodesPage from '../pages/admin/adminEditCodesPage';
import PinCodePage from '../pages/pinCodePage';
import AdminVinPricePage from '../pages/admin/vinPricePage';
import AdminNewVinPrice from '../pages/admin/adminNewVinPrice';
import AdminEditVinPricePage from '../pages/admin/adminEditVinPricePage';
import PayPinCodePage from '../pages/payPinCodePage';
import AdminDiscountCodesPage from '../pages/admin/discountCodesPage';
import AdminCreateDiscountCodesPage from '../pages/admin/adminNewDiscountCodePage';
import AdminEditDiscountCodesPage from '../pages/admin/adminEditDiscountCodesPage';
import VinKeyCodeInfoPage from '../pages/vinKeyCodeInfoPage';
import AdminEditCodeRequestPage from '../pages/admin/adminEditCodeRequestPage';
import AdminRequestCodesPage from '../pages/admin/adminCodeRequestPage';
import WhatsappFormComponent from '../components/whatsappFormComponent';
import DispatchOpenQuotePage from '../pages/dispatch/dispatchOpenQuotePage';
import InventoryToolPage from '../pages/inventory/inventoryToolPage';
import TasksPage from '../pages/tasks/tasksPage';
import NewTaskPage from '../pages/tasks/newTaskPage';
import TimeEntryPage from '../pages/timeEntry/timeEntryPage';
import WorkSchedulePage from '../pages/timeEntry/workSchedulePage';
import CreateWorkSchedulePage from '../pages/timeEntry/newWorkSchedulePage';
import RequestTimeOffPage from '../pages/timeEntry/requestTimeOffPage';
import CustContactFormPage from '../pages/customer/custContactFormPage';
import ProtectedRoute from './protectedRoutes';
import VerifyUserPage from '../pages/user/verifyUserPage';
import UserForgotPasswordPage from '../pages/user/userForgotPasswordPage';
import Login2FAVerifyPage from '../pages/user/login2FAVerifyPage';
import AdminUpdateTimePage from '../pages/timeEntry/adminUpdateTimePage';

const RouterComponent = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Layout>
                <Routes>
                     {/*Routes for non-logged in users*/}
                    <Route path={paths.LOGIN} caseSensitive={false} element={<LoginPage />} />
                    <Route path={paths.CLIENTINVOICE} caseSensitive={false} element={<ClientInvoicePage />} />
                    <Route path={paths.CLIENTESTIMATE} caseSensitive={false} element={<ClientEstimatePage />} />
                    <Route path={paths.REGISTER} caseSensitive={false} element={<RegisterPage />} />
                    <Route path={paths.NOTFOUND} caseSensitive={false} element={<NotFoundComponet />} />
                    <Route path={paths.CUSTCONTACT} caseSensitive={false} element={<CustContactFormPage />} />
                    <Route path={paths.VERIFY} caseSensitive={false} element={<VerifyUserPage />} />
                    <Route path={paths.PASSWORDASSISTANCE} caseSensitive={false} element={<UserForgotPasswordPage />} />
                    <Route path={paths.OTPVERIFY} caseSensitive={false} element={<Login2FAVerifyPage />} />

                    {/*Routes for the technition*/}
                    <Route path={paths.ROOT} caseSensitive={false} element={<ProtectedRoute component={HomePageComponent} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.LOCKSMITHSEARCH} caseSensitive={false} element={<ProtectedRoute component={LocksmithSelectPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.USERCOMPANY} caseSensitive={false} element={<ProtectedRoute component={UserCompanyPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.USERPROFILE} caseSensitive={false} element={<ProtectedRoute component={UserProfilePage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.MODELSEARCH} caseSensitive={false} element={<ProtectedRoute component={LocksmithInfoPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.LOCKSMITHHELP} caseSensitive={false} element={<ProtectedRoute component={LocksmithHelpPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.LOCKSMITHHELPINDEX} caseSensitive={false} element={<ProtectedRoute component={LocksmithHelpIndexPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.DECODEVIN} caseSensitive={false} element={<ProtectedRoute component={VinDecoderPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.MODELSEARCHVIN} caseSensitive={false} element={<ProtectedRoute component={LocksmithInfoPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.BUYKEYCODEVIN} caseSensitive={false} element={<ProtectedRoute component={PinCodePage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.BUYKEYCODE} caseSensitive={false} element={<ProtectedRoute component={PinCodePage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.PAYLOCKSMITHREQUEST} caseSensitive={false} element={<ProtectedRoute component={PayPinCodePage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.DISPATCHCALENDAR} caseSensitive={false} element={<ProtectedRoute component={CalendarPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.LOCKSMITHVINKEYCODEINFO} caseSensitive={false} element={<ProtectedRoute component={VinKeyCodeInfoPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.SMSPATH} caseSensitive={false} element={<ProtectedRoute component={WhatsappFormComponent} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.TASKSLIST} caseSensitive={false} element={<ProtectedRoute component={TasksPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.NEWTASK} caseSensitive={false} element={<ProtectedRoute component={NewTaskPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.TIMEENTRY} caseSensitive={false} element={<ProtectedRoute component={TimeEntryPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.WORKSCHEDULE} caseSensitive={false} element={<ProtectedRoute component={WorkSchedulePage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />
                    <Route path={paths.REQUESTTIMEOFF} caseSensitive={false} element={<ProtectedRoute component={RequestTimeOffPage} roles={['Developer', 'Admin', 'Office', 'Inventory', 'Dispatcher', 'European', 'Technician']} />} />

                    {/*Routes for inventory*/}
                    <Route path={paths.INVENTORYMANAGERITEMS} caseSensitive={false} element={<ProtectedRoute component={InventoryItemPage} roles={['Developer', 'Admin', 'Inventory']} />} />
                    <Route path={paths.INVENTORYADD} caseSensitive={false} element={<ProtectedRoute component={InventoryAddPage} roles={['Developer', 'Admin', 'Inventory']} />} />
                    <Route path={paths.INVENTORYEDIT} caseSensitive={false} element={<ProtectedRoute component={InventoryEditPage} roles={['Developer', 'Admin', 'Inventory']} />} />
                    <Route path={paths.INVENTROYORDER} caseSensitive={false} element={<ProtectedRoute component={InventoryOrderPage} roles={['Developer', 'Admin', 'Inventory']} />} />
                    <Route path={paths.INVENTORYINCREASE} caseSensitive={false} element={<ProtectedRoute component={InventoryIncreasePage} roles={['Developer', 'Admin', 'Inventory']} />} />
                    <Route path={paths.INVENTORYREQUEST} caseSensitive={false} element={<ProtectedRoute component={InventoryRequestPage} roles={['Developer', 'Admin', 'Inventory']} />} />
                    <Route path={paths.INVENTORYREQUESTSTATUS} caseSensitive={false} element={<ProtectedRoute component={InventoryRequestStatusPage} roles={['Developer', 'Admin', 'Inventory']} />} />
                    <Route path={paths.INVENTORYMANAGERITEMSTECH} caseSensitive={false} element={<ProtectedRoute component={InventoryItemTechPage} roles={['Developer', 'Admin', 'Inventory']} />} />
                    <Route path={paths.INVENTORYTOOLS} caseSensitive={false} element={<ProtectedRoute component={InventoryToolPage} roles={['Developer', 'Admin', 'Inventory']} />} />

                    {/*Routes for dispatchers*/}
                    <Route path={paths.DISPATCHCUTOMER} caseSensitive={false} element={<ProtectedRoute component={CustomerPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHNEWCUSTOMER} caseSensitive={false} element={<ProtectedRoute component={CreateCustomerPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHEDITCUSTOMER} caseSensitive={false} element={<ProtectedRoute component={EditCustomerPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.PRICESEARCH} caseSensitive={false} element={<ProtectedRoute component={DispatchPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.PRICESEARCHVIN} caseSensitive={false} element={<ProtectedRoute component={DispatchPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHOTHER} caseSensitive={false} element={<ProtectedRoute component={DispatchPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHJOBTYPE} caseSensitive={false} element={<ProtectedRoute component={JobTypePage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHEDITJOBTYPE} caseSensitive={false} element={<ProtectedRoute component={EditJobTypePage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHNEWJOBTYPE} caseSensitive={false} element={<ProtectedRoute component={CreateJobTypePage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHJOBSOURCE} caseSensitive={false} element={<ProtectedRoute component={JobSourcePage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHNEWJOBSOURCE} caseSensitive={false} element={<ProtectedRoute component={CreateJobSourcePage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHEDITJOBSOURCE} caseSensitive={false} element={<ProtectedRoute component={EditJobSourcePage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHJOB} caseSensitive={false} element={<ProtectedRoute component={DispatchJobPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHPANELJOB} caseSensitive={false} element={<ProtectedRoute component={DispatchPanelJobPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHJOBTAG} caseSensitive={false} element={<ProtectedRoute component={JobTagPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHNEWJOBTAG} caseSensitive={false} element={<ProtectedRoute component={CreateJobTagPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHEDITJOBTAG} caseSensitive={false} element={<ProtectedRoute component={EditJobTagPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHJOBITEM} caseSensitive={false} element={<ProtectedRoute component={JobItemPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHNEWJOBITEM} caseSensitive={false} element={<ProtectedRoute component={CreateJobItemPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHEDITJOBITEM} caseSensitive={false} element={<ProtectedRoute component={EditJobItemsPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.OTHERJOB} caseSensitive={false} element={<ProtectedRoute component={OtherJobSelectPage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHESTIMATE} caseSensitive={false} element={<ProtectedRoute component={EstimatePage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.INVOICE} caseSensitive={false} element={<ProtectedRoute component={InvoicePage} roles={['Developer', 'Admin', 'Dispatcher']} />} />
                    <Route path={paths.DISPATCHOPENQUOTES} caseSensitive={false} element={<ProtectedRoute component={DispatchOpenQuotePage} roles={['Developer', 'Admin', 'Dispatcher']} />} />

                    {/*Routes for site admins*/}
                    <Route path={paths.ADMINSEARCH} caseSensitive={false} element={<ProtectedRoute component={AdminChoicePage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINSEARCHVIN} caseSensitive={false} element={<ProtectedRoute component={AdminChoicePage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINCOMPANY} caseSensitive={false} element={<ProtectedRoute component={AdminCompanyPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINNEWCOMPANY} caseSensitive={false} element={<ProtectedRoute component={AdminCreateCompanyPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINEDITCOMPANY} caseSensitive={false} element={<ProtectedRoute component={AdminEditCompanyPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINTAX} caseSensitive={false} element={<ProtectedRoute component={AdminTaxPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINNEWTAX} caseSensitive={false} element={<ProtectedRoute component={AdminCreateJobTaxPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINEDITTAX} caseSensitive={false} element={<ProtectedRoute component={AdminEditTaxPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINROOTUSERS} caseSensitive={false} element={<ProtectedRoute component={AdminMainUsersPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINROOTEDITUSER} caseSensitive={false} element={<ProtectedRoute component={AdminEditMainUserPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINROOTNEWUSER} caseSensitive={false} element={<ProtectedRoute component={AdminNewUserMainPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINLOCATION} caseSensitive={false} element={<ProtectedRoute component={AdminLocationPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINNEWLOCATION} caseSensitive={false} element={<ProtectedRoute component={AdminNewLocationPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINEDITLOCATION} caseSensitive={false} element={<ProtectedRoute component={AdminEditLocationPage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.NEWWORKSCHEDULE} caseSensitive={false} element={<ProtectedRoute component={CreateWorkSchedulePage} roles={['Developer', 'Admin']} />} />
                    <Route path={paths.ADMINUPDATETIME} caseSensitive={false} element={<ProtectedRoute component={AdminUpdateTimePage} roles={['Developer', 'Admin']} />} />

                    {/*Routes for Developer*/}
                    <Route path={paths.ADMINMODEL} caseSensitive={false} element={<ProtectedRoute component={AdminModelPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINNEWMODEL} caseSensitive={false} element={<ProtectedRoute component={AdminCreateModelPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINEDITMODEL} caseSensitive={false} element={<ProtectedRoute component={AdminEditModelPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINMAKE} caseSensitive={false} element={<ProtectedRoute component={AdminMakePage} roles={['Developer']} />} />
                    <Route path={paths.ADMINEDITMAKE} caseSensitive={false} element={<ProtectedRoute component={AdminEditMakePage} roles={['Developer']} />} />
                    <Route path={paths.ADMINNEWMAKE} caseSensitive={false} element={<ProtectedRoute component={AdminCreateMakePage} roles={['Developer']} />} />
                    <Route path={paths.ADMINYEAR} caseSensitive={false} element={<ProtectedRoute component={AdminYearPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINNEWYEAR} caseSensitive={false} element={<ProtectedRoute component={AdminCreateYearPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINEDITYEAR} caseSensitive={false} element={<ProtectedRoute component={AdminEditYearPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINLOCKSMITHINFO} caseSensitive={false} element={<ProtectedRoute component={AdminLocksmithInfoPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINNEWLOCKSMITHINFO} caseSensitive={false} element={<ProtectedRoute component={AdminCreateLocksmithInfoPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINEDITLOCKSMITHINFO} caseSensitive={false} element={<ProtectedRoute component={AdminEditLocksmithInfoPage} roles={['Developer']} />} />
                    <Route path={paths.ADMININSTRUCTION} caseSensitive={false} element={<ProtectedRoute component={AdminInstructionPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINNEWINSTRUCTION} caseSensitive={false} element={<ProtectedRoute component={AdminCreateInstructionPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINEDITINSTRUCTION} caseSensitive={false} element={<ProtectedRoute component={AdminUpdateInstructionPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINCODES} caseSensitive={false} element={<ProtectedRoute component={AdminCodesPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINNEWCODES} caseSensitive={false} element={<ProtectedRoute component={AdminCreateCodesPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINEDITCODES} caseSensitive={false} element={<ProtectedRoute component={AdminEditCodesPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINVINPRICE} caseSensitive={false} element={<ProtectedRoute component={AdminVinPricePage} roles={['Developer']} />} />
                    <Route path={paths.ADMINNEWVINPRICE} caseSensitive={false} element={<ProtectedRoute component={AdminNewVinPrice} roles={['Developer']} />} />
                    <Route path={paths.ADMINEDITVINPRICE} caseSensitive={false} element={<ProtectedRoute component={AdminEditVinPricePage} roles={['Developer']} />} />
                    <Route path={paths.ADMINDISCOUNTCODES} caseSensitive={false} element={<ProtectedRoute component={AdminDiscountCodesPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINNEWDISCOUNTCODES} caseSensitive={false} element={<ProtectedRoute component={AdminCreateDiscountCodesPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINEDITDISCOUNTCODES} caseSensitive={false} element={<ProtectedRoute component={AdminEditDiscountCodesPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINCODEREQUESTS} caseSensitive={false} element={<ProtectedRoute component={AdminRequestCodesPage} roles={['Developer']} />} />
                    <Route path={paths.ADMINEDITCODEREQUEST} caseSensitive={false} element={<ProtectedRoute component={AdminEditCodeRequestPage} roles={['Developer']} />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
};

export default RouterComponent;