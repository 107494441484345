import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../router/paths";

const AdminEditDiscountCodesComponent = ({
    fetchCodes,
    updateCodesApiRequest
}) => {
    const [validated, setValidated] = useState(false);
    const [selectedValue, setSelectedValue] = useState('false');
    const [inputValue, setInputValue] = useState('');
    const [inputType, setInputType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [code, setCodes] = useState('');
    const [createCodesResponseState, setCreateCodesResponseState] = useState({
        message: "",
        error: "",
    });

    const { id } = useParams();
    const navigate = useNavigate();

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        let month = `${date.getMonth() + 1}`;
        let day = `${date.getDate()}`;
        const year = date.getFullYear();

        if (month.length < 2) month = `0${month}`;
        if (day.length < 2) day = `0${day}`;

        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        fetchCodes(id)
            .then((data) => {

                setCodes(data);
                setSelectedValue((data.forever).toString());
                setInputType(data.discountType);
                setInputValue(data.discount);
                setStartDate(formatDate(data.startDate));
                setEndDate(formatDate(data.endDate));
            })
            .catch((er) => console.log(er));
    }, [id, fetchCodes]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const timeStartString = `${startDate}T22:50:55Z`;
        const timeEndString = `${endDate}T22:50:55Z`;

        if (endDate) {
            const form = event.currentTarget.elements;
            const formInputs = {
                discountCode: form.discountCode.value,
                startDate: timeStartString,
                endDate: timeEndString,
                discount: inputValue,
                discountType: inputType,
                forever: selectedValue,
            };
            if (event.currentTarget.checkValidity() === true) {
                updateCodesApiRequest(id, formInputs)
                    .then((data) => {
                        if (data.message === "Code updated") navigate(`${paths.ADMINDISCOUNTCODES}`);
                    })
                    .catch((er) => {
                        setCreateCodesResponseState({
                            error: er.response.data.message
                                ? er.response.data.message
                                : er.response.data,
                        });
                    });
            }
        } else {
            const form = event.currentTarget.elements;
            const formInputs = {
                discountCode: form.discountCode.value,
                startDate: timeStartString,
                endDate: '',
                discount: inputValue,
                discountType: inputType,
                forever: selectedValue,
            };
            if (event.currentTarget.checkValidity() === true) {
                updateCodesApiRequest(id, formInputs)
                    .then((data) => {
                        if (data.message === "Code updated") navigate(`${paths.ADMINDISCOUNTCODES}`);
                    })
                    .catch((er) => {
                        setCreateCodesResponseState({
                            error: er.response.data.message
                                ? er.response.data.message
                                : er.response.data,
                        });
                    });
            }
        }
        setValidated(true);
    };

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    const handleInputChange = (e) => {
        setInputValue(parseFloat(e.target.value));
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const getTodayDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    };

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-center">Edit Code</h1>
                        <form validated={validated.toString()} onSubmit={handleSubmit} className="row pt-4 g-3">
                            <div className="row mt-3 m-0 p-0">
                                <div className="col m-0 p-0">
                                    <label htmlFor="discountCode">Discount Code:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="discountCode"
                                        name="discountCode"
                                        defaultValue={code.discountCode}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col m-0 p-0">
                                    <label htmlFor="discountDisplay">Discount Amount:</label>
                                    <input
                                        className="form-control"
                                        id="discountDisplay"
                                        type="number"
                                        onChange={handleInputChange}
                                        defaultValue={inputValue}
                                        aria-label="Disabled input example"
                                    />
                                </div>
                                <div className="col mt-4">
                                    <div className="btn-group me-2" role="group" aria-label="Discount Type">
                                        <button
                                            type="button"
                                            className={`btn btn-sm ${inputType === 'percentage' ? 'btn-selected' : 'btn-unselected'}`}
                                            onClick={() => setInputType('percentage')}
                                        >
                                            %
                                        </button>
                                        <button
                                            type="button"
                                            className={`btn btn-sm ${inputType === 'amount' ? 'btn-selected' : 'btn-unselected'}`}
                                            onClick={() => setInputType('amount')}
                                        >
                                            $
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-start ms-0 mt-2 me-0 pe-0 ps-0">
                                <div className="col m-0 p-0">
                                    <label htmlFor="trueFalseSelect">No End Date:</label>
                                    <select
                                        id="trueFalseSelect"
                                        className="form-select"
                                        value={selectedValue.toString()}
                                        onChange={handleSelectChange}
                                    >
                                        <option value="">Select An Option</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row justify-content-between mt-3 m-0 p-0">
                                <div className="col-sm-5 m-0 p-0">
                                    <label>Start Date:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        min={new Date(code.startDate)}
                                    />
                                </div>
                                {selectedValue === 'false' && (
                                    <div className="col-sm-5 m-0 p-0">
                                        <label>End Date:</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            min={getTodayDate()}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="text-center pt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINDISCOUNTCODES} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createCodesResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminEditDiscountCodesComponent;