import React, { useState } from "react";
import '../assets/inventory.css';
import { useEffect } from "react";

const InventoryConfirmReqPopUpComponent = ({ selectedItemIn, onConfirm, onCancel }) => {
    const [quantity, setQuantity] = useState('');

    useEffect(() => {
        if (selectedItemIn.requestData) {
            setQuantity(selectedItemIn.requestData.quantity);
        }
    }, [selectedItemIn]);

    const handleConfirm = () => {
        if (selectedItemIn.requestData) {
            if (parseInt(quantity, selectedItemIn.requestData._id) > 0) {
                onConfirm(quantity, selectedItemIn.requestData._id);
            }
        } else {
            if (parseInt(quantity) > 0) {
                onConfirm(quantity);
            }
        }
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box-req">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="popup">
                        <h2 className="popup-header">Request Stock</h2>
                        <div className="popup-1">
                            <p className="popup-message text-start">
                                Enter the quantity to approve, <br />
                                <span className="ps-3">{`(Your Available Stock: ${selectedItemIn.item.quantity}):`}</span>
                            </p>
                            <div className="text-start">
                                <label className="text-start" for="numbe">Stock Requested:</label>
                                <input
                                    type="number"
                                    id="numbe"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    placeholder="Enter quantity"
                                    className="quantity-input"
                                />
                            </div>
                            <div className="row deleteWidth bg-light justify-content-center border-top pt-3 border-dark">
                                <div className="col-sm">
                                    <button onClick={handleConfirm} disabled={(quantity === '') || (selectedItemIn.item.quantity < quantity) || (quantity === '0')} className="btn btn-outline-success loginButton">
                                        Confirm
                                    </button>
                                </div>
                                <div className="col-sm">
                                    <button onClick={onCancel} className="btn btn-outline-primary loginButton">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InventoryConfirmReqPopUpComponent;
