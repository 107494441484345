import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { verify } from "../../../redux/actions/action";
import { useParams, useNavigate } from 'react-router-dom';
import paths from "../../../router/paths";

const Login2FAVerifyComponent = () => {
    const { email } = useParams();
    const [formState, setFormState] = useState({
        otp: '',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formInputs = {
            otp: formState.otp,
            email: email,
        };

        dispatch(verify(formInputs, () => {
            navigate(paths.ROOT);
        }));
    };

    return (
        <div className="container mt-5 pt-5">
            <div className="row mt-5 pt-5 m-0 p-0 justify-content-center">
                <div className="col-sm-4 mt-5 border">
                    <h3 className="text-center pt-3 pt-2">Verify Identity</h3>
                    <form onSubmit={handleSubmit} className="row mt-4 g-3">
                        <div className="row m-0 p-0">
                            <div className="col mb-2">
                                <input
                                    type="text"
                                    id="otp"
                                    name="otp"
                                    placeholder="Verification Code"
                                    value={formState.otp}
                                    onChange={handleChange}
                                    className="signinFormInput form-control"
                                />
                            </div>
                        </div>
                        <div className="text-center mb-3">
                            {/*{error && <p className="text-center" style={{ color: 'red' }}>{error}</p>}*/}
                            {/*{success && <p className="text-center" style={{ color: 'green' }}>{success}</p>}*/}
                            <button type="submit" className="btn btn-outline-primary">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login2FAVerifyComponent;