import React, { useState, useEffect } from "react";
import '../assets/inventory.css';

const InventoryCheckInComponent = ({message, selectedItem, locations, onConfirm, onCancel }) => {
    const [quantity, setQuantity] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');

    useEffect(() => {
        if (locations) {
            const filteredDefaultLocations = locations.find(location => location.defaultLoc === true);

            setSelectedLocation(filteredDefaultLocations._id)
        }
    }, [locations, selectedItem.item.name1]);

    const handleConfirm = () => {
        if (parseInt(quantity) > 0 && selectedLocation) {
            onConfirm(quantity, selectedLocation);
        }
    };
   
    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="inventory-checkout-container">
                        <div className="popup">
                            <h2 className="popup-header">Increase Stock</h2>
                            <div className="popup-1">

                                <p className="popup-message">{message}</p>
                                <input
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    placeholder="Enter quantity"
                                    className="quantity-input"
                                />
                                <div className="row justify-content-center">
                                    <div className="col-sm-3 me-4">
                                        <button onClick={handleConfirm} disabled={quantity === ''} className="btn btn-outline-success loginButton">
                                            Confirm
                                        </button>
                                    </div>
                                    <div className="col-sm-3">
                                        <button onClick={onCancel} className="btn btn-outline-primary loginButton">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InventoryCheckInComponent;
