import axios from "axios";
import { useDispatch } from "react-redux";
import CreateInstructionPageComponent from "./components/adminNewInstructionComponent";

const createInstructionApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/instruction/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};
const fetchUploads = async (imageId) => {
    const { data } = await axios.get(`/api/uploads/image/${imageId}`, { withCredentials: true });
    return data
}

const AdminCreateInstructionPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateInstructionPageComponent
            fetchUploads={fetchUploads}
            createInstructionApiRequest={createInstructionApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreateInstructionPage;