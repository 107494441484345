import axios from 'axios'
import { useDispatch } from "react-redux";
import EditJobSourceComponent from './components/editJobSourceComponent';

const fetchJobSource = async (jobSourceId) => {
    const { data } = await axios.get(`/api/jobSource/get-one/${jobSourceId}`, { withCredentials: true });
    return data;
}

const updateJobSourceApiRequest = async (jobSourceId, formInputs) => {
    const { data } = await axios.put(`/api/jobSource/dispatch/${jobSourceId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const EditJobSourcePage = () => {

    const reduxDispatch = useDispatch();


    return <EditJobSourceComponent fetchJobSource={fetchJobSource} updateJobSourceApiRequest={updateJobSourceApiRequest} reduxDispatch={reduxDispatch} />;
};

export default EditJobSourcePage;
