import axios from "axios";
import CustContactFormComponent from "./components/custContactFormComponent";

const CustContactFormPage = () => {

    const submitContact = async (companyId, formInputs) => {
        const { data } = await axios.post(`/api/custContact?company=64c09f1cfcdd9b1f7cf1ff66`, { ...formInputs }, { withCredentials: true })
        return data;
    }

    return <CustContactFormComponent
        submitContact={submitContact}
    />;
};

export default CustContactFormPage;
