import axios from "axios";
import JobTypeComponent from "./components/jobTypeComponent";

const fetchJobType = async (abctrl) => {
    const { data } = await axios.get("/api/jobType/dispatch", { withCredentials: true });
    return data
}

const deleteJobType = async (jobTypeId) => {
    const { data } = await axios.delete(`/api/jobType/dispatch/${jobTypeId}`, { withCredentials: true });
    return data
}

const JobTypePage = () => {

    return <JobTypeComponent fetchJobType={fetchJobType} deleteJobType={deleteJobType} />;
};

export default JobTypePage;
