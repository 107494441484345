import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateSelectComponent = ({
    setSelectedDate,
    setSelectedDateEnd, // Define setSelectedDateEnd
    schedule,
    selectedDate, // Define selectedDate
    selectedDateEnd, // Define selectedDateEnd
}) => {
    const [isStartDateFocused, setIsStartDateFocused] = useState(false);
    const [isEndDateFocused, setIsEndDateFocused] = useState(false);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        if (schedule && schedule.start && schedule.end) {
            // Parse the start and end dates from the schedule
            const startDate = new Date(schedule.start);
            const endDate = new Date(schedule.end);

            // Update selectedDate and selectedDateEnd with formatted dates
            setSelectedDate(startDate.toISOString());
            setSelectedDateEnd(endDate.toISOString());
        } else {
            setSelectedDate(new Date().toISOString());
            setSelectedDateEnd(new Date().toISOString());
        }
    }, [schedule, setSelectedDate, setSelectedDateEnd]); // Add setSelectedDate and setSelectedDateEnd as dependencies

    return (
        <div className="col-sm mt-2">
            <div className={`date-picker-container ${isStartDateFocused ? "focused" : ""}`}>
                <DatePicker
                    id="startDate"
                    className="date-picker-input"
                    selected={new Date(selectedDate)}
                    onChange={handleDateChange}
                    onFocus={() => setIsStartDateFocused(true)}
                    onBlur={() => setIsStartDateFocused(false)}
                    dateFormat="MM/dd/yyyy"
                    showTimeInput
                />
                <label htmlFor="startDate" className="date-picker-label">
                    Starts
                </label>
            </div>
            <div className={`mt-2 mb-4 date-picker-container ${isEndDateFocused ? "focused" : ""}`}>
                <DatePicker
                    id="endDate"
                    className="date-picker-input"
                    selected={new Date(selectedDateEnd)}
                    onChange={(date) => setSelectedDateEnd(date.toISOString())}
                    onFocus={() => setIsEndDateFocused(true)}
                    onBlur={() => setIsEndDateFocused(false)}
                    dateFormat="MM/dd/yyyy"
                    showTimeInput
                />
                <label htmlFor="endDate" className="date-picker-label">
                    Ends
                </label>
            </div>
        </div>
    );
};

export default DateSelectComponent;
