import axios from "axios";
import LocksmithInfoListComponent from "./components/locksmithInfoComponent";

const LocksmithInfoPage = () => {

    const fetchLocksmithInfo = async (year, name, model) => {
        const { data } = await axios.get(`/api/locksmithInfo?model=${model}&make=${name}&years=${year}`, { withCredentials: true })
        return data;
    }
    const fetchMake = async (abctrl) => {
        const { data } = await axios.get("/api/vehicleMake/", { withCredentials: true })
        return data;
    }
    const fetchUploads = async () => {
        const { data } = await axios.get(`/api/uploads`, { withCredentials: true });
        return data
    }
    const fetchUploadsID = async (imageId) => {
        const { data } = await axios.get(`/api/uploads/image/${imageId}`, { withCredentials: true });
        return data
    }
    const fetchTechInfo = async (abctrl) => {
        const { data } = await axios.get("/api/techInfo/admin", { withCredentials: true });
        return data
    }
    const fetchUsers = async (abctrl) => {
        const { data } = await axios.get("/api/users", { withCredentials: true });
        return data
    }
    const fetchLocation = async (abctrl) => {
        const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
        return data
    }
    const fetchRequest = async (abctrl) => {
        const { data } = await axios.get("/api/request/", { withCredentials: true });
        return data
    }
    const createRequestApiRequest = async (formInputs) => {
        const { data } = await axios.post(`/api/request/post`, { ...formInputs }, { withCredentials: true });
        return data;
    };
    const fetchInstructionId = async (instructionId) => {
        const { data } = await axios.get(`/api/instruction/get-one/${instructionId}`, { withCredentials: true });
        return data;
    }

    return <LocksmithInfoListComponent
        fetchInstructionId={fetchInstructionId}
        fetchRequest={fetchRequest}
        createRequestApiRequest={createRequestApiRequest }
        fetchLocation={fetchLocation }
        fetchTechInfo={fetchTechInfo}
        fetchUsers={fetchUsers }
        fetchUploadsID={fetchUploadsID}
        fetchUploads={fetchUploads}
        fetchMake={fetchMake}
        fetchLocksmithInfo={fetchLocksmithInfo} />;
};

export default LocksmithInfoPage;
