import axios from 'axios'
import { useDispatch } from "react-redux";
import EditMakeComponent from './components/adminEditMakeComponent';

const fetchMake = async (makeId) => {
    const { data } = await axios.get(`/api/vehicleMake/get-one/${makeId}`, { withCredentials: true });
    return data;
}

const updateMakeApiRequest = async (makeId, formInputs) => {
    const { data } = await axios.put(`/api/vehicleMake/admin/${makeId}`, { ...formInputs }, { withCredentials: true });
    return data;
}



const AdminEditMakePage = () => {

    const reduxDispatch = useDispatch();


    return <EditMakeComponent fetchMake={fetchMake} updateMakeApiRequest={updateMakeApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditMakePage;
