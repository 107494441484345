import jsPDF from 'jspdf';

export async function EstimateGeneratePDF(companies, imgData, formatDateTime, estimate, filterJobTax, customer, jobItem, signatureData) {

    const doc = new jsPDF("p", "mm", "a4");
    const hasOptionalItems = jobItem.some(item => item.optional);

    if (imgData) {
        doc.addImage(imgData, "PNG", 10, 5, 80, 60);
    } 

    doc.setFontSize(25);
    doc.text("ESTIMATE", 160, 9);

    doc.setFontSize(10);
    doc.text(`${companies.name}`, 10, 80);
    doc.text(`${companies.address}`, 10, 85);
    doc.text(`${companies.city}, ${companies.state} ${companies.zip}, ${companies.country}`, 10, 90);
    doc.text(`${companies.email}`, 10, 95);
    doc.text(`${companies.phone}`, 10, 100);

    doc.setFontSize(10);
    doc.text(`Estimate #`, 130, 80);
    doc.text(`${estimate.count}`, 160, 80);
    doc.text(`Date`, 140, 85);
    doc.text(`${formatDateTime(estimate.updatedAt)}`, 160, 85);
    doc.text(`Total`, 140, 90);
    doc.text(`Total W/Optional`, 122, 95);
    doc.text(`Deposit`, 136, 100);
    if (hasOptionalItems) {
        doc.text(`Optional W/Deposit`, 118, 105);
    }

    doc.setFontSize(12);
    doc.text(`Prepared For:`, 10, 110);

    doc.setFontSize(10);
    doc.text(`${customer.name} ${customer.lastName}`, 12, 116);
    doc.text(`${customer.address}`, 12, 121);
    doc.text(`${customer.city}, ${customer.state} ${customer.zip}`, 12, 126);
    if (customer.email) {
        doc.text(`${customer.email}`, 12, 131);
    }

    const tableData = [
        ['Description', 'QTY', 'Price', 'Amount'],
        ...jobItem.map((item) => {
            const amount = `$${(item.quantity * item.price).toFixed(2)}`;
            const optionalText = item.optional ? 'Optional' : '';

            return [
                `${item.name}\n${item.description}`,
                item.quantity,
                `$${item.price}`,
                `${amount}\n${optionalText}`,
            ];
        }),
    ];

    let lastTableRowY = 0;

    const subtotal = jobItem.reduce((acc, row) => {
        if (!row.optional) {
            const amount = parseFloat(row.quantity * row.price);
            return acc + amount;
        }
        return acc;
    }, 0);

    const optionalTotal = jobItem.reduce((acc, row) => {
        const amount = parseFloat(row.quantity * row.price);
        return acc + amount;
    }, 0.00);

    let discountValue = 0;
    if (estimate.discountType === 'percentage') {
        discountValue = (estimate.discount / 100) * subtotal;
    } else if (estimate.discountType === 'amount') {
        discountValue = estimate.discount;
    }

    let discountValueOpt = 0;
    if (estimate.discountType === 'percentage') {
        discountValueOpt = (estimate.discount / 100) * optionalTotal;
    } else if (estimate.discountType === 'amount') {
        discountValueOpt = estimate.discount;
    }

    let depositValue = 0;
    if (estimate.depositType === 'percentage') {
        depositValue = (estimate.deposit / 100) * subtotal;
    } else if (estimate.depositType === 'amount') {
        depositValue = estimate.deposit;
    }

    let depositValueOpt = 0;
    if (estimate.depositType === 'percentage') {
        depositValueOpt = (estimate.deposit / 100) * optionalTotal;
    } else if (estimate.depositType === 'amount') {
        depositValueOpt = estimate.deposit;
    }

    const taxValue = (subtotal - discountValue) * filterJobTax.taxRate / 100;

    const taxValueOpt = (optionalTotal - discountValueOpt) * filterJobTax.taxRate / 100;

    const total = (subtotal - discountValue) + taxValue;
    const optional = (optionalTotal - discountValueOpt) + taxValueOpt;

    doc.autoTable({
        startY: 135,
        head: tableData.slice(0, 1),
        body: tableData.slice(1),
        didDrawPage: function (data) {
            lastTableRowY = data.cursor.y + 10; // Adjust the 10 as needed for spacing
        },
    });
    let startY = lastTableRowY;

    doc.text(`$${total.toFixed(2)}`, 160, 90);
    doc.text(`$${optional.toFixed(2)}`, 160, 95);
    doc.text(`$${depositValue.toFixed(2)}`, 160, 100);
    if (hasOptionalItems) {
        doc.text(`$${depositValueOpt.toFixed(2)}`, 160, 105);
    } 

    doc.text(`Sub Total:`, 130, startY);
    doc.text(`$${subtotal.toFixed(2)}`, 160, startY);
    startY += 8;
    doc.text(`Sub Total W/Optional:`, 111, startY);
    doc.text(`$${optionalTotal.toFixed(2)}`, 160, startY);
    startY += 8;
    if (estimate.discount > 0) {
        doc.text(`Discount:`, 130, startY);
        doc.text(`$${discountValue.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Tax:`, 139, startY);
        doc.text(`${taxValue.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Tax Rate:`, 131, startY);
        doc.text(`${filterJobTax.taxRate}%`, 160, startY);
        startY += 8;
        doc.text(`Total:`, 137, startY);
        doc.text(`$${total.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Total W/Optional:`, 119, startY);
        doc.text(`$${optional.toFixed(2)}`, 160, startY);
        startY += 8;
    } else {
        doc.text(`Tax:`, 139, startY);
        doc.text(`${taxValue.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Tax Rate:`, 131, startY);
        doc.text(`${filterJobTax.taxRate}%`, 160, startY);
        startY += 8;
        doc.text(`Total:`, 137, startY);
        doc.text(`$${total.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Total W/Optional:`, 119, startY);
        doc.text(`$${optional.toFixed(2)}`, 160, startY);
        startY += 8;
    }
    startY += 20;
    if (startY > 290) {
        doc.addPage(); // Add a new page
        startY = 15;
    }
    doc.setFontSize(12);
    doc.text("Terms:", 10, startY);
    startY += 5;
    doc.setFontSize(10);
    doc.text("Estimates are an approximation of charges to you, and they are based on the anticipated details of the work to be done.", 10, startY);
    startY += 5;
    doc.text("It is possible for unexpected complications to cause some deviation from the estimate.If additional parts or labor are", 10, startY);
    startY += 5;
    doc.text("required you will be contacted immediately.", 10, startY);
    startY += 10;
    if (startY > 290) {
        doc.addPage();
        startY = 15;
    }
    doc.setFontSize(12);
    doc.text("Notes:", 10, startY);
    startY += 5;
    if (startY > 290) {
        doc.addPage();
        startY = 15;
    }
    if (estimate.notes) {
        doc.setFontSize(10);
        doc.text(`${estimate.notes}`, 10, startY);
    };
    startY += 30;

    if (startY > 290) {
        doc.addPage();
        startY = 15;
    }
    doc.setFontSize(25);
    doc.text("Thank You For Your Business", 50, startY);

    startY += 25;


    if (signatureData.length > 0) {
        if (startY > 290) {
            doc.addPage();
            startY = 15;
        }
        const tableSignData = [
            ['Signature', 'Signed By', 'Signed'],
            ...signatureData.map((sign) => {
                const imgData = sign.signature; // Assuming sign.signature contains base64 encoded image data
                const imageWidth = 20; // Set the desired width of the image
                const imageHeight = 15;


                 doc.addImage(imgData, "JPEG", 15, 25, imageWidth, imageHeight);

                return [
                    '',
                    `\n${sign.signatureName}\n`,
                    `\n${formatDateTime(sign.dateTime)}\n`,
                ];
            }),
        ];

        const styles = {
            cellPadding: 3,
            fontSize: 10,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [0, 0, 0],
        };

        doc.autoTable({
            startY: startY,
            head: tableSignData.slice(0, 1),
            //body: tableSignData.slice(1),
            html: '#mytable',
            columnStyles: {
                0: { cellWidth: 100 },
            },
            styles: styles,
            bodyStyles: { minCellHeight: 8 },
            didDrawCell: function (data) {
                if (data.cell.section === 'body') {
                    var td = data.cell.raw;
                    var colIndex = data.column.index;

                    if (colIndex === 0) {
                        // Handle the 1st column (image column)
                        var img = td.getElementsByTagName('img')[0];
                        var dim = data.cell.height - data.cell.padding('vertical');
                        var desiredWidth = dim * 5.5; // Adjust the scale factor as needed
                        var aspectRatio = img.width / img.height;
                        var desiredHeight = desiredWidth / aspectRatio;
                        var posX = data.cell.x + (data.cell.width - desiredWidth) / 20;
                        var posY = startY + 15 + (data.cell.height - desiredHeight);

                        doc.setFillColor(255, 255, 255); // Set fill color to white
                        doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // 'F' stands for 'Fill'
                        doc.addImage(img.src, posX, posY, desiredWidth, desiredHeight);
                    } else {
                        // Handle the 2nd and 3rd columns (text columns)
                        doc.setFillColor(255, 255, 255); // Set fill color to white
                        doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // 'F' stands for 'Fill'

                        // Calculate the position to center the text horizontally and vertically in the cell
                        var textX = data.cell.x + data.cell.padding('left');
                        var textY = data.cell.y + (data.cell.height / 2);

                        // Draw the text content on top of the white background
                        doc.setTextColor(0, 0, 0); // Set text color to black
                        doc.text(textX, textY, data.cell.text, { baseline: 'middle' });
                    }
                }
                if (data.cell.section === 'head' && data.row.index === 0) {
                    doc.setLineWidth(0.1); // Set the border line width as needed
                    doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                }
            }

        });
    }

    //const pdfBlob = doc.output("blob");
    //const pdfUrl = URL.createObjectURL(pdfBlob);
    // window.open(pdfUrl, "_blank");
    //URL.revokeObjectURL(pdfUrl);
    doc.save(`estimate+${customer.name}+${customer.lastName}.pdf`);
}
