import Login2FAVerifyComponent from "./components/login2FAVerifyComponent";


const Login2FAVerifyPage = () => {

    return (
        <Login2FAVerifyComponent/>
    );
};

export default Login2FAVerifyPage;
