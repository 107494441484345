import React from "react";
import JobStatusGraphComponent from "../../components/jobStatusGraphComponent";
import JobSourceGraphComponent from "../../components/jobSourceGraphComponent";
import JobTypeGraphComponent from "../../components/jobTypeGraphComponent";
import PaymentGraphComponent from "../../components/paymentGraphComponent";

const HomePageComponent = ({ fetchJobItem, fetchUsers, fetchDispatch, fetchPayments, fetchJobSource, fetchJobType, fetchTechInfo }) => {

    return (
        <>
            <div className="container-fluid ms-0 me-0 pe-0 ps-0 mt-5 marginbottom1">
                <div className="row d-flex m-0 p-0 mt-4 pb-5 justify-content-center">
                    <div className="col-sm-11 text-center">
                        <PaymentGraphComponent fetchUsers={fetchUsers} fetchJobItem={fetchJobItem} fetchPayments={fetchPayments} fetchTechInfo={fetchTechInfo} fetchDispatch={fetchDispatch} />
                    </div>
                </div>
                <div className="row justify-content-center pt-5 pb-5 mt-5">
                    <div className="col-sm-11 text-center">
                        <JobStatusGraphComponent fetchTechInfo={fetchTechInfo} fetchDispatch={fetchDispatch} />
                    </div>
                </div>
                <div className="row justify-content-center mt-5 pt-5 m-0 p-0">
                    <div className="col-sm-5 m-0 p-0 text-start">
                        <JobSourceGraphComponent fetchTechInfo={fetchTechInfo} fetchJobSource={fetchJobSource} fetchDispatch={fetchDispatch} />
                    </div>
                    <div className="col-sm-1 m-0 p-0">
                    </div>
                    <div className="col-sm-5 m-0 p-0 align-end">
                        <JobTypeGraphComponent fetchTechInfo={fetchTechInfo} fetchJobType={fetchJobType} fetchDispatch={fetchDispatch} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomePageComponent;