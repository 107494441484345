import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import paths from "../../router/paths";
import { MdPayments } from "react-icons/md";

const VinKeyCodeInfoComponent = ({ fetchRequest }) => {
    const [codes, setCodes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();


    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchRequest(id)
            .then((res) => {
                setCodes(res[0]);
                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [fetchRequest]);

    return (
        <div className="container-fluid mt-5 pt-5 mb-5 pb-5">
            <div className="row d-flex justify-content-center mt-5 pt-5 mb-5 pb-5">
                <div className="col-sm-10 col-md-5 col-lg-4">
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {codes.status !== 'Paid' ? (
                                <div className="text-middle text-center">
                                    <MdPayments className="m-4" style={{ width: 9 + 'rem', height: 9 + 'rem' }} />
                                    <h4>This request has not yet been paid for.</h4>
                                    <Link to={paths.BUYKEYCODE} className="btn btn-sm btn-outline-primary mt-3 me-3 loginButton">My Codes</Link>
                                    <Link to={`/buy-pincode/purchase/${codes._id}`} className="btn btn-sm btn-outline-success mt-3 loginButton">Pay Now</Link>
                                </div>
                            ) : (
                                <>
                                    {codes.code ? (
                                        <>
                                            <div className="row mt-4 m-0 p-0">
                                                <div className="col-sm-12 m-0 p-0">
                                                    <nav aria-label="breadcrumb">
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item"><Link to={paths.BUYKEYCODE}>My Codes</Link></li>
                                                            <li className="breadcrumb-item active" aria-current="page">{codes.vin}</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="row d-flex justify-content-start m-0 p-0">
                                                        <div className="col-sm-12 text-start m-0 p-0">
                                                            VIN: {codes.vin}
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="list-group list-group-flush pb-3">
                                                    <li className="list-group-item text-center">
                                                        {codes.make} {codes.model} {codes.year}
                                                    </li>
                                                    <li className="list-group-item">
                                                        <strong>Key Code:</strong> {codes.code.keyCode}
                                                    </li>
                                                    <li className="list-group-item">
                                                        <strong>PIN Code:</strong> {codes.code.pinCode}
                                                    </li>
                                                    {codes.code.doorCode && (
                                                        <li className="list-group-item">
                                                            <strong>Door Code:</strong> {codes.code.doorCode}
                                                        </li>
                                                    )}
                                                    <li className="list-group-item">
                                                        {codes.code.cuts.map((cut, index) => (
                                                            <>
                                                                <span key={index}>
                                                                    {index === 0 ? <strong>Cuts:</strong> : <strong>Or, Cuts:</strong>} {cut !== 'Unknown' ? cut.split('').join('-') : 'Unknown'}
                                                                </span><br />
                                                            </>
                                                        ))}
                                                    </li>
                                                    <li className="list-group-item">
                                                        <strong>HPC Card:</strong> {codes.code.hpcCard}
                                                    </li>
                                                    <li className="list-group-item">
                                                        <strong>Test Blade:</strong> {codes.code.testBlade}
                                                    </li>
                                                    <li className="list-group-item">
                                                        <strong className="me-2">Transponder Key:</strong>
                                                        {codes.code.transponder === 'true' ? (
                                                            <span>True</span>
                                                        ) : codes.code.transponder === 'false' ? (
                                                            <span>False</span>
                                                        ) : (
                                                            <span>Optional</span>
                                                        )}
                                                    </li>
                                                    <li className="list-group-item">
                                                        <strong>Transponder Key:</strong> {codes.code.transponderKey}
                                                    </li>
                                                    {codes.code.system && (
                                                        <li className="list-group-item">
                                                            <strong>System:</strong> {codes.code.system}
                                                        </li>
                                                    )}
                                                    <li className="list-group-item">
                                                        <strong>Chip Type:</strong> {codes.code.chipType}
                                                    </li>
                                                    <li className="list-group-item">
                                                        <strong>Code Series:</strong> {codes.code.codeSeries}
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="text-center">
                                            <div className="d-flex justify-content-center m-5 p-5">
                                                <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                            </div>
                                            <h3>Currently Calculating Code Info...</h3>
                                            <Link to={paths.BUYKEYCODE} className="btn btn-sm btn-outline-primary mt-3 loginButton">My Codes</Link>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}

                </div>
            </div>
        </div>
    );
};

export default VinKeyCodeInfoComponent;