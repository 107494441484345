import RequestTimeOffComponent from "./components/requestTimeOffComponent";
import axios from "axios";

const RequestTimeOffPage = () => {

    const getSchedule = async () => {
        const { data } = await axios.get(`/api/workSchedule/work-schedule`, { withCredentials: true });
        return data
    }

    const getRequests = async () => {
        const { data } = await axios.get(`/api/timeOff/time-off-all`, { withCredentials: true });
        return data
    }

    const createTimeOffRequest = async (formInputs) => {
        const { data } = await axios.post(`/api/timeOff/time-off`, { ...formInputs }, { withCredentials: true });
        return data
    }

    const updateTimeOffRequest = async (id, formInputs) => {
        const { data } = await axios.put(`/api/timeOff/time-off/${id}`, { ...formInputs }, { withCredentials: true });
        return data
    }

    const updateTimeOffRequestAll = async (id, formInputs) => {
        const { data } = await axios.put(`/api/timeOff/time-off-all/${id}`, { ...formInputs }, { withCredentials: true });
        return data
    }

    const deleteTimeOff = async (requestId) => {
        const { data } = await axios.delete(`/api/timeOff/time-delete/${requestId}`, { withCredentials: true });
        return data
    }

    return <RequestTimeOffComponent
        updateTimeOffRequestAll={updateTimeOffRequestAll}
        updateTimeOffRequest={updateTimeOffRequest}
        deleteTimeOff={deleteTimeOff}
        getRequests={getRequests}
        createTimeOffRequest={createTimeOffRequest}
        getSchedule={getSchedule}
    />
};

export default RequestTimeOffPage;