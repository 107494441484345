import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import paths from "../../../router/paths";
import DispatchLinksComponent from "../../../components/dispatch/dispatchLinksComponent";
import { MdOutlineInventory } from "react-icons/md";
import DispatchDeletePopUpComponent from '../../../components/dispatch/deleteconfirm/dispatchDeletePopComponent';

const CustomerComponent = ({ fetchCustomer, deleteCustomer }) => {
    const [customer, setCustomer] = useState([]);
    const auth = useSelector(state => state.auth)
    const [customerDeleted, setCustomerDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // State for loading status
    const [page, setPage] = useState(1);
    const rowsPerPage = 20; // Number of rows displayed per page
    const [searchValue, setSearchValue] = useState(""); // State for the search bar value
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [customerId, setCustomerId] = useState("");

    const handleConfirmRemove = async () => {
        const data = await deleteCustomer(customerId);
        if (data.message === 'Customer deleted successfully') {
            setCustomerDeleted(!customerDeleted)
            setShowConfirmation(false);
            setIsLoading(true);
        }
    }
    const deleteHandler = (customerId) => {
        setCustomerId(customerId);
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchCustomer(abctrl)
            .then((res) => {
                const companyFilter = res.filter((customer) => customer.company === auth.user.company);

                setCustomer(companyFilter);
                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        return () => abctrl.abort();
    }, [deleteCustomer, fetchCustomer, auth.user.company]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchCustomer(abctrl)
                .then((res) => {
                    const companyFilter = res.filter((customer) => customer.company === auth.user.company);

                    setCustomer(companyFilter);
                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [deleteCustomer, isLoading, fetchCustomer, auth.user.company]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1); // Reset the page to 1 when the search query changes
    };

    return (
        <div className="min-vh-100">
            {showConfirmation && (
                <DispatchDeletePopUpComponent
                    title='Delete this customer info?'
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancel}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className='col-sm-2 m-0 p-0'>
                    <DispatchLinksComponent />
                </div>
                <div className="col-sm-10 ms-0 ps-0 mt-5 pt-5 pb-4">
                    <div className="row mb-3">
                        <div className="col-sm-8">
                            <h1>Customer List</h1>
                        </div>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                className="mt-2 input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Customers.."
                                disabled={customer.length === 0}
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1">
                            <Link to={paths.DISPATCHNEWCUSTOMER} className="btn btn-outline-primary loginButton">New</Link>
                        </div>
                    </div>
                    {customer.length > 0 ? (
                        <>
                            {isLoading ? (
                                <div className="d-flex justify-content-center m-5 p-5">
                                    <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            ) : (
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-start">Customer Name</th>
                                            <th scope="col" className="text-start">Customer Phone/Ext</th>
                                            {customer.email && (
                                                <th scope="col" className="text-start">Email</th>
                                            )}
                                            <th scope="col" className="text-start">Customer Address</th>
                                            <th scope="col" className="text-center">Edit/Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customer
                                            .filter(
                                                (customers) => {
                                                    return (
                                                        customers.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                                        customers.phone.toLowerCase().indexOf(searchValue) !== -1 ||
                                                        customers.address.toLowerCase().indexOf(searchValue) !== -1

                                                    );
                                                })
                                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                            .map((customers, idx) => {

                                                return (
                                                    <tr key={idx}>
                                                        <td className="text-center">{customers.name} {customers.lastName}</td>
                                                        <td className="text-center">{customers.phone} <span className="ms-2">{customers.phoneExt ? `Ext: ${customers.phoneExt}` : ''}</span></td>
                                                        {customer.email && (
                                                            <td className="text-center">{customers.email}</td>
                                                        )}
                                                        <td className="text-center">{customers.address} {customers.city}, {customers.state}, {customers.zip}</td>
                                                        <td className="text-center">
                                                            <Link to={`/dispatch/edit-customer/${customers._id}`}>
                                                                <button type="button" className="btn-sm btn btn-outline-primary">
                                                                    <GoPencil />
                                                                </button>
                                                            </Link>
                                                            {" / "}
                                                            <button
                                                                type="button"
                                                                className="btn-sm btn btn-outline-danger"
                                                                onClick={() => deleteHandler(customers._id)}
                                                            >
                                                                <BsTrash />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                    </tbody>
                                </table>
                            )}
                        </>
                    ) : (
                        <div className="mt-5">
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <MdOutlineInventory className="inventoryLogo fs-1" />
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <span>Currently no added customers. Click on the add button to include them.</span> <br />
                                </div>
                            </div>
                        </div>
                    )}
                    {customer.length > 0 && (
                        <div className="d-flex justify-content-center">

                            <button
                                className="btn-sm btn btn-outline-primary me-3 loginButton"
                                disabled={page === 1}
                                onClick={handlePrevPage}
                            >
                                Previous
                            </button>
                            <span className="mr-2 me-3">
                                Page {page} of {Math.ceil(customer
                                    .filter(
                                        (customers) => {
                                            return (
                                                customers.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                                customers.phone.toLowerCase().indexOf(searchValue) !== -1 ||
                                                customers.address.toLowerCase().indexOf(searchValue) !== -1

                                            );
                                        }).length / rowsPerPage)}
                            </span>
                            <button
                                className="btn-sm btn btn-outline-primary loginButton"
                                disabled={customer
                                    .filter(
                                        (customers) => {
                                            return (
                                                customers.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                                customers.phone.toLowerCase().indexOf(searchValue) !== -1 ||
                                                customers.address.toLowerCase().indexOf(searchValue) !== -1

                                            );
                                        }).length <= page * rowsPerPage}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomerComponent;