import React, { useEffect, useState } from "react";
import { MdOutlineImageNotSupported } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import '../../components/assets/LineSpinner.css';
import paths from "../../router/paths";

const LocksmithHelpComponent = ({ fetchInstruction }) => {
    const [instruction, setInstruction] = useState([]);
    const { category } = useParams();
    const [loading, setLoading] = useState([]);
    const [dots, setDots] = useState([false, false, false]);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => {
                const firstFalseIndex = prevDots.findIndex(dot => !dot);
                if (firstFalseIndex !== -1) {
                    const newDots = [...prevDots];
                    newDots[firstFalseIndex] = true;
                    return newDots;
                }
                return [false, false, false];
            });
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const instructionData = await fetchInstruction(category);
                setInstruction(instructionData);

            } catch (error) {
                console.log([{ name: error.response?.data?.message || error.response?.data || 'An error occurred.' }]);
            }
            setLoading(false);
        };

        fetchData();
    }, [fetchInstruction, category]);

    return (
        <div className="container mt-5 mb-5 pb-5">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={paths.LOCKSMITHSEARCH}>Locksmith Search</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{category} Instructions</li>
                </ol>
            </nav>
            <h1 className="text-center">{category} Instructions</h1>
            <div className="row justify-content-center mt-5">
                {loading ? (
                    <div className="d-flex justify-content-center mt-4" >
                        <div className="row-spinner">
                            {dots.map((dot, index) => (
                                <div key={index} className={`dot ${dot ? 'visible' : ''}`} style={{ animationDelay: `${index * 0.3}s` }}></div>
                            ))}
                        </div>
                    </div >
                ) : (
                    <div className="col-sm-10">
                        <div className="row d-flex justify-content-start">
                            {instruction.map((item, idx) => (
                                <div key={idx} className={`col-sm-6 mt-5 pt-4 m-0 p-0 ${idx % 2 === 0 ? 'd-flex justify-content-start' : 'd-flex justify-content-end'}`}>
                                    <div className="card" style={{ width: '30rem' }}>
                                        {item.instructionSteps[0].uploadId ? (
                                            <img src={item.instructionSteps[0].uploadId[0].imageUrl} className="card-img-top" alt={item.instructionSteps[0].uploadId[0].fileName} />
                                        ) : (
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-sm-5">
                                                    <MdOutlineImageNotSupported className="card-img-top" style={{ width: '10rem', height: '10rem' }} />
                                                </div>
                                            </div>
                                        )}
                                        <div className="card-body">
                                            <h5 className="card-title">{item.name}</h5>
                                            <p className="card-text">
                                                {item.description}
                                            </p>
                                            <Link to={`/locksmith-help/${encodeURIComponent(item.instructionCatagory)}/${encodeURIComponent(item.name)}`} className="btn btn-sm btn-outline-primary loginButton">See Instructions</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                )}
            </div>
        </div>
    );
};

export default LocksmithHelpComponent;