import axios from "axios";
import HomePageComponent from "./components/homePageComponent";

const HomePage = () => {

    const fetchMake = async (abctrl) => {
        const { data } = await axios.get("/api/vehicleMake/", { withCredentials: true })
        return data;
    }
    const fetchModel = async (make) => {
        const { data } = await axios.get(`/api/vehicleModel?make=${make}`, { withCredentials: true })
        return data;
    }
    const fetchYear = async (model) => {
        const { data } = await axios.get(`/api/vehicleYear?model=${model}`, { withCredentials: true });
        return data
    }
    const fetchDispatch = async (abctrl) => {
        const { data } = await axios.get("/api/dispatch/dispatch", { withCredentials: true });
        return data
    }
    const fetchJobItem = async (abctrl) => {
        const { data } = await axios.get("/api/jobItem/estimate", { withCredentials: true });
        return data
    }
    const fetchTechInfo = async (abctrl) => {
        const { data } = await axios.get("/api/techInfo/graph", { withCredentials: true });
        return data
    }
    const fetchUsers = async (abctrl) => {
        const { data } = await axios.get("/api/users/graph", { withCredentials: true });
        return data
    }
    const fetchJobSource = async (abctrl) => {
        const { data } = await axios.get("/api/jobSource", { withCredentials: true });
        return data
    }
    const fetchJobType = async (abctrl) => {
        const { data } = await axios.get("/api/jobType", { withCredentials: true });
        return data
    }
    const fetchPayments = async () => {
        const { data } = await axios.get(`/api/payment/home`, { withCredentials: true });
        return data
    }

    return <HomePageComponent
        fetchJobItem={fetchJobItem}
        fetchPayments={fetchPayments}
        fetchJobType={fetchJobType}
        fetchJobSource={fetchJobSource}
        fetchUsers={fetchUsers}
        fetchTechInfo={fetchTechInfo}
        fetchDispatch={fetchDispatch}
        fetchYear={fetchYear}
        fetchModel={fetchModel}
        fetchMake={fetchMake}
    />;
};

export default HomePage;
