import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import Select from "react-select";

const years = [];
for (let year = 1970; year <= 2023; year++) {
    years.push(year);
}

const CreateYearPageComponent = ({
    createYearApiRequest,
    fetchMake,
    fetchYear,
    fetchModel
}) => {
    const [validated, setValidated] = useState(false);
    const [selectedMake, setSelectedMake] = useState('');
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [unmatchedYears, setUnmatchedYears] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [createYearResponseState, setCreateYearResponseState] = useState({
        message: "",
        error: "",
    });

    useEffect(() => {
        fetchModel(selectedMake)
            .then((data) => {
                setModel(data);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
    }, [selectedMake, fetchModel]);

    useEffect(() => {
        const abctrl = new AbortController();
        fetchMake(abctrl)
            .then((res) => setMake(res))
            .catch((er) =>
                setMake([
                    { name: er.response.data.message ? er.response.data.message : er.response.data }
                ]),
            );
        return () => abctrl.abort();
    }, [fetchMake]);

    useEffect(() => {
        const abctrl = new AbortController();
        fetchYear(selectedModel)
            .then((data) => {
                const matchingYears = data.map((item) => item.year);
                const unmatchedYears = years.filter((year) =>
                    !matchingYears.includes(year)
                );
                setUnmatchedYears(unmatchedYears);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [selectedModel, fetchYear, years]);

    const navigate = useNavigate();

    const handleMakeChange = (e) => {
        const selectedMake = e.target.value;
        setSelectedMake(selectedMake);

        if (selectedMake) {
            fetchModel(selectedMake)
                .then((data) => {
                    setModel(data);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        } else {
            setModel([]);
            setSelectedModel('');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formInputs = {
            make: selectedMake,
            model: selectedModel,
            year: selectedYears,
        };
        if (event.currentTarget.checkValidity() === true) {
            createYearApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Year created") navigate(`${paths.ADMINYEAR}`);
                })
                .catch((er) => {
                    setCreateYearResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };


    const isModelDisabled = !selectedMake;
    const isYearDisabled = !selectedModel;

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-start">New Vehicle Year</h1>
                        <form noValidate validated={validated} onSubmit={handleSubmit} className="row d-flex justify-content-center p-0 m-0">
                            <div className="row mt-4">
                                <div className="col-sm-3" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <label htmlFor="make">Make:</label>
                                    <select
                                        className="btn btn-outline-light text-dark"
                                        id="make"
                                        name="make"
                                        value={selectedMake}
                                        onChange={handleMakeChange}
                                    >
                                        <option value="">Select A Make</option>
                                        {make
                                            .slice()
                                            .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
                                            .map((makes) => (
                                                <option key={makes.id} value={makes.id}>
                                                    {makes.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-3" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <label htmlFor="model">Model:</label>
                                    <select
                                        className="btn btn-outline-light text-dark"
                                        id="model"
                                        name="model"
                                        value={selectedModel}
                                        onChange={(e) => setSelectedModel(e.target.value)}
                                        disabled={isModelDisabled}
                                    >
                                        <option value="">Select A Model</option>
                                        {model.slice()
                                            .sort((a, b) => a.model.localeCompare(b.model))
                                            .map((models) => (
                                                <option key={models.id} value={models.id}>
                                                    {models.model}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-12" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <label htmlFor="year">Years:</label>
                                    <Select
                                        disabled={isYearDisabled}
                                        isMulti
                                        options={unmatchedYears.map(year => ({ value: year, label: year }))}
                                        value={selectedYears.map(year => ({ value: year, label: year }))}
                                        onChange={selectedOptions => setSelectedYears(selectedOptions.map(option => option.value))}
                                    />
                                </div>
                            </div>
                            <div className="text-center mt-3 pt-5">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINYEAR} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createYearResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateYearPageComponent;