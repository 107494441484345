import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import paths from "../../../router/paths";
import TimeClockLinksComponent from '../../../components/timeClock/timeClockLinksComponent';
import LoadingComponent from '../../../components/loadingComponet';
import { BsPerson } from 'react-icons/bs';
import { BiCalendar } from 'react-icons/bi';
import { MdOutlinePunchClock } from "react-icons/md";
import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { TfiClose } from 'react-icons/tfi';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { LiaGreaterThanSolid } from 'react-icons/lia';
import { LiaLessThanSolid } from 'react-icons/lia';
import WorkScheduleDeleteComponent from "../../../components/timeClock/workScheduleDeleteComponent";
import WorkScheduleEditComponent from "../../../components/timeClock/workScheduleEditComponent";

const localizer = momentLocalizer(moment);

const WorkScheduleComponent = ({ editSchedule, getSchedule, deleteSchedule, clockStatus, getLocation, updateWorkApiRequest }) => {
    const auth = useSelector(state => state.auth)
    const [workDeleted, setWorkDeleted] = useState(false);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationEdit, setShowConfirmationEdit] = useState(false);
    const [workId, setWorkId] = useState("");
    const [mainWorkId, setMainWorkId] = useState("");
    const [selectedId, setSelectedId] = useState("");

    const [currentView, setCurrentView] = useState('month');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const popupRef = useRef(null);

    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;

    const handleConfirmRemove = async () => {
        const data = await deleteSchedule(mainWorkId, workId);
        if (data.message === 'Schedule entry removed') {
            setWorkDeleted(!workDeleted)
            setWorkId('');
            setMainWorkId('');
            setShowConfirmation(false);
            setLoading(true);
        }
    }
    const deleteHandler = (mainId, workId) => {
        setWorkId(workId);
        setMainWorkId(mainId);
        setShowConfirmation(true);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const scheduleDataResponse = await getSchedule();
                const userIds = auth.user._id;
                const isAdminOrSuperAdmin = isAdmin || isSuperAdmin;

                const schedPromises = scheduleDataResponse.map(async (entry) => {
                    const userId = entry.userId._id;

                    if (isAdminOrSuperAdmin || (userId === userIds)) {

                        const data = await clockStatus(userId);
                        return {
                            ...entry,
                            clock: data,
                        };
                    }
                    return {
                        ...entry,
                        clock: null,
                    };
                });

                const sched = await Promise.all(schedPromises);
                const mappedEvents = mapScheduleToCalendar(sched);
                setEvents(mappedEvents);
                setLoading(false);
            } catch (error) {
                console.error("Error loading schedule:", error);
            }
            
        }

        if (loading) {
            fetchData();
        } else {
            fetchData();
        }

    }, [clockStatus, getSchedule, loading, auth.user._id, isAdmin, isSuperAdmin]);

    const mapScheduleToCalendar = (schedules) => {
        const events = [];
        const maxEndDate = moment().add(1, 'year').toDate();

        schedules.forEach(entry => {
            entry.schedule.forEach(scheduleItem => {
                const startTime = moment(scheduleItem.startTime);
                const endTime = moment(scheduleItem.endTime);

                let current = startTime.clone().startOf('day');

                while (current.isBefore(maxEndDate)) {
                    const startDateTime = current.clone().set({
                        hour: startTime.hour(),
                        minute: startTime.minute(),
                        second: startTime.second(),
                    });

                    const endDateTime = startDateTime.clone().add(endTime.diff(startTime));

                    const event = {
                        title: `${entry.userId.name} ${entry.userId.lastName}`,
                        start: startDateTime.toDate(),
                        end: endDateTime.toDate(),
                        allDay: false,
                        id: scheduleItem._id,
                        mainId: entry._id,
                        all: entry,
                        overTime: entry.overTime,
                        techId: entry.techId,
                        clock: entry.clock ? entry.clock.find(clock =>
                            moment(clock.clockIn.time).isSame(startDateTime, 'day')
                        ) : null,
                        time: null,
                    };

                    entry.time.forEach(timeItem => {
                        const timeStartDate = moment(timeItem.startDate).startOf('day');
                        const timeEndDate = moment(timeItem.endDate).endOf('day');

                        if (current.isBetween(timeStartDate, timeEndDate, 'day', '[]')) {
                            event.time = timeItem;
                        }
                    });

                    events.push(event);

                    current.add(1, 'week');
                }
            });
        });

        return events;
    };

    const eventStyleGetter = (event) => {

        const style = {
            backgroundColor: event.techId.color,
            color: '#000',
        };

        const eventStartDate = moment(event.start);

        if (event.time !== null) {
            const eventTimeOff = event.time.status;

            if (eventTimeOff === 'Approved') {
                style.opacity = 0.6;
                style.color = '#fff';
            }
        }

        const today = moment();

        if (eventStartDate.isBefore(today, 'day')) {
            style.opacity = 0.3;
            style.color = '#fff';
        }

        return {
            style,
        };
    };

    const handleEventClick = async (event, e) => {
        const eventBar = e.currentTarget;
        const rect = eventBar.getBoundingClientRect();
        const isDayView = currentView === "day";
        const isWeekView = currentView === "week";

        let top = rect.bottom + window.scrollY;
        let left = isDayView ? "50%" : rect.right + window.scrollX;
        const calendar = document.querySelector(".rbc-calendar");
        const calendarRect = calendar.getBoundingClientRect();
        const popupWidth = 300;
        if (left + popupWidth > calendarRect.right) {
            left = rect.left - popupWidth;
        }
        if (!isDayView && !isWeekView) {
            const eventHeight = rect.height;
            top = rect.top + window.scrollY + eventHeight / 2 - 12;
        }
        if (isDayView) {
            const eventHeight = rect.height;
            const popupHeight = 100;
            top = rect.top + window.scrollY + eventHeight / 2 - popupHeight / 2 + 40;
        }
        if (isWeekView) {
            const eventHeight = rect.height;
            top = rect.top + window.scrollY + eventHeight / 2 - 12;
        }

        try {
            if (event.clock) {
                let locClockIn, locClockOutBreak, locClockInBreak, locClockOut;

                if (event.clock.clockIn && event.clock.clockIn.location) {
                    locClockIn = await getLocation(event.clock.clockIn.location.lat, event.clock.clockIn.location.lng);
                }
                if (event.clock.breakClockOut && event.clock.breakClockOut.location) {
                    locClockOutBreak = await getLocation(event.clock.breakClockOut.location.lat, event.clock.breakClockOut.location.lng);
                }
                if (event.clock.breakClockIn && event.clock.breakClockIn.location) {
                    locClockInBreak = await getLocation(event.clock.breakClockIn.location.lat, event.clock.breakClockIn.location.lng);
                }
                if (event.clock.clockOut && event.clock.clockOut.location) {
                    locClockOut = await getLocation(event.clock.clockOut.location.lat, event.clock.clockOut.location.lng);
                }

                const combinedEvent = {
                    ...event,
                    locClockIn: locClockIn ? locClockIn.address : null,
                    locClockOutBreak: locClockOutBreak ? locClockOutBreak.address : null,
                    locClockInBreak: locClockInBreak ? locClockInBreak.address : null,
                    locClockOut: locClockOut ? locClockOut.address : null,
                };

                setSelectedEvent(combinedEvent);
            } else {
                setSelectedEvent(event);
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }

        setPopupPosition({ top, left });
    };

    const closePopup = () => {
        setSelectedEvent(null);
        setPopupPosition({ top: 0, left: 0 });
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                closePopup();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const CustomToolbar = (toolbar) => {
        const goToBack = () => {
            toolbar.onNavigate("PREV");
        };

        const goToNext = () => {
            toolbar.onNavigate("NEXT");
        };

        const goToToday = () => {
            toolbar.onNavigate("TODAY");
        };

        return (
            <div className="rbc-toolbar mt-4 ps-3 pe-3">
                <span className="rbc-btn-group">
                    <button type="button" onClick={goToToday}>
                        Today
                    </button>
                    <button type="button" className="border-white fs-4 ms-4" onClick={goToBack}>
                        <LiaLessThanSolid />
                    </button>
                    <button type="button" className="border-white fs-4" onClick={goToNext}>
                        <LiaGreaterThanSolid />
                    </button>
                </span>
                <span className="rbc-toolbar-label fw-bold">{toolbar.label}</span>
                <span className="rbc-btn-group">
                    <button
                        type="button"
                        onClick={() => toolbar.onView("day")}
                    >
                        Day
                    </button>
                    <button
                        type="button"
                        onClick={() => toolbar.onView("week")}
                    >
                        Week
                    </button>
                    <button
                        type="button"
                        onClick={() => toolbar.onView("month")}
                    >
                        Month
                    </button>
                </span>
            </div>
        );
    };

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    const EventComponent = ({ event }) => {
        const eventTitleStyle = {
            whiteSpace: "pre-wrap", // Allow text to wrap
            wordWrap: "break-word", // Break words if they are too long
        };
        const adjustEventData = () => {
            if (currentView === "month") {

                return (
                    <div className="event-title text-light">

                        {event.title}
                    </div>
                );
            } else {
                return (
                    <>
                        <div className="event-title" style={eventTitleStyle}>{event.title}</div>
                    </>
                );
            }
        };

        return (
            <div className="custom-event">
                {adjustEventData()}
            </div>
        );
    };

    const handleCancel = () => {
        setShowConfirmation(false);
        setWorkId('');
    };

    const handleCancelEdit = () => {
        setShowConfirmationEdit(false);
        setSelectedId('');
    };

    const handleConfirmEdit = async () => {
        setShowConfirmationEdit(false);
        setSelectedId('');
        setLoading(true);
    }
    const editHandler = (workId) => {
        setSelectedId(workId);
        setShowConfirmationEdit(true);
    };

    const minTime = new Date();
    minTime.setHours(7, 0, 0);

    return (
        <div className="container-fluid calendar-wrapper min-vh-100 m-0 p-0">
            {showConfirmation && (
                <WorkScheduleDeleteComponent
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancel}
                />
            )}
            {showConfirmationEdit && (
                <WorkScheduleEditComponent
                    selectedId={selectedId}
                    editSchedule={editSchedule}
                    updateWorkApiRequest={updateWorkApiRequest}
                    onConfirm={handleConfirmEdit}
                    onCancel={handleCancelEdit}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <TimeClockLinksComponent />
                </div>
                <div className="col-sm-10 mt-5 myCustomHeight">
                    {loading && (
                        <div className="loading-overlay">
                            <LoadingComponent />
                        </div>
                    )}
                    <div className="row mb-3">
                        <div className="col-sm-10"></div>
                        <div className="col-sm-2 text-end">
                            <Link to={paths.NEWWORKSCHEDULE} className="btn btn-outline-primary loginButton">Add Schedule</Link>
                        </div>
                    </div>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 800 }}
                        defaultView='month'
                        eventPropGetter={eventStyleGetter}
                        components={{
                            toolbar: CustomToolbar,
                            event: EventComponent,
                        }}
                        onSelectEvent={handleEventClick}
                        onView={handleViewChange}
                        view={currentView}
                        min={currentView === 'day' || currentView === 'week' ? minTime : undefined}
                        timeslots={4}
                        step={15}
                    />

                    {selectedEvent && (
                        <div
                            ref={popupRef} // Ref for the popup element
                            className="popup"
                            style={{
                                top: popupPosition.top,
                                left: popupPosition.left,
                                position: "absolute",
                            }}
                        >
                            <div className="popup-content-work">
                                <div className="row m-0 p-0 bg-work fs-5">
                                    <div className="m-0 p-0 text-start col-sm-9">
                                        Schedule Info
                                    </div>
                                    {(isAdmin || isSuperAdmin) && (
                                        <>
                                            <div className="col-sm-1 text-start pe-3 m-0 p-0">
                                                <div className="m-0 p-0 fs-5 text-success" role="button">
                                                    <GoPencil onClick={() => editHandler(selectedEvent)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-1 text-start pe-3 m-0 p-0">
                                                <div className="m-0 p-0 fs-5 text-danger" role="button">
                                                    <BsTrash onClick={() => deleteHandler(selectedEvent.mainId, selectedEvent.id)} />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="col-sm-1 text-start m-0 p-0">
                                        <div className="fw-light m-0 p-0 fs-5" role="button">
                                            <TfiClose onClick={() => closePopup()} />
                                        </div>
                                    </div>
                                </div>
                                <div className="popup-content-work-main">
                                    <div className="row mt-2">
                                        <div className="m-0 p-0 col-sm-1">
                                            <BsPerson className="fs-5" />
                                        </div>
                                        <div className="col-sm-11 m-0 p-0 mt-1 text-start calfont">
                                            {selectedEvent.title}
                                        </div>
                                    </div>
                                    {moment(selectedEvent.start).isSame(selectedEvent.end, 'day') && (
                                        <div className="row mt-3 m-0 p-0">
                                            <div className="m-0 p-0 col-sm-1">
                                                <BiCalendar className="fs-5" />
                                            </div>
                                            <div className="col-sm-11 m-0 p-0 calfont mt-1">
                                                {moment(selectedEvent.start).format('ddd MMM Do')} -{' '}
                                                {moment(selectedEvent.start).format('h:mm A')} to{' '}
                                                {moment(selectedEvent.end).format('h:mm A')}
                                            </div>
                                        </div>
                                    )}
                                    {selectedEvent.time && (selectedEvent.time.status === 'Approved') && (
                                        <div className="row mt-2">
                                            <div className="m-0 p-0 col-sm-1">
                                                <MdOutlinePunchClock className="fs-5" />
                                            </div>
                                            <div className="col-sm-11 m-0 p-0 mt-1 text-start calfont">
                                                <span>User is taking this day off.</span>
                                            </div>
                                        </div>
                                    )}
                                    <>
                                        {selectedEvent.clock && selectedEvent.clock.clockIn && (selectedEvent.time ? (selectedEvent.time.status !== 'Approved') : true) ? (
                                            <>
                                                <div className="row mt-3">
                                                    <div className="m-0 p-0 text-center col-sm-4">
                                                        <span className="calfont fw-bold">Clocked In At:</span>
                                                    </div>
                                                    {selectedEvent.clock.clockIn && (
                                                        <div className="col-sm-7 m-0 p-0 mt-1 calfont text-start">
                                                            {moment(selectedEvent.clock.clockIn.time).format('h:mm A')}
                                                        </div>
                                                    )}
                                                </div>
                                                {(isAdmin || isSuperAdmin) && (
                                                    <div className="row">
                                                        <div className="m-0 p-0 col-sm-3 ps-4 text-center">
                                                            <span className="calfont fw-bold">Location: </span>
                                                        </div>
                                                        {selectedEvent.clock.clockIn.location && (
                                                            <div className="col-sm-9 pe-3 m-0 p-0 mt-1 calfont text-start">
                                                                {selectedEvent.locClockIn}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {((auth.user._id === selectedEvent.techId.user) || (isAdmin || isSuperAdmin)) && (selectedEvent.time ? (selectedEvent.time.status !== 'Approved') : true) && (
                                                    <>
                                                        {moment().isBefore(moment(selectedEvent.start)) ? null : (
                                                            <div className="row mt-3">
                                                                <div className="m-0 p-0 text-center col-sm-4">
                                                                    <span className="calfont fw-bold">Clocked In At:</span>
                                                                </div>
                                                                <div className="col-sm-8 m-0 p-0 mt-1 calfont text-start text-danger">
                                                                    User never clocked in.
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )
                                        }
                                        {selectedEvent.clock && (
                                            <>
                                                {selectedEvent.clock.breakClockOut && (
                                                    <div className="row mt-3">
                                                        <div className="m-0 p-0 text-center col-sm-5">
                                                            <span className="calfont fw-bold">Took Break At:</span>
                                                        </div>
                                                        <div className="col-sm-6 m-0 p-0 mt-1 calfont text-start">
                                                            {moment(selectedEvent.clock.breakClockOut.time).format('h:mm A')}
                                                        </div>
                                                        {(isAdmin || isSuperAdmin) && (
                                                            <div className="row">
                                                                <div className="m-0 p-0 col-sm-3 ps-4 text-center">
                                                                    <span className="calfont fw-bold">Location:</span>
                                                                </div>
                                                                {selectedEvent.clock.breakClockOut.location && (
                                                                    <div className="col-sm-9 pe-3 m-0 p-0 mt-1 calfont text-start">
                                                                        {selectedEvent.locClockOutBreak}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {selectedEvent.clock && (
                                            <>
                                                {selectedEvent.clock.breakClockIn && (
                                                    <div className="row mt-3">
                                                        {selectedEvent.clock.breakClockIn.time ? (
                                                            <>
                                                                <div className="m-0 p-0 text-center col-sm-5">
                                                                    <span className="calfont fw-bold">Break Over At:</span>
                                                                </div>
                                                                <div className="col-sm-6 m-0 p-0 mt-1 calfont text-start">
                                                                    {moment(selectedEvent.clock.breakClockIn.time).format('h:mm A')}
                                                                </div>
                                                                {(isAdmin || isSuperAdmin) && (
                                                                    <div className="row">
                                                                        <div className="m-0 p-0 col-sm-3 ps-4 text-center">
                                                                            <span className="calfont fw-bold">Location:</span>
                                                                        </div>
                                                                        {selectedEvent.clock.breakClockIn.location && (
                                                                            <div className="col-sm-9 pe-3 m-0 p-0 mt-1 calfont text-start">
                                                                                {selectedEvent.locClockInBreak}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {moment().isBefore(moment(selectedEvent.start)) ? null : (
                                                                    <div className="row mt-3">
                                                                        <div className="m-0 p-0 text-center col-sm-4">
                                                                            <span className="calfont fw-bold">Break Over At:</span>
                                                                        </div>
                                                                        <div className="col-sm-8 m-0 p-0 mt-1 calfont text-start text-danger">
                                                                            User never clocked back in.
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {selectedEvent.clock && (
                                            <div className="row mt-3">
                                                {selectedEvent.clock.clockOut ? (
                                                    <>
                                                        <div className="m-0 p-0 text-center col-sm-4">
                                                            <span className="calfont fw-bold">Clocked Out At:</span>
                                                        </div>
                                                        <div className="col-sm-7 m-0 p-0 mt-1 calfont text-start">
                                                            {moment(selectedEvent.clock.clockOut.time).format('h:mm A')}
                                                        </div>
                                                        {(isAdmin || isSuperAdmin) && (
                                                            <div className="row">
                                                                <div className="m-0 p-0 col-sm-3 ps-4 text-center">
                                                                    <span className="calfont fw-bold">Location:</span>
                                                                </div>
                                                                {selectedEvent.clock.clockOut.location && (
                                                                    <div className="col-sm-9 pe-3 m-0 p-0 mt-1 calfont text-start">
                                                                        {selectedEvent.locClockOut}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {moment().isAfter(moment(selectedEvent.clock.clockIn.time), 'day') && (
                                                            <>
                                                                <div className="m-0 p-0 text-center col-sm-4">
                                                                    <span className="calfont fw-bold">Clocked Out At:</span>
                                                                </div>
                                                                <div className="col-sm-8 m-0 p-0 mt-1 calfont text-start text-danger">
                                                                    User never clocked out.
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WorkScheduleComponent;