
const FooterComponent = () => {

    return (
        <>
            <footer>
                <div className="container-fluid footer text-light">
                    <div className="row">
                        <div className="col text-center border-top pt-3 pb-3">
                            &copy; Copyrights. All rights reserved. Locksmith Menu inc.
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default FooterComponent;
