import axios from 'axios'
import { useDispatch } from "react-redux";
import EditCustomerComponent from './components/editCustomerComponent';

const fetchCustomer = async (customerId) => {
    const { data } = await axios.get(`/api/customer/get-one/${customerId}`, { withCredentials: true });
    return data;
}

const updateCustomerApiRequest = async (customerId, formInputs) => {
    const { data } = await axios.put(`/api/customer/dispatch/${customerId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const EditCustomerPage = () => {

    const reduxDispatch = useDispatch();


    return <EditCustomerComponent fetchCustomer={fetchCustomer} updateCustomerApiRequest={updateCustomerApiRequest} reduxDispatch={reduxDispatch} />;
};

export default EditCustomerPage;
