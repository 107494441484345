import axios from "axios";
import { useDispatch } from "react-redux";
import AdminUpdateInstructionComponent from "./components/adminUpdateInstructionComponent";

const updateInstructionApiRequest = async (instructionId, formInputs) => {
    const { data } = await axios.put(`/api/instruction/admin/${instructionId}`, { ...formInputs }, { withCredentials: true });
    return data;
};

const fetchInstruction = async (instructionId) => {
    const { data } = await axios.get(`/api/instruction/get-one/${instructionId}`, { withCredentials: true });
    return data;
}
const fetchUploads = async (imageId) => {
    const { data } = await axios.get(`/api/uploads/image/${imageId}`, { withCredentials: true });
    return data
}
const AdminUpdateInstructionPage = () => {
    const dispatch = useDispatch();

    return (
        <AdminUpdateInstructionComponent
            fetchInstruction={fetchInstruction}
            fetchUploads={fetchUploads }
            updateInstructionApiRequest={updateInstructionApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminUpdateInstructionPage;