import axios from 'axios'
import { useDispatch } from "react-redux";
import EditModelComponent from './components/adminEditModelComponent';

const fetchModel = async (modelId) => {
    const { data } = await axios.get(`/api/vehicleModel/get-one/${modelId}`, { withCredentials: true });
    return data;
}

const updateModelApiRequest = async (modelId, formInputs) => {
    const { data } = await axios.put(`/api/vehicleModel/admin/${modelId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const fetchMake = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleMake/")
    return data;
}
const AdminEditModelPage = () => {

    const reduxDispatch = useDispatch();


    return <EditModelComponent fetchMake={fetchMake} fetchModel={fetchModel} updateModelApiRequest={updateModelApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditModelPage;
