import React, { useState, useEffect } from "react";
import countryOptions from "./countryOptions";
import GoogleAddressSearch from "./googleAddressSearch";
import stateOptions from "./stateOptions";

const ClientJobInfoComponent = (
    {
        setEmail,
        email,
        phoneNumber,
        setPhoneNumber,
        setCompanyText,
        selectedAddress,
        setSelectedAddress,
        customers,
        companyClientText,
        firstName,
        lastNameText,
        setFirstName,
        setLastNameText,
        selectedCity,
        setSelectedCity,
        selectedState,
        setSelectedState,
        selectedZip,
        setSelectedZip,
        selectedCountry,
        setSelectedCountry,
        setSelectedPhoneExt,
        selectedPhoneExt
    }) => {
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true);

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
        setSelectedState('');
    }
    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
    }

    useEffect(() => {
        if (customers) {
            setFirstName(customers.name);
            setLastNameText(customers.lastName);
            setPhoneNumber(customers.phone || '');
            setSelectedAddress(customers.address);
            setEmail(customers.email || '');
            setSelectedCity(customers.city);
            setSelectedState(customers.state);
            setSelectedZip(customers.zip);
            setCompanyText(customers.companyClient || '');
            setSelectedCountry(customers.country);
            setSelectedPhoneExt(customers.phoneExt || '');
        }
    }, [customers, setCompanyText, setSelectedCountry, setFirstName, setLastNameText, setPhoneNumber, setSelectedAddress, setEmail, setSelectedCity, setSelectedState, setSelectedZip, setSelectedPhoneExt]);

    const capitalizeFirstLetterOfWords = (str) => {
        if (!str) {
            return '';
        }
        return str.replace(/\b\w/g, char => char.toUpperCase());
    }

    const handleFirstNameChange = (event) => {
        const input = event.target.value;
        setFirstName(input);
    };

    const handleLastNameChange = (event) => {
        const input = event.target.value;
        setLastNameText(input);
    };

    const handleCompanyChange = (event) => {
        setCompanyText(event.target.value);
    };
    const formatPhoneNumber = (input) => {
        const cleaned = ('' + input).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return input;
    };

    const handlePhoneChange = (e) => {
        const inputPhone = e.target.value;
        const formatted = formatPhoneNumber(inputPhone);
        setPhoneNumber(formatted);

        setIsValidPhone(formatted.length === 14);
    };
    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setIsValidEmail(validateEmail(newEmail));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };
    const handleZipChange = (event) => {
        setSelectedZip(event.target.value);
    };

    const handlePhoneExtChange = (event) => {
        setSelectedPhoneExt(event.target.value);
    };

    useEffect(() => {
        if (lastNameText) {
            const capitalizedLastName = lastNameText.charAt(0).toUpperCase() + lastNameText.slice(1);
            setLastNameText(capitalizedLastName);
        }

    }, [lastNameText, setLastNameText]);

    return (
        <div className="col-sm-4 mt-5">
            <h4>Client</h4>
            <hr />
            <div className="row mt-3">
                <div className="col">
                    <input type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                        name="firstName"
                        onChange={handleFirstNameChange}
                        defaultValue={firstName}
                    />
                </div>
                <div className="col">
                    <input type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name"
                        name="lastName"
                        onChange={handleLastNameChange}
                        defaultValue={lastNameText}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <input type="text"
                        className="form-control"
                        id="companyName"
                        placeholder="Company Name"
                        onChange={handleCompanyChange}
                        defaultValue={capitalizeFirstLetterOfWords(companyClientText)}
                        name="companyName" />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <input
                        type="text"
                        className={`form-control ${isValidPhone ? '' : 'is-invalid'}`}
                        id="phone"
                        placeholder="(xxx) xxx-xxxx"
                        name="phone"
                        onChange={handlePhoneChange}
                        defaultValue={phoneNumber}
                    />
                    {!isValidPhone && (
                        <div className="invalid-feedback">Please enter a valid phone number.</div>
                    )}
                </div>
                <div className="col">
                    <input
                        type="text"
                        className={`form-control`}
                        id="phoneExt"
                        placeholder="Ext."
                        name="phoneExt"
                        onChange={handlePhoneExtChange}
                        defaultValue={selectedPhoneExt}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <input
                        type="email"
                        className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                        id="email"
                        placeholder="Email"
                        name="email"
                        onChange={handleEmailChange}
                        defaultValue={email}
                    />
                    {!isValidEmail && (
                        <div className="invalid-feedback">
                            Please enter a valid email address.
                        </div>
                    )}
                </div>
            </div>
            <div className="row mt-4">
                <GoogleAddressSearch
                    setSelectedAddress={setSelectedAddress}
                    setSelectedCity={setSelectedCity}
                    setSelectedState={setSelectedState}
                    setSelectedZip={setSelectedZip}
                    selectedAddress={selectedAddress}
                    setSelectedCountry={setSelectedCountry}
                />
            </div>
            <div className="row mt-3">
                <div className="col">
                    <input type="text"
                        className="form-control"
                        id="city"
                        placeholder="City"
                        name="city"
                        onChange={handleCityChange}
                        defaultValue={selectedCity}
                    />
                </div>
                <div className="col">
                    <select
                        className="form-control"
                        id="state"
                        name="state"
                        value={selectedState}
                        onChange={handleStateChange}
                        disabled={!selectedCountry} // Disable the state select if no country is selected
                    >
                        <option value="" disabled>Select a state</option>
                        {selectedCountry && stateOptions[selectedCountry]?.map((state) => (
                            <option key={state.name} value={state.name}>
                                {state.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <input type="text"
                        className="form-control"
                        id="zip"
                        placeholder="Zip"
                        name="zip"
                        onChange={handleZipChange}
                        defaultValue={selectedZip}
                    />
                </div>
                <div className="col">
                    <select
                        className="form-control"
                        id="country"
                        name="country"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                    >
                        <option value="" disabled>Select a country</option>
                        {countryOptions.map((country) => (
                            <option key={country} value={country}>
                                {country}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default ClientJobInfoComponent;
