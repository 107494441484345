import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import RowPrintComponent from "../../../components/rowPrintComponent";
import InventoryLinksComponent from "../../../components/inventory/inventoryLinksComponent";
import { MdOutlineInventory2 } from "react-icons/md";

const InventoryOrderComponent = ({ fetchInventory, updateInventoryStockApiRequest, fetchRequest, fetchLocation, fetchTechInfo, fetchUsers }) => {
    const [inventory, setInventory] = useState([]);
    const auth = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [selectedStatus, setSelectedStatus] = useState([{ _id: '', status: 'Order' }]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectedTab, setSelectedTab] = useState("Order");
    const [request, setRequest] = useState([]);
    const [filterLocations, setFilterLocations] = useState([]);
    const [locations, setLocations] = useState([]);
    const [inventorySet, setInventorySet] = useState([]);
    const [userData, setUserData] = useState([]);
    const [techData, setTechData] = useState([]);

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);

        fetchInventory(abctrl)
            .then((res) => {
                const filteredInventory = res.filter((item) => item.company === auth.user.company);

                if (!selectedLocation) {
                    const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1
                    setSelectedLocation(locationSelect);

                    const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === locationSelect &&
                            location.quantity <= location.minimunStock1
                        )
                    );
                    setInventory(inventoryWithSelectedLocation);
                    setInventorySet(inventoryWithSelectedLocation)
                }

                if (selectedLocation) {
                    const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                    const inventoryWithSelectedLocationSet = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === locationSelect &&
                            location.quantity <= location.minimunStock1
                        )
                    );

                    const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === selectedLocation &&
                            location.quantity <= location.minimunStock1
                        )
                    );

                    setInventorySet(inventoryWithSelectedLocationSet)
                    setInventory(inventoryWithSelectedLocation);
                }

                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        fetchUsers()
            .then((data) => {
                const filteredUser = data.find((item) => item._id === auth.user._id);

                fetchTechInfo()
                    .then((resData) => {
                        const filteredTech = resData.find((item) => item.user === filteredUser._id);

                        fetchLocation()
                            .then((dataRes) => {
                                if (filteredTech) {
                                    const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);
                                    setFilterLocations(filteredLocation);
                                }
                                const filtered = dataRes.filter((item) => item.company === auth.user.company);

                                setLocations(filtered);
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                        fetchRequest()
                            .then((dataResRes) => {
                                if (dataResRes.message !== 'request not found') {
                                    const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                    setRequest(filteredRequest)
                                } else {
                                    setRequest([])
                                }
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );

            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [fetchInventory, fetchLocation, fetchRequest, fetchTechInfo, fetchUsers, selectedLocation, auth.user._id, auth.user.company]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchInventory(abctrl)
                .then((res) => {
                    const filteredInventory = res.filter((item) => item.company === auth.user.company);

                    if (!selectedLocation) {
                        const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1
                        setSelectedLocation(locationSelect);

                        const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                            item.InventoryLocation.some((location) =>
                                location.name1 === locationSelect &&
                                location.quantity <= location.minimunStock1
                            )
                        );
                        setInventorySet(inventoryWithSelectedLocation)
                        setInventory(inventoryWithSelectedLocation);
                    }

                    if (selectedLocation) {
                        const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                        const inventoryWithSelectedLocationSet = filteredInventory.filter((item) =>
                            item.InventoryLocation.some((location) =>
                                location.name1 === locationSelect &&
                                location.quantity <= location.minimunStock1
                            )
                        );

                        const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                            item.InventoryLocation.some((location) =>
                                location.name1 === selectedLocation &&
                                location.quantity <= location.minimunStock1
                            )
                        );

                        setInventorySet(inventoryWithSelectedLocationSet)
                        setInventory(inventoryWithSelectedLocation);
                    }

                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            fetchUsers()
                .then((data) => {
                    const filteredUser = data.find((item) => item._id === auth.user._id);
                    setUserData(data);

                    fetchTechInfo()
                        .then((resData) => {
                            const filteredTech = resData.find((item) => item.user === filteredUser._id);
                            setTechData(resData);

                            fetchLocation()
                                .then((dataRes) => {
                                    if (filteredTech) {
                                        const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);
                                        setFilterLocations(filteredLocation);
                                    }

                                    const filtered = dataRes.filter((item) => item.company === auth.user.company);

                                    setLocations(filtered);
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                            fetchRequest()
                                .then((dataResRes) => {
                                    if (dataResRes.message !== 'request not found') {
                                        const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                        setRequest(filteredRequest)
                                    } else {
                                        setRequest([])
                                    }
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );

                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [isLoading, fetchInventory, selectedLocation, auth.user.company, auth.user._id, fetchUsers, fetchTechInfo, fetchLocation, fetchRequest]);


    const handleStatusChange = (inventorys, event) => {
        const newSelectedOption = event.target.value;

        const formInputs = {
            status: newSelectedOption,
            name: inventorys.name,
            stock: inventorys.stock,
            model: inventorys.model,
            dontStock: inventorys.dontStock,
            location: inventorys.location,
            price: inventorys.price,
            ourCost: inventorys.ourCost,
            barcode: inventorys.barcode,
            minimunStock: inventorys.minimunStock,
            sku: inventorys.sku,
        };
        updateInventoryStockApiRequest(inventorys._id, formInputs)
            .then((data) => {
                if (data.message === "inventory updated") {
                    setIsLoading(true);

                }
            })
            .catch((er) => {
                console.log(er)
            });
    };

    const updateAllStatuses = () => {

        selectedRows.forEach((inventoryId) => {

            const formInputs = {
                status: selectedStatus.map(({ status, _id }) => ({ status, _id })),
            };

            updateInventoryStockApiRequest(inventoryId, formInputs)
                .then((data) => {
                    if (data.message === "inventory updated") {
                        setIsLoading(true);
                        setSelectedRows([]);
                        setSelectAllChecked(false);
                        setSelectedStatus({
                            _id: '',
                            status: 'Order',
                        });
                    }
                })
                .catch((er) => {
                    console.log(er);
                });
        });
    };

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };

    const componentRef = useRef(null);

    const handleCheckboxChange = (inventoryId, status) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(inventoryId)) {
                return prevSelectedRows.filter((id) => id !== inventoryId);
            } else {
                return [...prevSelectedRows, inventoryId];
            }
        });

        setSelectedStatus((prevSelectedStatus) => {
            const existingIndex = prevSelectedStatus.findIndex((item) => item._id === inventoryId);

            if (existingIndex !== -1) {
                const updatedStatus = [...prevSelectedStatus];
                updatedStatus[existingIndex].status = status;
                return updatedStatus;
            } else {
                return [...prevSelectedStatus, { _id: inventoryId, status: status }];
            }
        });
    };

    const handleSelectAllChange = () => {
        setSelectAllChecked((prevSelectAllChecked) => !prevSelectAllChecked);
        setSelectedRows((prevSelectedRows) => {
            return !selectAllChecked ? inventory.map((item) => item._id) : [];
        });
    };

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    const filterInventory = (inventorys) => {

        const isOrder = inventorys.status === "Order";
        const isOrdered = inventorys.status === "Ordered";
        const isReceived = inventorys.status === "Received";

        const result =
            (!selectedTab ||
                (selectedTab === "Order" && isOrder) ||
                (selectedTab === "Ordered" && isOrdered) ||
                (selectedTab === "Received" && isReceived)
            )
        return result;
    };

    const requestDataLenStatus = () => {

        const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.location && filterLocations.tech === locc.tech));

        const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

        if (filteredStatus === undefined) {
            return 0;
        } else {
            return filteredStatus;
        }
    }

    const requestDataLenInStatus = () => {

        const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.toLocation));

        const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

        if (filteredStatus === undefined) {
            return 0;
        } else {
            return filteredStatus;
        }
    }

    return (
        <div className="min-vh-100">
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <InventoryLinksComponent inventorySet={inventorySet} requestDataLenStatus={requestDataLenStatus} requestDataLenInStatus={requestDataLenInStatus} />
                </div>
                <div className="col-sm-10 ms-0 ps-0 mt-5 pt-5">
                    <div className="row m-0 p-0 mb-3 d-flex justify-content-between">
                        <div className="col-sm-7 m-0 p-0">
                            <h1>Order Stock</h1>
                        </div>
                        <div className="col-sm-2 text-end m-0 pe-3 p-0">
                            <select
                                id="Location"
                                className="form-select"
                                aria-label="Location:"
                                value={selectedLocation || ''}
                                onChange={(e) => setSelectedLocation(e.target.value)}
                            >
                                {locations.map((location, index) => {
                                    const filteredTech = techData.find((techItem) => techItem._id === location.tech);

                                    if (filteredTech) {
                                        const UserFilter = userData.find((item) => filteredTech.user === item._id)

                                        const newName = UserFilter ? `${UserFilter.name} Van` : '';

                                        return (
                                            <option key={location._id} value={location.id}>
                                                {newName}
                                            </option>
                                        )
                                    } else {
                                        return (
                                            <option key={location._id} value={location.id}>
                                                {location.name}
                                            </option>
                                        )
                                    }
                                })}
                            </select>
                        </div>
                        <div className="col-sm-3 m-0 p-0">
                            <input
                                type="text"
                                className="mt-1 me-4 input-group table-filter"
                                data-table="order-table"
                                placeholder="Search Inventory.."
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row d-flex mb-2 justify-content-start m-0 p-0">
                        <div className="col-sm-12 m-0 p-0">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${selectedTab === "Order" ? "active" : ""}`}
                                        onClick={() => handleTabChange("Order")}
                                    >
                                        Order ({inventory.filter(inv => inv.status === "Order").length})
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${selectedTab === "Ordered" ? "active" : ""}`}
                                        onClick={() => handleTabChange("Ordered")}
                                    >
                                        Ordered ({inventory.filter(inv => inv.status === "Ordered").length})
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${selectedTab === "Received" ? "active" : ""}`}
                                        onClick={() => handleTabChange("Received")}
                                    >
                                        Received ({inventory.filter(inv => inv.status === "Received").length})
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row d-flex pb-2 justify-content-end m-0 p-0">
                        {(selectAllChecked || selectedRows.length > 0) && (
                            <>
                                <div className="col-sm-1 pb-2 pt-2 m-0 p-0">
                                    <select
                                        className="form-select form-select-sm"
                                        value={selectedStatus}
                                        onChange={(event) => setSelectedStatus(event.target.value)}
                                    >
                                        <option value="Order">Order</option>
                                        <option value="Ordered">Ordered</option>
                                        <option value="Received">Received</option>
                                    </select>
                                </div>
                                <div className="col-sm-1 pb-2 pt-2 m-0 p-0">
                                    <button className="btn btn-sm btn-outline-primary ms-2" onClick={updateAllStatuses}>
                                        Update
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <div className="table-responsive">
                            {inventory.filter(inv =>
                                inv.status === selectedTab &&
                                inv.InventoryLocation.some(loc => loc.name1 === selectedLocation && loc.dontStock)
                            ).length > 0 ? (
                                <table ref={componentRef} className="table-hover table-bordered order-table table">
                                    <thead>
                                        <tr>
                                            <th className="text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={selectAllChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Location</th>
                                            <th className="text-center">Stock</th>
                                            <th className="text-center">Threshold</th>
                                            <th className="text-center">Barcode</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inventory
                                            .filter(inventorys => (
                                                (
                                                    inventorys.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
                                                    inventorys.barcode.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
                                                ) &&
                                                filterInventory(inventorys)
                                            ))
                                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                            .map((inventorys, idx) => (
                                                <>
                                                    {inventorys.InventoryLocation.filter((location) => location.name1 === selectedLocation).map((inv, idxx) => (
                                                        <tr key={idxx}>
                                                            <td className="text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectAllChecked || selectedRows.includes(inventorys._id)}
                                                                    onChange={() => handleCheckboxChange(inventorys._id, inventorys.status)}
                                                                />
                                                            </td>
                                                            <td className="text-center">{inventorys.name}</td>
                                                            <td className="align-middle text-center">
                                                                {inv.location1 !== '' ? (
                                                                    <>
                                                                        {inv.location1}
                                                                    </>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {inv.quantity !== '' ? (
                                                                    <>
                                                                        {inv.quantity}
                                                                    </>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {inv.minimunStock1 !== '' ? (
                                                                    <>
                                                                        {inv.minimunStock1}
                                                                    </>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                            <td className="ps-3 text-center">
                                                                <RowPrintComponent selectedLocation={selectedLocation} inventory={inventorys} />
                                                            </td>
                                                            <td className="text-center">
                                                                <select
                                                                    className="form-select form-select-sm"
                                                                    value={inventorys.status}
                                                                    onChange={(event) => handleStatusChange(inventorys, event)}
                                                                >
                                                                    <option value="Order">Order</option>
                                                                    <option value="Ordered">Ordered</option>
                                                                    <option value="Received">Received</option>
                                                                </select>

                                                            </td>
                                                        </tr >
                                                    ))}
                                                </>
                                            )
                                            )}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="mt-5">
                                    <div className="row d-flex justify-content-center m-0 p-0">
                                        <div className="col-sm-3 m-0 p-0 align-center">
                                            <MdOutlineInventory2 className="inventoryLogo fs-1" />
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center m-0 p-0">
                                        <div className="col-sm-4 m-0 p-0 text-start">
                                            <span>Your inventory is currently empty.</span> <br />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {inventory.filter(inv =>
                        inv.status === selectedTab &&
                        inv.InventoryLocation.some(loc => loc.name1 === selectedLocation && loc.dontStock)
                    ).length > 0 && (
                            <div className="row d-flex w-100 justify-content-center mb-3 m-0 p-0 pb-4">
                                <div className="col-sm-3 m-0 p-0">
                                    <button
                                        className="btn btn-sm btn-outline-primary pe-2 ps-2 p-0 m-0 me-3 loginButton"
                                        disabled={page === 1}
                                        onClick={handlePrevPage}
                                    >
                                        Previous
                                    </button>
                                    <span className="mr-2 me-3">
                                        Page {page} of {Math.ceil(inventory
                                            .filter(inventorys => (
                                                (
                                                    inventorys.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
                                                    inventorys.barcode.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
                                                ) &&
                                                filterInventory(inventorys)
                                            )).length / rowsPerPage)}
                                    </span>
                                    <button
                                        className="btn btn-sm btn-outline-primary pe-4 ps-4 p-0 m-0 loginButton"
                                        disabled={inventory
                                            .filter(inventorys => (
                                                (
                                                    inventorys.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
                                                    inventorys.barcode.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
                                                ) &&
                                                filterInventory(inventorys)
                                            )).length <= page * rowsPerPage}
                                        onClick={handleNextPage}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div >
    );
};

export default InventoryOrderComponent;