import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import countryOptions from "../../../components/dispatch/countryOptions";
import stateOptions from "../../../components/dispatch/stateOptions";
import CustomerInputWithDropdown from "../../../components/dispatch/customerInputDropdownComponent";
import TimeSelectComponent from "../../../components/dispatch/timeSelectComponent";
import DateSelectComponent from "../../../components/dispatch/dateSelectComponent";
import JobSourceSelectComponent from "../../../components/dispatch/jobSourceSelectComponent";
import JobTypeSelectComponent from "../../../components/dispatch/jobTypeSelectComponent";
import GoogleAddressSearch from "../../../components/dispatch/googleAddressSearch";
import TechSelectNewComponent from "../../../components/dispatch/techSelectNewComponent";
import axios from "axios";
import InventoryDispatchComponent from "../../../components/dispatch/inventoryDispatchComponent";

const DispatchComponent = ({
    fetchLocksmithInfo,
    createCustomerApiRequest,
    fetchUsers,
    fetchJobTax,
    fetchJobType,
    fetchJobSource,
    fetchTechInfo,
    createDispatchApiRequest,
    createPaymentApiRequest,
    fetchCustomer,
    updateDispatchPaymentApiRequest,
    createSchedule,
    updateSchedule,
    fetchUploadsID,
    updateCustomerApiRequest
}) => {
    const [selectedTimeStart, setSelectedTimeStart] = useState("");
    const [selectedTimeEnd, setSelectedTimeEnd] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
    const [customers, setCustomer] = useState([]);
    const [selectedOption, setSelectedOption] = useState('All Keys Lost');
    const [jobType, setJobType] = useState([]);
    const [jobSource, setJobSource] = useState([]);
    const [validated, setValidated] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedCompany, setSelectedCompany] = useState("");
    const auth = useSelector(state => state.auth)
    const [createCustomerResponseState, setCreateCustomerResponseState] = useState({ message: "", error: "", });
    const [createDispatchResponseState, setCreateDispatchResponseState] = useState({ message: "", error: "", });
    const [createScheduleResponseState, setCreateScheduleResponseState] = useState({ message: "", error: "", });
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [fullName, setFullName] = useState("");
    const [selectedAddress, setSelectedAddress] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedZip, setSelectedZip] = useState("");
    const [selectedPhoneExt, setSelectedPhoneExt] = useState("");
    const { other } = useParams();
    const { name } = useParams();
    const { model } = useParams();
    const { year } = useParams();
    const [selectedJobType, setSelectedJobType] = useState("");
    const [selectedJobSource, setSelectedJobSource] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedJobTypeName, setSelectedJobTypeName] = useState("");
    const [companyClientText, setCompanyText] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [tax, setTax] = useState('');
    const [selectedTech, setSelectedTech] = useState([]);
    const [locksmithInfo, setLocksmithInfo] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [loading, setloading] = useState(false);
    const [techPhoneNumber, setTechPhoneNumber] = useState([]);
    const [description, setDescription] = useState('');
    const [message, setMessage] = useState('');
    const [jobSourceName, setJobSourceName] = useState('');

    const isAdmin = auth.user?.usertype?.includes('Admin') || false;

    const convertPhoneNumber = (inputNumbers) => {
        if (!Array.isArray(inputNumbers)) {
            throw new Error('Input must be an array of phone numbers');
        }

        return inputNumbers.map((inputNumber) => {
            const cleanedNumber = inputNumber.replace(/\D/g, '');
            return cleanedNumber.startsWith('1') ? cleanedNumber : cleanedNumber;
        });
    };

    const formattedPhoneNumber = convertPhoneNumber(techPhoneNumber);

    const getModelAndPriceFromURL = useCallback(async () => {
        try {
            if (name && model && year) {
                const { data } = await axios.get(`/api/inventory?make=${name}&year=${year}&model=${model}`);

                setInventory(data);

                data.forEach(async (item) => {
                    if (item.image) {
                        const imageURL = await fetchUploadsID(item.image);
                        item.imageUrl = imageURL; // Add imageURL to the item
                    }
                });
                setloading(false);
            }
        } catch (error) {
            setInventory([
                { name: error.response?.data?.message || error.response?.data || 'An error occurred.' }
            ]);
        }
    }, [model, year, name, fetchUploadsID]);

    useEffect(() => {
        const abctrl = new AbortController();
        fetchCustomer(abctrl)
            .then((res) => {
                setCustomer(res);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        fetchJobTax(abctrl)
            .then((data) => {
                // Assuming selectedCompany holds the companyId you want to filter by
                const filteredTax = data.filter((item) => item.company === auth.user.company);
                const defaultTax = filteredTax.find((item) => item.defaultTax);

                setTax(defaultTax ? defaultTax : '');
            })
            .catch((er) => console.log(er.response?.data?.message || er.response?.data));

        fetchJobType()
            .then((data) => {
                setJobType(data);
            })
            .catch((error) => {
                console.log("Error fetching job types:", error);
            });

        fetchJobSource()
            .then((data) => {
                setJobSource(data);
            })
            .catch((error) => {
                console.log("Error fetching job source:", error);
            });
        // If the user is an admin, fetch the user data to get their company
        if (isAdmin) {
            fetchUsers()
                .then((data) => {
                    const adminData = data.find((user) => user._id === auth.user._id);
                    if (adminData) {
                        setSelectedCompany(adminData.company);
                    }
                })
                .catch((error) => {
                    console.log("Error fetching admin data:", error);
                });
        } else {
            setSelectedCompany(auth.user.company);
        }

        fetchLocksmithInfo(year, name, model)
            .then((data) => {
                setLocksmithInfo(data[0]);
            })
            .catch((error) => {
                console.log("Error fetching job source:", error);
            });

        getModelAndPriceFromURL();

        return () => abctrl.abort();
    }, [dispatch, fetchCustomer, fetchJobSource, fetchJobTax, fetchUsers, fetchJobType, auth, selectedCompany, name, model, year, fetchLocksmithInfo, getModelAndPriceFromURL]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (loading) {
            fetchCustomer(abctrl)
                .then((res) => {
                    setCustomer(res);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            fetchJobTax(abctrl)
                .then((data) => {
                    // Assuming selectedCompany holds the companyId you want to filter by
                    const filteredTax = data.filter((item) => item.company === auth.user.company);
                    const defaultTax = filteredTax.find((item) => item.defaultTax);

                    setTax(defaultTax ? defaultTax : '');
                })
                .catch((er) => console.log(er.response?.data?.message || er.response?.data));

            fetchJobType()
                .then((data) => {
                    setJobType(data);
                })
                .catch((error) => {
                    console.log("Error fetching job types:", error);
                });

            fetchJobSource()
                .then((data) => {
                    setJobSource(data);
                })
                .catch((error) => {
                    console.log("Error fetching job source:", error);
                });
            // If the user is an admin, fetch the user data to get their company
            if (isAdmin) {
                fetchUsers()
                    .then((data) => {
                        const adminData = data.find((user) => user._id === auth.user._id);
                        if (adminData) {
                            setSelectedCompany(adminData.company);
                        }
                    })
                    .catch((error) => {
                        console.log("Error fetching admin data:", error);
                    });
            } else {
                setSelectedCompany(auth.user.company);
            }

            fetchLocksmithInfo(year, name, model)
                .then((data) => {
                    setLocksmithInfo(data[0]);
                    console.log(data)
                })
                .catch((error) => {
                    console.log("Error fetching job source:", error);
                });

            getModelAndPriceFromURL();
        }
        return () => abctrl.abort();
    }, [dispatch, fetchCustomer, loading, fetchJobSource, fetchJobTax, fetchUsers, fetchJobType, auth, selectedCompany, name, model, year, fetchLocksmithInfo, getModelAndPriceFromURL]);

    const getFirstName = (fullName) => {
        const names = fullName.split(" ");
        return names[0] || ""; // Return the first name or an empty string if there's no name
    };

    const getLastName = (fullName) => {
        const names = fullName.split(" ");
        return names.slice(1).join(" "); // Return the rest of the names as the last name
    };

    const combinedDate = new Date(selectedDate); // Clone the selectedDate
    const timeParts = selectedTimeStart.split(':'); // Split the time string (HH:mm) into hours and minutes
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);

    combinedDate.setHours(hours);
    combinedDate.setMinutes(minutes);
    combinedDate.setSeconds(0); // You can set seconds and milliseconds to 0 if needed
    combinedDate.setMilliseconds(0);


    const combinedDateEnd = new Date(selectedDateEnd); // Clone the selectedDate
    const timeParts1 = selectedTimeEnd.split(':'); // Split the time string (HH:mm) into hours and minutes
    const hours1 = parseInt(timeParts1[0], 10);
    const minutes1 = parseInt(timeParts1[1], 10);

    combinedDateEnd.setHours(hours1);
    combinedDateEnd.setMinutes(minutes1);
    combinedDateEnd.setSeconds(0); // You can set seconds and milliseconds to 0 if needed
    combinedDateEnd.setMilliseconds(0);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const firstName = getFirstName(fullName);
        const lastName = getLastName(fullName);
        const formInputs = {
            name: firstName,
            lastName: lastName,
            address: form.address.value,
            city: form.city.value,
            state: form.state.value,
            zip: form.zip.value,
            phone: phoneNumber,
            phoneExt: selectedPhoneExt,
            email: email,
            company: auth.user.company,
            country: selectedCountry,
            companyClient: companyClientText,

        };
        const formInputsPayment = {
            jobId: '',
            customer: selectedCustomerId || "",
            companyId: auth.user.company,
            status: 'notPaid',
            tax: tax._id,
        };
        const formSchedule = {
            title: fullName,
            start: combinedDate,
            end: combinedDateEnd,
            user: auth.user._id,
            techs: selectedTech,
            description: form.description.value,
            jobType: selectedJobType,
            lead: selectedJobSource,
            status: "Submitted",
            address: form.address.value,
            city: form.city.value,
            state: form.state.value,
            zip: form.zip.value,
            phone: phoneNumber,
            phoneExt: selectedPhoneExt,
            companyClient: companyClientText,
            company: auth.user.company,
            live: true,
            deleted: false,
        };
        const formInputsDis = {
            otherJob: other || '',
            make: name || '',
            model: model || '',
            tax: tax._id,
            year: year || '',
            schedule: "",
            timeStart: selectedTimeStart,
            timeEnd: selectedTimeEnd,
            dateStart: selectedDate,
            dateEnd: selectedDateEnd,
            description: form.description.value,
            jobType: selectedJobType,
            lead: selectedJobSource,
            status: "Submitted",
            techs: selectedTech,
            customer: selectedCustomerId || "",
            user: auth.user._id,
            company: auth.user.company,
            paymentId: null,
            jobTypeKey: selectedOption,
        };
        const updatedEventData = {
            count: '',
            jobId: '',
        };
        if (event.currentTarget.checkValidity() === true) {
            const existingCustomer = customers.find((customer) => customer._id === selectedCustomerId);

            if (existingCustomer) {
                // Update the customer
                updateCustomerApiRequest(selectedCustomerId, formInputs)
                    .then((data) => {
                        if (data.message === "Customer updated");
                    })
                    .catch((er) => {
                        setCreateCustomerResponseState({
                            error: er.response.data.message
                                ? er.response.data.message
                                : er.response.data,
                        });
                    });
                createSchedule(formSchedule)
                    .then((res) => {
                        if (res.message === "Schedule created") {
                            const newScheduleId = res._id;
                            const formInputsDisWithScheduleID = {
                                ...formInputsDis,
                                schedule: newScheduleId,
                            };

                            createDispatchApiRequest(formInputsDisWithScheduleID)
                                .then((data) => {
                                    if (data.message === "Dispatch created") {
                                        const newJobId = data._id;
                                        const newJobCount = data.count;
                                        const formInputUpdatedEventData = {
                                            ...updatedEventData,
                                            count: newJobCount,
                                            jobId: newJobId,
                                        };
                                        const formInputUpdatedPayment = {
                                            ...formInputsPayment,
                                            jobId: newJobId,
                                        };
                                        createPaymentApiRequest(formInputUpdatedPayment)
                                            .then((res) => {
                                                if (res.message === "payment saved") {
                                                    const newPaymentId = res._id;
                                                    const formInputUpdatedPayment1 = {
                                                        ...formInputsDis,
                                                        paymentId: newPaymentId,
                                                    };
                                                    updateDispatchPaymentApiRequest(data._id, formInputUpdatedPayment1)
                                                        .then((res) => {
                                                            if (res.message === "payment updated") {

                                                                updateSchedule(data.schedule, formInputUpdatedEventData)
                                                                    .then(async (res) => { // Add async keyword here
                                                                        if (res.message === "schedule job updated") {
                                                                            try {
                                                                                const formattedPhoneNumbersArray = formattedPhoneNumber;

                                                                                for (const formattedPhoneNumber of formattedPhoneNumbersArray) {
                                                                                    const fullAddress = `${selectedAddress} ${selectedCity}, ${selectedState}, ${selectedZip}`;
                                                                                    const jobType = `${selectedOption || other}`;
                                                                                    const fullVehicle = `${name} ${model} ${year}`;

                                                                                    const response = await axios.post('/api/whatsapp/send-whatsapp-message', {
                                                                                        to: formattedPhoneNumber.trim(),
                                                                                        description: description,
                                                                                        fullVehicle: fullVehicle,
                                                                                        jobType: jobType,
                                                                                        selectedAddress: fullAddress,
                                                                                        phoneNumber: phoneNumber,
                                                                                        fullName: fullName,
                                                                                        jobSourceName: jobSourceName
                                                                                    });

                                                                                    setMessage(response.data.message);
                                                                                    navigate(`/dispatch/job/${data._id}`);
                                                                                }
                                                                            } catch (error) {
                                                                                console.error(error);
                                                                                setMessage('Error sending message');
                                                                            }
                                                                        }
                                                                    })
                                                                    .catch((error) => {
                                                                        console.error("Error updating event:", error);
                                                                    });

                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.error("Error updating event:", error);
                                                        });
                                                }
                                            })
                                            .catch((error) => {
                                                // Handle error if needed
                                                console.error("Error updating event:", error);
                                            });

                                    }

                                })
                                .catch((er) => {
                                    setCreateDispatchResponseState({
                                        error: er.response.data.message
                                            ? er.response.data.message
                                            : er.response.data,
                                    });
                                });
                        }
                    })
                    .catch((er) => {
                        setCreateScheduleResponseState({
                            error: er.response.data.message
                                ? er.response.data.message
                                : er.response.data,
                        });
                    });
            } else {
                // Create a new customer
                createCustomerApiRequest(formInputs)
                    .then((data) => {
                        if (data.message === "Customer created") {
                            const newCustomerId = data._id;
                            createSchedule(formSchedule)
                                .then((res) => {
                                    if (res.message === "Schedule created") {
                                        const newScheduleId = res._id;
                                        const formInputsDisWithScheduleID = {
                                            ...formInputsDis,
                                            schedule: newScheduleId,
                                            customer: newCustomerId,
                                        };
                                        createDispatchApiRequest(formInputsDisWithScheduleID)
                                            .then((data) => {
                                                if (data.message === "Dispatch created") {
                                                    const newJobId = data._id;
                                                    const newJobCount = data.count;
                                                    const formInputUpdatedEventData = {
                                                        ...updatedEventData,
                                                        count: newJobCount,
                                                        jobId: newJobId,
                                                    };
                                                    updateSchedule(data.schedule, formInputUpdatedEventData)
                                                        .then(async (res) => { // Add async keyword here
                                                            if (res.message === "schedule job updated") {
                                                                try {
                                                                    const fullMessage = `${jobSourceName}\n${fullName}\n${selectedOption || other}\n${phoneNumber}\n${selectedAddress} ${selectedCity} ${selectedState} ${selectedZip}\n${name} ${model} ${year}\n${description}`;

                                                                    const response = await axios.post('/api/whatsapp/send-whatsapp-message', { to: formattedPhoneNumber, body: fullMessage });
                                                                    setMessage(response.data.message);

                                                                    navigate(`/dispatch/job/${data._id}`);
                                                                } catch (error) {
                                                                    console.error(error);
                                                                    setMessage('Error sending message');
                                                                }
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            // Handle error if needed
                                                            console.error("Error updating event:", error);
                                                        });
                                                }

                                            })
                                            .catch((er) => {
                                                setCreateDispatchResponseState({
                                                    error: er.response.data.message
                                                        ? er.response.data.message
                                                        : er.response.data,
                                                });
                                            });
                                    }
                                })
                        }
                    })
                    .catch((er) => {
                        setCreateCustomerResponseState({
                            error: er.response.data.message
                                ? er.response.data.message
                                : er.response.data,
                        });
                    });
            }
        }
        setValidated(true);
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
        setSelectedState(""); // Reset the selected state when the country changes
    }
    useEffect(() => {
        const getNameFromId = (_id) => {
            const matchedJobType = jobType.find((type) => type._id === _id);
            return matchedJobType ? matchedJobType.name : "Unknown";
        };

        if (Array.isArray(jobType)) {
            const matchingJobType = jobType.find(
                (jobType) => jobType.company === selectedCompany
            );

            // Set the selectedJobTypeName based on the matching job type
            if (matchingJobType) {
                setSelectedJobTypeName(getNameFromId(matchingJobType._id));
            } else {
                setSelectedJobTypeName("");
            }
        }
    }, [jobType, selectedCompany]);

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
    };

    const handleCompanyChange = (event) => {
        setCompanyText(event.target.value);
    };
    const formatPhoneNumber = (input) => {
        const cleaned = ('' + input).replace(/\D/g, ''); // Remove non-digit characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match 10 digits
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return input; // If the input is invalid, return the original unformatted input
    };

    const handlePhoneChange = (e) => {
        const inputPhone = e.target.value;
        const formatted = formatPhoneNumber(inputPhone);
        setPhoneNumber(formatted); // Store the formatted phone number

        // You can add more validation checks here based on your requirements
        setIsValidPhone(formatted.length === 14); // Check if the formatted phone has 14 characters (including spaces and brackets)
    };
    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setIsValidEmail(validateEmail(newEmail));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        const errorTimeout = setTimeout(() => {
            setCreateScheduleResponseState({
                message: "",
                error: "",
            });
        }, 3000); // 3000 milliseconds = 3 seconds

        return () => {
            clearTimeout(errorTimeout);
        };
    }, [createScheduleResponseState.error]);

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handlePhoneExtChange = (event) => {
        setSelectedPhoneExt(event.target.value);
    };
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    return (
        <div className="pt-5">
                <div className="container mb-5 pb-5">
                    {createScheduleResponseState.error && (
                        <div className="alertRed alert-danger" role="alert">
                            Tech Already Scheduled
                            <div className="alert-line-container">
                                <div className="alert-lineRed"></div>
                            </div>
                        </div>
                    )}
                    <h1 className="mt-5">New Job</h1>
                    {locksmithInfo && locksmithInfo.dispatchNote && (
                        <div className="alert alert1 alert-danger d-flex align-items-center mb-5" role="alert">
                            <div className="text-light">
                                {locksmithInfo.dispatchNote}
                            </div>
                        </div>
                    )}
                    {name && model && year && inventory.length <= 0 && (
                        <div className="alert alert2 alert-danger w-100 d-flex align-items-center mt-5" role="alert">
                            <div className="text-light">
                                No keys for this vehicle in the inventory. Order key from supplier.
                            </div>
                        </div>
                    )}
                    <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="ms-5 row g-2">
                        <div className="col-sm-5 mt-5 p-3 border border-dark">
                            <h3>Client Details</h3>
                            <div className="row mt-3">
                                <CustomerInputWithDropdown
                                    fullName={fullName}
                                    setFullName={setFullName}
                                    setSelectedAddress={setSelectedAddress}
                                    setEmail={setEmail}
                                    setSelectedCity={setSelectedCity}
                                    setSelectedState={setSelectedState}
                                    setSelectedZip={setSelectedZip}
                                    setPhoneNumber={setPhoneNumber}
                                    setSelectedCustomerId={setSelectedCustomerId}
                                    selectedCustomerId={selectedCustomerId}
                                    customers={customers} />
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <input type="text"
                                        className="form-control"
                                        id="companyName"
                                        placeholder="Company Name"
                                        onChange={handleCompanyChange}
                                        value={companyClientText}
                                        name="companyName" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <input
                                        type="text"
                                        className={`form-control ${isValidPhone ? '' : 'is-invalid'}`}
                                        id="phone"
                                        placeholder="(xxx) xxx-xxxx"
                                        name="phone"
                                        onChange={handlePhoneChange}
                                        value={phoneNumber} // Use phoneNumber as the value
                                    />
                                    {!isValidPhone && (
                                        <div className="invalid-feedback">Please enter a valid phone number.</div>
                                    )}
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        className={`form-control`}
                                        id="phoneExt"
                                        placeholder="Ext."
                                        name="phoneExt"
                                        onChange={handlePhoneExtChange}
                                        defaultValue={selectedPhoneExt}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <input
                                        type="email"
                                        className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                                        id="email"
                                        placeholder="Email"
                                        name="email"
                                        onChange={handleEmailChange}
                                        value={email}
                                    />
                                    {!isValidEmail && (
                                        <div className="invalid-feedback">
                                            Please enter a valid email address.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1" />
                        <div className="col-sm-5 p-3 mt-5 border border-dark">
                            <h3>Service Location</h3>
                            <div className="row mt-4">
                                <GoogleAddressSearch
                                    setSelectedAddress={setSelectedAddress}
                                    setSelectedCity={setSelectedCity}
                                    setSelectedState={setSelectedState}
                                    setSelectedZip={setSelectedZip}
                                    selectedAddress={selectedAddress}
                                    setSelectedCountry={setSelectedCountry}
                                />
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <input type="text"
                                        className="form-control"
                                        id="city"
                                        placeholder="City"
                                        name="city"
                                        defaultValue={selectedCity}
                                    />
                                </div>
                                <div className="col">
                                    <select
                                        className="form-control"
                                        id="state"
                                        name="state"
                                        value={selectedState}
                                        onChange={handleStateChange}
                                        disabled={!selectedCountry}
                                    >
                                        <option value="" disabled>Select a state</option>
                                        {selectedCountry && stateOptions[`${selectedCountry}`].map((state) => (
                                            <option key={state.name} value={state.name}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <input type="text"
                                        className="form-control"
                                        id="zip"
                                        placeholder="Zip"
                                        name="zip"
                                        defaultValue={selectedZip}
                                    />
                                </div>
                                <div className="col">
                                    <select
                                        className="form-control"
                                        id="country"
                                        name="country"
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                    >
                                    <option value="" disabled>Select a country</option>
                                        {countryOptions.map((country) => (
                                            <option key={country} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div className="row g-2">
                            <div className="col-sm-5 mt-5 p-3 border border-dark">
                                <h3>Job Details</h3>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <>
                                            <JobTypeSelectComponent
                                                jobType={jobType}
                                                selectedJobType={selectedJobType}
                                                setSelectedJobType={setSelectedJobType}
                                                selectedCompany={selectedCompany}
                                                selectedJobTypeName={selectedJobTypeName}
                                                setJobType={setJobType} />
                                            <JobSourceSelectComponent setJobSourceName={setJobSourceName} setSelectedJobSource={setSelectedJobSource} selectedJobSource={selectedJobSource} jobSource={jobSource} selectedCompany={selectedCompany} setJobSource={setJobSource} />
                                        </>
                                    </div>
                                    <div className="mb-3 mt-3 col-sm-6">
                                        <textarea
                                            placeholder="Job Description"
                                            id="description"
                                            name="description"
                                            className="form-control"
                                            rows="3"
                                            value={description}  // Set the value of the textarea to the state value
                                            onChange={handleDescriptionChange}  // Set the onChange event handler
                                        ></textarea>
                                    </div>
                                </div>
                                {name && model && year ? (
                                    <>
                                        <div className="row w-100 mt-3">
                                            <div className="col-sm-4">
                                                <label>
                                                    <input
                                                        className="me-1"
                                                        type="radio"
                                                        value="All Keys Lost"
                                                        checked={selectedOption === 'All Keys Lost'}
                                                        onChange={handleRadioChange}
                                                    />
                                                    All Keys Lost
                                                </label>
                                            </div>
                                            <div className="col-sm-4">
                                                <label>
                                                    <input
                                                        className="me-1"
                                                        type="radio"
                                                        value="Duplicate"
                                                        checked={selectedOption === 'Duplicate'}
                                                        onChange={handleRadioChange}
                                                    />
                                                    Spare Key
                                                </label>
                                            </div>
                                            <div className="col-sm-4">
                                                <label>
                                                    <input
                                                        className="me-1"
                                                        type="radio"
                                                        value="Lockout"
                                                        checked={selectedOption === 'Lockout'}
                                                        onChange={handleRadioChange}
                                                    />
                                                    Car Lockout
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row w-100 mt-3">
                                            <div className="col-sm-4">
                                                {name} {model} {year}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="row w-100 mt-3">
                                        <div className="col-sm-6">
                                            <label>
                                                {other}
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-1" />
                            <div className="col-sm-5 mt-5 p-3 border border-dark">
                                <h3>Scheduled</h3>
                                <div className="row mt-3">
                                    <DateSelectComponent setSelectedDate={setSelectedDate} setSelectedDateEnd={setSelectedDateEnd} selectedDate={selectedDate} selectedDateEnd={selectedDateEnd} />
                                    <div className="col-sm-1" />
                                    <TimeSelectComponent setSelectedTimeStart={setSelectedTimeStart} setSelectedTimeEnd={setSelectedTimeEnd} selectedTimeStart={selectedTimeStart} selectedTimeEnd={selectedTimeEnd} />
                                    <div>
                                        <TechSelectNewComponent techPhoneNumber={techPhoneNumber} setTechPhoneNumber={setTechPhoneNumber} selectedTech={selectedTech} setSelectedTech={setSelectedTech} fetchUsers={fetchUsers} fetchTechInfo={fetchTechInfo} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <InventoryDispatchComponent fetchUploadsID={fetchUploadsID} model={model} years={year} name={name} />
                        <div className="text-center me-5 pe-5 mt-5">
                            <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                        </div>
                        {createCustomerResponseState.error ?? ""}
                        {createDispatchResponseState.error ?? ""}
                    </form>
                </div>
        </div>
    );
};

export default DispatchComponent;