import axios from "axios";
import AdminNewUserNewComponent from "./components/adminNewUserMainComponent";

const createUserApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/users/user`, { ...formInputs }, { withCredentials: true });
    return data;
}
const createTechInfoApiRequest = async (formInputTechEdit) => {
    const { data } = await axios.post(`/api/techInfo/admin`, { ...formInputTechEdit }, { withCredentials: true });
    return data;
};

const createLocationApiRequest = async (formInputLocationEdit) => {
    const { data } = await axios.post(`/api/inventoryLocation/post`, { ...formInputLocationEdit }, { withCredentials: true });
    return data;
};

const AdminNewUserMainPage = () => {

    return <AdminNewUserNewComponent createLocationApiRequest={createLocationApiRequest} createTechInfoApiRequest={createTechInfoApiRequest} createUserApiRequest={createUserApiRequest} />;
};

export default AdminNewUserMainPage;
