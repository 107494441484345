import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

const AdminChoiceComponent = () => {
    const dispatch = useDispatch();
    const { name } = useParams();
    const { model } = useParams();
    const { year } = useParams();
    const { vin } = useParams();
    const navigate = useNavigate();

    const navigateToUrl = () => {
        if (name && model && year && vin) {
            const url = `/${name}/${encodeURIComponent(model)}/${year}/${vin}`;
            navigate(url);
        } else if (name && model && year) {
            const url = `/${name}/${encodeURIComponent(model)}/${year}`;
            navigate(url);
        }
    };

    const navigateToDispatchUrl = () => {
        if (name && model && year && vin) {
            const dispatchUrl = `/dispatch/${name}/${encodeURIComponent(model)}/${year}/${vin}`;
            navigate(dispatchUrl);
        } else if (name && model && year) {
            const dispatchUrl = `/dispatch/${name}/${encodeURIComponent(model)}/${year}`;
            navigate(dispatchUrl);
        }
    };

    return (
        <>
            <div className="container d-flex align-items-center justify-content-center vh-100">
                <div className="d-flex">
                    <button className="btn btn-outline-primary flex-grow-1 mr-2 me-5" onClick={navigateToUrl}>
                        Locksmith Info
                    </button>
                    <button className="btn btn-outline-primary flex-grow-1 ml-2" onClick={navigateToDispatchUrl}>
                        Dispatching
                    </button>
                </div>
            </div>
        </>
    );
};

export default AdminChoiceComponent;
