import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';

const PayPinCodeComponent = ({ fetchCodes, fetchRequest, fetchVinPrice, sendPayRequest }) => {
    const auth = useSelector(state => state.auth)
    const [vinChange, setVinChange] = useState('');
    const [vinMake, setVinMake] = useState('');
    const [vinModel, setVinModel] = useState('');
    const [vinModelYear, setVinModelYear] = useState('');
    const [vinPrice, setVinPrice] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [newTotal, setNewTotal] = useState('');
    const [priceVin, setPriceVin] = useState('');
    const [seven, setSeven] = useState(false);
    const [request, setRequest] = useState([]);
    const [cardNameInput, setCardNameInput] = useState(null);
    const [addressInput, setAddressInput] = useState(null);
    const [cityInput, setCityInput] = useState(null);
    const [stateInput, setStateInput] = useState(null);
    const [countryInput, setCountryInput] = useState(null);
    const [zipInput, setZipInput] = useState(null);
    const [discountInput, setDiscountInput] = useState(null);
    const [discountAmount, setDiscountAmount] = useState('');
    const [discountError, setDiscountError] = useState('');

    const { id } = useParams();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const isTodayAvailable = (dayAvail) => {
        if (dayAvail === 'Monday - Sunday') return true;

        const today = new Date();
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const todayDay = days[today.getDay()];
        return dayAvail.includes(todayDay);
    };

    const isNowInTimeRange = (dayTime) => {
        if (dayTime === '24 Hours') return true;

        const now = new Date();
        const [startTime, endTime] = dayTime.split('-').map(t => t.trim());
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const [endHours, endMinutes] = endTime.split(':').map(Number);

        const startDate = new Date(now.setHours(startHours, startMinutes, 0, 0));
        const endDate = new Date(now.setHours(endHours, endMinutes, 0, 0));
        now.setTime(now.getTime());

        return now >= startDate && now <= endDate;
    };

    useEffect(() => {
        const abctrl = new AbortController();

        if (id) {
            fetchRequest(id)
                .then((data) => {

                    setRequest(data);
                    setVinChange(data.vin);
                    setVinMake(data.make);
                    setVinModel(data.model);
                    setVinModelYear(data.year)

                    fetchVinPrice(data.vinPrice)
                        .then((res) => {

                            if (isTodayAvailable(res.dayAvail) && isNowInTimeRange(res.dayTime)) {
                                setSeven(true);
                            } else {
                                setSeven(false);
                            }
                            setVinPrice(res);
                            setPriceVin(res.price);
                            setNewTotal((res.price).toFixed(2));
                            setDiscountAmount('');
                            setDiscountPrice('');
                            setDiscountInput(null);
                        })
                        .catch((er) =>
                            console.log({
                                error: er.response.data.message
                                    ? er.response.data.message
                                    : er.response.data,
                            })
                        );
                })
                .catch((er) =>
                    console.log({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }
        return () => abctrl.abort();
    }, [fetchVinPrice, fetchRequest, id]);

    const roundToTwoDecimalPlaces = (value) => {
        return Math.round(value * 100);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const amountInputValue = newTotal;

        try {
            if (request.status === 'Paid') {
                navigate(`/locksmith/vin/key-code/${request._id}`);
            } else {

                if (parseFloat(amountInputValue) === 0) {
                    const formInputs = {
                        request: request,
                        vin: vinChange,
                        make: vinMake,
                        model: vinModel,
                        year: vinModelYear,
                        amount: roundToTwoDecimalPlaces(amountInputValue),
                        currency: 'usd',
                        companyId: auth.user.company,
                        reqestId: id,
                        paymentFullName: '',
                        paymentAddress: '',
                        paymentCity: '',
                        paymentState: '',
                        paymentCountry: '',
                        paymentZip: '',
                        sourceSend: vinPrice.sourceSend,
                    };

                    const response = await sendPayRequest(formInputs);

                    if (response.success) {
                        navigate(`/locksmith/vin/key-code/${request._id}`);
                    } else {
                        console.log(response.data.error);
                    }
                } else {
                    if (!stripe || !elements) {
                        throw new Error('Stripe.js has not loaded yet.');
                    }

                    const cardElement = elements.getElement(CardNumberElement);

                    if (!cardElement) {
                        throw new Error('CardNumberElement not found.');
                    }

                    const cardNameInputValue = cardNameInput.value;
                    const addressInputValue = addressInput.value;
                    const stateInputValue = stateInput.value;
                    const cityInputValue = cityInput.value;
                    const countryInputValue = countryInput.value;
                    const zipInputValue = zipInput.value;
                    const cardNumberElement = elements.getElement(CardNumberElement);
                    const cardExpiryElement = elements.getElement(CardExpiryElement);
                    const cardCvcElement = elements.getElement(CardCvcElement);

                    if (
                        !addressInputValue ||
                        !stateInputValue ||
                        !cityInputValue ||
                        !countryInputValue ||
                        !amountInputValue ||
                        !cardNameInputValue ||
                        !zipInputValue ||
                        !cardNumberElement ||
                        !cardExpiryElement ||
                        !cardCvcElement
                    ) {
                        throw new Error('Please fill out all payment fields and ensure card details are valid.');
                    }
                    const formInputs = {
                        request: request,
                        vin: vinChange,
                        make: vinMake,
                        model: vinModel,
                        year: vinModelYear,
                        amount: roundToTwoDecimalPlaces(amountInputValue),
                        currency: 'usd',
                        companyId: auth.user.company,
                        reqestId: id,
                        paymentFullName: cardNameInputValue,
                        paymentAddress: addressInputValue,
                        paymentCity: cityInputValue,
                        paymentState: stateInputValue,
                        paymentCountry: countryInputValue,
                        paymentZip: zipInputValue,
                    };

                    const response = await sendPayRequest(formInputs);

                    if (response.success) {
                        navigate(`/locksmith/vin/key-code/${request._id}`);
                    } else {
                        console.log(response.data.error);
                    }
                }
            }
        } catch (error) {
            console.log({
                error: error.response?.data?.message || error.response?.data || error.message,
            });
        }
    };

    const handleBlur = () => {
        const VINPrice = parseFloat(priceVin).toFixed(2);
        const today = new Date().toISOString().split('T')[0];

        if (discountInput !== null) {
            fetchCodes(discountInput)
                .then((data) => {

                    if (data.length > 0) {
                        const findDiscount = data.find((item) => item.discount);

                        const itemStartDate = new Date(findDiscount.startDate).toISOString().split('T')[0];
                        const isStartDateValid = today >= itemStartDate;

                        const itemEndDate = new Date(findDiscount.endDate).toISOString().split('T')[0];
                        const isEndDateValid = today <= itemEndDate;

                        if (isStartDateValid) {
                            if (findDiscount.forever) {
                                if (findDiscount.discountType === 'percentage') {
                                    const newPrice = (VINPrice - (VINPrice * findDiscount.discount / 100)).toFixed(2);

                                    setDiscountPrice((VINPrice * findDiscount.discount / 100));
                                    setNewTotal(newPrice);
                                    setDiscountAmount(`${findDiscount.discount}%`);
                                    setDiscountError('');
                                } else {
                                    const discountedPrice = VINPrice - findDiscount.discount;
                                    const newPrice = discountedPrice > 0 ? discountedPrice.toFixed(2) : "0.00";

                                    setDiscountPrice(findDiscount.discount);
                                    setNewTotal(newPrice);
                                    setDiscountAmount(`$${findDiscount.discount}`);
                                    setDiscountError('');
                                }
                            } else if (isEndDateValid) {
                                if (findDiscount.discountType === 'percentage') {
                                    const newPrice = (VINPrice - (VINPrice * findDiscount.discount / 100)).toFixed(2);

                                    setDiscountPrice((VINPrice * findDiscount.discount / 100));
                                    setNewTotal(newPrice);
                                    setDiscountAmount(`${findDiscount.discount}%`);
                                    setDiscountError('');
                                } else {
                                    const discountedPrice = VINPrice - findDiscount.discount;
                                    const newPrice = discountedPrice > 0 ? discountedPrice.toFixed(2) : "0.00";

                                    setDiscountPrice(findDiscount.discount);
                                    setNewTotal(newPrice);
                                    setDiscountAmount(`$${findDiscount.discount}`);
                                    setDiscountError('');
                                }
                            } else {
                                setDiscountError('Discount code has expired.')
                            }
                        } else {
                            setDiscountError('Discount is expired or not available yet.')
                        }
                    } else {
                        setDiscountError('Discount code is invalid.')
                    }
                })
                .catch((er) =>
                    console.log({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }
    };

    const handleDiscount = (e) => {
        const discoumt = e.target.value;

        if (discoumt !== '') {
            setDiscountInput(discoumt);
            setDiscountPrice('');
            setNewTotal((vinPrice.price).toFixed(2));
            setDiscountAmount('');
            setDiscountError('');
        } else {
            setDiscountInput(null);
            setDiscountPrice('');
            setNewTotal((vinPrice.price).toFixed(2));
            setDiscountAmount('');
            setDiscountError('');
        }
    };

    return (
        <>
            <div className="container margintop1 marginbottom1">
                <form onSubmit={handleSubmit}>
                    <div className="row mb-5 pb-5 m-0 p-0">
                        <div className="col-sm-6 align-end m-0 p-4">
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-10 m-0 p-0">
                                    <span className="fw-bold">Payment Info</span>
                                    <hr />
                                    {parseFloat(newTotal) !== 0 ? (
                                        <div className="row m-0 p-0">
                                            <div className="form-row mt-2 text-start m-0 p-0">
                                                <label htmlFor="card-name">Full Name On Card</label>
                                                <input placeholder="Name on card" ref={(input) => setCardNameInput(input)} type="text" id="card-name" name="card-name" className="card-input" />
                                            </div>
                                            <div className="form-row mt-2 text-start m-0 p-0">
                                                <label htmlFor="card-number">Card number</label>
                                                <CardNumberElement id="card-number" className="card-input" />
                                            </div>
                                            <div className="row mb-4 mt-2 text-start m-0 p-0">
                                                <div className="col m-0 me-2 p-0">
                                                    <label htmlFor="card-expiry">Expiration date</label>
                                                    <CardExpiryElement id="card-expiry" className="card-input" />
                                                </div>
                                                <div className="col m-0 p-0">
                                                    <label htmlFor="card-cvc">CVV</label>
                                                    <CardCvcElement id="card-cvc" className="card-input" />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row m-0 p-0">
                                            <div className="form-row mt-2 text-start m-0 p-0">
                                                <label htmlFor="card-name">Full Name On Card</label>
                                                <input placeholder="Name on card" disabled type="text" id="card-name" name="card-name" className="card-input" />
                                            </div>
                                            <div className="form-row mt-2 text-start m-0 p-0">
                                                <label htmlFor="card-number">Card number</label>
                                                <input placeholder="Card Number" type="text" id="card-number" name="card-number" className="card-input" disabled />
                                            </div>
                                            <div className="row mb-4 mt-2 text-start m-0 p-0">
                                                <div className="col m-0 me-2 p-0">
                                                    <label htmlFor="card-expiry">Expiration date</label>
                                                    <input placeholder="Expiration Date" type="text" id="card-expiry" name="card-expiry" className="card-input" disabled />
                                                </div>
                                                <div className="col m-0 p-0">
                                                    <label htmlFor="card-cvc">CVV</label>
                                                    <input placeholder="CVC" type="text" id="card-cvc" name="card-cvc" className="card-input" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <span className="fw-bold">Billing Info</span>
                                    <hr />
                                    <div className="row m-0 p-0">
                                        <div className="row text-start m-0 p-0">
                                            <div className="col m-0 p-0">
                                                <label htmlFor="addressInput">Address:</label>
                                                <input
                                                    placeholder="Billing Address"
                                                    ref={(input) => setAddressInput(input)}
                                                    type="text"
                                                    id="addressInput"
                                                    name="addressInput"
                                                    className="form-control"
                                                    disabled={parseFloat(newTotal) === 0}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2 text-start m-0 p-0">
                                            <div className="col m-0 me-2 p-0">
                                                <label htmlFor="cityInput">City:</label>
                                                <input
                                                    placeholder="City"
                                                    ref={(input) => setCityInput(input)}
                                                    type="text"
                                                    id="cityInput"
                                                    name="cityInput"
                                                    className="form-control"
                                                    disabled={parseFloat(newTotal) === 0}
                                                />
                                            </div>
                                            <div className="col m-0 p-0">
                                                <label htmlFor="cityInput">State:</label>
                                                <input
                                                    placeholder="State"
                                                    ref={(input) => setStateInput(input)}
                                                    type="text"
                                                    id="stateInput"
                                                    name="stateInput"
                                                    className="form-control"
                                                    disabled={parseFloat(newTotal) === 0}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2 text-start m-0 p-0">
                                            <div className="col m-0 me-2 p-0">
                                                <label htmlFor="countryInput">Country:</label>
                                                <input
                                                    placeholder="State"
                                                    ref={(input) => setCountryInput(input)}
                                                    type="text"
                                                    id="countryInput"
                                                    name="countryInput"
                                                    className="form-control"
                                                    disabled={parseFloat(newTotal) === 0}
                                                />
                                            </div>
                                            <div className="col m-0 p-0">
                                                <label htmlFor="zip">Zip Code</label>
                                                <input
                                                    placeholder="Zip Code"
                                                    ref={(input) => setZipInput(input)}
                                                    type="text"
                                                    id="zip"
                                                    name="zip"
                                                    className="form-control"
                                                    disabled={parseFloat(newTotal) === 0}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-9 m-0 p-4">
                                    <div className="row m-0 p-0">
                                        <div className="col m-0 p-0">
                                            <span className="fw-bold">VIN:</span><span className="ms-2">{vinChange}</span>
                                        </div>
                                    </div>
                                    <div className="row m-0 p-0 mt-2">
                                        <div className="col m-0 p-0">
                                            <span className="fw-bold">Decoded VIN:</span> <span className="ms-2 mb-3">{vinMake} {vinModel} {vinModelYear}</span>
                                        </div>
                                    </div>
                                    {seven ? (
                                        <>
                                            <div className="row mt-2 m-0 p-0">
                                                <div className="col m-0 p-0">
                                                    <span className="mb-3 fw-bold">
                                                        Availability:
                                                    </span>
                                                    {vinPrice.dayAvail === 'Monday - Sunday' && vinPrice.dayTime === '24 Hours' ? (
                                                        <span className="ms-2">
                                                            24/7
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <span className="ms-3 fw-bold">
                                                                Price:
                                                            </span>
                                                            <span className="ms-2">
                                                                ${vinPrice.price ? vinPrice.price.toFixed(2) : ''}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row mt-2 m-0 p-0">
                                                <div className="col m-0 p-0">
                                                    <span className="mb-3 fw-bold">Wait Time:</span><span className="ms-2">{vinPrice.time}</span>
                                                </div>
                                            </div>
                                            <div className="row mt-2 mb-2 m-0 p-0">
                                                <div className="col m-0 p-0">
                                                    <span className="fw-bold">Price:</span><span className="ms-2"> ${vinPrice.price ? vinPrice.price.toFixed(2) : ''}</span>
                                                </div>
                                            </div>
                                            {discountPrice && (
                                                <div className="row mt-2 m-0 p-0">
                                                    <div className="col m-0 p-0">
                                                        <span className="mb-3 fw-bold">Discount:</span><span className="ms-2"> ${discountPrice ? discountPrice.toFixed(2) : ''} ({discountAmount})</span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row mt-2 mb-4 m-0 p-0">
                                                <div className="col m-0 p-0">
                                                    <span className="mb-3 fw-bold">Total Due:</span><span className="ms-2"> ${newTotal}</span>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="row mt-2 m-0 p-0">
                                                <div className="col m-0 p-0">
                                                    <span className="mb-3 fw-bold">Availability:</span><span className="ms-2">{vinPrice.dayAvail} {vinPrice.dayTime}</span>
                                                </div>
                                            </div>
                                            <div className="row mt-2 mb-4 m-0 p-0">
                                                <div className="col m-0 p-0">
                                                    <span className="mb-3 text-danger">Not currently available. Please check availability.</span>
                                                </div>
                                            </div>
                                            <div className="row mt-2 mb-2 m-0 p-0">
                                                <div className="col m-0 p-0">
                                                    <span className="fw-bold">Price:</span><span className="ms-2"> ${vinPrice.price ? vinPrice.price.toFixed(2) : ''}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="row mt-2 text-start m-0 p-0">
                                        <div className="col-sm-8 m-0 p-0">
                                            <label htmlFor="discountInput">Discount Code:</label>
                                            <input
                                                placeholder="Discount Code"
                                                onChange={(e) => handleDiscount(e)}
                                                defaultValue={discountInput}
                                                onBlur={handleBlur}
                                                type="text"
                                                id="discountInput"
                                                name="discountInput"
                                                className="form-control"
                                            />
                                        </div>
                                        {discountError && (
                                            <span className="m-0 p-0 text-danger mt-2">{discountError}</span>
                                        )}
                                    </div>
                                    <div className="row d-flex justify-content-center mt-4">
                                        <div className="col-sm-12 col-md-8 col-lg-5">
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary mb-3 loginButton"
                                            >
                                                Complete Order
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default PayPinCodeComponent;