import axios from "axios";
import { useDispatch } from "react-redux";
import AdminNewLocationComponent from "./components/adminNewLocationComponent";

const createLocationApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/inventoryLocation/post`, { ...formInputs }, { withCredentials: true });
    return data;
};

const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/admin", { withCredentials: true });
    return data
}
const fetchUsers = async (abctrl) => {
    const { data } = await axios.get("/api/users", { withCredentials: true });
    return data
}

const AdminNewLocationPage = () => {
    const dispatch = useDispatch();

    return (
        <AdminNewLocationComponent
            fetchUsers={fetchUsers }
            fetchTechInfo={fetchTechInfo }
            createLocationApiRequest={createLocationApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminNewLocationPage;