import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verify } from "../../redux/actions/action";

const VerifyOTPPopComponent = ({ onConfirm, onCancel }) => {
    const auth = useSelector(state => state.auth);
    const [formState, setFormState] = useState({
        otp: '',
    });
  
    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formInputs = {
            otp: formState.otp,
            email: auth.user.email,
        };

        dispatch(verify(formInputs, () => {
            onConfirm();
        }));
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder deleteWidth mb-4 ">
                        <div className="col-sm fs-5">
                            Verify Identity
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="row mt-4 g-3">
                        <div className="row m-0 p-0">
                            <div className="col mb-2">
                                <input
                                    type="text"
                                    id="otp"
                                    name="otp"
                                    placeholder="Verification Code"
                                    value={formState.otp}
                                    onChange={handleChange}
                                    className="signinFormInput form-control"
                                />
                            </div>
                        </div>
                        <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                            <div className="col-sm">
                                <button className="btn btn-outline-success loginButton" type="submit">
                                    Submit
                                </button>
                            </div>
                            <div className="col-sm">
                                <button className="btn btn-outline-primary ps-5 pe-5 loginButton" onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
};

export default VerifyOTPPopComponent;