import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import Select from "react-select";

const yearOptions = Array.from({ length: (2023 - 1970 + 1) }, (v, k) => {
    const year = k + 1970;
    return { value: year, label: year };
});

const AdminNewVinPriceComponent = ({
    createVinPriceApiRequest,
}) => {
    const [validated, setValidated] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedExtra, setSelectedExtra] = useState('');
    const [selectedSource, setSelectedSource] = useState('');
    const [createCodesResponseState, setCreateCodesResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const selectedYearsValues = selectedYear.map(option => option.value);

        const formInputs = {
            make: form.make.value,
            price: form.price.value,
            dontSupport: selectedValue,
            years: selectedYearsValues,
            dayAvail: form.dayAvail.value,
            dayTime: form.dayTime.value,
            time: form.time.value,
            extraDocs: selectedExtra,
            sourceSend: selectedSource,
        };
        if (event.currentTarget.checkValidity() === true) {
            createVinPriceApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "price created") navigate(`${paths.ADMINVINPRICE}`);
                })
                .catch((er) => {
                    setCreateCodesResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    const handleSelectExtraChange = (event) => {
        const newValue = event.target.value;
        setSelectedExtra(newValue);
    };

    const handleSelectSource = (event) => {
        const newValue = event.target.value;
        setSelectedSource(newValue);
    };

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-7">
                        <h1 className="text-center">New Vin Pricing</h1>
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="row pt-4 g-3">
                            <div className="row d-flex justify-content-between mt-3 m-0 p-0">
                                <div className="col-sm-5 p-0 m-0">
                                    <label htmlFor="make">Make:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="make"
                                        name="make"
                                    />
                                </div>
                                <div className="col-sm-5 m-0 p-0">
                                    <label htmlFor="codeSource">Run Source:</label>
                                    <select
                                        id="codeSource"
                                        className="form-select mt-0"
                                        value={selectedSource}
                                        onChange={handleSelectSource}
                                    >
                                        <option value="">Select An Source</option>
                                        <option value="Magic">Magic</option>
                                        <option value="Us">Us</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col p-0 m-0">
                                    <label htmlFor="year">Year:</label>
                                    <Select
                                        isMulti
                                        options={yearOptions}
                                        value={selectedYear}
                                        onChange={(selectedOptions) => setSelectedYear(selectedOptions)}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col ms-0 ps-0 mt-2">
                                    <label htmlFor="time">Time To Complete:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="time"
                                        name="time"
                                    />
                                </div>
                                <div className="col ms-0 ps-0 mt-2">
                                    <label htmlFor="dayAvail">Availability Days:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="dayAvail"
                                        name="dayAvail"
                                    />
                                </div>
                                <div className="col ms-0 ps-0 mt-2">
                                    <label htmlFor="dayTime">Availability Time:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="dayTime"
                                        name="dayTime"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col ms-0 ps-0 mt-2">
                                    <label htmlFor="price">Price:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="price"
                                        name="price"
                                    />
                                </div>
                                <div className="col ms-0 ps-0">
                                    <label className="form-label" htmlFor="trueFalseSelect">Requires Documents:</label>
                                    <select
                                        id="trueFalseSelect"
                                        className="form-select"
                                        value={selectedExtra.toString()}
                                        onChange={handleSelectExtraChange}
                                    >
                                        <option value="">Select An Option</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>
                                </div>
                                <div className="col ms-0 ps-0">
                                    <label className="form-label" htmlFor="trueFalseSelect">Support Running Codes:</label>
                                    <select
                                        id="trueFalseSelect"
                                        className="form-select"
                                        value={selectedValue.toString()}
                                        onChange={handleSelectChange}
                                    >
                                        <option value="">Select An Option</option>
                                        <option value="true">Support</option>
                                        <option value="false">Don't Support</option>
                                    </select>
                                </div>
                            </div>
                            <div className="text-center pt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINVINPRICE} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createCodesResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminNewVinPriceComponent;