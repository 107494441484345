import React, { useState } from "react";
import { FaRegThumbsUp } from 'react-icons/fa';
import { BsPencil } from 'react-icons/bs';
import SignaturePopupComponent from "./signaturePopupComponent";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const InvoiceSignaturesComponent = ({ customer, setShowSignAlert, showSignAlert, fetchInvoice, updateInvoiceSign }) => {
    const [showAlert1, setShowAlert1] = useState(false);
    const { id } = useParams();
    const [signatureData, setSignatureData] = useState([{ signatureName: "", signature: "", dateTime: null }]);

    useEffect(() => {
        const abctrl = new AbortController();

        fetchInvoice(id)
            .then((res) => {
                setSignatureData(
                    res.signature.map((sign, index) => ({
                        signature: sign.signature,
                        signatureName: sign.signatureName,
                        dateTime: sign.dateTime,
                    }))
                );
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        return () => abctrl.abort();
    }, [fetchInvoice, id]);

    const handleCloseAlert = () => {
        setShowSignAlert(false);
    };
    const handleCloseAlert1 = () => {
        setShowAlert1(false);
    };

    const handleApplySign = () => {
        setShowSignAlert(true);
    };

    const handleApplySignatures = (inputValue, signatureDataURL) => {
        const signedDateTime = new Date(); // Get the current date and time

        const updatedSignatureData = [
            ...signatureData,
            { signatureName: inputValue, signature: signatureDataURL, dateTime: signedDateTime }
        ];

        setSignatureData(updatedSignatureData);

        const formInputsSign = {
            signature: updatedSignatureData,
        };

        updateInvoiceSign(id, formInputsSign)
            .then((data) => {
                if (data.message === "invoice updated") {
                    setShowSignAlert(false);
                    setShowAlert1(true);
                    setTimeout(() => {
                        setShowAlert1(false);
                    }, 3000);
                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    function formatDateTime(dateTime) {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };

        return new Date(dateTime).toLocaleDateString(undefined, options);
    }

    return (
        <>
            <div className="row">
                {showAlert1 && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Invoice Updated Successfully
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert1}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showSignAlert && (
                    <SignaturePopupComponent
                        customer={customer}
                        onConfirm={handleApplySignatures}
                        onCancel={handleCloseAlert}
                    />
                )}
                <div className="row depositTitle m-0 mb-2 ps-0 pe-0">
                    <span className="jobSectionSpan col-sm-9 m-0 p-0">Signatures</span>
                    <div className="col m-0 p-0 btnColContent">
                        <span htmlFor="fileInput" className="btn btn-outline-primary btn-sm btnUpload ps-2 pe-2" onClick={handleApplySign}><BsPencil className="me-1" /> Sign</span>
                    </div>
                </div>
                {signatureData.length === 0 ? (
                    <span className="">No signatures found</span>
                ) : (
                    <>
                        {signatureData.map((signatureItem, index) => (
                            <div key={index} className="row justify-content-center">
                                <table className="col-sm-12 table">
                                    <thead className="border-bottom pt-4">
                                        <tr className="fontSizeTime">
                                            <th scope="col" className="item-col ps-3" style={{ width: "45%" }}>Signature</th>
                                            <th scope="col" style={{ width: "30%" }}>Signed By</th>
                                            <th scope="col" style={{ width: "50%" }}>Signed</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <img src={signatureItem.signature} className="signatureImage" alt="Signature Data" />
                                            </th>
                                            <td className="fontSizeTime pt-3">
                                                {signatureItem.signatureName}
                                            </td>
                                            <td className="fontSizeTime">
                                                {formatDateTime(signatureItem.dateTime)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))
                        }
                    </>
                )}
            </div>
        </>
    );
};

export default InvoiceSignaturesComponent;
