import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import InventoryComponent from "../../components/inventoryComponent";
import paths from "../../router/paths";
import PayNowComponent from "../../components/payNowComponent";
import '../../components/assets/LineSpinner.css';
import { useSelector } from "react-redux";

const LocksmithInfoListComponent = ({ fetchInstructionId, fetchRequest, createRequestApiRequest, fetchLocation, fetchTechInfo, fetchUsers, fetchUploads, fetchUploadsID, fetchLocksmithInfo, fetchMake }) => {
    const [locksmithInfo, setLocksmithInfo] = useState([]);
    const { model } = useParams();
    const { name } = useParams();
    const { year } = useParams();
    const { vin } = useParams();
    const [make, setMake] = useState([]);
    const [uploads, setUploads] = useState([]);
    const [description, setDescription] = useState([]);
    const [loading, setLoading] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [dots, setDots] = useState([false, false, false]);
    const auth = useSelector(state => state.auth)

    const isEuropean = auth.user?.usertype?.includes('European') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => {
                const firstFalseIndex = prevDots.findIndex(dot => !dot);
                if (firstFalseIndex !== -1) {
                    const newDots = [...prevDots];
                    newDots[firstFalseIndex] = true;
                    return newDots;
                }
                return [false, false, false];
            });
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const [makeData, locksmithData] = await Promise.all([fetchMake(), fetchLocksmithInfo(year, name, model)]);
                setMake(makeData);

                const fetchInstructionData = async (data, idProperty) => {
                    if (Array.isArray(data[idProperty]) && data[idProperty].length > 0) {
                        const instructions = await Promise.all(data[idProperty].map(id => fetchInstructionId(id)));
                        return { ...data, [idProperty]: instructions };
                    }
                    return data;
                };

                const processedLocksmithData = await Promise.all(locksmithData.map(async data => {
                    const propertiesToFetch = ['instructionId', 'instructionDiagnoseId', 'instructionHardwareId', 'instructionRekeyId', 'instructionOtherId'];

                    for (const property of propertiesToFetch) {
                        data = await fetchInstructionData(data, property);
                    }

                    return data;
                }));

                setLocksmithInfo(processedLocksmithData);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLocksmithInfo([{ name: error.response?.data?.message || error.response?.data || 'An error occurred.' }]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, [name, model, year, fetchMake, fetchLocksmithInfo, fetchInstructionId]);

    useEffect(() => {
        const fetchAndSetUploads = async () => {
            if (locksmithInfo.length > 0 && uploads.length === 0) {
                const imagesWithDescriptions = locksmithInfo
                    .map((item) => item.image)
                    .flat()
                    .filter((imageItem) => imageItem && imageItem.description);

                if (imagesWithDescriptions.length > 0) {
                    const imagePromises = imagesWithDescriptions.map(async (imageItem) => {
                        const id = imageItem.id._id;
                        return { id, description: imageItem.description };
                    });

                    const imageDataArray = await Promise.all(imagePromises);

                    const uploadIds = imageDataArray.map((item) => item.id);
                    const filteredUploads = await fetchUploads(uploadIds);
                    const imageUrls = imageDataArray.map((imageData) => {
                        const filteredUpload = filteredUploads.find((upload) => upload._id === imageData.id);
                        return filteredUpload ? filteredUpload.imageUrl : null;
                    });

                    const descriptions = imageDataArray.map((item) => item.description);

                    setUploads(imageUrls);
                    setDescription(descriptions);
                    setLoading(false);
                }


            }
        };

        fetchAndSetUploads();
    }, [fetchUploads, locksmithInfo, uploads]);


    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };

    return (
        <>
            <div className="container mt-5 mb-5 pb-5">
                {make.some((makeItem) => (makeItem.name === name) && (makeItem.european) && (!isEuropean && !isSuperAdmin)) ? (
                    <PayNowComponent />
                ) : (
                    <>

                        <div className="row mb-2">
                            <nav className="col-9" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={paths.ROOT}>Home</Link></li>
                                    <li className="breadcrumb-item"><Link to={paths.LOCKSMITHSEARCH}>Locksmith Search</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{name} {model} {year}</li>
                                </ol>
                            </nav>
                        </div>
                        {locksmithInfo.map((item, idx) => (
                            <div className="row" key={idx}>
                                <div className="col-sm-12 text-center mt-0 mb-0">
                                    <table className="table mb-0 mt-0 text-center table-bordered " key={item._id}>
                                        <tbody>
                                            <tr className="border border-dark">
                                                <td className="text-center editor">
                                                    <div dangerouslySetInnerHTML={{ __html: item.yearName }} />
                                                </td>
                                                {item.im608 ? (
                                                    <>
                                                        <td className="align-middle">IM608</td>
                                                        <td className="align-middle">Zedfull</td>
                                                        <td className="align-middle">On Board</td>
                                                        <td className="align-middle">Smartpro</td>
                                                        <td className="align-middle">Key Tool</td>
                                                        <td className="align-middle">Topdon</td>
                                                        <td className="align-middle">Smartbox</td>
                                                        <td className="align-middle">Max # of Keys</td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                    </>
                                                )}
                                            </tr>
                                            {item.im608 ? (
                                                <tr className={`border border-dark ${item.color}`}>
                                                    <td className="align-middle">{item.keyType}</td>
                                                    <td className="align-middle">{item.im608}</td>
                                                    <td className="align-middle">{item.zedfull}</td>
                                                    <td className="align-middle">{item.onBoard}</td>
                                                    <td className="align-middle">{item.smartPro}</td>
                                                    <td className="align-middle">{item.rw4}</td>
                                                    <td className="align-middle">{item.topdon}</td>
                                                    <td className="align-middle">{item.smartbox}</td>
                                                    <td className="align-middle">{item.numberOfKeys}</td>
                                                </tr>
                                            ) : (<></>)}
                                            {item.im6081 ? (
                                                <tr className={`border border-dark ${item.color1}`}>
                                                    <td className="align-middle">{item.keyType1}</td>
                                                    <td className="align-middle">{item.im6081}</td>
                                                    <td className="align-middle">{item.zedfull1}</td>
                                                    <td className="align-middle">{item.onBoard1}</td>
                                                    <td className="align-middle">{item.smartPro1}</td>
                                                    <td className="align-middle">{item.rw41}</td>
                                                    <td className="align-middle">{item.topdon1}</td>
                                                    <td className="align-middle">{item.smartbox1}</td>
                                                    <td className="align-middle">{item.numberOfKeys1}</td>
                                                </tr>
                                            ) : (<></>)}
                                            {item.im6082 ? (
                                                <tr className={`border border-dark ${item.color2}`}>
                                                    <td className="align-middle">{item.keyType2}</td>
                                                    <td className="align-middle">{item.im6082}</td>
                                                    <td className="align-middle">{item.zedfull2}</td>
                                                    <td className="align-middle">{item.onBoard2}</td>
                                                    <td className="align-middle">{item.smartPro2}</td>
                                                    <td className="align-middle">{item.rw42}</td>
                                                    <td className="align-middle">{item.topdon2}</td>
                                                    <td className="align-middle">{item.smartbox2}</td>
                                                    <td className="align-middle">{item.numberOfKeys2}</td>
                                                </tr>
                                            ) : (<></>)}
                                            {item.im6083 ? (
                                                <tr className={`border border-dark ${item.color3}`}>
                                                    <td className="align-middle">{item.keyType3}</td>
                                                    <td className="align-middle">{item.im6083}</td>
                                                    <td className="align-middle">{item.zedfull3}</td>
                                                    <td className="align-middle">{item.onBoard3}</td>
                                                    <td className="align-middle">{item.smartPro3}</td>
                                                    <td className="align-middle">{item.rw43}</td>
                                                    <td className="align-middle">{item.topdon3}</td>
                                                    <td className="align-middle">{item.smartbox3}</td>
                                                    <td className="align-middle">{item.numberOfKeys3}</td>
                                                </tr>
                                            ) : (<></>)}
                                            {item.im6084 ? (
                                                <tr className={`border border-dark ${item.color4}`}>
                                                    <td className="align-middle">{item.keyType4}</td>
                                                    <td className="align-middle">{item.im6084}</td>
                                                    <td className="align-middle">{item.zedfull4}</td>
                                                    <td className="align-middle">{item.onBoard4}</td>
                                                    <td className="align-middle">{item.smartPro4}</td>
                                                    <td className="align-middle">{item.rw44}</td>
                                                    <td className="align-middle">{item.topdon4}</td>
                                                    <td className="align-middle">{item.smartbox4}</td>
                                                    <td className="align-middle">{item.numberOfKeys4}</td>
                                                </tr>
                                            ) : (<></>)}
                                            {item.im6085 ? (
                                                <tr className={`border border-dark ${item.color5}`}>
                                                    <td className="align-middle">{item.keyType5}</td>
                                                    <td className="align-middle">{item.im6085}</td>
                                                    <td className="align-middle">{item.zedfull5}</td>
                                                    <td className="align-middle">{item.onBoard5}</td>
                                                    <td className="align-middle">{item.smartPro5}</td>
                                                    <td className="align-middle">{item.rw45}</td>
                                                    <td className="align-middle">{item.topdon5}</td>
                                                    <td className="align-middle">{item.smartbox5}</td>
                                                    <td className="align-middle">{item.numberOfKeys5}</td>
                                                </tr>
                                            ) : (<></>)}
                                            <tr className="border border-dark">
                                                <td colSpan="10" className="text-start">
                                                    <div className="editor" dangerouslySetInnerHTML={{ __html: item.mainContent }} />
                                                    {item.instructionId && item.instructionId.length > 0 && (
                                                        <>
                                                            <div className="mt-4 mb-3">
                                                                <span className="fw-bold me-2">EEPROM Instructions:</span><br />
                                                                {item.instructionId.map((instruc) => (
                                                                    <>
                                                                        <div key={instruc._id} className="row m-0 p-0 mb-3">
                                                                            <div className="col ms-3 m-0 p-0">
                                                                                <div className="mt-2">
                                                                                    - {instruc.name}
                                                                                    <Link to={`/locksmith-help/${encodeURIComponent(instruc.instructionCatagory)}/${encodeURIComponent(instruc.name)}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary ms-3 loginButton">See Instructions</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {item.instructionDiagnoseId && item.instructionDiagnoseId.length > 0 && (
                                                        <>
                                                            <div className="mt-4 mb-3">
                                                                <span className="fw-bold me-2">Diagnose Instructions:</span>
                                                                {item.instructionDiagnoseId.map((instruc) => (
                                                                    <>
                                                                        <div key={instruc._id} className="row m-0 p-0 mb-3">
                                                                            <div className="col ms-3 m-0 p-0">
                                                                                <div key={instruc._id} className="mt-2">
                                                                                    - {instruc.name}
                                                                                    <Link to={`/locksmith-help/${encodeURIComponent(instruc.instructionCatagory)}/${encodeURIComponent(instruc.name)}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary ms-3 loginButton">See Instructions</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {item.instructionRekeyId && item.instructionRekeyId.length > 0 && (
                                                        <>
                                                            <div className="mt-4 mb-3">
                                                                <span className="fw-bold me-2">Rekey Instructions:</span>
                                                                {item.instructionRekeyId.map((instruc) => (
                                                                    <>
                                                                        <div className="row m-0 p-0 mb-3">
                                                                            <div className="col ms-3 m-0 p-0">
                                                                                <div key={instruc._id} className="mt-2">
                                                                                    - {instruc.name}
                                                                                    <Link to={`/locksmith-help/${encodeURIComponent(instruc.instructionCatagory)}/${encodeURIComponent(instruc.name)}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary ms-3 loginButton">See Instructions</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {item.instructionHardwareId && item.instructionHardwareId.length > 0 && (
                                                        <>
                                                            <div className="mt-4 mb-3">
                                                                <span className="fw-bold me-2">Hardware Install/Repair Instructions:</span>
                                                                {item.instructionHardwareId.map((instruc) => (
                                                                    <>
                                                                        <div className="row m-0 p-0 mb-3">
                                                                            <div className="col ms-3 m-0 p-0">
                                                                                <div key={instruc._id} className="mt-2">
                                                                                    - {instruc.name}
                                                                                    <Link to={`/locksmith-help/${encodeURIComponent(instruc.instructionCatagory)}/${encodeURIComponent(instruc.name)}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary ms-3 loginButton">See Instructions</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {item.instructionOtherId && item.instructionOtherId.length > 0 && (
                                                        <>
                                                            <div className="mt-4 mb-3">
                                                                <span className="fw-bold me-2">Other Instructions:</span>
                                                                {item.instructionOtherId.map((instruc) => (
                                                                    <>
                                                                        <div className="row m-0 p-0 mb-3">
                                                                            <div className="col ms-3 m-0 p-0">
                                                                                <div key={instruc._id} className="mt-2">
                                                                                    - {instruc.name}
                                                                                    <Link to={`/locksmith-help/${encodeURIComponent(instruc.instructionCatagory)}/${encodeURIComponent(instruc.name)}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary ms-3 loginButton">See Instructions</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="mt-4 mb-3">
                                                        <span className="fw-bold me-2">Lishi:</span>
                                                        <span>{item.lishi}</span>
                                                    </div>
                                                    <div className="mt-2 mb-3">
                                                        <span className="fw-bold me-2">Test Blade:</span>
                                                        <span>{item.testBlade}</span>
                                                    </div>
                                                    {item.doorCode && (
                                                        <div className="mt-2 mb-3">
                                                            <span className="fw-bold me-2">Door Code Location:</span>
                                                            <span>{item.doorCode}</span>
                                                        </div>
                                                    )}
                                                    <div className="mt-2">
                                                        <span className="fw-bold me-2">Key Code Location:</span>
                                                        <span>{item.keyCode}</span>
                                                    </div>
                                                    {vin ? (
                                                        <>
                                                            <div className="mt-2 ms-3">
                                                                <span className="fw-bold me-2">VIN -</span>
                                                                <span>{vin}</span>
                                                            </div>
                                                            <div className="mb-3 ms-4 mt-1">
                                                                <Link className="btn btn-sm btn-outline-primary loginButton" to={`/buy-pincode/${vin}`}>Purchase</Link>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="mb-3 mt-2 ms-3">
                                                                <Link className="btn btn-sm btn-outline-primary loginButton" to={paths.BUYKEYCODE}>Purchase</Link>
                                                        </div>
                                                    )}
                                                    <div className="mt-2">
                                                        <span className="fw-bold me-2">Mechanical Key Info:</span>
                                                        <span>{item.keyInfo}</span>
                                                    </div>
                                                    <div className="mb-3 ms-3">
                                                        <span className="me-2">Code Series:</span>
                                                        <span>{item.codeSeries}</span>
                                                    </div>
                                                    <InventoryComponent
                                                        fetchRequest={fetchRequest}
                                                        createRequestApiRequest={createRequestApiRequest}
                                                        fetchLocation={fetchLocation}
                                                        fetchUploadsID={fetchUploadsID}
                                                        name={name}
                                                        model={model}
                                                        years={year}
                                                        fetchTechInfo={fetchTechInfo}
                                                        fetchUsers={fetchUsers}
                                                    />
                                                </td>
                                            </tr>
                                            <tr className="border border-dark">
                                                <td className="bg-primary" colSpan="9"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {loading ? (
                                    <div className="d-flex justify-content-center mt-4">
                                        <div className="row-spinner">
                                            {dots.map((dot, index) => (
                                                <div key={index} className={`dot ${dot ? 'visible' : ''}`} style={{ animationDelay: `${index * 0.3}s` }}></div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row w-100 justify-content-start mt-5 mb-5">
                                        {uploads.length > 0 && (
                                            <div className="d-flex flex-wrap">
                                                {uploads.map((upload, index) => (
                                                    <div className="ms-3 mb-3 card m-0 p-0 position-relative" style={{ width: '20rem' }} key={index}>
                                                        <img
                                                            src={upload}
                                                            alt={`Preview`}
                                                            style={{ height: '300px', cursor: 'pointer' }}
                                                            className="card-img-top p-0 m-0"
                                                            onClick={() => handleImageClick(upload)}
                                                        />
                                                        <div className="card-body w-100">
                                                            <span>{description[index]}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="enlarged-image-container d-flex justify-content-center">
                                    <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {selectedImage && <img src={selectedImage} alt="Large Preview" className="enlarged image" />}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        ))}
                    </>

                )}
            </div>
        </>
    );
};

export default LocksmithInfoListComponent;