import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

const SignaturePopupComponent = ({ onConfirm, customer, onCancel }) => {
    const [inputValue, setInputValue] = useState(customer.name + ' ' + customer.lastName);
    const signatureRef = useRef();

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleApplyDiscount = () => {
        const signatureDataURL = signatureRef.current.toDataURL();
        onConfirm(inputValue, signatureDataURL);
    };
   
    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel} // Close modal if clicked outside
            />
            <div className="confirmation-box-item112">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder bg-light deleteWidth mb-4">
                        <div className="col-sm fs-5">
                            Document Signature
                        </div>
                    </div>
                    <div className="row mb-2 m-0 p-0">
                        <div className="col-sm-5 p-0 m-0">
                            <span className="signBoxTitle">Please sign in the box below:</span>
                        </div>
                    </div>
                    <div className="row mb-4 signatureCanvasModule justify-content-center">
                        <SignatureCanvas
                            penColor="black"
                            canvasProps={{
                                width: 401,
                                height: 150,
                                className: "signature-canvas",
                                style: {
                                    cursor: "crosshair",
                                    border: "1px solid black", // Add a border for visualization
                                    margin: "0",              // Ensure no margin
                                    padding: "0"              // Ensure no padding
                                }
                            }}
                            ref={signatureRef}
                        />

                    </div>
                    <div className="row justify-content-center">
                            <span className="form-floating fLabel p-0 m-0">
                                <input
                                    type="text"
                                    className="form-control sajInput"
                                    id="jobItems"
                                    name="jobItems"
                                    autoComplete="off"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="jobItems" className="inputLabel">Signers Name</label>
                            </span>
                    </div>
                    <div className="row justify-content-center bg-light mt-3 border-top pt-3 pb-3 border-dark">
                        <div className="col-sm">
                            <button className="btn btn-outline-primary ps-5 pe-5" onClick={onCancel}>
                                Close
                            </button>
                        </div>
                        <div className="col-sm">
                            <button onClick={handleApplyDiscount} className="btn btn-outline-danger m-0 ps-5 pe-5">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignaturePopupComponent;
