import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import '../../components/assets/LineSpinner.css';
import paths from "../../router/paths";
import { MdOutlineImageNotSupported } from "react-icons/md";

const LocksmithHelpIndexComponent = ({ fetchInstruction }) => {
    const [instruction, setInstruction] = useState([]);
    const { category } = useParams();
    const { name } = useParams();

    const [loading, setLoading] = useState([]);
    const [dots, setDots] = useState([false, false, false]);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => {
                const firstFalseIndex = prevDots.findIndex(dot => !dot);
                if (firstFalseIndex !== -1) {
                    const newDots = [...prevDots];
                    newDots[firstFalseIndex] = true;
                    return newDots;
                }
                return [false, false, false];
            });
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const instructionData = await fetchInstruction(category, name);
                setInstruction(instructionData[0]);

            } catch (error) {
                console.log([{ name: error.response?.data?.message || error.response?.data || 'An error occurred.' }]);
            }
            setLoading(false);
        };

        fetchData();
    }, [fetchInstruction, category]);

    return (
        <div className="container mt-5 mb-5 pb-5">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={paths.LOCKSMITHSEARCH}>Locksmith Search</Link></li>
                    <li className="breadcrumb-item"><Link to={`/locksmith-help/${encodeURIComponent(category)}`}>{category} Instructions</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{name}</li>
                </ol>
            </nav>
            <h1 className="text-center">{name} Instructions</h1>
            <div className="mt-5">
                {loading ? (
                    <div className="d-flex justify-content-center mt-4" >
                        <div className="row-spinner">
                            {dots.map((dot, index) => (
                                <div key={index} className={`dot ${dot ? 'visible' : ''}`} style={{ animationDelay: `${index * 0.3}s` }}></div>
                            ))}
                        </div>
                    </div >
                ) : (
                    <div>
                        {instruction && instruction.tools.length > 0 && (
                            <div className="row mb-4 justify-content-start">
                                <div className="col-sm-4 me-5 m-0 p-0">
                                    <span className="fw-bold fs-5">Tools Required:</span>
                                    <ul className="list-group">
                                        {instruction.tools.map((item, idx) => (
                                            <li key={idx} className="m-0 p-0 ms-5 listUl list-group-item">{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {instruction && instruction.instructionSteps.length > 0 && (
                            <div className="row justify-content-start">
                                <div className="col-sm-12 m-0 p-0">
                                    <span className="fw-bold fs-5">Instructions:</span>
                                    <ol className="list-group list-group-numbered">
                                        {instruction.instructionSteps.map((item, idx) => (
                                            <li key={idx} className="list-group-item">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        {item.uploadId ? (
                                                            <img src={item.uploadId[0].imageUrl} style={{ width: '25rem' }} className="card-img-top" alt={item.uploadId[0].fileName} />
                                                        ) : (
                                                            <div className="row d-flex justify-content-center">
                                                                <div className="col-sm-5">
                                                                    <MdOutlineImageNotSupported className="card-img-top" style={{ width: '10rem', height: '10rem' }} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <p>
                                                            {item.step}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LocksmithHelpIndexComponent;