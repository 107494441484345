import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/userActions";
import { Link } from "react-router-dom";
import { MdOutlineInventory } from "react-icons/md";

const KeyCodeComponent = ({ fetchRequestCompany }) => {
    const [codes, setCodes] = useState([]);
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");


    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchRequestCompany(auth.user.company)
            .then((res) => {
                setCodes(res);
                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [dispatch, fetchRequestCompany]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };

    return (
        <div className="mt-5">
            {auth.user?.name ? (
                <div className="row m-5 mt-5 pt-5">
                    <div className="col-sm-12">
                        <div className="row mb-3">
                            <div className="col-sm-9">
                                <h1>My Codes</h1>
                            </div>
                            <div className="col-sm-3">
                                <input
                                    type="text"
                                    disabled={codes.length === 0}
                                    className="mt-2 input-group table-filter w-100"
                                    data-table="order-table"
                                    placeholder="Search Codes.."
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                        {codes.length > 0 ? (
                            <>
                                {isLoading ? (
                                    <div className="d-flex justify-content-center m-5 p-5">
                                        <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                ) : (
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center">VIN</th>
                                                <th scope="col" className="text-center">Make</th>
                                                <th scope="col" className="text-center">Model</th>
                                                <th scope="col" className="text-center">Year</th>
                                                <th scope="col" className="text-center">Key Code</th>
                                                <th scope="col" className="text-center">PIN Code</th>
                                                <th scope="col" className="text-center">Transponder</th>
                                                <th scope="col" className="text-center">Transponder Info</th>
                                                <th scope="col" className="text-center">More Info</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {codes
                                                .filter(
                                                    (code) =>
                                                        code.vin.toLowerCase().indexOf(searchValue) !== -1 ||
                                                        code.make.toLowerCase().indexOf(searchValue) !== -1 ||
                                                        code.model.toLowerCase().indexOf(searchValue) !== -1 ||
                                                        code.code && code.code.keyCode.toLowerCase().indexOf(searchValue) !== -1 ||
                                                        code.code && code.code.pinCode.toLowerCase().indexOf(searchValue) !== -1
                                                )
                                                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                                .map((code, idx) => (
                                                    <tr key={idx}>
                                                        <td className="text-center">{code.vin}</td>
                                                        <td className="text-center">{code.make}</td>
                                                        <td className="text-center">{code.model}</td>
                                                        <td className="text-center">{code.year}</td>
                                                        <td className="text-center">
                                                            {code.code ? (
                                                                <span>{code.code.keyCode}</span>
                                                            ) : (
                                                                <>
                                                                    {code.status !== 'Paid' ? (
                                                                        <span>-</span>
                                                                    ) : (
                                                                        <span>Calculating</span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            {code.code ? (
                                                                <span>{code.code.pinCode}</span>
                                                            ) : (
                                                                <>
                                                                    {code.status !== 'Paid' ? (
                                                                        <span>-</span>
                                                                    ) : (
                                                                        <span>Calculating</span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            {code.code ? (
                                                                <>
                                                                    {code.code.transponder ? (
                                                                        <span>True</span>
                                                                    ) : (
                                                                        <span>False</span>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {code.status !== 'Paid' ? (
                                                                        <span>-</span>
                                                                    ) : (
                                                                        <span>Calculating</span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            {code.code ? (
                                                                <span>{code.code.transponderKey}</span>
                                                            ) : (
                                                                <>
                                                                    {code.status !== 'Paid' ? (
                                                                        <span>-</span>
                                                                    ) : (
                                                                        <span>Calculating</span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            <Link to={`/locksmith/vin/key-code/${code._id}`} className="btn btn-sm btn-outline-primary loginButton">More Info</Link>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                )}
                            </>
                        ) : (
                            <div className="mt-5">
                                <div className="row d-flex justify-content-center m-0 p-0">
                                    <div className="col-sm-4 m-0 p-0 text-center">
                                        <MdOutlineInventory className="inventoryLogo fs-1" />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center m-0 p-0">
                                    <div className="col-sm-4 m-0 p-0 text-center">
                                        <span>Currently no codes have been ran. Enter a VIN above to request key info.</span> <br />
                                    </div>
                                </div>
                            </div>
                        )}
                        {codes.length > 0 && (
                            <div className="d-flex justify-content-center pt-4">
                                <button
                                    type="button"
                                    className="btn-sm btn btn-outline-primary mr-2 me-3 loginButton"
                                    disabled={page === 1}
                                    onClick={handlePrevPage}
                                >
                                    Previous
                                </button>
                                <span className="mr-2 me-3">
                                    Page {page} of {Math.ceil(codes
                                        .filter(
                                            (code) =>
                                                code.vin.toLowerCase().indexOf(searchValue) !== -1 ||
                                                code.make.toLowerCase().indexOf(searchValue) !== -1 ||
                                                code.model.toLowerCase().indexOf(searchValue) !== -1 ||
                                                code.code && code.code.keyCode.toLowerCase().indexOf(searchValue) !== -1 ||
                                                code.code && code.code.pinCode.toLowerCase().indexOf(searchValue) !== -1
                                        ).length / rowsPerPage)}
                                </span>
                                <button
                                    type="button"
                                    className="btn-sm btn btn-outline-primary loginButton"
                                    disabled={codes
                                        .filter(
                                            (code) =>
                                                code.vin.toLowerCase().indexOf(searchValue) !== -1 ||
                                                code.make.toLowerCase().indexOf(searchValue) !== -1 ||
                                                code.model.toLowerCase().indexOf(searchValue) !== -1 ||
                                                code.code && code.code.keyCode.toLowerCase().indexOf(searchValue) !== -1 ||
                                                code.code && code.code.pinCode.toLowerCase().indexOf(searchValue) !== -1
                                        ).length <= page * rowsPerPage}
                                    onClick={handleNextPage}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (dispatch(logout()))}
        </div>
    );
};

export default KeyCodeComponent;