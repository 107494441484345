import axios from "axios";
import AdminYearComponent from "./components/adminYearComponent";

const fetchYear = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleYear/admin", { withCredentials: true });
    return data
}

const deleteYear = async (yearId) => {
    const { data } = await axios.delete(`/api/vehicleYear/${yearId}`, { withCredentials: true });
    return data
}

const AdminYearPage = () => {

    return <AdminYearComponent fetchYear={fetchYear} deleteYear={deleteYear} />;
};

export default AdminYearPage;
