import React, { useEffect, useState } from "react";
import '../assets/payment.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ConnectStripeButton from "../stripe/connectStripeButton";
import PaymentForm from "../stripe/paymentForm";
import DeletePaymentPopModal from "../stripe/deletePaymentPopup";
import RefundPaymentPopModal from "../stripe/refundPaymentPopup";
import { FcMoneyTransfer } from 'react-icons/fc';
import { BsTrash } from 'react-icons/bs';
import { RiRefund2Fill } from "react-icons/ri";
import { useParams } from "react-router-dom";

const stripePromise = loadStripe('pk_test_51NukCVEVP3HcTg1aHpVOHTyiSqxEfA1m4EGAqaFKBJwy5ssADis9ToSjjXCH3cT169Av456ZMWIp8qtT1oGvBORM00hBVNo5te');

const EstimatePaymentTabComponent = ({ setPayLoading, fetchEstimates, subtotal, deletePayment, fetchDispatch, jobLoading, payLoading, fetchPaymentAll, companies, setCompanies, setTotalPay }) => {
    const [payment, setPayment] = useState([]);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [paymentId, setPaymentId] = useState('');
    const [refundId, setRefundId] = useState('');
    const [refundAmount, setRefundAmount] = useState('');
    const [dispatching, setDispatch] = useState({});
    const [loading, setLoading] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0.00);
    const { id } = useParams();

    const handleAddPaymentClick = () => {
        setShowPaymentForm(true);
    };


    const handlePaymentFormClose = () => {
        setShowPaymentForm(false);
    };

    const handleConfirmRemovePayment = () => {
        deletePayment(paymentId)
            .then((data) => {
                if (data.message === 'payment deleted successfully') {
                    setLoading(true);
                    setShowModal(false);
                }
            });
    };

    const handleCancelRemoveJob = () => {
        setShowModal(false);
    };

    const handleRemovePayment = (paymentId) => {
        setPaymentId(paymentId);
        setShowModal(true);
    };

    const handleCancelRefund = () => {
        setShowRefundModal(false);
    };

    const handleRefundPayment = (refundId, amount) => {
        setRefundId(refundId);
        setRefundAmount(amount);
        setShowRefundModal(true);
    };

    const handleRefund = async () => {
        try {
            const response = await fetch('/api/payment/refund', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refundAmount: refundAmount,
                    paymentIntentId: refundId,
                }),
            });

            const data = await response.json();

            if (data.success) {
                setShowRefundModal(false);
                setLoading(true);
            } else {
                alert('Refund failed');
            }
        } catch (error) {
            console.error('Error during refund:', error);
            alert('Refund failed');
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {

                fetchEstimates(id)
                    .then((resData) => {

                        fetchDispatch(resData.jobId)
                            .then((data) => {
                                setDispatch(data);

                                fetchPaymentAll()
                                    .then((res) => {
                                        const filterPayments = res.filter((item) => item.estimateId === id);

                                        setPayment(filterPayments)

                                        const calculatedTotalAmount = filterPayments.reduce((acc, payment) => {
                                            if (payment.status !== 'Refunded') {
                                                return acc + payment.amount;
                                            }
                                            return acc;
                                        }, 0);

                                        let depositValue = 0;
                                        if (resData.depositType === 'percentage') {
                                            depositValue = ((resData.deposit / 100) * subtotal).toFixed(2);
                                        } else if (resData.depositType === 'amount') {
                                            depositValue = (resData.deposit).toFixed(2);
                                        }

                                        setTotalPay((data.total - (calculatedTotalAmount / 100)).toFixed(2));
                                        setTotalAmount((depositValue - (calculatedTotalAmount / 100)).toFixed(2));
                                    })
                                    .catch((er) =>
                                        console.log(
                                            er.response.data.message ? er.response.data.message : er.response.data
                                        )
                                    );
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );
            } catch (error) {
                console.log(error);
            }
        };

        if (jobLoading) {
            fetchData();
        }

    }, [fetchPaymentAll, fetchDispatch, jobLoading, setTotalPay]);

    useEffect(() => {
        if (loading) {

            const fetchData = async () => {
                try {

                    fetchEstimates(id)
                        .then((resData) => {

                            fetchDispatch(resData.jobId)
                                .then((data) => {
                                    setDispatch(data);

                                    fetchPaymentAll()
                                        .then((res) => {
                                            const filterPayments = res.filter((item) => item.estimateId === id);

                                            setPayment(filterPayments)

                                            const calculatedTotalAmount = filterPayments.reduce((acc, payment) => {
                                                if (payment.status !== 'Refunded') {
                                                    return acc + payment.amount;
                                                }
                                                return acc;
                                            }, 0);

                                            let depositValue = 0;
                                            if (resData.depositType === 'percentage') {
                                                depositValue = ((resData.deposit / 100) * subtotal).toFixed(2);
                                            } else if (resData.depositType === 'amount') {
                                                depositValue = (resData.deposit).toFixed(2);
                                            }

                                            setTotalPay((data.total - (calculatedTotalAmount / 100)).toFixed(2));
                                            setTotalAmount((depositValue - (calculatedTotalAmount / 100)).toFixed(2));
                                            setLoading(false);
                                        })
                                        .catch((er) =>
                                            console.log(
                                                er.response.data.message ? er.response.data.message : er.response.data
                                            )
                                        );
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );
                } catch (error) {
                    console.log(error);
                }
            };

            fetchData();
        }

    }, [fetchPaymentAll, fetchDispatch, setTotalPay, loading]);

    useEffect(() => {
        if (payLoading) {
            const fetchData = async () => {
                try {

                    fetchEstimates(id)
                        .then((resData) => {

                            fetchDispatch(resData.jobId)
                                .then((data) => {
                                    setDispatch(data);

                                    fetchPaymentAll()
                                        .then((res) => {
                                            const filterPayments = res.filter((item) => item.estimateId === id);

                                            setPayment(filterPayments)

                                            const calculatedTotalAmount = filterPayments.reduce((acc, payment) => {
                                                if (payment.status !== 'Refunded') {
                                                    return acc + payment.amount;
                                                }
                                                return acc;
                                            }, 0);

                                            let depositValue = 0;
                                            if (resData.depositType === 'percentage') {
                                                depositValue = ((resData.deposit / 100) * subtotal).toFixed(2);
                                            } else if (resData.depositType === 'amount') {
                                                depositValue = (resData.deposit).toFixed(2);
                                            }

                                            setTotalPay((data.total - (calculatedTotalAmount / 100)).toFixed(2));
                                            setTotalAmount((depositValue - (calculatedTotalAmount / 100)).toFixed(2));
                                            setPayLoading(false);
                                        })
                                        .catch((er) =>
                                            console.log(
                                                er.response.data.message ? er.response.data.message : er.response.data
                                            )
                                        );
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );
                } catch (error) {
                    console.log(error);
                    setPayLoading(false);

                }
            };

            fetchData();
        }
    }, [fetchPaymentAll, fetchDispatch, payLoading, setTotalPay]);

    function formatDateTime(dateTime) {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };

        return new Date(dateTime).toLocaleDateString(undefined, options);
    }

    return (
        <Elements stripe={stripePromise}>
            {!companies.stripeAccountId ? (
                <ConnectStripeButton companyId={companies._id} onSuccess={() => setCompanies({ ...companies, stripeAccountId: 'pending' })} />
            ) : (
                <>
                    {payment === null || payment.length === 0 ? (
                        <div className="row w-100 m-0 p-0">
                            {showPaymentForm && (
                                <PaymentForm estimateId={id} setLoading={setLoading} totalPaid={totalAmount} dispatching={dispatching} companies={companies} companyId={companies._id} onCancel={handlePaymentFormClose} />
                            )}

                            <div className="row mt-3 ps-5 pe-5 justify-content-end">
                                <div className="col-sm-4 text-center">
                                    <button className="btn btn-outline-primary btn-sm" type="button" onClick={handleAddPaymentClick}>
                                        Add Payment
                                    </button>
                                </div>
                            </div>
                            <div className="depositModuleNoPayment m-0 p-0 row justify-content-center">
                                <div className="col-sm-3 m-0 p-0 text-center">
                                    <FcMoneyTransfer className="depositImageNoPayment" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {showModal && (
                                <DeletePaymentPopModal
                                    onConfirm={handleConfirmRemovePayment}
                                    onCancel={handleCancelRemoveJob}
                                />
                            )}
                            {showRefundModal && (
                                <RefundPaymentPopModal
                                    onConfirm={handleRefund}
                                    onCancel={handleCancelRefund}
                                />
                            )}

                            <div className="row w-100 justify-content-center">
                                {showPaymentForm && (
                                    <PaymentForm setLoading={setLoading} estimateId={id} totalPaid={totalAmount} dispatching={dispatching} companies={companies} companyId={companies._id} onCancel={handlePaymentFormClose} />
                                )}
                                <div className="col-sm-12">
                                    <div className="row mt-2 m-0 p-0 justify-content-end">
                                        <div className="col-sm-4 text-end">
                                            <button className="btn btn-outline-primary btn-sm" type="button" onClick={handleAddPaymentClick}>
                                                Add Payment
                                            </button>
                                        </div>
                                    </div>
                                    <table className="table table-hover mt-5">
                                        <thead className="border-bottom border-dark">
                                            <tr>
                                                <th scope="col" style={{ width: "10%" }}>Payment</th>
                                                <th scope="col" style={{ width: "8%" }}>Amount</th>
                                                <th scope="col" style={{ width: "20%" }}>Date</th>
                                                <th scope="col" style={{ width: "10%" }}>Status</th>
                                                <th scope="col" style={{ width: "10%" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="border border-bottom border-right border-left border-dotted">
                                            {payment.map((payments, index) => (
                                                <tr key={index}>
                                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                                        <div>
                                                            {payments.type}
                                                        </div>
                                                    </td>
                                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                                        ${(payments.amount / 100).toFixed(2)}
                                                    </td>
                                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                                        {formatDateTime(payments.createdAt)}
                                                    </td>
                                                    <td className="border align-middle text-center border-dotted border-bottom border-right border-left">
                                                        <span
                                                            className={`payment-status ${payments.status === 'Success' ? 'bg-success-subtle p-2 rounded' : ''} ${payments.status === 'Refunded' ? 'bg-info-subtle p-2 rounded' : ''}`}
                                                        >
                                                            <span className="z-3 position-relative text-black">{payments.status}</span>
                                                        </span>
                                                    </td>
                                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                                        <button
                                                            className="btn btn-outline-warning btn-sm me-2"
                                                            onClick={() => handleRefundPayment(payments.paymentId, payments.amount)}
                                                        >
                                                            <RiRefund2Fill className="fs-5" />
                                                        </button>
                                                        <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={() => handleRemovePayment(payments._id)}
                                                        >
                                                            <BsTrash />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </Elements>
    );
};

export default EstimatePaymentTabComponent;
