import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../router/paths";

const AdminEditCodesComponent = ({
    fetchCodes,
    updateCodesApiRequest
}) => {
    const [validated, setValidated] = useState(false);
    const [cuts, setCuts] = useState(['']);
    const [vin, setVin] = useState('');
    const [codes, setCodes] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [createCodesResponseState, setCreateCodesResponseState] = useState({
        message: "",
        error: "",
    });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchCodes(id)
            .then((data) => {
                setCodes(data);
                setVin(data.vin);
                setCuts(data.cuts);
                setSelectedValue(data.transponder);
            })
            .catch((er) => console.log(er));
    }, [id, fetchCodes]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            make: form.make.value,
            model: form.model.value,
            year: form.year.value,
            keyCode: form.keyCode.value,
            pinCode: form.pinCode.value,
            cuts: cuts,
            hpcCard: form.hpcCard.value,
            testBlade: form.testBlade.value,
            transponderKey: form.transponderKey.value,
            transponder: selectedValue,
            chipType: form.chipType.value,
            codeSeries: form.codeSeries.value,
            doorCode: form.doorCode.value,
            system: form.system.value,
        };
        if (event.currentTarget.checkValidity() === true) {
            updateCodesApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "Code updated") navigate(`${paths.ADMINCODES}`);
                })
                .catch((er) => {
                    setCreateCodesResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const handleCutsChange = (event, index) => {
        const updatedCuts = [...cuts];
        updatedCuts[index] = event.target.value;
        setCuts(updatedCuts);
    };

    const handleDeleteCut = (index) => {
        const updatedCuts = [...cuts];
        updatedCuts.splice(index, 1);
        setCuts(updatedCuts);
    };

    const handleAddCut = () => {
        setCuts([...cuts, ""]);
    };

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    return (
        <div className="mt-5 pt-4">
                <div className="container mb-5 pb-5">
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-sm-5">
                            <h1 className="text-center">Edit Code</h1>
                            <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="row pt-4 g-3">
                                <div>
                                    <label htmlFor="vin" className="form-label">
                                        VIN:
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="vin"
                                        name="vin"
                                        value={vin}
                                        disabled
                                    />
                                </div>
                                <div className="row mt-3 m-0 p-0">
                                    <div className="col">
                                        <label htmlFor="make">Make:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="make"
                                            name="make"
                                            defaultValue={codes.make}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="model">Model:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="model"
                                            name="model"
                                            defaultValue={codes.model}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="year">Year:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="year"
                                            name="year"
                                            defaultValue={codes.year}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3 m-0 p-0">
                                    <div className="col">
                                        <label htmlFor="keyCode">Key Code:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="keyCode"
                                            name="keyCode"
                                            defaultValue={codes.keyCode}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="pinCode">Pin Code:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="pinCode"
                                            name="pinCode"
                                            defaultValue={codes.pinCode}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="doorCode">Door Code:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="doorCode"
                                            name="doorCode"
                                            defaultValue={codes.doorCode}
                                        />
                                    </div>
                                </div>
                                <div className="row m-0 p-0 mt-3">
                                    <div className="row m-0 p-0 d-flex justify-content-between mb-3">
                                        <div className="col align-middle text-start mt-2">
                                            <label>Cuts:</label>
                                        </div>
                                        <div className="col align-middle text-end">
                                            <button type="button" onClick={handleAddCut} className="btn btn-sm btn-outline-primary">Add Cuts</button>
                                        </div>
                                    </div>
                                    {cuts.map((cut, index) => (
                                        <>
                                            <div key={index} className="input-group mb-3">
                                                <input
                                                    value={cut}
                                                    onChange={(e) => handleCutsChange(e, index)}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cuts Needed"
                                                    aria-label="Cuts Needed"
                                                    aria-describedby="button-addon2" />
                                                <button
                                                    className="btn btn-outline-danger"
                                                    type="button"
                                                    id="button-addon2"
                                                    onClick={() => handleDeleteCut(index)}
                                                >
                                                    x
                                                </button>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                <div>
                                    <label className="form-label" htmlFor="trueFalseSelect">Transponder:</label>
                                    <select
                                        id="trueFalseSelect"
                                        className="form-select"
                                        value={selectedValue.toString()}
                                        onChange={handleSelectChange}
                                    >
                                        <option value="">Select An Option</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                        <option value="optional">Optional</option>
                                    </select>
                                </div>
                                <div className="row mt-3 m-0 p-0">
                                    <div className="col">
                                        <label htmlFor="hpcCard">HPC Card:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="hpcCard"
                                            name="hpcCard"
                                            defaultValue={codes.hpcCard}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="testBlade">Test Blade:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="testBlade"
                                            name="testBlade"
                                            defaultValue={codes.testBlade}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="transponderKey">Transponder Key:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="transponderKey"
                                            name="transponderKey"
                                            defaultValue={codes.transponderKey}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="system">System:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="system"
                                        name="system"
                                        defaultValue={codes.system}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="chipType">Chip Type:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="chipType"
                                        name="chipType"
                                        defaultValue={codes.chipType}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="codeSeries">Code Series:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="codeSeries"
                                        name="codeSeries"
                                        defaultValue={codes.codeSeries}
                                    />
                                </div>
                                <div className="text-center pt-4">
                                    <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                    <Link to={paths.ADMINCODES} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                                </div>
                                {createCodesResponseState.error ?? ""}
                            </form>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default AdminEditCodesComponent;