import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteTaskConfirmationModal from "../../../components/task/deleteTaskComponent";
import paths from "../../../router/paths";
import { CgProfile } from "react-icons/cg";
import { isPast, isToday, isThisWeek, addWeeks, startOfWeek } from 'date-fns';
import { FaRegThumbsUp, FaInfo } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import TaskChatComponent from '../../../components/task/taskChatComponent';
import LoadingComponent from '../../../components/loadingComponet';
import { IoMdCheckmark } from "react-icons/io";
import AcceptTaskConfirmationModal from '../../../components/task/acceptTaskComponent';
import TaskInfoPopupComponent from '../../../components/task/taskInfoPopupComponet';
import EditTaskPopupComponent from '../../../components/task/editTaskPopupComponent';
import { logout } from '../../../redux/actions/action';

const isNextWeek = (date) => {
    const now = new Date();
    const nextWeekStart = addWeeks(startOfWeek(now, { weekStartsOn: 1 }), 1);
    const nextWeekEnd = addWeeks(nextWeekStart, 1);

    return date >= nextWeekStart && date < nextWeekEnd;
};

const TaskComponent = ({ createTaskApiRequest, clockStatus, updateTaskApiRequest, fetchUsersData, fetchTechInfoAll, updateTaskTechApiRequest, updateTaskChatAllApiRequest, deleteTaskChat, fetchUploadsImage, deleteTaskChatLike, fetchTaskChatLike, fetchTaskChat, newTaskChat, fetchTesk, deleteTesk, fetchTechInfo, fetchUsers, updateDispatchTaskApiRequest, updateTaskStatusApiRequest, updateTaskDeadlineApiRequest, updateTaskChatIconApiRequest, createTaskIconApiRequest }) => {
    const [task, setTask] = useState([]);
    const auth = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationAccept, setShowConfirmationAccept] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [taskId, setTaskId] = useState("");
    const [selectedTaskId, setSelectedTaskId] = useState('');
    const [showAlert1, setShowAlert1] = useState(false);
    const [statusTaskId, setStatusTaskId] = useState('');
    const [showAlertStatus, setShowAlertStatus] = useState(false);
    const [showAlertDeadline, setShowAlertDeadline] = useState(false);
    const [selectedChatId, setSelectedChatId] = useState([]);
    const [showChat, setShowChat] = useState(false);
    const [techInfo, setTechInfo] = useState([]);
    const [showAlertEdit, setShowAlertEdit] = useState(false);
    const [showAlertEditTask, setShowAlertEditTask] = useState(false);
    const [requestEdit, setRequestEdit] = useState("");
    const [filteredTech, setFilteredTech] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isCollapsedUnclaimed, setIsCollapsedUnclaimed] = useState(true);
    const [isCollapsedPast, setIsCollapsedPast] = useState(true);
    const [isCollapsedWeek, setIsCollapsedWeek] = useState(true);
    const [isCollapsedNext, setIsCollapsedNext] = useState(true);
    const [isCollapsedLater, setIsCollapsedLater] = useState(true);

    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;

    const dispatch = useDispatch();

    const toggleDropdown = (taskId) => {
        setSelectedTaskId(taskId);
        const content = document.getElementById(`dropdown-content-${taskId}`);
        content.style.display = content.style.display === 'block' ? 'none' : 'block';
    };

    const handleClickOutside = (event, taskId) => {

        const content = document.getElementById(`dropdown-content-${taskId}`);
        if (content && !content.contains(event.target)) {
            content.style.display = 'none';
        }
    };

    useEffect(() => {
        const taskId = selectedTaskId;
        document.addEventListener('mousedown', (event) => handleClickOutside(event, taskId));
        return () => {
            document.removeEventListener('mousedown', (event) => handleClickOutside(event, taskId));
        };
    }, [selectedTaskId]);

    const toggleStatusDropdown = (statusId) => {
        setStatusTaskId(statusId);
        const content = document.getElementById(`dropdown-status-${statusId}`);
        content.style.display = content.style.display === 'block' ? 'none' : 'block';
    };

    const handleClickOutsideStatus = (event, statusId) => {

        const content = document.getElementById(`dropdown-status-${statusId}`);
        if (content && !content.contains(event.target)) {
            content.style.display = 'none';
        }
    };

    useEffect(() => {
        const statusId = statusTaskId;
        document.addEventListener('mousedown', (event) => handleClickOutsideStatus(event, statusId));
        return () => {
            document.removeEventListener('mousedown', (event) => toggleStatusDropdown(event, statusId));
        };
    }, [statusTaskId]);

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);

        fetchTechInfoAll()
            .then((data) => {
                const filter = data.find((itm) => itm.user === auth.user._id);
                const filteredTech = data.filter((tech) => tech.company === auth.user.company);

                setFilteredTech(filteredTech);
                setTechInfo(filter);
            })
        fetchUsersData()
            .then((data) => {
                const filteredUser = data.filter((user) => user.company === auth.user.company);

                setFilteredUsers(filteredUser);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        fetchTesk(abctrl)
            .then((res) => {
                return Promise.all(res.map(task => {
                    const techPromises = task.techs.map(techId => fetchTechInfo(techId, abctrl));
                    return Promise.all(techPromises).then(techDetails => {
                        if (techDetails) {
                            const includeTask = !task.userSpecific || techDetails.some(tech => tech.user === auth.user._id);
                            if (!includeTask) return null;

                            const userPromises = techDetails.map(tech => fetchUsers(tech.user, abctrl));
                            return Promise.all(userPromises).then(userDetails => {
                                const tasksToReplicate = [];
                                const existingDeadlines = new Set();

                                // Populate existing deadlines to prevent duplication
                                res.forEach(t => {
                                    if (t.deadline && new Date(t.deadline).toDateString() !== new Date().toDateString()) {
                                        existingDeadlines.add(new Date(t.deadline).toDateString());
                                    }
                                });

                                if (task.repeat) {
                                    const today = new Date();
                                    today.setHours(0, 0, 0, 0); // Set to start of today
                                    let increment = 1;
                                    let unit = 'day';

                                    switch (task.repeatsEvery.toLowerCase()) {
                                        case 'week':
                                            increment = 7;
                                            break;
                                        case 'month':
                                            increment = 1;
                                            unit = 'month';
                                            break;
                                        case 'year':
                                            increment = 1;
                                            unit = 'year';
                                            break;
                                        case 'day':
                                        default:
                                            increment = 1;
                                            break;
                                    }

                                    let currentDate = new Date(today); // Start replicating from today
                                    let endDate = new Date(currentDate);

                                    // Set the end date for replication based on unit
                                    if (unit === 'day') {
                                        endDate.setDate(endDate.getDate() + 30); // Extend 30 days
                                    } else if (unit === 'month') {
                                        endDate.setMonth(endDate.getMonth() + 12); // Extend 12 months
                                    } else if (unit === 'year') {
                                        endDate.setFullYear(endDate.getFullYear() + 5); // Extend 5 years
                                    }

                                    while (currentDate <= endDate) {
                                        const dateString = currentDate.toDateString();
                                        if (!existingDeadlines.has(dateString) || new Date(task.deadline).toDateString() === dateString) {
                                            tasksToReplicate.push({
                                                ...task,
                                                techs: userDetails,
                                                techId: task.techs,
                                                deadline: new Date(currentDate),
                                                _id: undefined // Remove _id for new task entries
                                            });
                                        }

                                        if (unit === 'day') {
                                            currentDate.setDate(currentDate.getDate() + increment);
                                        } else if (unit === 'month') {
                                            currentDate.setMonth(currentDate.getMonth() + increment);
                                        } else if (unit === 'year') {
                                            currentDate.setFullYear(currentDate.getFullYear() + increment);
                                        }
                                    }
                                } else {
                                    tasksToReplicate.push({
                                        ...task,
                                        techs: userDetails,
                                        techId: task.techs
                                    });
                                }
                                return tasksToReplicate;
                            });
                        }
                    });
                })).then(tasks => tasks.flat()).then(filteredTasks => filteredTasks.filter(task => task !== null));
            })
            .then((replicatedTasks) => {
                setTask(replicatedTasks);
                setIsLoading(false);
            })
            .catch((er) => {
                console.log(er)
                if ((er.response && er.response.status === 401) || (er.response.data.message === "A token is required for authentication")) {
                    dispatch(logout());
                } else {
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                }
            });

        return () => abctrl.abort();
    }, [fetchTesk, fetchUsersData, auth.user._id, auth.user.company, dispatch, fetchTechInfo, fetchUsers, fetchTechInfoAll]);

    useEffect(() => {
        const abctrl = new AbortController();

        if (isLoading) {
            fetchTechInfoAll()
                .then((data) => {
                    const filter = data.find((itm) => itm.user === auth.user._id);
                    const filteredTech = data.filter((tech) => tech.company === auth.user.company);

                    setFilteredTech(filteredTech);
                    setTechInfo(filter);
                });

            fetchUsersData()
                .then((data) => {
                    const filteredUser = data.filter((user) => user.company === auth.user.company);

                    setFilteredUsers(filteredUser);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );

            fetchTesk(abctrl)
                .then((res) => {
                    return Promise.all(res.map(task => {
                        const techPromises = task.techs.map(techId => fetchTechInfo(techId, abctrl));
                        return Promise.all(techPromises).then(techDetails => {
                            if (techDetails) {
                                const includeTask = !task.userSpecific || techDetails.some(tech => tech.user === auth.user._id);
                                if (!includeTask) return null;

                                const userPromises = techDetails.map(tech => fetchUsers(tech.user, abctrl));
                                return Promise.all(userPromises).then(userDetails => {
                                    const tasksToReplicate = [];
                                    const existingDeadlines = new Set();

                                    // Populate existing deadlines to prevent duplication
                                    res.forEach(t => {
                                        if (t.deadline && new Date(t.deadline).toDateString() !== new Date().toDateString()) {
                                            existingDeadlines.add(new Date(t.deadline).toDateString());
                                        }
                                    });

                                    if (task.repeat) {
                                        const today = new Date();
                                        today.setHours(0, 0, 0, 0); // Set to start of today
                                        let increment = 1;
                                        let unit = 'day';

                                        switch (task.repeatsEvery.toLowerCase()) {
                                            case 'week':
                                                increment = 7;
                                                break;
                                            case 'month':
                                                increment = 1;
                                                unit = 'month';
                                                break;
                                            case 'year':
                                                increment = 1;
                                                unit = 'year';
                                                break;
                                            case 'day':
                                            default:
                                                increment = 1;
                                                break;
                                        }

                                        let currentDate = new Date(today); // Start replicating from today
                                        let endDate = new Date(currentDate);

                                        // Set the end date for replication based on unit
                                        if (unit === 'day') {
                                            endDate.setDate(endDate.getDate() + 30); // Extend 30 days
                                        } else if (unit === 'month') {
                                            endDate.setMonth(endDate.getMonth() + 12); // Extend 12 months
                                        } else if (unit === 'year') {
                                            endDate.setFullYear(endDate.getFullYear() + 5); // Extend 5 years
                                        }

                                        while (currentDate <= endDate) {
                                            const dateString = currentDate.toDateString();
                                            if (!existingDeadlines.has(dateString) || new Date(task.deadline).toDateString() === dateString) {
                                                tasksToReplicate.push({
                                                    ...task,
                                                    techs: userDetails,
                                                    techId: task.techs,
                                                    deadline: new Date(currentDate),
                                                    _id: undefined // Remove _id for new task entries
                                                });
                                            }

                                            if (unit === 'day') {
                                                currentDate.setDate(currentDate.getDate() + increment);
                                            } else if (unit === 'month') {
                                                currentDate.setMonth(currentDate.getMonth() + increment);
                                            } else if (unit === 'year') {
                                                currentDate.setFullYear(currentDate.getFullYear() + increment);
                                            }
                                        }
                                    } else {
                                        tasksToReplicate.push({
                                            ...task,
                                            techs: userDetails,
                                            techId: task.techs
                                        });
                                    }
                                    return tasksToReplicate;
                                });
                            }
                        });
                    })).then(tasks => tasks.flat()).then(filteredTasks => filteredTasks.filter(task => task !== null));
                })
                .then((replicatedTasks) => {
                    setTask(replicatedTasks);
                    setIsLoading(false);
                })
                .catch((er) => {
                    console.log(er)
                    if ((er.response && er.response.status === 401) || (er.response.data.message === "A token is required for authentication")) {
                        dispatch(logout());
                    } else {
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    }
                });
        }

        return () => abctrl.abort();
    }, [fetchTesk, fetchUsersData, auth.user._id, auth.user.company, dispatch, fetchTechInfo, fetchUsers, fetchTechInfoAll, isLoading]);

    useEffect(() => {
        const abctrl = new AbortController();
        const interval = setInterval(() => {

            fetchTesk(abctrl)
                .then((res) => Promise.all(res.map(async (task) => {
                    // Check if the createdAt time is more than 5 minutes ago
                    const taskCreatedAt = new Date(task.createdAt);
                    const fiveMinutesAgo = new Date(Date.now() - 5 * 60000);

                    if (task.techs.length === 0 && taskCreatedAt < fiveMinutesAgo) {
                        try {
                            const data = await fetchUsersData();
                            if (!data) throw new Error('No data received from fetchUsersData');

                            const dispatchers = data.filter((user) => user.company === auth.user.company);
                            const dispatchersFilter = dispatchers.filter((user) => user.usertype?.includes('Dispatcher') || user.usertype?.includes('Office'));

                            const clockStatusPromises = dispatchersFilter.map(dispatcher => clockStatus(dispatcher._id));
                            const clockStatuses = await Promise.all(clockStatusPromises);

                            const today = new Date();
                            today.setHours(0, 0, 0, 0);

                            const clockedInDispatchers = dispatchersFilter.filter((dispatcher, index) => {
                                const dispatcherClockStatus = clockStatuses[index];
                                const clockInTime = dispatcherClockStatus && dispatcherClockStatus.length > 0 ? new Date(dispatcherClockStatus[0].clockIn.time) : null;
                                return clockInTime && clockInTime >= today;
                            });

                            if (clockedInDispatchers.length > 0) {
                                const response = await fetch(`/api/roundRobin/${auth.user.company}`);
                                const { index: roundRobinIndex } = await response.json();

                                const assignedDispatcher = clockedInDispatchers[roundRobinIndex % clockedInDispatchers.length];

                                const filteredDisTech = filteredTech.find((tech) => tech.user === assignedDispatcher._id);

                                const newIndex = (roundRobinIndex + 1) % clockedInDispatchers.length;
                                await fetch(`/api/roundRobin/${auth.user.company}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({ index: newIndex }),
                                });

                                const formInputsTech = {
                                    techs: filteredDisTech._id,
                                    status: 'Not Started',
                                };

                                const data = await updateTaskTechApiRequest(task._id, formInputsTech);
                                if (data.message === "task updated") {
                                    setShowAlert1(true);
                                    setTimeout(() => {
                                        setShowAlert1(false);
                                    }, 3000);
                                    setIsLoading(true);
                                }
                            } else {
                                console.log("No clocked-in dispatchers available to assign tasks.");
                            }
                        } catch (er) {
                            console.log(er)
                            if ((er.response && er.response.status === 401) || (er.response.data.message === "A token is required for authentication")) {
                                dispatch(logout());
                            } else if (er.response && er.response.data) {
                                console.log(er.response.data.message ? er.response.data.message : er.response.data);
                            } else {
                                console.log('Error:', er.message);
                            }
                        }
                    }

                    const techPromises = task.techs.map(techId => fetchTechInfo(techId, abctrl));
                    return Promise.all(techPromises).then(techDetails => {
                        if (techDetails) {
                            const includeTask = !task.userSpecific || techDetails.some(tech => tech.user === auth.user._id);
                            if (!includeTask) return null;

                            const userPromises = techDetails.map(tech => fetchUsers(tech.user, abctrl));
                            return Promise.all(userPromises).then(userDetails => {
                                const tasksToReplicate = [];
                                const existingDeadlines = new Set();

                                // Populate existing deadlines to prevent duplication
                                res.forEach(t => {
                                    if (t.deadline && new Date(t.deadline).toDateString() !== new Date().toDateString()) {
                                        existingDeadlines.add(new Date(t.deadline).toDateString());
                                    }
                                });

                                if (task.repeat) {
                                    const today = new Date();
                                    today.setHours(0, 0, 0, 0); // Set to start of today
                                    let increment = 1;
                                    let unit = 'day';

                                    switch (task.repeatsEvery.toLowerCase()) {
                                        case 'week':
                                            increment = 7;
                                            break;
                                        case 'month':
                                            increment = 1;
                                            unit = 'month';
                                            break;
                                        case 'year':
                                            increment = 1;
                                            unit = 'year';
                                            break;
                                        case 'day':
                                        default:
                                            increment = 1;
                                            break;
                                    }

                                    let currentDate = new Date(today); // Start replicating from today
                                    let endDate = new Date(currentDate);

                                    // Set the end date for replication based on unit
                                    if (unit === 'day') {
                                        endDate.setDate(endDate.getDate() + 30); // Extend 30 days
                                    } else if (unit === 'month') {
                                        endDate.setMonth(endDate.getMonth() + 12); // Extend 12 months
                                    } else if (unit === 'year') {
                                        endDate.setFullYear(endDate.getFullYear() + 5); // Extend 5 years
                                    }

                                    while (currentDate <= endDate) {
                                        const dateString = currentDate.toDateString();
                                        if (!existingDeadlines.has(dateString) || new Date(task.deadline).toDateString() === dateString) {
                                            tasksToReplicate.push({
                                                ...task,
                                                techs: userDetails,
                                                techId: task.techs,
                                                deadline: new Date(currentDate),
                                                _id: undefined // Remove _id for new task entries
                                            });
                                        }

                                        if (unit === 'day') {
                                            currentDate.setDate(currentDate.getDate() + increment);
                                        } else if (unit === 'month') {
                                            currentDate.setMonth(currentDate.getMonth() + increment);
                                        } else if (unit === 'year') {
                                            currentDate.setFullYear(currentDate.getFullYear() + increment);
                                        }
                                    }
                                } else {
                                    tasksToReplicate.push({
                                        ...task,
                                        techs: userDetails,
                                        techId: task.techs
                                    });
                                }
                                return tasksToReplicate;
                            });
                        }
                    });
                })))
                .then(tasks => tasks.flat())
                .then(filteredTasks => filteredTasks.filter(task => task !== null))
                .then((updatedTasks) => {
                    setTask(updatedTasks);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error)
                    if ((error.response && error.response.status === 401) || (error.response.data && error.response.data.message === "A token is required for authentication")) {
                        dispatch(logout());
                    } else {
                        console.log(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response.data);
                    }
                });
        }, 30000);  // 30 seconds interval

        return () => {
            abctrl.abort();
            clearInterval(interval);
        };
    }, [fetchTesk, auth.user._id, auth.user.company, clockStatus, dispatch, filteredTech, updateTaskTechApiRequest, fetchUsersData, fetchTechInfo, fetchUsers, isLoading]);

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
    };

    const handleConfirmRemoveTask = async () => {

        const data = await deleteTesk(taskId);

        if (data === 'task deleted successfully') {
            setIsLoading(true)
        }

        setShowConfirmation(false);
    };

    const handleCancelRemoveTask = () => {
        setShowConfirmation(false);
    };

    const handleCancelTask = () => {
        setShowChat(false);
    };

    const handleChatClick = (task) => {
        setSelectedChatId(task);
        setShowChat(true);
    };

    const handleRemoveTask = (id) => {

        setTaskId(id);
        setShowConfirmation(true);
    };

    const handleAcceptTask = (id) => {

        setTaskId(id);
        setShowConfirmationAccept(true);
    };

    const handleCancelAcceptTask = () => {
        setShowConfirmationAccept(false);
    };

    const handleConfirmAcceptTask = async () => {

        const formInputsTech = {
            repeat: false,
            techs: techInfo._id,
            status: 'Not Started',
        };

        updateTaskTechApiRequest(taskId, formInputsTech)
            .then(async (data) => {
                if (data.message === "task updated") {
                    setShowConfirmationAccept(false);

                    setShowAlert1(true);
                    setTimeout(() => {
                        setShowAlert1(false);
                    }, 3000);

                    setIsLoading(true);
                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const categorizeTasks = (tasks) => {
        const categorizedTasks = {
            pastDates: [],
            today: [],
            thisWeek: [],
            nextWeek: [],
            later: [],
            noTech: [],
        };

        tasks.forEach(task => {
            const deadlineDate = new Date(task.deadline);
            const noTech = (task.techs.length === 0);

            if (noTech) {
                categorizedTasks.noTech.push(task);
            } else {
                if (isPast(deadlineDate) && !isToday(deadlineDate)) {
                    if (task.status === 'Done') {
                        categorizedTasks.pastDates.push(task);
                    } else {
                        categorizedTasks.today.push({ ...task, borderColor: true }); // Task not done but past date, show in today's tab with red border
                    }
                } else if (isToday(deadlineDate)) {
                    categorizedTasks.today.push(task);
                } else if (isThisWeek(deadlineDate, { weekStartsOn: 1 })) {
                    categorizedTasks.thisWeek.push(task);
                } else if (isNextWeek(deadlineDate)) {
                    categorizedTasks.nextWeek.push(task);
                } else {
                    categorizedTasks.later.push(task);
                }
            }
        });

        return categorizedTasks;
    };


    const categorizedTasks = categorizeTasks(task);

    const handlePriorityChange = (task) => {

        const formInputsPriority = {
            repeat: task.repeat || false,
            priority: task.priority,
        };

        updateDispatchTaskApiRequest(task._id, formInputsPriority)
            .then(async (data) => {
                if (data.message === "task updated") {

                    setShowAlert1(true);
                    setTimeout(() => {
                        setShowAlert1(false);
                    }, 3000);

                    setIsLoading(true);
                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const handleStatusChange = (task) => {

        if (task._id === undefined) {
            const formInputs = {
                title: task.title,
                repeat: task.repeat || false,
                description: task.description,
                priority: task.priority,
                status: task.status,
                deadline: task.deadline,
                completed: task.completed,
                techs: task.techId,
                company: task.company,
                user: task.user,
                userSpecific: task.userSpecific,
                contactForm: false,
            };
            createTaskApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "task created") {
                        const content = document.getElementById(`dropdown-status-${statusTaskId}`);
                        content.style.display = content.style.display === 'block' ? 'none' : 'block';

                        setShowAlertStatus(true);
                        setTimeout(() => {
                            setShowAlertStatus(false);
                        }, 3000);

                        setIsLoading(true);
                    }
                })
                .catch((er) => {
                    if ((er.response && er.response.status === 401) || (er.response.data.message === "A token is required for authentication")) {
                        dispatch(logout());
                    } else {
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    }
                });
        } else {
            const formInputsStatus = {
                repeat: task.repeat || false,
                status: task.status,
            };

            updateTaskStatusApiRequest(task._id, formInputsStatus)
                .then(async (data) => {
                    if (data.message === "task updated") {
                        const content = document.getElementById(`dropdown-status-${statusTaskId}`);
                        content.style.display = content.style.display === 'block' ? 'none' : 'block';

                        setShowAlertStatus(true);
                        setTimeout(() => {
                            setShowAlertStatus(false);
                        }, 3000);

                        setIsLoading(true);
                    }
                })
                .catch((er) => {
                    if ((er.response && er.response.status === 401) || (er.response.data.message === "A token is required for authentication")) {
                        dispatch(logout());
                    } else {
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    }
                });
        }
    };

    const handleDeadlineChange = (task) => {

        const formInputsDeadline = {
            repeat: task.repeat || false,
            deadline: task.deadline,
        };

        updateTaskDeadlineApiRequest(task._id, formInputsDeadline)
            .then(async (data) => {
                if (data.message === "task updated") {

                    setShowAlertDeadline(true);
                    setTimeout(() => {
                        setShowAlertDeadline(false);
                    }, 3000);

                    setIsLoading(true);
                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const renderTasksForCategory = (tasks) => {
        return tasks.map((task, idx) => (
            <tr className={`border ${task.borderColor ? 'border-danger border-2' : ''}`} key={idx} style={isToday(new Date(task.deadline)) && task.status !== 'Done' ? { border: '2px solid red' } : {}}>
                <td className="border align-middle text-start ps-2" style={{ maxWidth: '25rem', minWidth: '20rem', overflowX: 'auto' }}>
                    <div className="w-100" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {task.title}
                    </div>
                </td>
                <td className="border text-center align-middle">
                    {task.techs.length === 0 || task._id === undefined ? (
                        <span disabled><IoChatbubbleEllipsesOutline style={{ width: '2rem', height: '2rem' }} /></span>
                    ) : (
                        <span onClick={() => handleChatClick(task)}><IoChatbubbleEllipsesOutline style={{ width: '2rem', height: '2rem' }} /></span>
                    )}
                </td>
                <td className="border text-center align-middle">
                    {task.techs.map((itm) => (
                        <span
                            key={itm._id}
                            className="tech-logo"
                            title={`${itm.name} ${itm.lastName}`}
                        >
                            <CgProfile style={{ width: '2rem', height: '2rem' }} />
                        </span>
                    ))}
                </td>
                <td className="border text-center align-middle m-0 p-0" style={{ width: '9rem' }}>
                    <div
                        className="custom-dropdown"
                        style={{
                            background: task.status === 'Stuck' ? '#dc3545' :
                                task.status === 'Working On It' ? '#ffc107' :
                                    task.status === 'Done' ? '#198754' :
                                        '#6c757d'
                        }}
                    >
                        {task.techs.length === 0 ? (
                            <button
                                className="dropdown-selected"
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    borderRadius: '0px',
                                    color: '#fff',
                                    background: task.status === 'Stuck' ? '#dc3545' :
                                        task.status === 'Working On It' ? '#ffc107' :
                                            task.status === 'Done' ? '#198754' :
                                                '#6c757d'
                                }}
                                disabled
                            >
                                {task.status || 'Select Status'}
                            </button>
                        ) : (
                            <button
                                className="dropdown-selected"
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    borderRadius: '0px',
                                    color: '#fff',
                                    background: task.status === 'Stuck' ? '#dc3545' :
                                        task.status === 'Working On It' ? '#ffc107' :
                                            task.status === 'Done' ? '#198754' :
                                                '#6c757d'
                                }}
                                onClick={() => toggleStatusDropdown(task._id)}
                            >
                                {task.status || 'Select Status'}
                            </button>
                        )}
                        <div
                            id={`dropdown-status-${task._id}`}
                            className="dropdown-options"
                            style={{ display: 'none' }}
                        >
                            <button onClick={(e) => handleStatusChange({ ...task, status: 'Done' })} className="dropdown-option mt-4 bg-success">Done</button><br />
                            <button onClick={(e) => handleStatusChange({ ...task, status: 'Working On It' })} className="dropdown-option bg-warning">Working On It</button><br />
                            <button onClick={(e) => handleStatusChange({ ...task, status: 'Stuck' })} className="dropdown-option bg-danger">Stuck</button><br />
                            <button onClick={(e) => handleStatusChange({ ...task, status: 'Not Started' })} className="dropdown-option mb-4 bg-secondary">Not Started</button><br />
                        </div>
                    </div>
                </td>
                <td className="border text-center align-middle m-0 p-0" style={{ width: '9rem' }}>
                    {task.techs.length === 0 || task._id === undefined ? (
                        <DatePicker
                            id="startDate"
                            style={{
                                width: '100%',
                                border: 'none',
                                borderRadius: '0px',
                            }}
                            className="date-picker-input-Task-all"
                            selected={new Date(task.deadline)}
                            disabled
                            dateFormat="MMMM d yyyy"
                            showTimeInput
                        />
                    ) : (
                        <DatePicker
                            id="startDate"
                            style={{
                                width: '100%',
                                border: 'none',
                                borderRadius: '0px',
                            }}
                            className="date-picker-input-Task-all"
                            selected={new Date(task.deadline)}
                            onChange={(date) => handleDeadlineChange({ ...task, deadline: date.toISOString() })}
                            dateFormat="MMMM d yyyy"
                            showTimeInput
                        />
                    )}
                </td>
                <td className="border text-center align-middle m-0 p-0">
                    <div
                        className="custom-dropdown"
                        style={{
                            background: task.priority === 'High' ? '#dc3545' :
                                task.priority === 'Medium' ? '#ffc107' :
                                    task.priority === 'Low' ? '#17a2b8' :
                                        '#6c757d'
                        }}
                    >
                        {task.techs.length === 0 || task._id === undefined ? (
                            <button
                                className="dropdown-selected"
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    borderRadius: '0px',
                                    color: '#fff',
                                    background: task.priority === 'High' ? '#dc3545' :
                                        task.priority === 'Medium' ? '#ffc107' :
                                            task.priority === 'Low' ? '#17a2b8' :
                                                '#6c757d'
                                }}
                                disabled
                            >
                                {task.priority || 'Select Priority'}
                            </button>
                        ) : (
                            <button
                                className="dropdown-selected"
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    borderRadius: '0px',
                                    color: '#fff',
                                    background: task.priority === 'High' ? '#dc3545' :
                                        task.priority === 'Medium' ? '#ffc107' :
                                            task.priority === 'Low' ? '#17a2b8' :
                                                '#6c757d'
                                }}
                                onClick={() => toggleDropdown(task._id)}
                            >
                                {task.priority || 'Select Priority'}
                            </button>
                        )}
                        <div
                            id={`dropdown-content-${task._id}`}
                            className="dropdown-options"
                            style={{ display: 'none' }}
                        >
                            <button onClick={(e) => handlePriorityChange({ ...task, priority: 'High' })} className="dropdown-option mt-4 bg-danger">High</button><br />
                            <button onClick={(e) => handlePriorityChange({ ...task, priority: 'Medium' })} className="dropdown-option bg-warning">Medium</button><br />
                            <button onClick={(e) => handlePriorityChange({ ...task, priority: 'Low' })} className="dropdown-option bg-info">Low</button><br />
                            <button onClick={(e) => handlePriorityChange({ ...task, priority: 'No Priority' })} className="dropdown-option mb-4 bg-secondary">No Priority</button><br />
                        </div>
                    </div>
                </td>
                <td className="border text-center">
                    <button
                        className="btn btn-outline-info btn-sm"
                        onClick={() => EditHandler(task)}
                    >
                        <FaInfo />
                    </button>
                    {" / "}

                    {task.techs.length !== 0 ? (
                        <>
                            <button
                                disabled={task.repeat && new Date(task.deadline).toDateString() !== new Date().toDateString()}
                                className="btn btn-outline-success btn-sm"
                                onClick={() => EditTaskHandler(task)}
                            >
                                <GoPencil />
                            </button>
                            {" / "}
                            {isAdmin || isSuperAdmin ? (
                                <button
                                    className="btn btn-outline-danger btn-sm"
                                    onClick={() => handleRemoveTask(task._id)}
                                >
                                    <BsTrash />
                                </button>
                            ) : (
                                <button
                                    className="btn btn-outline-danger btn-sm"
                                    disabled
                                >
                                    <BsTrash />
                                </button>
                            )}
                        </>
                    ) : (

                        <button
                            className="btn btn-outline-success btn-sm"
                            onClick={() => handleAcceptTask(task._id)}
                        >
                            <IoMdCheckmark />
                        </button>
                    )}
                </td>
            </tr>
        ));
    };

    const handleCloseAlert = () => {
        setShowAlert1(false);
    };

    const handleCloseAlertStatus = () => {
        setShowAlertStatus(false);
    };

    const handleCloseAlertDeadline = () => {
        setShowAlertDeadline(false);
    };

    const EditHandler = async (item) => {
        setRequestEdit(item);
        setShowAlertEdit(true);
    };

    const handleCancelEdit = () => {
        setShowAlertEdit(false);
    };

    const EditTaskHandler = async (item) => {
        setRequestEdit(item);
        setShowAlertEditTask(true);
    };

    const handleCancelEditTask = () => {
        setShowAlertEditTask(false);
    };

    const handleConfirmEditTask = () => {
        setShowAlertEditTask(false);
        setIsLoading(true);
    };

    return (
        <div className="container-fluid mt-5">
            {showConfirmation && (
                <DeleteTaskConfirmationModal
                    onConfirm={handleConfirmRemoveTask}
                    onCancel={handleCancelRemoveTask}
                />
            )}
            {showConfirmationAccept && (
                <AcceptTaskConfirmationModal
                    onConfirm={handleConfirmAcceptTask}
                    onCancel={handleCancelAcceptTask}
                />
            )}
            {showAlertEditTask && (
                <EditTaskPopupComponent
                    filteredUsers={filteredUsers}
                    filteredTech={filteredTech}
                    onCancel={handleCancelEditTask}
                    requestEdit={requestEdit}
                    updateTaskApiRequest={updateTaskApiRequest}
                    onConfirm={handleConfirmEditTask}
                />
            )}
            {showAlertEdit && (
                <TaskInfoPopupComponent
                    onCancel={handleCancelEdit}
                    requestEdit={requestEdit}
                />
            )}
            {showChat && (
                <TaskChatComponent
                    updateTaskChatAllApiRequest={updateTaskChatAllApiRequest}
                    deleteTaskChat={deleteTaskChat}
                    fetchUploadsImage={fetchUploadsImage}
                    deleteTaskChatLike={deleteTaskChatLike}
                    fetchTaskChatLike={fetchTaskChatLike}
                    createTaskIconApiRequest={createTaskIconApiRequest}
                    updateTaskChatIconApiRequest={updateTaskChatIconApiRequest}
                    newTaskChat={newTaskChat}
                    fetchTaskChat={fetchTaskChat}
                    taskId={selectedChatId}
                    onCancel={handleCancelTask}
                />
            )}
            {showAlert1 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Priority Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertStatus && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Status Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertStatus}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertDeadline && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Deadline Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertDeadline}>
                            X
                        </span>
                    </div>
                </div>
            )}
            <div className="row d-flex justify-content-center m-5 mt-5 pt-5">
                {isLoading && (
                    <div className="loading-overlay">
                        <LoadingComponent />
                    </div>
                )}
                <div className="col-sm-12 mt-5">
                    <div className="row mb-3 m-0 p-0">
                        <div className="col-sm-9 m-0 p-0">
                            <h1>My Tasks</h1>
                        </div>
                        <div className="col-sm-2 m-0 p-0 text-end">
                            <input
                                type="text"
                                className="mt-2 input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Tasks.."
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1 text-center">
                            <Link to={paths.NEWTASK} className="btn btn-sm btn-outline-primary mt-2 ps-3 pe-3 loginButton">New</Link>
                        </div>
                    </div>
                    <div>
                        <div className="accordion" id="accordionUnclaimed">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingUnclaimed">
                                    <button
                                        className={`accordion-button collapsed ${categorizedTasks.noTech.length === 0 && 'gray-color'}`}
                                        type="button"
                                        onClick={() => setIsCollapsedUnclaimed(!isCollapsedUnclaimed)}
                                        aria-expanded={isCollapsedUnclaimed ? "false" : "true"}
                                        aria-controls="collapseUnclaim"
                                    >
                                        <span className="fw-bold me-2 fs-5 text-dark">Unclaimed</span>
                                        <span className="text-dark"> / {categorizedTasks.noTech.length} item</span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseUnclaim"
                                    className={`accordion-collapse collapse ${isCollapsedUnclaimed ? '' : 'show'}`}
                                    aria-labelledby="headingUnclaim"
                                    data-bs-parent="#accordionUnclaim"
                                >
                                    <div className="accordion-body">
                                        {categorizedTasks.noTech.length === 0 ? (
                                            <div className="">
                                                <span>No Tasks to be claimed.</span>
                                            </div>
                                        ) : (
                                            <table className="table-hover order-table table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center"></th>
                                                        <th className="text-center">Conversation</th>
                                                        <th className="text-center">People</th>
                                                        <th className="text-center">Status</th>
                                                        <th className="text-center">Date</th>
                                                        <th className="text-center">Priority</th>
                                                        <th className="text-center">Info/Accept</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderTasksForCategory(categorizedTasks.noTech)}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion mt-4" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        disabled={categorizedTasks.pastDates.length === 0}
                                        className={`accordion-button collapsed ${categorizedTasks.pastDates.length === 0 && 'gray-color'}`}
                                        type="button"
                                        onClick={() => setIsCollapsedPast(!isCollapsedPast)}
                                        aria-expanded={isCollapsedPast ? "false" : "true"}
                                        aria-controls="collapsePast"
                                    >
                                        <span className="fw-bold me-2 fs-5 text-dark">Past Dates</span>
                                        <span className="text-dark"> / {categorizedTasks.pastDates.length} item</span>
                                    </button>
                                </h2>
                                <div
                                    id="collapsePast"
                                    className={`accordion-collapse collapse ${isCollapsedPast ? '' : 'show'}`}
                                    aria-labelledby="headingPast"
                                    data-bs-parent="#accordionPast"
                                >
                                    <div className="accordion-body">
                                        <table className="table-hover order-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-center"></th>
                                                    <th className="text-center">Conversation</th>
                                                    <th className="text-center">People</th>
                                                    <th className="text-center">Status</th>
                                                    <th className="text-center">Date</th>
                                                    <th className="text-center">Priority</th>
                                                    <th className="text-center">Info/Edit/Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderTasksForCategory(categorizedTasks.pastDates)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion" id="accordionToday">
                            <div className="accordion-item mt-4">
                                <h2 className={`accordion-header`} id="headingToday">
                                    <button
                                        disabled={categorizedTasks.today.length === 0}
                                        className={`accordion-button ${categorizedTasks.today.length === 0 && 'gray-color'}`}
                                        type="button"
                                        onClick={() => setIsCollapsed(!isCollapsed)}
                                        aria-expanded={isCollapsed ? "false" : "true"}
                                        aria-controls="collapseToday"
                                    >
                                        <span className={`fw-bold me-2 fs-5 text-dark`}>Today</span>
                                        <span className="text-dark"> / {categorizedTasks.today.length} item</span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseToday"
                                    className={`accordion-collapse collapse ${isCollapsed ? '' : 'show'}`}
                                    aria-labelledby="headingToday"
                                    data-bs-parent="#accordionToday"
                                >
                                    <div className="accordion-body">
                                        <table className="table-hover order-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-start"></th>
                                                    <th className="text-start">Conversation</th>
                                                    <th className="text-start">People</th>
                                                    <th className="text-start">Status</th>
                                                    <th className="text-start">Date</th>
                                                    <th className="text-start">Priority</th>
                                                    <th className="text-center">Edit/Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>{renderTasksForCategory(categorizedTasks.today)}</tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion" id="accordionThisWeek">
                            <div className="accordion-item mt-4">
                                <h2 className="accordion-header" id="headingThisWeek">
                                    <button
                                        disabled={categorizedTasks.thisWeek.length === 0}
                                        className={`accordion-button collapsed ${categorizedTasks.thisWeek.length === 0 && 'gray-color'}`}
                                        type="button"
                                        onClick={() => setIsCollapsedNext(!isCollapsedNext)}
                                        aria-expanded={isCollapsedNext ? "false" : "true"}
                                        aria-controls="collapseNext"
                                    >
                                        <span className="fw-bold me-2 fs-5 text-dark">This Week</span>
                                        <span className="text-dark"> / {categorizedTasks.thisWeek.length} item</span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseNext"
                                    className={`accordion-collapse collapse ${isCollapsedNext ? '' : 'show'}`}
                                    aria-labelledby="headingNext"
                                    data-bs-parent="#accordionNext"
                                >
                                    <div className="accordion-body">
                                        <table className="table-hover order-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-start"></th>
                                                    <th className="text-start">Conversation</th>
                                                    <th className="text-start">People</th>
                                                    <th className="text-start">Status</th>
                                                    <th className="text-start">Date</th>
                                                    <th className="text-start">Priority</th>
                                                    <th className="text-center">Edit/Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderTasksForCategory(categorizedTasks.thisWeek)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion" id="accordionNextWeek">
                            <div className="accordion-item mt-4">
                                <h2 className="accordion-header" id="headingNextWeek">
                                    <button
                                        disabled={categorizedTasks.nextWeek.length === 0}
                                        className={`accordion-button collapsed ${categorizedTasks.nextWeek.length === 0 && 'gray-color'}`}
                                        type="button"
                                        onClick={() => setIsCollapsedWeek(!isCollapsedWeek)}
                                        aria-expanded={isCollapsedWeek ? "false" : "true"}
                                        aria-controls="collapseWeek"
                                    >
                                        <span className="fw-bold me-2 fs-5 text-dark">Next Week</span>
                                        <span className="text-dark"> / {categorizedTasks.nextWeek.length} item</span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseWeek"
                                    className={`accordion-collapse collapse ${isCollapsedWeek ? '' : 'show'}`}
                                    aria-labelledby="headingWeek"
                                    data-bs-parent="#accordionWeek"
                                >
                                    <div className="accordion-body">
                                        <table className="table-hover order-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-start"></th>
                                                    <th className="text-start">Conversation</th>
                                                    <th className="text-start">People</th>
                                                    <th className="text-start">Status</th>
                                                    <th className="text-start">Date</th>
                                                    <th className="text-start">Priority</th>
                                                    <th className="text-center">Edit/Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderTasksForCategory(categorizedTasks.nextWeek)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion" id="accordionLater">
                            <div className="accordion-item mt-4">
                                <h2 className="accordion-header" id="headingLater">
                                    <button
                                        disabled={categorizedTasks.later.length === 0}
                                        className={`accordion-button collapsed ${categorizedTasks.later.length === 0 && 'gray-color'}`}
                                        type="button"
                                        onClick={() => setIsCollapsedLater(!isCollapsedLater)}
                                        aria-expanded={isCollapsedLater ? "false" : "true"}
                                        aria-controls="collapseLater"
                                    >
                                        <span className="fw-bold me-2 fs-5 text-dark">Later</span>
                                        <span className="text-dark"> / {categorizedTasks.later.length} item</span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseLater"
                                    className={`accordion-collapse collapse ${isCollapsedLater ? '' : 'show'}`}
                                    aria-labelledby="headingLater"
                                    data-bs-parent="#accordionLater"
                                >
                                    <div className="accordion-body">
                                        <table className="table-hover order-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-start"></th>
                                                    <th className="text-start">Conversation</th>
                                                    <th className="text-start">People</th>
                                                    <th className="text-start">Status</th>
                                                    <th className="text-start">Date</th>
                                                    <th className="text-start">Priority</th>
                                                    <th className="text-center">Edit/Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderTasksForCategory(categorizedTasks.later)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TaskComponent;