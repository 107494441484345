import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";

const CreateCompanyPageComponent = ({
    createCompanyApiRequest,
}) => {
    const [validated, setValidated] = useState(false);
    const [createCompanyResponseState, setCreateCompanyResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            contactName: form.contactName.value,
            address: form.address.value,
            phone: form.phone.value,
            city: form.city.value,
            state: form.state.value,
            zip: form.zip.value,
            country: form.country.value,
            email: form.email.value,
        };
        if (event.currentTarget.checkValidity() === true) {
            createCompanyApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Company created") navigate(`${paths.ADMINCOMPANY}`);
                })
                .catch((er) => {
                    setCreateCompanyResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    return (
        <div className="mt-5 pt-4">
                <div className="container mb-5 pb-5">
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-sm-5">
                            <h1 className="text-center">New Company</h1>
                            <form noValidate validated={validated} onSubmit={handleSubmit} className="row pt-4 g-3">
                                <div>
                                    <label htmlFor="name">Company Name:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="name"
                                        name="name" />
                                </div>
                                <div>
                                    <label htmlFor="phone">Phone:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="phone"
                                        name="phone" />
                                </div>
                                <div>
                                    <label htmlFor="email">Email:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="email"
                                        name="email" />
                                </div>
                                <div>
                                    <label htmlFor="contactName">Contact Name:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="contactName"
                                        name="contactName" />
                                </div>
                                <div>
                                    <label htmlFor="address">Company Address:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="address"
                                        name="address" />
                                </div>
                                <div>
                                    <label htmlFor="city">City:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="city"
                                        name="city" />
                                </div>
                                <div>
                                    <label htmlFor="state">State:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="state"
                                        name="state" />
                                </div>
                                <div>
                                    <label htmlFor="zip">Zip Code:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="zip"
                                        name="zip" />
                                </div>
                                <div>
                                    <label htmlFor="country">Country:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="country"
                                        name="country" />
                                </div>
                                <div className="text-center pt-4">
                                    <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                    <Link to={paths.ADMINCOMPANY} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                                </div>
                                {createCompanyResponseState.error ?? ""}
                            </form>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default CreateCompanyPageComponent;