import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../assets/fileUploadCss.css';
import { FaRegThumbsUp } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { AiOutlineFileText, AiOutlineCloudUpload, AiOutlinePlus } from 'react-icons/ai';
import ImagePopupComponent from "./imagePopupComponent";
import EmailPopupFileComponent from "./emailPopUpFileComponent";
import DeleteConfirmUploadComponent from "./deleteconfirm/deleteConfirmUploadComponent";

const FileUploadComponent = ({ fetchUploads, id, setRowCount }) => {
    const [newFiles, setNewFiles] = useState([]);
    const [uploads, setUploads] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertDelete, setShowAlertDelete] = useState(false);
    const [showAlertDeleteSucc, setShowAlertDeleteSucc] = useState(false);
    const auth = useSelector(state => state.auth)
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageName, setSelectedImageName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploadId, setUploadId] = useState("");
    const [showAlertFail, setShowAlertFail] = useState(false);
    const [showAlertFailMessage, setShowAlertFailMessage] = useState('');

    const fetchFilteredUploads = (abctrl) => {
        return fetchUploads(abctrl)
            .then(res => res.filter(item => item.job === id && !item.estimate && !item.invoice))
            .catch(er => {
                console.log(er.response?.data?.message || er.response?.data || er);
                throw er;
            });
    };

    useEffect(() => {
        const abctrl = new AbortController();
        fetchFilteredUploads(abctrl)
            .then(filteredUploads => setUploads(filteredUploads))
            .finally(() => setLoading(false));
        return () => abctrl.abort();
    }, [fetchUploads, id]);

    useEffect(() => {
        if (loading) {
            const abctrl = new AbortController();
            fetchFilteredUploads(abctrl)
                .then(filteredUploads => setUploads(filteredUploads))
                .finally(() => setLoading(false));
            return () => abctrl.abort();
        }
    }, [loading, fetchUploads, id]);

    useEffect(() => {
        if (newFiles.length > 0) {
            uploadFilesToDatabase();
        }
    }, [newFiles]);

    const formatDate = (dateString) => {
        const options = {
            weekday: 'short', year: 'numeric', month: 'short',
            day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true,
        };
        const date = new Date(dateString);
        return isNaN(date) ? 'Invalid date' : new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const uploadFilesToDatabase = () => {
        const companyId = auth.user.company;
        const jobId = id;

        newFiles.forEach(file => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('company', companyId);
            formData.append('job', jobId);

            axios.post('/api/uploads/add', formData)
                .then((res) => {
                    if (res.data.message === "File Added") {
                        setLoading(true);
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        });

        setNewFiles([]);
    }

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 10) {
            setShowAlertFailMessage('You can only upload up to 10 files at a time.')
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
                setShowAlertFailMessage('');
            }, 3000);

            return;
        }
        setNewFiles(files);
    };

    const handleDownload = (filename, file) => {
        axios.get(`/api/uploads/download/${filename}`, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = file;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            })
            .catch(console.error);
    };

    const handleCancelRemoveItem = () => {
        setShowAlertDelete(false);
    };

    const handleConfirmRemoveItem = async () => {
        axios.delete(`/api/uploads/${uploadId}`)
            .then(() => {
                setShowAlertDelete(false);

                setShowAlertDeleteSucc(true);
                setTimeout(() => {
                    setShowAlertDeleteSucc(false);
                }, 3000);

                setLoading(true);
            })
            .catch(console.error);
    };

    const deleteHandler = async (uploadId) => {
        setUploadId(uploadId)
        setShowAlertDelete(true);
    };

    const handleSendEmail = (email, subject, description, cc) => {
        if (!selectedFile) {
            setShowAlertFailMessage('No file selected for email attachment.')
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
                setShowAlertFailMessage('');
            }, 3000);
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const fileContent = event.target.result.split(',')[1];
            const emailData = {
                to: email, cc, subject, text: description,
                fileName: selectedFile.fileName,
                attachment: {
                    filename: selectedFile.file,
                    content: fileContent,
                },
            };

            axios.post('/api/email/send-email-files', emailData)
                .then(response => console.log(response.data.message))
                .catch(console.log);
        };

        reader.readAsDataURL(new Blob([selectedFile]));
    };

    useEffect(() => {
        setRowCount(uploads.length);
    }, [uploads, setRowCount]);

    const handleCloseAlertFail = () => {
        setShowAlertFail(false);
        setShowAlertFailMessage('')
    };

    return (
        <div className="d-flex flex-column form-container" style={{ minHeight: "100vh" }}>
            {showAlertFail && (
                <div className="alertRed alert-danger" role="alert">
                    {showAlertFailMessage}
                    <div className="alert-line-container">
                        <div className="alert-lineRed"></div>
                        <span className="alert-closeRed" onClick={handleCloseAlertFail}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertDelete && (
                <DeleteConfirmUploadComponent
                    onConfirm={handleConfirmRemoveItem}
                    onCancel={handleCancelRemoveItem}
                />
            )}
            {showAlert && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    File Uploaded Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={() => setShowAlert(false)}>X</span>
                    </div>
                </div>
            )}
            {showAlertDeleteSucc && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    File Deleted Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={() => setShowAlertDeleteSucc(false)}>X</span>
                    </div>
                </div>
            )}
            {showImagePopup && (
                <ImagePopupComponent
                    image={selectedImage}
                    imageName={selectedImageName}
                    onClose={() => setShowImagePopup(false)}
                />
            )}
            <form className="row" encType="multipart/form-data">
                <div className="col-sm-12">
                    <div className="row ms-5 ps-5 mt-3">
                        <div className="col-sm-1 d-flex flex-column align-items-start">
                            <h4>Files</h4>
                        </div>
                        <div className="col-sm d-flex flex-column align-items-end">
                            <label htmlFor="fileInput" className="custom-file-input-label">Upload Files</label>
                            <input
                                id="fileInput"
                                multiple
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                name="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <div className="row mt-5 justify-content-center">
                <div className="col-sm-10">
                    {showEmailPopup && (
                        <EmailPopupFileComponent
                            file={selectedFile}
                            onClose={() => setShowEmailPopup(false)}
                            onSend={handleSendEmail}
                        />
                    )}
                    {uploads.length === 0 ? (
                        <div className="text-center">
                            <AiOutlineCloudUpload className="upload-image" />
                            <br />
                            <label htmlFor="fileInput" className="custom-file-input-label-middle"><AiOutlinePlus /> Upload files</label>
                            <input
                                id="fileInput"
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                name="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                            <br />
                            <span>Up to 10 files at once. Max 19MB of PDF, JPEG, or PNG files can be uploaded.</span>
                        </div>
                    ) : (
                        <table className="table table-hover table-bordered">
                            <thead></thead>
                            <tbody>
                                {uploads.map((file, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="image-container">
                                                    {file.fileName.toLowerCase().endsWith('.pdf') ? (
                                                        <a href={file.imageUrl} target="_blank" className="text-center" rel="noopener noreferrer">
                                                            <AiOutlineFileText className="file-icon" />
                                                        </a>
                                                    ) : (
                                                        <img
                                                            onClick={() => {
                                                                setShowImagePopup(true);
                                                                setSelectedImage(file.imageUrl);
                                                                setSelectedImageName(file.fileName);
                                                            }}
                                                            className="img-file-upload"
                                                            src={file.imageUrl}
                                                            alt={`Uploaded file ${index}`}
                                                        />
                                                    )}
                                                </div>
                                                <div className="text-container pt-2">
                                                    <strong>{file.fileName}</strong>
                                                    <br />
                                                    {file.description && <>{file.description}<br /></>}
                                                    {formatDate(file.updatedAt)}
                                                </div>
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-link text-dark dots-large" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <BiDotsVerticalRounded />
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                                        <button
                                                            className="dropdown-item"
                                                            onClick={() => handleDownload(file.file, file.fileName)}
                                                        >
                                                            Download
                                                        </button>
                                                        <button
                                                            className="dropdown-item"
                                                            onClick={() => {
                                                                setSelectedFile(file);
                                                                setShowEmailPopup(true);
                                                            }}
                                                        >
                                                            Send via Email
                                                        </button>
                                                        <button
                                                            className="dropdown-item text-danger"
                                                            onClick={() => deleteHandler(file._id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileUploadComponent;