import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paths from "../../../router/paths";

const EditModelComponent = ({
    fetchModel,
    updateModelApiRequest,
    fetchMake
}) => {
    const [validated, setValidated] = useState(false);
    const [model, setModel] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [make, setMake] = useState([]);
    const [updateModelResponseState, setUpdateModelResponseState] = useState({
        message: "",
        error: "",
    });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const abctrl = new AbortController();
        fetchMake(abctrl)
            .then((res) => setMake(res))
            .catch((er) =>
                setMake([
                    { name: er.response.data.message ? er.response.data.message : er.response.data }
                ]),
            );
        return () => abctrl.abort();
    }, [fetchMake]);

    useEffect(() => {
        fetchModel(id)
            .then((data) => {
                setModel(data);
                setSelectedValue(data.make);
                setDisabled(data.disabled);
            })
            .catch((er) => console.log(er));
    }, [id, fetchModel]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            make: selectedValue,
            model: form.model.value,
            disabled: disabled,
        };

        if (event.currentTarget.checkValidity() === true) {
            updateModelApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "model updated") navigate(`${paths.ADMINMODEL}`);
                })
                .catch((er) =>
                    setUpdateModelResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    }
    const handleCheckboxChange = (event) => {
        setDisabled(event.target.checked);
    };

    return (
        <div className="mt-5 pt-5">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-center">Edit Model</h1>
                        <form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} className="row pt-4 g-3">
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <label htmlFor="make">Make:</label>
                                <select
                                    className="btn btn-outline-light text-dark"
                                    id="make"
                                    name="make"
                                    value={selectedValue}
                                    onChange={(e) => setSelectedValue(e.target.value)}
                                >
                                    {make.map((makes) => (
                                        <option key={makes.id} value={makes.id}>
                                            {makes.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="model">Model:</label>
                                <input type="text"
                                    className="form-control"
                                    id="model"
                                    name="model"
                                    defaultValue={model.model}
                                />
                            </div>
                            <div className="form-check ms-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="disabled"
                                    id="disabled"
                                    checked={disabled}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="disabled">
                                    Disable Model Link
                                </label>
                            </div>
                            <div className="text-center pt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINMODEL} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {updateModelResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditModelComponent;