import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../assets/fileUploadCss.css';
import { FaRegThumbsUp } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import EmailPopupComponent from "./emailPopupComponent";
import { AiOutlineFileText } from 'react-icons/ai';
import ImagePopupComponent from "./imagePopupComponent";
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { AiOutlinePlus } from 'react-icons/ai';
import { useParams } from "react-router-dom";

const InvoiceFileUploadComponent = ({ fetchUploading, invoice }) => {
    const [newFile1, setNewFile1] = useState(null);
    const [uploads, setUploads] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const auth = useSelector(state => state.auth)
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showAlert1, setShowAlert1] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageName, setSelectedImageName] = useState(null);
    const [loading, setLoading] = useState(false); // New state to track loading state
    const { id } = useParams();

    useEffect(() => {
        const abctrl = new AbortController();

        fetchUploading(abctrl)
            .then((res) => {
                const filteredUploads = res.filter((item) => item.invoice === id && !item.invoiceImage);

                setUploads(filteredUploads);
                setLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [fetchUploading, id]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (loading) {

            fetchUploading(abctrl)
                .then((res) => {
                    const filteredUploads = res.filter((item) => item.invoice === id && !item.invoiceImage);

                    setUploads(filteredUploads);
                    setLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [fetchUploading, id, loading]);

    const formatDate = (dateString) => {
        try {
            const options = {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            };
            const date = new Date(dateString);
            if (isNaN(date)) {
                return 'Invalid date';
            }
            return new Intl.DateTimeFormat('en-US', options).format(date);
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Error';
        }
    };

    const uploadFileToDatabase = () => {
        if (newFile1) {

            const companyId = auth.user.company;
            const invoiceId = id;
            const jobId = invoice.jobId;

            const formData = new FormData();
            formData.append('file', newFile1);
            formData.append('company', companyId);
            formData.append('job', jobId);
            formData.append('invoice', invoiceId);

            axios.post('/api/uploads/add', formData)
                .then((res) => {
                    console.log(res.data.message);
                    if (res.data.message === "File Added") {
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(true);
                });
        }
    };

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000); 
        }
    }, [showAlert]);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleCloseAlert1 = () => {
        setShowAlert1(false);
    };

    const handleImagePhoto = (e) => {
        const file = e.target.files[0];
        setNewFile1(file);

        if (file) {
            uploadFileToDatabase(file);
        }
    };


    const handleDownload = (filename, file) => {
        axios.get(`/api/uploads/download/${filename}`, { responseType: 'blob' })
            .then((response) => {
                const blob = new Blob([response.data]);
                const contentType = response.headers['content-type']; // Get the content type from response headers

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;

                // Set the appropriate file extension and content type based on the response
                if (contentType) {
                    a.download = file;
                    a.type = contentType;
                } else {
                    // Provide a default file extension and content type
                    a.download = filename;
                    a.type = 'application/octet-stream'; // You can set it to 'application/pdf' for PDFs or other appropriate types
                }

                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleDelete = (uploadId) => {
        axios.delete(`/api/uploads/${uploadId}`)
            .then((res) => {
                setShowAlert3(true);
                setTimeout(() => {
                   setShowAlert3(false);
                }, 3000);
                setLoading(true);
            })
            .catch((error) => {
                console.error('Error deleting file:', error);
            });
    };

    const handleSendEmail = (email, subject, description) => {
        if (!selectedFile) {
            console.log('No file selected for email attachment.');
            return;
        }

        // Convert the selected file to a Blob
        const blob = new Blob([selectedFile]);

        const reader = new FileReader();
        reader.onload = (event) => {
            const fileContent = event.target.result.split(',')[1];

            const emailData = {
                to: email,
                subject: subject,
                text: description,
                attachment: {
                    filename: selectedFile.file, // Use name property for filename
                    content: fileContent,
                },
            };

            axios.post('/api/email/send-email', emailData)
                .then((response) => {
                    setShowAlert2(true);
                    setTimeout(() => {
                        setShowAlert2(false);
                    }, 3000);
                    setLoading(true);
                })
                .catch((error) => {
                    console.log('Error sending email:', error);
                });
        };

        reader.readAsDataURL(blob); // Read the selected file as a data URL
    };

    return (
        <div>
            {showAlert && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    File Uploaded Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert3 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    File deleted Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert2 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    File Sent Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert1 && (
                <ImagePopupComponent
                    image={selectedImage}
                    imageName={selectedImageName}
                    onClose={handleCloseAlert1}
                />
            )}
            <form className="row d-flex justify-content-between" encType="multipart/form-data">
                <div className="row notesTitle m-0 ps-0 pe-0">
                    <span className="jobSectionSpan col-sm-9 m-0 p-0">Files</span>
                    <div className="col m-0 p-0 btnColContent">
                        <label htmlFor="fileInput1" className="btn btn-outline-primary btn-sm btnUpload">Upload Files</label>
                        <input
                            id="fileInput1"
                            type="file"
                            accept=".png, .jpg, .jpeg, .pdf"
                            name="file"
                            style={{ display: "none" }}
                            onChange={handleImagePhoto}
                        />
                    </div>
                </div>
            </form>

            <div className="row mt-2 justify-content-center">
                <div className="col-sm-12">
                    {showEmailPopup && (
                        <EmailPopupComponent
                            file={selectedFile}
                            onClose={() => setShowEmailPopup(false)}
                            onSend={handleSendEmail}
                        />
                    )}
                    {uploads.length === 0 ? (
                        <div className="text-center">
                            <AiOutlineCloudUpload className="upload-image" />
                            <br />
                            <label htmlFor="fileInput1" className="custom-file-input-label-middle"><AiOutlinePlus /> Upload files</label>
                            <input
                                id="fileInput1"
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                name="file"
                                style={{ display: "none" }}
                                onChange={handleImagePhoto}
                            />
                            <br />
                            <span>
                                Up to 10 files at once. Max 19MB of PDF,
                                <br />
                                JPEG, or PNG files can be uploaded.
                            </span>
                        </div>
                    ) : (
                        <table className="table table-hover table-bordered">
                            <thead></thead>
                            <tbody>
                                {uploads.map((file, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div className="image-container">
                                                    {file.fileName.toLowerCase().endsWith('.pdf') ? (
                                                        <a href={file.imageUrl} target="_blank" className="text-center" rel="noopener noreferrer">
                                                            <AiOutlineFileText className="file-icon" />
                                                        </a>
                                                    ) : (
                                                        <img onClick={() => { setShowAlert1(true); setSelectedImage(file.imageUrl); setSelectedImageName(file.fileName) }} className="img-file-upload" src={file.imageUrl} alt={`Uploaded file ${index}`} />
                                                    )}
                                                </div>
                                                <div className="text-container pt-2">
                                                    <div>
                                                        <strong>{file.fileName}</strong>
                                                        <br />
                                                        <span className="pb-2 pt-2">{file.description && <>{file.description}<br /></>}</span>
                                                        {formatDate(file.updatedAt)}
                                                    </div>
                                                    <div className="btn-group">
                                                        {/* Dropdown menu options */}
                                                    </div>
                                                </div>
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-link text-dark dots-large" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <BiDotsVerticalRounded />
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                                        <button
                                                            className="dropdown-item"
                                                            onClick={() => handleDownload(file.file, file.fileName)}
                                                        >
                                                            Download
                                                        </button>
                                                        <button
                                                            className="dropdown-item"
                                                            onClick={() => {
                                                                setSelectedFile(file);
                                                                setShowEmailPopup(true);
                                                            }}
                                                        >
                                                            Send via Email
                                                        </button>
                                                        <button
                                                            className="dropdown-item text-danger" // Add a class for styling
                                                            onClick={() => handleDelete(file._id)} // Pass the unique identifier of the file
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InvoiceFileUploadComponent;
