import React, { useEffect } from "react";

const GoogleAddressSearch = ({ setSelectedAddress, selectedAddress, setSelectedCity, setSelectedState, setSelectedZip, setSelectedCountry }) => {

    const handlePlaceSelect = (address) => {
        setSelectedAddress(address);

        // Retrieve the selectedPlace object from the Google Places API
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({ input: address }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
                const placeId = predictions[0].place_id;
                const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));

                // Fetch the details of the selected place using the placeId
                placesService.getDetails({ placeId }, (placeDetails, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        // Extract individual components from placeDetails object
                        const { address_components } = placeDetails;

                        // Find and set the state, city, zip, and country based on address_components
                        let state = "";
                        let city = "";
                        let zip = "";
                        let country = "";

                        address_components.forEach((component) => {
                            const { types, long_name } = component;
                            if (types.includes("administrative_area_level_1")) {
                                state = long_name;
                            } else if (types.includes("locality")) {
                                city = long_name;
                            } else if (types.includes("postal_code")) {
                                zip = long_name;
                            } else if (types.includes("country")) {
                                country = long_name;
                            }
                        });
                        setSelectedState(state);
                        setSelectedCity(city);
                        setSelectedZip(zip);
                        setSelectedCountry(country);

                        // Remove "UT" from the address
                        let formattedAddress = address.replace(/,\s*UT\s*,?/gi, "");

                        // Remove city
                        if (city) {
                            const cityRegex = new RegExp(`,?\\s*${city}\\s*,?`, "gi");
                            formattedAddress = formattedAddress.replace(cityRegex, "");
                        }

                        // Remove state
                        if (state) {
                            const stateRegex = new RegExp(`,?\\s*${state}\\s*,?`, "gi");
                            formattedAddress = formattedAddress.replace(stateRegex, "");
                        }

                        // Remove zip
                        if (zip) {
                            const zipRegex = new RegExp(`,?\\s*${zip}\\s*,?`, "gi");
                            formattedAddress = formattedAddress.replace(zipRegex, "");
                        }

                        // Remove unwanted ",," or ","
                        formattedAddress = formattedAddress.replace(/,+/g, ",").replace(/(^,|,$)/g, "");

                        // Remove "USA"
                        formattedAddress = formattedAddress.replace(/\bUSA\b/g, "");

                        setSelectedAddress(formattedAddress.trim());
                    }
                });
            }
        });
    };

    const handlePlacesScriptLoad = () => {
        const input = document.getElementById("address");
        const autocomplete = new window.google.maps.places.Autocomplete(input);

        autocomplete.addListener("place_changed", () => {
            const selectedPlace = autocomplete.getPlace();
            if (selectedPlace && selectedPlace.formatted_address) {
                handlePlaceSelect(selectedPlace.formatted_address);
            }
        });
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3GJBa7Dwtx5pJmJD84lc3ClY2vgq6h40&libraries=places&callback=initMap`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        window.initMap = () => {
            handlePlacesScriptLoad();
        };

        return () => {
            document.head.removeChild(script);
            delete window.initMap;
        };
    }, []);

    const handleAddressChange = (event) => {
        setSelectedAddress(event.target.value);
    };

    return (
        <div className="col-sm">
            <input
                type="text"
                placeholder="Address"
                className="form-control"
                id="address"
                name="address"
                onChange={handleAddressChange}
                value={selectedAddress}
            />
        </div>
    );
};

export default GoogleAddressSearch;
