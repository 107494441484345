import axios from "axios";
import EstimateComponent from "./components/estimateComponent";

const fetchEstimates = async (estimateId) => {
    const { data } = await axios.get(`/api/estimate/get/${estimateId}`, { withCredentials: true });
    return data;
};

const fetchDispatch = async (dispatchId) => {
    const { data } = await axios.get(`/api/dispatch/get-one/${dispatchId}`, { withCredentials: true });
    return data;
}
const fetchUploadsAll = async (abctrl) => {
    const { data } = await axios.get("/api/uploads", { withCredentials: true });
    return data
}
const fetchJobItem = async (abctrl) => {
    const { data } = await axios.get("/api/jobItem", { withCredentials: true });
    return data
}
const fetchJobTax = async () => {
    const { data } = await axios.get(`/api/jobTax/dispatch`, { withCredentials: true });
    return data;
}
const fetchCompany = async (abctrl) => {
    const { data } = await axios.get("/api/company/admin", { withCredentials: true });
    return data
}
const getJobTaxById = async (taxId) => {
    const { data } = await axios.get(`/api/jobTax/get-one/${taxId}`, { withCredentials: true });
    return data
}
const fetchCustomer = async (customerId) => {
    const { data } = await axios.get(`/api/customer/get-one/${customerId}`, { withCredentials: true });
    return data;
}
const fetchUploading = async (abctrl) => {
    const { data } = await axios.get("/api/uploads", { withCredentials: true });
    return data
}
const fetchUploads = async (uploadId) => {
    const { data } = await axios.get(`/api/uploads/image/${uploadId}`, { withCredentials: true });
    return data
}
const deleteJobItem = async (jobItemId) => {
    const { data } = await axios.delete(`/api/jobItem/dispatch/${jobItemId}`, { withCredentials: true });
    return data
}
const deleteEstimate = async (estimateId) => {
    const { data } = await axios.delete(`/api/estimate/delete/${estimateId}`, { withCredentials: true });
    return data
}
const updateDispatchItemApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/jobItem/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateEstimateItem = async (estimateId, formInputsEst) => {
    const { data } = await axios.put(`/api/estimate/item/${estimateId}`, { ...formInputsEst }, { withCredentials: true });
    return data;
}
const updateEstimateNotes = async (estimateId, formInputsNote) => {
    const { data } = await axios.put(`/api/estimate/notes/${estimateId}`, { ...formInputsNote }, { withCredentials: true });
    return data;
}
const updateEstimateStatus = async (estimateId, formInputsStat) => {
    const { data } = await axios.put(`/api/estimate/status/${estimateId}`, { ...formInputsStat }, { withCredentials: true });
    return data;
}
const updateEstimateDescription = async (estimateId, formInputsDesc) => {
    const { data } = await axios.put(`/api/estimate/description/${estimateId}`, { ...formInputsDesc }, { withCredentials: true });
    return data;
}
const updateEstimateDiscount = async (estimateId, formInputsDiscount) => {
    const { data } = await axios.put(`/api/estimate/discount/${estimateId}`, { ...formInputsDiscount }, { withCredentials: true });
    return data;
}
const updateEstimateDeposit = async (estimateId, formInputsDeposit) => {
    const { data } = await axios.put(`/api/estimate/deposit/${estimateId}`, { ...formInputsDeposit }, { withCredentials: true });
    return data;
}
const updateEstimateTax = async (estimateId, formInputsTax) => {
    const { data } = await axios.put(`/api/estimate/tax/${estimateId}`, { ...formInputsTax }, { withCredentials: true });
    return data;
}
const updateEstimateUpload = async (estimateId, formInputsUpload) => {
    const { data } = await axios.put(`/api/estimate/upload/${estimateId}`, { ...formInputsUpload }, { withCredentials: true });
    return data;
}
const updateEstimateSign = async (estimateId, formInputsSign) => {
    const { data } = await axios.put(`/api/estimate/sign/${estimateId}`, { ...formInputsSign }, { withCredentials: true });
    return data;
}
const updateJobItemsApiRequest = async (jobItemsId, formInputs) => {
    const { data } = await axios.put(`/api/jobItem/quanity/${jobItemsId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const createJobItemApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/jobItem/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};
const updateEstimateTotal = async (estimateId, formInputTotal) => {
    const { data } = await axios.put(`/api/estimate/total/${estimateId}`, { ...formInputTotal }, { withCredentials: true });
    return data;
}
const deletePayment = async (paymentId) => {
    const { data } = await axios.delete(`/api/payment/delete/${paymentId}`, { withCredentials: true });
    return data
}
const fetchPaymentAll = async () => {
    const { data } = await axios.get(`/api/payment`, { withCredentials: true });
    return data
}
const fetchPayments = async (paymentsId) => {
    const { data } = await axios.get(`/api/payment/get/${paymentsId}`, { withCredentials: true });
    return data
}

const EstimatePage = () => {

    return <EstimateComponent fetchPayments={fetchPayments} fetchPaymentAll={fetchPaymentAll} deletePayment={deletePayment} fetchUploadsAll={fetchUploadsAll} updateEstimateTotal={updateEstimateTotal} updateEstimateDeposit={updateEstimateDeposit} deleteEstimate={deleteEstimate} updateEstimateSign={updateEstimateSign} fetchUploading={fetchUploading} updateEstimateNotes={updateEstimateNotes} updateDispatchItemApiRequest={updateDispatchItemApiRequest} deleteJobItem={deleteJobItem} updateJobItemsApiRequest={updateJobItemsApiRequest} createJobItemApiRequest={createJobItemApiRequest} fetchUploads={fetchUploads} updateEstimateUpload={updateEstimateUpload} updateEstimateStatus={updateEstimateStatus} fetchCustomer={fetchCustomer} updateEstimateDescription={updateEstimateDescription} fetchCompany={fetchCompany} updateEstimateTax={updateEstimateTax} updateEstimateDiscount={updateEstimateDiscount} updateEstimateItem={updateEstimateItem} getJobTaxById={getJobTaxById} fetchJobTax={fetchJobTax} fetchJobItem={fetchJobItem} fetchDispatch={fetchDispatch} fetchEstimates={fetchEstimates} />;
};

export default EstimatePage;
