import axios from "axios";
import { useDispatch } from "react-redux";
import DispatchJobComponent from "./components/dispatchJobComponent";

const fetchCompany = async (abctrl) => {
    const { data } = await axios.get("/api/company/admin", { withCredentials: true });
    return data
}
const fetchPayments = async (paymentsId) => {
    const { data } = await axios.get(`/api/payment/get/${paymentsId}`, { withCredentials: true });
    return data
}
const fetchPaymentAll = async () => {
    const { data } = await axios.get(`/api/payment`, { withCredentials: true });
    return data
}
const fetchJobTag = async (abctrl) => {
    const { data } = await axios.get("/api/jobTag/dispatch", { withCredentials: true });
    return data
}
const deleteJobItem = async (jobItemId) => {
    const { data } = await axios.delete(`/api/jobItem/dispatch/${jobItemId}`, { withCredentials: true });
    return data
}
const deleteEstimate = async (estimateId) => {
    const { data } = await axios.delete(`/api/estimate/delete/${estimateId}`, { withCredentials: true });
    return data
}
const deletePayment = async (paymentId) => {
    const { data } = await axios.delete(`/api/payment/delete/${paymentId}`, { withCredentials: true });
    return data
}
const fetchJobTax = async () => {
    const { data } = await axios.get(`/api/jobTax/dispatch`, { withCredentials: true });
    return data;
}
const fetchUsers = async (abctrl) => {
    const { data } = await axios.get("/api/users", { withCredentials: true });
    return data
}
const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/dispatch", { withCredentials: true });
    return data
}
const fetchUploads = async (abctrl) => {
    const { data } = await axios.get("/api/uploads", { withCredentials: true });
    return data
}
const fetchDispatch = async (dispatchId) => {
    const { data } = await axios.get(`/api/dispatch/get-job/${dispatchId}`, { withCredentials: true });
    return data;
}
const fetchJobType = async (abctrl) => {
    const { data } = await axios.get("/api/jobType", { withCredentials: true });
    return data
}
const fetchJobItem = async (abctrl) => {
    const { data } = await axios.get("/api/jobItem", { withCredentials: true });
    return data
}
const fetchJobSource = async (abctrl) => {
    const { data } = await axios.get("/api/jobSource", { withCredentials: true });
    return data
}
const updatePaymentTotal = async (paymentId, formInputPaymentTotal) => {
    const { data } = await axios.put(`/api/payment/total/${paymentId}`, { ...formInputPaymentTotal }, { withCredentials: true });
    return data;
}
const updateInvoiceDiscount = async (invoiceId, formInputsDiscount) => {
    const { data } = await axios.put(`/api/invoice/discount/${invoiceId}`, { ...formInputsDiscount }, { withCredentials: true });
    return data;
}
const updateSchedule = async (scheduleId, updatedEventData) => {
    const { data } = await axios.put(`/api/schedule/dispatch/live/${scheduleId}`, { ...updatedEventData }, { withCredentials: true });
    return data
}
const updateScheduleTime = async (scheduleId, updatedTimeData) => {
    const { data } = await axios.put(`/api/schedule/dispatch/time/${scheduleId}`, { ...updatedTimeData }, { withCredentials: true });
    return data
}
const updateCustomerApiRequest = async (customerId, formInputs) => {
    const { data } = await axios.put(`/api/customer/dispatch/${customerId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const updateDispatchApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateDispatchStatusApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/status/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateDispatchTechsApiRequest = async (dispatchId, formInputsTech) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/techs/${dispatchId}`, { ...formInputsTech }, { withCredentials: true });
    return data;
}
const updateDispatchItemApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/jobItem/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateDispatchTagsApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/jobTag/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateDispatchTaxApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/tax/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateDispatchDiscountApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/discount/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateDispatchEstimatesApiRequest = async (dispatchId, formInputsEst) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/estimates/${dispatchId}`, { ...formInputsEst }, { withCredentials: true });
    return data;
}
const updateDispatchInvoiceApiRequest = async (dispatchId, formInputsInv) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/invoice/${dispatchId}`, { ...formInputsInv }, { withCredentials: true });
    return data;
}
const getJobTaxById = async (taxId) => {
    const { data } = await axios.get(`/api/jobTax/get-one/${taxId}`, { withCredentials: true });
    return data
}
const deleteDispatch = async (dispatchId) => {
    const { data } = await axios.delete(`/api/dispatch/dispatch/${dispatchId}`, { withCredentials: true });
    return data
}
const deleteSchedule = async (scheduleId, formInputsSchedule) => {
    const { data } = await axios.put(`/api/schedule/dispatch/${scheduleId}`, { ...formInputsSchedule }, { withCredentials: true });
    return data
}
const fetchCustomer = async (customerId) => {
    const { data } = await axios.get(`/api/customer/get-one/${customerId}`, { withCredentials: true });
    return data
}
const updateScheduleTags = async (scheduleId, updatedTagsData) => {
    const { data } = await axios.put(`/api/schedule/dispatch/tags/${scheduleId}`, { ...updatedTagsData }, { withCredentials: true });
    return data
}
const createEstimateAPI = async (formInputs) => {
    const { data } = await axios.post(`/api/estimate/new`, { ...formInputs }, { withCredentials: true });
    return data;
};

const updateJobItemsApiRequest = async (jobItemsId, formInputs) => {
    const { data } = await axios.put(`/api/jobItem/quanity/${jobItemsId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const createJobItemApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/jobItem/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};
const createInvoiceApi = async (formInputs) => {
    const { data } = await axios.post(`/api/invoice/new`, { ...formInputs }, { withCredentials: true });
    return data;
};
const fetchEstimates = async () => {
    const { data } = await axios.get(`/api/estimate/`, { withCredentials: true });
    return data;
};
const updateDispatchTotal = async (dispatchId, formInputTotal) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/total/${dispatchId}`, { ...formInputTotal }, { withCredentials: true });
    return data;
}

const DispatchJobPage = () => {
    const dispatch = useDispatch();

    return (
        <DispatchJobComponent
            fetchCustomer={fetchCustomer}
            deletePayment={deletePayment}
            fetchPaymentAll={fetchPaymentAll}
            updateInvoiceDiscount={updateInvoiceDiscount}
            updateDispatchInvoiceApiRequest={updateDispatchInvoiceApiRequest}
            createInvoiceApi={createInvoiceApi}
            fetchPayments={fetchPayments}
            updateDispatchTotal={updateDispatchTotal}
            deleteEstimate={deleteEstimate}
            fetchEstimates={fetchEstimates}
            updateDispatchTechsApiRequest={updateDispatchTechsApiRequest}
            updateJobItemsApiRequest={updateJobItemsApiRequest}
            createJobItemApiRequest={createJobItemApiRequest}
            deleteJobItem={deleteJobItem}
            createEstimateAPI={createEstimateAPI}
            deleteSchedule={deleteSchedule}
            updateSchedule={updateSchedule}
            updateScheduleTime={updateScheduleTime}
            fetchUploads={fetchUploads}
            updateDispatchEstimatesApiRequest={updateDispatchEstimatesApiRequest}
            getJobTaxById={getJobTaxById}
            updateScheduleTags={updateScheduleTags}
            updateDispatchDiscountApiRequest={updateDispatchDiscountApiRequest}
            fetchJobTax={fetchJobTax}
            updateDispatchTaxApiRequest={updateDispatchTaxApiRequest}
            fetchJobItem={fetchJobItem}
            deleteDispatch={deleteDispatch}
            updateDispatchItemApiRequest={updateDispatchItemApiRequest}
            updateDispatchTagsApiRequest={updateDispatchTagsApiRequest}
            updateDispatchStatusApiRequest={updateDispatchStatusApiRequest}
            fetchTechInfo={fetchTechInfo}
            updateDispatchApiRequest={updateDispatchApiRequest}
            fetchJobSource={fetchJobSource}
            fetchJobType={fetchJobType}
            updateCustomerApiRequest={updateCustomerApiRequest}
            fetchUsers={fetchUsers}
            fetchDispatch={fetchDispatch}
            fetchCompany={fetchCompany}
            reduxDispatch={dispatch}
            updatePaymentTotal={updatePaymentTotal}
            fetchJobTag={fetchJobTag}
        />
    );
};

export default DispatchJobPage;