import React, { useState } from "react";
import { Link } from "react-router-dom";

const VinDecoderComponent = ({ fetchVin }) => {
    const [vinData, setVinData] = useState(null);
    const [vin, setVin] = useState('');
    const [vinMake, setVinMake] = useState('');
    const [vinModel, setVinModel] = useState('');
    const [vinModelYear, setVinModelYear] = useState('');
    const [vinTrim, setVinTrim] = useState('');
    const [vinOrigin, setVinOrigin] = useState('');
    const [vinBody, setVinBody] = useState('');
    const [vinError, setVinError] = useState('');
    const [vinLength, setVinLength] = useState(true);

    const [vinResult, setVinResult] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const toProperCase = (str) => {
            return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
            });
        };

        fetchVin(vin)
            .then((data) => {
                const newData = data.Results;
                const findMake = newData.find((item) => item.Variable === "Make");
                const findModel = newData.find((item) => item.Variable === "Model");
                const findModelYear = newData.find((item) => item.Variable === "Model Year");
                const findTrim = newData.find((item) => item.Variable === "Trim");
                const findOrigin = newData.find((item) => item.Variable === "Plant Country");
                const findBody = newData.find((item) => item.Variable === "NCSA Body Type");

                if (findMake.Value === null) {
                    setVinError('The VIN does not return any vehicle manufacture.');
                    setVinLength(true);
                } else {
                    const properCaseMake = toProperCase(findMake.Value);
                    const VINNumber = data.SearchCriteria.replace(/^VIN:/i, '');

                    setVinTrim(findTrim);
                    setVinOrigin(findOrigin);
                    setVinBody(findBody);
                    setVinResult(VINNumber);
                    setVinMake(properCaseMake);
                    setVinModel(findModel);
                    setVinModelYear(findModelYear)
                    setVinData(newData);
                    setVin('');
                }
            })
            .catch((er) =>
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data,
                })
            );

    };

    const removeMakeFromVinModel = () => {
        if (vinModel.Value.toLowerCase().startsWith(vinMake.toLowerCase())) {
            return vinModel.Value.substring(vinMake.length).trim();
        }
        return vinModel.Value;
    };

    const handleVinChange = (e) => {
        const newVin = e.target.value;

        const hasInvalidChars = /[OoiIqQ]/.test(newVin);

        if (newVin.length === 17) {
            if (hasInvalidChars) {
                setVinError('VIN cannot contain characters: O, I, or Q.');
                setVinLength(true);
            } else {
                setVinError('');
                setVinLength(false);
            }
        } else if (newVin.length > 0) {
            setVinError('VIN must be exactly 17 characters long.');
            setVinLength(true);
        } else {
            setVinError('');
            setVinLength(true);
        }

        setVin(newVin);
    };

    return (
        <>
            <div className="container-fluid margintop1 marginbottom1">
                <div className="row justify-content-center mt-5 mb-5 pb-5">
                    <div className="col-sm-6">
                        <table className="border border-dark table table-striped center">
                            <tbody>
                                <tr className="borderingTableNT align-items-center mb-5">
                                    <th colSpan="10" scope="row">
                                        <form onSubmit={handleSubmit} className="row justify-content-center mt-5 mb-5">
                                            <div className="col-sm-10">
                                                <input
                                                    type="text"
                                                    className={`form-control ${vinLength && vin ? 'is-invalid' : ''}`}
                                                    id="vin"
                                                    name="vin"
                                                    placeholder="Enter your VIN here...."
                                                    onChange={handleVinChange}
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <button type="submit" disabled={vinLength} className="btn btn-sm btn-outline-primary mb-3 loginButton">Submit</button>
                                            </div>
                                            {(vinLength && vin) && (
                                                <p className="text-danger text-center mt-2 ms-4 mb-3">
                                                    {vinError}
                                                </p>
                                            )}
                                        </form>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {!vinLength && vinData && (
                    <>
                        <div className="row justify-content-center mt-5">
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-header text-center">
                                        {vinModelYear.Value} {vinMake} {vinModel.Value}
                                    </div>
                                    <div className="card-body">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-sm-3">
                                                <strong className="vinFontSize">VIN:</strong>
                                            </div>
                                            <div className="col-sm-6 text-end line-item-text">
                                                {vinResult}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-header">
                                        Basic
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-3 text-start">
                                                <strong className="vinFontSize">Make:</strong>
                                            </div>
                                            <div className="col-sm-5 text-start line-item-text">
                                                {vinMake}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-3 text-start">
                                                <strong className="vinFontSize">Model:</strong>
                                            </div>
                                            <div className="col-sm-5 text-start line-item-text">
                                                {removeMakeFromVinModel()}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-3 text-start">
                                                <strong className="vinFontSize">Year:</strong>
                                            </div>
                                            <div className="col-sm-5 text-start line-item-text">
                                                {vinModelYear.Value}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-3 text-start">
                                                <strong className="vinFontSize">Origin:</strong>
                                            </div>
                                            <div className="col-sm-7 text-start line-item-text">
                                                {vinOrigin.Value}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-5 col-md-3 text-start">
                                                <strong className="vinFontSize">Trim Level:</strong>
                                            </div>
                                            <div className="col-sm-7 col-md-7 text-start line-item-text">
                                                {vinTrim.Value}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-5 col-md-3 text-start">
                                                <strong className="vinFontSize">Body Type:</strong>
                                            </div>
                                            <div className="col-sm-7 col-md-7 text-start line-item-text">
                                                {vinBody.Value}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-header">
                                        Locksmith Info
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-5 col-md-3 text-start">
                                                <strong className="vinFontSize">Key Code:</strong>
                                            </div>
                                            <div className="col-sm-7 col-md-7 text-start line-item-text">
                                                <Link className="btn btn-sm btn-outline-primary loginButton" to={`/buy-pincode/${vinResult}`}>Purchase</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default VinDecoderComponent;