import jsPDF from 'jspdf';

export function WorkOrderPdfGenerator(filterJobTax, companies, imgData, formatDateTimePDF, dispatching, customers, jobItem) {

    const doc = new jsPDF("p", "mm", "a4");

    if (imgData) {
        doc.addImage(imgData, "PNG", 10, 5, 80, 60);
    }

    doc.setFontSize(25);
    doc.text("WORK ORDER", 140, 12);

    doc.setFontSize(10);
    doc.text(`${companies.name}`, 10, 80);
    doc.text(`${companies.address}`, 10, 85);
    doc.text(`${companies.city}, ${companies.state} ${companies.zip}, ${companies.country}`, 10, 90);
    doc.text(`${companies.email}`, 10, 95);
    doc.text(`${companies.phone}`, 10, 100);

    doc.setFontSize(10);
    doc.text(`Job #`, 140, 80);
    doc.text(`${dispatching.count}`, 160, 80);
    doc.text(`Date`, 140, 85);
    doc.text(`${formatDateTimePDF(dispatching.updatedAt)}`, 160, 85);

    doc.setFontSize(12);
    doc.text(`Bill To:`, 10, 110);

    doc.setFontSize(10);
    doc.text(`${customers.name} ${customers.lastName}`, 12, 115);
    doc.text(`${customers.address}`, 12, 120);
    doc.text(`${customers.city}, ${customers.state} ${customers.zip}`, 12, 125);
    if (customers.phone) {
        doc.text(`${customers.phone}`, 12, 130);
    }
    if (customers.email) {
        doc.text(`${customers.email}`, 12, 135);
    }

    doc.setFontSize(12);
    doc.text(`Service Location:`, 100, 110);

    doc.setFontSize(10);
    doc.text(`${customers.name} ${customers.lastName}`, 102, 115);
    doc.text(`${customers.address}`, 102, 120);
    doc.text(`${customers.city}, ${customers.state} ${customers.zip}`, 102, 125);
    if (customers.phone) {
        doc.text(`${customers.phone}`, 102, 130);
    }
    if (customers.email) {
        doc.text(`${customers.email}`, 102, 135);
    }

    const tableData = [
        ['Description', 'QTY', 'Price', 'Amount'],
        ...jobItem.map((item) => {
            const amount = `$${(item.quantity * item.price).toFixed(2)}`;
            const optionalText = item.optional ? 'Optional' : '';

            return [
                `${item.name}\n${item.description}`,
                item.quantity,
                `$${item.price}`,
                `${amount}\n${optionalText}`,
            ];
        }),
    ];

    let lastTableRowY = 0;

    doc.autoTable({
        startY: 140,
        head: tableData.slice(0, 1),
        body: tableData.slice(1),
        didDrawPage: function (data) {
            // Keep track of the Y position of the last row in the first table
            lastTableRowY = data.cursor.y + 10; // Adjust the 10 as needed for spacing
        },
    });
    let startY = lastTableRowY;

    const subtotal = jobItem.reduce((acc, row) => {
        const amount = parseFloat(row.quantity * row.price);
        return acc + amount;
    }, 0);

    let discountValue = 0;
    if (dispatching.discountType === 'percent') {
        // If the discount type is a percent, calculate the discount as a percentage of the subtotal
        discountValue = (dispatching.discount / 100) * subtotal;
    } else if (dispatching.discountType === 'amount') {
        // If the discount type is an amount, use the provided discount value
        discountValue = dispatching.discount;
    }

    const taxValue = (subtotal - discountValue) * filterJobTax.taxRate / 100;

    const total = subtotal + taxValue;

    doc.text(`Sub Total:`, 130, startY);
    doc.text(`$${subtotal.toFixed(2)}`, 160, startY);
    startY += 8;
    if (dispatching.discount > 0) {
        doc.text(`Discount:`, 130, startY);
        doc.text(`$${discountValue.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Tax:`, 139, startY);
        doc.text(`${taxValue.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Tax Rate:`, 131, startY);
        doc.text(`${filterJobTax.taxRate}%`, 160, startY);
        startY += 8;
        doc.text(`Total:`, 137, startY);
        doc.text(`$${total.toFixed(2)}`, 160, startY);
    } else {
        doc.text(`Tax:`, 139, startY);
        doc.text(`${taxValue.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Tax Rate:`, 131, startY);
        doc.text(`${filterJobTax.taxRate}%`, 160, startY);
        startY += 8;
        doc.text(`Total:`, 137, startY);
        doc.text(`$${total.toFixed(2)}`, 160, startY);
    }
    startY += 20;
    if (startY > 290) {
        doc.addPage(); // Add a new page
        startY = 15;
    }
    doc.setFontSize(12);
    doc.text("Terms:", 10, startY);
    startY += 5;
    doc.setFontSize(10);
    doc.text("By paying the due balance on invoices provided, the Client hereby acknowledges that all requested service", 12, startY);
    startY += 5;
    doc.text("items for this date and/or any other dates listed above in the description section of the table, have been", 12, startY);
    startY += 5;
    doc.text("performed and have been tested showing successful satisfactory install/repair, unless otherwise stated on", 12, startY);
    startY += 5;
    doc.text("the invoice, in which labor service charges still apply if any repairs have been made. By accepting this", 12, startY);
    startY += 5;
    doc.text("invoice, the Client agrees to pay in full the amount listed in the Total section of the invoice.", 12, startY);
    startY += 10;
    if (startY > 290) {
        doc.addPage();
        startY = 15;
    }
    doc.setFontSize(12);
    doc.text("Notes:", 10, startY);
    startY += 5;
    if (startY > 290) {
        doc.addPage();
        startY = 15;
    }
    if (dispatching.notes) {
        doc.setFontSize(10);
        doc.text(`${dispatching.notes}`, 12, startY);
    };
    startY += 25;

    if (startY > 290) {
        doc.addPage();
        startY = 15;
    }
    doc.setFontSize(25);
    doc.text("Thank You For Your Business!", 50, startY);

    //const pdfBlob = doc.output("blob");
    //const pdfUrl = URL.createObjectURL(pdfBlob);
    // window.open(pdfUrl, "_blank");
    //URL.revokeObjectURL(pdfUrl);
    doc.save(`workOrder-${customers.name}-${customers.lastName}.pdf`);

}
