import axios from 'axios'
import { useDispatch } from "react-redux";
import AdminEditCodesComponent from './components/adminEditCodesComponent';

const fetchCodes = async (codesId) => {
    const { data } = await axios.get(`/api/codes/admin/${codesId}`, { withCredentials: true });
    return data;
}

const updateCodesApiRequest = async (codesId, formInputs) => {
    const { data } = await axios.put(`/api/codes/admin/${codesId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const AdminEditCodesPage = () => {

    const reduxDispatch = useDispatch();

    return <AdminEditCodesComponent fetchCodes={fetchCodes} updateCodesApiRequest={updateCodesApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditCodesPage;
