import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";

const EditJobItemsComponent = ({
    fetchJobItems,
    updateJobItemsApiRequest,
}) => {
    const [validated, setValidated] = useState(false);
    const [jobItems, setJobItems] = useState([]);
    const auth = useSelector(state => state.auth)
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedTag, setSelectedTag] = useState("Locksmith");
    const [selectedItemType, setSelectedItemType] = useState("");
    const [isTaxable, setIsTaxable] = useState(false);
    const [updateJobItemsResponseState, setUpdateJobItemsResponseState] = useState({
        message: "",
        error: "",
    });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchJobItems(id)
            .then((data) => {
                setJobItems(data);
                setSelectedItemType(data.itemType);
                setSelectedTag(data.tag);
                setSelectedCategory(data.type);
                setIsTaxable(data.taxable);
            })
            .catch((er) => console.log(er));
    }, [id, fetchJobItems]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            quantity: form.quantity.value,
            price: form.price.value,
            markup: form.markup.value,
            cost: form.cost.value,
            itemType: selectedItemType,
            description: form.description.value,
            taxable: isTaxable,
            tag: selectedTag,
            type: selectedCategory,
            company: auth.user.company,
        };

        if (event.currentTarget.checkValidity() === true) {
            updateJobItemsApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "Job item updated") navigate(`${paths.DISPATCHJOBITEM}`);
                })
                .catch((er) =>
                    setUpdateJobItemsResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    const handleSelectCategory = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedCategory(newSelectedOption);
    }

    const handleSelectTag = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedTag(newSelectedOption);
    }

    const handleSelectItemType = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedItemType(newSelectedOption);
    }

    const handleTaxableChange = () => {
        setIsTaxable((prevIsTaxable) => !prevIsTaxable);
    };

    return (
        <div className="pt-5 mt-3">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-6">
                        <h1 className="text-center">Edit Job Items</h1>
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="row g-3">
                            <div className="row mt-5">
                                <div className="col">
                                    <label htmlFor="name">Item Name:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        defaultValue={jobItems.name}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <label htmlFor="quantity">Quantity:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="quantity"
                                        name="quantity"
                                        defaultValue={jobItems.quantity}
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="price">Price:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="price"
                                        name="price"
                                        defaultValue={jobItems.price}
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="markup">Markup:</label>
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            className="form-control widthFormControlPer"
                                            id="markup"
                                            name="markup"
                                            defaultValue={jobItems.markup}
                                        />
                                        <span className="percent-sign">%</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <label htmlFor="cost">Cost:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cost"
                                        name="cost"
                                        defaultValue={jobItems.cost}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="form-floating">
                                        <select className="form-select" onChange={handleSelectItemType} value={selectedItemType} id="floatingSelect" aria-label="Floating label select example">
                                            <option defaultValue="Product">Product</option>
                                            <option value="Service">Service</option>
                                            <option value="Hours">Hours</option>
                                            <option value="Expense">Expense</option>
                                        </select>
                                        <label htmlFor="floatingSelect">Item Type:</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="form-floating">
                                        <select className="form-select" onChange={handleSelectTag} value={selectedTag} id="floatingSelect" aria-label="Floating label select example">
                                            <option defaultValue="Locksmith">Locksmith</option>
                                        </select>
                                        <label htmlFor="floatingSelect">Item Tag:</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating">
                                        <select className="form-select" onChange={handleSelectCategory} value={selectedCategory} id="floatingSelect" aria-label="Floating label select example">
                                            <option defaultValue="All">All</option>
                                            <option value="Automotive">Automotive</option>
                                            <option value="Access Control">Access Control</option>
                                            <option value="Commercial">Commercial</option>
                                            <option value="Commercial Rekey">Commercial Rekey</option>
                                            <option value="Commercial Diagnose">Commercial Diagnose</option>
                                            <option value="Commercial Hardware Install/Repair">Commercial Hardware Install/Repair</option>
                                            <option value="Commercial Other">Commercial Other</option>
                                            <option value="Residential">Residential</option>
                                            <option value="Residential Rekey">Residential Rekey</option>
                                            <option value="Residential Diagnose">Residential Diagnose</option>
                                            <option value="Residential Hardware Install/Repair">Residential Hardware Install/Repair</option>
                                            <option value="Residential Other">Residential Other</option>
                                            <option value="Residential Lockout">Residential Lockout</option>
                                            <option value="Commercial Lockout">Commercial Lockout</option>
                                            <option value="Other Rekey">Other Rekey</option>
                                            <option value="Other Lockout">Other Lockout</option>
                                            <option value="Safe Open/Repair">Safe Open/Repair</option>
                                            <option value="Surveillance">Surveillance</option>
                                            <option value="Other">Other</option>
                                            <option value="Windows/Doors">Windows/Doors</option>
                                        </select>
                                        <label htmlFor="floatingSelect">Item Category:</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-start mt-3">
                                <div className="col-sm-3 form-check form-switch form-check-reverse">
                                    <label htmlFor="taxable">Taxable Item:</label>
                                    <input
                                        className="form-check-input"
                                        id="taxable"
                                        type="checkbox"
                                        name="taxable"
                                        checked={isTaxable}
                                        onChange={handleTaxableChange}
                                        role="switch"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="form-floating">
                                        <textarea className="form-control" placeholder="Description" defaultValue={jobItems.description} name="description" id="description" style={{ height: "100px" }} ></textarea>
                                        <label htmlFor="description">Description</label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.DISPATCHJOBITEM} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {updateJobItemsResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditJobItemsComponent;