import axios from "axios";
import ClientInvoiceComponent from "./components/clientInvoiceComponent";

const fetchInvoice = async (invoiceId) => {
    const { data } = await axios.get(`/api/invoice/get/${invoiceId}`, { withCredentials: true });
    return data;
};
const fetchPaymentAll = async () => {
    const { data } = await axios.get(`/api/payment`, { withCredentials: true });
    return data
}
const fetchDispatch = async (dispatchId) => {
    const { data } = await axios.get(`/api/dispatch/get-one/${dispatchId}`, { withCredentials: true });
    return data;
}
const fetchJobItem = async (abctrl) => {
    const { data } = await axios.get("/api/jobItem/estimate", { withCredentials: true });
    return data
}
const fetchJobTax = async () => {
    const { data } = await axios.get(`/api/jobTax/estimate`, { withCredentials: true });
    return data;
}
const fetchCompany = async (abctrl) => {
    const { data } = await axios.get("/api/company/estimate", { withCredentials: true });
    return data
}
const getJobTaxById = async (taxId) => {
    const { data } = await axios.get(`/api/jobTax/get-one/${taxId}`, { withCredentials: true });
    return data
}
const fetchCustomer = async (customerId) => {
    const { data } = await axios.get(`/api/customer/get-one/estimate/${customerId}`, { withCredentials: true });
    return data;
}
const updateDispatchItemApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/jobItem/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateEstimateItem = async (estimateId, formInputsEst) => {
    const { data } = await axios.put(`/api/estimate/item/${estimateId}`, { ...formInputsEst }, { withCredentials: true });
    return data;
}

const fetchUploadsAll = async (abctrl) => {
    const { data } = await axios.get("/api/uploads", { withCredentials: true });
    return data
}


const ClientInvoicePage = () => {

    return <ClientInvoiceComponent
        fetchUploadsAll={fetchUploadsAll}
        fetchPaymentAll={fetchPaymentAll}
        updateDispatchItemApiRequest={updateDispatchItemApiRequest}
        fetchCustomer={fetchCustomer}
        fetchCompany={fetchCompany}
        updateEstimateItem={updateEstimateItem}
        getJobTaxById={getJobTaxById}
        fetchJobTax={fetchJobTax}
        fetchJobItem={fetchJobItem}
        fetchDispatch={fetchDispatch}
        fetchInvoice={fetchInvoice}
    />;
};

export default ClientInvoicePage;
