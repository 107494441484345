import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import InventoryLinksComponent from "../../../components/inventory/inventoryLinksComponent";
import { FaRegThumbsUp } from 'react-icons/fa';
import * as React from "react";
import InventoryRequestPopUpComponent from "../../../components/inventory/inventoryRequestComponent";
import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import InventoryCancelRequestComponent from "../../../components/inventory/inventoryCancelRequestComponent";
import { FaRegThumbsDown } from "react-icons/fa";
import InventoryDenyRequestComponent from "../../../components/inventory/inventoryDenyRequestComponent";
import InventoryConfirmReqPopUpComponent from "../../../components/inventory/inventoryConfirmReqComponent";
import InventoryPickUpComponent from "../../../components/inventory/inventoryPickUpComponent";
import axios from "axios";
import { MdOutlineInventory } from "react-icons/md";

const InventoryRequestStatusComponent = ({ updateRequestApiRequestDeny, fetchRequest, updateRequestApiRequest, fetchInventory, fetchLocation, fetchUsers, fetchTechInfo }) => {
    const [inventory, setInventory] = useState([]);
    const auth = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationReq, setShowConfirmationReq] = useState(false);
    const [showConfirmationDen, setShowConfirmationDen] = useState(false);
    const [showConfirmationCan, setShowConfirmationCan] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemIn, setSelectedItemIn] = useState(null);
    const [selectedItemDeny, setSelectedItemDeny] = useState(null);
    const [selectedItemCan, setSelectedItemCan] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [showAlertSuc, setShowAlertSuc] = useState(false);
    const [locations, setLocations] = useState([]);
    const [filterLocations, setFilterLocations] = useState('');
    const [request, setRequest] = useState([]);
    const [user, setUser] = useState([]);
    const [techs, setTechs] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Outgoing");
    const [showAlertSucDen, setShowAlertSucDen] = useState(false);
    const [showAlertPick, setShowAlertPick] = useState(false);
    const [selectedItemPick, setSelectedItemPick] = useState(null);
    const [showAlertSucCom, setShowAlertSucCom] = useState(false);
    const [inventorySet, setInventorySet] = useState([]);

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchInventory(abctrl)
            .then((res) => {
                const filteredInventory = res.filter((item) => item.company === auth.user.company);

                const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                const inventoryWithSelectedLocation1 = filteredInventory.filter((item) =>
                    item.InventoryLocation.some((location) =>
                        location.name1 === locationSelect &&
                        location.quantity <= location.minimunStock1
                    )
                );
                setInventorySet(inventoryWithSelectedLocation1);

                fetchUsers()
                    .then((data) => {
                        const filteredUser = data.find((item) => item._id === auth.user._id);

                        setUser(data);

                        fetchTechInfo()
                            .then((resData) => {
                                const filteredTech = resData.find((item) => item.user === filteredUser._id);

                                setTechs(resData);

                                fetchLocation()
                                    .then((dataRes) => {
                                        if (filteredTech) {
                                            const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);

                                            setFilterLocations(filteredLocation);
                                            setLocations(dataRes);

                                            if (filteredLocation) {
                                                const name = `${filteredUser.name} ${filteredLocation.name}`

                                                const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                                                    item.InventoryLocation.some((location) => location.name1 === name)
                                                );

                                                setInventory(inventoryWithSelectedLocation);
                                                setSelectedLocation(name);
                                            } else {
                                                setInventory(filteredInventory);
                                            }
                                        }
                                        fetchRequest()
                                            .then((dataResRes) => {
                                                if (dataResRes.message !== 'request not found') {
                                                    const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                                    setRequest(filteredRequest)
                                                } else {
                                                    setRequest([])
                                                }
                                            })
                                            .catch((er) =>
                                                console.log(
                                                    er.response.data.message ? er.response.data.message : er.response.data
                                                )
                                            );
                                    })
                                    .catch((er) =>
                                        console.log(
                                            er.response.data.message ? er.response.data.message : er.response.data
                                        )
                                    );
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );

                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );

                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        return () => abctrl.abort();
    }, [fetchInventory, auth.user.company, selectedLocation, fetchUsers, auth.user._id, fetchTechInfo, fetchLocation, fetchRequest]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchInventory(abctrl)
                .then((res) => {
                    const filteredInventory = res.filter((item) => item.company === auth.user.company);

                    const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                    const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === locationSelect &&
                            location.quantity <= location.minimunStock1
                        )
                    );
                    setInventorySet(inventoryWithSelectedLocation);

                    fetchUsers()
                        .then((data) => {
                            const filteredUser = data.find((item) => item._id === auth.user._id);

                            setUser(data);

                            fetchTechInfo()
                                .then((resData) => {
                                    const filteredTech = resData.find((item) => item.user === filteredUser._id);
                                    setTechs(resData);

                                    fetchLocation()
                                        .then((dataRes) => {
                                            if (filteredTech) {
                                                const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);

                                                setFilterLocations(filteredLocation);
                                                setLocations(dataRes);

                                                if (filteredLocation) {
                                                    const name = `${filteredUser.name} ${filteredLocation.name}`

                                                    const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                                                        item.InventoryLocation.some((location) => location.name1 === name)
                                                    );
                                                    setInventory(inventoryWithSelectedLocation);
                                                    setSelectedLocation(name);
                                                } else {
                                                    setInventory(filteredInventory);
                                                }
                                            }
                                            fetchRequest()
                                                .then((dataResRes) => {
                                                    if (dataResRes.message !== 'request not found') {
                                                        const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                                        setRequest(filteredRequest)
                                                    } else {
                                                        setRequest([])
                                                    }
                                                })
                                                .catch((er) =>
                                                    console.log(
                                                        er.response.data.message ? er.response.data.message : er.response.data
                                                    )
                                                );

                                        })
                                        .catch((er) =>
                                            console.log(
                                                er.response.data.message ? er.response.data.message : er.response.data
                                            )
                                        );
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );

                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );

                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [fetchInventory, auth.user.company, selectedLocation, isLoading, fetchUsers, auth.user._id, fetchTechInfo, fetchLocation, fetchRequest]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };
    const componentRef = useRef(null);

    const handleSubmit = async (id, destinationLocationName, quantity, status, selectedId) => {
        const parsedQuantity = parseInt(quantity, 10);

        if (isNaN(parsedQuantity) || !Number.isInteger(parsedQuantity) || parsedQuantity <= 0) {
            setShowAlertMessage("Quantity must be a positive integer.");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);

            return;
        }

        let formInputs;

        formInputs = {
            quantity: parsedQuantity,
            location: filterLocations._id,
            toLocation: destinationLocationName,
            status: status,
            inventoryId: id,
            company: auth.user.company,
        };

        updateRequestApiRequest(selectedId, formInputs)
            .then((data) => {
                if (data.message === "request updated");
                setShowAlertSuc(true);
                setTimeout(() => {
                    setShowAlertSuc(false);
                }, 3000);
                setIsLoading(true);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === "Invalid quantity to request.") {
                    setShowAlertMessage("Invalid quantity to request.");
                } else {
                    setShowAlertMessage(`Error requesting item: ${error.message}`);
                }

                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 3000);
            });

    };

    const handleSubmitIn = async (quantity, selectedId) => {
        const parsedQuantity = parseInt(quantity, 10);

        if (isNaN(parsedQuantity) || !Number.isInteger(parsedQuantity) || parsedQuantity <= 0) {
            setShowAlertMessage("Quantity must be a positive integer.");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);

            return;
        }

        let formInputs;

        formInputs = {
            quantity: parsedQuantity,
            status: 'Pending Pickup',
            company: auth.user.company,
        };

        updateRequestApiRequest(selectedId, formInputs)
            .then((data) => {
                if (data.message === "request updated");
                setShowAlertSuc(true);
                setTimeout(() => {
                    setShowAlertSuc(false);
                }, 3000);
                setIsLoading(true);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === "Invalid quantity to request.") {
                    setShowAlertMessage("Invalid quantity to request.");
                } else {
                    setShowAlertMessage(`Error requesting item: ${error.message}`);
                }

                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 3000);
            });

    };

    const handleSubmitCan = async (selectedItemCan) => {

        let formInputs;

        formInputs = {
            status: 'Canceled',
            company: auth.user.company,
        };

        updateRequestApiRequestDeny(selectedItemCan, formInputs)
            .then((data) => {
                if (data.message === "request updated");
                setShowAlertSucDen(true);
                setTimeout(() => {
                    setShowAlertSucDen(false);
                }, 3000);
                setIsLoading(true);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === "Invalid quantity to request.") {
                    setShowAlertMessage("Invalid quantity to request.");
                } else {
                    setShowAlertMessage(`Error requesting item: ${error.message}`);
                }

                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 3000);
            });

    };

    const handleSubmitPickUp = async (selectedItemPick) => {

        let formInputs;

        formInputs = {
            status: 'Completed',
            company: auth.user.company,
        };

        updateRequestApiRequestDeny(selectedItemPick.requestData._id, formInputs)
            .then(async (data) => {
                if (data.message === "request updated");

                try {
                    await axios.put(`/api/inventory/${selectedItemPick.requestData.inventoryId}/location/${selectedItemPick.invOther._id}/transfer/${selectedItemPick.inv._id}`, { quantity: selectedItemPick.requestData.quantity });

                    setShowAlertSucCom(true);
                    setTimeout(() => {
                        setShowAlertSucCom(false);
                    }, 3000);
                    setIsLoading(true);

                } catch (error) {

                    if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === "Invalid quantity to transfer.") {
                        setShowAlertMessage("Invalid quantity to transfer.");

                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    } else {
                        setShowAlertMessage(`Error transferring item between locations: ${error.message}`);

                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === "Invalid quantity to request.") {
                    setShowAlertMessage("Invalid quantity to request.");
                } else {
                    setShowAlertMessage(`Error requesting item: ${error.message}`);
                }

                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 3000);
            });

    };

    const handleCheckout = (id, item, requestData) => {
        setSelectedItem({ id, item, requestData });
        setShowConfirmation(true);
    };

    const handleCheckoutIn = (id, item, requestData) => {
        setSelectedItemIn({ id, item, requestData });
        setShowConfirmationReq(true);
    };

    const onConfirmRequest = (quantity, status, selectedId) => {
        handleSubmit(selectedItem.id, selectedItem.item._id, quantity, status, selectedId);
        setShowConfirmation(false);
    };

    const onConfirmRequestIn = (quantity, selectedId) => {
        handleSubmitIn(quantity, selectedId);
        setShowConfirmationReq(false);
    };

    const onConfirmRequestCan = () => {
        handleSubmitCan(selectedItemCan._id);
        setShowConfirmationCan(false);
    };

    const onConfirmRequestDen = () => {
        handleSubmitCan(selectedItemDeny._id);
        setShowConfirmationDen(false);
    };

    const handleCan = (requestData) => {
        setSelectedItemCan(requestData);
        setShowConfirmationCan(true);
    };

    const handleDenyReq = (requestData) => {
        setSelectedItemDeny(requestData);
        setShowConfirmationDen(true);
    };

    const handlePickUp = (requestData, inv, invOther) => {
        setSelectedItemPick({ requestData, inv, invOther });
        setShowAlertPick(true);
    };
    const onCancelPickUp = () => {
        setShowAlertPick(false);
    };

    const onConfirmRequestPickUp = () => {
        handleSubmitPickUp(selectedItemPick);
        setShowAlertPick(false);
    };

    const onCancelRequest = () => {
        setShowConfirmation(false);
    };

    const onCancelRequestCan = () => {
        setShowConfirmationCan(false);
    };

    const onCancelRequestIn = () => {
        setShowConfirmationReq(false);
    };

    const onCancelRequestDen = () => {
        setShowConfirmationDen(false);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleCloseAlertSuc = () => {
        setShowAlertSuc(false);
    };
    const handleCloseAlertSucCom = () => {
        setShowAlertSucCom(false);
    };
    const handleCloseAlertSucDen = () => {
        setShowAlertSucDen(false);
    };

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        setIsLoading(true);
    };

    const requestDataLen = () => {
        if (filterLocations) {
            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.location && filterLocations.tech === locc.tech));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            const requestDataDD = filteredStatus.length;

            if (requestDataDD === undefined) {
                return 0;
            } else {
                return filteredStatus;
            }
        } else {
            return 0;
        }
    }

    const requestDataLenStatus = () => {
        if (filterLocations) {

            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.location && filterLocations.tech === locc.tech));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            if (filteredStatus === undefined) {
                return 0;
            } else {
                return filteredStatus;
            }
        } else {
            return 0;
        }
    }

    const requestDataLenInStatus = () => {
        if (filterLocations) {

            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.toLocation));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            if (filteredStatus === undefined) {
                return 0;
            } else {
                return filteredStatus;
            }
        } else {
            return 0;
        }
    }

    const requestDataLenIn = () => {
        if (filterLocations) {

            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.toLocation));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            if (filteredStatus === undefined) {
                return 0;
            } else {
                return filteredStatus;
            }
        } else {
            return 0;
        }
    }

    const findUserData = (requestId) => {
        if (selectedTab === 'Incoming') {
            const requestDataLoc = locations.find((loc) => loc._id === requestId.location);

            if (requestDataLoc) {
                const requestDataTech = techs.find((tech) => tech._id === requestDataLoc.tech);

                if (requestDataTech) {
                    const requestDataUser = user.find((user) => user._id === requestDataTech.user);

                    return requestDataUser.name + ' ' + requestDataLoc.name;
                }
            }
        } else {
            const requestDataLoc = locations.find((loc) => loc._id === requestId.toLocation);

            if (requestDataLoc) {
                const requestDataTech = techs.find((tech) => tech._id === requestDataLoc.tech);

                if (requestDataTech) {
                    const requestDataUser = user.find((user) => user._id === requestDataTech.user);

                    return requestDataUser.name + ' ' + requestDataLoc.name;
                } else {
                    return "Unknown";
                }
            }
        }
    };

    const findUserDataTech = (requestId) => {
        if (selectedTab === 'Outgoing') {
            const requestDataLoc = locations.find((loc) => loc._id === requestId.location);

            if (requestDataLoc) {
                const requestDataTech = techs.find((tech) => tech._id === requestDataLoc.tech);

                if (requestDataTech) {
                    const requestDataUser = user.find((user) => user._id === requestDataTech.user);

                    return requestDataUser.name + ' ' + requestDataLoc.name;
                } else {
                    return "Unknown";
                }
            }
        } else {
            const requestDataLoc = locations.find((loc) => loc._id === requestId.toLocation);

            if (requestDataLoc) {
                const requestDataTech = techs.find((tech) => tech._id === requestDataLoc.tech);

                if (requestDataTech) {
                    const requestDataUser = user.find((user) => user._id === requestDataTech.user);

                    return requestDataUser.name + ' ' + requestDataLoc.name;
                } else {
                    return "Unknown";
                }
            }
        }
    };

    const formattedDate = (req) => {
        const date = new Date(req);
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const formattedDate = `${daysOfWeek[date.getUTCDay()]} ${months[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()}`;

        return formattedDate;
    }

    return (
        <div className="min-vh-100">
            {(showConfirmation && selectedItem && (filterLocations !== undefined)) ? (
                <InventoryRequestPopUpComponent
                    message={`Enter the quantity to request (Available Stock: ${selectedItem.item.quantity}):`}
                    selectedItem={selectedItem}
                    onConfirm={onConfirmRequest}
                    onCancel={onCancelRequest}
                />
            ) : showConfirmation && (
                <div className="alertRed w-90 alert-danger" role="alert">
                    Technition has not been set with an inventory yet.
                    <div className="alert-line-container">
                        <span className="alert-closeRed" onClick={onCancelRequest}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {(showConfirmationReq && selectedItemIn && (filterLocations !== undefined)) ? (
                <InventoryConfirmReqPopUpComponent
                    selectedItemIn={selectedItemIn}
                    onConfirm={onConfirmRequestIn}
                    onCancel={onCancelRequestIn}
                />
            ) : showConfirmationReq && (
                <div className="alertRed w-90 alert-danger" role="alert">
                    Technition has not been set with an inventory yet.
                    <div className="alert-line-container">
                        <span className="alert-closeRed" onClick={onCancelRequestIn}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertPick && (
                <InventoryPickUpComponent
                    onConfirm={onConfirmRequestPickUp}
                    onCancel={onCancelPickUp}
                />
            )}
            {showConfirmationCan && (
                <InventoryCancelRequestComponent
                    onConfirm={onConfirmRequestCan}
                    onCancel={onCancelRequestCan}
                />
            )}
            {showConfirmationDen && (
                <InventoryDenyRequestComponent
                    onConfirm={onConfirmRequestDen}
                    onCancel={onCancelRequestDen}
                />
            )}
            {showAlert && (
                <div className="alertRed alert-danger" role="alert">
                    {showAlertMessage}
                    <div className="alert-line-container">
                        <div className="alert-lineRed"></div>
                        <span className="alert-closeRed" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertSuc && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Requested
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertSucCom && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Transfer Completed
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSucCom}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertSucDen && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Request Canceled
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSucDen}>
                            X
                        </span>
                    </div>
                </div>
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <InventoryLinksComponent inventorySet={inventorySet} requestDataLenStatus={requestDataLenStatus} requestDataLenInStatus={requestDataLenInStatus} />
                </div>
                <div className="col-sm-10 mt-5 pt-5 ms-0 ps-0">
                    <div className="row d-flex justify-content-between m-0 p-0 w-100 mb-3">
                        <div className="col-sm-5 text-start m-0 p-0">
                            <h1>Request Status</h1>
                        </div>
                        <div className="col-sm-3 align-end m-0 p-0">
                            <input
                                type="text"
                                className="mt-1 me-4 form-control table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Requests.."
                                value={searchValue}
                                disabled={filterLocations.length === 0}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                    {filterLocations ? (
                        <>
                            <div className="row d-flex mb-2 justify-content-start m-0 p-0">
                                <div className="col-sm-12 m-0 p-0">
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${selectedTab === "Outgoing" ? "active" : ""}`}
                                                onClick={() => handleTabChange("Outgoing")}
                                            >
                                                Outgoing Requests ({requestDataLen().length || 0})
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${selectedTab === "Incoming" ? "active" : ""}`}
                                                onClick={() => handleTabChange("Incoming")}
                                            >
                                                Incoming Requests ({requestDataLenIn().length || 0})
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {isLoading ? (
                                <div className="d-flex justify-content-center m-5 p-5">
                                    <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="table-responsive">
                                        <table ref={componentRef} className="table-hover table-bordered order-table table">
                                            <thead>
                                                {selectedTab === 'Outgoing' ? (
                                                    <tr>
                                                        <th className="text-start ps-2">Inventory Name</th>
                                                        <th className="text-start">Requested Location</th>
                                                        <th className="text-start">Quantity</th>
                                                        <th className="text-start">Status</th>
                                                        <th className="text-start">Requested Date</th>
                                                        <th className="text-center">Status/Cancel</th>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <th className="text-start ps-2">Inventory Name</th>
                                                        <th className="text-start">Requesting Location</th>
                                                        <th className="text-start">Quantity</th>
                                                        <th className="text-start">Status</th>
                                                        <th className="text-start">Requested Date</th>
                                                        <th className="text-center">Status/Deny</th>
                                                    </tr>
                                                )}
                                            </thead>
                                            <tbody>
                                                {request.map((req, rdx) => (
                                                    <>
                                                        {locations.filter((location) => filterLocations.tech === location.tech).map((loc, leq) => (
                                                            <>
                                                                {inventory
                                                                    .filter((inventorys) =>
                                                                        ((selectedTab === 'Outgoing' && ((req.location === loc._id)))
                                                                            ||
                                                                            (selectedTab === 'Incoming' && ((req.toLocation === loc._id))))
                                                                        &&
                                                                        (req.inventoryId === inventorys._id)
                                                                    )
                                                                    .map((inventorys, idx) => (
                                                                        <tr key={idx}>
                                                                            <td className="align-middle text-start ps-2" style={{ maxWidth: '30rem', overflowX: 'auto' }}>
                                                                                <div className="w-100" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                                    {inventorys.name}
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {findUserData(req)}
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {req.quantity}
                                                                            </td>
                                                                            {req.status === 'Submitted' || req.status === 'Completed' ? (
                                                                                <td className="align-middle text-center">
                                                                                    <span className="bg-success-subtle border rounded border-success pe-1 ps-1">
                                                                                        {req.status}
                                                                                    </span>
                                                                                </td>
                                                                            ) : req.status === 'Pending Pickup' ? (
                                                                                <td className="align-middle text-center">
                                                                                    <span className="bg-warning-subtle border rounded border-warning pe-1 ps-1">
                                                                                        {req.status}
                                                                                    </span>
                                                                                </td>
                                                                            ) : (
                                                                                <td className="align-middle text-center">
                                                                                    <span className="bg-danger-subtle border rounded border-danger pe-1 ps-1">
                                                                                        {req.status}
                                                                                    </span>
                                                                                </td>
                                                                            )}
                                                                            <td className="align-middle text-center">
                                                                                {formattedDate(req.updatedAt)}
                                                                            </td>
                                                                            {inventorys.InventoryLocation.filter((loc) => loc.name1 === findUserData(req)).map((inv) => (
                                                                                <>
                                                                                    {selectedTab === 'Outgoing' ? (
                                                                                        <td className="align-middle text-center">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn-sm btn btn-outline-primary me-2"
                                                                                                onClick={() => handleCheckout(inventorys._id, inv, req)}
                                                                                                disabled={req.status === 'Canceled' || req.status === 'Denied' || req.status === 'Pending Pickup' || req.status === 'Complete'}
                                                                                            >
                                                                                                <GoPencil />
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn btn-sm btn-outline-danger"
                                                                                                disabled={req.status === 'Canceled' || req.status === 'Denied' || req.status === 'Complete'}
                                                                                                onClick={() => handleCan(req)}
                                                                                            >
                                                                                                <BsTrash />
                                                                                            </button>
                                                                                        </td>
                                                                                    ) : (
                                                                                        <>
                                                                                            {req.status === 'Submitted' || req.status === 'Canceled' || req.status === 'Denied' ? (
                                                                                                <td className="align-middle text-center">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn-sm btn btn-outline-success me-2"
                                                                                                        onClick={() => handleCheckoutIn(inventorys._id, inv, req)}
                                                                                                        disabled={req.status === 'Canceled' || req.status === 'Denied' || req.status === 'Pending Pickup' || req.status === 'Complete'}
                                                                                                    >
                                                                                                        <FaRegThumbsUp />
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="btn btn-sm btn-outline-danger"
                                                                                                        disabled={req.status === 'Canceled' || req.status === 'Denied' || req.status === 'Pending Pickup' || req.status === 'Complete'}
                                                                                                        onClick={() => handleDenyReq(req)}
                                                                                                    >
                                                                                                        <FaRegThumbsDown />
                                                                                                    </button>
                                                                                                </td>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {inventorys.InventoryLocation.filter((loc) => loc.name1 === findUserDataTech(req)).map((invOther) => (
                                                                                                        <td className="align-start ps-3 text-start">
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn-sm btn btn-outline-success me-2"
                                                                                                                onClick={() => handlePickUp(req, inv, invOther)}
                                                                                                                disabled={req.status === 'Canceled' || req.status === 'Denied' || req.status === 'Completed'}
                                                                                                            >
                                                                                                                <FaRegThumbsUp />
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    ))}
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </tr >
                                                                    ))}
                                                            </>
                                                        ))}
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <div className="mt-5">
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <MdOutlineInventory className="inventoryLogo fs-1" />
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <span>Currently no requests right now.</span> <br />
                                </div>
                            </div>
                        </div>
                    )}
                    {filterLocations && (
                        <div className="d-flex justify-content-center mb-3">
                            <button
                                className="btn btn-sm btn-outline-primary pe-2 ps-2 mr-2 p-0 m-0 me-3 loginButton"
                                disabled={page === 1}
                                onClick={handlePrevPage}
                            >
                                Previous
                            </button>
                            <span className="mr-2 me-3">
                                Page {page} of {Math.ceil(locations.length / rowsPerPage)}
                            </span>
                            <button
                                className="btn btn-sm btn-outline-primary pe-4 ps-4 mr-2 p-0 m-0 me-3 loginButton"
                                disabled={locations.length <= page * rowsPerPage}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
};

export default InventoryRequestStatusComponent;