import React from "react";
import { BsConeStriped } from 'react-icons/bs';

const DeleteConfirmationModal = ({ onConfirm, onCancel }) => {
    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel} // Close modal if clicked outside
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder bg-light deleteWidth mb-4">
                        <div className="col-sm fs-5"> 
                            Delete Job
                        </div>
                    </div>
                        <div className="row fw-bolder text-warning coneConfirJob mb-4">
                        <BsConeStriped />
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-sm-11 text-center">
                        <text className="fw-bold fs-5">Are You Sure?</text><br /><br/>
                        <text className="pt-2">
                            Deleting jobs is highly discouraged! <br/>
                            All history, payments and associated details will be removed!<br/>
                                You could just cancel the job instead.</text><br />
                    </div>
                    </div>
                    <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                        <div className="col-sm">
                            <button onClick={onConfirm} className="btn btn-outline-danger m-0 ps-5 pe-5">
                                Yes
                            </button>
                        </div>
                        <div className="col-sm">
                            <button className="btn btn-outline-primary ps-5 pe-5" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeleteConfirmationModal;
