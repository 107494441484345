import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useSelector } from 'react-redux';
import { VscGraphLine } from "react-icons/vsc";

const JobStatusGraphComponent = ({ fetchDispatch, fetchTechInfo }) => {
    const [chartData, setChartData] = useState({});
    const [tech, setTech] = useState([]);
    const [noData, setNoData] = useState(false);
    const [selectedDays, setSelectedDays] = useState(14);

    const auth = useSelector(state => state.auth)
    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isDispatcher = auth.user?.usertype?.includes('Dispatcher') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;
    const isAdminOrSuperAdmin = isAdmin || isSuperAdmin || isDispatcher;

    useEffect(() => {
        const abctrl = new AbortController();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - selectedDays);
        const formattedStartDate = startDate.toISOString();

        Promise.all([
            fetchTechInfo(abctrl.signal, formattedStartDate),
            fetchDispatch(abctrl.signal, formattedStartDate)
        ])
            .then(([techInfoResults, dispatchResults]) => {
                const filterTech = techInfoResults.filter(tech => tech.company === auth.user.company);

                if (auth.user) {
                    const techJobs = filterTech.find(tech => tech.user === auth.user._id);

                    if (techJobs) {

                        const filterDispatch = dispatchResults.filter((job) => job.techs.includes(techJobs._id));

                        if (techJobs !== undefined) {
                            setTech(techJobs);
                        }

                        if (isAdminOrSuperAdmin) {
                            const labels = [];
                            const jobsData = { open: [], closed: [], inProgress: [], canceled: [], pending: [], pendingApproval: [] };

                            for (let i = (selectedDays - 1); i >= 0; i--) {
                                const date = new Date();
                                date.setDate(date.getDate() - i);
                                const options = { month: 'short', day: 'numeric' };
                                const formattedDate = date.toLocaleDateString(undefined, options);
                                labels.push(formattedDate);

                                const jobsForDate = dispatchResults.filter(job => {
                                    const jobDate = new Date(job.updatedAt);
                                    return jobDate.getFullYear() === date.getFullYear() &&
                                        jobDate.getMonth() === date.getMonth() &&
                                        jobDate.getDate() === date.getDate();
                                });

                                jobsData.open.push(jobsForDate.filter(job => job.status === 'Submitted').length);
                                jobsData.closed.push(jobsForDate.filter(job => job.status === 'Done').length);
                                jobsData.inProgress.push(jobsForDate.filter(job => job.status === 'In Progress').length);
                                jobsData.canceled.push(jobsForDate.filter(job => job.status === 'Canceled').length);
                                jobsData.pending.push(jobsForDate.filter(job => job.status === 'Pending').length);
                                jobsData.pendingApproval.push(jobsForDate.filter(job => job.status === 'Done Pending Approval').length);
                            }

                            if (jobsData.open.every(val => val === 0) && jobsData.closed.every(val => val === 0)) {
                                setNoData(true);
                            } else {
                                setChartData({
                                    labels,
                                    datasets: [
                                        { label: 'Open Jobs', data: jobsData.open, backgroundColor: 'rgba(255, 236, 0, 0.8)' },
                                        { label: 'Closed Jobs', data: jobsData.closed, backgroundColor: 'rgba(45, 255, 0, 0.8)' },
                                        { label: 'In Progress', data: jobsData.inProgress, backgroundColor: 'rgba(124, 221, 221, 0.8)' },
                                        { label: 'Canceled Jobs', data: jobsData.canceled, backgroundColor: 'rgba(255, 0, 0, 0.8)' },
                                        { label: 'Pending Jobs', data: jobsData.pending, backgroundColor: 'rgba(255, 115, 0, 0.8)' },
                                        { label: 'Pending Approval', data: jobsData.pendingApproval, backgroundColor: 'rgba(0, 126, 214, 0.8)' },
                                    ]
                                });
                            }
                        } else {
                            const labels = [];
                            const jobsData = { open: [], closed: [], inProgress: [], canceled: [], pending: [], pendingApproval: [] };

                            for (let i = (selectedDays - 1); i >= 0; i--) {
                                const date = new Date();
                                date.setDate(date.getDate() - i);
                                const options = { month: 'short', day: 'numeric' };
                                const formattedDate = date.toLocaleDateString(undefined, options);
                                labels.push(formattedDate);

                                const jobsForDate = filterDispatch.filter(job => {
                                    const jobDate = new Date(job.updatedAt);
                                    return jobDate.getFullYear() === date.getFullYear() &&
                                        jobDate.getMonth() === date.getMonth() &&
                                        jobDate.getDate() === date.getDate();
                                });

                                jobsData.open.push(jobsForDate.filter(job => job.status === 'Submitted').length);
                                jobsData.closed.push(jobsForDate.filter(job => job.status === 'Done').length);
                                jobsData.inProgress.push(jobsForDate.filter(job => job.status === 'In Progress').length);
                                jobsData.canceled.push(jobsForDate.filter(job => job.status === 'Canceled').length);
                                jobsData.pending.push(jobsForDate.filter(job => job.status === 'Pending').length);
                                jobsData.pendingApproval.push(jobsForDate.filter(job => job.status === 'Done Pending Approval').length);
                            }

                            if (jobsData.open.every(val => val === 0) && jobsData.closed.every(val => val === 0)) {
                                setNoData(true);
                            } else {
                                setChartData({
                                    labels,
                                    datasets: [
                                        { label: 'Open Jobs', data: jobsData.open, backgroundColor: 'rgba(255, 236, 0, 0.8)' },
                                        { label: 'Closed Jobs', data: jobsData.closed, backgroundColor: 'rgba(45, 255, 0, 0.8)' },
                                        { label: 'In Progress', data: jobsData.inProgress, backgroundColor: 'rgba(124, 221, 221, 0.8)' },
                                        { label: 'Canceled Jobs', data: jobsData.canceled, backgroundColor: 'rgba(255, 0, 0, 0.8)' },
                                        { label: 'Pending Jobs', data: jobsData.pending, backgroundColor: 'rgba(255, 115, 0, 0.8)' },
                                        { label: 'Pending Approval', data: jobsData.pendingApproval, backgroundColor: 'rgba(0, 126, 214, 0.8)' },
                                    ]
                                });
                            }
                        }
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setNoData(true);
            });

        return () => abctrl.abort();
    }, [fetchDispatch, fetchTechInfo, selectedDays, auth]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
            title: {
                display: true,
                align: 'start',
                text: 'Jobs By Status:',
                font: {
                    size: 17,
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
            },
        },
    };

    return (
        <>
            {!noData ? (
                <div className="row">
                    {isAdminOrSuperAdmin ? (
                        <>
                            {chartData.labels && chartData.datasets ? (
                                <div className="mb-5">
                                    <div className="row d-flex justify-content-end">
                                        <div className="col-sm-1 m-0 p-0">
                                            <select className="form-select form-select-sm" value={selectedDays} onChange={(e) => setSelectedDays(Number(e.target.value))}>
                                                <option value={7}>This Week</option>
                                                <option value={14}>Last 14 Days</option>
                                                <option value={30}>This Month</option>
                                                <option value={90}>Last 3 Months</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <Bar className="piePayWidth" data={chartData} options={options} />
                                    </div>
                                </div>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </>
                    ) : (
                        <>
                            {tech && tech.user ? (
                                <>
                                    {chartData.labels && chartData.datasets ? (
                                        <div className="mb-5">
                                            <div className="row d-flex justify-content-end">
                                                <div className="col-sm-1 m-0 p-0">
                                                    <select className="form-select form-select-sm" value={selectedDays} onChange={(e) => setSelectedDays(Number(e.target.value))}>
                                                        <option value={7}>This Week</option>
                                                        <option value={14}>Last 14 Days</option>
                                                        <option value={30}>This Month</option>
                                                        <option value={90}>Last 3 Months</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <Bar className="piePayWidth" data={chartData} options={options} />
                                        </div>
                                    ) : (
                                        <p>Loading...</p>
                                    )}
                                </>
                            ) : (
                                <div className="row">
                                    <div className="pieSizeNon">
                                        <div className="row d-flex justify-content-start">
                                            <div className="col-sm-7 m-0 p-0">
                                                <h5><ins>Job Status:</ins></h5>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-start">
                                            <div className="col-sm-10 m-0 p-0">
                                                <span className="ms-3">User not assigned to a technician roles.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div >
            ) : (
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row d-flex justify-content-start">
                            <div className="col-sm-12 text-start m-0 p-0">
                                <h5><ins>Job Status:</ins></h5>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center m-0 p-0">
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <VscGraphLine className="inventoryLogo fs-1" />
                                </div>
                            </div>
                            <div className="row d-flex mt-3 justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <span>Currently no data to show.</span> <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default JobStatusGraphComponent;
