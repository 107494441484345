import axios from "axios";
import AdminDiscountCodesComponent from "./components/discountCodesComponent";

const fetchCodes = async (abctrl) => {
    const { data } = await axios.get("/api/discountCodes/admin", { withCredentials: true });
    return data
}

const deleteCodes = async (codesId) => {
    const { data } = await axios.delete(`/api/discountCodes/${codesId}`, { withCredentials: true });
    return data
}

const AdminDiscountCodesPage = () => {

    return <AdminDiscountCodesComponent fetchCodes={fetchCodes} deleteCodes={deleteCodes} />;
};

export default AdminDiscountCodesPage;
