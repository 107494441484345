import React, { useState, useEffect } from "react";
import { BsTrash } from 'react-icons/bs';
import { ImDrawer } from 'react-icons/im';
import { AiOutlinePlus } from 'react-icons/ai';
import '../assets/jobItemCss.css';
import { FaRegThumbsUp } from 'react-icons/fa';
import AddJobItemPopupComponent from "./addJobItemPopupComponent";
import DeleteJobItemPopModal from "./deleteJobItemPopComponent";
import DiscountPopupComponent from "./discountPopupComponent";
import { useSelector } from "react-redux";
import ItemProceBreakDownComponent from "./itemPriceBreakDownComponent";
import { MdOutlineInventory } from "react-icons/md";

const JobItemPanelComponent = ({ setJobLoading, totalPay, totalPrice, deleteJobItem, updateJobItemsApiRequest, updateInvoiceDiscount, updateDispatchTotal, createJobItemApiRequest, updateDispatchItemApiRequest, setTotalPrice, getJobTaxById, updateDispatchDiscountApiRequest, updateDispatchTaxApiRequest, fetchJobTax, fetchDispatch, fetchJobItem, id }) => {
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmation1, setShowConfirmation1] = useState(false);
    const [jobItems, setJobItems] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null); // New state to track the item to be deleted
    const [jobItemSource, setJobItemSource] = useState([]);
    const [dispatching, setDispatch] = useState([]);
    const [discountType, setDiscountType] = useState('percentage'); // 'percentage' or 'amount'
    const [discountValue, setDiscountValue] = useState(0); // Discount value
    const [selectedTax, setSelectedTax] = useState(null); // New state to store selected tax option
    const [jobTax, setJobTax] = useState([]);
    const [showDiscountModal, setShowDiscountModal] = useState(false); // State to control the discount modal
    const auth = useSelector(state => state.auth)
    const [selectedJobItem, setSelectedJobItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [createDispatchResponseState, setCreateDispatchResponseState] =
        useState({
            message: "",
            error: ""
        });

    useEffect(() => {
        const abctrl = new AbortController();
        if (loading) {

            fetchDispatch(id)
                .then((data) => {
                    setDispatch(data);
                    setJobItemSource(data.jobItems);
                    setDiscountType(data.discountType);
                    setDiscountValue(data.discount);

                    const taxId = data.tax || '';

                    getJobTaxById(taxId)
                        .then((data) => {
                            setSelectedTax(data)
                            setLoading(false);

                        })
                        .catch((er) => console.log(er));
                })
                .catch((er) => console.log(er));

            fetchJobItem(abctrl)
                .then((res) => {
                    setJobItems(res);
                    setLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            fetchJobTax(abctrl)
                .then((res) => {
                    setJobTax(res);
                    setLoading(false);

                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }

        return () => abctrl.abort();
    }, [loading, fetchDispatch, dispatching.tax, id, setLoading, fetchJobItem, fetchJobTax, getJobTaxById]); // Add loading as a dependency to trigger the effect when it changes

    useEffect(() => {
        const abctrl = new AbortController();
        setLoading(true);

        fetchDispatch(id)
            .then((data) => {
                setDispatch(data);
                setJobItemSource(data.jobItems);
                setDiscountType(data.discountType);
                setDiscountValue(data.discount);

                const taxId = data.tax || '';

                getJobTaxById(taxId)
                    .then((data) => {
                        setSelectedTax(data)
                        setLoading(false);

                    })
                    .catch((er) => console.log(er));

            })
            .catch((er) => console.log(er));

        fetchJobItem(abctrl)
            .then((res) => {
                setJobItems(res);
                setLoading(false); // Set loading to false after successful data fetch
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        fetchJobTax(abctrl)
            .then((res) => {
                setJobTax(res);
                setLoading(false);

            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        return () => abctrl.abort();
    }, [fetchDispatch, dispatching.tax, id, setLoading, fetchJobItem, fetchJobTax, getJobTaxById]);

    const handleItemChange = (selectedData) => {

        const matchedItem = jobItems.find((item) => {
            return (
                item.name === selectedData[0].name &&
                item.jobId === dispatching._id && !item.estimateId
            );
        });

        if (selectedData && selectedData[0].reuseable) {
            const formInputs = {
                name: selectedData[0].name,
                quantity: selectedData[0].quantity,
                price: selectedData[0].price,
                itemType: selectedData[0].itemType,
                markup: selectedData[0].markup || 0,
                cost: selectedData[0].cost,
                description: selectedData[0].description,
                taxable: selectedData[0].taxable || false,
                tag: selectedData[0].tag || 'Locksmith',
                type: selectedData[0].type || 'All',
                company: auth.user.company,
            };
            const formInputsSec = {
                name: selectedData[0].name,
                quantity: selectedData[0].quantity,
                price: selectedData[0].price,
                markup: selectedData[0].markup || 0,
                cost: selectedData[0].cost,
                itemType: selectedData[0].itemType,
                description: selectedData[0].description,
                taxable: selectedData[0].taxable || false,
                tag: selectedData[0].tag || 'Locksmith',
                type: selectedData[0].type || 'All',
                company: auth.user.company,
                jobId: dispatching._id,
            };
            createJobItemApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Job item created") {

                        createJobItemApiRequest(formInputsSec)
                            .then((data) => {
                                if (data.message === "Job item created") {

                                    const updatedJobItems = [...dispatching.jobItems, data._id];

                                    updateDispatchItemApiRequest(id, { jobItems: updatedJobItems, total: totalPrice })
                                        .then((data) => {
                                            if (data.message === "Dispatch job items updated") {
                                                setJobLoading(true);
                                                setShowAlert1(true);
                                                setTimeout(() => {
                                                    setShowAlert1(false);
                                                }, 3000);
                                                setLoading(true);
                                            }
                                        })
                                        .catch((er) => {
                                            setCreateDispatchResponseState({
                                                error: er.response.data.message
                                                    ? er.response.data.message
                                                    : er.response.data,
                                            });
                                        });
                                }
                            })
                            .catch((er) => {
                                setCreateDispatchResponseState({
                                    error: er.response.data.message
                                        ? er.response.data.message
                                        : er.response.data,
                                });
                            });
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        } else if (matchedItem && selectedData) {
            const formInputs = {
                name: selectedData[0].name || matchedItem.name,
                quantity: selectedData[0].quantity || matchedItem.quantity,
                price: selectedData[0].price || matchedItem.price,
                markup: selectedData[0].markup || matchedItem.markup,
                cost: selectedData[0].cost || matchedItem.cost,
                description: selectedData[0].description || matchedItem.description,
                taxable: selectedData[0].taxable,
                optional: false,
                itemType: selectedData[0].itemType,
                type: selectedData[0].type || 'All',
            };
            updateJobItemsApiRequest(matchedItem._id, formInputs)
                .then((data) => {
                    if (data.message === "Job item updated") {
                        const updatedJobItems = [...dispatching.jobItems, data._id];

                        if (totalPrice) {
                            updateDispatchItemApiRequest(id, { jobItems: updatedJobItems, total: totalPrice })
                                .then((data) => {
                                    if (data.message === "Dispatch job items updated") {
                                        setJobLoading(true);
                                        setShowAlert1(true);
                                        setTimeout(() => {
                                            setShowAlert1(false);
                                        }, 3000);
                                        setLoading(true);
                                    }
                                })
                                .catch((er) => {
                                    setCreateDispatchResponseState({
                                        error: er.response.data.message
                                            ? er.response.data.message
                                            : er.response.data,
                                    });
                                });
                        }
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        } else {
            const formInputs = {
                name: selectedData[0].name,
                quantity: selectedData[0].quantity,
                price: selectedData[0].price,
                markup: selectedData[0].markup,
                cost: selectedData[0].cost,
                itemType: selectedData[0].itemType,
                description: selectedData[0].description,
                taxable: selectedData[0].taxable || false,
                tag: selectedData[0].tag,
                type: selectedData[0].type || 'All',
                company: auth.user.company,
                optional: selectedData[0].optional,
                jobId: dispatching._id,
            };
            createJobItemApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Job item created") {

                        const updatedJobItems = [...dispatching.jobItems, data._id];

                        updateDispatchItemApiRequest(id, { jobItems: updatedJobItems, total: totalPrice })
                            .then((data) => {
                                if (data.message === "Dispatch job items updated") {
                                    setJobLoading(true);
                                    setShowAlert1(true);
                                    setTimeout(() => {
                                        setShowAlert1(false);
                                    }, 3000);
                                    setLoading(true);
                                }
                            })
                            .catch((er) => {
                                setCreateDispatchResponseState({
                                    error: er.response.data.message
                                        ? er.response.data.message
                                        : er.response.data,
                                });
                            });
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
    };

    const handleSubmitDiscount = (inputValue, inputType) => {

        setDiscountValue(inputValue);
        setDiscountType(inputType);

        setShowDiscountModal(false);

        const formInputsDiscount = {
            discount: inputValue,
            discountType: inputType,
        };

        updateDispatchDiscountApiRequest(id, formInputsDiscount)
            .then((data) => {
                if (data.message === "Dispatch tax updated") {
                    if (dispatching.invoice.length > 0) {
                        updateInvoiceDiscount(dispatching.invoice, formInputsDiscount)
                            .then((data) => {
                                if (data.message === "invoice discount updated") {
                                    setJobLoading(true);
                                    setShowAlert(true);
                                    setTimeout(() => {
                                        setShowAlert(false);
                                    }, 3000);

                                }
                            })
                            .catch((er) => {
                                setCreateDispatchResponseState({
                                    error: er.response.data.message
                                        ? er.response.data.message
                                        : er.response.data
                                });
                            });
                    } else {
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    }
                }
            })
            .catch((er) => {
                setCreateDispatchResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    useEffect(() => {
        if (showAlert1) {
            setTimeout(() => {
                setShowAlert1(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [showAlert1]);

    useEffect(() => {
        if (showAlert2) {
            setTimeout(() => {
                setShowAlert2(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [showAlert2]);

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [showAlert]);

    const handleRemoveItem = (itemId, event) => {
        event.stopPropagation();

        setItemToDelete(itemId); // Store the itemId of the item to be deleted
        setShowConfirmation1(true); // Show the confirmation modal
    };

    const handleConfirmRemoveItem = (itemId) => {
        const updatedJobItems = jobItemSource.filter((item) => item !== itemId);

        deleteJobItem(itemId)
            .then((res) => {
                if (res.message === "Job item deleted successfully") {
                    updateDispatchItemApiRequest(id, { jobItems: updatedJobItems })
                        .then((data) => {
                            if (data.message === "Dispatch job items updated") {
                                setJobLoading(true);
                                setShowConfirmation1(false);
                                setShowAlert1(true);
                                setTimeout(() => {
                                    setShowAlert1(false);
                                }, 3000);
                                setLoading(true);

                            }
                        })
                        .catch((er) => {
                            setCreateDispatchResponseState({
                                error: er.response.data.message
                                    ? er.response.data.message
                                    : er.response.data,
                            });
                        });
                }
            })
            .catch((er) => {
                setCreateDispatchResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data,
                });
            });


    };

    const handleCancelRemoveItem = () => {
        setShowConfirmation1(false);
    };
    const handleCancelDiscount = () => {
        setShowDiscountModal(false);
    };
    const handleCloseAlert1 = () => {
        setShowAlert1(false);
    };
    const handleCloseAlert2 = () => {
        setShowAlert2(false);
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleAddItem = (event) => {
        event.stopPropagation();
        setSelectedJobItem('');
        setShowConfirmation(true);
    };
    const handleCancelAddJobItem = () => {
        setShowConfirmation(false);
    };

    const handleTableRowClick = (jobItem, event) => {
        event.stopPropagation();

        setSelectedJobItem(jobItem);
        setShowConfirmation(true);

    };
    const filteredJobItems = jobItems.filter((item) => jobItemSource.includes(item._id));

    return (
        <div className="d-flex flex-column form-container">
            {showAlert1 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Action Preformed
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert1}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert2 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Totals Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert2}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Discount Changed Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showDiscountModal && (
                <DiscountPopupComponent
                    discountValue={discountValue}
                    discountType={discountType}
                    onCancel={handleCancelDiscount}
                    onConfirm={handleSubmitDiscount}
                />
            )}
            {showConfirmation && (
                <AddJobItemPopupComponent
                    fetchJobItem={fetchJobItem}
                    setSelectedJobItem={setSelectedJobItem}
                    selectedJobItem={selectedJobItem}
                    dispatching={dispatching}
                    onConfirm={handleItemChange}
                    onCancel={handleCancelAddJobItem}
                />
            )}
            {showConfirmation1 && (
                <DeleteJobItemPopModal
                    onConfirm={() => handleConfirmRemoveItem(itemToDelete)}
                    onCancel={handleCancelRemoveItem}
                />
            )}
            {loading ? (
                <p className="text-center">Loading...</p>
            ) : (
                <>
                    <div className="row justify-content-center mt-4">
                        <div className="col-sm-10">
                            <button type="button" className="btn btn-outline-primary addItemButton" onClick={(event) => handleAddItem(event)}><AiOutlinePlus /> Add Item</button>
                            <h5 className="mt-4">Job Items</h5>
                            {filteredJobItems.length > 0 ? (
                                <table className="mt-3 table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col" className="item-col ps-3">Item</th>
                                            <th scope="col" className="text-center">Quantity</th>
                                            <th scope="col" className="text-center">Price</th>
                                            <th scope="col" className="text-center">Cost</th>
                                            <th scope="col" className="text-center">Amount</th>
                                            <th scope="col" className="text-center">Taxable</th>
                                            <th scope="col" className="text-center fs-4"><ImDrawer /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredJobItems.map((item, index) => (
                                            <tr key={item._id} onClick={(event) => handleTableRowClick(item, event)}>
                                                <th scope="row" className="text-center"></th>
                                                <td>
                                                    {item.name}
                                                    <br />
                                                    <span className="fs-6 ps-2">{item.description}</span>
                                                    <br />
                                                    <button type="button" className="serviceTypeButton btn btn-outline-primary btn-sm" disabled>{item.itemType}</button>
                                                </td>
                                                <td className="text-center">{item.quantity}</td>
                                                <td className="text-center">${item.price}</td>
                                                <td className="text-center">${item.cost}</td>
                                                <td className="text-center">${item.price * item.quantity}</td>
                                                <td className="text-center">
                                                    {item.taxable === true ? (
                                                        <span>Yes</span>
                                                    ) : (
                                                        <span>No</span>
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    <button
                                                        className="btn btn-outline-danger"
                                                        onClick={(event) => handleRemoveItem(item._id, event)}
                                                    >
                                                        <BsTrash />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="row d-flex justify-content-center m-0 p-0 mb-5">
                                    <div className="row d-flex justify-content-center m-0 p-0">
                                        <div className="col-sm-4 m-0 p-0 text-center">
                                            <MdOutlineInventory className="inventoryLogo fs-1" />
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center m-0 p-0">
                                        <div className="col-sm-4 m-0 p-0 text-center">
                                            <span>Currently no job items. Click on the add item button to add them.</span> <br />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {filteredJobItems.length > 0 && (
                        <ItemProceBreakDownComponent
                            totalPay={totalPay}
                            setDiscountType={setDiscountType}
                            discountType={discountType}
                            selectedTax={selectedTax}
                            showDiscountModal={showDiscountModal}
                            jobTax={jobTax}
                            updateDispatchTotal={updateDispatchTotal}
                            setShowDiscountModal={setShowDiscountModal}
                            setSelectedTax={setSelectedTax}
                            setDiscountValue={setDiscountValue}
                            discountValue={discountValue}
                            setShowAlert2={setShowAlert2}
                            setLoading={setLoading}
                            setCreateDispatchResponseState={setCreateDispatchResponseState}
                            id={id}
                            loading={loading}
                            setJobLoading={setJobLoading}
                            updateDispatchTaxApiRequest={updateDispatchTaxApiRequest}
                            jobItems={jobItems}
                            jobItemSource={jobItemSource}
                            dispatching={dispatching}
                            setTotalPrice={setTotalPrice} />
                    )}
                </>
            )}
        </div>
    );
};

export default JobItemPanelComponent;
