import UserProfilePageComponent from "./components/UserProfilePageComponent";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setReduxUserState } from "../../redux/actions/userActions";

const updateUserApiRequest = async (formInputs, id) => {
    const { data } = await axios.put(`/api/users/profile/${id}`, { ...formInputs }, { withCredentials: true });
    return data;
};

const updateUserPassApiRequest = async (formData) => {
    const { data } = await axios.put("/api/users/password", { ...formData }, { withCredentials: true });
    return data;
};

const updateUser2FAApiRequest = async (formData) => {
    const { data } = await axios.put("/api/users/enable-2fa", { ...formData }, { withCredentials: true });
    return data;
};


const fetchUser = async (id) => {
    const { data } = await axios.get("/api/users/profile/" + id, { withCredentials: true });
    return data;
};

const fetch2FA = async (formInputs) => {
    const { data } = await axios.post("/api/users/send-2fa", { ...formInputs }, { withCredentials: true });
    return data;
};

const UserProfilePage = () => {
    const reduxDispatch = useDispatch();

    return (
        <UserProfilePageComponent
            updateUser2FAApiRequest={updateUser2FAApiRequest}
            fetch2FA={fetch2FA}
            updateUserPassApiRequest={updateUserPassApiRequest}
            updateUserApiRequest={updateUserApiRequest}
            fetchUser={fetchUser}
            setReduxUserState={setReduxUserState}
            reduxDispatch={reduxDispatch}
            localStorage={window.localStorage}
            sessionStorage={window.sessionStorage}
        />
    );
};

export default UserProfilePage;
