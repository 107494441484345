import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { FaRegThumbsUp } from 'react-icons/fa';
import 'jspdf-autotable';
import '../../components/assets/clientEstimate.css';
import { CiCalendar } from "react-icons/ci";
import { EstimateGeneratePDF } from '../../components/dispatch/generateEstimatePdfComponent';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ClientEstimatePaymentForm from '../../components/stripe/clientEstimatePaymentForm';
import axios from 'axios';

const stripePromise = loadStripe('pk_test_51NukCVEVP3HcTg1aHpVOHTyiSqxEfA1m4EGAqaFKBJwy5ssADis9ToSjjXCH3cT169Av456ZMWIp8qtT1oGvBORM00hBVNo5te');

const ClientEstimateComponent = ({ fetchUploadsAll, fetchDispatch, fetchPaymentAll, fetchCustomer, fetchCompany, updateEstimateSign, updateJobItemsApiRequest, updateEstimateStatus, fetchEstimates, fetchJobItem, fetchJobTax, }) => {
    const [estimate, setEstimate] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [companies, setCompanies] = useState([]);
    const { id } = useParams();
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [todayUS, setTodayUS] = useState('');
    const [jobItem, setJobItem] = useState([]);
    const [jobTax, setJobTax] = useState([]);
    const [selectedOptionalItems, setSelectedOptionalItems] = useState([]);
    const [showAlert1, setShowAlert1] = useState(false);
    const [signatureData, setSignatureData] = useState([{ signatureName: "", signature: "", dateTime: null }]);
    const [dispatch, setDispatch] = useState([]);
    const [totalPay, setTotalPay] = useState("0.00");
    const [totalPaystat, setTotalPaystat] = useState(false);
    const [uploadedLogo, setUploadedLogo] = useState(null);

    const [ setCreateEstimateResponseState] =
        useState({
            message: "",
            error: ""
        });

    const calculateSubtotal = () => {
        return jobItem.reduce((acc, item) => {
            if (!item.optional || selectedOptionalItems.includes(item._id)) {
                return acc + item.price * item.quantity;
            }
            return acc;
        }, 0);
    };

    let depositValue = 0;
    if (estimate.depositType === 'percentage') {
        depositValue = (estimate.deposit / 100) * calculateSubtotal();
    } else if (estimate.depositType === 'amount') {
        depositValue = estimate.deposit;
    }

    const handleOptionalItemChange = (itemId) => {
        if (selectedOptionalItems.includes(itemId)) {
            setSelectedOptionalItems(selectedOptionalItems.filter((id) => id !== itemId));
            setLoading(true);
        } else {
            setSelectedOptionalItems([...selectedOptionalItems, itemId]);
            setLoading(true);
        }
    };

    const calculateTaxableAmount = () => {
        const taxableItems = jobItem.filter(item => item.taxable && (!item.optional || selectedOptionalItems.includes(item._id)));

        const taxableAmount = taxableItems.reduce((acc, item) => {
            return acc + item.price * item.quantity;
        }, 0);

        if (jobTax !== null) {
            const taxRate = jobTax.taxRate;
            const taxAmount = parseFloat((taxableAmount * taxRate / 100).toFixed(2));
            return taxableAmount + taxAmount;
        }

        return taxableAmount;
    };

    const calculateTax = () => {
        const taxableAmount = calculateTaxableAmount();

        if (jobTax !== null) {
            const taxRate = jobTax.taxRate;
            const tax = (taxableAmount * taxRate) / 100;
            const roundedTax = tax.toFixed(3);

            return parseFloat(roundedTax);
        }
        return 0;
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const taxAmount = calculateTax();

        const subtotalWithDiscount = subtotal - depositValue;
        const total = subtotalWithDiscount + taxAmount;

        const optionalTotal = selectedOptionalItems.reduce((acc, itemId) => {
            const optionalItem = jobItem.find(item => item._id === itemId);
            if (optionalItem && !optionalItem.taxable) {
                return acc + (optionalItem.price * optionalItem.quantity);
            }
            return acc;
        }, 0);

        return total + optionalTotal;
    };

    useEffect(() => {
        const abctrl = new AbortController();

        const fetchData = async () => {
            try {
                const res = await fetchEstimates(id);
                setEstimate(res);
                setSignatureData(res.signature);

                const today = new Date(res.updatedAt);
                const options = { year: 'numeric', month: 'short', day: 'numeric' };
                const sentDate = today.toLocaleString('en-US', options);
                setTodayUS(sentDate);

                const companyData = await fetchCompany();
                const filteredCompany = companyData.find((item) => item._id === res.company);
                setCompanies(filteredCompany);

                const jobItemsData = await fetchJobItem();
                const filterJobItem = jobItemsData.filter((item) => item.estimateId === id);
                setJobItem(filterJobItem);

                const jobTaxData = await fetchJobTax();
                const filterJobTax = jobTaxData.find((item) => item._id === res.tax);
                setJobTax(filterJobTax);

                const customerData = await fetchCustomer(res.customer);
                setCustomer(customerData);

                const dispatchData = await fetchDispatch(res.jobId);
                setDispatch(dispatchData);

                const companyLogo = await fetchUploadsAll();
                const filteredUploadsall = companyLogo.filter((item) => res.company === item.company);
                const filteredUploadsFin = filteredUploadsall.find((item) => item.companyLogo === true);

                if (filteredUploadsFin) {
                    setUploadedLogo(filteredUploadsFin);
                } else {
                    setUploadedLogo(null);
                }

                const paymentData = await fetchPaymentAll();
                const filterPayments = paymentData.filter((item) => item.estimateId === dispatchData.estimates);
                const calculatedTotalAmount = filterPayments.reduce((acc, payment) => {
                    if (payment.status !== 'Refunded') {
                        return acc + payment.amount;
                    }
                    return acc;
                }, 0);
                const formattedTotalAmount = (calculatedTotalAmount / 100).toFixed(2);
                const deposit = depositValue.toFixed(2);

                if (parseFloat(deposit) - parseFloat(formattedTotalAmount) === 0.0) {
                    setTotalPaystat(true);
                    setTotalPay('0.00');
                } else {
                    setTotalPay((parseFloat(deposit) - parseFloat(formattedTotalAmount)).toFixed(2));
                    setTotalPaystat(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => abctrl.abort();
    }, [fetchEstimates, id, fetchCompany, fetchJobItem, fetchJobTax, fetchCustomer, fetchDispatch, fetchPaymentAll, depositValue, fetchUploadsAll]);

    useEffect(() => {
        if (loading) {
            const abctrl = new AbortController();
            const fetchData = async () => {
                try {
                    const res = await fetchEstimates(id);
                    setEstimate(res);
                    setSignatureData(res.signature);

                    const today = new Date(res.updatedAt);
                    const options = { year: 'numeric', month: 'short', day: 'numeric' };
                    const sentDate = today.toLocaleString('en-US', options);
                    setTodayUS(sentDate);

                    const companyData = await fetchCompany();
                    const filteredCompany = companyData.find((item) => item._id === res.company);
                    setCompanies(filteredCompany);

                    const jobItemsData = await fetchJobItem();
                    const filterJobItem = jobItemsData.filter((item) => item.estimateId === id);
                    setJobItem(filterJobItem);

                    const jobTaxData = await fetchJobTax();
                    const filterJobTax = jobTaxData.find((item) => item._id === res.tax);
                    setJobTax(filterJobTax);

                    const customerData = await fetchCustomer(res.customer);
                    setCustomer(customerData);

                    const dispatchData = await fetchDispatch(res.jobId);
                    setDispatch(dispatchData);

                    const companyLogo = await fetchUploadsAll();
                    const filteredUploadsall = companyLogo.filter((item) => res.company === item.company);
                    const filteredUploadsFin = filteredUploadsall.find((item) => item.companyLogo === true);

                    if (filteredUploadsFin) {
                        setUploadedLogo(filteredUploadsFin);
                    } else {
                        setUploadedLogo(null);
                    }

                    const paymentData = await fetchPaymentAll();
                    const filterPayments = paymentData.filter((item) => item.estimateId === dispatchData.estimates);
                    const calculatedTotalAmount = filterPayments.reduce((acc, payment) => {
                        if (payment.status !== 'Refunded') {
                            return acc + payment.amount;
                        }
                        return acc;
                    }, 0);
                    const formattedTotalAmount = (calculatedTotalAmount / 100).toFixed(2);
                    const deposit = depositValue.toFixed(2);

                    if (parseFloat(deposit) - parseFloat(formattedTotalAmount) === 0.0) {
                        setTotalPaystat(true);
                        setTotalPay('0.00');
                    } else {
                        setTotalPay((parseFloat(deposit) - parseFloat(formattedTotalAmount)).toFixed(2));
                        setTotalPaystat(false);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
            return () => abctrl.abort();
        }
    }, [loading, id, fetchEstimates, fetchCompany, fetchJobItem, fetchJobTax, fetchCustomer, fetchDispatch, fetchPaymentAll, depositValue, fetchUploadsAll]);

    const handleSubmitDecline = () => {
        const formInputsStat = {
            status: 'Denied'
        };

        updateEstimateStatus(id, formInputsStat)
            .then((data) => {
                if (data.message === "estimate updated") {
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                    setLoading(true);
                }
            })
            .catch((er) => {
                setCreateEstimateResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const handleSubmitApprove = () => {
        const formInputsStat = {
            status: 'Won'
        };

        updateEstimateStatus(id, formInputsStat)
            .then((data) => {
                if (data.message === "estimate updated") {
                    setShowAlert1(true);
                    setTimeout(() => {
                        setShowAlert1(false);
                    }, 3000);
                    setLoading(true);
                }
            })
            .catch((er) => {
                setCreateEstimateResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    function getBase64FromBlob(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    const handleGeneratePDF = async () => {
        try {
            const data = await fetchJobTax();
            const filterJobTax = data.find((item) => item._id === estimate.tax);

            if (!filterJobTax) {
                console.error("Job tax not found for estimate.tax:", estimate.tax);
                return;
            }

            let imgData = null;
            if (uploadedLogo) {
                try {
                    const response = await axios.get(`/api/uploads/download/${uploadedLogo.file}`, { responseType: 'blob' });
                    const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
                    imgData = await getBase64FromBlob(blob);
                } catch (error) {
                    console.error('Error fetching image:', error);
                    // Optionally handle the error (e.g., show a placeholder image)
                }
            }

            EstimateGeneratePDF(
                companies,
                imgData,
                formatDateTime,
                estimate,
                filterJobTax,
                customer,
                jobItem,
                signatureData,
            );
        } catch (error) {
            console.error("Error fetching or processing job tax:", error);
        }
    };

    function formatDateTime(dateTime) {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };

        return new Date(dateTime).toLocaleDateString(undefined, options);
    }

    const handleCloseAlert1 = () => {
        setShowAlert1(false);
    };


    return (
        <Elements stripe={stripePromise}>
            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light pt-5 pb-4">
                    <span className="ms-3 fs-4">{companies.name}</span>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto me-4">
                            <li className="nav-item">
                                <Link className="btn btn-outline-primary btnUpload loginButton" onClick={() => handleGeneratePDF()}>Download PDF</Link>
                            </li>
                        </ul>

                    </div>
                </nav>
                <div className="container-fluid mt-5 mb-5 pb-5">
                    {showAlert && (
                        <div className="alert alert-success" role="alert">
                            <FaRegThumbsUp className="me-3 mb-2" />
                            Estimate Declined Successfully
                            <div className="alert-line-container">
                                <div className="alert-line"></div>
                                <span className="alert-close" onClick={handleCloseAlert}>
                                    X
                                </span>
                            </div>
                        </div>
                    )}
                    {showAlert1 && (
                        <div className="alert alert-success" role="alert">
                            <FaRegThumbsUp className="me-3 mb-2" />
                            Estimate Approved Successfully
                            <div className="alert-line-container">
                                <div className="alert-line"></div>
                                <span className="alert-close" onClick={handleCloseAlert1}>
                                    X
                                </span>
                            </div>
                        </div>
                    )}

                    <div className="row justify-content-start">
                        <div className="col-sm-6 docCardInvoice">
                            <div className="row m-0 p-0 justify-content-between headerCard">
                                <div className="col-sm-6 p-0 m-0 imgCard">
                                    {uploadedLogo && (
                                        <img className="imgDocCard" src={uploadedLogo.imageUrl} alt="Company Logo" />
                                    )}
                                </div>
                                <div className="col-sm-4">
                                    <span className="fs-1 fw-bold">ESTIMATE</span>
                                </div>
                            </div>
                            <div className="row mt-5 p-0 headerCard justify-content-between">
                                <div className="col-sm-5 ms-4 fw-light p-0 m-0 ">
                                    <span className="row">{companies.name}</span>
                                    <span className="row">{companies.address}</span>
                                    <span className="row">{companies.city}, {companies.state} {companies.zip}, {companies.country}</span>
                                    <span className="row">{companies.email}</span>
                                    <span className="row">{companies.phone}</span>
                                </div>
                                <div className="col-sm-6 p-0 m-0 ">
                                    <table className="ms-4" width="100%">
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'right' }}>
                                                    <strong>Estimate #</strong>
                                                </td>
                                                <td style={{ textAlign: 'left', paddingLeft: '30px' }} className="fw-light">{estimate.count}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'right' }}>
                                                    <strong>Date</strong>
                                                </td>
                                                <td style={{ textAlign: 'left', paddingLeft: '30px' }} className="fw-light">{formatDateTime(estimate.updatedAt)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'right' }}>
                                                    <strong>Total</strong>
                                                </td>
                                                <td style={{ textAlign: 'left', paddingLeft: '30px' }} className="fw-light">${calculateTotal().toFixed(2)}</td>
                                            </tr>
                                            {depositValue > 0 && (
                                                <tr>
                                                    <td style={{ textAlign: 'right' }}>
                                                        <strong>Required Deposit</strong>
                                                    </td>
                                                    <td style={{ textAlign: 'left', paddingLeft: '30px' }} className="fw-light">${depositValue.toFixed(2)}</td>
                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div className="row mt-5 p-0 ps-4 headerCard justify-content-start">
                                <div className="col-sm-6 p-0 m-0 ">
                                    <span className="row">Prepared For:</span>
                                    <span className="row fw-light ps-3">{customer.name} {customer.lastName}</span>
                                    <span className="row fw-light ps-3">{customer.address}</span>
                                    <span className="row fw-light ps-3">{customer.city}, {customer.state} {customer.zip}, {customer.country}</span>
                                    <span className="row fw-light ps-3">{customer.phone}</span>
                                    <span className="row fw-light ps-3">{customer.email}</span>
                                </div>
                            </div>
                            <div className="row mt-5 p-0 m-0 headerCard justify-content-center">
                                <div className="col-sm-12 p-0 m-0 ">
                                    <table className="table table-striped">
                                        <thead className="border-top border-bottom border-dark">
                                            <tr>
                                                <th scope="col" style={{ width: '65%' }}>Description</th>
                                                <th scope="col" className="text-center">QTY</th>
                                                <th scope="col" className="text-center">Price</th>
                                                <th scope="col" className="text-center">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className="fw-light">
                                            {jobItem.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.optional ? (
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`checkbox-${item._id}`}
                                                                    checked={selectedOptionalItems.includes(item._id)}
                                                                    onChange={() => handleOptionalItemChange(item._id)}
                                                                />
                                                                <label className="form-check-label" htmlFor={`checkbox-${item._id}`}>
                                                                    {item.name}
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {item.name}
                                                            </>
                                                        )}
                                                        <br />
                                                        {item.description}
                                                    </td>
                                                    <td className="text-center">{item.quantity}</td>
                                                    <td className="text-center">${item.price}</td>
                                                    <td className="text-center">${(item.price * item.quantity).toFixed(2)} <br /> {item.optional && (<span className="text-primary">Optional</span>)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row p-0 headerCard justify-content-end">
                                    <div className="col-sm-5 p-0 m-0 ">
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <td className="pe-5 text-end">
                                                        <strong>Sub Total</strong>
                                                        <br />
                                                        {estimate.discount > 0 && (
                                                            <>
                                                                <strong>Discount</strong>
                                                                <br />
                                                            </>
                                                        )}
                                                        <strong>Tax</strong>
                                                        <br />
                                                        <strong>Tax Rate</strong>
                                                        <br />
                                                        <strong>Total</strong>
                                                    </td>
                                                    <td className="fw-light ps-2 border-start border-dotted">
                                                        {calculateSubtotal().toFixed(2)}
                                                        <br />
                                                        {estimate.discount > 0 && (
                                                            <>
                                                                {estimate.discount.toFixed(2)}
                                                                < br />
                                                            </>
                                                        )}
                                                        {calculateTax().toFixed(2)}
                                                        <br />
                                                        {jobTax.taxRate}%
                                                        <br />
                                                        {calculateTotal().toFixed(2)}
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5 p-0 m-0 headerCard justify-content-center">
                                <div className="col-sm-12 p-0 m-0 ">
                                    <span className="fw-bold row">
                                        Terms:
                                    </span>
                                    <span className="row ps-3 pe-3 fw-light">
                                        Estimates are an approximation of charges to you, and they are based on the anticipated details of the work to be done. It is possible for unexpected complications to cause some deviation
                                        from the estimate.  If additional parts or labor are required you will be contacted immediately.
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-3 p-0 m-0 headerCard justify-content-center">
                                <div className="col-sm-12 p-0 m-0 ">
                                    <span className="fw-bold row">
                                        Notes:
                                    </span>
                                    <span className="row ps-3 fw-light">
                                        {estimate.notes}
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-5 p-0 m-0 headerCard text-center">
                                <div className="col-sm-12 p-0 m-0 ">
                                    <span className="fs-2 m-0 p-0 fw-light">
                                        Thank You For Your Business
                                    </span>
                                </div>
                            </div>
                            {signatureData && signatureData.length > 0 && (
                                <div className="row mt-5 p-0 m-0 headerCard justify-content-center">
                                    <div className="col-sm-12 p-0 m-0 ">
                                        <table className="table table-striped" id="mytable">
                                            <thead className="border-top border-bottom border-dark">
                                                <tr>
                                                    <th scope="col" style={{ width: '55%' }}>Signature</th>
                                                    <th scope="col" className="text-center">Signed By</th>
                                                    <th scope="col" className="text-center">Signed</th>
                                                </tr>
                                            </thead>
                                            <tbody className="fw-light">
                                                {signatureData.map((signatureItem, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <img src={signatureItem.signature} className="signatureImage" alt="Signature Data" />
                                                        </td>
                                                        {signatureItem && signatureItem.signatureName && (
                                                            <td className="text-center">{signatureItem.signatureName}</td>
                                                        )}
                                                        <td className="text-center">{formatDateTime(signatureItem.dateTime)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                        {!totalPaystat && estimate.status !== 'Denied' && estimate.status !== 'Won' && estimate.status === 'Pending' ? (
                            <div className="col-sm-4 ms-5">
                                <div className="code-container p-3">
                                    <h3 className="row invoiceTextHeader d-flex justify-content-between mb-3 pt-2">
                                        <div className="col-sm-4 text-start m-0 p-0">
                                            Estimate# {estimate.count}
                                        </div>
                                        <div className="col-sm-8 text-end">
                                            <span className="yellowBackground pe-2 ps-2">
                                                AWAITING APPROVAL
                                            </span>
                                        </div>
                                    </h3>
                                    <h3 className="row d-flex justify-content-start invoiceText mb-3 p-0">
                                        <div className="col-sm m-0 text-dark p-0">
                                            <CiCalendar className="me-1 mb-1" style={{ width: '1rem', height: '1rem' }} />
                                            Sent: {todayUS}
                                        </div>
                                    </h3>
                                    <hr />
                                    <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                        <div className="col-sm-4 text-end m-0 p-0">
                                            Total:
                                            <span className="fw-bold ms-2">${calculateTotal().toFixed(2)}</span>
                                        </div>
                                    </h3>
                                    <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                        <div className="col-sm-6 text-end me-2 m-0 p-0">
                                            Required Deposit:
                                            <span className="fw-bold ms-2">${depositValue.toFixed(2)}</span>
                                        </div>
                                    </h3>
                                </div>
                                {depositValue > 0 ? (
                                    <>
                                        <div className="row justify-content-center mt-4">
                                            <span className="text-center w-10 fancy-or-invoice">Payment</span>
                                        </div>
                                        <ClientEstimatePaymentForm
                                            estimateId={estimate._id}
                                            setLoading={setLoading}
                                            totalPay={totalPay}
                                            dispatching={dispatch}
                                            handleSubmitDecline={handleSubmitDecline}
                                            companies={companies}
                                            companyId={companies._id}
                                            jobItem={jobItem}
                                            selectedOptionalItems={selectedOptionalItems}
                                            signatureData={signatureData}
                                            updateJobItemsApiRequest={updateJobItemsApiRequest}
                                            updateEstimateStatus={updateEstimateStatus}
                                            setShowAlert1={setShowAlert1}
                                            setCreateEstimateResponseState={setCreateEstimateResponseState}
                                            setSignatureData={setSignatureData}
                                            updateEstimateSign={updateEstimateSign}
                                        />
                                    </>
                                ) : (
                                    <div className="col-sm-8 m-0 p-0">
                                        <div className="row m-0 p-0 w-100 justify-content-center mt-4">
                                            <div className="col-sm-7 text-end me-2 m-0 p-0">
                                                <button className="btn btn-outline-primary me-2 loginButton" onClick={() => handleSubmitApprove()}>Approve Estimate</button>
                                            </div>
                                            <div className="col-sm-4 text-start m-0 p-0">
                                                <button className="btn btn-outline-danger loginButton" onClick={() => handleSubmitDecline()}>Decline</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : totalPaystat && estimate.status !== 'Denied' && estimate.status !== 'Won' && estimate.status === 'Pending' ? (
                            <div className="col-sm-4 ms-5">
                                <div className="code-container p-3">
                                    <h3 className="row invoiceTextHeader d-flex justify-content-between mb-3 pt-2">
                                        <div className="col-sm-4 text-start m-0 p-0">
                                            Estimate# {estimate.count}
                                        </div>
                                        <div className="col-sm-8 text-end">
                                            <span className="yellowBackground pe-2 ps-2">
                                                AWAITING APPROVAL
                                            </span>
                                        </div>
                                    </h3>
                                    <h3 className="row d-flex justify-content-start invoiceText mb-3 p-0">
                                        <div className="col-sm m-0 text-dark p-0">
                                            <CiCalendar className="me-1 mb-1" style={{ width: '1rem', height: '1rem' }} />
                                            Sent: {todayUS}
                                        </div>
                                    </h3>
                                    <hr />
                                    <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                        <div className="col-sm-4 text-end m-0 p-0">
                                            Total:
                                            <span className="fw-bold ms-2">${calculateTotal().toFixed(2)}</span>
                                        </div>
                                    </h3>
                                    <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                        <div className="col-sm-6 text-end me-3 m-0 p-0">
                                            Required Deposit:
                                            <span className="fw-bold ms-2">$0.00</span>
                                        </div>
                                    </h3>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-sm-8 m-0 p-0">
                                        <div className="row m-0 p-0 w-100 justify-content-center mt-4">
                                            <div className="col-sm-7 text-end me-2 m-0 p-0">
                                                <button className="btn btn-outline-primary me-2 loginButton" onClick={() => handleSubmitApprove()}>Approve Estimate</button>
                                            </div>
                                            <div className="col-sm-4 text-start m-0 p-0">
                                                <button className="btn btn-outline-danger loginButton" onClick={() => handleSubmitDecline()}>Decline</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : totalPaystat && estimate.status !== 'Denied' && estimate.status === 'Won' ? (
                            <div className="col-sm-4 ms-5">
                                <div className="code-container p-3">
                                    <h3 className="row invoiceTextHeader d-flex justify-content-between mb-3 pt-2">
                                        <div className="col-sm-4 text-start m-0 p-0">
                                            Estimate# {estimate.count}
                                        </div>
                                        <div className="col-sm-8 text-end">
                                            <span className="greenBackground pe-2 ps-2">
                                                APPROVED
                                            </span>
                                        </div>
                                    </h3>
                                    <h3 className="row d-flex justify-content-start invoiceText mb-3 p-0">
                                        <div className="col-sm m-0 text-dark p-0">
                                            <CiCalendar className="me-1 mb-1" style={{ width: '1rem', height: '1rem' }} />
                                            Sent: {todayUS}
                                        </div>
                                    </h3>
                                    <hr />
                                    <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                        <div className="col-sm-4 text-end m-0 p-0">
                                            Total:
                                            <span className="fw-bold ms-2">${calculateTotal().toFixed(2)}</span>
                                        </div>
                                    </h3>
                                    <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                        <div className="col-sm-6 text-end me-3 m-0 p-0">
                                            Required Deposit:
                                            <span className="fw-bold ms-2">$0.00</span>
                                        </div>
                                    </h3>
                                </div>
                            </div>
                        ) : (
                            <div className="col-sm-4 ms-5">
                                <div className="code-container p-3">
                                    <h3 className="row invoiceTextHeader d-flex justify-content-between mb-3 pt-2">
                                        <div className="col-sm-4 text-start m-0 p-0">
                                            Estimate# {estimate.count}
                                        </div>
                                        <div className="col-sm-8 text-end">
                                            <span className="greenBackground pe-2 ps-2">
                                                DECLINED
                                            </span>
                                        </div>
                                    </h3>
                                    <h3 className="row d-flex justify-content-start invoiceText mb-3 p-0">
                                        <div className="col-sm m-0 text-dark p-0">
                                            <CiCalendar className="me-1 mb-1" style={{ width: '1rem', height: '1rem' }} />
                                            Sent: {todayUS}
                                        </div>
                                    </h3>
                                    <hr />
                                    <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                        <div className="col-sm-4 text-end m-0 p-0">
                                            Total:
                                            <span className="fw-bold ms-2">${calculateTotal().toFixed(2)}</span>
                                        </div>
                                    </h3>
                                    <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                        <div className="col-sm-6 text-end me-3 m-0 p-0">
                                            Required Deposit:
                                            <span className="fw-bold ms-2">$0.00</span>
                                        </div>
                                    </h3>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <footer>
                    <div className="container-fluid footer text-light">
                        <div className="row">
                            <div className="col text-center border-top pt-3 pb-3">
                                &copy; Copyrights. All rights reserved. Locksmith Menu inc.
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </Elements>
    );
};

export default ClientEstimateComponent;
