import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { FaRegThumbsUp } from 'react-icons/fa';
import TimeClockLinksComponent from "../../../components/timeClock/timeClockLinksComponent";
import LoadingComponent from "../../../components/loadingComponet";
import moment from 'moment';

const TimeEntryComponent = ({
    clockIn,
    clockStatus,
    clockOut,
    breakClockOut,
    breakClockIn,
    getSchedule
}) => {
    const [time, setTime] = useState('');
    const [location, setLocation] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAlertInBreak, setShowAlertInBreak] = useState(false);
    const [showAlertOutBreak, setShowAlertOutBreak] = useState(false);
    const [showAlertClockIn, setShowAlertClockIn] = useState(false);
    const [error, setError] = useState('');
    const [errorStat, setLoadingStat] = useState(false);

    const auth = useSelector(state => state.auth)

    useEffect(() => {
        const abctrl = new AbortController();

        setLoading(true);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ lat: latitude, lng: longitude });
                },
                error => {
                    setLoadingStat(true);
                    setError('Please enable your location to continue to clock in/out.');
                    console.error('Error getting location:', error);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0
                }
            );
        } else {
            setLoadingStat(true);
            setError('Please log into another device with location capabilities.');
        }

        const fetchData = async () => {
            try {
                const data = await clockStatus(auth.user._id);
                const scheduleDataResponse = await getSchedule();
                const todayDayName = moment().format('dddd');  // Using moment to get the day name
                const today = moment().startOf('day');

                const filteredSched = scheduleDataResponse.find((item) =>
                    (item.userId._id === auth.user._id) &&
                    (item.schedule.some(sched => sched.day === todayDayName) || item.overTime)
                );

                if (filteredSched) {
                    let conflictFound = false;  // Flag to indicate a conflict

                    filteredSched.time.forEach(timeItem => {
                        const timeStartDate = moment(timeItem.startDate).startOf('day');
                        const timeEndDate = moment(timeItem.endDate).endOf('day');

                        if (today.isBetween(timeStartDate, timeEndDate, 'day', '[]')) {
                            setError('Error: You cannot clock in because there is a time off scheduled for today.');
                            setLoading(false);
                            conflictFound = true;  // Set flag to true if conflict found
                        }
                    });

                    if (conflictFound) return;  // Early exit if there is a conflict

                    if (data) {
                        const matchingTime = data.find((item) => {
                            const clockInDate = moment(item.clockIn.time).startOf('day');
                            return clockInDate.isSame(today);  // Using moment for comparison
                        });

                        setTime(matchingTime ? matchingTime : '');
                        setLoading(false);
                    } else {
                        setTime('');
                        setLoadingStat(false);
                        setLoading(false);
                    }
                } else {
                    setLoadingStat(true);
                    setError(`You currently can't clock in. Today you are not scheduled to work.`);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }

        fetchData();

        return () => abctrl.abort();
    }, [clockStatus, getSchedule, auth.user._id]);

    useEffect(() => {
        const abctrl = new AbortController();

        if (loading) {
            setLoading(true);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const { latitude, longitude } = position.coords;
                        setLocation({ lat: latitude, lng: longitude });
                    },
                    error => {
                        setLoadingStat(true);
                        setError('Please enable your location to continue to clock in/out.');
                        console.error('Error getting location:', error);
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 10000,
                        maximumAge: 0
                    }
                );
            } else {
                setLoadingStat(true);
                setError('Please log into another device with location capabilities.');
            }

            const fetchData = async () => {
                try {
                    const data = await clockStatus(auth.user._id);
                    const scheduleDataResponse = await getSchedule();
                    const todayDayName = moment().format('dddd');  // Using moment to get the day name
                    const today = moment().startOf('day');
                    let current = today.clone().startOf('day');

                    const filteredSched = scheduleDataResponse.find((item) =>
                        (item.userId._id === auth.user._id) &&
                        (item.schedule.some(sched => sched.day === todayDayName) || item.overTime)
                    );

                    if (filteredSched) {
                        let conflictFound = false;  // Flag to indicate a conflict

                        filteredSched.time.forEach(timeItem => {
                            const timeStartDate = moment(timeItem.startDate).startOf('day');
                            const timeEndDate = moment(timeItem.endDate).endOf('day');

                            if (current.isBetween(timeStartDate, timeEndDate, 'day', '[]')) {
                                setLoadingStat(true);
                                setError('You cannot clock in because you requested time off that is scheduled for today.');
                                setLoading(false);
                                conflictFound = true;  // Set flag to true if conflict found
                            }
                        });

                        if (conflictFound) return;  // Early exit if there is a conflict

                        if (data) {
                            const matchingTime = data.find((item) => {
                                const clockInDate = moment(item.clockIn.time).startOf('day');
                                return clockInDate.isSame(today);  // Using moment for comparison
                            });

                            setTime(matchingTime ? matchingTime : '');
                            setLoading(false);
                        } else {
                            setTime('');
                            setLoadingStat(false);
                            setLoading(false);
                        }
                    } else {
                        setLoadingStat(true);
                        setError(`You currently can't clock in. Today you are not scheduled to work.`);
                        setLoading(false);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }

            fetchData();
        }
        return () => abctrl.abort();
    }, [clockStatus, getSchedule, loading, auth.user._id]);

    const handleClockIn = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formInputs = {
            userId: auth.user._id,
            clockIn: {
                time: new Date(),
                location: location,
            },
            company: auth.user.company,
        };

        clockIn(formInputs)
            .then((data) => {
                if (data.message === "Clocked in successfully") {

                    setShowAlertClockIn(true);
                    setTimeout(() => {
                        setShowAlertClockIn(false);
                    }, 3000);

                    setLoading(true);
                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data,
                });
            });
    };

    const handleClockOut = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formInputs = {
            location: location,
            userId: auth.user._id,
            clockOut: {
                time: new Date(),
                location: location,
            },
            company: auth.user.company,
        };

        clockOut(time._id, formInputs)
            .then((data) => {
                if (data.message === "Clocked out successfully") {
                    setLoading(true)
                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data,
                });
            });
    };

    const handleClockOutBreak = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formInputs = {
            location: location,
            userId: auth.user._id,
            breakClockOut: {
                time: new Date(),
                location: location,
            },
            company: auth.user.company,
        };

        breakClockOut(time._id, formInputs)
            .then((data) => {
                if (data.message === "Clocked out successfully") {

                    setShowAlertOutBreak(true);
                    setTimeout(() => {
                        setShowAlertOutBreak(false);
                    }, 3000);

                    setLoading(true)
                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data,
                });
            });
    };

    const handleClockInBreak = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formInputs = {
            location: location,
            userId: auth.user._id,
            breakClockIn: {
                time: new Date(),
                location: location,
            },
            company: auth.user.company,
        };

        breakClockIn(time._id, formInputs)
            .then((data) => {
                if (data.message === "Clocked in successfully") {

                    setShowAlertInBreak(true);
                    setTimeout(() => {
                        setShowAlertInBreak(false);
                    }, 3000);

                    setLoading(true)
                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data,
                });
            });
    };

    const handleCloseAlert = () => {
        setShowAlertOutBreak(false);
        setShowAlertInBreak(false);
        setShowAlertClockIn(false);
    };

    const formatDate = (dateString) => {
        const options = {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };

    return (
        <div className="min-vh-100">
            <div className="container-fluid min-vh-100 m-0 p-0">
                {showAlertOutBreak && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Have an amazing break {auth.user.name}!
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlertClockIn && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Welcome into work {auth.user.name}!
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlertInBreak && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Welcome back from break {auth.user.name}!
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}

                <div className="row min-vh-100 m-0 p-0">
                    <div className="col-sm-2 m-0 p-0">
                        <TimeClockLinksComponent />
                    </div>
                    {loading && (
                        <div className="loading-overlay">
                            <LoadingComponent />
                        </div>
                    )}
                    <div className="col-sm-10 mt-5 pt-5">
                        <div className="row d-flex justify-content-center">
                            <h1 className="text-center">Time Clock</h1>
                        </div>
                        {time.clockIn && (
                            <div className="row d-flex justify-content-center mt-5">
                                <div className="col-auto text-center">
                                    <span className="fw-bold">Clocked in at: </span>
                                    <span>{formatDate(time.clockIn.time)}</span>
                                </div>
                            </div>
                        )}
                        {time.breakClockOut && (
                            <div className="row d-flex justify-content-center mt-2">
                                <div className="col-auto text-center">
                                    <span className="fw-bold">Start of break: </span>
                                    <span>{formatDate(time.breakClockOut.time)}</span>
                                </div>
                            </div>
                        )}
                        {time.breakClockIn && (
                            <div className="row d-flex justify-content-center mt-2">
                                <div className="col-auto text-center">
                                    <span className="fw-bold">End of break: </span>
                                    <span>{formatDate(time.breakClockIn.time)}</span>
                                </div>
                            </div>
                        )}
                        {time.clockOut && (
                            <div className="row d-flex justify-content-center mt-2">
                                <div className="col-auto text-center">
                                    <span className="fw-bold">Clocked out at: </span>
                                    <span>{formatDate(time.clockOut.time)}</span>
                                </div>
                            </div>
                        )}
                        <div className="row justify-content-center mt-3">
                            <div className="col-sm-12 m-0 mt-3">
                                <div className="row justify-content-center mt-3">
                                    {!time.clockIn && (
                                        <div className="col-sm-3 text-center">
                                            <button disabled={time.clockIn || time.clockOut || errorStat} onClick={handleClockIn} className="btn btn-outline-primary loginButton mb-3">Clock In</button>
                                        </div>
                                    )}
                                    {!time.breakClockOut && time.clockIn && (
                                        <>
                                            <div className="col-sm-3 col-lg-2 text-center">
                                                <button disabled={time.clockOut || !time.clockIn || time.breakClockIn || time.breakClockOut || errorStat} onClick={handleClockOutBreak} className="btn btn-outline-primary mb-3">Take A Break</button>
                                            </div>
                                            <div className="col-sm-3 col-lg-2 text-center">
                                                <button disabled={time.clockOut || !time.clockIn || errorStat} onClick={handleClockOut} className="btn btn-outline-primary loginButton mb-3">Clock Out</button>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="row justify-content-center mt-2">
                                    {!time.breakClockIn && time.breakClockOut ? (
                                        <div className="col-sm-3 text-center">
                                            <button disabled={!time.clockIn || !time.breakClockOut || time.breakClockIn || errorStat} onClick={handleClockInBreak} className="btn btn-outline-primary loginButton mb-3">Clock Back In</button>
                                        </div>
                                    ) : (
                                        <>
                                            {!time.clockOut && time.clockIn && time.breakClockOut && (
                                                <div className="col-sm-3 text-center">
                                                        <button disabled={time.clockOut || !time.clockIn || errorStat} onClick={handleClockOut} className="btn btn-outline-primary loginButton mb-3">Clock Out</button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            {errorStat && error && (
                                <span className="text-center text-danger">{error}</span>
                            )}
                        </div>
                    </div>
                </div >
            </div>
        </div>
    );
};

export default TimeEntryComponent;