import axios from "axios";
import AdminMakeComponent from "./components/adminMakeComponent";

const fetchMake = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleMake/admin", { withCredentials: true });
    return data
}

const deleteMake = async (makeId) => {
    const { data } = await axios.delete(`/api/vehicleMake/${makeId}`, { withCredentials: true });
    return data
}

const AdminMakePage = () => {
    return <AdminMakeComponent fetchMake={fetchMake} deleteMake={deleteMake} />;
};

export default AdminMakePage;
