import axios from "axios";
import AdminInstructionComponent from "./components/adminInstructionComponent";

const fetchInstruction = async (abctrl) => {
    const { data } = await axios.get("/api/instruction/admin", { withCredentials: true });
    return data
}

const deleteInstruction = async (instructionId) => {
    const { data } = await axios.delete(`/api/instruction/${instructionId}`, { withCredentials: true });
    return data
}

const AdminInstructionPage = () => {

    return <AdminInstructionComponent fetchInstruction={fetchInstruction} deleteInstruction={deleteInstruction} />;
};

export default AdminInstructionPage;
