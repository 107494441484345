import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/userActions";
import '../assets/workSchedule.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TaskInfoPopupComponent = ({
    onCancel,
    requestEdit
}) => {
    const auth = useSelector(state => state.auth)
   
    const dispatch = useDispatch();
   
    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            {auth.user?.name ? (
                <div className="confirmation-box-request">
                    <div className="alertWhite alert-light text-start" role="alert">
                        <div>
                            <div className="popup">
                                <h2 className="popup-header text-center">Task Info</h2>
                                <div className="popup-content-scrollable">
                                    <div className="mt-4">
                                        <form className="row pt-4 g-3">
                                            <div className="m-0 p-0 mt-4">
                                                <label htmlFor="title">Title:</label>
                                                <input type="text"
                                                    defaultValue={requestEdit.title}
                                                    className="form-control"
                                                    id="title"
                                                    disabled
                                                    name="title"
                                                />
                                            </div>
                                            <div className="m-0 p-0 mt-4">
                                                <label htmlFor="name">Customer Name:</label>
                                                <input
                                                    type="text"
                                                    defaultValue={requestEdit.name}
                                                    className="form-control"
                                                    id="name"
                                                    disabled
                                                    name="name"
                                                />
                                            </div>
                                            <div className="m-0 p-0 mt-4">
                                                <label htmlFor="phone">Phone Number:</label>
                                                <input
                                                    type="text"
                                                    defaultValue={requestEdit.phone}
                                                    className="form-control"
                                                    id="phone"
                                                    disabled
                                                    name="phone"
                                                />
                                            </div>
                                            <div className="m-0 p-0 mt-4">
                                                <label htmlFor="email">Email:</label>
                                                <input
                                                    type="text"
                                                    defaultValue={requestEdit.email}
                                                    className="form-control"
                                                    id="email"
                                                    disabled
                                                    name="email"
                                                />
                                            </div>
                                            {requestEdit.location && (
                                                <div className="m-0 p-0 mt-4">
                                                    <label htmlFor="location">Location:</label>
                                                    <input
                                                        type="text"
                                                        defaultValue={requestEdit.location}
                                                        className="form-control"
                                                        id="location"
                                                        disabled
                                                        name="location"
                                                    />
                                                </div>
                                            )}
                                            {requestEdit.serviceSelect && (
                                                <div className="m-0 p-0 mt-4">
                                                    <label htmlFor="serviceSelect">Service Requested:</label>
                                                    <input
                                                        type="text"
                                                        defaultValue={requestEdit.serviceSelect}
                                                        className="form-control"
                                                        id="serviceSelect"
                                                        disabled
                                                        name="serviceSelect"
                                                    />
                                                </div>
                                            )}
                                            {requestEdit.dateSelect && (
                                                <div className="m-0 p-0 mt-4">
                                                    <label htmlFor="dateSelect">Date Requested:</label>
                                                    <input
                                                        type="text"
                                                        defaultValue={requestEdit.dateSelect}
                                                        className="form-control"
                                                        id="dateSelect"
                                                        disabled
                                                        name="dateSelect"
                                                    />
                                                </div>
                                            )}
                                            <div className="m-0 p-0 mt-4">
                                                <label htmlFor="description" className="form-label">Description</label>
                                                <textarea
                                                    defaultValue={requestEdit.description}
                                                    className="form-control"
                                                    id="description"
                                                    disabled
                                                    name="description"
                                                    rows="15"
                                                >
                                                </textarea>
                                            </div>
                                            <div className="m-0 p-0 mt-4">
                                                <label htmlFor="startDate" className="form-label">
                                                    Deadline:
                                                </label><br />
                                                <DatePicker
                                                    id="startDate"
                                                    className="date-picker-input-Task"
                                                    selected={new Date(requestEdit.deadline)}
                                                    disabled
                                                    dateFormat="MMMM d"
                                                    showTimeInput
                                                />
                                            </div>
                                            <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                                                <div className="col-sm-3">
                                                    <button className="btn btn-outline-primary ps-5 pe-5 loginButton" onClick={onCancel}>
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                dispatch(logout())
            )
            }
        </ >
    );
};

export default TaskInfoPopupComponent;