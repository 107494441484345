import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import RowPrintComponent from "../../../components/rowPrintComponent";
import InventoryLinksComponent from "../../../components/inventory/inventoryLinksComponent";
import InventoryRequestPopUpComponent from "../../../components/inventory/inventoryRequestComponent";
import { FaRegThumbsUp } from 'react-icons/fa';
import InventoryCheckOutComponent from "../../../components/inventory/inventoryCheckOutComponent";
import axios from "axios";
import InventoryEditInfoComponent from "../../../components/inventory/inventoryEditInfoComponent";
import { MdOutlineInventory2 } from "react-icons/md";
import { MdOutlinePersonOff } from "react-icons/md";

const InventoryToolComponent = ({ fetchInventory, createRequestApiRequest, fetchRequest, fetchLocation, fetchTechInfo, fetchUsers }) => {
    const [inventory, setInventory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const auth = useSelector(state => state.auth)
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedTab, setSelectedTab] = useState("Locksmith");
    const [request, setRequest] = useState([]);
    const [filterLocations, setFilterLocations] = useState('');
    const [locations, setLocations] = useState([]);
    const [inventorySet, setInventorySet] = useState([]);
    const [userData, setUserData] = useState([]);
    const [techData, setTechData] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [showAlertSuc, setShowAlertSuc] = useState(false);
    const [disableRequest, setDisableRequest] = useState({ filterLocation: null, filterDefault: null });
    const [showAlertEdit, setShowAlertEdit] = useState(false);
    const [selectedItemEdit, setSelectedItemEdit] = useState(null);
    const [showConfirmationTool, setShowConfirmationTool] = useState(false);
    const [selectedItemTool, setSelectedItemTool] = useState(null);
    const [tech, setTech] = useState([]);
    const [showAlertCheckTech, setShowAlertCheckTech] = useState(false);
    const [showAlertSucEdit, setShowAlertSucEdit] = useState(false);

    useEffect(() => {
        if (inventory.length > 0 && selectedLocation === null) {
            setSelectedLocation(inventory[0].InventoryLocation[0]?.name1);
        }
    }, [inventory, selectedLocation]);


    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);

        fetchInventory(abctrl)
            .then((res) => {
                const filteredInventory = res.filter((item) => item.company === auth.user.company);

                if (!selectedLocation) {
                    const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1
                    setSelectedLocation(locationSelect);

                    const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === locationSelect &&
                            location.quantity <= location.minimunStock1
                        )
                    );
                    setInventory(inventoryWithSelectedLocation);
                    setInventorySet(inventoryWithSelectedLocation)
                }

                if (selectedLocation) {
                    const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                    const inventoryWithSelectedLocationSet = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === locationSelect &&
                            location.quantity <= location.minimunStock1
                        )
                    );

                    const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === selectedLocation &&
                            location.quantity <= location.minimunStock1
                        )
                    );

                    setInventorySet(inventoryWithSelectedLocationSet)
                    setInventory(inventoryWithSelectedLocation);
                }

                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        fetchUsers()
            .then((data) => {
                const filteredUser = data.find((item) => item._id === auth.user._id);
                setUserData(data);

                fetchTechInfo()
                    .then((resData) => {
                        const filteredTech = resData.find((item) => item.user === filteredUser._id);
                        const filteredTechId = resData.filter((item) => item.company === auth.user.company);

                        setTech(filteredTech);
                        setTechData(filteredTechId);

                        fetchLocation()
                            .then((dataRes) => {
                                const filtered = dataRes.filter((item) => item.company === auth.user.company);

                                if (filteredTech) {
                                    const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);
                                    const filteredDefault = filtered.find((item) => item.defaultLoc);

                                    setDisableRequest({ filterLocation: filteredLocation._id, filterDefault: filteredDefault._id });
                                    setFilterLocations(filteredLocation);
                                }

                                setLocations(filtered);
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                        fetchRequest()
                            .then((dataResRes) => {
                                if (dataResRes.message !== 'request not found') {
                                    const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                    setRequest(filteredRequest)
                                } else {
                                    setRequest([])
                                }
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );

            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [fetchInventory, fetchLocation, fetchRequest, fetchTechInfo, fetchUsers, selectedLocation, auth.user._id, auth.user.company]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchInventory(abctrl)
                .then((res) => {
                    const filteredInventory = res.filter((item) => item.company === auth.user.company);

                    if (!selectedLocation) {
                        const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1
                        setSelectedLocation(locationSelect);

                        const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                            item.InventoryLocation.some((location) =>
                                location.name1 === locationSelect &&
                                location.quantity <= location.minimunStock1
                            )
                        );
                        setInventory(inventoryWithSelectedLocation);
                        setInventorySet(inventoryWithSelectedLocation)
                    }

                    if (selectedLocation) {
                        const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                        const inventoryWithSelectedLocationSet = filteredInventory.filter((item) =>
                            item.InventoryLocation.some((location) =>
                                location.name1 === locationSelect &&
                                location.quantity <= location.minimunStock1
                            )
                        );

                        const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                            item.InventoryLocation.some((location) =>
                                location.name1 === selectedLocation &&
                                location.quantity <= location.minimunStock1
                            )
                        );

                        setInventorySet(inventoryWithSelectedLocationSet)
                        setInventory(inventoryWithSelectedLocation);
                    }

                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            fetchUsers()
                .then((data) => {
                    const filteredUser = data.find((item) => item._id === auth.user._id);
                    setUserData(data);

                    fetchTechInfo()
                        .then((resData) => {
                            const filteredTech = resData.find((item) => item.user === filteredUser._id);
                            const filteredTechId = resData.filter((item) => item.company === auth.user.company);

                            setTech(filteredTech);
                            setTechData(filteredTechId);

                            fetchLocation()
                                .then((dataRes) => {
                                    const filtered = dataRes.filter((item) => item.company === auth.user.company);

                                    if (filteredTech) {
                                        const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);
                                        const filteredDefault = filtered.find((item) => item.defaultLoc);

                                        setDisableRequest({ filterLocation: filteredLocation._id, filterDefault: filteredDefault._id });
                                        setFilterLocations(filteredLocation);
                                    }

                                    setLocations(filtered);
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                            fetchRequest()
                                .then((dataResRes) => {
                                    if (dataResRes.message !== 'request not found') {
                                        const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                        setRequest(filteredRequest)
                                    } else {
                                        setRequest([])
                                    }
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );

                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [isLoading, fetchInventory, selectedLocation, auth.user.company, auth.user._id, fetchUsers, fetchTechInfo, fetchLocation, fetchRequest]);

    const handleCheckout = (id, item) => {
        setSelectedItem({ id, item });
        setShowConfirmation(true);
    };

    const onCancelRequest = () => {
        setShowConfirmation(false);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleCloseAlertSuc = () => {
        setShowAlertSuc(false);
    };

    const onConfirmRequest = (quantity) => {
        handleSubmit(selectedItem.id, selectedItem.item._id, quantity);
        setShowConfirmation(false);
    };

    const handleSubmit = async (id, destinationLocationName, quantity) => {
        const parsedQuantity = parseInt(quantity, 10);

        if (isNaN(parsedQuantity) || !Number.isInteger(parsedQuantity) || parsedQuantity <= 0) {
            setShowAlertMessage("Quantity must be a positive integer.");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);

            return;
        }

        fetchRequest()
            .then((dataRes) => {
                const filteredRequest = dataRes.find((item) => (item.toLocation === destinationLocationName) && (item.status === 'Submitted' || item.status === 'Pending') && item.inventoryId === id);

                const isRequestExist = filteredRequest;

                if (isRequestExist) {
                    setShowAlertMessage(`You have already requested stock from this technician.`);
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);

                } else {
                    let formInputs;

                    formInputs = {
                        quantity: parsedQuantity,
                        location: filterLocations._id,
                        toLocation: destinationLocationName,
                        status: 'Submitted',
                        inventoryId: id,
                        company: auth.user.company,
                    };

                    createRequestApiRequest(formInputs)
                        .then((data) => {
                            if (data.message === "request created");
                            setShowAlertSuc(true);
                            setTimeout(() => {
                                setShowAlertSuc(false);
                            }, 3000);
                            setIsLoading(true);
                        })
                        .catch((error) => {
                            if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === "Invalid quantity to request.") {
                                setShowAlertMessage("Invalid quantity to request.");
                            } else {
                                setShowAlertMessage(`Error requesting item: ${error.message}`);
                            }

                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                            }, 3000);
                        });
                }
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
    };

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };

    const componentRef = useRef(null);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    const filterInventory = (inventorys) => {
        const isLocksmith = inventorys.catagoryType === 'Locksmith';
        const isCategory = inventorys.catagory === 'Tools';

        const result =
            (!selectedTab ||
                (selectedTab === "Locksmith" && isLocksmith && isCategory)
            )

        return result;
    };

    const requestDataLenStatus = () => {

        const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.location && filterLocations.tech === locc.tech));

        const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

        if (filteredStatus === undefined) {
            return 0;
        } else {
            return filteredStatus;
        }
    }

    const requestDataLenInStatus = () => {

        const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.toLocation));

        const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

        if (filteredStatus === undefined) {
            return 0;
        } else {
            return filteredStatus;
        }
    }

    const removeFromStock = async (id, sourceLocationName, destinationLocationName, quantity) => {
        const parsedQuantity = parseInt(quantity, 10);

        if (isNaN(parsedQuantity) || !Number.isInteger(parsedQuantity) || parsedQuantity <= 0) {
            setShowAlertMessage("Quantity must be a positive integer.");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);

            return;
        }

        try {
            await axios.put(`/api/inventory/${id}/location/${sourceLocationName}/transfer/${destinationLocationName}`, { quantity: parsedQuantity });
            setShowAlertCheckTech(true);
            setTimeout(() => {
                setShowAlertCheckTech(false);
            }, 3000);
            setIsLoading(true);

        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === "Invalid quantity to transfer.") {
                setShowAlertMessage("Invalid quantity to transfer.");
            } else {
                setShowAlertMessage(`Error transferring item between locations: ${error.message}`);
            }

            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    const editInventoryItem = async (id, destinationLocationName, cost, locationName, stock, stockKey) => {

        try {
            await axios.put(`/api/inventory/${id}/edit/${destinationLocationName}`,
                {
                    minimunStock1: stock || 0,
                    location1: locationName || '',
                    ourCost1: cost || 0.00,
                    dontStock: stockKey || true,
                });
            setShowAlertSucEdit(true);
            setTimeout(() => {
                setShowAlertSucEdit(false);
            }, 3000);
            setIsLoading(true);

        } catch (error) {
            setShowAlertMessage(`Error updating item: ${error.message}`);
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    const handleCheckoutTool = (id, item) => {
        setSelectedItemTool({ id, item });
        setShowConfirmationTool(true);
    };

    const handleEditInventory = (id, item) => {
        setSelectedItemEdit({ id, item });
        setShowAlertEdit(true);
    };

    const onConfirmCheckout = (quantity, destinationLocation) => {
        removeFromStock(selectedItemTool.id._id, selectedItemTool.item._id, destinationLocation, quantity);
        setShowConfirmationTool(false);
    };

    const onCancelCheckout = () => {
        setShowConfirmationTool(false);
    };

    const onConfirmCheckoutEdit = (cost, locationName, stock, stockKey) => {
        editInventoryItem(selectedItemEdit.id._id, selectedItemEdit.item._id, cost, locationName, stock, stockKey);
        setShowAlertEdit(false);
    };

    const handleCloseAlertEdit = () => {
        setShowAlertEdit(false);
    };

    return (
        <div className="min-vh-100">
            {showAlertEdit && selectedItemEdit && (
                <InventoryEditInfoComponent
                    selectedItemEdit={selectedItemEdit}
                    locations={locations}
                    techs={techData}
                    selectedLocation={selectedLocation}
                    user={userData}
                    tech={tech}
                    onConfirm={onConfirmCheckoutEdit}
                    onCancel={handleCloseAlertEdit}
                />
            )}
            {showConfirmationTool && selectedItemTool && (
                <InventoryCheckOutComponent
                    message={`Enter the quantity to remove (Available stock: ${selectedItemTool.item.quantity}):`}
                    selectedItem={selectedItemTool}
                    locations={locations}
                    tech={tech}
                    user={userData}
                    techId={techData}
                    onConfirm={onConfirmCheckout}
                    onCancel={onCancelCheckout}
                />
            )}
            {(showConfirmation && selectedItem && (filterLocations !== undefined)) ? (
                <InventoryRequestPopUpComponent
                    message={`Enter the quantity to request (Available stock: ${selectedItem.item.quantity}):`}
                    selectedItem={selectedItem}
                    onConfirm={onConfirmRequest}
                    onCancel={onCancelRequest}
                />
            ) : showConfirmation && (
                <div className="alertRed w-90 alert-danger" role="alert">
                    Technition has not been set with an inventory yet.
                    <div className="alert-line-container">
                        <span className="alert-closeRed" onClick={onCancelRequest}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert && (
                <div className="alertRed alert-danger" role="alert">
                    {showAlertMessage}
                    <div className="alert-line-container">
                        <div className="alert-lineRed"></div>
                        <span className="alert-closeRed" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertCheckTech && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Moved Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertSucEdit && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertSuc && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Requested
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>
                            X
                        </span>
                    </div>
                </div>
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <InventoryLinksComponent inventorySet={inventorySet} requestDataLenStatus={requestDataLenStatus} requestDataLenInStatus={requestDataLenInStatus} />
                </div>
                <div className="col-sm-10 ms-0 ps-0 mt-5 pt-5">
                    <div className="row m-0 p-0 mb-3 d-flex justify-content-between">
                        <div className="col-sm-7 m-0 p-0">
                            <h1>Tools</h1>
                        </div>
                        <div className="col-sm-2 text-end m-0 pe-3 p-0">
                            <select
                                id="Location"
                                className="form-select"
                                aria-label="Location:"
                                disabled={!filterLocations}
                                value={selectedLocation || ''}
                                onChange={(e) => setSelectedLocation(e.target.value)}
                            >
                                {locations.map((location, index) => {
                                    const filteredTech = techData.find((techItem) => techItem._id === location.tech);

                                    if (filteredTech) {
                                        const UserFilter = userData.find((item) => filteredTech.user === item._id)

                                        const newName = UserFilter ? `${UserFilter.name} Van` : '';

                                        return (
                                            <option key={location._id} value={location.id}>
                                                {newName}
                                            </option>
                                        )
                                    } else {
                                        return (
                                            <option key={location._id} value={location.id}>
                                                {location.name}
                                            </option>
                                        )
                                    }
                                })}
                            </select>
                        </div>
                        <div className="col-sm-3 m-0 p-0">
                            <input
                                disabled={!filterLocations}
                                type="text"
                                className={`mt-1 me-4 form-control table-filter`}
                                data-table="order-table"
                                placeholder="Search Inventory.."
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row d-flex mb-2 justify-content-start m-0 p-0">
                        <div className="col-sm-12 m-0 p-0">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${selectedTab === "Locksmith" ? "active" : ""}`}
                                        onClick={() => handleTabChange("Locksmith")}
                                    >
                                        Locksmith ({inventory.filter(inv =>
                                            inv.catagoryType === "Locksmith" &&
                                            inv.catagory === "Tools" &&
                                            inv.InventoryLocation.some(loc => loc.name1 === selectedLocation && loc.dontStock)
                                        ).length})
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {filterLocations ? (
                                <>
                                    <div className="table-responsive">
                                        {inventory.filter(inv =>
                                            inv.catagoryType === selectedTab &&
                                            inv.catagory === "Tools" &&
                                            inv.InventoryLocation.some(loc => loc.name1 === selectedLocation && loc.dontStock)
                                        ).length > 0 ? (
                                            <table ref={componentRef} className="table-hover table-bordered order-table table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Name</th>
                                                        <th className="text-center">Location</th>
                                                        <th className="text-center">Stock</th>
                                                        <th className="text-center">Threshold</th>
                                                        <th className="text-center">Barcode</th>
                                                        <th className="text-center">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inventory
                                                        .filter(inventorys => (
                                                            (
                                                                inventorys.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
                                                                inventorys.barcode.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
                                                            ) &&
                                                            filterInventory(inventorys)
                                                        ))
                                                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                                        .map((inventorys, idx) => (
                                                            <>
                                                                {inventorys.InventoryLocation.filter((location) => location.name1 === selectedLocation && location.dontStock).map((inv, idxx) => (
                                                                    <tr key={idxx}>
                                                                        <td className="text-center">{inventorys.name}</td>
                                                                        <td className="align-middle text-center">
                                                                            {inv.location1 !== '' ? (
                                                                                <>
                                                                                    {inv.location1}
                                                                                </>
                                                                            ) : (
                                                                                "-"
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle text-center">
                                                                            {inv.quantity !== '' ? (
                                                                                <>
                                                                                    {inv.quantity}
                                                                                </>
                                                                            ) : (
                                                                                "-"
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle text-center">
                                                                            {inv.minimunStock1 !== '' ? (
                                                                                <>
                                                                                    {inv.minimunStock1}
                                                                                </>
                                                                            ) : (
                                                                                "-"
                                                                            )}
                                                                        </td>
                                                                        <td className="ps-3 text-center">
                                                                            <RowPrintComponent selectedLocation={selectedLocation} inventory={inventorys} />
                                                                        </td>
                                                                        {inv && (
                                                                            <>
                                                                                {(inv._id === disableRequest.filterDefault) ? (
                                                                                    <td className="fs-6 align-middle text-center">
                                                                                        <button
                                                                                            className="btn btn-sm btn-outline-primary"
                                                                                            onClick={() => handleCheckoutTool(inventorys, inv)}
                                                                                            disabled={(inv) ? ((inv.quantity !== '' && inv.quantity > 0) ? false : true) : true}
                                                                                        >
                                                                                            Check Out
                                                                                        </button>
                                                                                    </td>
                                                                                ) : (inv._id === disableRequest.filterLocation) ? (
                                                                                    <td className="fs-6 align-middle text-center">
                                                                                        <button
                                                                                            className="btn btn-sm btn-outline-primary me-2"
                                                                                            onClick={() => handleCheckoutTool(inventorys, inv)}
                                                                                            disabled={(inv) ? ((inv.quantity !== '' && inv.quantity > 0) ? false : true) : true}
                                                                                        >
                                                                                            Check Out
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn btn-sm btn-outline-success"
                                                                                            onClick={() => handleEditInventory(inventorys, inv)}
                                                                                        >
                                                                                            Edit
                                                                                        </button>
                                                                                    </td>
                                                                                ) : (
                                                                                    <td className="align-middle text-center">
                                                                                        <button
                                                                                            className="btn btn-sm btn-outline-primary"
                                                                                            onClick={() => handleCheckout(inventorys._id, inv)}
                                                                                            disabled={(inv) ? (
                                                                                                (inv.quantity !== '' && inv.quantity > 0) ? false : true) ||
                                                                                                (inv._id === disableRequest.filterDefault) ||
                                                                                                (inv._id === disableRequest.filterLocation)
                                                                                                : true}
                                                                                        >
                                                                                            Request
                                                                                        </button>
                                                                                    </td >
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </tr >
                                                                ))}
                                                            </>
                                                        )
                                                        )}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="mt-5">
                                                <div className="row d-flex justify-content-center m-0 p-0">
                                                    <div className="col-sm-3 m-0 p-0 align-center">
                                                        <MdOutlineInventory2 className="inventoryLogo fs-1" />
                                                    </div>
                                                </div>
                                                <div className="row d-flex justify-content-center m-0 p-0">
                                                    <div className="col-sm-4 m-0 p-0 text-start">
                                                        <span>Your inventory is currently empty.</span> <br />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <div className="mt-5 d-flex flex-column align-items-center">
                                    <div className="d-flex justify-content-start align-items-center m-0 p-0">
                                        <div className="col-sm-4 m-0 p-0 text-center">
                                            <MdOutlinePersonOff className="inventoryLogo fs-1" />
                                        </div>
                                    </div>
                                    <div className="d-flex mt-3 justify-content-center m-0 p-0">
                                        <div className="col-sm-7 m-0 p-0 text-center w-100">
                                            <span>
                                                You have not yet been allocated a location.<br />
                                                Please reach out to an administrator to have them assign a location for you to begin.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {filterLocations && (
                        <>
                            <div className="row d-flex w-100 justify-content-center mb-3 m-0 p-0 pb-4">
                                <div className="col-sm-3 m-0 p-0">
                                    <button
                                        className="btn btn-sm btn-outline-primary pe-2 ps-2 p-0 m-0 me-3 loginButton"
                                        disabled={page === 1}
                                        onClick={handlePrevPage}
                                    >
                                        Previous
                                    </button>
                                    <span className="mr-2 me-3">
                                        Page {page} of {Math.ceil(inventory
                                            .filter(inventorys => (
                                                (
                                                    inventorys.catagoryType === selectedTab &&
                                                    inventorys.catagory === "Tools" &&
                                                    inventorys.InventoryLocation.some(loc => loc.name1 === selectedLocation && loc.dontStock) ||
                                                    inventorys.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
                                                    inventorys.barcode.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
                                                ) &&
                                                filterInventory(inventorys)
                                            )).length / rowsPerPage)}
                                    </span>
                                    <button
                                        className="btn btn-sm btn-outline-primary pe-4 ps-4 p-0 m-0 loginButton"
                                        disabled={(inventory
                                            .filter(inventorys => (
                                                (
                                                    inventorys.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
                                                    inventorys.barcode.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
                                                ) &&
                                                filterInventory(inventorys)
                                            )).length) <= page * rowsPerPage}
                                        onClick={handleNextPage}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div >
        </div >
    );
};

export default InventoryToolComponent;