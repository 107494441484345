import React from "react";
import { BsConeStriped } from 'react-icons/bs';

const DeleteConfirmUploadComponent = ({ onConfirm, onCancel }) => {
    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel} // Close modal if clicked outside
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder text-warning coneConfir mb-4 pt-4">
                        <BsConeStriped />
                    </div>
                    <text className="fw-bold fs-5">You sure you want to delete the uploaded file?</text><br />
                    <text className="fw-bold fs-5">Once the file is deleted it cannot be undone.</text><br />
                    <div className="row justify-content-center mt-5 pb-3 border-top pt-3 border-dark">
                        <div className="col-sm-2 me-5">
                            <button onClick={onConfirm} className="btn btn-outline-danger m-0 ps-4 pe-4">
                                Yes
                            </button>
                        </div>
                        <div className="col-sm-2 me-4">
                            <button className="btn btn-outline-primary" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeleteConfirmUploadComponent;
