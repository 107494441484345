import axios from "axios";
import { useDispatch } from "react-redux";
import DispatchComponent from "./components/dispatchComponent";

const createCustomerApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/customer/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};
const fetchLocksmithInfo = async (year, name, model) => {
    const { data } = await axios.get(`/api/locksmithInfo?model=${model}&make=${name}&years=${year}`, { withCredentials: true })
    return data;
}
const fetchUploadsID = async (imageId) => {
    const { data } = await axios.get(`/api/uploads/image/${imageId}`, { withCredentials: true });
    return data
}
const createPaymentApiRequest = async (formInputsPayment) => {
    const { data } = await axios.post(`/api/payment/new`, { ...formInputsPayment }, { withCredentials: true });
    return data;
};
const createSchedule = async (formSchedule) => {
    const { data } = await axios.post(`/api/schedule/dispatch/post`, { ...formSchedule }, { withCredentials: true });
    return data;
};
const fetchCompany = async (abctrl) => {
    const { data } = await axios.get("/api/company/admin", { withCredentials: true });
    return data
}
const fetchUsers = async (abctrl) => {
    const { data } = await axios.get("/api/users", { withCredentials: true });
    return data
}
const createDispatchApiRequest = async (formInputsDis) => {
    const { data } = await axios.post(`/api/dispatch/dispatch/post`, { ...formInputsDis }, { withCredentials: true });
    return data;
};
const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/dispatch", { withCredentials: true });
    return data
}
const fetchJobType = async (abctrl) => {
    const { data } = await axios.get("/api/jobType", { withCredentials: true });
    return data
}
const fetchJobSource = async (abctrl) => {
    const { data } = await axios.get("/api/jobSource", { withCredentials: true });
    return data
}
const updateCustomerApiRequest = async (customerId, formInputs) => {
    const { data } = await axios.put(`/api/customer/dispatch/${customerId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const fetchCustomer = async (abctrl) => {
    const { data } = await axios.get("/api/customer/dispatch", { withCredentials: true });
    return data
}
const updateSchedule = async (scheduleId, updatedEventData) => {
    const { data } = await axios.put(`/api/schedule/dispatch/job/${scheduleId}`, { ...updatedEventData }, { withCredentials: true });
    return data
}
const fetchJobTax = async () => {
    const { data } = await axios.get(`/api/jobTax/dispatch`, { withCredentials: true });
    return data;
}
const updateDispatchPaymentApiRequest = async (dispatchId, formInputsPay) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/payment/${dispatchId}`, { ...formInputsPay }, { withCredentials: true });
    return data;
}

const DispatchPage = () => {
    const dispatch = useDispatch();

    return (
        <DispatchComponent
            fetchUploadsID={fetchUploadsID }
            fetchLocksmithInfo={fetchLocksmithInfo }
            createPaymentApiRequest={createPaymentApiRequest }
            fetchJobTax={fetchJobTax }
            updateSchedule={updateSchedule }
            createSchedule={createSchedule }
            fetchTechInfo={fetchTechInfo}
            updateDispatchPaymentApiRequest={updateDispatchPaymentApiRequest }
            createDispatchApiRequest={createDispatchApiRequest }
            fetchJobSource={fetchJobSource }
            fetchJobType={fetchJobType }
            fetchCustomer={fetchCustomer}
            updateCustomerApiRequest={updateCustomerApiRequest }
            fetchUsers={fetchUsers}
            createCustomerApiRequest={createCustomerApiRequest}
            fetchCompany={fetchCompany}
            reduxDispatch={dispatch}
        />
    );
};

export default DispatchPage;