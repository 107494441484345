import axios from "axios";
import { useDispatch } from "react-redux";
import { setReduxUserState } from "../../redux/actions/userActions";
import UserEditCompanyComponent from "./components/userCompanyComponent";


const updateCompanyApiRequest = async (companyId, formInputs) => {
    const { data } = await axios.put(`/api/company/profile/${companyId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const fetchCompany = async (companyId) => {
    const { data } = await axios.get(`/api/company/profile/${companyId}`, { withCredentials: true });
    return data;
}

const fetchUser = async (id) => {
    const { data } = await axios.get("/api/users/profile/" + id, { withCredentials: true });
    return data;
};

const fetchUploads = async (abctrl) => {
    const { data } = await axios.get("/api/uploads", { withCredentials: true });
    return data
}

const UserCompanyPage = () => {
    const reduxDispatch = useDispatch();

    return (
        <UserEditCompanyComponent
            fetchUploads={fetchUploads}
            fetchUser={fetchUser}
            updateCompanyApiRequest={updateCompanyApiRequest}
            fetchCompany={fetchCompany}
            setReduxUserState={setReduxUserState}
            reduxDispatch={reduxDispatch}
            localStorage={window.localStorage}
            sessionStorage={window.sessionStorage}
        />
    );
};

export default UserCompanyPage;
