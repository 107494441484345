import React from "react";

const ImagePopupComponent = ({ image, imageName, onClose }) => {
    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onClose} // Close modal if clicked outside
            />
            <div className="confirmation-box-image">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder bg-light deleteWidth mb-4">
                        <div className="col-sm fs-5">
                            {imageName }
                        </div>
                    </div>
                    <div className="row fw-bolder text-warning mb-4">
                        <img className="img-fluid" src={image} alt={`${image}`} />
                    </div>
                    <div className="row justify-content-center mt-5 border-top pt-3 border-dark">
                        <div className="col-sm-2 me-4 pb-3">
                            <button className="btn btn-outline-primary" onClick={onClose}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImagePopupComponent;
