import axios from "axios";
import VinDecoderComponent from "./components/vinDecoderComponent";

const VinDecoderPage = () => {

    const fetchVin = async (vin) => {
        const { data } = await axios.post(`/api/vin/decode?vin=${vin}`, { withCredentials: true });
        return data;
    }

    return <VinDecoderComponent
        fetchVin={fetchVin}
    />;
};

export default VinDecoderPage;
