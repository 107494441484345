import axios from "axios";
import WorkScheduleComponent from "./components/workScheduleComponent";

const WorkSchedulePage = () => {

    const getSchedule = async () => {
        const { data } = await axios.get(`/api/workSchedule/work-schedule`, { withCredentials: true });
        return data
    }

    const getLocation = async (lat, lng) => {
        const { data } = await axios.get(`/api/workSchedule/geocode?lat=${lat}&lng=${lng}`, { withCredentials: true });
        return data
    }

    const deleteSchedule = async (scheduleId, entryId) => {
        const { data } = await axios.delete(`/api/workSchedule/schedules/${scheduleId}/entries/${entryId}`, { withCredentials: true });
        return data
    }

    const editSchedule = async (scheduleId, entryId, formInputs) => {
        const { data } = await axios.put(`/api/workSchedule/schedules-edit/${scheduleId}/entries/${entryId}`, { ...formInputs }, { withCredentials: true });
        return data
    }

    const updateWorkApiRequest = async (workId, formInputs) => {
        const { data } = await axios.put(`/api/workSchedule/update-work-schedule/${workId}`, { ...formInputs }, { withCredentials: true });
        return data;
    }

    const clockStatus = async (userId) => {
        const { data } = await axios.get(`/api/timeEntry/status-cal/${userId}`, { withCredentials: true });
        return data
    }

    return <WorkScheduleComponent
        editSchedule={editSchedule}
        clockStatus={clockStatus}
        updateWorkApiRequest={updateWorkApiRequest}
        deleteSchedule={deleteSchedule}
        getSchedule={getSchedule}
        getLocation={getLocation}
    />
};

export default WorkSchedulePage;