import axios from "axios";
import { useDispatch } from "react-redux";
import AdminEditLocationComponent from "./components/adminEditLocationComponent";

const updateLocationApiRequest = async (locationId, formInputs) => {
    const { data } = await axios.put(`/api/inventoryLocation/${locationId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const fetchLocation = async (locationId) => {
    const { data } = await axios.get(`/api/inventoryLocation/get-one/${locationId}`, { withCredentials: true });
    return data
}
const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/admin", { withCredentials: true });
    return data
}
const fetchUsers = async (abctrl) => {
    const { data } = await axios.get("/api/users", { withCredentials: true });
    return data
}

const AdminEditLocationPage = () => {
    const dispatch = useDispatch();

    return (
        <AdminEditLocationComponent
            fetchLocation={fetchLocation }
            fetchUsers={fetchUsers}
            fetchTechInfo={fetchTechInfo}
            updateLocationApiRequest={updateLocationApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminEditLocationPage;