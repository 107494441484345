import React from 'react';
import { Link } from "react-router-dom";
import paths from '../router/paths';

const PayNowComponent = () => {

    return (
        <div className="container justify-content-center">
            <div className="row mt-5 pt-5">
                <div className="col-sm-1"/>
                <div className="col-sm-10 text-center">
                    <h3 className="text-center font-weight-bold">Upgrade Your Account to Access Exclusive European Locksmith Information</h3>
                    <br/>
                    <p>
                         Welcome to our platform! We are thrilled to offer a wealth of valuable information about European locksmith services and techniques.<br />
                        Our goal is to provide you with the most up-to-date and comprehensive knowledge to meet your locksmith needs effectively. To unlock this <br />
                        treasure trove of exclusive content, we invite you to upgrade your account to our premium membership.
                    </p>
                    <br/>
                    <div className="row justify-content-center">
                        <div className="col-sm-2">
                            <Link type="button" className="btn btn-outline-primary loginButton" to={paths.ROOT}>Go Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayNowComponent;
