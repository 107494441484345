import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const VerifyUserComponent = ({ updateUserPasswordRequest }) => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const [passwordsMatchState, setPasswordsMatchState] = useState(true);

    const [passwordRequirements, setPasswordRequirements] = useState({
        hasUpperCase: false,
        hasNumber: false,
        hasSpecialChar: false,
        minLength: false,
    });

    const onChangePassword = (e) => {
        const value = e.target.value;
        setPassword(value);

        const upperCaseRegex = /[A-Z]/;
        const numberRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

        setPasswordRequirements({
            hasUpperCase: upperCaseRegex.test(value),
            hasNumber: numberRegex.test(value),
            hasSpecialChar: specialCharRegex.test(value),
            minLength: value.length >= 6,
        });

        setPasswordsMatchState(value === confirmPassword);
    };

    const onChangeConfirmPassword = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);

        setPasswordsMatchState(value === password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!passwordsMatchState) {
            setError('Passwords do not match');
            return;
        }

        try {
            const data = await updateUserPasswordRequest(token, password);
            setSuccess(data.message);
            setTimeout(() => navigate('/login'), 3000);
        } catch (err) {
            setError(err.response?.data?.message || 'Something went wrong');
        }
    };

    const isSubmitDisabled = !(
        passwordRequirements.hasUpperCase &&
        passwordRequirements.hasNumber &&
        passwordRequirements.hasSpecialChar &&
        passwordRequirements.minLength &&
        passwordsMatchState
    );

    return (
        <div className="container mt-5 pt-5">
            <div className="row mt-5 pt-5 m-0 p-0 justify-content-center">
                <div className="col-sm-4 mt-5 border">
                    <h3 className="text-center pt-3 pt-2">Set Your Password</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 m-0 p-2 mt-4">
                            <label htmlFor="password">Password</label>
                            <input
                                name="password"
                                required
                                className={`form-control ${!passwordRequirements.hasUpperCase ||
                                    !passwordRequirements.hasNumber ||
                                    !passwordRequirements.hasSpecialChar ||
                                    !passwordRequirements.minLength
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                type="password"
                                placeholder="Password"
                                minLength={6}
                                value={password}
                                onChange={onChangePassword}
                            />
                            <div className="invalid-feedback pt-3 pb-3">
                                <ul className="ms-3 m-0 p-0">
                                    <li>
                                        {passwordRequirements.hasUpperCase ? (
                                            <span className="text-success">&#10003;</span>
                                        ) : (
                                            <span className="text-danger">&#10007;</span>
                                        )}{' '}
                                        At least one uppercase letter
                                    </li>
                                    <li>
                                        {passwordRequirements.hasNumber ? (
                                            <span className="text-success">&#10003;</span>
                                        ) : (
                                            <span className="text-danger">&#10007;</span>
                                        )}{' '}
                                        At least one number
                                    </li>
                                    <li>
                                        {passwordRequirements.hasSpecialChar ? (
                                            <span className="text-success">&#10003;</span>
                                        ) : (
                                            <span className="text-danger">&#10007;</span>
                                        )}{' '}
                                        At least one special character
                                    </li>
                                    <li>
                                        {passwordRequirements.minLength ? (
                                            <span className="text-success">&#10003;</span>
                                        ) : (
                                            <span className="text-danger">&#10007;</span>
                                        )}{' '}
                                        Minimum length of 6 characters
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="mb-3 m-0 p-2 mt-4">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <input
                                name="confirmPassword"
                                required
                                className={`form-control ${!passwordsMatchState ? 'is-invalid' : ''
                                    }`}
                                type="password"
                                placeholder="Confirm Password"
                                minLength={6}
                                value={confirmPassword}
                                onChange={onChangeConfirmPassword}
                            />
                            <div className="invalid-feedback">
                                Passwords do not match.
                            </div>
                        </div>
                        <div className="text-center mb-3">
                            {error && <p className="text-center" style={{ color: 'red' }}>{error}</p>}
                            {success && <p className="text-center" style={{ color: 'green' }}>{success}</p>}
                            <button type="submit" className="btn btn-outline-primary" disabled={isSubmitDisabled}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default VerifyUserComponent;