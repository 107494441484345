import axios from "axios";
import { useDispatch } from "react-redux";
import CreateJobItemPageComponent from "./components/newJobItemComponent";

const createJobItemApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/jobItem/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const CreateJobItemPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateJobItemPageComponent
            createJobItemApiRequest={createJobItemApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default CreateJobItemPage;