import axios from "axios";
import { useDispatch } from "react-redux";
import CreateCustomerPageComponent from "./components/newCustomerComponent";

const createCustomerApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/customer/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const CreateCustomerPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateCustomerPageComponent
            createCustomerApiRequest={createCustomerApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default CreateCustomerPage;