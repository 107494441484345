import React from 'react';
import { Link } from "react-router-dom";

const LocksmithHelpComponent = () => {

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-10">
                    <div className="row justify-content-start">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Commercial Rekey</h5>
                                    <p className="card-text">
                                        For locksmith professionals seeking expert assistance in commercial rekeying.
                                        With a focus on precision and expertise, we specialize in the meticulous adjustment
                                        of internal lock components, providing the support you need to enhance security in commercial settings.
                                    </p>
                                    <Link to='/locksmith-help/Commercial Rekey' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Commercial Hardware Install/Repair</h5>
                                    <p className="card-text">
                                        For locksmith professionals in need of expert assistance with commercial hardware installation and repair.
                                        With a focus on precision and technical expertise, we excel in providing the support
                                        necessary for maintaining and upgrading security systems in commercial settings.
                                    </p>
                                    <Link to={`/locksmith-help/${encodeURIComponent('Commercial Hardware Install/Repair')}`} className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Commercial Diagnose</h5>
                                    <p className="card-text">
                                        Locksmith professionals seeking technical assistance in commercial lock diagnostics can rely on our specialized support.
                                        We offer precise assessments of commercial lock systems, providing accurate diagnoses to address issues effectively.
                                    </p>
                                    <Link to='/locksmith-help/Commercial Diagnose' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Commercial Other</h5>
                                    <p className="card-text mb-4 pb-3">
                                        Locksmith professionals seeking specialized assistance in diverse commercial applications can rely on our expertise.
                                        With a dedicated focus on precision, we excel in addressing various commercial locksmith challenges beyond the
                                        outlined scope.
                                    </p>
                                    <Link to='/locksmith-help/Commercial Other' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Residential Rekey</h5>
                                    <p className="card-text">
                                        For locksmith professionals seeking expert assistance in residential rekeying.
                                        With a focus on precision and expertise, we specialize in the meticulous adjustment
                                        of internal lock components, providing the support you need to enhance security in residential settings.
                                    </p>
                                    <Link to='/locksmith-help/Residential Rekey' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Residential Hardware Install/Repair</h5>
                                    <p className="card-text">
                                        For locksmith professionals in need of expert assistance with residential hardware installation and repair.
                                        With a focus on precision and technical expertise, we excel in providing the support
                                        necessary for maintaining and upgrading security systems in residential settings.
                                    </p>
                                    <Link to={`/locksmith-help/${encodeURIComponent('Residential Hardware Install/Repair')}`} className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Residential Diagnose</h5>
                                    <p className="card-text">
                                        Locksmith professionals seeking technical assistance in residential lock diagnostics can rely on our specialized support.
                                        We offer precise assessments of residential lock systems, providing accurate diagnoses to address issues effectively.
                                    </p>
                                    <Link to='/locksmith-help/Residential Diagnose' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Residential Other</h5>
                                    <p className="card-text">
                                        Locksmith professionals seeking specialized assistance in diverse residential applications can rely on our expertise.
                                        With a dedicated focus on precision, we excel in addressing various residential locksmith challenges beyond the
                                        outlined scope.
                                    </p>
                                    <Link to='/locksmith-help/Residential Other' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Automotive Rekey</h5>
                                    <p className="card-text">
                                        For locksmith professionals seeking expert assistance in automotive rekeying.
                                        With a focus on precision and expertise, we specialize in the meticulous adjustment
                                        of internal lock components, providing the support you need to enhance security in automotive settings.
                                    </p>
                                    <Link to='/locksmith-help/Automotive Rekey' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Automotive Hardware Install/Repair</h5>
                                    <p className="card-text">
                                        For locksmith professionals requiring expert assistance in automotive hardware installation and repair,
                                        our specialized support is tailored to meet your technical needs. Count on us for precise assessments and
                                        efficient solutions for automotive lock systems.
                                    </p>
                                    <Link to={`/locksmith-help/${encodeURIComponent('Automotive Hardware Install/Repair')}`} className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Automotive Diagnose</h5>
                                    <p className="card-text">
                                        For locksmith professionals seeking specialized support in automotive lock diagnostics, our services are tailored to
                                        meet your technical needs. Count on us for precise assessments of automotive lock systems, providing accurate diagnoses
                                        to efficiently tackle any issues that may arise.
                                    </p>
                                    <Link to='/locksmith-help/Automotive Diagnose' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Automotive EEPROM</h5>
                                    <p className="card-text">
                                        For locksmith professionals seeking specialized support in automotive EEPROM work, our services are tailored to
                                        meet your technical needs. Count on us for precise assessments of automotive software systems, providing accurate info
                                        to efficiently tackle any issues that may arise.
                                    </p>
                                    <Link to='/locksmith-help/Automotive EEPROM' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Automotive Other</h5>
                                    <p className="card-text mb-4 pb-3">
                                        Locksmith professionals seeking specialized assistance in diverse automotive applications can rely on our expertise.
                                        With a dedicated focus on precision, we excel in addressing various residential locksmith challenges beyond the
                                        outlined scope.
                                    </p>
                                    <Link to='/locksmith-help/Automotive Other' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Automotive Lockouts</h5>
                                    <p className="card-text">
                                        For locksmith professionals dealing with automotive lockouts, our specialized support ensures reliable technical assistance.
                                        We understand the challenges presented by automotive lockouts and provide expert solutions. Count on us for swift
                                        and precise interventions, enabling you to efficiently address automotive lockout issues.
                                    </p>
                                    <Link to='/locksmith-help/Automotive Lockouts' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Commercial Lockouts</h5>
                                    <p className="card-text">
                                        For locksmith professionals encountering commercial lockouts, our specialized assistance is tailored to meet your technical
                                        needs. We understand the urgency and challenges associated with commercial lockouts and offer prompt, efficient support.
                                        Count on us for expert solutions to swiftly resolve lockout situations.
                                    </p>
                                    <Link to='/locksmith-help/Commercial Lockouts' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Residential Lockouts</h5>
                                    <p className="card-text">
                                        Locksmith professionals in need of specialized assistance with residential lockouts can rely on our dedicated support.
                                        We understand the urgency of addressing such situations and offer swift, expert solutions to ensure efficient resolution.
                                        Trust our team for reliable and effective support in handling residential lockouts, empowering locksmith professionals
                                        to navigate these scenarios with confidence and expertise.
                                    </p>
                                    <Link to='/locksmith-help/Residential Lockouts' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Other Lockouts</h5>
                                    <p className="card-text">
                                        Locksmith professionals in search of specialized support for diverse lockout scenarios can trust in our expertise.
                                        Our commitment to precision sets us apart as we navigate and excel in addressing an array of lockout challenges
                                        beyond the outlined scope. Count on our dedicated assistance to enhance your locksmith capabilities and confidently
                                        tackle a wide range of lock-related situations.
                                    </p>
                                    <Link to='/locksmith-help/Other Lockouts' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Safe Open/Repair</h5>
                                    <p className="card-text">
                                        For locksmith professionals in need of expert support with safe open and repair services, our specialized assistance
                                        is tailored to meet your technical requirements. Count on us for precise assessments and skilled repairs of safes,
                                        ensuring accurate solutions to address any issues that may arise. Our commitment is to provide reliable and effective
                                        support, empowering locksmith professionals in their craft.
                                    </p>
                                    <Link to={`/locksmith-help/${encodeURIComponent('Safe Open/Repair')}`} className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Access Control</h5>
                                    <p className="card-text">
                                        Locksmith professionals seeking specialized guidance in access control can rely on our expert support. We provide
                                        tailored assistance in the implementation and maintenance of access control systems. Our services encompass thorough
                                        assessments and precise configurations, ensuring effective access management. Trust in our expertise to enhance your
                                        proficiency in access control.
                                    </p>
                                    <Link to='/locksmith-help/Access Control' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Surveillance</h5>
                                    <p className="card-text">
                                        For locksmith professionals requiring specialized support in surveillance solutions, our services are tailored to meet
                                        your technical needs. We provide expertise in the installation, maintenance, and troubleshooting of surveillance systems,
                                        ensuring optimal performance. Count on us for precise assessments, accurate diagnoses, and effective solutions,
                                        empowering locksmith professionals in the realm of surveillance.
                                    </p>
                                    <Link to='/locksmith-help/Surveillance' className="btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start mt-5">
                        <div className="col-sm-6 m-0 p-0">
                            <div className="card" style={{ width: '30rem' }}>
                                <img src="..." className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Other </h5>
                                    <p className="card-text pb-5 mb-4">
                                        Locksmith professionals in need of specialized support across diverse locksmith applications can trust in our expertise.
                                        Our dedicated focus on precision sets us apart, allowing us to excel in overcoming various locksmith challenges beyond
                                        the outlined scope.
                                    </p>
                                    <Link to='/locksmith-help/Other' className="mt-2 pt-2 btn btn-sm btn-outline-primary loginButton">Get Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocksmithHelpComponent;
