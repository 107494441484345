import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../router/paths";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const AdminEditLocationComponent = ({
    updateLocationApiRequest,
    fetchLocation,
    fetchTechInfo,
    fetchUsers
}) => {
    const [location, setLocation] = useState([]);
    const [tech, setTech] = useState([]);
    const [selectedTech, setSelectedTech] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(false);
    const [selectedVan, setSelectedVan] = useState(false);
    const { id } = useParams();
    const auth = useSelector(state => state.auth)

    const [validated, setValidated] = useState(false);
    const [createLocationResponseState, setCreateLocationResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    useEffect(() => {
        const abctrl = new AbortController();
        fetchLocation(id)
            .then((res) => {
                setSelectedLocation(res.defaultLoc);
                setSelectedTech(res.tech);
                setSelectedVan(res.van);
                setLocation(res);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        fetchUsers(abctrl)
            .then((res) => {
                const filteredUsers = res.filter((item) => item.company === auth.user.company);
                setUsers(filteredUsers);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        fetchTechInfo(abctrl)
            .then((res) => {
                const filteredTech = res.filter((item) => item.company === auth.user.company);
                setTech(filteredTech);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [fetchTechInfo, fetchLocation, id, fetchUsers, auth.user.company]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            company: auth.user.company,
            tech: selectedTech,
            defaultLoc: selectedLocation,
            van: selectedVan,
        };
        if (event.currentTarget.checkValidity() === true) {
            updateLocationApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "location updated") navigate(`${paths.ADMINLOCATION}`);
                })
                .catch((er) => {
                    setCreateLocationResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    }

    const getTechName = (techId) => {
        const techUser = users.find((user) => user._id === techId);
        return techUser ? techUser.name : "";
    };

    const handleSelectLocation = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedLocation(newSelectedOption);
    }

    const handleSelectVan = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedVan(newSelectedOption);
    }

    return (
        <div className="pt-4 mt-5">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-4 mt-3">
                        <h1 className="text-center">Edit Location</h1>
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} className="row pt-5 g-3">
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <div className="form-floating w-100 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="locationName"
                                        placeholder="City Name"
                                        name="name"
                                        defaultValue={location.name}
                                    />
                                    <label htmlFor="locationName">Location Name</label>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <div className="form-floating w-100 mb-3">
                                    <select className="form-select" onChange={handleSelectLocation} value={selectedLocation} id="floatingSelect" aria-label="Headquarters:">
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                    <label htmlFor="floatingSelect">Headquarters:</label>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <div className="form-floating w-100 mb-3">
                                    <select className="form-select" onChange={handleSelectVan} value={selectedVan} id="floatingSelect1" aria-label="Mobile Inventory:">
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                    <label htmlFor="floatingSelect1">Mobile Inventory:</label>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <div className="form-floating w-100 mb-3">
                                    <select className="form-select" onChange={(e) => setSelectedTech(e.target.value)} value={selectedTech || ''} id="tech" aria-label="Technician: (Optional)">
                                        <option>Select Technician</option>
                                        {tech.map((techs) => (
                                            <option key={techs._id} value={techs._id}>
                                                {getTechName(techs.user)}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="tech">Technician: (Optional)</label>
                                </div>
                            </div>
                            <div className="text-center pt-3">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINLOCATION} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createLocationResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminEditLocationComponent;