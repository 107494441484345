import React, { useEffect, useRef, useState } from "react";
import { FaRegThumbsUp } from 'react-icons/fa';
import { FaRegThumbsDown } from 'react-icons/fa';
import ConfirmationModal from "./confirmationComponent";
import '../assets/tagsCss.css';
import { useSelector } from "react-redux";

const JobTagDropdown = ({ updateDispatchTagsApiRequest, jobTagData, dispatching, updateScheduleTags, id, jobTag }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [filteredJobTag, setFilteredJobTag] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTag, setSelectedTag] = useState({});
    const dropdownRef = useRef(null);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const auth = useSelector(state => state.auth)
    const [createDispatchResponseState, setCreateDispatchResponseState] =
        useState({
            message: "",
            error: ""
        });
    const [isTagChanged, setIsTagChanged] = useState(false); // New state to track if tags have been changed
    const [isTagRemoved, setIsTagRemoved] = useState(false); // New state to track if tags have been changed

    const toggleDropdown = () => {
        setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
    };
    
    const handleJobTagChange = (selectedJobTag) => {
        const isSelected = selectedTag.some((tag) => tag.name === selectedJobTag);

        if (isSelected) {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        } else {
            const selectedTagObj = jobTag.find((tag) => tag.name === selectedJobTag);
            setSelectedTag((prevSelectedTags) => [...prevSelectedTags, selectedTagObj]);
            setIsTagChanged(true); // Set the flag to indicate that tags have been changed
        }

        setIsDropdownOpen(false); // Close the dropdown when a tag is selected/removed
    };

    useEffect(() => {
        // Decode the object IDs in jobTagData and fetch the corresponding jobTags from the jobTag collection
        const decodedTags = jobTagData.map((objectId) => {
            const matchingTag = jobTag.find((tag) => tag._id === objectId);
            return matchingTag ? { name: matchingTag.name, _id: matchingTag._id, color: matchingTag.color } : null;
        });

        // Remove null values (for unmatched IDs) and set the selectedTag state with the names and colors of jobTags
        setSelectedTag(decodedTags.filter((tag) => tag !== null));

    }, [jobTagData, jobTag]);

    useEffect(() => {
        if (isTagChanged) {
            const formInputsOption = {
                jobTags: selectedTag,
            };

            updateDispatchTagsApiRequest(id, formInputsOption)
                .then((data) => {
                    if (data.message === "Dispatch tag updated") {

                        updateScheduleTags(dispatching.schedule, formInputsOption)
                            .then((response) => {
                                setShowAlert1(true);
                                setTimeout(() => {
                                    setShowAlert1(false);
                                }, 3000);
                            })
                            .catch((error) => {
                                // Handle error if needed
                                console.error("Error updating tag:", error);
                            });
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data
                    });
                });

            setIsTagChanged(false); // Reset the flag after the API call is made
            setShowAlert(false); // Reset the showAlert state after API call
        } else if (isTagRemoved) {
            const formInputsOption = {
                jobTags: selectedTag,
            };

            updateDispatchTagsApiRequest(id, formInputsOption)
                .then((data) => {
                    if (data.message === "Dispatch tag updated") {
                        updateScheduleTags(dispatching.schedule, formInputsOption)
                            .then((response) => {
                                setShowAlert1(true);
                                setTimeout(() => {
                                    setShowAlert1(false);
                                }, 3000);
                            })
                            .catch((error) => {
                                // Handle error if needed
                                console.error("Error updating tag:", error);
                            });
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data
                    });
                });

            setIsTagChanged(false); // Reset the flag after the API call is made
            setIsTagRemoved(false);
            setShowAlert(false); // Reset the showAlert state after API call
        }
    }, [selectedTag, id, updateDispatchTagsApiRequest, isTagChanged]);

    useEffect(() => {
        if (showAlert1) {
            setTimeout(() => {
                setShowAlert1(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [showAlert1]);

    const handleCloseAlert1 = () => {
        setShowAlert1(false);
    };
    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [showAlert]);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
    };
    const handleRemoveTag = (tagName) => {
        const selectedTagObj = selectedTag.find((tag) => tag.name === tagName);

        // Show confirmation modal when removing a tag
        setShowConfirmation(true);
        setSelectedTagToRemove(selectedTagObj);
    };

    const [selectedTagToRemove, setSelectedTagToRemove] = useState({});

    const handleConfirmRemoveTag = () => {
        setSelectedTag((prevSelectedTags) =>
            prevSelectedTags.filter((tag) => tag.name !== selectedTagToRemove.name)
        );
        setIsTagRemoved(true); // Set the flag to indicate that tags have been changed
        setShowConfirmation(false);
    };

    const handleCancelRemoveTag = () => {
        setShowConfirmation(false);
    };
   const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            setSearchTerm("");
        }
    };

    useEffect(() => {
        if (Array.isArray(jobTag)) {
            // Filter based on selected company and search term
            setFilteredJobTag(
                jobTag.filter(
                    (jobTags) =>
                        jobTags.company === auth.user.company &&
                        jobTags.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
    }, [jobTag, auth.user.company, searchTerm]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="dropdown-container" ref={dropdownRef}>
            {showAlert1 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Tag Assigned
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert1}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showConfirmation && (
                <ConfirmationModal
                    message={`Are you sure you want to remove this tag?`}
                    onConfirm={handleConfirmRemoveTag}
                    onCancel={handleCancelRemoveTag}
                />
            )}
            {showAlert && (
                <div className="alert-danger alertRed" role="alertRed">
                    <FaRegThumbsDown className="me-3 mt-1 mb-2" />
                    Tag already assigned
                    <div className="alert-line-container">
                        <div className="alert-lineRed"></div>
                        <span className="alert-closeRed" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            <div className={`dropdown-select-bar ${isDropdownOpen ? "open" : ""}`}>
                {/* Show the selected job tag's name or "+" */}
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                    }}
                    className={`plus-sign selected`}>
                        <><span style={{ fontSize: "1rem", }} className="text-dark me-3">Tags:</span> </>
                    {selectedTag.length ? (
                        selectedTag.map((tag, index) => (
                            <div
                                className="selected-tags-view"
                                key={index}
                                style={{
                                    backgroundColor: tag.color,
                                }}
                            >
                                {tag.name}
                                <span
                                    onClick={() => handleRemoveTag(tag.name)}
                                    style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                    }}
                                >
                                    X
                                </span>
                            </div>
                        ))
                          ):(<></>)}
                </div>
                <div
                    className="dropdown-plus"
                    className={`plus-sign selected`}
                    onClick={toggleDropdown}
                >
                    <div className="ms-3 addPlusTag">+</div>
                </div>
            </div>
            {isDropdownOpen && (
                <div className="dropdown-content dropdownJobTags">
                    <h5>Job Tags</h5>
                    {/* Add a styled search input field */}
                    <div className="search-container mt-3">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <div className="line"></div>
                    </div>
                    {filteredJobTag.map((jobTags) => (
                        <span
                            key={jobTags._id}
                            className={`col jobTagsSelect ${selectedTag === jobTags.name ? "selected" : ""}`}
                            style={{backgroundColor: `${jobTags.color}`}}
                            onClick={() => handleJobTagChange(jobTags.name)}
                        >
                            {jobTags.name}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default JobTagDropdown;
