import React, { useState, useEffect } from "react";
import '../assets/inventory.css';

const InventoryEditInfoComponent = ({ user, techs, tech, selectedItemEdit, locations, onConfirm, onCancel }) => {
    const [cost, setCost] = useState(0.00);
    const [locationName, setLocation] = useState('');
    const [stock, setStock] = useState(0);
    const [stockKey, setStockKey] = useState(true);

    useEffect(() => {
        const getTechNamess = (techId) => {
            const techUser = techs.find((tech) => tech._id === techId);
            const userId = techUser.user;
            const techName = user.find((users) => users._id === userId);

            return techName ? techName.name : "";
        };
        const capitalizeFirstLetterss = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        if (selectedItemEdit) {
            const locationsfil = locations.find((location) => !location.defaultLoc && location.tech === tech._id);
            const name = `${getTechNamess(locationsfil.tech)} ${capitalizeFirstLetterss(locationsfil.name)}`
            const locationData = selectedItemEdit.id.InventoryLocation.find((loc) => loc.name1 === name);

            setCost(locationData.ourCost1);
            setLocation(locationData.location1);
            setStock(locationData.minimunStock1);
            setStockKey(locationData.dontStock);
        }
    }, [locations, selectedItemEdit, tech._id, techs, user]);

    const handleConfirm = () => {
        onConfirm(cost, locationName, stock, stockKey);
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="inventory-checkout-container">
                        <div className="popup">
                            <h2 className="popup-header">Edit Inventory</h2>
                            <div className="popup-1">
                                <div className="mt-4">
                                    <div className="row text-start mt-2 m-0 p-0">
                                        <div className="col">
                                            <label htmlFor="minimunStock1">Stock Threshold:</label>
                                            <input
                                                className="form-control"
                                                id="minimunStock1"
                                                name="minimunStock1"
                                                type="number"
                                                onChange={(e) => setStock(e.target.value)}
                                                value={stock}
                                            />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="location1">Location:</label>
                                            <input
                                                className="form-control"
                                                id="location1"
                                                name="location1"
                                                type="text"
                                                onChange={(e) => setLocation(e.target.value)}
                                                value={locationName}
                                            />
                                        </div>
                                    </div>
                                    <div className="row text-start mt-2 m-0 p-0">
                                        <div className="col">
                                            <label htmlFor="ourCost1">Cost:</label>
                                            <input
                                                className="form-control"
                                                id="ourCost1"
                                                name="ourCost1"
                                                type="text"
                                                disabled
                                                value={cost}
                                            />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="floatingSelect">Stock Key:</label>
                                            <select className="form-select" onChange={(e) => setStockKey(e.target.value)} value={stockKey} id="floatingSelect">
                                                <option value={false}>No</option>
                                                <option value={true}>Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row deleteWidth mt-4 bg-light justify-content-center border-top pt-3 border-dark">
                                    <div className="col-sm-3 me-4">
                                        <button onClick={handleConfirm} className="btn btn-outline-success loginButton">
                                            Confirm
                                        </button>
                                    </div>
                                    <div className="col-sm-3">
                                        <button onClick={onCancel} className="btn btn-outline-primary loginButton">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InventoryEditInfoComponent;
