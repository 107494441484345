import axios from "axios";
import LocksmithHelpIndexComponent from "./components/locksmithHelpIndexComponent";

const LocksmithHelpIndexPage = () => {

    const fetchInstruction = async (Category, name) => {
        const { data } = await axios.get(`/api/instruction?instructionType=Locksmith&instructionCatagory=${Category}&name=${name}`, { withCredentials: true })
        return data;
    }

    return <LocksmithHelpIndexComponent
        fetchInstruction={fetchInstruction}
    />;
};

export default LocksmithHelpIndexPage;
