import axios from 'axios'
import { useDispatch } from "react-redux";
import EditJobItemsComponent from './components/editJobItemsComponent';

const fetchJobItems = async (jobItemsId) => {
    const { data } = await axios.get(`/api/jobItem/get-one/${jobItemsId}`, { withCredentials: true });
    return data;
}

const updateJobItemsApiRequest = async (jobItemsId, formInputs) => {
    const { data } = await axios.put(`/api/jobItem/dispatch/${jobItemsId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const EditJobItemsPage = () => {

    const reduxDispatch = useDispatch();


    return <EditJobItemsComponent fetchJobItems={fetchJobItems} updateJobItemsApiRequest={updateJobItemsApiRequest} reduxDispatch={reduxDispatch} />;
};

export default EditJobItemsPage;
