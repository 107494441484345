import React, { useState } from "react";
import '../assets/inventory.css';
import { useEffect } from "react";

const InventoryRequestPopUpComponent = ({ message, selectedItem, onConfirm, onCancel }) => {
    const [quantity, setQuantity] = useState('');

    useEffect(() => {
        if (selectedItem.requestData) {
            setQuantity(selectedItem.requestData.quantity);
        }
    }, [selectedItem]);

    const handleConfirm = () => {
        if (selectedItem.requestData) {
            if (parseInt(quantity, selectedItem.requestData.status, selectedItem.requestData._id) > 0) {
                onConfirm(quantity, selectedItem.requestData.status, selectedItem.requestData._id);
            }
        } else {
            if (parseInt(quantity) > 0) {
                onConfirm(quantity);
            }
        }
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="inventory-checkout-container">
                        <div className="popup">
                            <h2 className="popup-header">Request Stock</h2>
                            <div className="popup-1">

                                <p className="popup-message">{message}</p>
                                <input
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    placeholder="Enter quantity"
                                    className="quantity-input"
                                />
                                <div className="row justify-content-center">
                                    <div className="col-sm-3 me-4">
                                        <button onClick={handleConfirm} disabled={quantity === '' || selectedItem.item.quantity < quantity || quantity === '0'} className="btn btn-outline-success loginButton">
                                            Confirm
                                        </button>
                                    </div>
                                    <div className="col-sm-3">
                                        <button onClick={onCancel} className="btn btn-outline-primary loginButton">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InventoryRequestPopUpComponent;
