import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paths from "../../../router/paths";

const EditMakeComponent = ({
    fetchMake,
    updateMakeApiRequest,
}) => {
    const [european, setEuropean] = useState(false);
    const [validated, setValidated] = useState(false);
    const [make, setMake] = useState({});
    const [updateMakeResponseState, setUpdateMakeResponseState] = useState({
        message: "",
        error: "",
    });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchMake(id)
            .then((data) => {
                setMake(data);
                setEuropean(data.european)
            })
            .catch((er) => console.log(er));
    }, [id, fetchMake]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            european: european,
        };

        if (event.currentTarget.checkValidity() === true) {
            updateMakeApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "vehicle make updated") navigate(`${paths.ADMINMAKE}`);
                })
                .catch((er) =>
                    setUpdateMakeResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    }
    const handleEuropeanChange = (event) => {
        setEuropean(event.target.checked);
    };

    return (
        <div className="pt-5 mt-5">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-center">Edit Make</h1>
                        <form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} className="row pt-4 g-3">
                            <div>
                                <label htmlFor="name">Make:</label>
                                <input type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    defaultValue={make.name}
                                />
                            </div>
                            <div className="form-check ms-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="european"
                                    id="european"
                                    checked={european}
                                    onChange={handleEuropeanChange}
                                />
                                <label className="form-check-label" htmlFor="european">
                                    European Vehicle
                                </label>
                            </div>
                            <div className="text-center pt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINMAKE} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {updateMakeResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditMakeComponent;