import axios from "axios";
import PayPinCodeComponent from "./components/payPinCodeComponent";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51NukCVEVP3HcTg1aHpVOHTyiSqxEfA1m4EGAqaFKBJwy5ssADis9ToSjjXCH3cT169Av456ZMWIp8qtT1oGvBORM00hBVNo5te');

const PayPinCodePage = () => {

    const fetchRequest = async (requestId) => {
        const { data } = await axios.get(`/api/vinRequest/get/${requestId}`, { withCredentials: true });
        return data;
    }

    const fetchVinPrice = async (priceId) => {
        const { data } = await axios.get(`/api/vinPrice/get/${priceId}`);
        return data
    }

    const sendPayRequest = async (formInputs) => {
        const { data } = await axios.post(`/api/sitePayment/process-payment-site`, { ...formInputs }, { withCredentials: true });
        return data
    }

    const fetchCodes = async (discountInput) => {
        const { data } = await axios.get(`/api/discountCodes?discountCode=${discountInput}`, { withCredentials: true });
        return data;
    }

    return (
        <Elements stripe={stripePromise}>
            <PayPinCodeComponent
                fetchCodes={fetchCodes}
                fetchRequest={fetchRequest}
                sendPayRequest={sendPayRequest}
                fetchVinPrice={fetchVinPrice}
            />
        </Elements>
    );
};

export default PayPinCodePage;
