import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../redux/actions/action";

const ChangePasswordPopComponent = ({ onConfirm, onCancel }) => {
    const [formState, setFormState] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: ""
    });
    const [passwordsMatchState, setPasswordsMatchState] = useState(true);
    const [showRequirements, setShowRequirements] = useState(false);
    const [showRequirementsOld, setShowRequirementsOld] = useState(false);
    const [passwordsState, setPasswordsState] = useState(true);
    const [passwordComplexity, setPasswordComplexity] = useState({
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
    });

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value,
        });

        if (name === "password" || name === "confirmPassword") {
            setShowRequirements(true);
            setPasswordComplexity({
                uppercase: /[A-Z]/.test(value),
                lowercase: /[a-z]/.test(value),
                number: /\d/.test(value),
                specialChar: /[@$!%*?&]/.test(value),
            });

            setPasswordsMatchState(value === formState.password || value === formState.confirmPassword);
        }

        if (name === "oldPassword") {
            setShowRequirementsOld(true);
            setPasswordsState(value === "");
        }
    };

    const handleSubmitPass = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { oldPassword, password } = formState;

        const formInputs = {
            oldPassword: oldPassword,
            newPassword: password,
        };
       
        dispatch(updatePassword(formInputs, () => {
            onConfirm();
        }));
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder deleteWidth mb-4 ">
                        <div className="col-sm fs-5">
                            Update Password
                        </div>
                    </div>
                    <form onSubmit={handleSubmitPass} className="row mt-4 g-3">
                        <div className="row m-0 p-0">
                            <div className="col mb-2">
                                <input
                                    type="password"
                                    id="oldPassword"
                                    name="oldPassword"
                                    placeholder="Old Password"
                                    value={formState.oldPassword}
                                    onChange={handleChange}
                                    className={`signinFormInput form-control ${!showRequirementsOld ? '' : passwordsState ? 'is-invalid' : 'is-valid'}`}
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                            <input
                                name="password"
                                type="password"
                                placeholder="New Password"
                                minLength={6}
                                value={formState.password}
                                onChange={handleChange}
                                className={`signinFormInput form-control ${!showRequirements ? '' : passwordsMatchState ? 'is-valid' : 'is-invalid'}`}
                                id="password"
                            />
                            {!passwordsMatchState && (
                                <div className="invalid-feedback">Please enter a valid password</div>
                            )}
                            {showRequirements && (
                                <span className="text-muted">
                                    Password should have at least one of each:
                                    <ul className="list-unstyled ms-3">
                                        <li className={`text-${passwordComplexity.uppercase ? 'success' : 'danger'}`}>
                                            {passwordComplexity.uppercase ? '✓' : '✗'} Uppercase letter
                                        </li>
                                        <li className={`text-${passwordComplexity.lowercase ? 'success' : 'danger'}`}>
                                            {passwordComplexity.lowercase ? '✓' : '✗'} Lowercase letter
                                        </li>
                                        <li className={`text-${passwordComplexity.number ? 'success' : 'danger'}`}>
                                            {passwordComplexity.number ? '✓' : '✗'} Number
                                        </li>
                                        <li className={`text-${passwordComplexity.specialChar ? 'success' : 'danger'}`}>
                                            {passwordComplexity.specialChar ? '✓' : '✗'} Special character
                                        </li>
                                    </ul>
                                </span>
                            )}
                        </div>
                        {showRequirements && (
                            <div className="mb-3">
                                <input
                                    name="confirmPassword"
                                    className={`signinFormInput form-control ${passwordsMatchState ? 'is-valid' : 'is-invalid'}`}
                                    type="password"
                                    placeholder="Confirm Password"
                                    minLength={6}
                                    value={formState.confirmPassword}
                                    onChange={handleChange}
                                    id="repeatPassword"
                                />
                                {!passwordsMatchState && (
                                    <div className="invalid-feedback">Both passwords should match</div>
                                )}
                            </div>
                        )}
                        <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                            <div className="col-sm">
                                <button disabled={passwordsMatchState && !showRequirements} className="btn btn-outline-success loginButton" type="submit">
                                    Update Password
                                </button>
                            </div>
                            <div className="col-sm">
                                <button className="btn btn-outline-primary ps-5 pe-5 loginButton" onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
};

export default ChangePasswordPopComponent;