import axios from "axios";
import InventoryItemComponent from "./components/InventoryItemComponent";

const fetchInventory = async (abctrl) => {
    const { data } = await axios.get("/api/inventory/all", { withCredentials: true });
    return data
}

const deleteInventroy = async (inventryId) => {
    const { data } = await axios.delete(`/api/inventory/${inventryId}`, { withCredentials: true });
    return data
}
const fetchTechInfo = async (techId) => {
    const { data } = await axios.get(`/api/techInfo/graph`, { withCredentials: true });
    return data
}
const fetchUsers = async () => {
    const { data } = await axios.get(`/api/users/inventory/`, { withCredentials: true });
    return data
}
const fetchLocation = async (abctrl) => {
    const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
    return data
}
const fetchRequest = async (abctrl) => {
    const { data } = await axios.get("/api/request/", { withCredentials: true });
    return data
}

const InventroyItemPage = () => {

    return <InventoryItemComponent
        fetchTechInfo={fetchTechInfo}
        fetchRequest={fetchRequest}
        fetchUsers={fetchUsers}
        fetchLocation={fetchLocation}
        fetchInventory={fetchInventory}
        deleteInventroy={deleteInventroy}
    />;
};

export default InventroyItemPage;
