import axios from "axios";
import VerifyUserComponent from "./components/verifyUserComponent";

const updateUserPasswordRequest = async (token, password) => {
    const { data } = await axios.post('/api/users/verify', { token, password });
    return data;
};

const VerifyUserPage = () => {

    return (
        <VerifyUserComponent
            updateUserPasswordRequest={updateUserPasswordRequest}
        />
    );
};

export default VerifyUserPage;
