import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useSelector } from 'react-redux';
import { VscGraphLine } from "react-icons/vsc";

const PaymentGraphComponent = ({ fetchUsers, fetchJobItem, fetchDispatch, fetchPayments, fetchTechInfo }) => {
    const [chartData, setChartData] = useState({});
    const [tech, setTech] = useState([]);
    const [users, setUsers] = useState([]);
    const [noData, setNoData] = useState(false);
    const [teching, setTeching] = useState([]);
    const [selectedDays, setSelectedDays] = useState(14);
    const auth = useSelector(state => state.auth)
    const [selectedTech, setSelectedTech] = useState('All Sales');
    const [loading, setLoading] = useState(false);

    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;
    const isAdminOrSuperAdmin = isAdmin || isSuperAdmin;

    useEffect(() => {
        const abctrl = new AbortController();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - selectedDays);
        const fetchAndProcessData = async () => {
            try {
                const [techInfoRes, usersRes, paymentsRes, dispatchRes] = await Promise.all([
                    fetchTechInfo(abctrl, startDate.toISOString()),
                    fetchUsers(abctrl),
                    fetchPayments(abctrl, startDate.toISOString()),
                    fetchDispatch(abctrl, startDate.toISOString())
                ]);

                const filterTech = techInfoRes.filter((tech) => tech.company === auth.user.company);
                const techJobs = filterTech.find((tech) => tech.user === auth.user._id);
                setTeching(filterTech);

                if (techJobs !== undefined) {
                    setTech(techJobs);
                }

                setUsers(usersRes.filter((use) => use.company === auth.user.company));

                // Additional processing for payments and dispatches
                let filteredDispatch = [];
                let filteredPayments = [];

                if (selectedTech !== 'All Sales' && tech !== '') {
                    if (auth.user.userType.isAdmin || auth.user.userType.isSuperAdmin) {
                        filteredDispatch = dispatchRes.filter((job) => job.techs.includes(selectedTech));
                    } else {
                        filteredDispatch = dispatchRes.filter((job) => job.techs.includes(tech._id));
                    }

                    filteredPayments = paymentsRes.filter((pay) =>
                        filteredDispatch.some((dispatch) => dispatch._id === pay.jobId) &&
                        pay.jobId &&
                        pay.status === 'Success'
                    );
                } else {
                    filteredPayments = paymentsRes.filter((pay) =>
                        pay.jobId &&
                        pay.status === 'Success'
                    );
                }

                if (filteredPayments.length === 0) {
                    setNoData(true);
                    setLoading(false);
                } else {
                    setNoData(false);
                    generateChartData(filteredPayments);
                }

                setLoading(false);

            } catch (error) {
                console.error(error.response?.data?.message || error.response?.data || 'Error fetching data');
            }
        };

        fetchAndProcessData();

        if (loading) {
            fetchAndProcessData();
        }
        return () => abctrl.abort();
    }, [selectedDays, loading, auth, selectedTech, fetchDispatch, fetchPayments, fetchTechInfo, fetchJobItem]);

    const generateChartData = (filteredPayments) => {
        const labels = [];
        const openJobsData = [];
        const closedJobsData = [];

        for (let i = selectedDays - 1; i >= 0; i--) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            const options = { month: 'short', day: 'numeric' };
            const formattedDate = date.toLocaleDateString(undefined, options);
            labels.push(formattedDate);

            const jobsForDate = filteredPayments.filter((pay) => {
                const jobDate = new Date(pay.updatedAt);
                return (
                    jobDate.getFullYear() === date.getFullYear() &&
                    jobDate.getMonth() === date.getMonth() &&
                    jobDate.getDate() === date.getDate()
                );
            });

            openJobsData.push(jobsForDate.reduce((sum, job) => sum + job.amount / 100, 0));
            closedJobsData.push(jobsForDate.reduce((sum, job) => sum + job.cost, 0));
        }

        setChartData({
            labels: labels,
            datasets: [
                {
                    label: 'Total',
                    data: openJobsData,
                    backgroundColor: ['rgba(255, 236, 0, 0.8)'],
                    borderColor: ['rgba(255, 236, 0, 0.8)'],
                    borderWidth: 1,
                    barThickness: 8,
                    barPercentage: 1,
                },
                {
                    label: 'Costs',
                    data: closedJobsData,
                    backgroundColor: ['rgba(45, 255, 0, 0.8)'],
                    borderColor: ['rgba(45, 255, 0, 0.8)'],
                    borderWidth: 1,
                    barThickness: 8,
                    barPercentage: 1,
                },
            ],
        });
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
            title: {
                display: true,
                align: 'start',
                text: 'Sales',
                font: {
                    size: 17,
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': $';
                        }
                        let value = context.parsed.y;
                        if (value !== null && !isNaN(value)) {
                            label += value.toFixed(2);
                        } else {
                            label += '0.00';
                        }
                        return label;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
            },
        },
    };

    const handleTechSelection = (event) => {
        const selectedTech = event.target.value;

        setSelectedTech(selectedTech)
        setLoading(true);
    };

    const getTechName = (techId) => {
        const techUser = users.find((user) => user._id === techId);
        return techUser ? `${techUser.name} ${techUser.lastName}` : "";
    };

    return (
        <>
            <div className="row d-flex justify-content-end">
                {isAdminOrSuperAdmin && (
                    <div className="col-sm-2">
                        <select
                            onChange={handleTechSelection}
                            disabled={noData}
                            className="form-select form-select-sm"
                        >
                            <option value='All Sales'>All Sales</option>
                            {teching.map((tech) => (
                                <option key={tech._id} value={tech._id}>
                                    {getTechName(tech.user)}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <div className="col-sm-2">
                    <select
                        value={selectedDays}
                        disabled={noData}
                        className="form-select form-select-sm"
                        onChange={(e) => setSelectedDays(Number(e.target.value))}
                    >
                        <option value={7}>This Week</option>
                        <option value={14}>Last 14 Days</option>
                        <option value={30}>This Month</option>
                        <option value={90}>Last 3 Months</option>
                    </select>
                </div>
            </div>
            {!noData ? (
                <div className="row">
                    <>
                        {chartData.labels && chartData.datasets ? (
                            <div className="mb-5">
                                <div className="row w-100 m-0 p-0">
                                    <div>
                                        <Bar className="piePayWidth" data={chartData} options={options} />
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </>
                </div >
            ) : (
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row d-flex justify-content-start">
                            <div className="col-sm-12 text-start m-0 p-0">
                                <h5><ins>Sales:</ins></h5>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center m-0 p-0">
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <VscGraphLine className="inventoryLogo fs-1" />
                                </div>
                            </div>
                            <div className="row d-flex mt-3 justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <span>Currently no data to show.</span> <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PaymentGraphComponent;
