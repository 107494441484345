import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import paths from "../../../router/paths";
import RowPrintComponent from "../../../components/rowPrintComponent";
import InventoryLinksComponent from "../../../components/inventory/inventoryLinksComponent";
import InventoryDeletePopUpComponent from '../../../components/inventory/inventoryDeletePopComponent';

const InventoryItemComponent = ({ fetchInventory, deleteInventroy, fetchRequest, fetchLocation, fetchTechInfo, fetchUsers }) => {
    const [inventory, setInventory] = useState([]);
    const auth = useSelector(state => state.auth);
    const [inventoryDeleted, setInventoryDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [selectedLocation, setSelectedLocation] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [inventryId, setInventryId] = useState("");
    const [request, setRequest] = useState([]);
    const [filterLocations, setFilterLocations] = useState([]);
    const [locations, setLocations] = useState([]);
    const [inventorySet, setInventorySet] = useState([]);
    const [userData, setUserData] = useState([]);
    const [techData, setTechData] = useState([]);

    const deleteHandler = async (inventryId) => {
        setInventryId(inventryId);
        setShowAlert(true);
    };

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchInventory(abctrl)
            .then((res) => {
                const filteredInventory = res.filter((item) => item.company === auth.user.company);

                if (selectedLocation) {
                    const inventoryWithSelectedLocationSet = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.quantity <= location.minimunStock1
                        )
                    );

                    const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === selectedLocation
                        )
                    );

                    setInventorySet(inventoryWithSelectedLocationSet)
                    setInventory(inventoryWithSelectedLocation);
                } else {

                    setInventory(filteredInventory);
                }
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        fetchUsers()
            .then((data) => {
                const filteredUser = data.find((item) => item._id === auth.user._id);

                fetchTechInfo()
                    .then((resData) => {
                        const filteredTech = resData.find((item) => item.user === filteredUser._id);

                        fetchLocation()
                            .then((dataRes) => {
                                if (filteredTech) {
                                    const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);

                                    if (filteredLocation) {
                                        setFilterLocations(filteredLocation);
                                    } else {
                                        setFilterLocations([]);
                                    }
                                }
                                const filtered = dataRes.filter((item) => item.company === auth.user.company);

                                setLocations(filtered);
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                        fetchRequest()
                            .then((dataResRes) => {
                                if (dataResRes.message !== 'request not found') {
                                    const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                    setRequest(filteredRequest)
                                } else {
                                    setRequest([])
                                }
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );

            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [deleteInventroy, fetchInventory, auth.user.company, selectedLocation, fetchUsers, auth.user._id, fetchTechInfo, fetchLocation, fetchRequest]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchInventory(abctrl)
                .then((res) => {
                    const filteredInventory = res.filter((item) => item.company === auth.user.company);

                    if (selectedLocation) {
                        const inventoryWithSelectedLocationSet = filteredInventory.filter((item) =>
                            item.InventoryLocation.some((location) =>
                                location.quantity <= location.minimunStock1
                            )
                        );

                        const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                            item.InventoryLocation.some((location) =>
                                location.name1 === selectedLocation
                            )
                        );

                        setInventorySet(inventoryWithSelectedLocationSet)
                        setInventory(inventoryWithSelectedLocation);
                    } else {

                        setInventory(filteredInventory);
                    }

                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            fetchUsers()
                .then((data) => {
                    const filteredUser = data.find((item) => item._id === auth.user._id);
                    setUserData(data);

                    fetchTechInfo()
                        .then((resData) => {
                            setTechData(resData);
                            const filteredTech = resData.find((item) => item.user === filteredUser._id);

                            fetchLocation()
                                .then((dataRes) => {
                                    const filtered = dataRes.filter((item) => item.company === auth.user.company);

                                    if (filteredTech) {
                                        const filteredLocation = filtered.find((item) => item.tech === filteredTech._id);

                                        if (filteredLocation) {
                                            setFilterLocations(filteredLocation);
                                        } else {
                                            setFilterLocations([]);
                                        }
                                    }

                                    setLocations(filtered);

                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                            fetchRequest()
                                .then((dataResRes) => {
                                    if (dataResRes.message !== 'request not found') {
                                        const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                        setRequest(filteredRequest)
                                    } else {
                                        setRequest([])
                                    }
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );

                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [deleteInventroy, fetchInventory, auth.user.company, selectedLocation, isLoading, fetchUsers, auth.user._id, fetchTechInfo, fetchLocation, fetchRequest]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };

    const componentRef = useRef(null);

    const handleCancelRemoveItem = () => {
        setShowAlert(false);
    };

    const handleConfirmRemoveItem = async () => {
        const data = await deleteInventroy(inventryId);
        if (data === 'inventory deleted successfully') {
            setInventoryDeleted(!inventoryDeleted);
        }
        setShowAlert(false);
        setIsLoading(true);
    };

    const requestDataLenStatus = () => {
        const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.location && filterLocations.tech === locc.tech));
        const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');
        return filteredStatus === undefined ? 0 : filteredStatus.length;
    };

    const requestDataLenInStatus = () => {
        const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.toLocation));
        const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');
        return filteredStatus === undefined ? 0 : filteredStatus.length;
    };

    return (
        <div className="min-vh-100">
            {showAlert && (
                <InventoryDeletePopUpComponent
                    title="Delete item"
                    onConfirm={handleConfirmRemoveItem}
                    onCancel={handleCancelRemoveItem}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <InventoryLinksComponent inventorySet={inventorySet} requestDataLenStatus={requestDataLenStatus} requestDataLenInStatus={requestDataLenInStatus} />
                </div>
                <div className="col-sm-10 ms-0 ps-0 mt-5 pt-5">
                    <div className="row m-0 p-0 w-100 mb-3">
                        <div className="col-sm-7 m-0 p-0">
                            <h1>Inventory List</h1>
                        </div>
                        <div className="col-sm-2 text-end m-0 pe-3 p-0">
                            <select
                                id="Location"
                                className="form-select"
                                aria-label="Location:"
                                value={selectedLocation || ''}
                                onChange={(e) => setSelectedLocation(e.target.value)}
                            >
                                <option value=''>All Inventory Items</option>
                                {locations.map((location, index) => {
                                    const filteredTech = techData.find((techItem) => techItem._id === location.tech);

                                    if (filteredTech && location.van) {
                                        const UserFilter = userData.find((item) => filteredTech.user === item._id)

                                        const newName = UserFilter ? `${UserFilter.name} Van` : '';

                                        return (
                                            <option key={location._id} value={location.id}>
                                                {newName}
                                            </option>
                                        )
                                    } else {
                                        return (
                                            <option key={location._id} value={location.id}>
                                                {location.name}
                                            </option>
                                        )
                                    }
                                })}
                            </select>
                        </div>
                        <div className="col-sm-2 align-end m-0 p-0">
                            <input
                                type="text"
                                className="mt-1 me-4 input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Inventory.."
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1 text-start ps-3 m-0 p-0">
                            <Link to={paths.INVENTORYADD} className="btn btn-outline-primary loginButton">New</Link>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <div className="table-responsive">
                            {selectedLocation === '' ? (
                                <table ref={componentRef} className="table-hover table-bordered order-table table">
                                    <thead>
                                        <tr>
                                            <th className="text-start ps-4"> Inventory Item Name</th>
                                            <th className="text-center">Price</th>
                                            <th className="text-center">Edit/Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inventory
                                            .filter(
                                                (inventorys) =>
                                                    inventorys.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                                    inventorys.barcode.toLowerCase() === searchValue ||
                                                    inventorys.InventoryLocation.some((location) =>
                                                        location.location1.toLowerCase() === searchValue
                                                    )
                                            )
                                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                            .map((inventorys, idx) => (
                                                <>
                                                    <tr key={idx}>
                                                        <td className="align-middle text-start">{inventorys.name}</td>
                                                        <td className="align-middle text-center">
                                                            {Number(inventorys.price).toFixed(2)}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <Link to={`/root/edit/inventory/${inventorys._id}`}>
                                                                <button className="btn-sm btn btn-outline-primary">
                                                                    <GoPencil />
                                                                </button>
                                                            </Link>
                                                            {" / "}
                                                            <button
                                                                type="button"
                                                                className="btn-sm btn btn-outline-danger"
                                                                onClick={() => deleteHandler(inventorys._id)}
                                                            >
                                                                <BsTrash />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                    </tbody>
                                </table>
                            ) : (
                                <table ref={componentRef} className="table-hover table-bordered order-table table">
                                    <thead>
                                        <tr>
                                            <th className="text-start ps-4"> Inventory Item Name</th>
                                            <th className="text-center">Location</th>
                                            <th className="text-center">Stock</th>
                                            <th className="text-center">Price</th>
                                            <th className="text-center">AKL Price</th>
                                            <th className="text-center">Cost</th>
                                            <th className="text-center">Barcode</th>
                                            <th className="text-center">Edit/Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inventory
                                            .filter(
                                                (inventorys) =>
                                                    inventorys.name.toLowerCase() === searchValue ||
                                                    inventorys.barcode.toLowerCase() === searchValue ||
                                                    inventorys.InventoryLocation.some((location) =>
                                                        location.location1.toLowerCase() === searchValue
                                                    )
                                            )
                                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                            .map((inventorys, idx) => (
                                                <>
                                                    <tr key={idx}>
                                                        <td className="align-middle text-start">{inventorys.name}</td>
                                                        {inventorys.InventoryLocation.filter((location) => location.name1 === selectedLocation).map((inv, idxx) => (
                                                            <>
                                                                <td className="align-middle text-center">
                                                                    {inv.location1 !== '' ? (
                                                                        <>
                                                                            {inv.location1}
                                                                        </>
                                                                    ) : (
                                                                        "-"
                                                                    )}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {inv.quantity !== '' ? (
                                                                        <>
                                                                            {inv.quantity}
                                                                        </>
                                                                    ) : (
                                                                        "-"
                                                                    )}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {Number(inventorys.price).toFixed(2)}
                                                                </td>
                                                                {inventorys.allKeysLostPrice && inventory.vehicleInven ? (
                                                                    <td className="align-middle text-center">
                                                                        {Number(inventorys.allKeysLostPrice).toFixed(2)}
                                                                    </td>
                                                                ) : (
                                                                    <td className="align-middle text-center">
                                                                        ---
                                                                    </td>
                                                                )}
                                                                <td className="align-middle text-center">
                                                                    {inv.quantity !== '' ? (
                                                                        <>
                                                                            {inv.ourCost1}
                                                                        </>
                                                                    ) : (
                                                                        "-"
                                                                    )}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    <RowPrintComponent selectedLocation={selectedLocation} inventory={inventorys} />

                                                                </td>
                                                            </>
                                                        ))}
                                                        <td className="align-middle text-center">
                                                            <Link to={`/root/edit/inventory/${inventorys._id}`}>
                                                                <button className="btn-sm btn btn-outline-primary">
                                                                    <GoPencil />
                                                                </button>
                                                            </Link>
                                                            {" / "}
                                                            <button
                                                                type="button"
                                                                className="btn-sm btn btn-outline-danger"
                                                                onClick={() => deleteHandler(inventorys._id)}
                                                            >
                                                                <BsTrash />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                    </tbody>
                                </table>
                            )}
                        </div >
                    )}
                    {inventory.filter(inv => inv.InventoryLocation.some(loc => loc.name1 === selectedLocation && loc.dontStock)).length > 0 ? (
                        <div className="d-flex justify-content-center mb-3 pb-4">
                            <button
                                className="btn btn-sm btn-outline-primary pe-2 ps-2 mr-2 p-0 m-0 me-3 loginButton"
                                disabled={page === 1}
                                onClick={handlePrevPage}
                            >
                                Previous
                            </button>
                            <span className="mr-2 me-3">
                                Page {page} of {Math.ceil(inventory
                                    .filter(
                                        (inventorys) =>
                                            inventorys.name.toLowerCase() === searchValue ||
                                            inventorys.barcode.toLowerCase() === searchValue ||
                                            inventorys.InventoryLocation.some((location) =>
                                                location.location1.toLowerCase() === searchValue
                                            )
                                    ).length / rowsPerPage)}
                            </span>
                            <button
                                className="btn btn-sm btn-outline-primary pe-4 ps-4 mr-2 p-0 m-0 me-3 loginButton"
                                disabled={inventory
                                    .filter(
                                        (inventorys) =>
                                            inventorys.name.toLowerCase() === searchValue ||
                                            inventorys.barcode.toLowerCase() === searchValue ||
                                            inventorys.InventoryLocation.some((location) =>
                                                location.location1.toLowerCase() === searchValue
                                            )
                                    ).length <= page * rowsPerPage}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center mb-3 pb-4">
                            <button
                                className="btn btn-sm btn-outline-primary pe-2 ps-2 mr-2 p-0 m-0 me-3 loginButton"
                                disabled={page === 1}
                                onClick={handlePrevPage}
                            >
                                Previous
                            </button>
                            <span className="mr-2 me-3">
                                Page {page} of {Math.ceil(inventory
                                    .filter(
                                        (inventorys) =>
                                            inventorys.name.toLowerCase() === searchValue ||
                                            inventorys.barcode.toLowerCase() === searchValue ||
                                            inventorys.InventoryLocation.some((location) =>
                                                location.location1.toLowerCase() === searchValue
                                            )
                                    ).length / rowsPerPage)}
                            </span>
                            <button
                                className="btn btn-sm btn-outline-primary pe-4 ps-4 mr-2 p-0 m-0 me-3 loginButton"
                                disabled={inventory
                                    .filter(
                                        (inventorys) =>
                                            inventorys.name.toLowerCase() === searchValue ||
                                            inventorys.barcode.toLowerCase() === searchValue ||
                                            inventorys.InventoryLocation.some((location) =>
                                                location.location1.toLowerCase() === searchValue
                                            )
                                    ).length <= page * rowsPerPage}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div >
            </div >
        </div >
    );
};

export default InventoryItemComponent;