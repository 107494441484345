import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { useSelector } from 'react-redux';
import { BiSolidPieChartAlt2 } from "react-icons/bi";

const JobSourceGraphComponent = ({ fetchDispatch, fetchJobSource, fetchTechInfo }) => {
    const [chartData, setChartData] = useState({});
    const [tech, setTech] = useState([]);
    const [noData, setNoData] = useState(false);
    const [selectedDays, setSelectedDays] = useState(14);

    const auth = useSelector(state => state.auth)
    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isDispatcher = auth.user?.usertype?.includes('Dispatcher') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;
    const isAdminOrSuperAdmin = isAdmin || isSuperAdmin || isDispatcher;

    useEffect(() => {
        const abctrl = new AbortController();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - selectedDays);
        const formattedStartDate = startDate.toISOString();

        Promise.all([
            fetchJobSource(abctrl.signal, formattedStartDate),
            fetchTechInfo(abctrl.signal, formattedStartDate),
            fetchDispatch(abctrl.signal, formattedStartDate)
        ])
            .then(([jobSourceData, techInfoData, dispatchData]) => {
                const filteredJobSource = jobSourceData.filter(job => job.company === auth.user.company);
                const techJobs = techInfoData.find(tech => tech.user === auth.user._id);
                setTech(techJobs);
                const filteredDispatch = dispatchData.filter(job => job.company === auth.user.company);

                // Process data for chart visualization
                processDataForChart(filteredJobSource, filteredDispatch, techJobs);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setNoData(true);
            });

        function processDataForChart(jobSources, dispatches, techData) {
            if (dispatches.length > 0) {
                const jobSourcesNames = jobSources.map(job => job.name);
                const jobSourcesIds = jobSources.map(job => job._id);

                const customColors = [
                    'rgba(95, 189, 209, 0.8)',
                    'rgba(246, 77, 227, 0.8)',
                    'rgba(184, 242, 5, 0.8)',
                    'rgba(181, 50, 17, 0.8)',
                    'rgba(16, 108, 239, 0.8)',
                    'rgba(162, 189, 210, 0.8)',
                    'rgba(26, 166, 253, 0.8)',
                    'rgba(72, 188, 151, 0.8)',
                    'rgba(176, 221, 68, 0.8)',
                    'rgba(228, 108, 62, 0.8)',
                    'rgba(154, 89, 226, 0.8)',
                    'rgba(125, 4, 199, 0.8)',
                    'rgba(137, 215, 1, 0.8)',
                    'rgba(237, 113, 245, 0.8)',
                    'rgba(205, 171, 44, 0.8)',
                    'rgba(10, 49, 86, 0.8)',
                    'rgba(193, 194, 202, 0.8)',
                    'rgba(172, 62, 172, 0.8)',
                    'rgba(76, 204, 11, 0.8)',
                    'rgba(253, 177, 147, 0.8)',
                    'rgba(183, 38, 191, 0.8)',
                    'rgba(162, 191, 146, 0.8)',
                    'rgba(82, 225, 164, 0.8)',
                    'rgba(113, 208, 199, 0.8)',
                    'rgba(112, 37, 244, 0.8)',
                    'rgba(162, 61, 19, 0.8)',
                    'rgba(214, 72, 227, 0.8)',
                    'rgba(84, 63, 143, 0.8)',
                    'rgba(203, 210, 190, 0.8)',
                    'rgba(29, 183, 177, 0.8)',
                    'rgba(66, 242, 59, 0.8)',
                    'rgba(140, 176, 244, 0.8)',
                    'rgba(243, 37, 13, 0.8)',
                    'rgba(3, 176, 196, 0.8)',
                    'rgba(226, 70, 155, 0.8)',
                    'rgba(236, 40, 139, 0.8)',
                    'rgba(98, 143, 3, 0.8)',
                    'rgba(192, 125, 59, 0.8)',
                    'rgba(249, 0, 55, 0.8)',
                    'rgba(96, 91, 21, 0.8)',
                    'rgba(210, 209, 182, 0.8)',
                    'rgba(129, 134, 31, 0.8)',
                    'rgba(160, 236, 203, 0.8)',
                    'rgba(98, 12, 170, 0.8)',
                    'rgba(132, 192, 242, 0.8)',
                    'rgba(238, 51, 136, 0.8)',
                    'rgba(72, 72, 111, 0.8)',
                    'rgba(219, 147, 248, 0.8)',
                    'rgba(157, 103, 48, 0.8)',
                    'rgba(161, 45, 140, 0.8)'
                ];

                if (isAdminOrSuperAdmin) {
                    const datasets = jobSourcesIds.map((id, index) => mapJobDataToDates(dispatches, id));

                    setChartData({
                        labels: jobSourcesNames,
                        datasets: [{
                            label: 'Job Count by Source',
                            data: datasets,
                            backgroundColor: customColors,
                            borderColor: customColors,
                            borderWidth: 1,
                        }]
                    });
                } else {
                    if (techData && techData._id) {
                        const techDispatches = dispatches.filter(job => job.techs.includes(techData._id));
                        const datasets = jobSourcesIds.map((id, index) => mapJobDataToDates(techDispatches, id));

                        setChartData({
                            labels: jobSourcesNames,
                            datasets: [{
                                label: 'Job Count by Source',
                                data: datasets,
                                backgroundColor: customColors,
                                borderColor: customColors,
                                borderWidth: 1,
                            }]
                        });
                    } else {
                        setNoData(true);  // Handle case where tech has no jobs
                    }
                }
            } else {
                setNoData(true);
            }
        }

        function mapJobDataToDates(jobs, sourceId) {
            let JobData = [];
            for (let i = (selectedDays - 1); i >= 0; i--) {
                const date = new Date();
                date.setDate(date.getDate() - i);
                const jobsForDate = jobs.filter(job => {
                    const jobDate = new Date(job.updatedAt);
                    return jobDate.getFullYear() === date.getFullYear() &&
                        jobDate.getMonth() === date.getMonth() &&
                        jobDate.getDate() === date.getDate() &&
                        job.lead === sourceId;
                });
                JobData = JobData.concat(jobsForDate);
            }
            return JobData.length;
        }

        return () => abctrl.abort();  // Cleanup function to abort fetch requests on component unmount
    }, [fetchDispatch, fetchTechInfo, fetchJobSource, selectedDays, auth]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'left',
                labels: {
                    usePointStyle: true,
                },
            },
            title: {
                display: false,
                align: 'start',
                text: 'Job Sources:',
                font: {
                    size: 17, // Adjust the font size as needed
                },
            },

        },
    };

    return (
        <>
            {!noData ? (
                <div>
                    {isAdminOrSuperAdmin ? (
                        <>
                            {chartData.labels && chartData.datasets ? (
                                <div className="mb-5">
                                    <div className="row d-flex justify-content-end">
                                        <div className="col-sm-9 m-0 p-0">
                                            <h5>Job Sources:</h5>
                                        </div>
                                        <div className="col-sm-3 m-0 p-0">
                                            <select className="form-select form-select-sm" value={selectedDays} onChange={(e) => setSelectedDays(Number(e.target.value))}>
                                                <option value={7}>This Week</option>
                                                <option value={14}>Last 14 Days</option>
                                                <option value={30}>This Month</option>
                                                <option value={90}>Last 3 Months</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Pie className="pieJobSource" data={chartData} options={options} />
                                    </div>
                                </div>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </>
                    ) : (
                        <>
                            {tech && tech.user ? (
                                <>
                                    {chartData.labels && chartData.datasets ? (
                                        <div>
                                            <div className="mb-5">
                                                <div className="row d-flex justify-content-end">
                                                    <div className="col-sm-2 m-0 p-0">
                                                        <select className="form-select form-select-sm" value={selectedDays} onChange={(e) => setSelectedDays(Number(e.target.value))}>
                                                            <option value={7}>This Week</option>
                                                            <option value={14}>Last 14 Days</option>
                                                            <option value={30}>This Month</option>
                                                            <option value={90}>Last 3 Months</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <Pie className="pieJobSource" data={chartData} options={options} />
                                            </div>
                                        </div>
                                    ) : (
                                        <p>Loading...</p>
                                    )}
                                </>
                            ) : (
                                <div className="row">
                                    <div className="pieSizeNon">
                                        <div className="row d-flex justify-content-start">
                                            <div className="col-sm-7 m-0 p-0">
                                                <h5>Job Sources:</h5>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-start">
                                            <div className="col-sm-10 m-0 p-0">
                                                <span className="ms-3">User not assigned to a technician roles.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div >
            ) : (
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row d-flex justify-content-start">
                            <div className="col-sm-7 text-start">
                                <h5><ins>Job Sources:</ins></h5>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center m-0 p-0">
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <BiSolidPieChartAlt2 className="inventoryLogo fs-1" />
                                </div>
                            </div>
                            <div className="row d-flex mt-3 justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <span>Currently no data to show.</span> <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default JobSourceGraphComponent;
