import React, { useState, useEffect, useRef } from 'react';
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import EmojiPicker from 'emoji-picker-react';
import { MdAttachFile } from "react-icons/md";
import { BsEmojiSmile } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { IoTimeOutline } from "react-icons/io5";
import axios from 'axios';
import DeleteTaskChatConfirmationModal from './deleteTaskChatComponent';
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { LuPencil } from "react-icons/lu";

const TaskChatComponent = ({ updateTaskChatAllApiRequest, deleteTaskChat, fetchUploadsImage, deleteTaskChatLike, newTaskChat, fetchTaskChat, fetchTaskChatLike, taskId, onCancel, updateTaskChatIconApiRequest, createTaskIconApiRequest }) => {
    const [image, setImage] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [newMessageEdit, setNewMessageEdit] = useState('');
    const [newMessageReply, setNewMessageReply] = useState('');
    const auth = useSelector(state => state.auth)
    const [editMode, setEditMode] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteTaskId, setDeleteTaskId] = useState("");
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [editModeReply, setEditModeReply] = useState(false);
    const [openReplyId, setOpenReplyId] = useState(null);
    const [editReplyId, setEditRepltId] = useState('');
    const [openDropdownReplyId, setOpenDropdownReplyId] = useState(null);

    const fileInputRef = useRef(null);
    const optionsRef = useRef(null);
    const emojiPickerRef = useRef(null);
    const textOpt = useRef(null);
    const replyTextOpt = useRef(null);
    const dropDownDots = useRef(null);
    const textEditOpt = useRef(null);
    const dotsReply = useRef(null);

    const toggleDropdownDots = (id) => {
        if (openDropdownId === id) {
            setOpenDropdownId(null);
        } else {
            setOpenDropdownId(id);
        }
    };

    const toggleDropdown = (taskId) => {
        setSelectedTaskId(taskId);
        const content = document.getElementById(`dropdown-content-${taskId}`);
        content.style.display = content.style.display === 'block' ? 'none' : 'block';
    };

    const handleAddFilesClick = () => {
        setShowOptions(!showOptions);
    };

    const handleOptionSelect = (source) => {
        setShowOptions(false);
        if (source === 'computer') {
            fileInputRef.current.click();
        } else if (source === 'google-drive') {
            console.log('Open Google Drive picker');
        }
    };

    const uploadFile = async (event) => {
        const file = event.target.files[0];

        const imageFormData = new FormData();
        imageFormData.append("file", file);
        imageFormData.append("company", auth.user.company);

        try {
            const response = await axios.post("/api/uploads/add", imageFormData);
            const data = await fetchUploadsImage(response.data.file._id);

            setImage(data);

        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
    };

    const handleClickOutside = (event, taskId) => {
        if (optionsRef.current && !optionsRef.current.contains(event.target)) {
            setShowOptions(false);
        }
        if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
            setShowEmojiPicker(false);
        }
        if (textOpt.current && !textOpt.current.contains(event.target)) {
            setEditMode(false);
        }
        if (replyTextOpt.current && !replyTextOpt.current.contains(event.target)) {
            setOpenReplyId(false);
        }
        if (dropDownDots.current && !dropDownDots.current.contains(event.target)) {
            setOpenDropdownId(null);
        }

        //if (dotsReply.current && !dotsReply.current.contains(event.target)) {
        //    setOpenDropdownReplyId(null);
        //}

        const content = document.getElementById(`dropdown-content-${taskId}`);
        if (content && !content.contains(event.target)) {
            content.style.display = 'none';
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const activateEditMode = () => {
        setEditMode(true);
    };

    const onEmojiClick = (emojiObject) => {
        setNewMessage(prevInput => prevInput + emojiObject.emoji);
        setShowEmojiPicker(false);
    };

    useEffect(() => {
        const taskId = selectedTaskId;
        document.addEventListener('mousedown', (event) => handleClickOutside(event, taskId));
        return () => {
            document.removeEventListener('mousedown', (event) => handleClickOutside(event, taskId));
        };
    }, [selectedTaskId]);

    useEffect(() => {
        const abctrl = new AbortController();

        const fetchAndUpdateMessages = async () => {
            try {

                const data = await fetchTaskChat(taskId._id);

                const updatedMessages = await Promise.all(data.map(async (task) => {
                    if (task.icon) {
                        const icons = await Promise.all(task.icon.map(fetchTaskChatLike));
                        return { ...task, icon: icons };
                    }
                    return task;
                }));

                setMessages(updatedMessages);
                setLoading(false);
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message;
                console.error(errorMessage);
                setLoading(false);
            }
            setLoading(false);

        };

        fetchAndUpdateMessages();

        if (loading) {
            fetchAndUpdateMessages();
        }
        return () => abctrl.abort();
    }, [fetchTaskChat, taskId, fetchTaskChatLike, loading]);

    const sendMessage = async () => {

        if (newMessage) {

            const formInputs = {
                sender: auth.user.name + ' ' + auth.user.lastName,
                content: newMessage,
                company: auth.user.company,
                user: auth.user._id,
                taskId: taskId._id,
                reply: false,
                uploadId: image,
            }

            newTaskChat(taskId._id, formInputs)
                .then((data) => {
                    if (data.message === "taskChat created") {
                        setNewMessage('');
                        setImage(null);
                        setEditMode(false);
                        setMessages([]);
                        setLoading(true);
                    };
                })
                .catch((er) => {
                    console.log({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
    };

    const sendMessageReply = async (msg) => {

        if (newMessageReply) {

            const formInputs = {
                sender: auth.user.name + ' ' + auth.user.lastName,
                content: newMessageReply,
                company: auth.user.company,
                user: auth.user._id,
                taskId: taskId._id,
                reply: true,
                uploadId: image,
                replyId: msg._id
            }

            newTaskChat(taskId._id, formInputs)
                .then((data) => {
                    if (data.message === "taskChat created") {
                        setNewMessageReply('');
                        setImage(null);
                        setMessages([]);
                        setLoading(true);
                    };
                })
                .catch((er) => {
                    console.log({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
    };

    const handleFocus = () => {
        setEditMode(true);
    };

    const getTimeDisplay = (timestamp) => {
        const messageDate = new Date(timestamp);
        const now = new Date();
        const diffInSeconds = Math.floor((now - messageDate) / 1000);

        if (diffInSeconds < 60) {
            return 'now';
        } else if (diffInSeconds < 3600) { // Less than 1 hour
            return Math.floor(diffInSeconds / 60) + 'm';
        } else if (diffInSeconds < 86400) { // Less than 24 hours
            return Math.floor(diffInSeconds / 3600) + 'h';
        } else {
            return Math.floor(diffInSeconds / 86400) + 'd';
        }
    }

    const onEmojiLikeClick = async (msg, emojiObject) => {

        if (msg.icon.length === 0) {

            const formInputsIcon = {
                icon: emojiObject.imageUrl,
                sender: auth.user.name + ' ' + auth.user.lastName,
                user: auth.user._id,
                company: auth.user.company,
                type: emojiObject.names[1],
            };

            const update = await createTaskIconApiRequest(formInputsIcon);

            if (update.message === "taskChat created") {

                const formInputsIconLike = {
                    icon: update._id,
                };

                const updateChat = await updateTaskChatIconApiRequest(msg._id, formInputsIconLike)

                if (updateChat.message === "taskChat updated") {

                    const content = document.getElementById(`dropdown-content-${selectedTaskId}`);
                    if (content !== null) {
                        content.style.display = 'none';
                        setMessages([]);
                        setLoading(true);
                    } else {
                        setMessages([]);
                        setLoading(true);
                    }
                }
            }
        } else if (Array.isArray(msg.icon) && msg.icon.some(icon => icon.type === emojiObject.names[1] && icon.user === auth.user._id)) {
            const matchingIcon = msg.icon.find(icon => icon.type === emojiObject.names[1] && icon.user === auth.user._id);

            const update = await deleteTaskChatLike(matchingIcon._id);

            if (update.message === "TaskChat deleted successfully") {
                let iconIds = [];

                if (msg.icon && Array.isArray(msg.icon)) {
                    iconIds = msg.icon.map(icon => icon._id).filter(id => id !== matchingIcon._id);
                }

                const formInputsIconLike = {
                    icon: iconIds,
                };

                const updateChat = await updateTaskChatIconApiRequest(msg._id, formInputsIconLike)

                if (updateChat.message === "taskChat updated") {

                    const content = document.getElementById(`dropdown-content-${selectedTaskId}`);
                    if (content !== null) {
                        content.style.display = 'none';
                        setMessages([]);
                        setLoading(true);
                    } else {
                        setMessages([]);
                        setLoading(true);
                    }
                }
            }
        } else if (Array.isArray(msg.icon) && msg.icon.some(icon => icon.type === emojiObject.names[1] && icon.user !== auth.user._id)) {

            const formInputsIcon = {
                icon: emojiObject.imageUrl,
                sender: auth.user.name + ' ' + auth.user.lastName,
                user: auth.user._id,
                company: auth.user.company,
                type: emojiObject.names[1],
            };

            const update = await createTaskIconApiRequest(formInputsIcon);

            if (update.message === "taskChat created") {

                let iconIds = [];

                if (msg.icon && Array.isArray(msg.icon)) {
                    iconIds = msg.icon.map(icon => icon._id);
                }

                iconIds.push(update._id);

                const formInputsIconLike = {
                    icon: iconIds,
                };

                const updateChat = await updateTaskChatIconApiRequest(msg._id, formInputsIconLike)

                if (updateChat.message === "taskChat updated") {

                    const content = document.getElementById(`dropdown-content-${selectedTaskId}`);
                    if (content !== null) {
                        content.style.display = 'none';
                        setMessages([]);
                        setLoading(true);
                    } else {
                        setMessages([]);
                        setLoading(true);
                    }
                }
            }
        } else if (Array.isArray(msg.icon) && msg.icon.some(icon => icon.type !== emojiObject.names[1] && icon.user === auth.user._id)) {

            const formInputsIcon = {
                icon: emojiObject.imageUrl,
                sender: auth.user.name + ' ' + auth.user.lastName,
                user: auth.user._id,
                company: auth.user.company,
                type: emojiObject.names[1],
            };

            const update = await createTaskIconApiRequest(formInputsIcon);

            if (update.message === "taskChat created") {

                let iconIds = [];

                if (msg.icon && Array.isArray(msg.icon)) {
                    iconIds = msg.icon.map(icon => icon._id);
                }

                iconIds.push(update._id);

                const formInputsIconLike = {
                    icon: iconIds,
                };

                const updateChat = await updateTaskChatIconApiRequest(msg._id, formInputsIconLike)

                if (updateChat.message === "taskChat updated") {

                    const content = document.getElementById(`dropdown-content-${selectedTaskId}`);
                    if (content !== null) {
                        content.style.display = 'none';
                        setMessages([]);
                        setLoading(true);
                    } else {
                        setMessages([]);
                        setLoading(true);
                    }
                }
            }
        }
    };

    const handleRemoveTask = (id) => {
        setDeleteTaskId(id);
        setShowConfirmation(true);
    };

    const handleConfirmRemoveTask = async () => {

        if (deleteTaskId.uploadId.length > 0) {

            const updatedDelete = await Promise.all(deleteTaskId.uploadId.map(async (file) => {

                const icons = await axios.delete(`/api/uploads/${file._id}`);

                if (icons.data.message === 'File deleted') {
                    const data = await deleteTaskChat(deleteTaskId._id);

                    return { data };
                }
            }))

            if (updatedDelete[0].data.message === 'TaskChat deleted successfully') {
                setShowConfirmation(false);
                setMessages([]);
                setLoading(true);
            }
        }

    };

    const handleCancelRemoveTask = () => {
        setShowConfirmation(false);
    };

    const handleReplyInputChange = (e) => {
        setNewMessageReply(e.target.value);
    };

    const toggleDropdownReply = (id) => {

        if (openReplyId === id) {
            setOpenReplyId(null);
        } else {
            setOpenReplyId(id);
        }
    };

    const handleEditTask = (msg) => {
        console.log(msg)
        setEditRepltId(msg._id);
        setNewMessageEdit(msg.content);
        setEditModeReply(true);
    };

    const handleCancelEditTask = () => {
        setEditRepltId('');
        setNewMessageEdit('');
        setEditModeReply(false);
    };

    const handleInputChangeEdit = (e) => {
        setNewMessageEdit(e.target.value);
    };

    const updateMessage = async (msg) => {

        if (newMessageEdit) {

            const formInputs = {
                content: newMessageEdit,
                uploadId: image === null ? msg.uploadId : image,
            }

            updateTaskChatAllApiRequest(msg._id, formInputs)
                .then((data) => {
                    if (data.message === "taskChat updated") {
                        setNewMessageEdit('');
                        setImage(null);
                        setEditRepltId('');
                        setEditModeReply(false);
                        setMessages([]);
                        setLoading(true);
                    };
                })
                .catch((er) => {
                    console.log({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
    };

    const toggleDropdownReplyDots = (id) => {
        if (openDropdownReplyId === id) {
            setOpenDropdownReplyId(null);
        } else {
            setOpenDropdownReplyId(id);
        }
    };

    const onEmojiClickEdit = (emojiObject) => {
        setNewMessageEdit(prevInput => prevInput + emojiObject.emoji);
        setShowEmojiPicker(false);
    };

    return (
        <>
            <div
                className="background-Confirmation-chat"
                onClick={onCancel}
            />
            {showConfirmation && (
                <DeleteTaskChatConfirmationModal
                    onConfirm={handleConfirmRemoveTask}
                    onCancel={handleCancelRemoveTask}
                />
            )}
            <div className="confirmation-box-chat">
                <div className="row fw-bolder text-center gray-color deleteWidth mb-4">
                    <div className="col-sm-10 fs-5">
                    </div>
                    <div className="col-sm-2 align-end text-end fs-5">
                        <IoMdClose onClick={onCancel} />
                    </div>
                </div>
                <div className="row m-0 p-0 mb-5" onClick={activateEditMode}>
                    <div className="col-sm">
                        {editMode ? (
                            <div ref={textOpt}>
                                {image && (
                                    <div className="row">
                                        <img src={image.imageUrl} style={{ width: 'auto', height: '5rem' }} alt="uploaded" />
                                    </div>
                                )}
                                <div className="row">
                                    <textarea
                                        value={newMessage}
                                        onChange={handleInputChange}
                                        rows={4}
                                        autoFocus
                                    />
                                </div>
                                <div className="row mt-1 m-0 p-0">
                                    <div className="col-sm-2 m-0 p-0">
                                        <div>
                                            <button className="button-taskMess text-start" onClick={handleAddFilesClick}><MdAttachFile /> Add files</button>
                                            {showOptions && (
                                                <div className="dropdown-task-convo" ref={optionsRef}>
                                                    <button className="button-taskMess text-start" onClick={() => handleOptionSelect('computer')}>From computer</button>
                                                </div>
                                            )}
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                onChange={uploadFile}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-2 m-0 p-0">
                                        <button className="button-taskMess text-start" onClick={toggleEmojiPicker}><BsEmojiSmile />  Emoji</button>
                                        {showEmojiPicker && (
                                            <div ref={emojiPickerRef} className="mt-2">
                                                <EmojiPicker onEmojiClick={onEmojiClick} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-sm-8 text-end m-0 p-0">
                                        <button className="btn btn-sm btn-outline-primary" onClick={sendMessage}>Update</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newMessage}
                                        onFocus={handleFocus}
                                        onChange={handleInputChange}
                                        placeholder="Write an update..."
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {messages.length > 0 ? (
                    <div className="row d-flex justify-content-end pe-5 mb-5">
                        {messages && messages.filter((msg) => !msg.replyId || !msg.reply).map((msg, index) => (
                            <>
                                <div key={msg._id + '' + index} className="card m-0 p-0 mt-3 mb-3" style={{ width: '33rem' }}>
                                    <div className="card-body m-0 p-0">
                                        <div className="row">
                                            <div className="col-sm-1 text-end mt-2">
                                                <CgProfile style={{ width: '2.5rem', height: '2.5rem' }} />
                                            </div>
                                            <div className="col mt-3">
                                                {msg.sender}
                                            </div>
                                            <div className="col-sm-5 text-end mt-3 fs-6 me-2 pe-0">
                                                <IoTimeOutline /> {getTimeDisplay(msg.timestamp)}
                                            </div>
                                            {msg.user === auth.user._id && (
                                                <div className="col-sm-1 text-end fs-6 ms-0 ps-0" ref={dropDownDots}>
                                                    <div key={msg._id} className="custom-select-wrapper">
                                                        <div className="custom-select" onClick={() => toggleDropdownDots(msg._id)}>
                                                            <HiOutlineDotsHorizontal style={{ height: '1.2rem', width: '1.2rem' }} />
                                                        </div>
                                                        {openDropdownId === msg._id && (
                                                            <div className="custom-options">
                                                                <div className="custom-option" onClick={() => handleRemoveTask(msg)}>
                                                                    <RiDeleteBin5Line className="me-2" /> Delete Update
                                                                </div>
                                                                <div className="custom-option" onClick={() => handleEditTask(msg)}>
                                                                    <LuPencil className="me-2" /> Edit Update
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {editModeReply && (editReplyId === msg._id) ? (
                                            <>
                                                <div ref={textEditOpt}>
                                                    {msg.uploadId ? (
                                                        <div className="row mt-3 mb-5">
                                                            <div className="col-sm-12">
                                                                {msg.uploadId.map((img) => (
                                                                    <img key={img._id} src={img.imageUrl} style={{ width: 'auto', height: '5rem' }} alt="upload" />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : image && (
                                                        <div className="row">
                                                            <img src={image.imageUrl} style={{ width: 'auto', height: '5rem' }} alt="uploaded" />
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <textarea
                                                            value={newMessageEdit}
                                                            onChange={handleInputChangeEdit}
                                                            rows={4}
                                                            autoFocus
                                                        />
                                                    </div>
                                                    <div className="row mt-1 m-0 p-0">
                                                        <div className="col-sm-3 m-0 p-0">
                                                            <div>
                                                                <button className="button-taskMess text-start" onClick={handleAddFilesClick}><MdAttachFile /> Add files</button>
                                                                {showOptions && (
                                                                    <div className="dropdown-task-convo" ref={optionsRef}>
                                                                        <button className="button-taskMess text-start" onClick={() => handleOptionSelect('computer')}>From computer</button>
                                                                    </div>
                                                                )}
                                                                <input
                                                                    type="file"
                                                                    ref={fileInputRef}
                                                                    onChange={uploadFile}
                                                                    style={{ display: 'none' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2 m-0 p-0">
                                                            <button className="button-taskMess text-start" onClick={toggleEmojiPicker}><BsEmojiSmile />  Emoji</button>
                                                            {showEmojiPicker && (
                                                                <div ref={emojiPickerRef} className="mt-2">
                                                                    <EmojiPicker onEmojiClick={onEmojiClickEdit} />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-sm-7 text-end m-0 p-0">
                                                            <button className="btn btn-sm btn-outline-primary" onClick={() => handleCancelEditTask()}>Cancel</button>

                                                            <button className="btn btn-sm btn-outline-success ms-3" onClick={() => updateMessage(msg)}>Update</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="row mt-3 mb-5">
                                                    <div className="col-sm-12">
                                                        {msg.content}
                                                    </div>
                                                </div>
                                                {msg.uploadId && (
                                                    <div className="row mt-3 mb-5">
                                                        <div className="col-sm-12">
                                                            {msg.uploadId.map((img) => (
                                                                <img key={img._id} src={img.imageUrl} style={{ width: 'auto', height: '5rem' }} alt="upload" />
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                                {msg.icon && (
                                                    <div className="row mt-1 mb-2">
                                                        <div className="col-sm-12 text-start">
                                                            {Object.entries(msg.icon.reduce((acc, icn) => {
                                                                const key = icn.type;
                                                                if (acc[key]) {
                                                                    acc[key].count++;
                                                                    acc[key].senders.add(icn.sender);
                                                                } else {
                                                                    acc[key] = { count: 1, icon: icn.icon, senders: new Set([icn.sender]) };
                                                                }
                                                                return acc;
                                                            }, {})).map(([type, { count, icon, senders }]) => (
                                                                <>
                                                                    <span key={type} className="tech-logo" title={Array.from(senders).join(', ')}>
                                                                        <img src={icon} style={{ width: '1.5rem', height: '1.5rem' }} alt="icon" />
                                                                        {count > 1 && <span className="badge badge-pill badge-info">{count}</span>}
                                                                    </span>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                                <div id={`dropdown-content-${msg._id}`} style={{ position: 'relative', zIndex: 1, display: 'none' }}>
                                                    <EmojiPicker onReactionClick={(emojiObject) => onEmojiLikeClick(msg, emojiObject)} reactionsDefaultOpen={true} />
                                                </div>
                                                {messages.replyId === msg._id ? (
                                                    <>
                                                        {messages.filter(reply => reply.replyId !== msg._id && reply.reply).map((replyMsg) => (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-sm-6 text-center m-0 p-0">
                                                                        <button onClick={() => toggleDropdown(msg._id)} className="btn btn-sm btn-outline-success updateButton w-100 m-0">Like</button>
                                                                    </div>
                                                                    <div className="col-sm-6 text-center m-0 p-0">
                                                                        <button onClick={() => toggleDropdownReply(msg._id)} className="btn btn-sm btn-outline-primary updateButton w-100 m-0">Reply</button>
                                                                    </div>
                                                                </div>
                                                                {openReplyId === msg._id && (
                                                                    <div className="row d-flex justify-content-end mt-4 mb-4">
                                                                        <div className="col-sm-10 text-center me-2 p-0">
                                                                            <div ref={replyTextOpt}>
                                                                                {image && (
                                                                                    <div className="row">
                                                                                        <img src={image.imageUrl} style={{ width: 'auto', height: '5rem' }} alt="uploaded" />
                                                                                    </div>
                                                                                )}
                                                                                <div className="row">
                                                                                    <textarea
                                                                                        value={newMessageReply}
                                                                                        onChange={handleReplyInputChange}
                                                                                        rows={4}
                                                                                        autoFocus
                                                                                    />
                                                                                </div>
                                                                                <div className="row mt-1 m-0 p-0">
                                                                                    <div className="col-sm-3 m-0 p-0">
                                                                                        <div>
                                                                                            <button className="button-taskMess text-start" onClick={handleAddFilesClick}><MdAttachFile /> Add files</button>
                                                                                            {showOptions && (
                                                                                                <div className="dropdown-task-convo" ref={optionsRef}>
                                                                                                    <button className="button-taskMess text-start" onClick={() => handleOptionSelect('computer')}>From computer</button>
                                                                                                </div>
                                                                                            )}
                                                                                            <input
                                                                                                type="file"
                                                                                                ref={fileInputRef}
                                                                                                onChange={uploadFile}
                                                                                                style={{ display: 'none' }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3 m-0 p-0">
                                                                                        <button className="button-taskMess text-start" onClick={toggleEmojiPicker}><BsEmojiSmile />  Emoji</button>
                                                                                        {showEmojiPicker && (
                                                                                            <div ref={emojiPickerRef} className="mt-2">
                                                                                                <EmojiPicker onEmojiClick={onEmojiClick} />
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="col-sm-6 text-end m-0 p-0">
                                                                                        <button className="btn btn-sm btn-outline-primary" onClick={() => sendMessageReply(msg)}>Reply</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        {messages.filter(reply => reply.replyId === msg._id && reply.reply).map((replyMsg) => (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-sm-6 text-center m-0 p-0">
                                                                        <button onClick={() => toggleDropdown(msg._id)} className="btn btn-sm btn-outline-success updateButton w-100 m-0">Like</button>
                                                                    </div>
                                                                    <div className="col-sm-6 text-center m-0 p-0">
                                                                        <button onClick={() => toggleDropdownReply(replyMsg._id)} className="btn btn-sm btn-outline-primary updateButton w-100 m-0">Reply</button>
                                                                    </div>
                                                                </div>
                                                                <div key={replyMsg._id} className={`row mt-3 ${replyMsg.user === auth.user._id ? 'justify-content-end' : 'justify-content-start'}`}>
                                                                    <div
                                                                        className="message-bubble m-0 p-0 mt-1 mb-3"
                                                                        style={{
                                                                            background: replyMsg.user === auth.user._id ? '#0dcaf0' : '#adb5bd',
                                                                        }}
                                                                    >
                                                                        <div className="row">
                                                                            <div className="col-sm-1 text-end mt-2">
                                                                                <CgProfile style={{ width: '2.5rem', height: '2.5rem' }} />
                                                                            </div>
                                                                            <div className="col-sm-5 ps-4 mt-3">
                                                                                {replyMsg.sender}
                                                                            </div>
                                                                            <div className="col-sm-5 text-end mt-3 fs-6 me-0 pe-0">
                                                                                <IoTimeOutline /> {getTimeDisplay(replyMsg.timestamp)}
                                                                            </div>
                                                                            {replyMsg.user === auth.user._id && (
                                                                                <div className="col-sm-1 text-end fs-6 ms-0 ps-0" ref={dotsReply}>
                                                                                    <div key={replyMsg._id} className="custom-select-wrapper">
                                                                                        <div className="custom-select" onClick={() => toggleDropdownReplyDots(replyMsg._id)}>
                                                                                            <HiOutlineDotsHorizontal style={{ height: '1.2rem', width: '1.2rem' }} />
                                                                                        </div>
                                                                                        {openDropdownReplyId === replyMsg._id && (
                                                                                            <div className="custom-options">
                                                                                                <div className="custom-option" onClick={() => handleRemoveTask(replyMsg)}>
                                                                                                    <RiDeleteBin5Line className="me-2" /> Delete Update
                                                                                                </div>
                                                                                                <div className="custom-option" onClick={() => handleEditTask(replyMsg)}>
                                                                                                    <LuPencil className="me-2" /> Edit Update
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {editModeReply && (editReplyId === replyMsg._id) ? (
                                                                            <>
                                                                                <div ref={textEditOpt}>
                                                                                    {msg.uploadId ? (
                                                                                        <div className="row mt-3 mb-5">
                                                                                            <div className="col-sm-12">
                                                                                                {msg.uploadId.map((img) => (
                                                                                                    <img key={img._id} src={img.imageUrl} style={{ width: 'auto', height: '5rem' }} alt="upload" />
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : image && (
                                                                                        <div className="row">
                                                                                            <img src={image.imageUrl} style={{ width: 'auto', height: '5rem' }} alt="uploaded" />
                                                                                        </div>
                                                                                    )}
                                                                                    <div className="row">
                                                                                        <textarea
                                                                                            value={newMessageEdit}
                                                                                            onChange={handleInputChangeEdit}
                                                                                            rows={4}
                                                                                            autoFocus
                                                                                        />
                                                                                    </div>
                                                                                    <div className="row pt-1 m-0 p-0 bg-light">
                                                                                        <div className="col-sm-4 m-0 p-0">
                                                                                            <div>
                                                                                                <button className="button-taskMess text-start" onClick={handleAddFilesClick}><MdAttachFile /> Add files</button>
                                                                                                {showOptions && (
                                                                                                    <div className="dropdown-task-convo" ref={optionsRef}>
                                                                                                        <button className="button-taskMess text-start" onClick={() => handleOptionSelect('computer')}>From computer</button>
                                                                                                    </div>
                                                                                                )}
                                                                                                <input
                                                                                                    type="file"
                                                                                                    ref={fileInputRef}
                                                                                                    onChange={uploadFile}
                                                                                                    style={{ display: 'none' }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3 m-0 p-0">
                                                                                            <button className="button-taskMess text-start" onClick={toggleEmojiPicker}><BsEmojiSmile />  Emoji</button>
                                                                                            {showEmojiPicker && (
                                                                                                <div ref={emojiPickerRef} className="mt-2">
                                                                                                    <EmojiPicker onEmojiClick={onEmojiClickEdit} />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="col-sm-5 text-end m-0 p-0">
                                                                                            <button className="btn btn-sm btn-outline-primary" onClick={() => handleCancelEditTask()}>Cancel</button>

                                                                                            <button className="btn btn-sm btn-outline-success ms-3" onClick={() => updateMessage(msg)}>Update</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="row d-flex justify-content-end mt-2">
                                                                                    <div className="col-sm-11">
                                                                                        {replyMsg.content}
                                                                                    </div>
                                                                                </div>
                                                                                {replyMsg.uploadId && (
                                                                                    <div className="row mt-3 mb-3">
                                                                                        <div className="col-sm-12">
                                                                                            {replyMsg.uploadId.map((img) => (
                                                                                                <img key={replyMsg._id} src={img.imageUrl} style={{ width: 'auto', height: '5rem' }} alt="upload" />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {replyMsg.icon && (
                                                                                    <div className="row mt-1 mb-2">
                                                                                        <div className="col-sm-12 text-start">
                                                                                            {Object.entries(replyMsg.icon.reduce((acc, icn) => {
                                                                                                const key = icn.type;
                                                                                                if (acc[key]) {
                                                                                                    acc[key].count++;
                                                                                                    acc[key].senders.add(icn.sender);
                                                                                                } else {
                                                                                                    acc[key] = { count: 1, icon: icn.icon, senders: new Set([icn.sender]) };
                                                                                                }
                                                                                                return acc;
                                                                                            }, {})).map(([type, { count, icon, senders }]) => (
                                                                                                <>
                                                                                                    <span key={type} className="tech-logo" title={Array.from(senders).join(', ')}>
                                                                                                        <img src={icon} style={{ width: '1.5rem', height: '1.5rem' }} alt="icon" />
                                                                                                        {count > 1 && <span className="badge badge-pill badge-info">{count}</span>}
                                                                                                    </span>
                                                                                                </>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                <div id={`dropdown-content-${replyMsg._id}`} style={{ position: 'relative', zIndex: 1, display: 'none' }}>
                                                                                    <EmojiPicker onReactionClick={(emojiObject) => onEmojiLikeClick(replyMsg, emojiObject)} reactionsDefaultOpen={true} />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    {openReplyId === replyMsg._id && (
                                                                        <div className="row d-flex justify-content-end mt-4 mb-4">
                                                                            <div className="col-sm-10 text-center me-2 p-0">
                                                                                <div ref={replyTextOpt}>
                                                                                    {image && (
                                                                                        <div className="row">
                                                                                            <img src={image.imageUrl} style={{ width: 'auto', height: '5rem' }} alt="uploaded" />
                                                                                        </div>
                                                                                    )}
                                                                                    <div className="row">
                                                                                        <textarea
                                                                                            value={newMessageReply}
                                                                                            onChange={handleReplyInputChange}
                                                                                            rows={4}
                                                                                            autoFocus
                                                                                        />
                                                                                    </div>
                                                                                    <div className="row mt-1 m-0 p-0">
                                                                                        <div className="col-sm-3 m-0 p-0">
                                                                                            <div>
                                                                                                <button className="button-taskMess text-start" onClick={handleAddFilesClick}><MdAttachFile /> Add files</button>
                                                                                                {showOptions && (
                                                                                                    <div className="dropdown-task-convo" ref={optionsRef}>
                                                                                                        <button className="button-taskMess text-start" onClick={() => handleOptionSelect('computer')}>From computer</button>
                                                                                                    </div>
                                                                                                )}
                                                                                                <input
                                                                                                    type="file"
                                                                                                    ref={fileInputRef}
                                                                                                    onChange={uploadFile}
                                                                                                    style={{ display: 'none' }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3 m-0 p-0">
                                                                                            <button className="button-taskMess text-start" onClick={toggleEmojiPicker}><BsEmojiSmile />  Emoji</button>
                                                                                            {showEmojiPicker && (
                                                                                                <div ref={emojiPickerRef} className="mt-2">
                                                                                                    <EmojiPicker onEmojiClick={onEmojiClick} />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="col-sm-6 text-end m-0 p-0">
                                                                                            <button className="btn btn-sm btn-outline-primary" onClick={() => sendMessageReply(msg)}>Reply</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div >
                                </div>
                            </>
                        ))}
                    </div >
                ) : (
                    <div className="row d-flex justify-content-center text-center pe-5 mt-5">
                        <HiOutlineChatBubbleLeftRight style={{ width: '10rem', height: '10rem' }} />
                        <span className="fs-4">Currently there are no updates.</span>
                    </div>
                )}
            </div >
        </>
    );
};

export default TaskChatComponent;
