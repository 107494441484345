import axios from "axios";
import AdminMainUserComponent from "./components/adminMainUserComponent";

const fetchUsers = async (abctrl) => {
    const { data } = await axios.get("/api/users", { withCredentials: true });
    return data
}

const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/admin", { withCredentials: true });
    return data
}

const fetchLocation = async (abctrl) => {
    const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
    return data
}

const deleteUser = async (userId) => {
    const { data } = await axios.delete(`/api/users/${userId}`, { withCredentials: true });
    return data
}

const deleteTechInfo = async (techInfoId) => {
    const { data } = await axios.delete(`/api/techInfo/${techInfoId}`, { withCredentials: true });
    return data
}

const deleteLocation = async (locationId) => {
    const { data } = await axios.delete(`/api/inventoryLocation/${locationId}`, { withCredentials: true });
    return data
}

const AdminMainUsersPage = () => {
    return <AdminMainUserComponent fetchLocation={fetchLocation} deleteTechInfo={deleteTechInfo} deleteLocation={deleteLocation} fetchTechInfo={fetchTechInfo} fetchUsers={fetchUsers} deleteUser={deleteUser} />;
};

export default AdminMainUsersPage;
