import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import paths from "../../../router/paths";
import DispatchLinksComponent from "../../../components/dispatch/dispatchLinksComponent";
import { MdOutlineInventory } from "react-icons/md";
import DispatchDeletePopUpComponent from '../../../components/dispatch/deleteconfirm/dispatchDeletePopComponent';

const JobTagComponent = ({ fetchJobTag, deleteJobTag }) => {
    const [jobTag, setJobTag] = useState([]);
    const auth = useSelector(state => state.auth)
    const [jobTagDeleted, setJobTagDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [jobTagId, setJobTagId] = useState("");

    const handleConfirmRemove = async () => {
        const data = await deleteJobTag(jobTagId);
        if (data.message === 'Job tag deleted successfully') {
            setJobTagDeleted(!jobTagDeleted)
            setShowConfirmation(false);
            setSearchValue('');
            setIsLoading(true);
        }
    }

    const deleteHandler = (jobTagId) => {
        setJobTagId(jobTagId);
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchJobTag(abctrl)
            .then((res) => {
                const jobTagFilter = res.filter((job) => job.company === auth.user.company);

                setJobTag(jobTagFilter);
                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );


        return () => abctrl.abort();
    }, [deleteJobTag, fetchJobTag, auth.user.company]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchJobTag(abctrl)
                .then((res) => {
                    const jobTagFilter = res.filter((job) => job.company === auth.user.company);

                    setJobTag(jobTagFilter);
                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }

        return () => abctrl.abort();
    }, [deleteJobTag, isLoading, fetchJobTag, auth.user.company]);


    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };

    return (
        <div className="min-vh-100">
            {showConfirmation && (
                <DispatchDeletePopUpComponent
                    title='Delete this job tag?'
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancel}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <DispatchLinksComponent />
                </div>
                <div className="col-sm-10 ms-0 ps-0 mt-5 pt-5 pb-4">
                    <div className="row mb-3">
                        <div className="col-sm-8">
                            <h1>Job Tag</h1>
                        </div>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                disabled={jobTag.length === 0}
                                className="mt-2 input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Job Tags.."
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1">
                            <Link to={paths.DISPATCHNEWJOBTAG} className="btn btn-outline-primary loginButton">New</Link>
                        </div>
                    </div>
                    {jobTag.length > 0 ? (
                        <>
                            {isLoading ? (
                                <div className="d-flex justify-content-center m-5 p-5">
                                    <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            ) : (
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-start">Job Tag</th>
                                            <th scope="col" className="text-center">Edit/Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobTag
                                            .filter(
                                                (jobTags) => {

                                                    return (
                                                        jobTags.name.toLowerCase().indexOf(searchValue) !== -1
                                                    );
                                                })
                                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                            .map((jobTags, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <div
                                                                style={{
                                                                    color: '#fff',
                                                                    width: 'fit-content',
                                                                    border: '1px solid #000',
                                                                    backgroundColor: `${jobTags.color}`,
                                                                    padding: '5px',
                                                                    borderRadius: '5px',
                                                                }}
                                                            >
                                                                {jobTags.name}
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <Link to={`/dispatch/edit-job-tag/${jobTags._id}`}>
                                                                <button className="btn-sm btn btn-outline-primary">
                                                                    <GoPencil />
                                                                </button>
                                                            </Link>
                                                            {" / "}
                                                            <button
                                                                className="btn-sm btn btn-outline-danger"
                                                                onClick={() => deleteHandler(jobTags._id)}
                                                            >
                                                                <BsTrash />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                    </tbody>
                                </table>
                            )}
                        </>
                    ) : (
                        <div className="mt-5">
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <MdOutlineInventory className="inventoryLogo fs-1" />
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <span>Currently no added job tags. Click on the add button to include them.</span> <br />
                                </div>
                            </div>
                        </div>
                    )}
                    {jobTag.length > 0 && (
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn-sm btn btn-outline-primary me-3 loginButton"
                                disabled={page === 1}
                                onClick={handlePrevPage}
                            >
                                Previous
                            </button>
                            <span className="mr-2 me-3">
                                Page {page} of {Math.ceil(jobTag
                                    .filter(
                                        (jobTags) => {

                                            return (
                                                jobTags.name.toLowerCase().indexOf(searchValue) !== -1
                                            );
                                        }).length / rowsPerPage)}
                            </span>
                            <button
                                className="btn-sm btn btn-outline-primary loginButton"
                                disabled={jobTag
                                    .filter(
                                        (jobTags) => {

                                            return (
                                                jobTags.name.toLowerCase().indexOf(searchValue) !== -1
                                            );
                                        }).length <= page * rowsPerPage}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default JobTagComponent;