import TimeEntryComponent from "./components/timeEntryComponent";
import axios from "axios";

const TimeEntryPage = () => {

    const clockIn = async (formInputs) => {
        const { data } = await axios.post(`/api/timeEntry/clock-in`, { ...formInputs }, { withCredentials: true });
        return data
    }
    const breakClockOut = async (id, formInputs) => {
        const { data } = await axios.put(`/api/timeEntry/clock-out-break/${id}`, { ...formInputs }, { withCredentials: true });
        return data
    }
    const breakClockIn = async (id, formInputs) => {
        const { data } = await axios.put(`/api/timeEntry/clock-in-break/${id}`, { ...formInputs }, { withCredentials: true });
        return data
    }
    const clockOut = async (id, formInputs) => {
        const { data } = await axios.put(`/api/timeEntry/clock-out/${id}`, { ...formInputs }, { withCredentials: true });
        return data
    }
    const clockStatus = async (userId) => {
        const { data } = await axios.get(`/api/timeEntry/status/${userId}`, { withCredentials: true });
        return data
    }

    const getSchedule = async () => {
        const { data } = await axios.get(`/api/workSchedule/work-schedule`, { withCredentials: true });
        return data
    }

    return <TimeEntryComponent
        getSchedule={getSchedule}
        breakClockOut={breakClockOut}
        breakClockIn={breakClockIn}
        clockOut={clockOut}
        clockStatus={clockStatus}
        clockIn={clockIn}
    />
};

export default TimeEntryPage;