import paths from "../../router/paths";
import { Link } from "react-router-dom";

const DispatchLinksComponent = () => {

    return (
        <nav className="navbar navbar-light gray-color p-0 m-0 navLinksnavbar">
            <ul className="nav flex-column m-0 pe-3 pb-3 p-0">
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.DISPATCHOPENQUOTES}>
                        Open Jobs
                    </Link>
                </li>
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.DISPATCHCUTOMER}>
                        Customers
                    </Link>
                </li>
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.DISPATCHPANELJOB}>
                        Jobs
                    </Link>
                </li>
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.DISPATCHJOBITEM}>
                        Jobs Items
                    </Link>
                </li>
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.DISPATCHJOBSOURCE}>
                        Job Source
                    </Link>
                </li>
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.DISPATCHJOBTAG}>
                        Job Tags
                    </Link>
                </li>
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.DISPATCHJOBTYPE}>
                        Job Types
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default DispatchLinksComponent;