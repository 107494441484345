import { useSelector } from "react-redux";
import paths from "../../router/paths";
import { Link } from "react-router-dom";

const TimeClockLinksComponent = () => {
    const auth = useSelector(state => state.auth)
    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;

    return (
        <nav className="navbar navbar-light gray-color p-0 m-0 navLinksnavbar">
            <ul className="nav flex-column m-0 pe-3 pb-3 p-0">
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.TIMEENTRY}>
                        Time Clock
                    </Link>
                </li>
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.REQUESTTIMEOFF}>
                        Request Time Off
                    </Link>
                </li>
                {isAdmin || isSuperAdmin && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINUPDATETIME}>
                            Change Time Entry
                        </Link>
                    </li>
                )}
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.WORKSCHEDULE}>
                        Schedule
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default TimeClockLinksComponent;