import React, { useState, useEffect } from "react";
import '../assets/inventory.css';

const InventoryCheckOutComponent = ({ techId, user, message, tech, selectedItem, locations, onConfirm, onCancel }) => {
    const [quantity, setQuantity] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [filteredLocations, setFilteredLocations] = useState([]);

    useEffect(() => {
        if (tech && locations) {
            const filteredTech = locations.filter(location => location.tech);
            const filtered = filteredTech.filter(location => location.tech === techId._id);
            const filteredFind = filteredTech.find(location => location.tech === techId._id);

            const filteredTechId = locations.find(location => location.name === selectedItem.item.name1);

            if (filteredTechId) {
                if (selectedItem.item.name1 === filteredTechId.name) {
                    setFilteredLocations(filtered);
                    setSelectedLocation(filteredFind)
                } 
            } else {
                const filteredDefaultLocations = locations.filter(location => location.defaultLoc === true);
                const combinedLocations = [...filtered, ...filteredDefaultLocations];

                setFilteredLocations(combinedLocations);
            }
        }
    }, [tech, locations, selectedItem.item.name1]);

    const handleConfirm = () => {
        if (parseInt(quantity) > 0 && selectedLocation) {
            onConfirm(quantity, selectedLocation);
        }
    };

    const getTechName = (id) => {
        const technitionId = tech.find((techs) => techs._id === id);
        const techUser = user.find((user) => user._id === technitionId.user);
        return techUser ? techUser.name : "";
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="inventory-checkout-container">
                        <div className="popup">
                            <h2 className="popup-header">Reduce Stock</h2>
                            <div className="popup-1">

                                <p className="popup-message">{message}</p>
                                <input
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    placeholder="Enter quantity"
                                    className="quantity-input"
                                />
                                <select
                                    value={selectedLocation}
                                    onChange={(e) => setSelectedLocation(e.target.value)}
                                    className="form-select mb-3"
                                >
                                    <option value="" disabled>Select destination location</option>
                                    {filteredLocations.map(location => (
                                        <option key={location._id} value={location._id}>
                                            {location.tech && getTechName(location.tech)} {location.name}
                                        </option>
                                    ))}
                                </select>

                                <div className="row justify-content-center">
                                    <div className="col-sm-3 me-4">
                                        <button onClick={handleConfirm} disabled={quantity === '' || selectedLocation === '' || selectedItem.item.quantity < quantity} className="btn btn-outline-success loginButton">
                                            Confirm
                                        </button>
                                    </div>
                                    <div className="col-sm-3">
                                        <button onClick={onCancel} className="btn btn-outline-primary loginButton">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InventoryCheckOutComponent;
