import axios from 'axios'
import { useDispatch } from "react-redux";
import AdminEditCodeRequestComponent from './components/adminEditCodeRequestComponent';

const fetchCodes = async (codesId) => {
    const { data } = await axios.get(`/api/vinRequest/admin/${codesId}`, { withCredentials: true });
    return data;
}

const updateCodesApiRequest = async (codesId, formInputs) => {
    const { data } = await axios.put(`/api/vinRequest/admin/${codesId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const updateCodesRequestApiRequest = async (codesId, formInputs) => {
    const { data } = await axios.put(`/api/codes/admin/${codesId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const createCodesApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/codes/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const fetchVINCodes = async (vin) => {
    const { data } = await axios.get(`/api/codes/admin?vin=${vin}`, { withCredentials: true });
    return data
}

const AdminEditCodeRequestPage = () => {

    const reduxDispatch = useDispatch();

    return <AdminEditCodeRequestComponent fetchVINCodes={fetchVINCodes} createCodesApiRequest={createCodesApiRequest} updateCodesRequestApiRequest={updateCodesRequestApiRequest} fetchCodes={fetchCodes} updateCodesApiRequest={updateCodesApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditCodeRequestPage;
