import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect } from "react";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { Link } from "react-router-dom";
import paths from "../../../router/paths";
import AdminDeletePopUpComponent from '../../../components/admin/adminDeletePopUpComponent';

const AdminYearComponent = ({ fetchYear, deleteYear }) => {
    const [year, setYear] = useState([]);
    const [yearDeleted, setYearDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;
    const [searchValue, setSearchValue] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [yearId, setYearId] = useState("");

    const handleConfirmRemove = async () => {
        const data = await deleteYear(yearId);
        if (data.message === 'Year deleted successfully') {
            setYearDeleted(!yearDeleted)
            setShowConfirmation(false);
            setIsLoading(true);
        }
    }
    const deleteHandler = (yearId) => {
        setYearId(yearId);
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchYear(abctrl)
            .then((res) => {
                setYear(res);
                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [deleteYear, fetchYear]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchYear(abctrl)
                .then((res) => {
                    setYear(res);
                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [deleteYear, isLoading, fetchYear]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };

    return (
        <div className="min-vh-100">
            {showConfirmation && (
                <AdminDeletePopUpComponent
                    title='Delete year?'
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancel}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <AdminLinksComponent />
                </div>
                <div className="col-sm-10 mt-5 pt-5">
                    <div className="row mb-3">
                        <div className="col-sm-8">
                            <h1>Vehicle Year List</h1>
                        </div>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                className="mt-2 input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Vehicles Years.."
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1">
                            <Link to={paths.ADMINNEWYEAR} className="btn btn-outline-primary">New</Link>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <table className="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">Make</th>
                                    <th scope="col" className="text-center">Model</th>
                                    <th scope="col" className="text-center">Year</th>
                                    <th scope="col" className="text-center">Edit/Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {year
                                    .filter(
                                        (years) =>
                                            years.make.toLowerCase().indexOf(searchValue) !== -1 ||
                                            years.model.toLowerCase().indexOf(searchValue) !== -1
                                    )
                                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                    .map((years, idx) => (
                                        <tr key={idx}>

                                            <td className="text-center align-middle">{years.make}</td>
                                            <td className="text-center align-middle">{years.model}</td>
                                            <td className="text-center">
                                                {year
                                                    .filter((yearObj) => yearObj.make === years.make && yearObj.model === years.model)
                                                    .sort((a, b) => b.year - a.year).map((yearObj) => (
                                                        yearObj.year.map((year) => (
                                                            <option key={year} value={year}>
                                                                {year}
                                                            </option>
                                                        ))
                                                    ))}
                                            </td>
                                            <td className="text-center align-middle">
                                                <Link to={`/admin/edit-year/${years._id}`}>
                                                    <button className="btn-sm btn btn-outline-primary">
                                                        <GoPencil />
                                                    </button>
                                                </Link>
                                                {" / "}
                                                <button
                                                    className="btn-sm btn btn-outline-danger"
                                                    onClick={() => deleteHandler(years._id)}
                                                >
                                                    <BsTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    )}
                    <div className="d-flex justify-content-center pb-4">
                        <button
                            className="btn-sm btn btn-outline-primary mr-2 me-3"
                            disabled={page === 1}
                            onClick={handlePrevPage}
                        >
                            Previous
                        </button>
                        <span className="mr-2 me-3">
                            Page {page} of {Math.ceil(year.filter((years) => years.make.toLowerCase().indexOf(searchValue) !== -1 || years.model.toLowerCase().indexOf(searchValue) !== -1).length / rowsPerPage)}
                        </span>
                        <button
                            className="btn-sm btn btn-outline-primary"
                            disabled={year.filter((years) => years.make.toLowerCase().indexOf(searchValue) !== -1 || years.model.toLowerCase().indexOf(searchValue) !== -1).length <= page * rowsPerPage}
                            onClick={handleNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminYearComponent;