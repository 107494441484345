import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import paths from './paths';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/actions/action';
import axios from "axios";

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
    const [isAuth, setIsAuth] = useState(null);
    const [isAuthChecked, setIsAuthChecked] = useState(null);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const checkToken = async () => {
        try {
            const { data } = await axios.get(`/api/verify/token`, { withCredentials: true });

            if (data.message === 'No token provided') {
                setIsAuth(false);
                setIsAuthChecked(false);
                dispatch(logout());
                navigate(paths.LOGIN);
            } else {
                setIsAuth(data);
                setIsAuthChecked(true);
            }
        } catch (error) {
            console.error("Token validation error:", error);
            setIsAuth(false);
            setIsAuthChecked(false);
            dispatch(logout());
            navigate(paths.LOGIN);
            return { isAuthenticated: false, error: error.response ? error.response.data.message : error.message };
        }
    };

    useEffect(() => {
        checkToken();
    }, [navigate]);

    if (isAuthChecked === null) {
        return <div>Loading...</div>;
    }

    if (!isAuthChecked) {
        return <Link to={paths.LOGIN} />;
    }

    if (isAuth && roles && !roles.some(role => auth.user?.usertype?.includes(role))) {
        return <Link to={paths.NOTFOUND} />;
    }

    return <Component {...rest} />;
};

export default ProtectedRoute;
