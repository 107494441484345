import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { FaRegThumbsUp } from 'react-icons/fa';
import 'jspdf-autotable';
import '../../components/assets/clientInvoice.css';
import { InvoiceGeneratePDF } from '../../components/dispatch/invoiceGeneratePDFComponent';
import { CiCalendar } from "react-icons/ci";
import ClientPaymentForm from '../../components/stripe/clientPaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import axios from 'axios';

const stripePromise = loadStripe('pk_test_51NukCVEVP3HcTg1aHpVOHTyiSqxEfA1m4EGAqaFKBJwy5ssADis9ToSjjXCH3cT169Av456ZMWIp8qtT1oGvBORM00hBVNo5te');

const ClientInvoiceComponent = ({ fetchUploadsAll, fetchCustomer, fetchCompany, fetchDispatch, fetchPaymentAll, fetchInvoice, fetchJobItem, fetchJobTax }) => {
    const [invoice, setInvoice] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [companies, setCompanies] = useState([]);
    const { id } = useParams();
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jobItem, setJobItem] = useState([]);
    const [jobTax, setJobTax] = useState([]);
    const [discountValue, setDiscountValue] = useState(0);
    const [showAlert1, setShowAlert1] = useState(false);
    const [signatureData, setSignatureData] = useState([{ signatureName: "", signature: "", dateTime: null }]);
    const [dueTerm, setDueTerm] = useState("");
    const [totalPay, setTotalPay] = useState("0.00");
    const [dispatch, setDispatch] = useState([]);
    const [totalPaid, setTotalPaid] = useState("0.00");
    const [uploadedLogo, setUploadedLogo] = useState(null);

    const auth = useSelector(state => state.auth)

    useEffect(() => {
        if (invoice && invoice.dueDate) {
            const today = new Date();
            const net15Date = new Date(today);
            const net30Date = new Date(today);
            const net45Date = new Date(today);

            net15Date.setDate(today.getDate() + 15);
            net30Date.setDate(today.getDate() + 30);
            net45Date.setDate(today.getDate() + 45);

            if (invoice.dueDate === today.toISOString()) {
                setDueTerm("dueOn");
            } else if (invoice.dueDate <= net15Date.toISOString()) {
                setDueTerm("net15");
            } else if (invoice.dueDate <= net30Date.toISOString()) {
                setDueTerm("net30");
            } else if (invoice.dueDate <= net45Date.toISOString()) {
                setDueTerm("net45");
            } else {
                setDueTerm("dueOn");
            }
        }
    }, [invoice]);

    const calculateSubtotal = () => {
        return jobItem.reduce((acc, item) => {
            if (!item.optional) {
                return acc + item.price * item.quantity;
            }
            return acc;
        }, 0);
    };

    const calculateTaxableAmount = () => {
        const taxableItems = jobItem.filter(item => (item.taxable && !item.optional));

        const taxableAmount = taxableItems.reduce((acc, item) => {
            return acc + item.price * item.quantity;
        }, 0);

        if (jobTax !== null) {
            const taxRate = jobTax.taxRate;
            const taxAmount = parseFloat((taxableAmount * taxRate / 100).toFixed(2));
            return taxableAmount + taxAmount;
        }

        return taxableAmount;
    };

    const calculateTax = () => {
        const taxableAmount = calculateTaxableAmount();

        if (jobTax !== null) {
            const taxRate = jobTax.taxRate;
            const tax = (taxableAmount * taxRate) / 100;
            const roundedTax = tax.toFixed(3);

            return parseFloat(roundedTax);
        }

        return 0;
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const taxAmount = calculateTax();

        const total = subtotal - discountValue + taxAmount;

        return total;
    };

    useEffect(() => {
        const abctrl = new AbortController();

        const fetchData = async (abctrl) => {
            try {
                const invoiceRes = await fetchInvoice(id);
                setInvoice(invoiceRes);
                setDiscountValue(invoiceRes.discount);
                setSignatureData(invoiceRes.signature);
                setDueTerm(invoiceRes.dueDate);

                const companyData = await fetchCompany();
                const filteredCompany = companyData.find((item) => item._id === invoiceRes.company);
                setCompanies(filteredCompany);

                const jobItemsData = await fetchJobItem();
                const filterJobItem = jobItemsData.filter((item) => item.jobId === invoiceRes.jobId && !item.estimateId);
                setJobItem(filterJobItem);

                const jobTaxData = await fetchJobTax(abctrl);
                const filterJobTax = jobTaxData.find((item) => item._id === invoiceRes.tax);
                setJobTax(filterJobTax);

                const customerData = await fetchCustomer(invoiceRes.customer);
                setCustomer(customerData);

                const dispatchData = await fetchDispatch(invoiceRes.jobId);
                setDispatch(dispatchData);

                const companyLogo = await fetchUploadsAll();
                const filteredUploadsall = companyLogo.filter((item) => invoiceRes.company === item.company);
                const filteredUploadsFin = filteredUploadsall.find((item) => item.companyLogo === true);

                if (filteredUploadsFin) {
                    setUploadedLogo(filteredUploadsFin);
                } else {
                    setUploadedLogo(null);
                }

                const paymentData = await fetchPaymentAll(abctrl);
                const filterPayments = paymentData.filter((item) => item.jobId === dispatchData._id);

                const calculatedTotalAmount = filterPayments.reduce((acc, payment) => {
                    if (payment.status !== 'Refunded') {
                        return acc + payment.amount;
                    }
                    return acc;
                }, 0);

                setTotalPaid(calculatedTotalAmount / 100);
                setTotalPay((dispatchData.total - calculatedTotalAmount / 100).toFixed(2));

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData(abctrl);

        return () => abctrl.abort();
    }, [fetchInvoice, id, fetchCompany, fetchCustomer, fetchDispatch, fetchJobItem, fetchJobTax, fetchPaymentAll, fetchUploadsAll]);

    useEffect(() => {
        const abctrl = new AbortController();

        const fetchData = async (abctrl) => {
            try {
                const invoiceRes = await fetchInvoice(id);
                setInvoice(invoiceRes);
                setDiscountValue(invoiceRes.discount);
                setSignatureData(invoiceRes.signature);
                setDueTerm(invoiceRes.dueDate);

                const companyData = await fetchCompany();
                const filteredCompany = companyData.find((item) => item._id === invoiceRes.company);
                setCompanies(filteredCompany);

                const jobItemsData = await fetchJobItem();
                const filterJobItem = jobItemsData.filter((item) => item.jobId === invoiceRes.jobId && !item.estimateId);
                setJobItem(filterJobItem);

                const jobTaxData = await fetchJobTax(abctrl);
                const filterJobTax = jobTaxData.find((item) => item._id === invoiceRes.tax);
                setJobTax(filterJobTax);

                const customerData = await fetchCustomer(invoiceRes.customer);
                setCustomer(customerData);

                const dispatchData = await fetchDispatch(invoiceRes.jobId);
                setDispatch(dispatchData);

                const companyLogo = await fetchUploadsAll();
                const filteredUploadsall = companyLogo.filter((item) => invoiceRes.company === item.company);
                const filteredUploadsFin = filteredUploadsall.find((item) => item.companyLogo === true);

                if (filteredUploadsFin) {
                    setUploadedLogo(filteredUploadsFin);
                } else {
                    setUploadedLogo(null);
                }

                const paymentData = await fetchPaymentAll(abctrl);
                const filterPayments = paymentData.filter((item) => item.jobId === dispatchData._id);

                const calculatedTotalAmount = filterPayments.reduce((acc, payment) => {
                    if (payment.status !== 'Refunded') {
                        return acc + payment.amount;
                    }
                    return acc;
                }, 0);

                setTotalPaid(calculatedTotalAmount / 100);
                setTotalPay((dispatchData.total - calculatedTotalAmount / 100).toFixed(2));

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        if (loading) {
            fetchData(abctrl);
        }

        return () => abctrl.abort();
    }, [fetchInvoice, id, fetchCompany, loading, fetchCustomer, fetchDispatch, fetchJobItem, fetchJobTax, fetchPaymentAll, fetchUploadsAll]);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    function getBase64FromBlob(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    const handleGeneratePDF = async () => {
        try {
            const data = await fetchJobTax();
            const filterJobTax = data.find((item) => item._id === invoice.tax);

            if (!filterJobTax) {
                console.error("Job tax not found for invoice.tax:", invoice.tax);
                return;
            }

            let imgData = null;
            if (uploadedLogo) {
                try {
                    const response = await axios.get(`/api/uploads/download/${uploadedLogo.file}`, { responseType: 'blob' });
                    const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
                    imgData = await getBase64FromBlob(blob);
                } catch (error) {
                    console.error('Error fetching image:', error);
                    // Optionally handle the error (e.g., show a placeholder image)
                }
            }

            InvoiceGeneratePDF(
                totalPay,
                dueTerm,
                companies,
                imgData,
                formatDateTime,
                invoice,
                filterJobTax,
                customer,
                jobItem,
                signatureData,
            );
        } catch (error) {
            console.error("Error fetching or processing job tax:", error);
        }
    };
   
    function formatDateTime(dateTime) {
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
        };

        return new Date(dateTime).toLocaleDateString(undefined, options);
    }

    const handleCloseAlert1 = () => {
        setShowAlert1(false);
    };
    const calculateDueDate = () => {
        const today = new Date();

        if (dueTerm === "dueOn") {
            return today.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        } else if (dueTerm === "net15" || dueTerm === "net30" || dueTerm === "net45") {
            const netDays = parseInt(dueTerm.substring(3));
            const dueDate = new Date(today);
            dueDate.setDate(dueDate.getDate() + netDays);

            return dueDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        }

        return null;
    };

    let formattedTotal;
    if (invoice.due !== undefined && typeof invoice.due === 'number') {
        formattedTotal = invoice.due.toFixed(2);
    } else {
        formattedTotal = '0.00';
    }

    const today = new Date();
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const todayUS = today.toLocaleString('en-US', options)

    return (
        <Elements stripe={stripePromise}>
            <nav className={`navbar navbar-expand-lg navbar-light bg-light ${auth.user.name ? 'pt-4 pb-2' : 'pt-2 pb-2'}`}>
                <div className="ms-3 pt-3 pb-3 fs-4">
                    <h3 className="invoiceHeaderText">{companies.name}</h3>
                    <h3 className="me-4 invoiceHeaderText">Invoice #{invoice.count}</h3>
                    <h3 className="me-4 invoiceHeaderText">Total: {calculateTotal().toFixed(2)}</h3>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto me-4">
                        <li className="nav-item">
                            <Link className="btn btn-outline-primary btnUpload loginButton" onClick={() => handleGeneratePDF()}>Download PDF</Link>
                        </li>
                    </ul>

                </div>
            </nav>
            <div className="container-fluid mt-5 mb-5 pb-5">
                {showAlert && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Invoice Declined Successfully
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlert1 && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Invoice Paid Successfully
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert1}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                <div className="row justify-content-start">
                    <div className="col-sm-6 docCardInvoice">
                        <div className="row m-0 p-0 justify-content-between headerCard">
                            <div className="col-sm-6 p-0 m-0 imgCard">
                                {uploadedLogo && (
                                    <img className="imgDocCard" src={uploadedLogo.imageUrl} alt="Company Logo" />
                                )}
                            </div>
                            <div className="col-sm-2">
                                <span className="invoiceShowText">INVOICE</span>
                            </div>
                        </div>
                        <div className="row mt-5 p-0 headerCard justify-content-between">
                            <div className="col-sm-6 fw-light invoiceText p-0 m-0 ">
                                <span className="row">{companies.name}</span>
                                <span className="row">{companies.address}</span>
                                <span className="row">{companies.city}, {companies.state} {companies.zip}, {companies.country}</span>
                                <span className="row">{companies.email}</span>
                                <span className="row">{companies.phone}</span>
                            </div>
                            <div className="col-sm-5 p-0 m-0 invoiceText">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Invoice #</strong>
                                            </td>
                                            <td style={{ textAlign: 'left', paddingLeft: '50px' }} className="fw-light">{invoice.count}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Date</strong>
                                            </td>
                                            <td style={{ textAlign: 'left', paddingLeft: '50px' }} className="fw-light">{formatDateTime(invoice.updatedAt)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Balance</strong>
                                            </td>
                                            <td style={{ textAlign: 'left', paddingLeft: '50px' }} className="fw-light">{totalPay}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Due On</strong>
                                            </td>
                                            <td style={{ textAlign: 'left', paddingLeft: '50px' }} className="fw-light">{calculateDueDate()}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div className="row mt-5 p-0 headerCard justify-content-start">
                            <div className="col-sm-6 p-0 m-0 invoiceText">
                                <span className="row fw-bold">Bill To:</span>
                                <span className="row fw-light ps-2">{customer.name} {customer.lastName}</span>
                                <span className="row fw-light ps-2">{customer.address}</span>
                                <span className="row fw-light ps-2">{customer.city}, {customer.state} {customer.zip}, {customer.country}</span>
                                <span className="row fw-light ps-2">{customer.phone}</span>
                                <span className="row fw-light ps-2">{customer.email}</span>
                            </div>
                            <div className="col-sm-6 p-0 m-0 invoiceText">
                                <span className="row fw-bold">Service Location:</span>
                                <span className="row fw-light ps-2">{customer.name} {customer.lastName}</span>
                                <span className="row fw-light ps-2">{customer.address}</span>
                                <span className="row fw-light ps-2">{customer.city}, {customer.state} {customer.zip}, {customer.country}</span>
                                <span className="row fw-light ps-2">{customer.phone}</span>
                                <span className="row fw-light ps-2">{customer.email}</span>
                            </div>
                        </div>
                        <div className="row mt-5 p-0 m-0 headerCard justify-content-center">
                            <div className="col-sm-12 p-0 m-0 invoiceText">
                                <table className="table table-striped">
                                    <thead className="border-top border-bottom border-dark">
                                        <tr>
                                            <th scope="col" style={{ width: '65%' }}>Description</th>
                                            <th scope="col" className="text-center">QTY</th>
                                            <th scope="col" className="text-center">Price</th>
                                            <th scope="col" className="text-center">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-light">
                                        {jobItem.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {item.name}
                                                    <br />
                                                    {item.description}
                                                </td>
                                                <td className="text-center">{item.quantity}</td>
                                                <td className="text-center">${item.price}</td>
                                                <td className="text-center">${(item.price * item.quantity).toFixed(2)} <br /> {item.optional && (<span className="text-primary">Optional</span>)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row p-0 headerCard justify-content-end">
                                <div className="col-sm-3 p-0 m-0 invoicePriceText">
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td className="pe-3">
                                                    <strong>Sub Total</strong>
                                                    <br />
                                                    {invoice.discount > 0 && (
                                                        <>
                                                            <strong>Discount</strong>
                                                            <br />
                                                        </>
                                                    )}
                                                    <strong>Tax</strong>
                                                    <br />
                                                    <strong>Tax Rate</strong>
                                                    <br />
                                                    <strong>Total</strong>
                                                    <br />
                                                    <strong>Balance</strong>
                                                </td>
                                                <td className="fw-light ps-2 border-start border-dotted">
                                                    {calculateSubtotal().toFixed(2)}
                                                    <br />
                                                    {invoice.discount > 0 && (
                                                        <>
                                                            {discountValue.toFixed(2)}
                                                            < br />
                                                        </>
                                                    )}
                                                    {calculateTax().toFixed(2)}
                                                    <br />
                                                    {jobTax.taxRate}%
                                                    <br />
                                                    {calculateTotal().toFixed(2)}
                                                    <br />
                                                    <strong>{totalPay}</strong>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 p-0 m-0 headerCard justify-content-center">
                            <div className="col-sm-12 p-0 m-0 invoiceText">
                                <span className="fw-bold row">
                                    Terms:
                                </span>
                                <span className="row ps-3">
                                    By paying the due balance on invoices provided, the Client hereby acknowledges that all <br />
                                    requested service items for this date and/or any other dates listed above in the description<br />
                                    section of the table, have been performed and have been tested showing successful satisfactory install/repair, unless otherwise stated on the invoice, in which labor service charges still apply if any repairs have been made. By accepting this invoice, the Client agrees to pay in full the amount listed in the Total section of the invoice.
                                </span>
                            </div>
                        </div>
                        <div className="row mt-3 p-0 m-0 headerCard justify-content-center">
                            <div className="col-sm-12 p-0 m-0 invoiceText">
                                <span className="fw-bold row">
                                    Notes:
                                </span>
                                <span className="row ps-3">
                                    {invoice.notes}
                                </span>
                            </div>
                        </div>
                        <div className="row mt-5 p-0 m-0 headerCard text-center">
                            <div className="col-sm-12 p-0 m-0 invoiceText">
                                <span className="fs-2 m-0 p-0 fw-light">
                                    Thank You For Your Business
                                </span>
                            </div>
                        </div>
                    </div>
                    {dispatch.total === totalPaid ? (
                        <div className="col-sm-4 ms-5">
                            <div className="code-container p-3">
                                <h3 className="row invoiceTextHeader d-flex justify-content-between mb-3 pt-2">
                                    <div className="col-sm-4 text-start m-0 p-0">
                                        Invoice #{invoice.count}
                                    </div>
                                    <div className="col-sm-4 text-end">
                                        <span className="greenBackground pe-2 ps-2">
                                            PAID
                                        </span>
                                    </div>
                                </h3>
                                <h3 className="row d-flex justify-content-start invoiceText mb-3 p-0">
                                    <div className="col-sm m-0 text-dark p-0">
                                        <CiCalendar className="me-1 mb-1" style={{ width: '1rem', height: '1rem' }} />
                                        Due: {calculateDueDate()}
                                    </div>
                                </h3>
                                <hr />
                                <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                    <div className="col-sm-4 text-end m-0 p-0">
                                        Total:
                                        <span className="fw-bold ms-2">${formattedTotal}</span>
                                    </div>
                                </h3>
                                <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                    <div className="col-sm-4 text-end me-2 m-0 p-0">
                                        Due:
                                        <span className="fw-bold ms-2">$0.00</span>
                                    </div>
                                </h3>
                            </div>
                        </div>
                    ) : dispatch.total < totalPaid ? (
                        <div className="col-sm-4 ms-5">
                            <div className="code-container p-3">
                                <h3 className="row invoiceTextHeader d-flex justify-content-between mb-3 pt-2">
                                    <div className="col-sm-4 text-start m-0 p-0">
                                        Invoice #{invoice.count}
                                    </div>
                                    <div className="col-sm-4 text-end">
                                        <span className="redBackground pe-2 ps-2">
                                            OVER PAID
                                        </span>
                                    </div>
                                </h3>
                                <h3 className="row d-flex justify-content-start invoiceText mb-3 p-0">
                                    <div className="col-sm m-0 p-0 text-dark">
                                        <CiCalendar className="me-1 mb-1" style={{ width: '1rem', height: '1rem' }} />
                                        Due: {calculateDueDate()}
                                    </div>
                                </h3>
                                <hr />
                                <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                    <div className="col-sm-4 text-end m-0 p-0">
                                        Total:
                                        <span className="fw-bold ms-2">${formattedTotal}</span>
                                    </div>
                                </h3>
                                <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                    <div className="col-sm-4 text-end me-3 m-0 p-0">
                                        Due:
                                        <span className="fw-bold ms-2">${(totalPay - formattedTotal)}</span>
                                    </div>
                                </h3>
                            </div>
                        </div>
                    ) : dispatch.total > totalPaid.length > 0 ? (
                        <div className="col-sm-4 ms-5">
                            <div className="code-container p-3">
                                <h3 className="row invoiceTextHeader d-flex justify-content-between mb-3 pt-2">
                                    <div className="col-sm-4 text-start text-dark m-0 p-0">
                                        Invoice #{invoice.count}
                                    </div>
                                    <div className="col-sm-4 text-end">
                                        <span className="yellowBackground pe-2 ps-2">
                                            PAID: ${totalPay}
                                        </span>
                                    </div>
                                </h3>
                                <h3 className="row d-flex justify-content-start invoiceText mb-3 p-0">
                                    <div className="col-sm m-0 text-dark p-0">
                                        <CiCalendar className="me-1 mb-1" style={{ width: '1rem', height: '1rem' }} />
                                        Due: {calculateDueDate()}
                                    </div>
                                </h3>
                                <hr />
                                <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                    <div className="col-sm-4 text-end m-0 p-0">
                                        Total:
                                        <span className="fw-bold ms-2">${formattedTotal}</span>
                                    </div>
                                </h3>
                                <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                    <div className="col-sm-4 text-end  me-3 m-0 p-0">
                                        Due:
                                        <span className="fw-bold ms-2">${(formattedTotal - totalPay).toFixed(2)}</span>
                                    </div>
                                </h3>
                            </div>
                            <div className="row justify-content-center mt-4">
                                <span className="text-center w-10 fancy-or-invoice">Payment</span>
                            </div>
                            <ClientPaymentForm setLoading={setLoading} totalPaid={totalPay} dispatching={dispatch} companies={companies} companyId={companies._id} />

                        </div>
                    ) : (
                        <div className="col-sm-4 ms-5">
                            <div className="code-container p-3">
                                <h3 className="row invoiceTextHeader d-flex justify-content-between mb-3 pt-2">
                                    <div className="col-sm-4 text-start m-0 p-0">
                                        Invoice #{invoice.count}
                                    </div>
                                    <div className="col-sm-4 text-end">
                                        {todayUS === calculateDueDate() ? (
                                            <span className="redBackground pe-2 ps-2">
                                                OVERDUE
                                            </span>
                                        ) : (
                                            <span className="yellowBackground pe-2 ps-2">
                                                UNPAID
                                            </span>
                                        )}

                                    </div>
                                </h3>
                                <h3 className="row d-flex justify-content-start invoiceText mb-3 p-0">
                                    <div className="col-sm m-0 text-dark p-0">
                                        <CiCalendar className="me-1 mb-1" style={{ width: '1rem', height: '1rem' }} />
                                        Due: {calculateDueDate()}
                                    </div>
                                </h3>
                                <hr />
                                <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                    <div className="col-sm-4 text-end m-0 p-0">
                                        Total:
                                        <span className="fw-bold ms-2">${formattedTotal}</span>
                                    </div>
                                </h3>
                                <h3 className="row d-flex justify-content-end invoiceTextHeader mb-3 p-0">
                                    <div className="col-sm-4 text-end m-0 p-0">
                                        Due:
                                        <span className="fw-bold ms-2">${(totalPay)}</span>
                                    </div>
                                </h3>
                            </div>
                            <div className="row justify-content-center mt-4">
                                <span className="text-center w-10 fancy-or-invoice">Payment</span>
                            </div>
                            <ClientPaymentForm setLoading={setLoading} totalPaid={totalPay} dispatching={dispatch} companies={companies} companyId={companies._id} />
                        </div>
                    )}
                </div>
            </div>
            <footer>
                <div className="container-fluid footer text-light">
                    <div className="row">
                        <div className="col text-center border-top pt-3 pb-3">
                            &copy; Copyrights. All rights reserved. Locksmith Menu inc.
                        </div>
                    </div>
                </div>
            </footer>
        </Elements>
    );
};

export default ClientInvoiceComponent;
