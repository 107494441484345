import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";

const AdminNewUserNewComponent = ({ createLocationApiRequest, createTechInfoApiRequest, createUserApiRequest }) => {
    const [validated, setValidated] = useState(false);
    const auth = useSelector(state => state.auth);
    const [updateUserResponseState, setUpdateUserResponseState] = useState({ message: "", error: "" });
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const [selectedRoles, setSelectedRoles] = useState(['Technician']);
    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
    const [selectedColor, setSelectedColor] = useState('');
    const [booleanValue, setBooleanValue] = useState(false); // State for the boolean dropdown
    const navigate = useNavigate();

    // Conditionally include "Developer" in the roles array
    const roles = ['Admin', 'Office', 'Inventory', 'Dispatcher', 'Technician'];
    if (auth.user.usertype.includes('Developer')) {
        roles.push('Developer');
    }

    const initialColors = [
        '#7fffd4',
        '#1e90ff',
        '#6495ed',
        '#008b8b',
        '#5f9ea0',
        '#00008b',
        '#2e8b57',
        '#556b2f',
        '#8fbc8f',
        '#9acd32',
        '#bdb76b',
        '#808000',
        '#deb887',
        '#d2b48c',
        '#cd853f',
        '#b8860b',
        '#d2691e',
        '#a52a2a',
        '#ffa500',
        '#ff8c00',
        '#ff6347',
        '#ff4500',
        '#dc143c',
        '#8b0000',
        '#ee82ee',
        '#da70d6',
        '#db7093',
        '#bc8f8f',
        '#8a2be2',
        '#191970',
        '#3cb371',
        '#008000',
        '#20b2aa',
        '#4169e1',
        '#2f4f4f',
        '#708090',
        '#f0e68c',
        '#000',
        '#5e5e5e',
    ];

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleColorSelect = (color) => {
        setSelectedColor(color);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;

        const formInputsLocation = {
            name: 'Van',
            company: auth.user.company,
            tech: '',
            defaultLoc: false,
            van: true,
        };

        const formInputsTech = {
            company: auth.user.company,
            user: '',
            color: selectedColor,
        };

        const formInputs = {
            name: capitalizeFirstLetter(form.name.value),
            lastName: capitalizeFirstLetter(form.lastName.value),
            username: form.username.value,
            email: form.email.value,
            phoneNumber: formattedPhoneNumber,
            company: auth.user.company,
            usertype: selectedRoles,
        };

        createUserApiRequest(formInputs)
            .then(data => {
                if (data.message === "Verification email sent, please check your email!") {

                    const formInputTechEdit = {
                        ...formInputsTech,
                        user: data.data,
                    }
                    createTechInfoApiRequest(formInputTechEdit)
                        .then((data) => {
                            if (data.message === "Tech info created") {
                                if (booleanValue) {
                                    const formInputLocationEdit = {
                                        ...formInputsLocation,
                                        tech: data.data,
                                    }

                                    createLocationApiRequest(formInputLocationEdit)
                                        .then((data) => {
                                            if (data.message === "location created") {
                                                navigate(`${paths.ADMINROOTUSERS}`);
                                            };
                                        })
                                        .catch((er) => {
                                            console.log({
                                                error: er.response.data.message
                                                    ? er.response.data.message
                                                    : er.response.data,
                                            });
                                        });
                                } else {
                                    navigate(`${paths.ADMINROOTUSERS}`);
                                }
                            };
                        })
                        .catch((er) => {
                            console.log({
                                error: er.response.data.message
                                    ? er.response.data.message
                                    : er.response.data,
                            });
                        });
                }
            })
            .catch(er => {
                setUpdateUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
            });

        setValidated(true);
    };

    const handleChange = (event) => {
        const phoneNumber = event.target.value.replace(/\D/g, '');
        const formattedNumber = phoneNumber.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1)-$2-$3'
        );
        setFormattedPhoneNumber(formattedNumber);
    };

    const handleRoleChange = (event) => {
        const { value } = event.target;
        if (value === 'Admin') {
            setSelectedRoles(['Admin']);
            setIsDropdownDisabled(true);
        } else if (value === 'Developer') {
            setSelectedRoles(['Developer']);
            setIsDropdownDisabled(true);
        } else {
            setSelectedRoles(prevRoles => [...prevRoles.filter(role => role !== 'Admin'), value]);
        }
    };

    const handleRemoveRole = (role) => {
        const newSelectedRoles = selectedRoles.filter(r => r !== role);
        setSelectedRoles(newSelectedRoles);
        if (role === 'Admin') {
            setIsDropdownDisabled(false);
        }
        if (role === 'Developer') {
            setIsDropdownDisabled(false);
        }
    };

    const handleBooleanChange = (event) => {
        const value = event.target.value === 'true';
        setBooleanValue(value);
    };

    const availableRoleOptions = roles.filter((itm) => !selectedRoles.includes(itm));

    return (
        <div className="pt-4 mt-5">
            <div className="container">
                <div className="row justify-content-sm-center mt-5">
                    <div className="col-sm-6 m-0 p-0">
                        <h1>New User</h1>
                        <form className="pt-4" noValidate validated={validated.toString()} onSubmit={handleSubmit}>
                            <div className="row m-0 p-0">
                                <div className="col-sm-6 m-0 p-0 pe-3 mb-3">
                                    <label htmlFor="firstName">First Name:</label>
                                    <input
                                        id="firstName"
                                        name="name"
                                        required
                                        className="form-control"
                                        type="text"
                                    />
                                </div>
                                <div className="col-sm-6 m-0 p-0 mb-3">
                                    <label htmlFor="lastName">Last Name:</label>
                                    <input
                                        id="lastName"
                                        name="lastName"
                                        required
                                        className="form-control"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="username">Username:</label>
                                <input
                                    id="username"
                                    name="username"
                                    className="form-control"
                                    required
                                    type="text"
                                />
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-sm-6 m-0 p-0 pe-3 mb-3">
                                    <label htmlFor="email">Email:</label>
                                    <input
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        required
                                        type="text"
                                    />
                                </div>
                                <div className="col-sm-6 m-0 p-0 mb-3">
                                    <label htmlFor="phoneNumber">Phone Number:</label>
                                    <input
                                        type="text"
                                        id="phoneNumber"
                                        required
                                        className="form-control"
                                        placeholder="(xxx)-xxx-xxxx"
                                        value={formattedPhoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="usertype">User Role:</label>
                                <div className="selected-roles mb-2">
                                    {selectedRoles.length > 0 && (
                                        <div className="d-flex flex-wrap">
                                            {selectedRoles.map((role, index) => (
                                                <div key={index} className="selected-role mt-2 me-2">
                                                    <span className="selected-role-name">
                                                        {role}
                                                        <button
                                                            type="button"
                                                            className="btn btn-link btn-remove-role"
                                                            onClick={() => handleRemoveRole(role)}
                                                        >
                                                            x
                                                        </button>
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <select
                                    className="form-select mt-3 text-dark"
                                    id="usertype"
                                    name="usertype"
                                    onChange={handleRoleChange}
                                    value=""
                                    disabled={isDropdownDisabled}
                                >
                                    <option value="" disabled>
                                        User Role
                                    </option>
                                    {availableRoleOptions.map(role => (
                                        <option key={role} value={role}>
                                            {role}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="booleanField">Field tech:</label>
                                <select
                                    className="form-select mt-3 text-dark"
                                    id="booleanField"
                                    name="booleanField"
                                    onChange={handleBooleanChange}
                                    value={booleanValue.toString()}
                                >
                                    <option value="" disabled>
                                        Select a value
                                    </option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                            <span className="mb-2">Color Tag:</span>
                            <div className="row mt-1 justify-content-start">
                                {initialColors.map((color) => (
                                    <div
                                        key={color}
                                        id="color"
                                        className="col-1 d-flex align-items-center justify-content-center"
                                        style={{
                                            backgroundColor: color,
                                            height: '20px',
                                            width: '20px',
                                            cursor: 'pointer',
                                            color: '#fff',
                                            borderRadius: '5px',
                                            padding: '0',
                                            margin: '1px',
                                            border: selectedColor === color ? '1px solid #000' : 'none',
                                        }}
                                        onClick={() => handleColorSelect(color)}
                                    >
                                        {selectedColor === color && <span>&#10004;</span>}
                                    </div>
                                ))}
                            </div>
                            <div className="row d-flex justify-content-center mt-5 pt-3 pb-4 mb-5">
                                <div className="col-sm-3 text-end">
                                    <button className="btn btn-outline-success" type="submit">
                                        Create User
                                    </button>
                                </div>
                                <div className="col-sm-3 text-start">
                                    <Link to={paths.ADMINROOTUSERS} className="btn btn-outline-primary">
                                        Go Back
                                    </Link>
                                </div>
                            </div>
                            {updateUserResponseState.error}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminNewUserNewComponent;