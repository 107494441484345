import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";

const CreateJobTypePageComponent = ({ createJobTypeApiRequest }) => {
    const [validated, setValidated] = useState(false);
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const auth = useSelector(state => state.auth)
    const [createJobTypeResponseState, setCreateJobTypeResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            days: days,
            hours: hours,
            minutes: minutes,
            company: auth.user.company,
        };
        if (event.currentTarget.checkValidity() === true) {
            createJobTypeApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Job type created") navigate(`${paths.DISPATCHJOBTYPE}`);
                })
                .catch((er) => {
                    setCreateJobTypeResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const handleDaysChange = (event) => {
        setDays(event.target.value);
    };

    const handleHoursChange = (event) => {
        setHours(event.target.value);
    };

    const handleMinutesChange = (event) => {
        setMinutes(event.target.value);
    };

    return (
        <div className="pt-5 mt-3">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-6">
                        <h1 className="text-center">New Job Type</h1>
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="row mt-4 g-3">
                            <div className="row mt-3">
                                <div className="col">
                                    <label htmlFor="name">Name:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3 mt-3">
                                <div className="col">
                                    <label htmlFor="days">Days:</label>
                                    <select
                                        className="form-control"
                                        id="days"
                                        name="days"
                                        value={days}
                                        onChange={handleDaysChange}
                                    >
                                        <option value="">Select Days</option>
                                        {Array.from({ length: 31 }, (_, i) => i).map((day) => (
                                            <option key={day} value={day}>
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="hours">Hours:</label>
                                    <select
                                        className="form-control"
                                        id="hours"
                                        name="hours"
                                        value={hours}
                                        onChange={handleHoursChange}
                                    >
                                        <option value="">Select Hours</option>
                                        {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                                            <option key={hour} value={hour}>
                                                {hour}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="minutes">Minutes:</label>
                                    <select
                                        className="form-control"
                                        id="minutes"
                                        name="minutes"
                                        value={minutes}
                                        onChange={handleMinutesChange}
                                    >
                                        <option value="">Select Minutes</option>
                                        {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                                            <option key={minute} value={minute}>
                                                {minute}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="text-center mt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.DISPATCHJOBTYPE} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createJobTypeResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateJobTypePageComponent;