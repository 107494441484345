import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { logout } from "../../redux/actions/userActions";
import paths from "../../router/paths";
import '../assets/workSchedule.css';

const RequestTimeOffEditComponent = ({ onCancel, getSchedule, onConfirm, updateTimeOffRequestAll, requestEdit }) => {
    const auth = useSelector(state => state.auth)
    const [allDay, setAllDay] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [reason, setReason] = useState('');
    const [timeDetails, setTimeDetails] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [scheduleMap, setScheduleMap] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const today = moment().format('YYYY-MM-DD');

    useEffect(() => {
        if (!auth?.isAuthenticated) {
            dispatch(logout());
            navigate(paths.LOGIN);
        } else {

            if (requestEdit && requestEdit.startDate) {
                const Start = moment(requestEdit.startDate).format('yyyy-MM-DD');

                setStartDate(Start);
            }

            if (requestEdit && requestEdit.endDate) {
                const End = moment(requestEdit.endDate).format('yyyy-MM-DD');

                setEndDate(End);
            }

            if (requestEdit) {
                setAllDay(requestEdit.allDay);
            }

            if (requestEdit && requestEdit.reason) {
                setReason(requestEdit.reason);
            }

            if (requestEdit) {
                setScheduleMap(requestEdit);
            }

            if (requestEdit) {
                const formattedDetails = requestEdit.details.map(detail => ({
                    date: moment(detail.date).format('yyyy-MM-DD'),
                    startTime: moment(detail.startTime).format('HH:mm') || '',
                    endTime: moment(detail.endTime).format('HH:mm') || '',
                    isScheduled: true,
                }));
                setTimeDetails(formattedDetails);
            }
        }
    }, [auth.user._id, auth.user.name, dispatch, navigate, setScheduleMap, auth?.isAuthenticated, requestEdit, startDate, endDate, scheduleMap, allDay]);

    useEffect(() => {
        const validTimeDetails = allDay || timeDetails.every(detail => detail.startTime && detail.endTime);
        setIsFormValid(startDate && endDate && reason && validTimeDetails);
    }, [startDate, endDate, reason, timeDetails, allDay]);

    const handleDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            getSchedule(auth.user._id).then(scheduleDataResponse => {
                if (scheduleDataResponse && scheduleDataResponse.length) {
                    const dayMapping = scheduleDataResponse.reduce((acc, curr) => {
                        acc[curr.day] = { startTime: curr.startTime, endTime: curr.endTime };
                        return acc;
                    }, {});
                    updateTimeDetails(start, end, dayMapping);
                }
            }).catch(error => {
                console.error("Error fetching schedule data for date change:", error);
            });
        }
    };

    const handleAllDayChange = () => {
        setAllDay(prev => !prev);
    };

    const updateTimeDetails = (start, end, map) => {
        const startMoment = moment(start);
        const endMoment = moment(end);
        const details = [];

        while (startMoment.isSameOrBefore(endMoment, 'day')) {
            const dayName = startMoment.format('dddd');
            if (map[dayName]) {
                details.push({
                    date: startMoment.format('YYYY-MM-DD'),
                    startTime: allDay ? '' : moment(map[dayName].startTime).format('HH:mm'),
                    endTime: allDay ? '' : moment(map[dayName].endTime).format('HH:mm'),
                    isScheduled: true,
                });
            }
            startMoment.add(1, 'day');
        }

        setTimeDetails(details);
    };

    const handleTimeChange = (index, field, value) => {
        const updatedDetails = [...timeDetails];
        updatedDetails[index][field] = value;
        setTimeDetails(updatedDetails);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formattedDetails = timeDetails.map(detail => {
            const formattedDate = moment(detail.date).format('YYYY-MM-DD');

            return {
                date: moment(formattedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                startTime: detail.startTime ? moment(`${formattedDate}T${detail.startTime}`).toISOString() : null,
                endTime: detail.endTime ? moment(`${formattedDate}T${detail.endTime}`).toISOString() : null,
            };
        });

        const formInputs = {
            userId: auth.user._id,
            startDate: moment(startDate).toISOString(),
            endDate: moment(endDate).toISOString(),
            allDay,
            reason,
            company: auth.user.company,
            details: formattedDetails,
            status: 'Submitted',
        };
        try {
            const response = await updateTimeOffRequestAll(requestEdit._id, formInputs);
            if (response.message === "Time off status updated") {
                onConfirm();
            }
        } catch (error) {
            console.error("Failed to submit time off request:", error);
        }
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box-request">
                <div className="alertWhite alert-light text-start" role="alert">
                    <div>
                        <div className="popup">
                            <h2 className="popup-header text-center">Request Time Off</h2>
                            <div className="popup-content-scrollable">
                                <div className="mt-4">
                                    <form onSubmit={handleSubmit} className="row g-3 mt-5 justify-content-center needs-validation">
                                        <div className="col-sm-12">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-md-6">
                                                    <label htmlFor="startDate" className="form-label">Start Date</label>
                                                    <input type="date" className="form-control" id="startDate" value={startDate} min={today} onChange={e => handleDateChange(e.target.value, endDate)} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="endDate" className="form-label">End Date</label>
                                                    <input type="date" className="form-control" id="endDate" value={endDate} min={startDate || today} onChange={e => handleDateChange(startDate, e.target.value)} required />
                                                </div>
                                            </div>
                                            <div className="row mt-4 mb-4 d-flex justify-content-center">
                                                <div className="col-12">
                                                    <label htmlFor="reason" className="form-label">Reason for Time Off</label>
                                                    <textarea className="form-control" id="reason" value={reason} onChange={e => setReason(e.target.value)} required></textarea>
                                                </div>
                                            </div>
                                            <div className="row d-flex justify-content-start mb-4">
                                                <div className="col-sm-12">
                                                    <div className="form-check">
                                                        <input disabled={!startDate || !endDate} className="form-check-input" type="checkbox" id="allDay" checked={allDay} onChange={handleAllDayChange} />
                                                        <label className="form-check-label" htmlFor="allDay">All Full Days Off</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {!allDay && timeDetails.map((detail, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-md-6">
                                                        <label htmlFor={`startTime${index}`} className="form-label">{`Start Time (${detail.date})`}</label>
                                                        <input type="time" className="form-control" id={`startTime${index}`} value={detail.startTime} onChange={e => handleTimeChange(index, 'startTime', e.target.value)} required disabled={!detail.isScheduled} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor={`endTime${index}`} className="form-label">{`End Time (${detail.date})`}</label>
                                                        <input type="time" className="form-control" id={`endTime${index}`} value={detail.endTime} onChange={e => handleTimeChange(index, 'endTime', e.target.value)} required disabled={!detail.isScheduled} />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="row deleteWidth mt-4 bg-light justify-content-center border-top pt-3 border-dark">
                                                <div className="col-sm-3 text-center">
                                                    <button type="submit" className="btn btn-outline-success loginButton" disabled={!isFormValid}>Edit Request</button>
                                                </div>
                                                <div className="col-sm-3">
                                                    <button onClick={onCancel} className="btn btn-outline-primary loginButton">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ >
    );
};

export default RequestTimeOffEditComponent;