import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import './assets/inventory.css';
import InventoryCheckOutCompontent from "./inventory/inventoryCheckOutComponent";
import { FaRegThumbsUp } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import InventoryRequestPopUpComponent from "./inventory/inventoryRequestComponent";
import { MdOutlineInventory } from "react-icons/md";

const InventoryComponent = ({ fetchRequest, createRequestApiRequest, fetchUsers, fetchTechInfo, fetchUploadsID, fetchLocation, model, years, name }) => {
    const [inventory, setInventory] = useState({ OEM: [], nonOEM: [] });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [showAlertSuc, setShowAlertSuc] = useState(false);
    const [locations, setLocations] = useState([]);
    const [tech, setTech] = useState([]);
    const auth = useSelector(state => state.auth);
    const [user, setUser] = useState([]);
    const [techId, setTechId] = useState([]);
    const [showConfirmationReq, setShowConfirmationReq] = useState(false);
    const [selectedItemReq, setSelectedItemReq] = useState(null);
    const [filterLocations, setFilterLocations] = useState([]);
    const [showAlertSucReq, setShowAlertSucReq] = useState(false);

    const getModelAndPriceFromURL = useCallback(async () => {
        try {
            if (name && model && years) {
                const { data } = await axios.get(`/api/inventory?make=${name}&year=${years}&model=${model}`);
                const updatedData = await Promise.all(data.map(async (item) => {
                    if (item.image) {
                        const imageURL = await fetchUploadsID(item.image);
                        item.imageUrl = imageURL;
                    }
                    return item;
                }));

                const sortedData = updatedData.reduce((acc, item) => {
                    item.oem ? acc.OEM.push(item) : acc.nonOEM.push(item);
                    return acc;
                }, { OEM: [], nonOEM: [] });

                setInventory(sortedData);
            }
        } catch (error) {
            setInventory({
                OEM: [{ name: error.response?.data?.message || error.response?.data || 'An error occurred.' }],
                nonOEM: []
            });
        }
    }, [name, model, years, fetchUploadsID]);

    useEffect(() => {
        const abctrl = new AbortController();

        fetchUsers()
            .then((data) => {
                const filteredUser = data.find((item) => item._id === auth.user._id);
                const filteredCompany = data.filter((item) => item.company === auth.user.company);

                setUser(filteredCompany);

                fetchTechInfo()
                    .then((resData) => {
                        const filteredTech = resData.find((item) => item.user === filteredUser._id);
                        const filteredTechCom = resData.filter((item) => item.company === auth.user.company);

                        setTechId(filteredTechCom);
                        setTech(resData);

                        fetchLocation()
                            .then((dataRes) => {
                                const filterLocation = dataRes.filter((itm) => itm.van === true || itm.defaultLoc === true);

                                const updatedLocations = filterLocation.map(location => {
                                    const techInfo = resData.find(tech => tech._id === location.tech);
                                    const userInfo = techInfo ? data.find(user => user._id === techInfo.user) : null;
                                    return { ...location, techUser: userInfo };
                                });

                                setLocations(updatedLocations);

                                if (filteredTech) {
                                    const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);
                                    setFilterLocations(filteredLocation);
                                } else {
                                    const defaultLocation = dataRes.find((item) => item.defaultLoc === true);
                                    setFilterLocations(defaultLocation);
                                }
                            })
                            .catch((er) => console.log(er.response?.data?.message || er.response?.data || er));
                    })
                    .catch((er) => console.log(er.response?.data?.message || er.response?.data || er));
                getModelAndPriceFromURL();
            })
            .catch((er) => console.log(er.response?.data?.message || er.response?.data || er));

        return () => abctrl.abort();
    }, [years, model, getModelAndPriceFromURL, fetchLocation, fetchUsers, fetchTechInfo, auth.user.company, auth.user._id]);

    const removeFromStock = async (id, sourceLocationName, destinationLocationName, quantity) => {
        const parsedQuantity = parseInt(quantity, 10);

        if (isNaN(parsedQuantity) || !Number.isInteger(parsedQuantity) || parsedQuantity <= 0) {
            setShowAlertMessage("Quantity must be a positive integer.");
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
            return;
        }

        try {
            const formInputs = {
                quantity: parsedQuantity,
                inventory: destinationLocationName,
            }
            await axios.put(`/api/inventory/${id}/location/${sourceLocationName._id}/transfer/${destinationLocationName._id}`, { ...formInputs });
            setShowAlertSuc(true);
            setTimeout(() => setShowAlertSuc(false), 3000);
            getModelAndPriceFromURL();
        } catch (error) {
            setShowAlertMessage(error.response?.data?.error === "Invalid quantity to transfer." ? "Invalid quantity to transfer." : `Error transferring item between locations: ${error.message}`);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        }
    };

    const handleSubmit = async (id, destinationLocationName, quantity, inventory, locations) => {
        const parsedQuantity = parseInt(quantity, 10);

        if (isNaN(parsedQuantity) || !Number.isInteger(parsedQuantity) || parsedQuantity <= 0) {
            setShowAlertMessage("Quantity must be a positive integer.");
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
            return;
        }

        const destinationLocationCheck = inventory.find(loc =>
            loc.InventoryLocation.find(inv => inv.name1 === destinationLocationName.name1)
        );

        const inventoryLocationsWithNames = locations.map(location => {
            const invLoc = destinationLocationCheck.InventoryLocation.find(inv => inv._id === location._id);

            return !invLoc && {
                name1: location.techUser ? `${location.techUser.name} ${location.name}` : `${location.name}`,
            };
        });

        const foundName = inventoryLocationsWithNames.find(inv => inv.name1);

        try {
            const dataRes = await fetchRequest();

            let filteredRequest;

            if (dataRes.message === 'request not found') {
                filteredRequest = undefined;
            } else {
                filteredRequest = dataRes.find((item) => item.toLocation === destinationLocationName._id && ['Submitted', 'Pending'].includes(item.status) && item.inventoryId === id);
            }

            if (filteredRequest) {
                setShowAlertMessage("You have already requested stock from this technician.");
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
            } else {
                const formInputs = {
                    inventory: destinationLocationCheck,
                    name1: foundName,
                    quantity: parsedQuantity,
                    location: filterLocations._id,
                    toLocation: destinationLocationName._id,
                    status: 'Submitted',
                    inventoryId: id,
                    company: auth.user.company,
                };

                const data = await createRequestApiRequest(formInputs);
                if (data.message === "request created") {
                    setShowAlertSucReq(true);
                    setTimeout(() => setShowAlertSucReq(false), 3000);
                }
            }
        } catch (error) {
            setShowAlertMessage(error.response?.data?.error === "Invalid quantity to request." ? "Invalid quantity to request." : `Error requesting item: ${error.message}`);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        }
    };

    const handleCheckout = (id, item) => {
        setSelectedItem({ id, item });
        setShowConfirmation(true);
    };

    const onConfirmCheckout = (quantity, destinationLocation) => {
        removeFromStock(selectedItem.id, selectedItem.item, destinationLocation, quantity);
        setShowConfirmation(false);
    };

    const onCancelCheckout = () => {
        setShowConfirmation(false);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleCloseAlertSuc = () => {
        setShowAlertSuc(false);
    };

    const handleReq = (id, item) => {
        setSelectedItemReq({ id, item });
        setShowConfirmationReq(true);
    };

    const onConfirmRequest = (quantity) => {
        handleSubmit(selectedItemReq.id, selectedItemReq.item, quantity, inventory.nonOEM, locations);
        setShowConfirmationReq(false);
    };

    const onCancelRequest = () => {
        setShowConfirmationReq(false);
    };

    const getTechName = (techReqId) => {
        const techUser = tech.find((tech) => tech._id === techReqId);
        const techName = techUser ? user.find((users) => users._id === techUser.user)?.name : "";
        return techName || "";
    };

    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    const renderInventory = (items) => items.map((item, idx) => {
        const inventoryLocationsWithNames = locations.map(location => {
            const invLoc = item.InventoryLocation.find(inv => inv._id === location._id);

            return invLoc ? { ...invLoc, name1: location.techUser ? `${location.techUser.name} ${location.name}` : `${location.name}`, tech: location.tech, user: location.user } : {
                name1: location.techUser ? `${location.techUser.name} ${location.name}` : `${location.name}`,
                location1: "Unknown",
                ourCost1: "0",
                quantity: 0,
                dontStock: false,
                _id: location._id
            };
        });

        return (
            <React.Fragment key={idx}>
                <div className="row align-items-center mt-5">
                    <div className="col-sm-2 text-center d-flex justify-content-center">
                        {item.imageUrl && <img src={item.imageUrl.imageUrl} className="img-fluid-locksmith" alt={`Item ${idx}`} />}
                    </div>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-12"><strong>Product:</strong> {item.name}</div>
                        </div>
                        {item.sku && <div className="row"><div className="col-sm-6"><strong>SKU:</strong> {item.sku}</div></div>}
                        {item.fccId && <div className="row"><div className="col-sm-12"><strong>FCC ID:</strong> {item.fccId}</div></div>}
                        {item.chip && <div className="row"><div className="col-sm-6"><strong>Chip ID:</strong> {item.chip}</div></div>}
                        {item.frequency && <div className="row"><div className="col-sm-3"><strong>Frequency:</strong> {item.frequency}</div></div>}
                        {item.battery && <div className="row"><div className="col-sm-3"><strong>Battery:</strong> {item.battery}</div></div>}
                        {item.replacementPin && <div className="row"><div className="col-sm-12"><strong>Replaces PN:</strong> {item.replacementPin}</div></div>}
                        {item.allKeysLostPrice && item.vehicleInven ? (
                            <div className="row">
                                <div className="col-sm-12"><strong>Spare Key Price:</strong> ${item.price}</div>
                            </div>
                        ) : (
                        <div className="row">
                            <div className="col-sm-12"><strong>Selling Price:</strong> ${item.price}</div>
                        </div>
                        )}
                        {item.price && item.vehicleInven && (
                            <div className="row">
                                <div className="col-sm-12">
                                    <strong>All Keys Lost Price:</strong> ${Number(item.allKeysLostPrice).toFixed(2)}
                                </div>
                            </div>
                        )}
                        {inventoryLocationsWithNames.map((inv, idx) => {
                            const locationData = filterLocations && filterLocations.tech
                                ? locations.filter(location => !location.defaultLoc && filterLocations.tech !== location.tech).map(location => `${getTechName(location.tech)} ${capitalizeFirstLetter(location.name)}`).includes(inv.name1)
                                : locations.filter(location => location.defaultLoc && location.name === inv.name1).map(location => `${capitalizeFirstLetter(location.name)}`).includes(inv.name1);
                            return (
                                <div key={idx} className="row">
                                    <span className="mt-2 fw-bold mb-1">{inv.name1}</span>
                                    <div className="row ps-3 m-0 p-0 w-100">
                                        <div className="col-sm-3 m-0 p-0">
                                            <strong>Location:</strong> {inv.location1 || "Unknown"}
                                        </div>
                                        <div className="col-sm-3 m-0 p-0">
                                            <strong>Our Cost:</strong> ${inv.ourCost1}
                                        </div>
                                        <div className="col-sm-4 m-0 p-0">
                                            {!inv.dontStock && inv.quantity === 0 ? (
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <strong>Stock:</strong>
                                                        <span className="text-danger ms-2">Special Order Key</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {inv.quantity === 0 ? (
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <strong>Stock:</strong>
                                                                <span className="text-danger ms-2">Out Of Stock</span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <strong>Stock:</strong>
                                                                <span className="text-success ms-2">{inv.quantity}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {filterLocations && filterLocations.tech ? (
                                            locationData ? (
                                                <div className="col-sm">
                                                    <button className="btn btn-outline-primary loginButton" onClick={() => handleReq(item._id, inv)} disabled={!inv.dontStock || inv.quantity === 0}>Request</button>
                                                </div>
                                            ) : (
                                                <div className="col-sm">
                                                    <button className="btn btn-outline-primary loginButton" onClick={() => handleCheckout(item._id, inv)} disabled={!inv.dontStock || inv.quantity === 0}>Check Out</button>
                                                </div>
                                            )
                                        ) : (
                                            locationData ? (
                                                <div className="col-sm">
                                                    <button className="btn btn-outline-primary loginButton" onClick={() => handleReq(item._id, inv)} disabled>Request</button><br />
                                                    <span className="fs-6 text-danger">Can't request keys until you're a technician.</span>
                                                </div>
                                            ) : (
                                                <div className="col-sm">
                                                    <button className="btn btn-outline-primary loginButton" onClick={() => handleCheckout(item._id, inv)} disabled>Check Out</button><br />
                                                    <span className="fs-6 text-danger">Can't check out keys until you're a technician.</span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    });

    return (
        <div className="container-fluid mb-5 mt-4 w-100">
            {showConfirmationReq && selectedItemReq && filterLocations !== undefined ? (
                <InventoryRequestPopUpComponent
                    message={`Enter the quantity to request (Available stock: ${selectedItemReq.item.quantity}):`}
                    selectedItem={selectedItemReq}
                    onConfirm={onConfirmRequest}
                    onCancel={onCancelRequest}
                />
            ) : showConfirmationReq && (
                <div className="alertRed w-90 alert-danger" role="alert">
                    Technician has not been set with an inventory yet.
                    <div className="alert-line-container">
                        <span className="alert-closeRed" onClick={onCancelRequest}>X</span>
                    </div>
                </div>
            )}
            {showAlert && (
                <div className="alertRed alert-danger" role="alert">
                    {showAlertMessage}
                    <div className="alert-line-container">
                        <div className="alert-lineRed"></div>
                        <span className="alert-closeRed" onClick={handleCloseAlert}>X</span>
                    </div>
                </div>
            )}
            {showAlertSuc && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>X</span>
                    </div>
                </div>
            )}
            {showAlertSucReq && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Requested
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>X</span>
                    </div>
                </div>
            )}
            <hr className="w-100" />
            {showConfirmation && selectedItem && (
                <InventoryCheckOutCompontent
                    message={`Enter the quantity to remove (Available stock: ${selectedItem.item.quantity}):`}
                    selectedItem={selectedItem}
                    locations={locations}
                    tech={tech}
                    user={user}
                    techId={techId}
                    onConfirm={onConfirmCheckout}
                    onCancel={onCancelCheckout}
                />
            )}
            <div className="row">
                {inventory.OEM.length > 0 ? (
                    <div className="col-sm-12">
                        <span className="fs-4">OEM Products:</span>
                        {renderInventory(inventory.OEM)}
                    </div>
                ) : (
                    <div className="col-sm-12">
                        <span className="fs-4">OEM Products:</span><br />
                        <div className="depositModuleNoPayment mb-5 m-0 p-0 row justify-content-center">
                            <div className="col-sm-6 m-0 p-0 text-center">
                                <MdOutlineInventory className="depositImageNoPayment" /><br />
                                <span className="fs-4">There is currently nothing that is in the inventory.</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="row">
                {inventory.nonOEM.length > 0 ? (
                    <div className="col-sm-12">
                        <span className="fs-4">Non-OEM Products</span>
                        {renderInventory(inventory.nonOEM)}
                    </div>
                ) : (
                    <div className="col-sm-12">
                        <span className="fs-4">Non-OEM Products:</span><br />
                        <div className="depositModuleNoPayment mb-5 m-0 p-0 row justify-content-center">
                            <div className="col-sm-6 m-0 p-0 text-center">
                                <MdOutlineInventory className="depositImageNoPayment" /><br />
                                <span className="fs-4">There is currently nothing that is in the inventory.</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InventoryComponent;
