import React, { useState } from "react";

const DiscountPopupComponent = ({ discountValue, onConfirm, onCancel, discountType }) => {
    const [inputValue, setInputValue] = useState(discountValue);
    const [inputType, setInputType] = useState(discountType);

    const handleInputChange = (e) => {
        setInputValue(parseFloat(e.target.value));
    };

    const handleApplyDiscount = () => {
        onConfirm(inputValue, inputType);
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel} // Close modal if clicked outside
            />
            <div className="confirmation-box-item">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder bg-light deleteWidth mb-4">
                        <div className="col-sm fs-5">
                            Set Discount
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-sm-9 d-flex align-items-start mt-2">
                        <div className="btn-group me-2" role="group" aria-label="Discount Type">
                            <button
                                type="button"
                                    className={`btn btn-sm ${inputType === 'percentage' ? 'btn-selected' : 'btn-unselected'}`}
                                onClick={() => setInputType('percentage')}
                            >
                                %
                            </button>
                            <button
                                type="button"
                                    className={`btn btn-sm ${inputType === 'amount' ? 'btn-selected' : 'btn-unselected'}`}
                                onClick={() => setInputType('amount')}
                            >
                                $
                            </button>
                        </div>
                        <input
                            className="form-control form-control-sm formWidth"
                            id="discountDisplay"
                            type="number"
                            onChange={handleInputChange}
                            value={inputValue}
                            aria-label="Disabled input example"
                        />
                    </div>
                </div>
                    <div className="row  bg-light mt-5 border-top pt-3 pb-3 border-dark">
                        <div className="col-sm">
                            <button className="btn btn-outline-primary ps-5 pe-5" onClick={onCancel}>
                                Close
                            </button>
                        </div>
                        <div className="col-sm">
                            <button onClick={handleApplyDiscount} className="btn btn-outline-danger m-0 ps-5 pe-5">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DiscountPopupComponent;
