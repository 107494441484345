import React, { useState, useEffect } from "react";
import '../assets/workSchedule.css';

const dayOfWeekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const getNextDayDate = (dayIndex) => {
    const date = new Date();
    date.setDate(date.getDate() + ((dayIndex + 7 - date.getDay()) % 7));
    return date;
};

const WorkScheduleEditComponent = ({ selectedId, onConfirm, onCancel, updateWorkApiRequest, editSchedule }) => {
    const [daysOfWeek, setDaysOfWeek] = useState(new Array(7).fill(false));
    const [startTime, setStartTime] = useState('');
    const [editFull, setEditFull] = useState(false);
    const [endTime, setEndTime] = useState('');
    const [overTime, setOverTime] = useState(false);

    useEffect(() => {
        if (selectedId && selectedId.start) {
            const date = new Date(selectedId.start);
            const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
            setStartTime(formattedTime);
        }
        if (selectedId && selectedId.end) {
            const date = new Date(selectedId.end);
            const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
            setEndTime(formattedTime);
        }
        if (selectedId && selectedId.all && selectedId.all.schedule) {
            const scheduleDays = selectedId.all.schedule.map(entry => dayOfWeekNames.indexOf(entry.day));
            const newDaysOfWeek = daysOfWeek.slice();
            scheduleDays.forEach(dayIndex => {
                if (dayIndex !== -1) {
                    newDaysOfWeek[dayIndex] = true;
                }
            });
            setDaysOfWeek(newDaysOfWeek);
        }
        if (selectedId && selectedId.overTime) {
            setOverTime(selectedId.overTime);
        }

    }, [daysOfWeek, selectedId]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (editFull) {
            const scheduleEntries = daysOfWeek.map((selected, index) => {
                if (selected) {
                    const dayDate = getNextDayDate(index);
                    const startDateTime = new Date(dayDate);
                    const endDateTime = new Date(dayDate);

                    startDateTime.setHours(...startTime.split(':').map(Number));
                    endDateTime.setHours(...endTime.split(':').map(Number));

                    return {
                        day: dayOfWeekNames[index],
                        startTime: startDateTime,
                        endTime: endDateTime
                    };
                }
                return null;
            }).filter(entry => entry !== null); // Filter out unselected days

            const formInputs = {
                overTime: overTime,
                schedule: scheduleEntries,
                sick: false,
                timeOff: false,
            };

            updateWorkApiRequest(selectedId.mainId, formInputs)
                .then((data) => {
                    if (data.message === "Schedule updated successfully") {
                        onConfirm()
                    }
                })
                .catch((error) => {
                    console.error(error.response?.data?.message || error.response?.data || error.message);
                });
        } else {

            const startDate = new Date(selectedId.start);
            const timeParts = startTime.split(':').map(Number);
            startDate.setHours(timeParts[0], timeParts[1], 0, 0);

            const dayOfWeek = startDate.toLocaleDateString('en-US', { weekday: 'long' });

            const endDate = new Date(selectedId.end);
            const timePartsEnd = endTime.split(':').map(Number);
            endDate.setHours(timePartsEnd[0], timePartsEnd[1], 0, 0);

            const formInputs = {
                day: dayOfWeek,
                startTime: startDate,
                endTime: endDate,
            };

            editSchedule(selectedId.mainId, selectedId.id, formInputs)
                .then((data) => {
                    if (data.message === "Schedule entry updated successfully") {
                        onConfirm()
                    }
                })
                .catch((error) => {
                    console.error(error.response?.data?.message || error.response?.data || error.message);
                });
        }
    };

    const toggleDay = index => {
        const newDaysOfWeek = [...daysOfWeek];
        newDaysOfWeek[index] = !newDaysOfWeek[index];
        setDaysOfWeek(newDaysOfWeek);
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box-work">
                <div className="alertWhite alert-light text-start" role="alert">
                    <div className="inventory-checkout-container">
                        <div className="popup">
                            <h2 className="popup-header text-center">Edit Schedule</h2>
                            <div className="popup-1">
                                <div className="mt-4">
                                    <form onSubmit={handleSubmit} className="row pt-4 g-3">
                                        <div className="row">
                                            <div className="col">
                                                <div className="days-of-week-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id='editFull'
                                                        checked={editFull}
                                                        onChange={() => setEditFull(!editFull)}
                                                    />
                                                    <label className="ms-2" htmlFor="editFull">Edit Everything</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="text-start">Scheduled Days:</label>
                                            <div className="days-of-week-container">
                                                {dayOfWeekNames.map((name, index) => (
                                                    <div key={name} className="days-of-week-checkbox">
                                                        <input
                                                            disabled={!editFull}
                                                            type="checkbox"
                                                            id={name}
                                                            checked={daysOfWeek[index]}
                                                            onChange={() => toggleDay(index)}
                                                        />
                                                        <label className="ms-2" htmlFor={name}>{name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>Start Time:</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    name="startTime"
                                                    value={startTime}
                                                    onChange={e => setStartTime(e.target.value)}
                                                />
                                            </div>
                                            <div className="col">
                                                <label>End Time:</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    name="endTime"
                                                    value={endTime}
                                                    onChange={e => setEndTime(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-2">
                                            <div className="col">
                                                <div className="days-of-week-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        disabled={!editFull}
                                                        id='overTime'
                                                        checked={overTime}
                                                        onChange={() => setOverTime(!overTime)}
                                                    />
                                                    <label className="ms-2" htmlFor="editFull">Clock in on days off?</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row deleteWidth mt-4 bg-light justify-content-center border-top pt-3 border-dark">
                                            <div className="col-sm-3 me-4"> 
                                                <button type="submit" disabled={!daysOfWeek.some(day => day) || !startTime || !endTime} className="btn btn-outline-success loginButton">
                                                    Confirm
                                                </button>
                                            </div>
                                            <div className="col-sm-3">
                                                <button onClick={onCancel} className="btn btn-outline-primary loginButton">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkScheduleEditComponent;
