import axios from "axios";
import InvoiceComponent from "./components/invoiceComponent";

const fetchInvoice = async (invoiceId) => {
    const { data } = await axios.get(`/api/invoice/get/${invoiceId}`, { withCredentials: true });
    return data;
};
const fetchDispatch = async (dispatchId) => {
    const { data } = await axios.get(`/api/dispatch/get-one/${dispatchId}`, { withCredentials: true });
    return data;
}
const fetchPaymentAll = async () => {
    const { data } = await axios.get(`/api/payment`, { withCredentials: true });
    return data
}
const fetchPayments = async (paymentsId) => {
    const { data } = await axios.get(`/api/payment/get/${paymentsId}`, { withCredentials: true });
    return data
}
const fetchUploadsAll = async (abctrl) => {
    const { data } = await axios.get("/api/uploads", { withCredentials: true });
    return data
}
const fetchJobItem = async (abctrl) => {
    const { data } = await axios.get("/api/jobItem", { withCredentials: true });
    return data
}
const fetchJobTax = async () => {
    const { data } = await axios.get(`/api/jobTax/dispatch`, { withCredentials: true });
    return data;
}
const fetchCompany = async (abctrl) => {
    const { data } = await axios.get("/api/company/admin", { withCredentials: true });
    return data
}
const getJobTaxById = async (taxId) => {
    const { data } = await axios.get(`/api/jobTax/get-one/${taxId}`, { withCredentials: true });
    return data
}
const fetchCustomer = async (customerId) => {
    const { data } = await axios.get(`/api/customer/get-one/${customerId}`, { withCredentials: true });
    return data;
}
const fetchUploading = async (abctrl) => {
    const { data } = await axios.get("/api/uploads", { withCredentials: true });
    return data
}
const fetchUploads = async (uploadId) => {
    const { data } = await axios.get(`/api/uploads/image/${uploadId}`, { withCredentials: true });
    return data
}
const deleteJobItem = async (jobItemId) => {
    const { data } = await axios.delete(`/api/jobItem/dispatch/${jobItemId}`, { withCredentials: true });
    return data
}
const deleteInvoice = async (invoiceId) => {
    const { data } = await axios.delete(`/api/invoice/delete/${invoiceId}`, { withCredentials: true });
    return data
}
const updateDispatchItemApiRequest = async (dispatchId, formInputsDis) => {
    const { data } = await axios.put(`/api/dispatch/dispatch/jobItem/${dispatchId}`, { ...formInputsDis }, { withCredentials: true });
    return data;
}
const updateInvoiceItem = async (invoiceId, formInputsEst) => {
    const { data } = await axios.put(`/api/invoice/item/${invoiceId}`, { ...formInputsEst }, { withCredentials: true });
    return data;
}
const updateInvoiceDue = async (invoiceId, formInputsDue) => {
    const { data } = await axios.put(`/api/invoice/due/${invoiceId}`, { ...formInputsDue }, { withCredentials: true });
    return data;
}
const updateInvoiceNotes = async (invoiceId, formInputsNote) => {
    const { data } = await axios.put(`/api/invoice/notes/${invoiceId}`, { ...formInputsNote }, { withCredentials: true });
    return data;
}
const updateInvoiceStatus = async (invoiceId, formInputsStat) => {
    const { data } = await axios.put(`/api/invoice/status/${invoiceId}`, { ...formInputsStat }, { withCredentials: true });
    return data;
}
const updateInvoiceDescription = async (invoiceId, formInputsDesc) => {
    const { data } = await axios.put(`/api/invoice/description/${invoiceId}`, { ...formInputsDesc }, { withCredentials: true });
    return data;
}
const updateInvoiceDiscount = async (invoiceId, formInputsDiscount) => {
    const { data } = await axios.put(`/api/invoice/discount/${invoiceId}`, { ...formInputsDiscount }, { withCredentials: true });
    return data;
}
const updateInvoiceTip = async (invoiceId, formInputsTip) => {
    const { data } = await axios.put(`/api/invoiceId/tip/${invoiceId}`, { ...formInputsTip }, { withCredentials: true });
    return data;
}
const updateInvoiceTax = async (invoiceId, formInputsTax) => {
    const { data } = await axios.put(`/api/invoice/tax/${invoiceId}`, { ...formInputsTax }, { withCredentials: true });
    return data;
}
const updateInvoiceUpload = async (invoiceId, formInputsUpload) => {
    const { data } = await axios.put(`/api/invoice/upload/${invoiceId}`, { ...formInputsUpload }, { withCredentials: true });
    return data;
}
const updateInvoiceSign = async (invoiceId, formInputsSign) => {
    const { data } = await axios.put(`/api/invoice/sign/${invoiceId}`, { ...formInputsSign }, { withCredentials: true });
    return data;
}
const updateJobItemsApiRequest = async (jobItemsId, formInputs) => {
    const { data } = await axios.put(`/api/jobItem/quanity/${jobItemsId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const createJobItemApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/jobItem/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};
const updateInvoiceTotal = async (invoiceId, formInputTotal) => {
    const { data } = await axios.put(`/api/invoice/total/${invoiceId}`, { ...formInputTotal }, { withCredentials: true });
    return data;
}
const deletePayment = async (paymentId) => {
    const { data } = await axios.delete(`/api/payment/delete/${paymentId}`, { withCredentials: true });
    return data
}

const InvoicePage = () => {

    return <InvoiceComponent
        deletePayment={deletePayment }
        fetchPayments={fetchPayments}
        fetchPaymentAll={fetchPaymentAll}
        updateInvoiceDue={updateInvoiceDue}
        fetchUploadsAll={fetchUploadsAll}
        updateInvoiceTotal={updateInvoiceTotal}
        updateInvoiceTip={updateInvoiceTip}
        deleteInvoice={deleteInvoice}
        updateInvoiceSign={updateInvoiceSign}
        fetchUploading={fetchUploading}
        updateInvoiceNotes={updateInvoiceNotes}
        updateDispatchItemApiRequest={updateDispatchItemApiRequest}
        deleteJobItem={deleteJobItem}
        updateJobItemsApiRequest={updateJobItemsApiRequest}
        createJobItemApiRequest={createJobItemApiRequest}
        fetchUploads={fetchUploads}
        updateInvoiceUpload={updateInvoiceUpload}
        updateInvoiceStatus={updateInvoiceStatus}
        fetchCustomer={fetchCustomer}
        updateInvoiceDescription={updateInvoiceDescription}
        fetchCompany={fetchCompany}
        updateInvoiceTax={updateInvoiceTax}
        updateInvoiceDiscount={updateInvoiceDiscount}
        updateInvoiceItem={updateInvoiceItem}
        getJobTaxById={getJobTaxById}
        fetchJobTax={fetchJobTax}
        fetchJobItem={fetchJobItem}
        fetchDispatch={fetchDispatch}
        fetchInvoice={fetchInvoice} />;
};

export default InvoicePage;
