import axios from "axios";
import JobTagComponent from "./components/jobTagComponent";

const fetchJobTag = async (abctrl) => {
    const { data } = await axios.get("/api/jobTag/dispatch", { withCredentials: true });
    return data
}

const deleteJobTag = async (jobTagId) => {
    const { data } = await axios.delete(`/api/jobTag/dispatch/${jobTagId}`, { withCredentials: true });
    return data
}

const JobTagPage = () => {

    return <JobTagComponent fetchJobTag={fetchJobTag} deleteJobTag={deleteJobTag} />;
};

export default JobTagPage;
