import React, { useState } from "react";

const InvoiceEmailPopupComponent = ({ onClose, onSend, companies, customer }) => {
    const [email, setEmail] = useState(customer.email || '');
    const [cc, setCC] = useState('');
    const [subject, setSubject] = useState(`Your service with ${companies.name}`);
  
    const [plainTextDescription, setPlainTextDescription] = useState(
        `Greetings ${customer.name} ${customer.lastName}.\n Thank you for your recent business with us.\n Click the link below to view your invoice.\n Feel free to call back.\n\n`
    );

    const handleSend = () => {
        const convertedHtmlDescription = plainTextDescription.replace(/\n/g, "<br/>");
        onSend(email, subject, convertedHtmlDescription, cc);
        onClose();
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onClose} 
            />
            <div className="confirmation-box1">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder bg-light deleteWidth mb-4">
                        <div className="col-sm fs-5">
                            Send File
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-11 text-center">
                            <div className="form-floating mb-2">
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="floatingInput" placeholder="name@example.com" />
                                <label htmlFor="floatingInput">Email address</label>
                            </div>
                            <div className="form-floating mb-2">
                                <input type="text" value={cc} onChange={(e) => setCC(e.target.value)} className="form-control" id="floatingPasswordCC" placeholder="CC" />
                                <label htmlFor="floatingPasswordCC">CC</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" id="floatingPassword" placeholder="Subject" />
                                <label htmlFor="floatingPassword">Subject</label>
                            </div>
                            <div className="form-floating mt-2 text-start">
                                <textarea
                                    value={plainTextDescription}
                                    onChange={(e) => setPlainTextDescription(e.target.value)}
                                    className="form-control"
                                    placeholder="Email Message"
                                    id="floatingTextarea2"
                                    style={{ height: "150px" }}
                                ></textarea>
                                <label htmlFor="floatingTextarea2">Email Message</label>
                            </div>
                        </div>
                    </div>
                    <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                        <div className="col-sm">
                            <button className="btn btn-outline-secondary ps-5 pe-5" onClick={onClose}>
                                Close
                            </button>
                        </div>
                        <div className="col-sm">
                            <button onClick={handleSend} className="btn btn-outline-primary m-0 ps-5 pe-5">
                                Send File
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceEmailPopupComponent;
