import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeClockLinksComponent from "../../../components/timeClock/timeClockLinksComponent";
import LoadingComponent from "../../../components/loadingComponet";
import { useNavigate } from "react-router-dom";
import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useEffect } from "react";
import paths from "../../../router/paths";
import TimeRequestDeleteComponent from "../../../components/timeClock/timeRequestDeleteComponent";
import { MdOutlineInventory } from "react-icons/md";
import { logout } from "../../../redux/actions/action";

const AdminUpdateTimeComponent = ({ clockStatus, fetchUsers }) => {
    const auth = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [clock, setClock] = useState([]);
    const [requestId, setRequestId] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;

    useEffect(() => {
        const abctrl = new AbortController();

        if (!auth?.isAuthenticated) {
            dispatch(logout());
            navigate(paths.LOGIN);
        } else {
            setLoading(true);

            clockStatus(abctrl)
                .then((res) => {
                    const clockFilter = res.filter((itm) => itm.company === auth.user.company);

                    const userPromises = clockFilter.map((clock) => fetchUsers(clock.userId, abctrl));
                    return Promise.all(userPromises).then((users) => {
                        const clockWithUsers = clockFilter.map((clock, index) => ({
                            ...clock,
                            user: users[index]
                        }));
                        setClock(clockWithUsers);
                        setLoading(false);
                    });
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }

        return () => abctrl.abort();
    }, [clockStatus, dispatch, navigate, auth.user]);

    useEffect(() => {
        const abctrl = new AbortController();

        if (loading) {
            if (!auth?.isAuthenticated) {
                dispatch(logout());
                navigate(paths.LOGIN);
            } else {
                setLoading(true);

                clockStatus(abctrl)
                    .then((res) => {
                        const clockFilter = res.filter((itm) => itm.company === auth.user.company);

                        // Fetch user details for each clock status
                        const userPromises = clockFilter.map((clock) => fetchUsers(clock.userId, abctrl));
                        return Promise.all(userPromises).then((users) => {
                            const clockWithUsers = clockFilter.map((clock, index) => ({
                                ...clock,
                                user: users[index]
                            }));
                            setClock(clockWithUsers);
                            setLoading(false);
                        });
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );
            }
        }

        return () => abctrl.abort();
    }, [clockStatus, dispatch, navigate, auth.user, loading]);

    const formatDate = (isoString) => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const date = new Date(isoString);
        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = date.getMinutes();
        const isAM = hours < 12;
        const period = isAM ? 'A.M.' : 'P.M.';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        const formattedMinutes = minutes.toString().padStart(2, '0');

        const formattedTime = `${hours}:${formattedMinutes} ${period}`;

        return `${dayOfWeek}, ${month} ${dayOfMonth}, ${year} ${formattedTime}`;
    }

    const deleteHandler = async (requestId) => {
        setRequestId(requestId)
        setShowAlert(true);
    };

    const handleCancelRemove = () => {
        setShowAlert(false);
    };

    const handleConfirmRemove = async () => {
        //const data = await deleteTimeOff(requestId);
        //if (data.message === 'Time Request deleted successfully') {
        //    setShowAlert(false);
        //    setLoading(true);
        //}
    };

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1); // Reset the page to 1 when the search query changes
    };

    console.log(clock)
    return (
        <div className="min-vh-100">
            <div className="container-fluid min-vh-100 m-0 p-0">
                {showAlert && (
                    <TimeRequestDeleteComponent
                        onConfirm={handleConfirmRemove}
                        onCancel={handleCancelRemove}
                    />
                )}
                <div className="row justify-content-md-center min-vh-100 m-0 p-0">
                    <div className="col-sm-2 m-0 p-0">
                        <TimeClockLinksComponent />
                    </div>
                    {loading && (
                        <div className="loading-overlay">
                            <LoadingComponent />
                        </div>
                    )}
                    <div className="col-sm-10 mt-5 pt-5">
                        <div className="row m-0 p-0 w-100 mb-3">
                            <div className="col-sm-7 text-start m-0 p-0">
                                <h1>Clock In Lists</h1>
                            </div>
                            <div className="col-sm-3 align-end m-0 p-0">
                                <input
                                    type="text"
                                    className="mt-1 input-group table-filter w-100"
                                    data-table="order-table"
                                    placeholder="Search clock times.."
                                    value={searchValue}
                                    disabled={clock.length === 0}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                        {clock.length > 0 ? (
                            <>
                                <div className="table-responsive">
                                    <table className="table-hover table-bordered order-table table">
                                        <thead>
                                            <tr>
                                                <th className="text-start">Employee Name</th>
                                                <th className="text-start">Clock In</th>
                                                <th className="text-start">Clock Out Break</th>
                                                <th className="text-start">Clock In Break</th>
                                                <th className="text-start">Clock Out</th>
                                                <th className="text-center">Edit / Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clock
                                                .filter(
                                                    (itm) =>
                                                        itm.user.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                                        itm.user.lastName.toLowerCase().indexOf(searchValue) !== -1
                                                )
                                                .map((itm, idx) => (
                                                    <>
                                                        <tr key={itm._id + idx}>
                                                            <td className="align-middle text-start">{itm.user.name} {itm.user.lastName}</td>
                                                            <td className="align-middle text-center">
                                                                {itm.clockIn ? `${formatDate(itm.clockIn.time)}` : '---'}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {itm.breakClockOut ? `${formatDate(itm.breakClockOut.time)}` : '---'}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {itm.breakClockIn ? `${formatDate(itm.breakClockIn.time)}` : '---'}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {itm.clockout ? `${formatDate(itm.clockOut.time)}` : '---'}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <button
                                                                    className="btn-sm btn btn-outline-primary"
                                                                //onClick={() => EditHandler(itm)}
                                                                >
                                                                    <GoPencil className="requestLogo" />
                                                                </button>
                                                                {" / "}
                                                                <button
                                                                    type="button"
                                                                    className="btn-sm btn btn-outline-danger"
                                                                    onClick={() => deleteHandler(itm._id)}
                                                                >
                                                                    <BsTrash className="requestLogo" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button
                                        className="btn-sm btn btn-outline-primary me-3 loginButton"
                                        disabled={page === 1}
                                        onClick={handlePrevPage}
                                    >
                                        Previous
                                    </button>
                                    <span className="mr-2 me-3">
                                        Page {page} of {Math.ceil(clock
                                            .filter(
                                                (itm) =>
                                                    itm.user.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                                    itm.user.lastName.toLowerCase().indexOf(searchValue) !== -1
                                            ).length / rowsPerPage)}
                                    </span>
                                    <button
                                        className="btn-sm btn btn-outline-primary loginButton"
                                        disabled={clock
                                            .filter(
                                                (itm) =>
                                                    itm.user.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                                    itm.user.lastName.toLowerCase().indexOf(searchValue) !== -1
                                            ).length <= page * rowsPerPage}
                                        onClick={handleNextPage}
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="mt-5">
                                <div className="row d-flex justify-content-center m-0 p-0">
                                    <div className="col-sm-4 m-0 p-0 text-center">
                                        <MdOutlineInventory className="inventoryLogo fs-1" />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center m-0 p-0">
                                    <div className="col-sm-4 m-0 p-0 text-center">
                                        <span>Currently no time off requests.<br /> Request time off by clicking on request time off button.</span> <br />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AdminUpdateTimeComponent;