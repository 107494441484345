import React from 'react';

const CustContactFormComponent = ({ submitContact }) => {

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget.elements;

        const formInputs = {
            name: form.name.value,
            phone: form.phone.value,
            email: form.email.value,
            phoneExt: form.phoneExt.value,
            message: form.message.value,
        };
        submitContact(formInputs)
            .then((data) => {
                if (data.message === "task created") {

                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data,
                });
            });
    };

    return (
        <div className="container mt-5 pt-5">
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    placeholder="John Doe"
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="example@gmail.com"
                />
                <input
                    type="phone"
                    name="phone"
                    placeholder="(801) 888-8888"
                    required
                />
                <input
                    type="phoneExt"
                    name="phoneExt"
                    placeholder="Ext: 123"
                />
                <textarea
                    name="message"
                    placeholder="Please provide a description of what your looking for."
                    required
                />
                <button type="submit">Send</button>
            </form>
        </div>
    );
}

export default CustContactFormComponent;
