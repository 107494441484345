import axios from 'axios'
import { useDispatch } from "react-redux";
import AdminEditVinPriceComponent from './components/adminEditVinPriceComponent';

const fetchVinCode = async (vinPriceId) => {
    const { data } = await axios.get(`/api/vinPrice/get/${vinPriceId}`, { withCredentials: true });
    return data;
}

const updateVinPriceApiRequest = async (vinPriceId, formInputs) => {
    const { data } = await axios.put(`/api/vinPrice/admin/${vinPriceId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const AdminEditVinPricePage = () => {

    const reduxDispatch = useDispatch();

    return <AdminEditVinPriceComponent fetchVinCode={fetchVinCode} updateVinPriceApiRequest={updateVinPriceApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditVinPricePage;
