import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import '../assets/payment.css';
import { FaRegThumbsUp } from 'react-icons/fa';
import ClientEstimateSignaturesComponent from '../clientEstimateSignComponent';
import { useParams } from "react-router-dom";

const ClientEstimatePaymentForm = ({
    signatureData,
    setLoading,
    jobItem,
    selectedOptionalItems,
    handleSubmitDecline,
    setSignatureData,
    updateEstimateSign,
    companyId,
    totalPay,
    estimateId,
    companies,
    dispatching,
    updateJobItemsApiRequest,
    updateEstimateStatus,
    setShowAlert1,
    setCreateEstimateResponseState
}) => {
    const stripe = useStripe();
    const { id } = useParams();
    const elements = useElements();
    const [paymentError, setPaymentError] = useState(null);
    const stripeId = companies.stripeAccountId;
    const jobId = dispatching._id;
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertError, setAlertError] = useState(null);
    const [alertPaymentError, setAlertPaymentError] = useState(null);
    const [amountInput, setAmountInput] = useState('0.00');
    const [cardNameInput, setCardNameInput] = useState(null);
    const [zipInput, setZipInput] = useState(null);
    const [isValidAmount, setIsValidAmount] = useState(true);
    const [isValidAmountOver, setIsValidAmountOver] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const fiftyPercent = (totalPay * 0.5).toFixed(2);
    const [signature, setSignature] = useState('');

    const roundToTwoDecimalPlaces = (value) => {
        return Math.round(value * 100);
    };

    useEffect(() => {
        if (!totalPay) {
            setLoading(true);
        }
        setAmountInput(totalPay)
    }, [setLoading, totalPay]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const filterJobItem = jobItem.find((item) => item._id === selectedOptionalItems[0]);

            const signedDateTime = new Date();

            const updatedSignatureData = [
                ...signatureData,
                { signatureName: cardNameInput.value, signature: signature, dateTime: signedDateTime }
            ];

            setSignatureData(updatedSignatureData);

            const formInputsSign = {
                signature: updatedSignatureData,
            };
            const formInputsStat = {
                status: 'Won'
            };

            if (!stripe || !elements) {
                throw new Error('Stripe.js has not loaded yet.');
            }

            const cardElement = elements.getElement(CardNumberElement);

            if (!cardElement) {
                throw new Error('CardNumberElement not found.');
            }

            const amountInputValue = parseFloat(amountInput);
            const cardNameInputValue = cardNameInput.value;
            const zipInputValue = zipInput.value;
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            if (
                !amountInputValue ||
                !cardNameInputValue ||
                !zipInputValue ||
                !cardNumberElement ||
                !cardExpiryElement ||
                !cardCvcElement
            ) {
                throw new Error('Please fill out all payment fields and ensure card details are valid.');
            }

            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: cardNameInput.value,
                    address: {
                        postal_code: zipInput.value,
                    },
                },
            });

            if (error) {
                setPaymentError(error.message);
                setShowAlertError(true);
                setTimeout(() => {
                    setShowAlertError(false);
                }, 3000);
            } else {
                const response = await axios.post('/api/payment/process-payment', {
                    paymentMethodId: paymentMethod.id,
                    amount: roundToTwoDecimalPlaces(amountInputValue),
                    currency: 'usd',
                    companyId,
                    estimateId,
                    stripeId,
                    jobId,
                    type: 'Credit Card',
                    status: 'Success',
                });

                if (response.data.success) {

                    updateEstimateSign(id, formInputsSign)
                        .then((res) => {
                            if (res.message === "estimate updated") {

                                updateEstimateStatus(id, formInputsStat)
                                    .then((dataRes) => {
                                        if (dataRes.message === "estimate updated") {

                                            if (selectedOptionalItems[0] && filterJobItem) {
                                                const formInputs = {
                                                    name: filterJobItem.name,
                                                    quantity: filterJobItem.quantity,
                                                    price: filterJobItem.price,
                                                    markup: filterJobItem.markup,
                                                    cost: filterJobItem.cost,
                                                    description: filterJobItem.description,
                                                    taxable: filterJobItem.taxable,
                                                    optional: false,
                                                    itemType: filterJobItem.itemType,
                                                    type: filterJobItem.type,
                                                };
                                                updateJobItemsApiRequest(selectedOptionalItems[0], formInputs)
                                                    .then((resData) => {
                                                        if (resData.message === "Job item updated") {
                                                            setShowAlert1(true);
                                                            setTimeout(() => {
                                                                setShowAlert1(false);
                                                            }, 3000);
                                                            setLoading(true);
                                                        }
                                                    })
                                                    .catch((er) => {
                                                        setCreateEstimateResponseState({
                                                            error: er.response.resData.message
                                                                ? er.response.resData.message
                                                                : er.response.resData,
                                                        });
                                                    });
                                            }
                                            setShowAlert1(true);
                                            setTimeout(() => {
                                                setShowAlert1(false);
                                            }, 3000);
                                            setLoading(true);
                                        }
                                    })
                                    .catch((er) => {
                                        console.log(er)
                                        setCreateEstimateResponseState({
                                            error: er.response.dataRes.message
                                                ? er.response.dataRes.message
                                                : er.response.dataRes
                                        });
                                    });

                            }
                        })
                        .catch((er) => {
                            setCreateEstimateResponseState({
                                error: er.response.res.message
                                    ? er.response.res.message
                                    : er.response.res
                            });
                        });
                    
                } else {
                    setAlertError(response.data.error);
                    setShowAlertError(true);
                    setTimeout(() => {
                        setShowAlertError(false);
                    }, 3000);
                }

            }
        } catch (error) {
            setAlertPaymentError(error.message);
            setShowAlertError(true);
            setTimeout(() => {
                setShowAlertError(false);
            }, 3000);
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleCloseAlertError = () => {
        setShowAlertError(false);
    };

    const validateForm = () => {
        const cardNameInputValue = cardNameInput.value;
        const zipInputValue = zipInput.value;
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        if (amountInput && isValidAmount && !isValidAmountOver) {
            const amount = amountInput;

            const isFormValid =
                amount &&
                cardNameInputValue &&
                zipInputValue &&
                cardNumberElement &&
                cardExpiryElement &&
                cardCvcElement;

            setIsFormValid(isFormValid);

        } else {
            const amount = !amountInput;

            const isFormValid =
                amount &&
                cardNameInputValue &&
                zipInputValue &&
                cardNumberElement &&
                cardExpiryElement &&
                cardCvcElement;

            setIsFormValid(isFormValid);
        }

    };

    const handleAmountInputChange = (e) => {
        const inputAmount = parseFloat(e.target.value);
        const formattedAmount = isNaN(inputAmount) ? '0.00' : inputAmount.toFixed(2);
        setAmountInput(formattedAmount);

        if (formattedAmount >= fiftyPercent && formattedAmount <= totalPay) {
            setIsValidAmount(true);
            setIsValidAmountOver(false);

            const cardNameInputValue = cardNameInput.value;
            const zipInputValue = zipInput.value;
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            const isFormValid =
                formattedAmount &&
                cardNameInputValue &&
                zipInputValue &&
                cardNumberElement &&
                cardExpiryElement &&
                cardCvcElement;

            setIsFormValid(isFormValid);

        } else if (formattedAmount >= fiftyPercent && formattedAmount > totalPay) {
            setIsValidAmountOver(true);
            setIsValidAmount(true);
            setIsFormValid(false);
        } else {
            setIsValidAmount(false);
            setIsValidAmountOver(false);
            setIsFormValid(false);
        }
    };

    const handleCardElementChange = () => {
        validateForm();
    };

    return (
        <>
            <div className="mt-3">
                {showAlert && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Payment successful
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlertError && (
                    <div className="alert1 alert-danger" role="alert">
                        {alertError} {alertPaymentError} {paymentError}
                        <div className="alert-line-container">
                            <div className="alert-lineRed"></div>
                            <span className="alert-closeRed" onClick={handleCloseAlertError}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                <div className="row justify-content-center">
                    <div className="col-sm-10">
                        <form className="card-form">
                            <div className="row text-start">
                                <div className="col-sm-4 m-0 me-2 p-0">
                                    <label htmlFor="amount">Amount</label>
                                    <input
                                        placeholder="Amount"
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        defaultValue={amountInput}
                                        className={`card-input ${isValidAmount ? '' : 'invalid-amount'} ${isValidAmountOver && 'invalid-amount'}`}
                                        onChange={(e) => {
                                            handleAmountInputChange(e)
                                        }}
                                    />
                                    {!isValidAmount && (
                                        <div className="ps-3 invalid-amount-warning">Amount should be ${fiftyPercent} or above.</div>
                                    )}
                                    {isValidAmountOver && (
                                        <div className="ps-3 invalid-amount-warning">Amount cannot go over ${totalPay}.</div>
                                    )}
                                </div>
                            </div>
                            <div className="form-row text-start">
                                <label htmlFor="card-name">Name on card</label>
                                <input
                                    placeholder="Name on card"
                                    onChange={(input) => {
                                        handleCardElementChange();
                                        setCardNameInput(input);
                                    }}
                                    ref={(input) => setCardNameInput(input)}
                                    type="text"
                                    id="card-name"
                                    name="card-name"
                                    className="card-input"
                                />
                            </div>
                            <div className="form-row text-start">
                                <label htmlFor="card-number">Card number</label>
                                <CardNumberElement
                                    id="card-number"
                                    onChange={handleCardElementChange}
                                    className="card-input"
                                />
                            </div>
                            <div className="row mb-4 text-start">
                                <div className="col m-0 me-2 p-0">
                                    <label htmlFor="card-expiry">Expiration date</label>
                                    <CardExpiryElement id="card-expiry" onChange={handleCardElementChange} className="card-input" />
                                </div>
                                <div className="col m-0 me-2 p-0">
                                    <label htmlFor="card-cvc">CVV</label>
                                    <CardCvcElement id="card-cvc" onChange={handleCardElementChange} className="card-input" />
                                </div>
                                <div className="col m-0 p-0 me-2">
                                    <label htmlFor="zip">Zip Code</label>
                                    <input placeholder="Zip Code" onChange={handleCardElementChange} ref={(input) => setZipInput(input)} type="text" id="zip" name="zip" className="card-input" />
                                </div>
                            </div>
                        </form>
                        <ClientEstimateSignaturesComponent onConfirm={setSignature}/>
                        <div className="row justify-content-center mt-3">
                            <div className="col-sm-4 text-end m-0 p-0">
                                <button onClick={handleSubmit} disabled={!isFormValid} className="btn btn-outline-success loginButton">
                                    Submit
                                </button>
                            </div>
                            <div className="col-sm-4 text-start ms-3 m-0 p-0">
                                <button onClick={handleSubmitDecline} className="btn btn-outline-danger loginButton">
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ >
    );
};

export default ClientEstimatePaymentForm;