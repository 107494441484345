import axios from "axios";
import AdminCodesComponent from "./components/adminCodesComponent";

const fetchCodes = async (abctrl) => {
    const { data } = await axios.get("/api/codes/admin", { withCredentials: true });
    return data
}

const deleteCodes = async (codesId) => {
    const { data } = await axios.delete(`/api/codes/${codesId}`, { withCredentials: true });
    return data
}

const AdminCodesPage = () => {

    return <AdminCodesComponent fetchCodes={fetchCodes} deleteCodes={deleteCodes} />;
};

export default AdminCodesPage;
