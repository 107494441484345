import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

const AddJobItemPopupComponent = ({ selectedJobItem, setSelectedJobItem, fetchJobItem, onCancel, onConfirm }) => {
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const inputRef = useRef(null);
    const [selectedName, setSelectedName] = useState("");
    const [name, setName] = useState(""); // Track name of selected job item
    const [price, setPrice] = useState(""); // Track price of selected job item
    const [jobItems, setJobItem] = useState([]);
    const [quantity, setQuantity] = useState(""); // Track quantity
    const [cost, setCost] = useState("");
    const [markup, setMarkup] = useState("");
    const [description, setDescription] = useState("");
    const [taxable, setTaxable] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [reuseable, setReuseable] = useState(false);
    const [itemType, setItemType] = useState("");
    const [filteredJobItems, setFilteredJobItems] = useState([]);

    const auth = useSelector(state => state.auth)

    useEffect(() => {
        if (selectedJobItem) {
            setTaxable(selectedJobItem.taxable);
            setName(selectedJobItem.name);
            setPrice(selectedJobItem.price);
            setMarkup(selectedJobItem.markup);
            setCost(selectedJobItem.cost || 0);
            setItemType(selectedJobItem.itemType || 'Product');
        }
    }, [selectedJobItem]);

    useEffect(() => {
        const abctrl = new AbortController();

        fetchJobItem(abctrl)
            .then((res) => {
                setJobItem(res);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [fetchJobItem]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowAutocomplete(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const handleInputChange = (e) => {
        const inputValue = e.target.value.toLowerCase(); // Convert input to lowercase
        setSelectedName(inputValue);
        setShowAutocomplete(true);
        setSelectedJobItem(null); // Clear selected job item when input changes

        // Modify the filter condition to match items with the exact input word in their name
        const filteredItems = jobItems.filter((jobItem) => {
            const nameLower = jobItem.name.toLowerCase();
            return (
                (!inputValue || nameLower.includes(inputValue)) && // Check if the name contains the input value (or show all if no input)
                jobItem.company === auth.user.company &&
                (!jobItem.estimateId && !jobItem.jobId || !jobItem.jobId)
            );
        });

        setFilteredJobItems(filteredItems); // Store the filtered items in state
    };

    const handleOptionClick = (jobItem) => {
        setSelectedJobItem(jobItem);
        setShowAutocomplete(false);
        setName(jobItem.name);
        setPrice(jobItem.price || 0);
        setCost(jobItem.cost || 0);
        setMarkup(jobItem.markup || 0);
        setQuantity(jobItem.quantity || 1);
        setDescription(jobItem.description || "");
        setTaxable(jobItem.taxable || false);
        if (jobItem.reuseable) {
            setItemType(jobItem.itemType || 'Product');
            setReuseable(true);
        } else {
            setReuseable(false);
        }
    };

    const typedInputListItem = (
        <li
            key="typed-input"
            className="selected"
            onClick={() => handleOptionClick({ reuseable: true, itemType: '', name: selectedName, price: "", cost: "", markup: "", quantity: "", description: "", taxable: false })}
        >
            {`Create new reuseable item '${selectedName}'`}
        </li>
    );

    const handleTaxableChange = () => {
        setTaxable(!taxable);
    };

    const handleConfirm = () => {
        if (selectedJobItem) {
            let newItem = { ...selectedJobItem, name, price, cost, markup, quantity, description, taxable };

            if (reuseable) {
                newItem = { ...newItem, itemType };
            }

            setSelectedItems([...selectedItems, newItem]);
            onConfirm([...selectedItems, newItem]);
            onCancel();
        }
    };

    const calculatePrice = (cost, markup) => {
        const costFloat = parseFloat(cost);
        const markupFloat = parseFloat(markup);

        if (!isNaN(costFloat) && !isNaN(markupFloat)) {
            // Calculate the price by adding the markup to the cost
            const price = costFloat + (costFloat * (markupFloat / 100));
            return price.toFixed(2); // Round to 2 decimal places
        }

        return ''; // Return an empty string if cost or markup is not a valid number
    };

    const handleCostChange = (e) => {
        const newCost = e.target.value;
        setCost(newCost);
    };

    const handleMarkupChange = (e) => {
        const newMarkup = e.target.value;
        setMarkup(newMarkup);

        // Calculate the new price based on the updated cost and markup
        const newPrice = calculatePrice(cost, newMarkup);
        setPrice(newPrice);
    };

    const shouldRenderTypedInputListItem = filteredJobItems.length === 0 && selectedName !== "";

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel} // Close modal if clicked outside
            />
            <div className="confirmation-box mt-4">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder bg-light deleteWidth mb-4">
                        <div className="col-sm fs-5">
                            Add Job Items
                        </div>
                    </div>
                    {reuseable ? (
                        <div className="row p-2" ref={inputRef}>
                            <div className="row p-0 m-0 mb-3">
                                <span className="form-floating fLabel p-0 m-0">
                                    <input
                                        type="text"
                                        className="form-control sajInput"
                                        id="jobItems"
                                        autoComplete="off"
                                        name="jobItems"
                                        placeholder="Item Name"
                                        value={name || selectedJobItem.name}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="jobItems" className="inputLabel">Item Name</label>
                                </span>
                            </div>
                            {showAutocomplete && (
                                <div className="col-md-3">
                                    <ul className="autocomplete-list1" style={{ width: inputRef.current.offsetWidth }}>
                                        {shouldRenderTypedInputListItem && typedInputListItem}
                                        {filteredJobItems.map((jobItem) => (
                                            <li
                                                key={jobItem.id}
                                                onClick={() => handleOptionClick(jobItem)}
                                                tabIndex={0}
                                                role="option"
                                                aria-selected={jobItem._id === (selectedJobItem?.id)}
                                                className={jobItem._id === (selectedJobItem?.id) ? "selected" : ""}
                                            >
                                                {`${jobItem.name} (${jobItem.price})`}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className="col-md m-0 p-0">
                                <span className="form-floating fLabel">
                                    <input
                                        type="number"
                                        className="form-control sajInput"
                                        placeholder="Quantity"
                                        id="quantity"
                                        value={quantity}
                                        onChange={(e) => setQuantity(e.target.value)}
                                    />
                                    <label htmlFor="quantity" className="inputLabel">Quantity</label>
                                </span>
                            </div>
                            <div className="col-md m-0 p-0">
                                <span className="form-floating fLabel">
                                    <input
                                        type="number"
                                        className="form-control sajInput"
                                        placeholder="Price"
                                        id="price"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                    <label htmlFor="price" className="inputLabel">Price</label>
                                </span>
                            </div>
                            <div className="col-md m-0 p-0 position-relative">
                                <span className="form-floating fLabel">
                                    <input
                                        type="text"
                                        className="form-control sajInput"
                                        placeholder="Markup"
                                        id="markup"
                                        value={markup}
                                        onChange={handleMarkupChange}
                                    />
                                    <label htmlFor="markup" className="inputLabel">Markup</label>
                                </span>
                                <span className="percentage-sign">%</span>
                            </div>
                            <div className="col-md m-0 p-0">
                                <span className="form-floating fLabel">
                                    <input
                                        type="number"
                                        className="form-control sajInput"
                                        placeholder="Cost"
                                        id="cost"
                                        value={cost}
                                        onChange={handleCostChange}
                                    />
                                    <label htmlFor="cost" className="inputLabel">Cost</label>
                                </span>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="form-floating m-0 p-0">
                                    <select
                                        className="form-select"
                                        onChange={(e) => setItemType(e.target.value)}
                                        value={itemType} // Make sure itemType is correctly set as the value
                                        id="floatingSelect"
                                        aria-label="Floating label select example"
                                    >
                                        <option value="Product">Product</option>
                                        <option value="Service">Service</option>
                                        <option value="Hours">Hours</option>
                                        <option value="Expense">Expense</option>
                                    </select>
                                    <label htmlFor="floatingSelect">Item Type:</label>
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="form-floating fLabel m-0 p-0">
                                    <textarea
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="form-control"
                                        id="floatingTextarea2"
                                        style={{ height: '100px' }}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="d-flex align-items-center">
                                    <label className="form-check-label me-2" htmlFor="flexSwitchCheckDefault">Taxable Item</label>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                            onChange={handleTaxableChange}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    ) : selectedJobItem ? (
                        <div className="row p-2" ref={inputRef}>
                            <div className="row p-0 m-0 mb-3">
                                <span className="form-floating fLabel p-0 m-0">
                                    <input
                                        type="text"
                                        className="form-control sajInput"
                                        id="jobItems"
                                            name="jobItems"
                                            autoComplete="off"
                                        placeholder="Item Name"
                                        value={name || selectedJobItem.name}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="jobItems" className="inputLabel">Item Name</label>
                                </span>
                            </div>
                            {showAutocomplete && (
                                <div className="col-md-3">
                                    <ul className="autocomplete-list1" style={{ width: inputRef.current.offsetWidth }}>
                                        {shouldRenderTypedInputListItem && typedInputListItem}
                                        {filteredJobItems.map((jobItem) => (
                                            <li
                                                key={jobItem.id}
                                                onClick={() => handleOptionClick(jobItem)}
                                                tabIndex={0}
                                                role="option"
                                                aria-selected={jobItem._id === (selectedJobItem?.id)}
                                                className={jobItem._id === (selectedJobItem?.id) ? "selected" : ""}
                                            >
                                                {`${jobItem.name} (${jobItem.price})`}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className="col-md m-0 p-0">
                                <span className="form-floating fLabel">
                                    <input
                                        type="number"
                                        className="form-control sajInput"
                                        placeholder="Quantity"
                                        id="quantity"
                                        value={quantity || selectedJobItem.quantity}
                                        onChange={(e) => setQuantity(e.target.value)}
                                    />
                                    <label htmlFor="quantity" className="inputLabel">Quantity</label>
                                </span>
                            </div>
                            <div className="col-md m-0 p-0">
                                <span className="form-floating fLabel">
                                    <input
                                        type="number"
                                        className="form-control sajInput"
                                        placeholder="Price"
                                        id="price"
                                        value={price || selectedJobItem.price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                    <label htmlFor="price" className="inputLabel">Price</label>
                                </span>
                            </div>
                            <div className="col-md m-0 p-0 position-relative">
                                <span className="form-floating fLabel">
                                    <input
                                        type="text"
                                        className="form-control sajInput"
                                        placeholder="Markup"
                                        id="markup"
                                        value={markup}
                                        onChange={handleMarkupChange}
                                    />
                                    <label htmlFor="markup" className="inputLabel">Markup</label>
                                </span>
                                <span className="percentage-sign">%</span>
                            </div>
                            <div className="col-md m-0 p-0">
                                <span className="form-floating fLabel">
                                    <input
                                        type="number"
                                        className="form-control sajInput"
                                        placeholder="Cost"
                                        id="cost"
                                        value={cost || selectedJobItem.cost}
                                        onChange={handleCostChange}
                                    />
                                    <label htmlFor="cost" className="inputLabel">Cost</label>
                                </span>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="form-floating fLabel m-0 p-0">
                                    <textarea
                                        value={description || selectedJobItem.description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="form-control"
                                        id="floatingTextarea2"
                                        style={{ height: '100px' }}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="d-flex align-items-center">
                                    <label className="form-check-label me-2" htmlFor="flexSwitchCheckDefault">Taxable Item</label>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                            checked={taxable}
                                            onChange={handleTaxableChange}
                                        />
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    ) :(
                        <div className="row" ref={inputRef}>
                            <span className="form-floating fLabel">
                                <input
                                    type="text"
                                    className="form-control sajInput"
                                    id="jobItems"
                                    name="jobItems"
                                    placeholder="Item Name"
                                            value={selectedName}
                                            autoComplete="off"
                                    onChange={handleInputChange}
                                />
                                <label for="jobItems" className="inputLabel ps-3">Item Name</label>
                            </span>
                            {showAutocomplete && (
                                <ul className="autocomplete-list1" style={{ width: inputRef.current.offsetWidth }}>
                                    {shouldRenderTypedInputListItem && typedInputListItem}
                                    {filteredJobItems.map((jobItem) => (
                                        <li
                                            key={jobItem.id}
                                            onClick={() => handleOptionClick(jobItem)}
                                            tabIndex={0}
                                            role="option"
                                            aria-selected={jobItem._id === (selectedJobItem?.id)}
                                            className={jobItem._id === (selectedJobItem?.id) ? "selected" : ""}
                                        >
                                            {`${jobItem.name} (${jobItem.price})`}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                    <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                        <div className="col-sm">
                            <button className="btn btn-outline-light text-dark border-dark ps-5 pe-5" onClick={onCancel}>
                                Close
                            </button>
                        </div>
                        <div className="col-sm">
                            <button onClick={handleConfirm} className="btn btn-outline-primary m-0 ps-5 pe-5" disabled={!selectedJobItem}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddJobItemPopupComponent;
