import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";
import Select from "react-select";

const years = [];
for (let year = 1970; year <= 2023; year++) {
    years.push(year);
}

const AdminEditVinPriceComponent = ({
    fetchVinCode,
    updateVinPriceApiRequest
}) => {
    const [validated, setValidated] = useState(false);
    const auth = useSelector(state => state.auth)
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedYear, setSelectedYear] = useState([]);
    const [unSelectedYear, setUnSelectedYear] = useState([]);
    const [selectedExtra, setSelectedExtra] = useState('');
    const [selectedSource, setSelectedSource] = useState('');
    const [vinPrice, setVinPrice] = useState([]);
    const { id } = useParams();
    const [createCodesResponseState, setCreateCodesResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    useEffect(() => {
        fetchVinCode(id)
            .then((data) => {
                const matchingYears = data.years;
                const unmatchedYears = years.filter((year) =>
                    !matchingYears.includes(year)
                );

                if (!unmatchedYears.includes(data.years)) {
                    unmatchedYears.push(data.years);
                }

                setUnSelectedYear(unmatchedYears);
                setSelectedYear(data.years);
                setSelectedExtra(data.extraDocs);
                setSelectedValue(data.dontSupport);
                setSelectedSource(data.sourceSend);
                setVinPrice(data);
            })
            .catch((er) => console.log(er));
    }, [id, fetchVinCode]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;

        const formInputs = {
            make: form.make.value,
            price: form.price.value,
            dontSupport: selectedValue,
            years: selectedYear,
            dayAvail: form.dayAvail.value,
            dayTime: form.dayTime.value,
            time: form.time.value,
            extraDocs: selectedExtra,
            sourceSend: selectedSource,
        };
        if (event.currentTarget.checkValidity() === true) {
            updateVinPriceApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "price updated") navigate(`${paths.ADMINVINPRICE}`);
                })
                .catch((er) => {
                    setCreateCodesResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    const handleSelectExtraChange = (event) => {
        const newValue = event.target.value;
        setSelectedExtra(newValue);
    };

    const handleSelectSource = (event) => {
        const newValue = event.target.value;
        setSelectedSource(newValue);
    };

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-7">
                        <h1 className="text-center">Edit Vin Pricing</h1>
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="row pt-4 g-3">
                            <div className="row d-flex justify-content-between mt-3 m-0 p-0">
                                <div className="col-sm-5 p-0 m-0">
                                    <label htmlFor="make">Make:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="make"
                                        name="make"
                                        defaultValue={vinPrice.make}
                                    />
                                </div>
                                <div className="col-sm-5 m-0 p-0">
                                    <label htmlFor="codeSource">Run Source:</label>
                                    <select
                                        id="codeSource"
                                        className="form-select mt-0"
                                        value={selectedSource}
                                        onChange={handleSelectSource}
                                    >
                                        <option value="">Select An Source</option>
                                        <option value="Magic">Magic</option>
                                        <option value="Us">Us</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col p-0 m-0">
                                    <label htmlFor="year">Year:</label>
                                    <Select
                                        isMulti
                                        options={unSelectedYear.map(year => ({ value: year, label: year }))}
                                        value={selectedYear.map(year => ({ value: year, label: year }))}
                                        onChange={selectedOptions => setSelectedYear(selectedOptions.map(option => option.value))}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col ms-0 ps-0 mt-2">
                                    <label htmlFor="time">Time To Complete:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="time"
                                        name="time"
                                        defaultValue={vinPrice.time}
                                    />
                                </div>
                                <div className="col ms-0 ps-0 mt-2">
                                    <label htmlFor="dayAvail">Available Days:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="dayAvail"
                                        name="dayAvail"
                                        defaultValue={vinPrice.dayAvail}
                                    />
                                </div>
                                <div className="col ms-0 ps-0 mt-2">
                                    <label htmlFor="dayTime">Availability Time:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="dayTime"
                                        name="dayTime"
                                        defaultValue={vinPrice.dayTime}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col ms-0 ps-0 mt-2">
                                    <label htmlFor="price">Price:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="price"
                                        name="price"
                                        defaultValue={vinPrice.price ? vinPrice.price.toFixed(2) : ''}
                                    />
                                </div>
                                <div className="col ms-0 ps-0">
                                    <label className="form-label" htmlFor="trueFalseSelect">Needs Documents:</label>
                                    <select
                                        id="trueFalseSelect"
                                        className="form-select"
                                        value={selectedExtra.toString()}
                                        onChange={handleSelectExtraChange}
                                    >
                                        <option value="">Select An Option</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>
                                </div>
                                <div className="col ms-0 ps-0">
                                    <label className="form-label" htmlFor="trueFalseSelect">Support Running Codes:</label>
                                    <select
                                        id="trueFalseSelect"
                                        className="form-select"
                                        value={selectedValue.toString()}
                                        onChange={handleSelectChange}
                                    >
                                        <option value="">Select An Option</option>
                                        <option value="true">Support</option>
                                        <option value="false">Don't Support</option>
                                    </select>
                                </div>
                            </div>
                            <div className="text-center pt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINVINPRICE} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createCodesResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminEditVinPriceComponent;