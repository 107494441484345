import React, { useState } from 'react';
import axios from 'axios';

const WhatsappFormComponent = () => {
    const [message, setMessage] = useState('');
   
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const response = await axios.post('/api/sms/send-sms-message' );
            setMessage(response.data.message);
        } catch (error) {
            console.error(error);
            setMessage('Error sending message');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="pt-5 mt-5">
            <button type="submit">Send Message</button>
            {message && <p>{message}</p>}
        </form>
    );
};

export default WhatsappFormComponent;
