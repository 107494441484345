import axios from "axios";
import VinKeyCodeInfoComponent from "./components/vinKeyCodeInfoComponent";

const VinKeyCodeInfoPage = () => {

    const fetchRequest = async (id) => {
        const { data } = await axios.get(`/api/vinRequest/keyInfo?_id=${id}`, { withCredentials: true });
        return data;
    }

    return <VinKeyCodeInfoComponent
        fetchRequest={fetchRequest}
    />;
};

export default VinKeyCodeInfoPage;
