import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";

const CreateCodesPageComponent = ({
    createCodesApiRequest,
    fetchVin
}) => {
    const [validated, setValidated] = useState(false);
    const [cuts, setCuts] = useState(['']);
    const [vin, setVin] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [vinLength, setVinLength] = useState(true);
    const [vinError, setVinError] = useState('');
    const [createCodesResponseState, setCreateCodesResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            vin: vin,
            make: form.make.value,
            model: form.model.value,
            year: form.year.value,
            keyCode: form.keyCode.value,
            pinCode: form.pinCode.value,
            cuts: cuts,
            hpcCard: form.hpcCard.value,
            testBlade: form.testBlade.value,
            transponderKey: form.transponderKey.value,
            transponder: selectedValue,
            chipType: form.chipType.value,
            codeSeries: form.codeSeries.value,
            doorCode: form.doorCode.value,
            system: form.system.value,
        };
        if (event.currentTarget.checkValidity() === true) {
            createCodesApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Code created") navigate(`${paths.ADMINCODES}`);
                })
                .catch((er) => {
                    setCreateCodesResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const handleVinChange = (e) => {
        const newVin = e.target.value;

        setVin(newVin);
    };

    const handleCutsChange = (event, index) => {
        const updatedCuts = [...cuts];
        updatedCuts[index] = event.target.value;
        setCuts(updatedCuts);
    };

    const handleDeleteCut = (index) => {
        const updatedCuts = [...cuts];
        updatedCuts.splice(index, 1);
        setCuts(updatedCuts);
    };

    const handleAddCut = () => {
        setCuts([...cuts, ""]);
    };

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    const handleBlur = () => {
        const hasInvalidChars = /[OoiIqQ]/.test(vin);

        if (vin.length === 17) {
            if (hasInvalidChars) {
                setVinError('VIN cannot contain characters: O, I, or Q.');
                setVinLength(true);
            } else {

                fetchVin(vin)
                    .then((data) => {
                        const newData = data.Results;
                        const findMake = newData.find((item) => item.Variable === "Make");

                        if (findMake.Value === null) {
                            setVinError('The VIN does not return any vehicle manufacture.');
                            setVinLength(true);
                        } else {
                            setVinLength(false);
                        }
                    })
                    .catch((er) =>
                        console.log(er)
                    );
            }
        } else {
            setVinError('VIN must be exactly 17 characters long.');
            setVinLength(true);
        }
    };

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-center">New Code</h1>
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="row pt-4 g-3">
                            <div>
                                <label htmlFor="vin" className="form-label">
                                    VIN:
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${vinLength && vin ? 'is-invalid' : ''}`}
                                    id="vin"
                                    name="vin"
                                    onChange={(e) => handleVinChange(e)}
                                    onBlur={handleBlur}
                                />
                                {(vinLength && vin) && (
                                    <p className="text-danger text-center mt-2 ms-4 mb-3">
                                        {vinError}
                                    </p>
                                )}
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col">
                                    <label htmlFor="make">Make:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="make"
                                        name="make"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="model">Model:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="model"
                                        name="model"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="year">Year:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="year"
                                        name="year"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col">
                                    <label htmlFor="keyCode">Key Code:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="keyCode"
                                        name="keyCode"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="pinCode">Pin Code:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="pinCode"
                                        name="pinCode" />
                                </div>
                                <div className="col">
                                    <label htmlFor="doorCode">Door Code:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="doorCode"
                                        name="doorCode"
                                    />
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="row m-0 p-0 d-flex justify-content-between mb-3">
                                    <div className="col align-middle text-start mt-2">
                                        <label>Cuts:</label>
                                    </div>
                                    <div className="col align-middle text-end">
                                        <button type="button" onClick={handleAddCut} className="btn btn-sm btn-outline-primary">Add Cuts</button>
                                    </div>
                                </div>
                                {cuts.map((cut, index) => (
                                    <>
                                        <div className="input-group mb-3">
                                            <input
                                                value={cut}
                                                onChange={(e) => handleCutsChange(e, index)}
                                                type="text"
                                                className="form-control"
                                                placeholder="Cuts Needed"
                                                aria-label="Cuts Needed"
                                                aria-describedby="button-addon2" />
                                            <button
                                                className="btn btn-outline-danger"
                                                type="button"
                                                id="button-addon2"
                                                onClick={() => handleDeleteCut(index)}
                                            >
                                                x
                                            </button>
                                        </div>
                                    </>
                                ))}
                            </div>
                            <div>
                                <label className="form-label" htmlFor="trueFalseSelect">Transponder:</label>
                                <select
                                    id="trueFalseSelect"
                                    className="form-select"
                                    value={selectedValue.toString()}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Select An Option</option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                    <option value="optional">Optional</option>
                                </select>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col">
                                    <label htmlFor="hpcCard">HPC Card:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="hpcCard"
                                        name="hpcCard"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="testBlade">Test Blade:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="testBlade"
                                        name="testBlade" />
                                </div>
                                <div className="col">
                                    <label htmlFor="transponderKey">Transponder Key:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="transponderKey"
                                        name="transponderKey" />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="system">System:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="system"
                                    name="system"
                                />
                            </div>
                            <div>
                                <label htmlFor="chipType">Chip Type:</label>
                                <input type="text"
                                    className="form-control"
                                    id="chipType"
                                    name="chipType"
                                />
                            </div>
                            <div>
                                <label htmlFor="codeSeries">Code Series:</label>
                                <input type="text"
                                    className="form-control"
                                    id="codeSeries"
                                    name="codeSeries"
                                />
                            </div>
                            <div className="text-center pt-4">
                                <button type="submit" disabled={vinLength && vin || vin === ''} className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINCODES} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createCodesResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCodesPageComponent;