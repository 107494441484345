import axios from "axios";
import { useDispatch } from "react-redux";
import CreateCodesPageComponent from "./components/adminNewCodesComponent";

const createCodesApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/codes/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const fetchVin = async (vin) => {
    const { data } = await axios.post(`/api/vin/decode?vin=${vin}`, { withCredentials: true });
    return data;
}

const AdminCreateCodesPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateCodesPageComponent
            fetchVin={fetchVin}
            createCodesApiRequest={createCodesApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreateCodesPage;