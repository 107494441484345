import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";
import { BsTrash } from 'react-icons/bs';
import Select from "react-select";
import axios from 'axios';
import '../../../components/assets/inventory.css';
import '../../../components/assets/LineSpinnerInventory.css';


const InventoryAddComponent = ({
    createInventoryApiRequest,
    fetchMake,
    fetchModel,
    fetchYear,
    fetchLocation,
    fetchTechInfo,
    fetchUsers
}) => {
    const [barcode, setBarcode] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [modelYearPairs, setModelYearPairs] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setloading] = useState(false);
    const [vehicleInven, setVehicleInven] = useState('true');
    const [isOEM, setIsOEM] = useState('false');
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [selectedMake, setSelectedMake] = useState('');
    const [years, setYears] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [locations, setLocations] = useState([{ dontStock: '', _id: '', name: '', quantity: '', minimunStock: '', location: '', ourCost: 0.00, }]);
    const [sellingPrice, setSellingPrice] = useState(0.00);
    const [instructionType, setInstructionType] = useState('Locksmith');
    const [instructionCategory, setInstructionCategory] = useState('');
    const [allKeysLostPrice, setAllKeysLostPrice] = useState(0.00);

    const auth = useSelector(state => state.auth)
    const [createInventoryResponseState, setCreateInventoryResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();
    const [dots, setDots] = useState([false, false, false]);

    useEffect(() => {
        const abctrl = new AbortController();
        fetchMake(abctrl)
            .then((res) => setMake(res))
            .catch((er) =>
                setMake([
                    { name: er.response.data.message ? er.response.data.message : er.response.data }
                ]),
            );
        return () => abctrl.abort();
    }, [fetchMake]);

    useEffect(() => {
        if (selectedMake) {
            const abctrl = new AbortController();
            fetchModel(selectedMake)
                .then((data) => {
                    setModel(data);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            return () => abctrl.abort();
        }
    }, [selectedMake, fetchModel]);

    useEffect(() => {
        if (selectedMake && selectedModel) {
            fetchYear(selectedModel)
                .then((data) => {
                    const matchingMake = data.filter((item) => item.make === selectedMake);
                    setYears(matchingMake[0].year);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
    }, [selectedModel, fetchYear, selectedMake]);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => {
                const firstFalseIndex = prevDots.findIndex(dot => !dot);
                if (firstFalseIndex !== -1) {
                    const newDots = [...prevDots];
                    newDots[firstFalseIndex] = true;
                    return newDots;
                }
                return [false, false, false];
            });
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const locationData = await fetchLocation();
                const techData = await fetchTechInfo();
                const userData = await fetchUsers();


                setLocations(

                    locationData.map((inventory) => {

                        if (inventory) {
                            if (inventory.van) {
                                const filteredTech = techData.find((techItem) => techItem._id === inventory.tech);

                                if (filteredTech) {
                                    const UserFilter = userData.find((item) => filteredTech.user === item._id)

                                    const newName = UserFilter ? `${UserFilter.name} Van` : 'Mobile';

                                    return {
                                        _id: inventory._id,
                                        name: newName,
                                        quantity: 0,
                                        minimunStock: 0,
                                        location: '',
                                        ourCost: 0,
                                        dontStock: true,
                                    };
                                }
                            } else {

                                return {
                                    _id: inventory._id,
                                    name: inventory.name,
                                    quantity: 0,
                                    minimunStock: 0,
                                    location: '',
                                    ourCost: 0,
                                    dontStock: true,
                                };
                            }
                        }
                        return null;
                    })
                )

            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [fetchLocation, fetchTechInfo, fetchUsers]);

    const randomBar = () => {
        const max = 9000000000000;
        const rand = Math.floor(Math.random() * max) + 1;
        setBarcode(rand.toString());
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setloading(true);

        let formInputs;
        const form = event.currentTarget.elements;

        if (selectedImage) {
            try {
                const companyId = auth.user.company;
                const imageFormData = new FormData();
                imageFormData.append("file", selectedImage);
                imageFormData.append("company", companyId);

                const response = await axios.post("/api/uploads/add", imageFormData);
                const imageId = response.data.file;

                formInputs = {
                    name: form.name.value,
                    image: imageId, // Include the image ID here
                    model: modelYearPairs.map(pair => ({
                        make: pair.make,
                        model: pair.model,
                        years: pair.years.map((year) => year.toString()),
                    })),
                    oem: isOEM,
                    price: sellingPrice,
                    barcode: barcode,
                    sku: form.sku.value,
                    fccId: form.fccId.value,
                    chip: form.chip.value,
                    frequency: form.frequency.value,
                    battery: form.battery.value,
                    replacementPin: form.replacementPin.value,
                    company: auth.user.company,
                    InventoryLocation: locations.map(({ dontStock, _id, name, quantity, minimunStock, location, ourCost }) => ({ dontStock, _id, name, quantity: parseInt(quantity, 10) || 0, minimunStock, location, ourCost })),
                    vehicleInven: vehicleInven,
                    catagory: instructionCategory,
                    catagoryType: instructionType,
                    allKeysLostPrice: allKeysLostPrice,
                };
            } catch (error) {
                console.error("Error uploading image:", error);
                setloading(false);
                return;
            }
        } else {

            formInputs = {
                name: form.name.value,
                model: modelYearPairs.map(pair => ({
                    make: pair.make,
                    model: pair.model,
                    years: pair.years.map((year) => year.toString()),
                })),
                oem: isOEM,
                price: sellingPrice,
                barcode: barcode,
                sku: form.sku.value,
                fccId: form.fccId.value,
                chip: form.chip.value,
                frequency: form.frequency.value,
                battery: form.battery.value,
                replacementPin: form.replacementPin.value,
                company: auth.user.company,
                InventoryLocation: locations.map(({ dontStock, _id, name, quantity, minimunStock, location, ourCost }) => ({ dontStock, _id, name, quantity: parseInt(quantity, 10) || 0, minimunStock, location, ourCost })),
                vehicleInven: vehicleInven,
                catagory: instructionCategory,
                catagoryType: instructionType,
                allKeysLostPrice: allKeysLostPrice,
            };
        }

        createInventoryApiRequest(formInputs)
            .then((data) => {
                if (data.message === "inventory created") navigate(`${paths.INVENTORYMANAGERITEMS}`);
            })
            .catch((er) => {
                setCreateInventoryResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data,
                });
                setloading(false);
            });
    };

    const handleItemClick1 = (index) => {
        if (editingIndex !== index) {
            setEditingIndex(index);
            const selectedMake = modelYearPairs[index].make;
            if (selectedMake) {
                fetchModel(selectedMake)
                    .then((data) => {
                        setModel(data);
                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message
                                ? er.response.data.message
                                : er.response.data
                        )
                    );
            }
        }
    };

    const handleDeleteItem1 = (index) => {
        const updatedPairs = [...modelYearPairs];
        updatedPairs.splice(index, 1);
        setModelYearPairs(updatedPairs);
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    }

    const handleMakeInputChange = (selectedOption, index) => {
        const updatedPairs = [...modelYearPairs];
        updatedPairs[index].make = selectedOption.value;
        setSelectedMake(selectedOption.value);
        setModel([]);
        setModelYearPairs(updatedPairs);
    };

    const handleModelInputChange = (selectedOption, index) => {
        const updatedPairs = [...modelYearPairs];
        updatedPairs[index].model = selectedOption.value;
        setSelectedModel(selectedOption.value);
        setYears([]);
        setModelYearPairs(updatedPairs);
    };

    const handleYearInputChange = (selectedOptions, index) => {
        const updatedPairs = [...modelYearPairs];
        const UpdatedPrice = selectedOptions.map((year) => year.value); // Convert to string using toString()
        const updateOptions = UpdatedPrice;
        updatedPairs[index].years = updateOptions;
        setModelYearPairs(updatedPairs);
    };

    const handleLocationChange = (index, field, value) => {
        const updatedLocations = [...locations];
        updatedLocations[index][field] = value;
        setLocations(updatedLocations);
    };

    const handleSellingPriceChange = (event) => {
        setSellingPrice(event.target.value);
    };

    const handleSellingPriceBlur = () => {
        const inputValue = parseFloat(sellingPrice);
        if (!isNaN(inputValue)) {
            setSellingPrice(inputValue.toFixed(2));
        }
    };

    const handleAllKeysLostPriceChange = (event) => {
        setAllKeysLostPrice(event.target.value);
    };

    const handleAllKeysLostPriceBlur = () => {
        const inputValue = parseFloat(allKeysLostPrice);
        if (!isNaN(inputValue)) {
            setAllKeysLostPrice(inputValue.toFixed(2));
        }
    };

    const handleOurCostBlur = (index) => {
        const updatedLocations = [...locations];
        const inputValue = parseFloat(updatedLocations[index].ourCost);
        if (!isNaN(inputValue)) {
            updatedLocations[index].ourCost = inputValue.toFixed(2);
            setLocations(updatedLocations);
        }
    };
    
    const handleFrequencyBlur = (event) => {
        let inputValue = event.target.value.trim();

        if (inputValue.match(/mhz$/i) && inputValue !== '') {
            inputValue = inputValue.replace(/mhz$/i, 'MHz');
        } else if (!inputValue.endsWith('MHz') && inputValue !== '') {
            inputValue += ' MHz';
        }

        event.target.value = inputValue;
    };

    const handleInstructionTypeChange = (event) => {
        const selectedType = event.target.value;
        setInstructionType(selectedType);
        setInstructionCategory("");
    };

    const handleInstructionCategoryChange = (event) => {
        setInstructionCategory(event.target.value);
    };

    const instructionTypeCategories = {
        Locksmith: [
            "Commercial Rekey",
            "Commercial Hardware",
            "Commercial Other",
            "Residential Rekey",
            "Residential Hardware",
            "Residential Other",
            "Automotive Lockouts",
            "Commercial Lockouts",
            "Residential Lockouts",
            "Other Lockouts",
            "Safe Open/Repair",
            "Access Control",
            "Surveillance",
            "Other",
            "Tools",
        ],
        AirDuct: [
            "CategoryX",
            "CategoryY",
            "Tools",
        ],
        Chimney: [
            "CategoryX",
            "CategoryY",
            "Tools",
        ],
        Garage: [
            "CategoryX",
            "CategoryY",
            "Tools",
        ],
    };

    return (
        <div className="pt-5">
            <div className="container mb-5 pb-5">
                {loading && (
                    <div className="loading-overlay">
                        <div className="row-spinner">
                            {dots.map((dot, index) => (
                                <div key={index} className={`dot ${dot ? 'visible' : ''}`} style={{ animationDelay: `${index * 0.3}s` }}></div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-8">
                        <h1 className="text-center">New Inventory</h1>
                        <form onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} className="row g-3">
                            <div className="row mt-5 m-0 p-0">
                                <div className="col-sm-12">
                                    <label htmlFor="name">Name:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col-sm-6">
                                    <label htmlFor="instructionType">Inventory Item Type:</label>
                                    <select
                                        className="form-control"
                                        id="instructionType"
                                        name="instructionType"
                                        value={instructionType}
                                        onChange={handleInstructionTypeChange}
                                    >
                                        <option value="Locksmith">Locksmith</option>
                                        <option value="AirDuct">Air Duct</option>
                                        <option value="Chimney">Chimney</option>
                                        <option value="Garage">Garage Door/Gates</option>
                                    </select>
                                </div>
                                {instructionType === 'Locksmith' && (
                                    <div className="col-sm-6">
                                        <label htmlFor="vehicleInven">Is Item For Vehicle:</label>
                                        <select
                                            id="vehicleInven"
                                            className="form-select"
                                            aria-label="Vehicle Inventory:"
                                            value={vehicleInven}
                                            onChange={(e) => setVehicleInven(e.target.value)}
                                        >
                                            <option value='true'>True</option>
                                            <option value='false'>False</option>
                                        </select>
                                    </div>
                                )}
                            </div>
                            <div className="row d-flex justify-content-start w-100 m-0 p-0 mt-4">
                                <div className="col-sm-4">
                                    <label htmlFor="isOEM">Is the product OEM:</label>
                                    <select
                                        id="isOEM"
                                        className="form-select"
                                        aria-label="OEM:"
                                        value={isOEM}
                                        onChange={(e) => setIsOEM(e.target.value)}
                                    >
                                        <option value='true'>True</option>
                                        <option value='false'>False</option>
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="price">{instructionType === 'Locksmith' && vehicleInven === 'true' ? 'Spare Key Price' : 'Selling Price'}</label>
                                    <input type="text"
                                        className="form-control"
                                        id="price"
                                        name="price"
                                        value={sellingPrice}
                                        onChange={handleSellingPriceChange}
                                        onBlur={handleSellingPriceBlur}
                                    />
                                </div>
                                {instructionType === 'Locksmith' && vehicleInven === 'true' && (
                                    <div className="col-sm-4">
                                        <label htmlFor="allKeyPrice">All Keys Lost Price:</label>
                                        <input type="text"
                                            className="form-control"
                                            id="allKeyPrice"
                                            name="allKeyPrice"
                                            value={allKeysLostPrice}
                                            onChange={handleAllKeysLostPriceChange}
                                            onBlur={handleAllKeysLostPriceBlur}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="w-100 mt-4 mb-3">
                                {locations.filter((location) => location !== null).map((location, index) => (
                                    <div className="mt-4" key={index}>
                                        <span className="fw-bold pt-5">{location.name}</span>
                                        <div className="row mt-2 m-0 p-0">
                                            <div className="col">
                                                <label htmlFor="inventoryQuanity">Stock:</label>
                                                <input
                                                    className="form-control"
                                                    id="inventoryQuanity"
                                                    name="inventoryQuanity"
                                                    type="number"
                                                    value={location.quantity}
                                                    onChange={(e) => handleLocationChange(index, 'quantity', e.target.value)}
                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="minimunStock1">Stock Threshold:</label>
                                                <input
                                                    className="form-control"
                                                    id="minimunStock1"
                                                    name="minimunStock1"
                                                    type="number"
                                                    value={location.minimunStock}
                                                    onChange={(e) => handleLocationChange(index, 'minimunStock', e.target.value)}
                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="location1">Location:</label>
                                                <input
                                                    className="form-control"
                                                    id="location1"
                                                    name="location1"
                                                    type="text"
                                                    value={location.location}
                                                    onChange={(e) => handleLocationChange(index, 'location', e.target.value.toUpperCase())}
                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="ourCost1">Cost:</label>
                                                <input
                                                    className="form-control"
                                                    id="ourCost1"
                                                    name="ourCost1"
                                                    type="text"
                                                    value={location.ourCost}
                                                    onChange={(e) => handleLocationChange(index, 'ourCost', e.target.value)}
                                                    onBlur={() => handleOurCostBlur(index)}
                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="floatingSelect">Stock Item:</label>
                                                <select className="form-select" onChange={(e) => handleLocationChange(index, 'dontStock', e.target.value)} value={location.dontStock} id="floatingSelect">
                                                    <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="row mt-2 mb-4 m-0 p-0">
                                <div className="col">
                                    <label htmlFor="barcode">Barcode:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="barcode"
                                        value={barcode}
                                        onChange={(e) => setBarcode(e.target.value)}
                                        name="barcode" />
                                </div>
                                <div className="col">
                                    <button className="btn mt-4 btn-outline-info" type="button" onClick={randomBar} id="button-addon2">Generate</button>
                                </div>
                                <div className="col">
                                    <label htmlFor="sku">SKU:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="sku"
                                        name="sku"

                                    />
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col">
                                    <label htmlFor="fccId">FCC ID:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="fccId"
                                        name="fccId"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="chip">Chip ID:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="chip"
                                        name="chip"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="frequency">Frequency:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="frequency"
                                        name="frequency"
                                        onBlur={(e) => handleFrequencyBlur(e)}
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="battery">Battery:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="battery"
                                        name="battery"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="replacementPin">Replaces PN:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="replacementPin"
                                        name="replacementPin"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 m-0 p-0">
                                <div className="col-sm-5">
                                    <label htmlFor="image">Image:</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        onChange={(e) => setSelectedImage(e.target.files[0])}
                                        accept="image/*"
                                    />
                                </div>
                            </div>
                            {vehicleInven === 'true' && instructionType === 'Locksmith' ? (
                                <div className="row justify-content-start mt-5">
                                    <div className="col-sm-10 text-start">
                                        <label htmlFor="make">Vehicle Makes:</label>
                                        <Select
                                            isMulti={false}
                                            options={make
                                                .map(m => ({ value: m.name, label: m.name }))
                                                .sort((a, b) => a.label.localeCompare(b.label))
                                            }
                                            onChange={(selectedOption) => {
                                                setSelectedMake(selectedOption.value); // Update the selected make
                                                setModel([]);
                                                setModelYearPairs([...modelYearPairs, { make: selectedOption.value, model: "", years: "" }]);
                                            }}
                                        />
                                        <ul>
                                            {modelYearPairs.map((pair, index) => (
                                                <li key={index} onClick={() => handleItemClick1(index)} onKeyDown={(e) => checkKeyDown(e)}>
                                                    {editingIndex === index ? (
                                                        <div className="row w-100 mt-4">
                                                            <Select
                                                                className="col-sm-3 mt-1 me-2"
                                                                isMulti={false}
                                                                options={make
                                                                    .map(m => ({ value: m.name, label: m.name }))
                                                                    .sort((a, b) => a.label.localeCompare(b.label))  // Sort options alphabetically
                                                                }
                                                                value={{ value: pair.make, label: pair.make }}
                                                                onChange={(selectedOption) => handleMakeInputChange(selectedOption, index)}
                                                            />
                                                            <Select
                                                                className="col-sm-3 mt-1 me-2"
                                                                isMulti={false}
                                                                options={model
                                                                    .sort((a, b) => a.model.localeCompare(b.model))  // Sort options alphabetically
                                                                    .map((model) => ({
                                                                        value: model.model,
                                                                        label: model.model,
                                                                    }))
                                                                }
                                                                value={{ value: pair.model, label: pair.model }}
                                                                onChange={(selectedOption) => {
                                                                    setSelectedModel(selectedOption.value);
                                                                    setYears([]);
                                                                    handleModelInputChange(selectedOption, index);
                                                                }}
                                                            />
                                                            <div className="col">
                                                                <Select
                                                                    isMulti
                                                                    options={years
                                                                        .slice()
                                                                        .reverse()
                                                                        .map((year) => ({
                                                                            value: year,
                                                                            label: year,
                                                                        }))
                                                                    }
                                                                    value={pair.years ? pair.years.map((year) => ({ label: year, value: year })) : []}
                                                                    onChange={(selectedOptions) => handleYearInputChange(selectedOptions, index)}
                                                                />
                                                            </div>

                                                            <button
                                                                className="btn col-sm-1 btn-outline-danger delete-button ms-3"
                                                                onClick={() => handleDeleteItem1(index)}
                                                            >
                                                                <BsTrash />
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {pair.make && <span>{pair.make} - </span>}
                                                            {pair.model && <span>{pair.model} - </span>}
                                                            {pair.years && (
                                                                <span>
                                                                    {pair.years.map((year, index) => (
                                                                        <span key={index}>
                                                                            {year}
                                                                            {index !== pair.years.length - 1 && ','}{' '}
                                                                        </span>
                                                                    ))}
                                                                </span>
                                                            )}

                                                            {pair.make && (
                                                                <button
                                                                    className="btn btn-outline-danger delete-button ms-3 mt-2"
                                                                    onClick={() => handleDeleteItem1(index)}
                                                                >
                                                                    <BsTrash />
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                <div className="row m-0 p-0 mt-3 mb-2">
                                    <div className="col">
                                        <label htmlFor="instructionCategory">Inventory Item Category:</label>
                                        <select
                                            className="form-control"
                                            id="instructionCategory"
                                            name="instructionCategory"
                                            value={instructionCategory}
                                            onChange={handleInstructionCategoryChange}
                                            disabled={!instructionType}
                                        >
                                            <option value="">Select Item Category</option>
                                            {instructionTypeCategories[instructionType] &&
                                                instructionTypeCategories[instructionType].map((category) => (
                                                    <option key={category} value={category}>{category}</option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="text-center mt-5">
                                <button type="submit" className="btn btn-outline-primary mb-3 loginButton">Submit</button>
                                <Link to={paths.INVENTORYMANAGERITEMS} className="btn btn-outline-success mb-3 ms-2 loginButton">Go Back</Link>
                            </div>
                            {createInventoryResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default InventoryAddComponent;
