import paths from '../router/paths';
import { logout } from "../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import Logo from './assets/logo2.png';
import { useState, useEffect, useRef } from 'react';
import 'bootstrap/js/dist/collapse';

function HeaderComponent({ fetchTesk, fetchUsersData, clockStatus, updateTaskTechApiRequest, fetchInventory, fetchRequest, fetchLocation, fetchTechInfo, fetchUsers }) {
    const dispatch = useDispatch();
    const [request, setRequest] = useState([]);
    const [filterLocations, setFilterLocations] = useState([]);
    const [locations, setLocations] = useState([]);
    const [inventorySet, setInventorySet] = useState([]);
    const collapseRef = useRef(null);
    const navigate = useNavigate();
    const [filteredTech, setFilteredTech] = useState([]);
    const [taskLength, setTaskLength] = useState(null);

    const auth = useSelector(state => state.auth);
    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isInventory = auth.user?.usertype?.includes('Inventory') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;
    const isDispatcher = auth.user?.usertype?.includes('Dispatcher') || false;
    const isOffice = auth.user?.usertype?.includes('Office') || false;
    const isAdminOrSuperAdmin = isAdmin || isSuperAdmin || isInventory;
    const isAdminOrDeveloper = isAdmin || isSuperAdmin;

    useEffect(() => {
        const abctrl = new AbortController();
        if (auth.user.name) {
            fetchUsers()
                .then((data) => {
                    const filteredUser = data.find((item) => item._id === auth.user._id);

                    fetchTechInfo()
                        .then((resData) => {
                            const filteredTech = resData.find((item) => item.user === filteredUser._id);

                            setFilteredTech(data);

                            fetchLocation()
                                .then((dataRes) => {
                                    if (filteredTech) {
                                        const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);
                                        setFilterLocations(filteredLocation);
                                    }

                                    const filtered = dataRes.filter((item) => item.company === auth.user.company);

                                    if (filtered) {
                                        setLocations(filtered);
                                    }
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                            fetchRequest()
                                .then((dataResRes) => {
                                    if (dataResRes.message !== 'request not found') {
                                        const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);
                                        setRequest(filteredRequest);
                                    } else {
                                        setRequest([]);
                                    }
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );
                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            fetchInventory(abctrl)
                .then((res) => {
                    const filteredInventory = res.filter((item) => item.company === auth.user.company);

                    const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1;

                    const inventoryWithSelectedLocationSet = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === locationSelect &&
                            location.quantity <= location.minimunStock1
                        )
                    );

                    setInventorySet(inventoryWithSelectedLocationSet);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [dispatch, auth.user.company, fetchUsers, auth.user._id, fetchTechInfo, fetchLocation, fetchRequest, fetchInventory]);

    useEffect(() => {
        const handleLinkClick = () => {
            if (collapseRef.current && collapseRef.current.classList.contains('show')) {
                const bsCollapse = new window.bootstrap.Collapse(collapseRef.current, {
                    toggle: false
                });
                bsCollapse.hide();
            }
        };

        const navLinks = document.querySelectorAll('.navbar-nav .nav-link');
        navLinks.forEach(link => {
            link.addEventListener('click', handleLinkClick);
        });

        return () => {
            navLinks.forEach(link => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, []);

    useEffect(() => {
        const abctrl = new AbortController();

        if (isAdminOrDeveloper || isOffice) {

            const interval = setInterval(() => {
                fetchTesk(abctrl)
                    .then((res) => Promise.all(res.map(async (task) => {

                        fetchTechInfo()
                            .then(async (resData) => {

                                // Check if the createdAt time is more than 5 minutes ago
                                const taskCreatedAt = new Date(task.createdAt);
                                const fiveMinutesAgo = new Date(Date.now() - 5 * 60000);
                                let filteredAll;

                                task.techs.forEach((techId) => {
                                    const filterTech = resData.find((item) => item._id === techId);
                                    const filteredUser = filteredTech.filter((item) => item._id === auth.user._id);
                                    filteredAll = filteredUser.find((item) => item._id === filterTech.user);
                                });

                                if (filteredAll !== undefined) {
                                    setTaskLength(filteredAll.length)
                                } else {
                                    setTaskLength(0);
                                }

                                if (task.techs.length === 0 && taskCreatedAt < fiveMinutesAgo) {
                                    try {
                                        const data = await fetchUsersData();
                                        if (!data) throw new Error('No data received from fetchUsersData');

                                        const dispatchers = data.filter((user) => user.company === auth.user.company);
                                        const dispatchersFilter = dispatchers.filter((user) => user.usertype?.includes('Dispatcher') || user.usertype?.includes('Office'));

                                        const clockStatusPromises = dispatchersFilter.map(dispatcher => clockStatus(dispatcher._id));
                                        const clockStatuses = await Promise.all(clockStatusPromises);

                                        const today = new Date();
                                        today.setHours(0, 0, 0, 0);

                                        const clockedInDispatchers = dispatchersFilter.filter((dispatcher, index) => {
                                            const dispatcherClockStatus = clockStatuses[index];
                                            const clockInTime = dispatcherClockStatus && dispatcherClockStatus.length > 0 ? new Date(dispatcherClockStatus[0].clockIn.time) : null;
                                            return clockInTime && clockInTime >= today;
                                        });

                                        if (clockedInDispatchers.length > 0) {
                                            const response = await fetch(`/api/roundRobin/${auth.user.company}`);
                                            const { index: roundRobinIndex } = await response.json();

                                            const assignedDispatcher = clockedInDispatchers[roundRobinIndex % clockedInDispatchers.length];

                                            const filteredDisTech = filteredTech.find((tech) => tech.user === assignedDispatcher._id);

                                            const newIndex = (roundRobinIndex + 1) % clockedInDispatchers.length;
                                            await fetch(`/api/roundRobin/${auth.user.company}`, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify({ index: newIndex }),
                                            });

                                            if (filteredDisTech) {
                                                const formInputsTech = {
                                                    techs: filteredDisTech._id,
                                                    status: 'Not Started',
                                                };

                                                const data = await updateTaskTechApiRequest(task._id, formInputsTech);
                                                if (data.message === "task updated") {
                                                    //setShowAlert1(true);
                                                    //setTimeout(() => {
                                                    //    setShowAlert1(false);
                                                    //}, 3000);
                                                }
                                            } else {
                                                console.log("No clocked-in dispatchers available to assign tasks.");
                                            }
                                        } else {
                                            console.log("No clocked-in dispatchers available to assign tasks.");
                                        }
                                    } catch (er) {
                                        console.log(er)
                                        if (er.response && er.response.status === 401 || er.response.data.message === "A token is required for authentication") {
                                            dispatch(logout());
                                        } else if (er.response && er.response.data) {
                                            console.log(er.response.data.message ? er.response.data.message : er.response.data);
                                        } else {
                                            console.log('Error:', er.message);
                                        }
                                    }
                                }
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );
                    })))

            }, 30000);  // 30 seconds interval

            return () => {
                abctrl.abort();
                clearInterval(interval);
            };
        }
    }, [fetchTesk, fetchUsersData, fetchTechInfo, fetchUsers]);

    const handleToggle = () => {
        if (collapseRef.current.classList.contains('show')) {
            const bsCollapse = new window.bootstrap.Collapse(collapseRef.current, {
                toggle: false
            });
            bsCollapse.hide();
        } else {
            const bsCollapse = new window.bootstrap.Collapse(collapseRef.current, {
                toggle: false
            });
            bsCollapse.show();
        }
    };

    const requestDataLenInStatus = () => {
        if (filterLocations && filterLocations.tech) {
            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.toLocation && filterLocations.tech === locc.tech));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            return filteredStatus || [];
        }
        return [];
    };

    const requestDataLenStatus = () => {
        if (filterLocations && filterLocations.tech) {
            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.location && filterLocations.tech === locc.tech));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            return filteredStatus || [];
        }
        return [];
    };

    const inventoryOrder = () => {
        const inventoryOrder = inventorySet.filter(inv => inv.status === "Order");
        return inventoryOrder || [];
    };

    const LengthStatus = () => {
        if (isAdminOrSuperAdmin) {
            const totalStatus = requestDataLenStatus().length + requestDataLenInStatus().length + inventoryOrder().length;
            return totalStatus;
        } else {
            const totalStatus = requestDataLenStatus().length + requestDataLenInStatus().length;
            return totalStatus;
        }
    };

    const handleLogout = () => {
        dispatch(logout(() => {
            navigate(paths.LOGIN);
        }));
    };

    return (
        <>
            {auth.user?.name ? (
                <nav className="navbar navbar-expand-lg navbar-primary fixed-top bg-header navbar-dark mb-5">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to={paths.ROOT}><img alt="Locksmith Menu" src={Logo}></img></Link>
                        <button className="navbar-toggler" type="button" onClick={handleToggle} aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav" ref={collapseRef}>
                            <ul className="navbar-nav ms-auto me-4">
                                {isAdminOrDeveloper && (
                                    <li className="nav-item">
                                        <Link className="nav-link" to={paths.ADMINROOTUSERS}>Admin</Link>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <Link className="nav-link" to={paths.TIMEENTRY}>Time Management</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={paths.LOCKSMITHSEARCH}>Locksmith</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={paths.BUYKEYCODE}>Buy Key Code</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={paths.DECODEVIN}>Decode VIN</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={paths.TASKSLIST}>
                                        Tasks
                                        {taskLength > 0 && (
                                            <span className="translate-middle fs-6 badge1 rounded-pill bg-danger">
                                                {taskLength}
                                                <span className="visually-hidden">unread messages</span>
                                            </span>
                                        )}
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to={paths.DISPATCHCALENDAR}>Schedule</Link>
                                </li>
                                {isAdminOrDeveloper || isDispatcher ? (
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" to={paths.DISPATCHOPENQUOTES}>Dispatch</Link>
                                    </li>
                                ) : null}
                                {isAdminOrSuperAdmin ? (
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" to={paths.INVENTORYMANAGERITEMS}>
                                            Inventory
                                            {LengthStatus() > 0 && (
                                                <span className="translate-middle fs-6 badge1 rounded-pill bg-danger">
                                                    {LengthStatus()}
                                                    <span className="visually-hidden">unread messages</span>
                                                </span>
                                            )}
                                        </Link>
                                    </li>
                                ) : (
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" to={paths.INVENTORYMANAGERITEMSTECH}>
                                            Inventory
                                            {LengthStatus() > 0 && (
                                                <span className="translate-middle fs-6 badge1 rounded-pill bg-danger">
                                                    {LengthStatus()}
                                                    <span className="visually-hidden">unread messages</span>
                                                </span>
                                            )}
                                        </Link>
                                    </li>
                                )}
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to={paths.USERPROFILE} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {auth.user?.name} {auth.user?.lastName}
                                    </Link>
                                    <div className="dropdown-menu width" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item m-0 ps-2 pe-2 p-1" to={paths.USERPROFILE}>My Profile</Link>
                                        <Link className="dropdown-item m-0 ps-2 pe-2 p-1" to={paths.USERCOMPANY}>My Company</Link>
                                        <a className="dropdown-item m-0 ps-2 pe-2 p-1" href='/' onClick={handleLogout}>Logout</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            ) : (
                <></>
            )}
        </>
    );
}

export default HeaderComponent;