import axios from "axios";
import LocksmithSelectComponent from "./components/locksmithSelectComponent";

const LocksmithSelectPage = () => {

    const fetchMake = async (abctrl) => {
        const { data } = await axios.get("/api/vehicleMake/", { withCredentials: true })
        return data;
    }
    const fetchModel = async (make) => {
        const { data } = await axios.get(`/api/vehicleModel?make=${make}`, { withCredentials: true })
        return data;
    }
    const fetchYear = async (model) => {
        const { data } = await axios.get(`/api/vehicleYear?model=${model}`, { withCredentials: true });
        return data
    }
    const fetchVin = async (vin) => {
        const { data } = await axios.post(`/api/vin/decode?vin=${vin}`, { withCredentials: true });
        return data;
    }

    return <LocksmithSelectComponent
        fetchVin={fetchVin}
        fetchYear={fetchYear}
        fetchModel={fetchModel}
        fetchMake={fetchMake}
    />;
};

export default LocksmithSelectPage;
