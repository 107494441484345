import React, { useState, useEffect } from "react";
import { FaRegThumbsUp } from 'react-icons/fa';
import axios from "axios";

const TechSelectComponent = ({ fetchUsers, fetchJobType, fetchJobSource, fetchCustomer, fetchDispatch, id, updateDispatchTechsApiRequest, fetchTechInfo }) => {
    const [users, setUsers] = useState([]);
    const [tech, setTech] = useState([]);
    const [selectedTech, setSelectedTech] = useState([]); 
    const [showAlert1, setShowAlert1] = useState(false);
    const [dispatching, setDispatch] = useState([]);
    const [message, setMessage] = useState('');
    const [customer, setCustomer] = useState([]); 
    const [jobSource, setJobSource] = useState([]);
    const [jobTypes, setJobType] = useState([]);
    const [description, setDescription] = useState('----');

    const [createDispatchResponseState, setCreateDispatchResponseState] =
        useState({
            message: "",
            error: ""
        });

    useEffect(() => {
        const abctrl = new AbortController();

        fetchDispatch(id)
            .then((data) => {
                setDispatch(data);
                setSelectedTech(data.techs)

                if (data.description === '') {
                    setDescription('----');
                } else {
                    setDescription(data.description);
                }

                setCustomer(data.customer);

                fetchJobSource()
                    .then((res) => {
                        const filteredJobSource = res.filter((item) => data.lead.includes(item._id));

                        setJobSource(filteredJobSource);
                    })
                    .catch((error) => {
                        console.log("Error fetching job source:", error);
                    });

                fetchJobType()
                    .then((res) => {
                        const filteredJobType = res.find((item) => item._id === data.jobType);
                        setJobType(filteredJobType);
                    })
                    .catch((error) => {
                        console.log("Error fetching job types:", error);
                    });
            })
            .catch((er) => console.log(er));

        fetchTechInfo(abctrl)
            .then((res) => {
                setTech(res);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        fetchUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                console.log("Error fetching users:", error);
            });
        
        return () => abctrl.abort();
    }, [fetchUsers, fetchTechInfo, fetchCustomer, fetchJobType, fetchJobSource, id, fetchDispatch]);

    const convertPhoneNumber = (inputNumber) => {
        const cleanedNumber = inputNumber.replace(/\D/g, '');

        return cleanedNumber.startsWith('1') ? cleanedNumber : cleanedNumber;
    };

    const getTechName = (techId) => {
        const techUser = users.find((user) => user._id === techId);
        return techUser ? techUser.name : "";
    };

    const handleTechChange = (event) => {
        const selectedValue = JSON.parse(event.target.value);

        const techUser = users.find((user) => user._id === selectedValue.user);
        setSelectedTech([...selectedTech, selectedValue._id]);

        const formattedPhoneNumber = convertPhoneNumber(techUser.phoneNumber);
        const formattedPhoneNumberCustomer = convertPhoneNumber(customer.phone);

        const formInputsTech = {
            techs: [...selectedTech, selectedValue],
        };

        updateDispatchTechsApiRequest(id, formInputsTech)
            .then(async (data) => { 
                if (data.message === "Dispatch job techs updated") {
                    try {
                        const fullAddress = `${customer.address} ${customer.city}, ${customer.state}, ${customer.zip}`;
                        const jobType = `${jobTypes.name || dispatching.other}`;
                        const fullVehicle = `${dispatching.make} ${dispatching.model} ${dispatching.year}`;
                        const fullName = `${customer.name} ${customer.lastName} `;

                        const response = await axios.post('/api/whatsapp/send-whatsapp-message', { to: formattedPhoneNumber, description: description, fullVehicle: fullVehicle, jobType: jobType, selectedAddress: fullAddress, phoneNumber: formattedPhoneNumberCustomer, fullName: fullName, jobSourceName: jobSource.name });
                        setMessage(response.data.message);

                        setShowAlert1(true);
                        setTimeout(() => {
                            setShowAlert1(false);
                        }, 3000);
                    } catch (error) {
                        console.error(error);
                        setMessage('Error sending message');
                    }
                }
            })
            .catch((er) => {
                setCreateDispatchResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const handleRemoveTech = (techId) => {
        setSelectedTech(selectedTech.filter(id => id !== techId)); // Remove the selected tech

        const formInputsTech = {
            techs: selectedTech.filter(id => id !== techId),
        };
        updateDispatchTechsApiRequest(id, formInputsTech)
            .then((data) => {
                if (data.message === "Dispatch job techs updated") {
                    setShowAlert1(true);
                    setTimeout(() => {
                        setShowAlert1(false);
                    }, 3000);
                }
            })
            .catch((er) => {
                setCreateDispatchResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const handleCloseAlert = () => {
        setShowAlert1(false);
    };

    const getSelectedTechInfo = () => {
        return selectedTech.map((selectedTechId) => {
            const techItem = tech.find((techItem) => techItem._id === selectedTechId);
            if (techItem) {
                const userName = getTechName(techItem.user);
                return { ...techItem, userName };
            }
            return null;
        }).filter(Boolean);
    };

    const availableTechOptions = tech.filter((techItem) => !selectedTech.includes(techItem._id));

    return (
        <div className="col-sm-12">
            {showAlert1 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Technician Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            <div className="selected-techs">
                {selectedTech.length > 0 && (
                    <div className="d-flex flex-wrap">
                        {getSelectedTechInfo().map((tech, index) => (
                            <div key={tech._id} className="selected-tech mt-2 me-2">
                                <span className="selected-user">
                                    {tech.userName}
                                    <button
                                        type="button"
                                        className="btn btn-link btn-remove-tech"
                                        onClick={() => handleRemoveTech(tech._id)}
                                    >
                                        x
                                    </button>
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <select
                className="form-select mt-3 text-dark"
                id="techs"
                name="techs"
                onChange={handleTechChange}
                defaultValue={""}
            >
                <option defaultValue="">Assign Team Members</option>
                {availableTechOptions.map((techItem) => (
                    <option key={techItem._id} value={JSON.stringify(techItem)}>
                        {getTechName(techItem.user)}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default TechSelectComponent;
