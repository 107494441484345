import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";

const EditJobTagComponent = ({
    fetchJobTag,
    updateJobTagApiRequest,
}) => {
    const [validated, setValidated] = useState(false);
    const [jobTag, setJobTag] = useState([]);
    const auth = useSelector(state => state.auth)
    const [updateJobTagResponseState, setUpdateJobTagResponseState] = useState({
        message: "",
        error: "",
    });
    const initialColors = ['#0059a0', '#be2c04', '#e18a2b', '#37d34d', '#edce4c', '#c04d97', '#198218', '#3d1c8b'];
    const [selectedColor, setSelectedColor] = useState('');

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchJobTag(id)
            .then((data) => {
                setJobTag(data);
                setSelectedColor(data.color);
            })
            .catch((er) => console.log(er));
    }, [id, fetchJobTag]);

    const handleColorSelect = (color) => {
        setSelectedColor(color);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            color: selectedColor,
            company: auth.user.company,
        };

        if (event.currentTarget.checkValidity() === true) {
            updateJobTagApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "Job tag updated") navigate(`${paths.DISPATCHJOBTAG}`);
                })
                .catch((er) =>
                    setUpdateJobTagResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    return (
        <div className="pt-5 mt-3">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-center">Edit Job Tag</h1>
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="row mt-4 g-3">
                            <div className="row justify-content-center mt-5">
                                <div className="col-sm-12">
                                    <label htmlFor="name">Job Tag:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        defaultValue={jobTag.name}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 mb-4 justify-content-center">
                                {initialColors.map((color) => (
                                    <div
                                        key={color}
                                        className="col-1 d-flex align-items-center justify-content-center"
                                        style={{
                                            backgroundColor: color,
                                            height: '30px', // Adjust the height as per your preference
                                            width: '30px', // Adjust the width as per your preference
                                            cursor: 'pointer',
                                            color: '#fff',
                                            border: selectedColor === color ? '3px solid #000' : 'none',
                                        }}
                                        onClick={() => handleColorSelect(color)}
                                    >
                                        {selectedColor === color && <span>&#10004;</span>}
                                    </div>
                                ))}
                            </div>
                            <div className="text-center mt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.DISPATCHJOBTAG} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {updateJobTagResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditJobTagComponent;