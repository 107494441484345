import axios from "axios";
import TaskComponent from "./components/taskComponent";

const TasksPage = () => {

    const fetchTesk = async (abctrl) => {
        const { data } = await axios.get("/api/task/all", { withCredentials: true });
        return data
    }
    const deleteTesk = async (teskId) => {
        const { data } = await axios.delete(`/api/task/admin/delete/${teskId}`, { withCredentials: true });
        return data
    }
    const fetchTechInfo = async (techId) => {
        const { data } = await axios.get(`/api/techInfo/getTech/${techId}`, { withCredentials: true });
        return data
    }
    const fetchTechInfoAll = async () => {
        const { data } = await axios.get(`/api/techInfo/graph`, { withCredentials: true });
        return data
    }

    const fetchUsersData = async () => {
        const { data } = await axios.get(`/api/users/inventory/`, { withCredentials: true });
        return data
    }

    const fetchUsers = async (userId) => {
        const { data } = await axios.get(`/api/users/profile/${userId}`, { withCredentials: true });
        return data
    }

    const updateDispatchTaskApiRequest = async (taskId, formInputsPriority) => {
        const { data } = await axios.put(`/api/task/priority/${taskId}`, { ...formInputsPriority }, { withCredentials: true });
        return data;
    }

    const updateTaskStatusApiRequest = async (taskId, formInputsStatus) => {
        const { data } = await axios.put(`/api/task/status/${taskId}`, { ...formInputsStatus }, { withCredentials: true });
        return data;
    }

    const updateTaskDeadlineApiRequest = async (taskId, formInputsDeadline) => {
        const { data } = await axios.put(`/api/task/deadline/${taskId}`, { ...formInputsDeadline }, { withCredentials: true });
        return data;
    }

    const updateTaskTechApiRequest = async (taskId, formInputsTech) => {
        const { data } = await axios.put(`/api/task/tech/${taskId}`, { ...formInputsTech }, { withCredentials: true });
        return data;
    }

    const fetchTaskChat = async (taskId) => {
        const { data } = await axios.get(`/api/task-chat/${taskId}/messages`, { withCredentials: true });
        return data
    }
    const fetchTaskChatLike = async (taskChatId) => {
        const { data } = await axios.get(`/api/task-chatLike/${taskChatId}`, { withCredentials: true });
        return data
    }
    const deleteTaskChatLike = async (taskChatId) => {
        const { data } = await axios.delete(`/api/task-chatLike/remove/${taskChatId}`, { withCredentials: true });
        return data
    }
    const newTaskChat = async (taskId, formInputs) => {
        const { data } = await axios.post(`/api/task-chat/${taskId}/messages`, { ...formInputs }, { withCredentials: true });
        return data;
    }
    const updateTaskChatIconApiRequest = async (taskChatId, formInputsIconLike) => {
        const { data } = await axios.put(`/api/task-chat/icon/${taskChatId}`, { ...formInputsIconLike }, { withCredentials: true });
        return data;
    }
    const createTaskIconApiRequest = async (formInputsIcon) => {
        const { data } = await axios.post(`/api/task-chatLike/post`, { ...formInputsIcon }, { withCredentials: true });
        return data;
    }
    const fetchUploadsImage = async (imageId) => {
        const { data } = await axios.get(`/api/uploads/image/${imageId}`, { withCredentials: true });
        return data
    }
    const deleteTaskChat = async (taskChatId) => {
        const { data } = await axios.delete(`/api/task-chat/remove/${taskChatId}`, { withCredentials: true });
        return data
    }
    const updateTaskChatAllApiRequest = async (taskChatId, formInputs) => {
        const { data } = await axios.put(`/api/task-chat/all/${taskChatId}`, { ...formInputs }, { withCredentials: true });
        return data;
    }
    const updateTaskApiRequest = async (taskId, formInputs) => {
        const { data } = await axios.put(`/api/task/admin/${taskId}`, { ...formInputs }, { withCredentials: true });
        return data;
    }
    const clockStatus = async (userId) => {
        const { data } = await axios.get(`/api/timeEntry/status/${userId}`, { withCredentials: true });
        return data
    }
    const createTaskApiRequest = async (formInputs) => {
        const { data } = await axios.post(`/api/task/admin/new`, { ...formInputs }, { withCredentials: true });
        return data;
    };

    return <TaskComponent
        createTaskApiRequest={createTaskApiRequest}
        clockStatus={clockStatus}
        updateTaskTechApiRequest={updateTaskTechApiRequest}
        updateTaskChatAllApiRequest={updateTaskChatAllApiRequest}
        deleteTaskChat={deleteTaskChat}
        updateTaskApiRequest={updateTaskApiRequest}
        fetchUploadsImage={fetchUploadsImage}
        deleteTaskChatLike={deleteTaskChatLike}
        fetchTaskChatLike={fetchTaskChatLike}
        createTaskIconApiRequest={createTaskIconApiRequest}
        updateTaskChatIconApiRequest={updateTaskChatIconApiRequest}
        newTaskChat={newTaskChat}
        fetchTaskChat={fetchTaskChat}
        updateTaskDeadlineApiRequest={updateTaskDeadlineApiRequest}
        updateTaskStatusApiRequest={updateTaskStatusApiRequest}
        updateDispatchTaskApiRequest={updateDispatchTaskApiRequest}
        fetchTesk={fetchTesk}
        deleteTesk={deleteTesk}
        fetchTechInfo={fetchTechInfo}
        fetchUsers={fetchUsers}
        fetchTechInfoAll={fetchTechInfoAll}
        fetchUsersData={fetchUsersData}
    />;
};

export default TasksPage;
