import axios from "axios";
import { useDispatch } from "react-redux";
import AdminNewVinPriceComponent from "./components/adminNewVinPriceComponent";

const createVinPriceApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/vinPrice/admin/new`, { ...formInputs }, { withCredentials: true });
    return data;
};

const AdminNewVinPrice = () => {
    const dispatch = useDispatch();

    return (
        <AdminNewVinPriceComponent
            createVinPriceApiRequest={createVinPriceApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminNewVinPrice;