import React, { useState } from 'react';
import axios from 'axios';

const ConnectStripeButton = ({ companyId, onSuccess }) => {
    const handleConnectClick = async () => {
        try {
            // Call your backend endpoint to create a connected Stripe account
            const response = await axios.post(`/api/company/create-stripe-account/${companyId}`, { companyId });

            // If successful, get the Stripe account ID from the response
            const { accountId } = response.data;

            // Redirect the user to Stripe Connect onboarding
            if (accountId) {
                const stripeRedirect = await axios.post('/api/payment/create-connect-link', { accountId });

                // Redirect the user to the Stripe onboarding link
                window.location.href = stripeRedirect.data.url;

                // Note: The server needs to handle the creation of the account link
            }
        } catch (error) {
            console.error('Error connecting to Stripe:', error);
        }
    };

    return (
        <button className="btn btn-outline-primary mt-5" onClick={handleConnectClick}>Connect with Stripe</button>
    );
};

export default ConnectStripeButton;
