import axios from "axios";
import { useDispatch } from "react-redux";
import CreateDiscountCodesComponent from "./components/adminNewDiscountCodesComponent";

const createCodesApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/discountCodes/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const AdminCreateDiscountCodesPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateDiscountCodesComponent
            createCodesApiRequest={createCodesApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreateDiscountCodesPage;