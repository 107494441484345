import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import InventoryLinksComponent from "../../../components/inventory/inventoryLinksComponent";
import { FaRegThumbsUp } from 'react-icons/fa';
import * as React from "react";
import InventoryRequestPopUpComponent from "../../../components/inventory/inventoryRequestComponent";
import { MdOutlineInventory } from "react-icons/md";
import { MdOutlinePersonOff } from "react-icons/md";

const InventoryRequestComponent = ({ fetchRequest, createRequestApiRequest, fetchInventory, fetchLocation, fetchUsers, fetchTechInfo }) => {
    const [inventory, setInventory] = useState([]);
    const auth = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [showAlertSuc, setShowAlertSuc] = useState(false);
    const [locations, setLocations] = useState([]);
    const [filterLocations, setFilterLocations] = useState('');
    const [user, setUser] = useState([]);
    const [techs, setTechs] = useState([]);
    const [request, setRequest] = useState([]);
    const [inventorySet, setInventorySet] = useState([]);

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchInventory(abctrl)
            .then((res) => {
                const filteredInventory = res.filter((item) => item.company === auth.user.company);

                const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                    item.InventoryLocation.some((location) =>
                        location.name1 === locationSelect &&
                        location.quantity <= location.minimunStock1
                    )
                );
                setInventorySet(inventoryWithSelectedLocation);

                fetchUsers()
                    .then((data) => {
                        const filteredUser = data.find((item) => item._id === auth.user._id);

                        setUser(data);

                        fetchTechInfo()
                            .then((resData) => {
                                const filteredTech = resData.find((item) => item.user === filteredUser._id);

                                setTechs(resData);

                                fetchLocation()
                                    .then((dataRes) => {
                                        if (filteredTech) {
                                            const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);

                                            setFilterLocations(filteredLocation);
                                        }
                                        setLocations(dataRes);
                                        setInventory(filteredInventory);

                                    })
                                    .catch((er) =>
                                        console.log(
                                            er.response.data.message ? er.response.data.message : er.response.data
                                        )
                                    );
                                fetchRequest()
                                    .then((dataResRes) => {
                                        if (dataResRes.message !== 'request not found') {
                                            const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                            setRequest(filteredRequest)
                                        } else {
                                            setRequest([])
                                        }
                                    })
                                    .catch((er) =>
                                        console.log(
                                            er.response.data.message ? er.response.data.message : er.response.data
                                        )
                                    );
                            })
                            .catch((er) =>
                                console.log(
                                    er.response.data.message ? er.response.data.message : er.response.data
                                )
                            );

                    })
                    .catch((er) =>
                        console.log(
                            er.response.data.message ? er.response.data.message : er.response.data
                        )
                    );

                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [fetchInventory, auth.user.company, fetchUsers, auth.user._id, fetchTechInfo, fetchLocation, fetchRequest]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchInventory(abctrl)
                .then((res) => {
                    const filteredInventory = res.filter((item) => item.company === auth.user.company);

                    const locationSelect = filteredInventory[0].InventoryLocation[0]?.name1

                    const inventoryWithSelectedLocation = filteredInventory.filter((item) =>
                        item.InventoryLocation.some((location) =>
                            location.name1 === locationSelect &&
                            location.quantity <= location.minimunStock1
                        )
                    );
                    setInventorySet(inventoryWithSelectedLocation);

                    fetchUsers()
                        .then((data) => {
                            const filteredUser = data.find((item) => item._id === auth.user._id);

                            setUser(data);

                            fetchTechInfo()
                                .then((resData) => {
                                    const filteredTech = resData.find((item) => item.user === filteredUser._id);

                                    fetchLocation()
                                        .then((dataRes) => {
                                            if (filteredTech) {
                                                const filteredLocation = dataRes.find((item) => item.tech === filteredTech._id);
                                                setFilterLocations(filteredLocation);
                                            }

                                            setLocations(dataRes);
                                            setInventory(filteredInventory);
                                        })
                                        .catch((er) =>
                                            console.log(
                                                er.response.data.message ? er.response.data.message : er.response.data
                                            )
                                        );
                                    fetchRequest()
                                        .then((dataResRes) => {
                                            if (dataResRes.message !== 'request not found') {
                                                const filteredRequest = dataResRes.filter((item) => item.company === auth.user.company);

                                                setRequest(filteredRequest)
                                            } else {
                                                setRequest([])
                                            }
                                        })
                                        .catch((er) =>
                                            console.log(
                                                er.response.data.message ? er.response.data.message : er.response.data
                                            )
                                        );
                                })
                                .catch((er) =>
                                    console.log(
                                        er.response.data.message ? er.response.data.message : er.response.data
                                    )
                                );

                        })
                        .catch((er) =>
                            console.log(
                                er.response.data.message ? er.response.data.message : er.response.data
                            )
                        );

                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [fetchInventory, auth.user.company, isLoading, fetchUsers, auth.user._id, fetchTechInfo, fetchLocation, fetchRequest]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };
    const componentRef = useRef(null);

    const handleSubmit = async (id, destinationLocationName, quantity) => {
        const parsedQuantity = parseInt(quantity, 10);

        if (isNaN(parsedQuantity) || !Number.isInteger(parsedQuantity) || parsedQuantity <= 0) {
            setShowAlertMessage("Quantity must be a positive integer.");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);

            return;
        }

        fetchRequest()
            .then((dataRes) => {
                const filteredRequest = dataRes.find((item) => (item.toLocation === destinationLocationName) && (item.status === 'Submitted' || item.status === 'Pending') && item.inventoryId === id);

                const isRequestExist = filteredRequest;

                if (isRequestExist) {
                    setShowAlertMessage(`You have already requested stock from this technician.`);
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);

                } else {
                    let formInputs;

                    formInputs = {
                        quantity: parsedQuantity,
                        location: filterLocations._id,
                        toLocation: destinationLocationName,
                        status: 'Submitted',
                        inventoryId: id,
                        company: auth.user.company,
                    };

                    createRequestApiRequest(formInputs)
                        .then((data) => {
                            if (data.message === "request created");
                            setShowAlertSuc(true);
                            setTimeout(() => {
                                setShowAlertSuc(false);
                            }, 3000);
                            setIsLoading(true);
                        })
                        .catch((error) => {
                            if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === "Invalid quantity to request.") {
                                setShowAlertMessage("Invalid quantity to request.");
                            } else {
                                setShowAlertMessage(`Error requesting item: ${error.message}`);
                            }

                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                            }, 3000);
                        });
                }
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
    };

    const handleCheckout = (id, item) => {
        setSelectedItem({ id, item });
        setShowConfirmation(true);
    };

    const onConfirmRequest = (quantity) => {
        handleSubmit(selectedItem.id, selectedItem.item._id, quantity);
        setShowConfirmation(false);
    };
    const onCancelRequest = () => {
        setShowConfirmation(false);
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleCloseAlertSuc = () => {
        setShowAlertSuc(false);
    };

    const getTechName = (techId) => {
        const techUser = techs.find((tech) => tech._id === techId);

        if (techUser) {
            const userId = techUser.user;

            if (userId) {
                const techName = user.find((users) => users._id === userId);
                return techName ? techName.name : "";
            } else {
                return "Undefined"
            }
        } else {
            return "Undefined"
        }
    };
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const requestDataLenStatus = () => {
        if (filterLocations) {
            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.location && filterLocations.tech === locc.tech));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            if (filteredStatus === undefined) {
                return 0;
            } else {
                return filteredStatus;
            }
        } else {
            return 0;
        }
    }

    const requestDataLenInStatus = () => {
        if (filterLocations) {

            const requestData = request.filter((loc) => locations.filter((location) => (filterLocations.tech === location.tech)).some((locc) => locc._id === loc.toLocation));

            const filteredStatus = requestData.filter((item) => item.status === 'Submitted' || item.status === 'Pending Pickup');

            if (filteredStatus === undefined) {
                return 0;
            } else {
                return filteredStatus;
            }
        } else {
            return 0;
        }
    }

    return (
        <div className="min-vh-100">
            {(showConfirmation && selectedItem && (filterLocations !== undefined)) ? (
                <InventoryRequestPopUpComponent
                    message={`Enter the quantity to request (Available stock: ${selectedItem.item.quantity}):`}
                    selectedItem={selectedItem}
                    onConfirm={onConfirmRequest}
                    onCancel={onCancelRequest}
                />
            ) : showConfirmation && (
                <div className="alertRed w-90 alert-danger" role="alert">
                    Technition has not been set with an inventory yet.
                    <div className="alert-line-container">
                        <span className="alert-closeRed" onClick={onCancelRequest}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert && (
                <div className="alertRed alert-danger" role="alert">
                    {showAlertMessage}
                    <div className="alert-line-container">
                        <div className="alert-lineRed"></div>
                        <span className="alert-closeRed" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlertSuc && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Inventory Requested
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>
                            X
                        </span>
                    </div>
                </div>
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <InventoryLinksComponent inventorySet={inventorySet} requestDataLenStatus={requestDataLenStatus} requestDataLenInStatus={requestDataLenInStatus} />
                </div>
                <div className="col-sm-10 ms-0 ps-0 mt-5 pt-5">
                    <div className="row d-flex justify-content-between m-0 p-0 w-100 mb-3">
                        <div className="col-sm-5 text-start m-0 p-0">
                            <h1>Request Inventory</h1>
                        </div>
                        <div className="col-sm-3 align-end m-0 p-0">
                            <input
                                type="text"
                                className="mt-1 me-4 form-control table-filter w-100"
                                data-table="order-table"
                                placeholder="Enter barcode to request stock.."
                                value={searchValue}
                                disabled={filterLocations.length === 0}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {filterLocations ? (
                                <>
                                    {searchValue ? (
                                        <>
                                            {locations.filter((location) => !location.defaultLoc && filterLocations.tech !== location.tech).map((location, locationIndex) => (
                                                <div key={locationIndex} className="table-responsive">
                                                    <span>{location.van ? getTechName(location.tech) : ''} {location.name}</span>
                                                    <table ref={componentRef} className="table-hover table-bordered order-table table">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-start ps-4">Inventory Name</th>
                                                                <th className="text-center">Location</th>
                                                                <th className="text-center">Stock</th>
                                                                <th className="text-center">Price</th>
                                                                <th className="text-center">Cost</th>
                                                                <th className="text-center">Request</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {inventory
                                                                .filter((inventoryItem) =>
                                                                    inventoryItem.barcode.toLowerCase().indexOf(searchValue) !== -1
                                                                )
                                                                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                                                .map((inventoryItem, innerIdx) => {

                                                                    const name = `${getTechName(location.tech)} ${capitalizeFirstLetter(location.name)}`

                                                                    const locationData = inventoryItem.InventoryLocation.find((loc) => loc.name1 === name);

                                                                    return (
                                                                        <tr key={innerIdx}>
                                                                            <td className="align-middle text-center">{inventoryItem.name}</td>
                                                                            <td className="align-middle text-center">
                                                                                {locationData ? (locationData.location1 !== '' ? locationData.location1 : "-") : "-"}
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {locationData ? (locationData.quantity !== '' ? locationData.quantity : "-") : "-"}
                                                                            </td>
                                                                            <td className="align-middle text-center">{Number(inventoryItem.price).toFixed(2)}</td>
                                                                            <td className="align-middle text-center">
                                                                                {locationData ? (locationData.ourCost1 !== 0 ? Number(locationData.ourCost1).toFixed(2) : "-") : "-"}
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                <button
                                                                                    className="btn btn-sm btn-outline-primary"
                                                                                    onClick={() => handleCheckout(inventoryItem._id, locationData)}
                                                                                    disabled={(locationData) ? ((locationData.quantity !== '' && locationData.quantity > 0) ? false : true) : true}
                                                                                >
                                                                                    Request
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="mt-5">
                                            <div className="row d-flex justify-content-center m-0 p-0">
                                                <div className="col-sm-4 m-0 p-0 text-center">
                                                    <MdOutlineInventory className="inventoryLogo fs-1" />
                                                </div>
                                            </div>
                                            <div className="row d-flex justify-content-center m-0 p-0">
                                                <div className="col-sm-4 m-0 p-0 text-center">
                                                    <span>Scan or enter a barcode to get started.</span> <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="mt-5 d-flex flex-column align-items-center">
                                    <div className="d-flex justify-content-start align-items-center m-0 p-0">
                                        <div className="col-sm-4 m-0 p-0 text-center">
                                            <MdOutlinePersonOff className="inventoryLogo fs-1" />
                                        </div>
                                    </div>
                                    <div className="d-flex mt-3 justify-content-center m-0 p-0">
                                        <div className="col-sm-7 m-0 p-0 text-center w-100">
                                            <span>
                                                You have not yet been allocated a location.<br />
                                                Please reach out to an administrator to have them assign a location for you to begin.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {(searchValue && filterLocations) && (
                        <div className="d-flex justify-content-center mb-3 pb-4">
                            <button
                                className="btn btn-sm btn-outline-primary pe-2 ps-2 mr-2 p-0 m-0 me-3"
                                disabled={page === 1}
                                onClick={handlePrevPage}
                            >
                                PREVIOUS
                            </button>
                            <span className="mr-2 me-3">
                                Page {page} of {Math.ceil(inventory
                                    .filter((inventoryItem) =>
                                        inventoryItem.barcode.toLowerCase().indexOf(searchValue) !== -1
                                    ).length / rowsPerPage)}
                            </span>
                            <button
                                className="btn btn-sm btn-outline-primary pe-4 ps-4 mr-2 p-0 m-0 me-3"
                                disabled={inventory
                                    .filter((inventoryItem) =>
                                        inventoryItem.barcode.toLowerCase().indexOf(searchValue) !== -1
                                    ).length <= page * rowsPerPage}
                                onClick={handleNextPage}
                            >
                                NEXT
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InventoryRequestComponent;