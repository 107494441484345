import React from "react";
import { MdOutlineInventory } from "react-icons/md";

const InventoryPickUpComponent = ({ onConfirm, onCancel }) => {

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box-pick">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder bg-light deleteWidth mb-4">
                        <div className="col-sm fs-5">
                            Approve Pick Up
                        </div>
                    </div>
                    <div className="row fw-bolder text-primary coneConfirJob mb-4">
                        <MdOutlineInventory />
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-11 text-center">
                            <span className="fw-bold fs-5">Are you Sure?</span><br />
                            <span className="fw-bold fs-6">Did the requesting technition pick up the inventory?</span><br />
                        </div>
                    </div>
                    <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                        <div className="col-sm">
                            <button onClick={onConfirm} className="btn btn-outline-success m-0 ps-5 pe-5 loginButton">
                                Confirm
                            </button>
                        </div>
                        <div className="col-sm">
                            <button className="btn btn-outline-primary ps-5 pe-5 loginButton" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InventoryPickUpComponent;
