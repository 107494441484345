import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import paths from "../../../router/paths";
import { FaUsers } from "react-icons/fa";
import AdminDeletePopUpComponent from "../../../components/admin/adminDeletePopUpComponent";

const AdminMainUserComponent = ({ fetchLocation, fetchTechInfo, fetchUsers, deleteUser, deleteTechInfo, deleteLocation }) => {
    const [users, setUsers] = useState([]);
    const auth = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const abctrl = new AbortController();

        const loadUsersAndTechInfo = async () => {
            try {
                setIsLoading(true);
                const [userRes, techInfoRes, locationInfoRes] = await Promise.all([
                    fetchUsers(abctrl),
                    fetchTechInfo(abctrl),
                    fetchLocation(abctrl)
                ]);

                const filteredUsers = userRes.filter((item) => item.company === auth.user.company);
                const filteredAdmin = filteredUsers.filter((item) => !item.usertype?.includes('Developer'));

                const updatedUsers = filteredAdmin.map(user => {
                    const techInfo = techInfoRes.find((itm) => itm.user === user._id);
                    const locationInfo = techInfo ? locationInfoRes.find((itm) => itm.tech === techInfo._id) : null;

                    return {
                        ...user,
                        fieldtech: !!techInfo,
                        tech: techInfo || null,
                        location: locationInfo || null,
                    };
                });

                setUsers(updatedUsers);
            } catch (er) {
                setUsers([
                    { name: er.response?.data?.message || er.message }
                ]);
            } finally {
                setIsLoading(false);
            }
        };

        loadUsersAndTechInfo();

        return () => abctrl.abort();
    }, [fetchUsers, fetchTechInfo, fetchLocation, auth.user.company]);

    useEffect(() => {
        const abctrl = new AbortController();

        const loadUsersAndTechInfo = async () => {
            try {
                setIsLoading(true);
                const [userRes, techInfoRes, locationInfoRes] = await Promise.all([
                    fetchUsers(abctrl),
                    fetchTechInfo(abctrl),
                    fetchLocation(abctrl)
                ]);

                const filteredUsers = userRes.filter((item) => item.company === auth.user.company);
                const filteredAdmin = filteredUsers.filter((item) => !item.usertype?.includes('Developer'));

                const updatedUsers = filteredAdmin.map(user => {
                    const techInfo = techInfoRes.find((itm) => itm.user === user._id);
                    const locationInfo = techInfo ? locationInfoRes.find((itm) => itm.tech === techInfo._id) : null;

                    return {
                        ...user,
                        fieldtech: !!techInfo,
                        tech: techInfo || null,
                        location: locationInfo || null,
                    };
                });

                setUsers(updatedUsers);
            } catch (er) {
                setUsers([
                    { name: er.response?.data?.message || er.message }
                ]);
            } finally {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            loadUsersAndTechInfo();
        }

        return () => abctrl.abort();
    }, [fetchUsers, isLoading, fetchTechInfo, fetchLocation, auth.user.company]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };

    const handleDelete = (user) => {
        setShowAlert(true);
        setSelectedUser(user);
    }

    const handleCancelRemoveItem = () => {
        setShowAlert(false);
        setSelectedUser(null);
    };

    const handleConfirmRemoveItem = async () => {

        if (selectedUser.location && selectedUser.tech) {
            const data = await deleteUser(selectedUser._id);
            if (data.message === 'Deleted!') {

                const data = await deleteTechInfo(selectedUser.tech._id);
                if (data.message === 'Tech info deleted successfully') {

                    const data = await deleteLocation(selectedUser.location._id);
                    if (data.message === 'location deleted successfully') {
                        setShowAlert(false);
                        setIsLoading(true);
                    }
                }
            }
        } else {
            const data = await deleteUser(selectedUser._id);
            if (data.message === 'Deleted!') {
                setShowAlert(false);
                setIsLoading(true);
            }
        }
    };

    return (
        <div className="min-vh-100">
            {showAlert && (
                <AdminDeletePopUpComponent
                    title='Delete User?'
                    onConfirm={handleConfirmRemoveItem}
                    onCancel={handleCancelRemoveItem}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <AdminLinksComponent />
                </div>
                <div className="col-sm-10">
                    <div className="row mb-3 mt-5 pt-5">
                        <div className="col">
                            <h1>Your Team</h1>
                        </div>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                className="mt-2 input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Users.."
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1">
                            <Link to={paths.ADMINROOTNEWUSER} className="btn btn-outline-primary loginButton">New</Link>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {users.length === 0 ? (
                                    <div className="mt-5">
                                        <div className="row d-flex justify-content-center m-0 p-0">
                                            <div className="col-sm-2 m-0 p-0 text-center align-center">
                                                <FaUsers className="inventoryLogo fs-1" />
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-center m-0 p-0">
                                            <div className="col-sm-5 m-0 p-0 text-center">
                                                <span>Start by adding your team by clicking new.</span>
                                            </div>
                                        </div>
                                    </div>
                            ) : (
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="text-start">Name</th>
                                            <th className="text-start">Username</th>
                                            <th className="text-start">Phone</th>
                                            <th className="text-start">Email</th>
                                            <th className="text-start">Roles</th>
                                            <th className="text-start">Field Tech</th>
                                            <th className="text-center">Edit/Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users
                                            .filter(
                                                (user) =>
                                                    user.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                                    user.lastName.toLowerCase().indexOf(searchValue) !== -1 ||
                                                    user.email.toLowerCase().indexOf(searchValue) !== -1 ||
                                                    user.username.toLowerCase().indexOf(searchValue) !== -1
                                            )
                                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                            .map((user, idx) => (
                                                <tr key={idx}>
                                                    <td className="text-center">{user.name} {user.lastName}</td>
                                                    <td className="text-center">{user.username}</td>
                                                    <td className="text-center">{user.phoneNumber}</td>
                                                    <td className="text-center">{user.email}</td>
                                                    <td className="text-center">
                                                        <ul className="list-unstyled">
                                                            {user.usertype.map((type, index) => (
                                                                <li key={index}>{type}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                    <td className="text-center">{user.fieldtech ? (
                                                        <span>True</span>
                                                    ) : (
                                                        <span>False</span>
                                                    )}</td>
                                                    <td className="text-center">
                                                        <Link to={`/root/edit/users/${user._id}`} className="btn btn-outline-primary btn-sm">
                                                            <GoPencil />
                                                        </Link>
                                                        {" / "}
                                                        <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={() => handleDelete(user)}
                                                        >
                                                            <BsTrash />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                    </tbody>
                                </table>
                            )}
                        </>
                    )}
                    {users.length !== 0 && (
                        <div className="d-flex justify-content-center pb-4">
                            <button
                                className="btn btn-outline-primary loginButton btn-sm me-3"
                                disabled={page === 1}
                                onClick={handlePrevPage}
                            >
                                Previous
                            </button>
                            <span className="mr-2 me-3">
                                Page {page} of {Math.ceil(users.filter((user) => user.name.toLowerCase().indexOf(searchValue) !== -1 || user.lastName.toLowerCase().indexOf(searchValue) !== -1 || user.email.toLowerCase().indexOf(searchValue) !== -1 || user.username.toLowerCase().indexOf(searchValue) !== -1).length / rowsPerPage)}
                            </span>
                            <button
                                className="btn btn-outline-primary loginButton btn-sm me-3"
                                disabled={users.filter((user) => user.name.toLowerCase().indexOf(searchValue) !== -1 || user.lastName.toLowerCase().indexOf(searchValue) !== -1 || user.email.toLowerCase().indexOf(searchValue) !== -1 || user.username.toLowerCase().indexOf(searchValue) !== -1).length <= page * rowsPerPage}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminMainUserComponent;