import axios from "axios";
import PinCodeComponent from "./components/pinCodeComponent";

const PinCodePage = () => {

    const fetchVin = async (vin) => {
        const { data } = await axios.post(`/api/vin/decode?vin=${vin}`, { withCredentials: true });
        return data;
    }

    const fetchRequest = async (vin, companyId) => {
        const { data } = await axios.get(`/api/vinRequest?vin=${vin}&company=${companyId}`, { withCredentials: true });
        return data;
    }

    const fetchRequestCompany = async (companyId) => {
        const { data } = await axios.get(`/api/vinRequest/company?company=${companyId}`, { withCredentials: true });
        return data;
    }

    const fetchVinPrice = async (abctrl) => {
        const { data } = await axios.get("/api/vinPrice/price", { withCredentials: true });
        return data
    }

    const sendRequestVin = async (formInputs) => {
        const { data } = await axios.post(`/api/vinRequest/request`, { ...formInputs }, { withCredentials: true });
        return data
    }

    return <PinCodeComponent
        fetchRequestCompany={fetchRequestCompany}
        fetchRequest={fetchRequest}
        sendRequestVin={sendRequestVin}
        fetchVin={fetchVin}
        fetchVinPrice={fetchVinPrice}
    />;
};

export default PinCodePage;
