import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import paths from "../../../router/paths";

const AdminEditTaxCompontent = ({
    fetchJobTax,
    updateJobTaxApiRequest,
}) => {
    const [selectedTax, setSelectedTax] = useState(true);
    const [validated, setValidated] = useState(false);
    const [jobTax, setJobTax] = useState([]);
    const [updateJobTaxResponseState, setUpdateJobTaxResponseState] = useState({
        message: "",
        error: "",
    });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchJobTax(id)
            .then((data) => {
                setJobTax(data);
                setSelectedTax(data.defaultTax);
            })
            .catch((er) => console.log(er));
    }, [id, fetchJobTax]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            taxRate: form.taxRate.value,
            defaultTax: selectedTax,
            description: form.description.value,
            company: jobTax.company,
        };

        if (event.currentTarget.checkValidity() === true) {
            updateJobTaxApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "Job tax updated") navigate(`${paths.ADMINTAX}`);
                })
                .catch((er) =>
                    setUpdateJobTaxResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    const handleSelectTax = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedTax(newSelectedOption);
    }

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-5">
                        <h1 className="text-center">Edit Tax</h1>
                        <form noValidate validated={validated} onSubmit={handleSubmit} className="row pt-3 g-3">
                            <div className="row mt-5">
                                <div className="col">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            defaultValue={jobTax.name}
                                            placeholder="Name" />
                                        <label for="name">Name</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="taxRate"
                                            name="taxRate"
                                            defaultValue={jobTax.taxRate}
                                            placeholder="Tax Rate %" />
                                        <label for="taxRate">Tax Rate %</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <div className="form-floating mb-3">
                                        <select className="form-select" onChange={handleSelectTax} value={selectedTax} id="floatingSelect" aria-label="Is default tax rate?">
                                            <option value={true}>Use this rate as default</option>
                                            <option value={false}>Don't use this rate as default</option>
                                        </select>
                                        <label htmlFor="floatingSelect">Is default tax rate?</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <div className="form-floating">
                                        <textarea
                                            className="form-control"
                                            placeholder="Description"
                                            name="description"
                                            id="description"
                                            defaultValue={jobTax.description}
                                            style={{ height: "100px" }} >
                                        </textarea>
                                        <label for="description">Description</label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center pt-3">
                                <button Source="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.ADMINTAX} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {updateJobTaxResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminEditTaxCompontent;