import axios from "axios";
import { useDispatch } from "react-redux";
import AdminCreateTaxComponent from "./components/adminNewJobTaxComponent";

const createJobTaxApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/jobTax/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};
const AdminCreateJobTaxPage = () => {
    const dispatch = useDispatch();

    return (
        <AdminCreateTaxComponent
            createJobTaxApiRequest={createJobTaxApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreateJobTaxPage;