import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import paths from "../../../router/paths";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import AdminDeletePopUpComponent from '../../../components/admin/adminDeletePopUpComponent';

const AdminJobTaxComponent = ({ fetchJobTax, fetchCompany, deleteJobTax }) => {
    const [jobTax, setJobTax] = useState([]);
    const auth = useSelector(state => state.auth)
    const [jobTaxDeleted, setJobTaxDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [companies, setCompanies] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [taxId, setTaxId] = useState("");

    const handleConfirmRemove = async () => {
        const data = await deleteJobTax(taxId);
        if (data.message === 'Job tax deleted successfully') {
            setJobTaxDeleted(!jobTaxDeleted)
            setShowConfirmation(false);
            setIsLoading(true);
        }
    }

    const deleteHandler = (taxId) => {
        setTaxId(taxId);
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchJobTax(abctrl)
            .then((res) => {
                setJobTax(res);
                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        fetchCompany()
            .then((res) => {
                setCompanies(res);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );


        return () => abctrl.abort();
    }, [deleteJobTax, fetchJobTax, fetchCompany]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchJobTax(abctrl)
                .then((res) => {
                    setJobTax(res);
                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );

            fetchCompany()
                .then((res) => {
                    setCompanies(res);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }

        return () => abctrl.abort();
    }, [deleteJobTax, isLoading, fetchJobTax, fetchCompany]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };

    return (
        <div className="min-vh-100">
            {showConfirmation && (
                <AdminDeletePopUpComponent
                    title='Delete this tax info?'
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancel}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <AdminLinksComponent />
                </div>
                <div className="col-sm-10 mt-5 pt-5">
                    <div className="row mb-3">
                        <div className="col-sm-8">
                            <h1>Job Tax List</h1>
                        </div>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                className="mt-2 input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Job Taxs.."
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1">
                            <Link to={paths.ADMINNEWTAX} className="btn btn-outline-primary loginButton">New</Link>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <table className="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">Name</th>
                                    <th scope="col" className="text-center">Tax Rate</th>
                                    <th scope="col" className="text-center">Default Rate</th>
                                    <th scope="col" className="text-center">Edit/Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jobTax
                                    .filter(
                                        (jobTaxs) => {
                                            const userCompany = auth.user.company || "";
                                            const associatedCompany = companies.find((company) => company._id === jobTaxs.company);
                                            const companyName = associatedCompany ? associatedCompany.name : 'Unknown Company';
                                            return (
                                                (jobTaxs.company.toLowerCase() === userCompany.toLowerCase()) &&
                                                (companyName.toLowerCase().indexOf(searchValue) !== -1 ||
                                                    jobTaxs.name.toLowerCase().indexOf(searchValue) !== -1)
                                            );
                                        })
                                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                    .map((jobTaxs, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td className="text-center">{jobTaxs.name}</td>
                                                <td className="text-center">{jobTaxs.taxRate}%</td>
                                                <td className="text-center">
                                                    {jobTaxs.defaultTax === true ? (
                                                        <span>Yes</span>
                                                    ) : (<span>No</span>)} </td>
                                                <td className="text-center">
                                                    <Link to={`/admin/edit-tax/${jobTaxs._id}`}>
                                                        <button type="button" className="btn-sm btn btn-outline-primary">
                                                            <GoPencil />
                                                        </button>
                                                    </Link>
                                                    {" / "}
                                                    <button
                                                        type="button"
                                                        className="btn-sm btn btn-outline-danger"
                                                        onClick={() => deleteHandler(jobTaxs._id)}
                                                    >
                                                        <BsTrash />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )}
                            </tbody>
                        </table>
                    )}
                    <div className="d-flex justify-content-center pb-4">

                        <button
                            type="button"
                            className="btn-sm btn btn-outline-primary mr-2 me-3 loginButton"
                            disabled={page === 1}
                            onClick={handlePrevPage}
                        >
                            Previous
                        </button>
                        <span className="mr-2 me-3">
                            Page {page} of {Math.ceil(jobTax
                                .filter(
                                    (jobTaxs) => {
                                        const userCompany = auth.user.company || "";
                                        const associatedCompany = companies.find((company) => company._id === jobTaxs.company);
                                        const companyName = associatedCompany ? associatedCompany.name : 'Unknown Company';
                                        return (
                                            (jobTaxs.company.toLowerCase() === userCompany.toLowerCase()) &&
                                            (companyName.toLowerCase().indexOf(searchValue) !== -1 ||
                                                jobTaxs.name.toLowerCase().indexOf(searchValue) !== -1)
                                        );
                                    }).length / rowsPerPage)}
                        </span>
                        <button
                            type="button"
                            className="btn-sm btn btn-outline-primary loginButton"
                            disabled={jobTax
                                .filter(
                                    (jobTaxs) => {
                                        const userCompany = auth.user.company || "";
                                        const associatedCompany = companies.find((company) => company._id === jobTaxs.company);
                                        const companyName = associatedCompany ? associatedCompany.name : 'Unknown Company';
                                        return (
                                            (jobTaxs.company.toLowerCase() === userCompany.toLowerCase()) &&
                                            (companyName.toLowerCase().indexOf(searchValue) !== -1 ||
                                                jobTaxs.name.toLowerCase().indexOf(searchValue) !== -1)
                                        );
                                    }).length <= page * rowsPerPage}
                            onClick={handleNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminJobTaxComponent;