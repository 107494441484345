import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';

const RowPrintComponent = ({ selectedLocation, inventory }) => {
    const componentRef = useRef(null);

    let ourCost = "-";
    let ourName = "";
    let ourLocation = "";

    if (inventory) {
        if (selectedLocation && inventory.InventoryLocation) {
            const locationData = inventory.InventoryLocation.find(
                (location) => location.name1 === selectedLocation
            );

            if (locationData) {
                ourCost = Number(locationData.ourCost1).toFixed(2);
                ourName = inventory.name;
                ourLocation = locationData.location1;
            }
        }
    }
    return (
        <>
            <ReactToPrint
                trigger={() => <button type="button" className="btn btn-sm btn-outline-primary">Print</button>}
                content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
                <div ref={componentRef} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ fontSize: '12px' }}>{ourLocation} {ourName}<br /> ${Number(ourCost).toFixed(2)}</div>
                    <div>
                        <Barcode value={inventory.barcode} fontSize={12} format="CODE128" width={2} height={35} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default RowPrintComponent;
