import React from "react";
import axios from "axios";

const ChangeEmailSettingsPopComponent = ({ setSmtpSettings, smtpSettings, companyId, onConfirm, onCancel }) => {
  
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(`/api/company/${companyId}/smtp`, { ...smtpSettings });

            if (response.data.message === 'Company updated') {
                onConfirm();
            }
        } catch (error) {
            console.error('Error updating SMTP settings:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSmtpSettings({
            ...smtpSettings,
            [name]: value,
        });
    };

    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel}
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder deleteWidth mb-4 ">
                        <div className="col-sm fs-5">
                            Update Email Settings
                        </div>
                    </div>
                    <form className="p-4" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control signinFormInput"
                                id="smtpName"
                                placeholder="Name to send it as..."
                                name="name"
                                value={smtpSettings.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control signinFormInput"
                                id="smtpHost"
                                placeholder="SMTP Host"
                                name="host"
                                value={smtpSettings.host}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="number"
                                className="form-control signinFormInput"
                                placeholder="SMTP Port"
                                id="smtpPort"
                                name="port"
                                value={smtpSettings.port}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="SMTP User"
                                className="form-control signinFormInput"
                                id="smtpUser"
                                name="user"
                                value={smtpSettings.user}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="password"
                                placeholder="SMTP Password"
                                className="form-control signinFormInput"
                                id="smtpPass"
                                name="pass"
                                value={smtpSettings.pass}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="row deleteWidth bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                            <div className="col-sm">
                                <button className="btn btn-outline-success loginButton" type="submit">
                                    Update Settings
                                </button>
                            </div>
                            <div className="col-sm">
                                <button className="btn btn-outline-primary ps-5 pe-5 loginButton" onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ChangeEmailSettingsPopComponent;