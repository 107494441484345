import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TechSelectComponent from "../../../components/dispatch/techSelectComponent";
import TimeSelectComponent from "../../../components/dispatch/timeSelectComponent";
import DateSelectComponent from "../../../components/dispatch/dateSelectComponent";
import JobSourceSelectComponent from "../../../components/dispatch/jobSourceSelectComponent";
import JobTypeSelectComponent from "../../../components/dispatch/jobTypeSelectComponent";
import ClientJobInfoComponent from "../../../components/dispatch/clientJobComponent";
import { FaRegThumbsUp } from 'react-icons/fa';
import JobTagDropdown from "../../../components/dispatch/jobTagDropdown";
import '../../../components/assets/dispatchJobCss.css';
import { BsTrash } from 'react-icons/bs';
import { AiOutlineOrderedList } from 'react-icons/ai';
import DeleteConfirmationModal from "../../../components/dispatch/deleteConfirmationComponent";
import paths from "../../../router/paths";
import JobItemPanelComponent from "../../../components/dispatch/jobItemPanelComponent";
import FileUploadComponent from "../../../components/dispatch/fileUploadComponent";
import EstimateTabComponent from "../../../components/dispatch/estimateTabComponent";
import axios from 'axios';
import { WorkOrderPdfGenerator } from "../../../components/dispatch/workOrderPdfGenerator";
import PaymentTabComponent from "../../../components/dispatch/paymentTabComponent";

const DispatchJobComponent = ({
    fetchDispatch,
    deleteDispatch,
    fetchEstimates,
    updateSchedule,
    createInvoiceApi,
    updateDispatchTotal,
    updateInvoiceDiscount,
    fetchPayments,
    deleteEstimate,
    fetchPaymentAll,
    updateDispatchTechsApiRequest,
    deleteJobItem,
    fetchUsers,
    updatePaymentTotal,
    updateJobItemsApiRequest,
    createJobItemApiRequest,
    updateDispatchEstimatesApiRequest,
    deleteSchedule,
    getJobTaxById,
    fetchJobType,
    updateDispatchDiscountApiRequest,
    updateDispatchStatusApiRequest,
    fetchJobSource,
    createEstimateAPI,
    updateDispatchItemApiRequest,
    fetchTechInfo,
    updateDispatchTagsApiRequest,
    updateDispatchApiRequest,
    fetchCustomer,
    fetchJobTax,
    updateScheduleTags,
    fetchUploads,
    updateScheduleTime,
    updateDispatchTaxApiRequest,
    fetchJobItem,
    updateDispatchInvoiceApiRequest,
    updateCustomerApiRequest,
    deletePayment,
    fetchJobTag
}) => {
    const [rowCount, setRowCount] = useState(0);
    const [estimateRowCount, setEstimateRowCount] = useState(0);
    const [totalPay, setTotalPay] = useState('0.00');
    const [dispatchDeleted, setDispatchDeleted] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedAddress, setSelectedAddress] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedZip, setSelectedZip] = useState("");
    const [selectedPhoneExt, setSelectedPhoneExt] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedTimeStart, setSelectedTimeStart] = useState("");
    const [selectedTimeEnd, setSelectedTimeEnd] = useState("");
    const [firstName, setFirstName] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionText, setDescriptionText] = useState("");
    const [lastNameText, setLastNameText] = useState("");
    const [companyClientText, setCompanyText] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);
    const [showAlertFailMessage, setShowAlertFailMessage] = useState('');
    const [showAlert1, setShowAlert1] = useState(false);
    const [email, setEmail] = useState('');
    const [jobTag, setJobTag] = useState([]);
    const [jobTagData, setJobTagData] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateEnd, setSelectedDateEnd] = useState(null);
    const [customers, setCustomer] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [jobType, setJobType] = useState([]);
    const [dispatching, setDispatch] = useState([]);
    const [jobSource, setJobSource] = useState([]);
    const [invoice, setInvoice] = useState([]);
    const [validated, setValidated] = useState('false');
    const [totalPrice, setTotalPrice] = useState(0.00);
    const [companies, setCompanies] = useState([]);
    const [jobItem, setJobItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const [jobLoading, setJobLoading] = useState(false);
    const [techs, setTechs] = useState([]);
    const [uploadedLogo, setUploadedLogo] = useState(null);

    const auth = useSelector(state => state.auth)
    const [createCustomerResponseState, setCreateCustomerResponseState] =
        useState({
            message: "",
            error: ""
        });
    const [createDispatchResponseState, setCreateDispatchResponseState] =
        useState({
            message: "",
            error: ""
        });
    const { id } = useParams();
    const [selectedJobTypeName, setSelectedJobTypeName] = useState("");
    const [selectedJobType, setSelectedJobType] = useState("");
    const [selectedJobSource, setSelectedJobSource] = useState("");

    const dispatch = useDispatch();
    const selectRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const abctrl = new AbortController();

        const fetchData = async () => {
            try {
                const data = await fetchDispatch(id);

                setDispatch(data);
                setSelectedJobType(data.jobType);
                setSelectedJobSource(data.lead);
                setDescription(data.description);
                setCompanyText(data.companyClient);
                setSelectedCountry(data.country);
                setSelectedState(data.state);
                setSelectedOption(data.status);
                setJobTagData(data.jobTags);
                setTechs(data.techs);
                setInvoice(data.invoice);
                setSchedule(data.schedule);
                setCustomer(data.customer);
                setCompanies(data.companyPull);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchJobSourceData = async () => {

            try {
                const [jobSourceData, jobTypeData, jobItemData, jobTagData, uploadLogo] = await Promise.all([
                    fetchJobSource(),
                    fetchJobType(),
                    fetchJobItem(),
                    fetchJobTag(),
                    fetchUploads()
                ]);

                setJobType(jobTypeData);
                setJobSource(jobSourceData);
                setJobTag(jobTagData);

                const filteredUploads = uploadLogo.filter((item) => auth.user.company === item.company);
                const filteredUploadsFin = filteredUploads.find((item) => item.companyLogo === true);

                if (filteredUploadsFin) {
                    setUploadedLogo(filteredUploadsFin);
                } else {
                    setUploadedLogo(null);
                }

                const dispatchData = await fetchDispatch(id);

                const jobItemIdSet = new Set(dispatchData.jobItems);
                const filteredJobItems = jobItemData.filter(item => jobItemIdSet.has(item._id));

                setJobItem(filteredJobItems);

                const filteredJobType = jobTypeData.find((item) => item._id === dispatchData.jobType);
                setSelectedJobTypeName(filteredJobType);

                setLoading(false);
            } catch (error) {
                console.error("Error fetching job source:", error);
            }
        };

        const executeFetches = async () => {
            await fetchData();
            await fetchJobSourceData();
        };

        executeFetches();

        return () => abctrl.abort();
    }, [dispatch, fetchDispatch, fetchJobSource, id, auth.user.company, fetchJobItem, fetchJobTag, fetchJobType, fetchUploads]);

    useEffect(() => {
        const abctrl = new AbortController();

        const fetchData = async () => {
            try {
                const data = await fetchDispatch(id);

                setDispatch(data);
                setSelectedJobType(data.jobType);
                setSelectedJobSource(data.lead);
                setDescription(data.description);
                setCompanyText(data.companyClient);
                setSelectedCountry(data.country);
                setSelectedState(data.state);
                setSelectedOption(data.status);
                setJobTagData(data.jobTags);
                setTechs(data.techs);
                setInvoice(data.invoice);
                setSchedule(data.schedule);
                setCustomer(data.customer);
                setJobTag(data.jobTagAll);
                setCompanies(data.companyPull);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchJobSourceData = async () => {

            try {
                const [jobSourceData, jobTypeData, jobItemData, jobTagData, uploadLogo] = await Promise.all([
                    fetchJobSource(),
                    fetchJobType(),
                    fetchJobItem(),
                    fetchJobTag(),
                    fetchUploads()
                ]);

                setJobType(jobTypeData);
                setJobSource(jobSourceData);
                setJobTag(jobTagData);

                const filteredUploads = uploadLogo.filter((item) => auth.user.company === item.company);
                const filteredUploadsFin = filteredUploads.find((item) => item.companyLogo === true);

                if (filteredUploadsFin) {
                    setUploadedLogo(filteredUploadsFin);
                } else {
                    setUploadedLogo(null);
                }

                const dispatchData = await fetchDispatch(id);

                const jobItemIdSet = new Set(dispatchData.jobItems);
                const filteredJobItems = jobItemData.filter(item => jobItemIdSet.has(item._id));

                setJobItem(filteredJobItems);

                const filteredJobType = jobTypeData.find((item) => item._id === dispatchData.jobType);
                setSelectedJobTypeName(filteredJobType);

                setLoading(false);
            } catch (error) {
                console.error("Error fetching job source:", error);
            }
        };

        const executeFetches = async () => {
            await fetchData();
            await fetchJobSourceData();
        };

        if (loading) {
            executeFetches();
        }

        return () => abctrl.abort();
    }, [dispatch, fetchDispatch, fetchJobSource, id, auth.user.company, loading, fetchJobItem, fetchJobTag, fetchJobType, fetchUploads]);
    function formatDateTimePDF(dateTime) {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };

        return new Date(dateTime).toLocaleDateString(undefined, options);
    }

    function formatDateTime(selectedDate, selectedTimeStart, selectedDateEnd, selectedTimeEnd) {
        const startTimeParts = selectedTimeStart.match(/(\d+):(\d+)\s(AM|PM)/);
        const endTimeParts = selectedTimeEnd.match(/(\d+):(\d+)\s(AM|PM)/);

        if (!startTimeParts || !endTimeParts) {
            return "Invalid time format";
        }

        let startHour = parseInt(startTimeParts[1], 10);
        const startMinute = startTimeParts[2];
        const startMeridian = startTimeParts[3];

        let endHour = parseInt(endTimeParts[1], 10);
        const endMinute = endTimeParts[2];
        const endMeridian = endTimeParts[3];

        if (startMeridian === "PM" && startHour !== 12) {
            startHour += 12;
        } else if (startMeridian === "AM" && startHour === 12) {
            startHour = 0;
        }

        if (endMeridian === "PM" && endHour !== 12) {
            endHour += 12;
        } else if (endMeridian === "AM" && endHour === 12) {
            endHour = 0;
        }

        const startDate = new Date(selectedDate);
        startDate.setHours(startHour, startMinute, 0, 0);

        const endDate = new Date(selectedDateEnd);
        endDate.setHours(endHour, endMinute, 0, 0);

        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        return `${formattedStartDate.substring(0, 23)}+00:00 - ${formattedEndDate.substring(0, 23)}+00:00`;
    }
    const formattedDateTime = formatDateTime(selectedDate, selectedTimeStart, selectedDateEnd, selectedTimeEnd);
    const [startDateTime, endDateTime] = formattedDateTime.split(" - ");

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formInputs = {
            name: firstName,
            lastName: lastNameText,
            address: selectedAddress,
            city: selectedCity,
            state: selectedState,
            zip: selectedZip,
            phoneExt: selectedPhoneExt,
            phone: phoneNumber,
            email: email,
            companyClient: companyClientText,
            company: auth.user.company,
            country: selectedCountry,
        };

        const formInputsDis = {
            make: dispatching.make,
            model: dispatching.model,
            year: dispatching.year,
            description: descriptionText,
            jobType: selectedJobType,
            lead: selectedJobSource,
            status: "Submitted",
            techs: dispatching.techs || '',
            count: dispatching.count,
            customer: dispatching.customer,
            user: auth.user._id,
            company: auth.user.company
        };

        const updatedTimeData = {
            techs: techs,
            start: startDateTime,
            end: endDateTime,
        }
        if (event.currentTarget.checkValidity() === true) {

            updateCustomerApiRequest(customers._id, formInputs)
                .then((data) => {
                    if (data.message === "Customer updated");
                })
                .catch((er) => {
                    setCreateCustomerResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data
                    });
                });
            updateDispatchApiRequest(id, formInputsDis)
                .then((data) => {
                    if (data.message === "Dispatch updated") {
                        updateScheduleTime(schedule._id, updatedTimeData)
                            .then((data) => {
                                if (data.message === "time updated") {
                                    setShowAlert(true);
                                    setTimeout(() => {
                                        setShowAlert(false);
                                    }, 3000);
                                }
                            })
                            .catch((er) => {
                                setCreateDispatchResponseState({
                                    error: er.response.data.message
                                        ? er.response.data.message
                                        : er.response.data
                                });
                            });
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data
                    });
                });
        }
        setValidated(true);
    };

    const tabLinks = document.querySelectorAll(".nav-link1");

    tabLinks.forEach((tabLink) => {
        tabLink.addEventListener("click", (event) => {
            event.preventDefault(); // Prevent default link behavior
            const target = event.target;
            const tabId = target.getAttribute("href"); // Get the target tab content ID
            const tabContent = document.querySelector(tabId);

            if (tabContent) {
                tabLinks.forEach((link) => link.classList.remove("active"));
                document
                    .querySelectorAll(".tab-pane")
                    .forEach((content) => content.classList.remove("show", "active"));

                target.classList.add("active");
                tabContent.classList.add("show", "active");
            }
        });
    });


    const handleDescriptonChange = (event) => {
        const newValue = event.target.value;
        setDescriptionText(newValue);
    };
    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [showAlert]);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleCloseAlertFail = () => {
        setShowAlertFail(false);
        setShowAlertFailMessage('')
    };

    function getBase64FromBlob(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    const handleGeneratePDF = async () => {
        try {
            const data = await fetchJobTax();
            const filterJobTax = data.find((item) => item._id === dispatching.tax);

            if (!filterJobTax) {
                console.error("Job tax not found:", dispatching.tax);
                return;
            }

            let imgData = null;
            if (uploadedLogo) {
                try {
                    const response = await axios.get(`/api/uploads/download/${uploadedLogo.file}`, { responseType: 'blob' });
                    const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
                    imgData = await getBase64FromBlob(blob);
                } catch (error) {
                    console.error('Error fetching image:', error);
                    // Optionally handle the error (e.g., show a placeholder image)
                }
            }

            WorkOrderPdfGenerator(
                filterJobTax,
                companies,
                imgData,
                formatDateTimePDF,
                dispatching,
                customers,
                jobItem,
            );
        } catch (error) {
            console.error("Error fetching or processing job tax:", error);
        }
    };

    const handleJobDone = () => {
        if (selectRef.current) {
            selectRef.current.value = "Done";
            selectRef.current.dispatchEvent(new Event("change", { bubbles: true }));
        }
    };

    const handleSelectChange = (event) => {
        const newSelectedOption = event.target.value;

        if (dispatching.techs && dispatching.techs.length === 0) {
            setShowAlertFailMessage('Please select a technician first.')
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
            }, 3000);
        } else {

            setSelectedOption(newSelectedOption);

            const formInputsOption = {
                status: newSelectedOption,
                count: dispatching.count,
            };
            const updatedEventData = {
                status: newSelectedOption,
                live: newSelectedOption !== "Done",
                deleted: newSelectedOption === "Canceled",
            };
            updateDispatchStatusApiRequest(id, formInputsOption)
                .then((data) => {
                    if (data.message === "Dispatch Status updated") {
                        setShowAlert1(true);
                        setTimeout(() => {
                            setShowAlert1(false);
                        }, 3000);
                    }
                    updateSchedule(dispatching.schedule._id, updatedEventData)
                        .then((response) => {
                            // Handle success if needed
                        })
                        .catch((error) => {
                            // Handle error if needed
                            console.error("Error updating event:", error);
                        });
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data
                    });
                });
        }
    };
    const handleRemoveJob = () => {
        setShowConfirmation(true);
    };
    const handleCancelRemoveJob = () => {
        setShowConfirmation(false);
    };
    const handleConfirmRemoveJob = () => {
        const formInputsSchedule = {
            deleted: true,
        };
        deleteDispatch(id)
            .then((data) => {
                if (data.message === 'Dispatch deleted successfully') {
                    deleteSchedule(dispatching.schedule, formInputsSchedule)
                        .then((data) => {
                            if (data.message === 'schedule deleted') {
                                setDispatchDeleted(!dispatchDeleted)
                                navigate(`${paths.DISPATCHPANELJOB}`)
                            }
                        });
                }
            });
        setShowConfirmation(false);
    };

    const handleSubmitClickInvoice = () => {
        const formInputs = {
            jobId: id,
            customer: dispatching.customer,
            status: false,
            total: 0,
            dueDate: new Date(),
            due: dispatching.total || 0,
            jobItems: dispatching.jobItem,
            discount: dispatching.discount || 0,
            discountType: dispatching.discountType || 'Amount',
            tax: dispatching.tax || '',
            company: auth.user.company,
        };

        createInvoiceApi(formInputs)
            .then((data) => {
                if (data.message === "invoice saved") {
                    const newInvoiceId = data._id;
                    const formInputsInv = {
                        ...formInputs,
                        invoice: newInvoiceId,
                    };
                    updateDispatchInvoiceApiRequest(id, formInputsInv)
                        .then((data) => {
                            if (data.message === "invoice updated") {
                                navigate(`/job-invoice/${data.invoice}`);
                            }
                        })
                        .catch((er) => {
                            setCreateDispatchResponseState({
                                error: er.response.data.message
                                    ? er.response.data.message
                                    : er.response.data
                            });
                        });
                }
            })
    };

    return (
        <>
            <div className="container-fluid p-0 mb-5 pb-5 w-100">
                {showAlert && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Job Updated
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlertFail && (
                    <div className="alertRed alert-danger" role="alert">
                        {showAlertFailMessage}
                        <div className="alert-line-container">
                            <div className="alert-lineRed"></div>
                            <span className="alert-closeRed" onClick={handleCloseAlertFail}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showConfirmation && (
                    <DeleteConfirmationModal
                        message={`Are you sure you want to remove this tag?`}
                        onConfirm={handleConfirmRemoveJob}
                        onCancel={handleCancelRemoveJob}
                    />
                )}
                {showAlert1 && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Status Updated
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                <div className="row pt-5 bg-light">
                    <div className="col-sm-5 ps-5">
                        <span className="text-dark fs-5 pb-3">
                            Job #{dispatching.count} - {customers.name} {customers.lastName}
                        </span>
                    </div>
                    <div className="col-sm-5 p-0 m-0"></div>
                    <div className="col-sm-2 m-0 p-0">
                        <div className="btn-group me-3">
                            <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                                Actions
                            </button>
                            <ul className="dropdown-menu dropdown-menu-lg-end">
                                <li className="dropdown-border"><Link className="dropdown-item" type="button" onClick={handleJobDone}><FaRegThumbsUp className="ThumbsupActions me-2" /> Job Done</Link></li>
                                <li className="dropdown-border"><Link className="dropdown-item" type="button" onClick={() => handleGeneratePDF()}><AiOutlineOrderedList className="ThumbsupActions me-2" />View Work Order</Link></li>
                                <li className="dropdown-border"><Link className="dropdown-item" type="button" onClick={() => handleRemoveJob()}><BsTrash className="ThumbsupActions me-2" />Delete Job</Link></li>
                            </ul>
                        </div>
                        {invoice && invoice.jobId ? (
                            <Link className="btn btn-outline-primary" to={`/job-invoice/${invoice._id}`}>View Invoice</Link>
                        ) : (
                            <button className="btn btn-outline-primary" onClick={() => handleSubmitClickInvoice()}>Create Invoice</button>
                        )}
                    </div>
                    <div className="row ps-5">
                        <div className="form-group mt-4">
                            <label htmlFor="selectStatus" className="form-label me-3">Status:</label>
                            <div className="col-sm-2 col-md-2">
                                <select
                                    id="selectStatus"
                                    className="form-select form-select-sm"
                                    aria-label="Default select example"
                                    onChange={handleSelectChange}
                                    value={selectedOption}
                                    ref={selectRef}
                                >
                                    <option value="Submitted">Submitted</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Open Quote">Open Quote</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Pending Payment">Pending Payment</option>
                                    <option value="Done">Done</option>
                                    <option value="Done Pending Parts">Done Pending Parts</option>
                                    <option value="Canceled">Canceled</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row ps-5 pt-2 mb-5">
                        <div className="col-sm-12 ps-4 pt-1">
                            <JobTagDropdown dispatching={dispatching} updateScheduleTags={updateScheduleTags} jobTagData={jobTagData} id={id} updateDispatchTagsApiRequest={updateDispatchTagsApiRequest} setJobTag={setJobTag} jobTag={jobTag} />
                        </div>
                    </div>

                </div>
                <div className="container-grid">
                    <div className="custom-tabs m-0 p-0">
                        <ul className="nav nav-tabs" id="myTabs" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="text-dark nav-link1 nav-link active"
                                    id="details-tab"
                                    href="#details"
                                >
                                    <span className="fw-bold">Details </span>
                                    <br /> <span className="fs-6">{selectedJobTypeName.name || 'Fetching Job Info....'}</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="text-dark nav-link1 nav-link"
                                    id="items-tab"
                                    href="#items"
                                >
                                    Items
                                    <br />
                                    {totalPrice > 0 ? (
                                        <span className="fs-6">
                                            ${totalPrice}
                                        </span>
                                    ) : (
                                        <span className="fs-6">
                                            $0.00
                                        </span>
                                    )}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="text-dark nav-link1 nav-link"
                                    id="payments-tab"
                                    href="#payments"
                                >
                                    Payments
                                    <br /> <span className="fs-6">
                                        ${totalPay} balance
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="text-dark nav-link1 nav-link"
                                    id="estimates-tab"
                                    href="#estimates"
                                >
                                    Estimates<br />
                                    <span className="fs-6">
                                        {estimateRowCount} estimates
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="text-dark nav-link1 nav-link"
                                    id="files-tab"
                                    href="#files"
                                >
                                    Files<br />
                                    <span className="fs-6">
                                        {rowCount} files
                                    </span>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane row fade show active" id="details">
                                <div
                                    className="d-flex flex-column form-container"
                                    style={{ minHeight: "100vh" }}
                                >
                                    <form
                                        noValidate
                                        validated={validated.toString()}
                                        onSubmit={handleSubmit}
                                        style={{ display: "flex", justifyContent: "center" }}
                                        className="row g-2"
                                    >
                                        <ClientJobInfoComponent
                                            setSelectedPhoneExt={setSelectedPhoneExt}
                                            selectedPhoneExt={selectedPhoneExt}
                                            setEmail={setEmail}
                                            email={email}
                                            firstName={firstName}
                                            lastNameText={lastNameText}
                                            setPhoneNumber={setPhoneNumber}
                                            phoneNumber={phoneNumber}
                                            setCompanyText={setCompanyText}
                                            setLastNameText={setLastNameText}
                                            selectedCountry={selectedCountry}
                                            setSelectedCountry={setSelectedCountry}
                                            companyClientText={companyClientText}
                                            customers={customers}
                                            setFirstName={setFirstName}
                                            setSelectedAddress={setSelectedAddress}
                                            selectedAddress={selectedAddress}
                                            setSelectedCity={setSelectedCity}
                                            selectedCity={selectedCity}
                                            setSelectedState={setSelectedState}
                                            selectedState={selectedState}
                                            setSelectedZip={setSelectedZip}
                                            selectedZip={selectedZip}
                                        />
                                        <div className="col-sm-1" />
                                        <div className="col-sm-4 mt-4 p-3">
                                            <h4>Schedule</h4>
                                            <hr />
                                            <div className="row mt-3">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <DateSelectComponent
                                                            schedule={schedule}
                                                            setSelectedDate={setSelectedDate}
                                                            setSelectedDateEnd={setSelectedDateEnd}
                                                            selectedDate={selectedDate}
                                                            selectedDateEnd={selectedDateEnd}
                                                        />
                                                        <TimeSelectComponent
                                                            schedule={schedule}
                                                            setSelectedTimeStart={setSelectedTimeStart}
                                                            setSelectedTimeEnd={setSelectedTimeEnd}
                                                            selectedTimeStart={selectedTimeStart}
                                                            selectedTimeEnd={selectedTimeEnd}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <div className="col-sm-4 mt-5 p-3">
                                                <h4>Job</h4>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <>
                                                            <JobTypeSelectComponent
                                                                jobType={jobType}
                                                                selectedJobType={selectedJobType}
                                                                setSelectedJobType={setSelectedJobType}
                                                                selectedJobTypeName={selectedJobTypeName}
                                                                setJobType={setJobType}
                                                            />
                                                            <JobSourceSelectComponent
                                                                setSelectedJobSource={setSelectedJobSource}
                                                                selectedJobSource={selectedJobSource}
                                                                jobSource={jobSource}
                                                            />
                                                            <textarea
                                                                placeholder="Job Description"
                                                                id="description"
                                                                name="description"
                                                                className="form-control mt-3"
                                                                defaultValue={description}
                                                                onChange={handleDescriptonChange}
                                                                rows="3"
                                                            ></textarea>
                                                        </>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-1" />
                                            <div className="col-sm-4 mt-5 p-3">
                                                <h4>Team</h4>
                                                <hr />
                                                <div className="row mt-3">
                                                    <TechSelectComponent fetchJobType={fetchJobType} fetchJobSource={fetchJobSource} fetchCustomer={fetchCustomer} fetchDispatch={fetchDispatch} id={id} updateDispatchTechsApiRequest={updateDispatchTechsApiRequest} fetchUsers={fetchUsers} fetchTechInfo={fetchTechInfo} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center fixed-bottom pt-3 pb-4 bg-light">
                                            <button type="submit" className="btn btn-outline-primary">
                                                Save
                                            </button>
                                        </div>
                                        {createCustomerResponseState.error ?? ""}
                                        {createDispatchResponseState.error ?? ""}
                                    </form>
                                </div>
                            </div>
                            <div className="tab-pane row fade" id="items">
                                <JobItemPanelComponent totalPay={totalPay} setJobLoading={setJobLoading} totalPrice={totalPrice} fetchPayments={fetchPayments} updatePaymentTotal={updatePaymentTotal} updateInvoiceDiscount={updateInvoiceDiscount} updateDispatchTotal={updateDispatchTotal} updateJobItemsApiRequest={updateJobItemsApiRequest} createJobItemApiRequest={createJobItemApiRequest} deleteJobItem={deleteJobItem} setTotalPrice={setTotalPrice} getJobTaxById={getJobTaxById} updateDispatchDiscountApiRequest={updateDispatchDiscountApiRequest} updateDispatchTaxApiRequest={updateDispatchTaxApiRequest} fetchJobTax={fetchJobTax} fetchDispatch={fetchDispatch} id={id} fetchJobItem={fetchJobItem} dispatching={dispatching} updateDispatchItemApiRequest={updateDispatchItemApiRequest} />
                            </div>
                            <div className="tab-pane fade" id="payments">
                                <PaymentTabComponent fetchJobItem={fetchJobItem} deletePayment={deletePayment} fetchDispatch={fetchDispatch} jobLoading={jobLoading} setJobLoading={setJobLoading} fetchPaymentAll={fetchPaymentAll} setCompanies={setCompanies} companies={companies} setTotalPay={setTotalPay} fetchPayments={fetchPayments} />
                            </div>
                            <div className="tab-pane fade" id="estimates">
                                <EstimateTabComponent deleteEstimate={deleteEstimate} setEstimateRowCount={setEstimateRowCount} fetchEstimates={fetchEstimates} id={id} updateDispatchEstimatesApiRequest={updateDispatchEstimatesApiRequest} dispatching={dispatching} createEstimateAPI={createEstimateAPI} />
                            </div>
                            <div className="tab-pane fade" id="files">
                                <FileUploadComponent setRowCount={setRowCount} id={id} fetchUploads={fetchUploads} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DispatchJobComponent;
