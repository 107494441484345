import { useSelector } from "react-redux";
import paths from "../../router/paths";
import { Link } from "react-router-dom";

const AdminLinksComponent = () => {
    const auth = useSelector(state => state.auth)
    const isDeveloper = auth.user?.usertype?.includes('Developer') || false;

    return (
        <nav className="navbar navbar-light gray-color p-0 m-0 navLinksnavbar">
            <ul className="nav flex-column m-0 pe-3 pb-3 p-0">
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.ADMINROOTUSERS}>
                        Users
                    </Link>
                </li>
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINDISCOUNTCODES}>
                            Discount Codes
                        </Link>
                    </li>
                )}
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINVINPRICE}>
                            Key Code Prices
                        </Link>
                    </li>
                )}
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMININSTRUCTION}>
                            Instructions
                        </Link>
                    </li>
                )}
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINCODEREQUESTS}>
                            Code Requests
                        </Link>
                    </li>
                )}
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINCODES}>
                            Codes
                        </Link>
                    </li>
                )}
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINMAKE}>
                            Vehicle Makes
                        </Link>
                    </li>
                )}
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINMODEL}>
                            Vehicle Model
                        </Link>
                    </li>
                )}
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINYEAR}>
                            Vehicle Year
                        </Link>
                    </li>
                )}
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINCOMPANY}>
                            Company Info
                        </Link>
                    </li>
                )}
                {isDeveloper && (
                    <li className="nav-item mb-2">
                        <Link className="underline" to={paths.ADMINLOCKSMITHINFO}>
                            Locksmith Info
                        </Link>
                    </li>
                )}
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.ADMINLOCATION}>
                        Company Locations
                    </Link>
                </li>
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.ADMINTAX}>
                        Job Tax
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default AdminLinksComponent;