import paths from "../../router/paths";
import { Link } from "react-router-dom";

const ProfileLinksComponent = () => {

    return (
        <nav className="navbar navbar-light gray-color p-0 m-0 navLinksnavbar">
            <ul className="nav text-start flex-column pb-3 p-0">
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.USERPROFILE}>
                        My Profile Info
                    </Link>
                </li>
                <li className="nav-item mb-2">
                    <Link className="underline" to={paths.USERCOMPANY}>
                        Company Info
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default ProfileLinksComponent;