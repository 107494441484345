import axios from 'axios'
import { useDispatch } from "react-redux";
import EditJobTagComponent from './components/editJobTagComponent';

const fetchJobTag = async (jobTagId) => {
    const { data } = await axios.get(`/api/jobTag/get-one/${jobTagId}`, { withCredentials: true });
    return data;
}

const updateJobTagApiRequest = async (jobTagId, formInputs) => {
    const { data } = await axios.put(`/api/jobTag/dispatch/${jobTagId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const EditJobTagPage = () => {

    const reduxDispatch = useDispatch();


    return <EditJobTagComponent fetchJobTag={fetchJobTag} updateJobTagApiRequest={updateJobTagApiRequest} reduxDispatch={reduxDispatch} />;
};

export default EditJobTagPage;
