import axios from 'axios'
import { useDispatch } from "react-redux";
import EditCompanyComponent from './components/adminEditCompanyComponent';

const fetchCompany = async (companyId) => {
    const { data } = await axios.get(`/api/company/get-one/${companyId}`, { withCredentials: true });
    return data;
}

const updateCompanyApiRequest = async (companyId, formInputs) => {
    const { data } = await axios.put(`/api/company/admin/${companyId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const AdminEditCompanyPage = () => {

    const reduxDispatch = useDispatch();


    return <EditCompanyComponent fetchCompany={fetchCompany} updateCompanyApiRequest={updateCompanyApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditCompanyPage;
