import axios from 'axios'
import { useDispatch } from "react-redux";
import EditLocksmithInfoComponent from './components/adminEditLocksmithInfoComponent';

const fetchLocksmithInfo = async (locksmithInfoId) => {
    const { data } = await axios.get(`/api/locksmithInfo/get-one/${locksmithInfoId}`, { withCredentials: true });
    return data;
}

const updateLocksmithInfoApiRequest = async (locksmithInfoId, formInputs) => {
    const { data } = await axios.put(`/api/locksmithInfo/admin/${locksmithInfoId}`, { ...formInputs }, { withCredentials: true });
    return data;
}
const updateLocksmithFileApiRequest = async (locksmithInfoId, formInputsFile) => {
    const { data } = await axios.put(`/api/locksmithInfo/admin/image/${locksmithInfoId}`, { ...formInputsFile }, { withCredentials: true });
    return data;
}
const fetchMake = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleMake/", { withCredentials: true })
    return data;
}
const fetchModel = async (make) => {
    const { data } = await axios.get(`/api/vehicleModel?make=${make}`, { withCredentials: true })
    return data;
}
const fetchYear = async (model) => {
    const { data } = await axios.get(`/api/vehicleYear?model=${model}`, { withCredentials: true });
    return data
}
const fetchUploads = async () => {
    const { data } = await axios.get(`/api/uploads`, { withCredentials: true });
    return data
}
const fetchUploadsImage = async (imageId) => {
    const { data } = await axios.get(`/api/uploads/image/${imageId}`, { withCredentials: true });
    return data
}
const fetchInstruction = async (abctrl) => {
    const { data } = await axios.get("/api/instruction/admin", { withCredentials: true });
    return data
}

const fetchInstructionId = async (instructionId) => {
    const { data } = await axios.get(`/api/instruction/get-one/${instructionId}`, { withCredentials: true });
    return data;
}

const AdminEditLocksmithInfoPage = () => {

    const reduxDispatch = useDispatch();


    return <EditLocksmithInfoComponent fetchInstructionId={fetchInstructionId} fetchInstruction={fetchInstruction} fetchUploadsImage={fetchUploadsImage} updateLocksmithFileApiRequest={updateLocksmithFileApiRequest} fetchUploads={fetchUploads} fetchYear={fetchYear} fetchModel={fetchModel} fetchLocksmithInfo={fetchLocksmithInfo} fetchMake={fetchMake} updateLocksmithInfoApiRequest={updateLocksmithInfoApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditLocksmithInfoPage;
