import React from "react";
import { useSelector } from "react-redux";

const EstimatePriceBreakComponent = ({
    setCreateDispatchResponseState,
    discountValue,
    discountType,
    setShowAlert2,
    setLoading,
    selectedTax,
    setSelectedTax,
    updateEstimateTotal,
    dispatching,
    updateEstimateTax,
    id,
    setShowDiscountModal,
    jobTax,
    jobItemSource,
    depositType,
    depositValue,
    setShowDepositModal,
    jobItems
}) => {
    const auth = useSelector(state => state.auth)

    const handleSubmitTax = (selectedTaxId) => {

        setSelectedTax(selectedTaxId);

        const formInputsTax = {
            make: dispatching.make,
            model: dispatching.model,
            year: dispatching.year,
            status: dispatching.status,
            count: dispatching.count,
            user: auth.user._id,
            discount: dispatching.discount,
            discountType: dispatching.discountType,
            tax: selectedTaxId,
            company: auth.user.company
        };

        updateEstimateTax(id, formInputsTax)
            .then((data) => {
                if (data.message === "estimate tax updated") {
                    setShowAlert2(true);
                    setTimeout(() => {
                        setShowAlert2(false);
                    }, 3000);
                    setLoading(true);
                }
            })
            .catch((er) => {
                setCreateDispatchResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const calculateSubTotal = () => {
        let subtotal = 0;
        for (const item of filteredJobItems) {
            if (!item.optional) {
                subtotal += item.price * item.quantity;
            }
        }
        return subtotal.toFixed(2);
    };
    
    const calculateDiscountAmount = () => {
        const subtotal = calculateSubTotal();

        if (discountType === 'percentage') {
            return (subtotal * (discountValue / 100));
        } else if (discountType === 'amount') {
            return parseFloat(discountValue); 
        } else {
            return 0; 
        }
    };

    const calculateTaxableField = () => {
        const taxableItems = filteredJobItems.filter(item => item.taxable && !item.optional);

        if (taxableItems.length > 0) {
            const subtotal = taxableItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);

            const discount = parseFloat(calculatedDiscountAmount);

            const taxableAmount = subtotal - discount;

            return taxableAmount.toFixed(2);
        } else {
            return 0.00
        }
    };
    const calculateTaxableFieldNoTax = () => {
        const taxableItems = filteredJobItems.filter(item => item.taxable && !item.optional);
        const nonTaxableItems = filteredJobItems.filter(item => !item.taxable && !item.optional);

        if (taxableItems.length === 0 && nonTaxableItems.length > 0) {

            const subtotal = nonTaxableItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);
            const discount = parseFloat(calculatedDiscountAmount);

            const taxableAmount = subtotal - discount;

            return taxableAmount.toFixed(2);
        } else {
            const subtotal = nonTaxableItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);

            const taxableAmount = subtotal;

            return taxableAmount.toFixed(2);
        }
    };

    const calculateTaxableFieldNoTaxOptional = () => {
        const taxableItems = filteredJobItems.filter(item => item.taxable);
        const nonTaxableItems = filteredJobItems.filter(item => !item.taxable);

        if (taxableItems.length === 0 && nonTaxableItems.length > 0) {

            const subtotal = nonTaxableItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);
            const discount = parseFloat(calculatedDiscountAmount);

            const taxableAmount = subtotal - discount;

            return taxableAmount.toFixed(2);
        } else {
            const subtotal = nonTaxableItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);

            const taxableAmount = subtotal;

            return taxableAmount.toFixed(2);
        }
    };

    const calculateTaxableFieldOptional = () => {
        const taxableItems = filteredJobItems.filter(item => item.taxable);

        const subtotal = taxableItems.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);

        const discount = parseFloat(calculatedDiscountAmount);

        const taxableAmount = subtotal - discount;

        return taxableAmount.toFixed(2);
    };

    const calculateTax = () => {
        const taxableAmount = parseFloat(calculateTaxableField());
        const taxRate = selectedTax ? selectedTax.taxRate : 0; 
        const tax = (taxableAmount * taxRate) / 100;
        return tax.toFixed(2);
    };

    const calculateTaxOptional = () => {
        const taxableAmount = parseFloat(calculateTaxableFieldOptional());
        const taxRate = selectedTax ? selectedTax.taxRate : 0; 
        const tax = (taxableAmount * taxRate) / 100;
        return tax.toFixed(2);
    };

    const calculateCostTotal = () => {
        let costTotal = 0;
        
        for (const item of filteredJobItems) {
            if (!item.optional) {
                costTotal += item.cost * item.quantity;
            }
        }
        return costTotal.toFixed(2);
    };

    const calculateTotal = () => {
        const nonTaxItems = parseFloat(calculateTaxableFieldNoTax());
        const taxableAmount = parseFloat(calculateTaxableField());
        const tax = parseFloat(calculateTax());
        const total = taxableAmount + tax + nonTaxItems;

        if (total !== dispatching.total) {

            updateEstimateTotal(id, { total: total })
                .then((data) => {
                    if (data.message === "estimate updated") {
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data
                    });
                });
        }
        return total.toFixed(2);
    };

    const calculateOptionalTotal = () => {
        const nonTaxItems = parseFloat(calculateTaxableFieldNoTaxOptional());
        const taxableAmount = parseFloat(calculateTaxableFieldOptional());
        const tax = parseFloat(calculateTaxOptional());
        const total = taxableAmount + tax + nonTaxItems;
        return total.toFixed(2);
    };

    const filteredJobItems = jobItems.filter((item) => jobItemSource.includes(item._id));
    const filteredJobTax = jobTax.filter((item) => dispatching?.company?.includes(item.company));
    const calculatedDiscountAmount = calculateDiscountAmount();
    const formattedDiscountAmount = typeof calculatedDiscountAmount === 'number' ? calculatedDiscountAmount.toFixed(2) : discountValue;
    const hasOptionalItems = filteredJobItems.some(item => item.optional === true);

    const formatDiscount = (amount, type, value) => {
        const subtotal = calculateSubTotal();
        const percentage = value / subtotal * 100;

        if (type === 'percentage') {
            return `${value}% ($${(amount)})`;
        } else if (type === 'amount') {
            const formattedPercentage = isNaN(percentage) ? '0.00%' : percentage.toFixed(2) + '%';
            return `$${value} (${formattedPercentage})`;
        }

        return '';
    };

    const formatDeposit = (amount, type, value) => {
        const subtotal = calculateSubTotal();
        const percentage = value / subtotal * 100;

        if (type === 'percentage') {
            return `${value}% ($${(amount)})`;
        } else if (type === 'amount') {
            const formattedPercentage = isNaN(percentage) ? '0.00%' : percentage.toFixed(2) + '%';
            return `$${value} (${formattedPercentage})`;
        }

        return '';
    };

    const calculateDepositAmount = () => {
        const subtotal = calculateSubTotal();
        const discountAmount = calculateDiscountAmount();

        if (depositType === 'percentage') {
            return (subtotal - discountAmount) * (depositValue / 100);
        } else if (depositType === 'amount') {
            return parseFloat(depositValue);
        } else {
            return 0;
        }
    };

    const calculatedDepositAmount = calculateDepositAmount();
    const formattedDepositAmount = typeof calculatedDepositAmount === 'number' ? calculatedDepositAmount.toFixed(2) : depositValue;

    return (
        <>
            <div className="row justify-content-end mt-4">
                <div className="col-sm-3 d-flex flex-column align-items-end">
                    <div className="d-flex align-items-end">
                        <label htmlFor="total" className="form-label me-2">Total:</label>
                        <input
                            className="form-control form-control-sm formWidth"
                            id="total"
                            type="text"
                            value={`$${calculateTotal()}`}
                            aria-label="Disabled input example"
                            disabled
                            readOnly
                        />
                    </div>
                    {hasOptionalItems ? (
                        <div className="d-flex align-items-end mt-2">
                            <label htmlFor="total" className="form-label me-2">With Optional:</label>
                            <input
                                className="form-control form-control-sm formWidth"
                                id="total"
                                type="text"
                                value={`$${calculateOptionalTotal()}`}
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                            />
                        </div>
                    ) : (
                            <div className="d-flex align-items-end mt-2">
                                <label htmlFor="total" className="form-label me-2">With Optional:</label>
                                <input
                                    className="form-control form-control-sm formWidth"
                                    id="total"
                                    type="text"
                                    value={`$0.00`} 
                                    aria-label="Disabled input example"
                                    disabled
                                    readOnly
                                />
                            </div>
                    )}
                </div>
                <div className="col-sm-5 d-flex flex-column align-items-end">
                    <div className="d-flex align-items-end">
                        <label htmlFor="subTotal" className="form-label me-2">Sub Total:</label>
                        <input className="form-control form-control-sm formWidth" id="subTotal" type="text" value={`$${calculateSubTotal()}`} aria-label="Disabled input example" disabled readOnly />
                    </div>
                    <div className="d-flex align-items-end mt-2">
                        <label htmlFor="discount" className="form-label me-2">Discount:</label>
                        <input
                            className="form-control form-control-sm formWidth"
                            id="discount"
                            type="text"
                            value={formatDiscount(formattedDiscountAmount, discountType, discountValue)}
                            onClick={() => setShowDiscountModal(true)}
                            aria-label="Disabled input example"
                            readOnly
                        />

                    </div>
                    {dispatching.tax === null || selectedTax === "" ? (
                        <div className="d-flex align-items-end mt-2">
                            <label htmlFor="taxable" className="form-label me-2">Taxable:</label>
                            <input
                                className="form-control form-control-sm formWidth"
                                id="taxable"
                                type="text"
                                value="0.00"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                            />
                        </div>
                    ) : (
                            <div className="d-flex align-items-end mt-2">
                                <label htmlFor="taxableField" className="form-label me-2">Taxable Field:</label>
                                <input
                                    className="form-control form-control-sm formWidth"
                                    id="taxableField"
                                    type="text"
                                    value={`$${calculateTaxableField()}`}
                                    aria-label="Disabled input example"
                                    disabled
                                    readOnly
                                />
                            </div>
                    )}
                    <div className="d-flex align-items-end mt-2">
                        <label htmlFor="taxable" className="form-label me-2">Tax%:</label>
                        <select
                            className="form-control form-control-sm formWidth"
                            id="taxable"
                           value={selectedTax ? selectedTax._id : ""}
                            onChange={(event) => handleSubmitTax(event.target.value)} 
                        >
                            {filteredJobTax.map((tax) => (
                                <option key={tax._id} value={tax._id}>
                                    {`${tax.name} (${tax.taxRate}%)`}
                                </option>
                            ))}
                        </select>
                    </div>
                    {dispatching.tax === null || selectedTax === "" ? (
                        <div className="d-flex align-items-end mt-2">
                            <label htmlFor="taxable" className="form-label me-2">Tax:</label> 
                            <input
                                className="form-control form-control-sm formWidth"
                                id="taxable"
                                type="text"
                                value="0.00"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                            />
                        </div>
                    ) : (
                        <div className="d-flex align-items-end mt-2">
                            <label htmlFor="taxable" className="form-label me-2">Tax:</label>
                            <input
                                className="form-control form-control-sm formWidth"
                                id="taxable"
                                type="text"
                                value={`$${calculateTax()}`}
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                            />
                        </div>
                    )}
                    <div className="d-flex align-items-end mt-2">
                        <label htmlFor="taxable" className="form-label me-2">Item Cost:</label>
                        <input
                            className="form-control form-control-sm formWidth"
                            id="taxable"
                            type="text"
                            value={`$${calculateCostTotal()}`}
                            aria-label="Disabled input example"
                            disabled
                            readOnly
                        />
                    </div>
                    <div className="d-flex align-items-end mt-2">
                        <label htmlFor="deposit" className="form-label me-2">Deposit:</label>
                        <input
                            className="form-control form-control-sm formWidth"
                            id="deposit"
                            type="text"
                            value={formatDeposit(formattedDepositAmount, depositType, depositValue)}
                            onClick={() => setShowDepositModal(true)} 
                            aria-label="Disabled input example"
                            readOnly
                        />
                    </div>
                </div>
                <div className="col-sm-1"></div>
            </div>
        </>
    );
};

export default EstimatePriceBreakComponent;
