import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { Link } from "react-router-dom";
import paths from "../../../router/paths";
import AdminDeletePopUpComponent from '../../../components/admin/adminDeletePopUpComponent';

const AdminCodesComponent = ({ fetchCodes, deleteCodes }) => {
    const [codes, setCodes] = useState([]);
    const [codesDeleted, setCodesDeleted] = useState(false);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [codeId, setCodeId] = useState("");

    const handleConfirmRemove = async () => {
        const data = await deleteCodes(codeId);
        if (data.message === 'Code deleted successfully') {
            setCodesDeleted(!codesDeleted)
            setShowConfirmation(false);
            setIsLoading(true);
        }
    }
    const deleteHandler = (codesId) => {
        setCodeId(codesId);
        setShowConfirmation(true);
    };

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchCodes(abctrl)
            .then((res) => {
                setCodes(res);
                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [deleteCodes, dispatch, fetchCodes]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchCodes(abctrl)
                .then((res) => {
                    setCodes(res);
                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [deleteCodes, isLoading, dispatch, fetchCodes]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    return (
        <div className="min-vh-100">
            {showConfirmation && (
                <AdminDeletePopUpComponent
                    title='Delete this VIN data?'
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancel}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <AdminLinksComponent />
                </div>
                <div className="col-sm-10 mt-5 pt-5">
                    <div className="row mb-3">
                        <div className="col-sm-8">
                            <h1>Codes List</h1>
                        </div>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                className="mt-2 input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Codes.."
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1">
                            <Link to={paths.ADMINNEWCODES} className="btn btn-outline-primary loginButton">New</Link>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <table className="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">Vin</th>
                                    <th scope="col" className="text-center">Make</th>
                                    <th scope="col" className="text-center">Model</th>
                                    <th scope="col" className="text-center">Year</th>
                                    <th scope="col" className="text-center">Edit/Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {codes
                                    .filter(
                                        (code) =>
                                            code.vin.toLowerCase().indexOf(searchValue) !== -1 ||
                                            code.make.toLowerCase().indexOf(searchValue) !== -1 ||
                                            code.model.toLowerCase().indexOf(searchValue) !== -1
                                    )
                                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                    .map((code, idx) => (
                                        <tr key={idx}>
                                            <td className="text-center">{code.vin}</td>
                                            <td className="text-center">{code.make}</td>
                                            <td className="text-center">{code.model}</td>
                                            <td className="text-center">{code.year}</td>
                                            <td className="text-center">
                                                <Link to={`/admin/edit-codes/${code._id}`}>
                                                    <button type="button" className="btn-sm btn btn-outline-primary">
                                                        <GoPencil />
                                                    </button>
                                                </Link>
                                                {" / "}
                                                <button
                                                    type="button"
                                                    className="btn-sm btn btn-outline-danger"
                                                    onClick={() => deleteHandler(code._id)}
                                                >
                                                    <BsTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                    )}
                            </tbody>
                        </table>
                    )}
                    <div className="d-flex justify-content-center pb-4">
                        <button
                            type="button"
                            className="btn-sm btn btn-outline-primary mr-2 me-3 loginButton"
                            disabled={page === 1}
                            onClick={handlePrevPage}
                        >
                            Previous
                        </button>
                        <span className="mr-2 me-3">
                            Page {page} of {Math.ceil(codes
                                .filter(
                                    (code) =>
                                        code.vin.toLowerCase().indexOf(searchValue) !== -1 ||
                                        code.make.toLowerCase().indexOf(searchValue) !== -1 ||
                                        code.model.toLowerCase().indexOf(searchValue) !== -1
                                ).length / rowsPerPage)}
                        </span>
                        <button
                            type="button" 
                            className="btn-sm btn btn-outline-primary loginButton"
                            disabled={codes
                                .filter(
                                    (code) =>
                                        code.vin.toLowerCase().indexOf(searchValue) !== -1 ||
                                        code.make.toLowerCase().indexOf(searchValue) !== -1 ||
                                        code.model.toLowerCase().indexOf(searchValue) !== -1
                                ).length <= page * rowsPerPage}
                            onClick={handleNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminCodesComponent;