import axios from 'axios'
import { useDispatch } from "react-redux";
import AdminEditTaxCompontent from './components/adminEditJobTaxComponent';

const fetchJobTax = async (jobTaxId) => {
    const { data } = await axios.get(`/api/jobTax/admin/${jobTaxId}`, { withCredentials: true });
    return data;
}

const updateJobTaxApiRequest = async (jobTaxId, formInputs) => {
    const { data } = await axios.put(`/api/jobTax/dispatch/${jobTaxId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const AdminEditTaxPage = () => {

    const reduxDispatch = useDispatch();


    return <AdminEditTaxCompontent fetchJobTax={fetchJobTax} updateJobTaxApiRequest={updateJobTaxApiRequest} reduxDispatch={reduxDispatch} />;
};

export default AdminEditTaxPage;
