import React, { useState, useEffect } from "react";
import { BsTrash } from 'react-icons/bs';
import { ImDrawer } from 'react-icons/im';
import { AiOutlinePlus } from 'react-icons/ai';
import '../assets/jobItemCss.css';
import { FaRegThumbsUp } from 'react-icons/fa';
import DeleteJobItemPopModal from "./deleteJobItemPopComponent";
import DiscountPopupComponent from "./discountPopupComponent";
import { useSelector } from "react-redux";
import EstimatePriceBreakComponent from "./estimatePriceBreakComponent";
import EstimateAddJobItemPopupComponent from "./estimateAddJobItemPopComponent";
import DepositPopupComponent from "./depositPopupComponent";

const EstimateItemPanelComponent = ({ setPayLoading, estLoading, updateEstimateItem, updateEstimateTotal, deleteJobItem, updateEstimateDeposit, updateJobItemsApiRequest, createJobItemApiRequest, id, getJobTaxById, updateEstimateDiscount, updateEstimateTax, fetchJobTax, fetchEstimates, fetchJobItem }) => {
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmation1, setShowConfirmation1] = useState(false);
    const [jobItems, setJobItems] = useState([]);
    const [loading, setLoading] = useState(false); // New state to track loading state
    const [itemToDelete, setItemToDelete] = useState(null); // New state to track the item to be deleted
    const [jobItemSource, setJobItemSource] = useState([]);
    const [dispatching, setDispatch] = useState([]);
    const [discountType, setDiscountType] = useState('percentage'); // 'percentage' or 'amount'
    const [discountValue, setDiscountValue] = useState(0); // Discount value
    const [depositType, setDepositType] = useState('percentage');
    const [depositValue, setDepositValue] = useState(0);
    const [selectedTax, setSelectedTax] = useState(null); // New state to store selected tax option
    const [jobTax, setJobTax] = useState([]);
    const [showDepositModal, setShowDepositModal] = useState(false);
    const [showDiscountModal, setShowDiscountModal] = useState(false); // State to control the discount modal
    const auth = useSelector(state => state.auth)
    const [selectedJobItem, setSelectedJobItem] = useState(null);

    const [createDispatchResponseState, setCreateDispatchResponseState] =
        useState({
            message: "",
            error: ""
        });

    useEffect(() => {
        const abctrl = new AbortController();
        if (loading) {

            fetchEstimates(id)
                .then((data) => {
                    setDispatch(data);
                    setJobItemSource(data.jobItems);
                    setDiscountType(data.discountType);
                    setDiscountValue(data.discount);
                    setDepositType(data.depositType);
                    setDepositValue(data.deposit);

                    const taxId = data.tax || '';

                    getJobTaxById(taxId)
                        .then((data) => {
                            setSelectedTax(data)
                            setLoading(false);

                        })
                        .catch((er) => console.log(er));
                })
                .catch((er) => console.log(er));

            fetchJobItem(abctrl)
                .then((res) => {

                    setJobItems(res);
                    setLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
            fetchJobTax(abctrl)
                .then((res) => {
                    setJobTax(res);
                    setLoading(false);

                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }

        return () => abctrl.abort();
    }, [loading, fetchEstimates, dispatching.tax, id, fetchJobItem, fetchJobTax, getJobTaxById]);

    useEffect(() => {
        const abctrl = new AbortController();
        setLoading(true);

        fetchEstimates(id)
            .then((res) => {
                setDispatch(res);
                setJobItemSource(res.jobItems);
                setDiscountType(res.discountType);
                setDiscountValue(res.discount);
                setDepositType(res.depositType);
                setDepositValue(res.deposit);

                const taxId = res.tax || '';

                getJobTaxById(taxId)
                    .then((data) => {
                        setSelectedTax(data)
                        setLoading(false);

                    })
                    .catch((er) => console.log(er));

            })
            .catch((er) => console.log(er));

        fetchJobItem(abctrl)
            .then((res) => {
                setJobItems(res);
                setLoading(false); // Set loading to false after successful data fetch
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        fetchJobTax(abctrl)
            .then((res) => {
                setJobTax(res);
                setLoading(false);

            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        return () => abctrl.abort();
    }, [fetchEstimates, dispatching.tax, id, fetchJobItem, fetchJobTax, getJobTaxById]);

    const handleItemChange = (selectedData) => {

        const matchedItem = jobItems.find((item) => {
            return (
                item.name === selectedData[0].name &&
                item.jobId === dispatching.jobId &&
                item.estimateId === dispatching._id
            );
        });

        if (selectedData && selectedData[0].reuseable) {
            const formInputs = {
                name: selectedData[0].name,
                quantity: selectedData[0].quantity,
                price: selectedData[0].price,
                itemType: selectedData[0].itemType,
                markup: selectedData[0].markup || 0,
                cost: selectedData[0].cost,
                description: selectedData[0].description,
                taxable: selectedData[0].taxable || false,
                tag: selectedData[0].tag || 'Locksmith',
                type: selectedData[0].type || 'All',
                company: auth.user.company,
            };
            const formInputsSec = {
                name: selectedData[0].name,
                quantity: selectedData[0].quantity,
                price: selectedData[0].price,
                markup: selectedData[0].markup || 0,
                cost: selectedData[0].cost,
                itemType: selectedData[0].itemType,
                description: selectedData[0].description,
                taxable: selectedData[0].taxable || false,
                tag: selectedData[0].tag || 'Locksmith',
                type: selectedData[0].type || 'All',
                company: auth.user.company,
                jobId: dispatching.jobId,
                estimateId: dispatching._id,
            };
            createJobItemApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Job item created") {

                        createJobItemApiRequest(formInputsSec)
                            .then((data) => {
                                if (data.message === "Job item created") {

                                    const updatedJobItems = [...dispatching.jobItems, data._id];

                                    updateEstimateItem(id, { jobItems: updatedJobItems })
                                        .then((data) => {
                                            if (data.message === "estimate items updated") {
                                                setShowAlert1(true);
                                                setTimeout(() => {
                                                    setShowAlert1(false);
                                                }, 3000);
                                                setLoading(true);
                                            }
                                        })
                                        .catch((er) => {
                                            setCreateDispatchResponseState({
                                                error: er.response.data.message
                                                    ? er.response.data.message
                                                    : er.response.data,
                                            });
                                        });
                                }
                            })
                            .catch((er) => {
                                setCreateDispatchResponseState({
                                    error: er.response.data.message
                                        ? er.response.data.message
                                        : er.response.data,
                                });
                            });
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        } else if (matchedItem && selectedData) {
            const formInputs = {
                name: selectedData[0].name || matchedItem.name,
                quantity: selectedData[0].quantity || matchedItem.quantity,
                price: selectedData[0].price || matchedItem.price,
                markup: selectedData[0].markup || matchedItem.markup,
                cost: selectedData[0].cost || matchedItem.cost,
                description: selectedData[0].description || matchedItem.description,
                taxable: selectedData[0].taxable,
                optional: selectedData[0].optional,
                itemType: selectedData[0].itemType,
                type: selectedData[0].type || 'All',
            };
            updateJobItemsApiRequest(matchedItem._id, formInputs)
                .then((data) => {
                    if (data.message === "Job item updated") {

                        setShowAlert1(true);
                        setTimeout(() => {
                            setShowAlert1(false);
                        }, 3000);
                        setLoading(true);

                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        } else {
            const formInputs = {
                name: selectedData[0].name,
                quantity: selectedData[0].quantity,
                price: selectedData[0].price,
                markup: selectedData[0].markup,
                cost: selectedData[0].cost,
                itemType: selectedData[0].itemType,
                description: selectedData[0].description,
                taxable: selectedData[0].taxable || false,
                tag: selectedData[0].tag,
                type: selectedData[0].type || 'All',
                company: auth.user.company,
                optional: selectedData[0].optional,
                jobId: dispatching.jobId,
                estimateId: dispatching._id,
            };
            createJobItemApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Job item created") {

                        const updatedJobItems = [...dispatching.jobItems, data._id];

                        updateEstimateItem(id, { jobItems: updatedJobItems })
                            .then((data) => {
                                if (data.message === "estimate items updated") {
                                    setShowAlert1(true);
                                    setTimeout(() => {
                                        setShowAlert1(false);
                                    }, 3000);
                                    setLoading(true);
                                }
                            })
                            .catch((er) => {
                                setCreateDispatchResponseState({
                                    error: er.response.data.message
                                        ? er.response.data.message
                                        : er.response.data,
                                });
                            });
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
    };

    const handleSubmitDiscount = (inputValue, inputType) => {

        setDiscountValue(inputValue);
        setDiscountType(inputType);

        setShowDiscountModal(false);

        const formInputsDiscount = {
            make: dispatching.make,
            model: dispatching.model,
            year: dispatching.year,
            status: dispatching.status,
            count: dispatching.count,
            user: auth.user._id,
            discount: inputValue,
            discountType: inputType,
            tax: selectedTax,
            company: auth.user.company
        };

        updateEstimateDiscount(id, formInputsDiscount)
            .then((data) => {
                if (data.message === "Dispatch tax updated") {
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                }
            })
            .catch((er) => {
                setCreateDispatchResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const handleSubmitDeposit = (inputValue, inputType) => {

        setDepositValue(inputValue);
        setDepositType(inputType);

        const formInputsDeposit = {
            deposit: inputValue,
            depositType: inputType,
        };

        updateEstimateDeposit(id, formInputsDeposit)
            .then((data) => {
                if (data.message === "estimate deposit updated") {
                    setPayLoading(true);
                    setShowAlert3(true);
                    setTimeout(() => {
                        setShowAlert3(false);
                    }, 3000);
                    setShowDepositModal(false);
                    setPayLoading(true);
                }
            })
            .catch((er) => {
                setCreateDispatchResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    useEffect(() => {
        if (showAlert1) {
            setTimeout(() => {
                setShowAlert1(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [showAlert1]);

    useEffect(() => {
        if (showAlert2) {
            setTimeout(() => {
                setShowAlert2(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [showAlert2]);

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, [showAlert]);

    const handleRemoveItem = (itemId, event) => {
        event.stopPropagation();

        setItemToDelete(itemId); // Store the itemId of the item to be deleted
        setShowConfirmation1(true); // Show the confirmation modal
    };

    const handleConfirmRemoveItem = async (itemId) => {
        try {
            const updatedJobItems = jobItemSource.filter((item) => item !== itemId);

            deleteJobItem(itemId)
                .then((res) => {
                    if (res.message === "Job item deleted successfully") {
                        updateEstimateItem(id, { jobItems: updatedJobItems })
                            .then((data) => {
                                if (data.message === "estimate items updated") {
                                    setShowConfirmation1(false);
                                    setShowAlert1(true);
                                    setTimeout(() => {
                                        setShowAlert1(false);
                                    }, 3000);
                                    setLoading(true);

                                }
                            })
                            .catch((er) => {
                                setCreateDispatchResponseState({
                                    error: er.response.data.message
                                        ? er.response.data.message
                                        : er.response.data,
                                });
                            });
                    }
                })
                .catch((er) => {
                    setCreateDispatchResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        } catch (error) {
            // Handle any errors that occur during the asynchronous operations
            console.error("An error occurred:", error);

            // You can set an error state here if needed
            setCreateDispatchResponseState({
                error: error.response?.data.message || error.response?.data || "An error occurred",
            });
        }
    };

    const handleCancelRemoveItem = () => {
        setShowConfirmation1(false);
    };
    const handleCancelDiscount = () => {
        setShowDiscountModal(false);
    };
    const handleCancelDeposit = () => {
        setShowDepositModal(false);
    };
    const handleCloseAlert1 = () => {
        setShowAlert1(false);
    };
    const handleCloseAlert2 = () => {
        setShowAlert2(false);
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleCloseAlert3 = () => {
        setShowAlert3(false);
    };
    const handleAddItem = () => {
        setSelectedJobItem('');
        setShowConfirmation(true);
    };
    const handleCancelAddJobItem = () => {
        setShowConfirmation(false);
    };

    const handleTableRowClick = (jobItem) => {
        setSelectedJobItem(jobItem);
        setShowConfirmation(true);

    };

    const filteredJobItems = jobItems.filter((item) => jobItemSource.includes(item._id));

    return (
        <div className="d-flex flex-column form-container w-100 p-0 m-0">
            {showAlert1 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Action Preformed
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert1}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert2 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Totals Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert2}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Discount Changed Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showAlert3 && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    Deposit Changed Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlert3}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {showDiscountModal && (
                <DiscountPopupComponent
                    discountValue={discountValue}
                    discountType={discountType}
                    onCancel={handleCancelDiscount}
                    onConfirm={handleSubmitDiscount}
                />
            )}
            {showDepositModal && (
                <DepositPopupComponent
                    depositValue={depositValue}
                    depositType={depositType}
                    onCancel={handleCancelDeposit}
                    onConfirm={handleSubmitDeposit}
                />
            )}
            {showConfirmation && (
                <EstimateAddJobItemPopupComponent
                    fetchJobItem={fetchJobItem}
                    setSelectedJobItem={setSelectedJobItem}
                    selectedJobItem={selectedJobItem}
                    onConfirm={handleItemChange}
                    onCancel={handleCancelAddJobItem}
                />
            )}
            {showConfirmation1 && (
                <DeleteJobItemPopModal
                    onConfirm={() => handleConfirmRemoveItem(itemToDelete)}
                    onCancel={handleCancelRemoveItem}
                />
            )}
            {loading ? (
                <p className="text-center">Loading...</p>
            ) : (
                <>
                    <div className="row justify-content-center m-0 p-0 mt-4">
                        <div className="col-sm-12 p-0 m-0">
                            <h5 className="mt-4">Items</h5>
                            <table className="mt-3 table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col" className="item-col ps-3" style={{ width: "40%" }}>Item</th>
                                        <th scope="col" className="text-center">Quantity</th>
                                        <th scope="col" className="text-center">Price</th>
                                        <th scope="col" className="text-center">Cost</th>
                                        <th scope="col" className="text-center">Amount</th>
                                        <th scope="col" className="text-center">Taxable</th>
                                        <th scope="col" className="text-center fs-4"><ImDrawer /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredJobItems.map((item, index) => (
                                        <tr key={item._id} onClick={() => handleTableRowClick(item)}>
                                            <th scope="row" className="text-center"></th>
                                            <td>
                                                {item.name}
                                                <br />
                                                <span className="fs-6 ps-2">{item.description}</span>
                                                <br />
                                                <button type="button" className="serviceTypeButton btn btn-outline-primary btn-sm" disabled>{item.itemType}</button>
                                            </td>
                                            <td className="text-center">{item.quantity}</td>
                                            <td className="text-center">${item.price}</td>
                                            <td className="text-center">${item.cost}</td>
                                            <td className="text-center">${item.price * item.quantity}</td>
                                            <td className="text-center">
                                                {item.taxable === true ? (
                                                    <span>Yes</span>
                                                ) : (
                                                    <span>No</span>
                                                )}
                                            </td>
                                            <td className="text-center">
                                                <button
                                                    className="btn btn-outline-danger"
                                                    onClick={(event) => handleRemoveItem(item._id, event)}
                                                >
                                                    <BsTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button type="button" className="btn btn-outline-primary addItemButton" onClick={handleAddItem}><AiOutlinePlus /> Add Item</button>
                        </div>
                    </div>
                    <EstimatePriceBreakComponent
                        estLoading={estLoading}
                        depositType={depositType}
                        depositValue={depositValue}
                        setShowDepositModal={setShowDepositModal}
                        discountType={discountType}
                        selectedTax={selectedTax}
                        jobTax={jobTax}
                        updateEstimateTotal={updateEstimateTotal}
                        setShowDiscountModal={setShowDiscountModal}
                        setSelectedTax={setSelectedTax}
                        discountValue={discountValue}
                        setShowAlert2={setShowAlert2}
                        setLoading={setLoading}
                        setCreateDispatchResponseState={setCreateDispatchResponseState}
                        id={id}
                        updateEstimateTax={updateEstimateTax}
                        jobItems={jobItems}
                        jobItemSource={jobItemSource}
                        dispatching={dispatching}
                    />
                </>
            )}
        </div>
    );
};

export default EstimateItemPanelComponent;
