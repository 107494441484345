import axios from "axios";
import AdminCodeRequestComponent from "./components/adminCodeRequestsComponent";

const fetchCodes = async (abctrl) => {
    const { data } = await axios.get("/api/vinRequest/admin", { withCredentials: true });
    return data
}

const deleteCodes = async (codesId) => {
    const { data } = await axios.delete(`/api/vinRequest/${codesId}`, { withCredentials: true });
    return data
}

const AdminRequestCodesPage = () => {

    return <AdminCodeRequestComponent fetchCodes={fetchCodes} deleteCodes={deleteCodes} />;
};

export default AdminRequestCodesPage;
