import axios from "axios";
import AdminUpdateTimeComponent from "./components/adminUpdateTimeComponent";

const AdminUpdateTimePage = () => {
  
    const updateTimeApiRequest = async (workId, formInputs) => {
        const { data } = await axios.put(`/api/workSchedule/update-work-schedule/${workId}`, { ...formInputs }, { withCredentials: true });
        return data;
    }

    const clockStatus = async () => {
        const { data } = await axios.get(`/api/timeEntry/clock-status/all`, { withCredentials: true });
        return data
    }

    const fetchUsers = async (userId) => {
        const { data } = await axios.get(`/api/users/profile/${userId}`, { withCredentials: true });
        return data
    }

    return <AdminUpdateTimeComponent
        fetchUsers={fetchUsers}
        clockStatus={clockStatus}
        updateTimeApiRequest={updateTimeApiRequest}
    />
};

export default AdminUpdateTimePage;