import React, { useState } from "react";
import { FaRegThumbsUp } from 'react-icons/fa';

const EstimateNotesComponent = ({ notes, setLoading, setNotes, id, setShowNotes, updateEstimateNotes }) => {
    const [inputValue, setInputValue] = useState(notes);
    const [showAlert, setShowAlert] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleApplyDescription = () => {

        setNotes(inputValue);

        const formInputsNote = {
            notes: inputValue,
        };

        updateEstimateNotes(id, formInputsNote)
            .then((data) => {
                if (data.message === "estimate updated") {
                    setLoading(true);
                    setShowNotes(false);
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                }
            })
            .catch((er) => {
                console.log({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <>
            <div className="row">
                {showAlert && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Estimate Updated Successfully
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                    <div className="row justify-content-center">
                        <div className="col-sm-12 mt-2">
                            <div className="mb-3">
                                <textarea onChange={handleInputChange} value={inputValue} className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-2">
                        <button onClick={handleApplyDescription} className="btn btn-outline-primary btn-sm ps-2 pe-2">
                                Submit
                            </button>
                        </div>
                    </div>
            </div>
        </>
    );
};

export default EstimateNotesComponent;
