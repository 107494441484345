import { useSelector } from "react-redux";
import paths from "../../router/paths";
import { Link } from "react-router-dom";

const InventoryLinksComponent = ({ inventorySet, requestDataLenStatus, requestDataLenInStatus }) => {
    const auth = useSelector(state => state.auth)
    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isInventory = auth.user?.usertype?.includes('Inventory') || false;
    const isDeveloper = auth.user?.usertype?.includes('Developer') || false;
    const isAdminOrSuperAdmin = isAdmin || isDeveloper || isInventory;

    const totalStatus = requestDataLenStatus().length + requestDataLenInStatus().length;

    const inventoryOrder = inventorySet.filter(inv => inv.status === "Order").length;

    return (
        <nav className="navbar navbar-light gray-color p-0 m-0 navLinksnavbar">
            <ul className="nav flex-column m-0 pe-3 pb-3 p-0">
                {isAdminOrSuperAdmin && (
                    <li className="nav-item">
                        <Link className="nav-link text-dark m-1 p-0" to={paths.INVENTORYMANAGERITEMS}>
                            Inventory Items
                        </Link>
                    </li>
                )}
                <li className="nav-item">
                    <Link className="nav-link text-dark m-1 p-0" to={paths.INVENTORYMANAGERITEMSTECH}>
                        My Items
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-dark m-1 p-0" to={paths.INVENTORYTOOLS}>
                        Tools
                    </Link>
                </li>
                {isAdminOrSuperAdmin && (
                    <li className="nav-item">
                        <Link className="nav-link text-dark m-0 m-1 p-0" to={paths.INVENTROYORDER}>
                            Inventory Order
                            {inventoryOrder > 0 && (
                                <span className="translate-middle fs-6 badge1 rounded-pill bg-danger">
                                    {inventoryOrder}
                                    <span className="visually-hidden">unread messages</span>
                                </span>
                            )}
                        </Link>
                    </li>
                )}
                {isAdminOrSuperAdmin && (
                    <li className="nav-item">
                        <Link className="nav-link text-dark m-1 p-0" to={paths.INVENTORYINCREASE}>
                            Add Inventory
                        </Link>
                    </li>
                )}
                <li className="nav-item">
                    <Link className="nav-link text-dark m-1 p-0" to={paths.INVENTORYREQUEST}>
                        Request Inventory
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-dark m-1 p-0" to={paths.INVENTORYREQUESTSTATUS}>
                        Request Status
                        {totalStatus > 0 && (
                            <span className="ms-3 translate-middle fs-6 badge rounded-pill bg-danger">
                                {totalStatus}
                                <span className="visually-hidden">unread messages</span>
                            </span>
                        )}
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default InventoryLinksComponent;