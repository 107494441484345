import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

const ClientEstimateSignaturesComponent = ({ onConfirm }) => {
    const signatureRef = useRef();

    const handleApplyDiscount = () => {
        const signatureDataURL = signatureRef.current.toDataURL();
        onConfirm(signatureDataURL);
    };

    return (
        <div className="row d-flex justify-content-center mt-4">
            <div className="col-sm-12">
                <div className="alertWhite alert-light text-start" role="alert">
                    <div className="row mb-2 m-0 p-0">
                        <div className="col-sm-8 p-0 m-0">
                            <span className="signBoxTitle">Please sign in the box below:</span>
                        </div>
                    </div>
                    <div className="row mb-4 signatureCanvasModule11 justify-content-center">
                        <SignatureCanvas
                            penColor="black"
                            canvasProps={{
                                width: 401,
                                height: 150,
                                className: "signature-canvas",
                                style: {
                                    cursor: "crosshair",
                                    border: "1px solid black", // Add a border for visualization
                                    margin: "0", // Ensure no margin
                                    padding: "0", // Ensure no padding
                                },
                            }}
                            ref={signatureRef}
                            onEnd={handleApplyDiscount} // Trigger the function on drawing end
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientEstimateSignaturesComponent;
