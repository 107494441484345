import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DispatchLinksComponent from "../../../components/dispatch/dispatchLinksComponent";
import '../../../components/assets/dispatchPanel.css'
import { MdOutlineInventory } from "react-icons/md";
import DispatchDeletePopUpComponent from '../../../components/dispatch/deleteconfirm/dispatchDeletePopComponent';

const DispatchPanelJobComponent = ({ fetchSchedule, fetchUsers, fetchJobTag, fetchTechInfo, fetchJobType, fetchDispatch, fetchCustomer, deleteDispatch }) => {
    const [dispatch, setDispatch] = useState([]);
    const auth = useSelector(state => state.auth)
    const [dispatchDeleted, setDispatchDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [selectedTab, setSelectedTab] = useState("Submitted");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [dispatchId, setDispatchId] = useState("");

    const handleConfirmRemove = async () => {
        const data = await deleteDispatch(dispatchId);
        if (data.message === 'Dispatch deleted successfully') {
            setDispatchDeleted(!dispatchDeleted)
            setShowConfirmation(false);
            setIsLoading(true);
        }
    }

    const deleteHandler = (dispatchId) => {
        setDispatchId(dispatchId);
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchDispatch(abctrl)
            .then(async (res) => {
                const dispatchFilter = res.filter((dispatch) => dispatch.company === auth.user.company);

                const schedulesAndTechs = await Promise.all(dispatchFilter.map(async (dispatch) => {
                    const schedule = await fetchSchedule(dispatch.schedule);
                    const customer = await fetchCustomer(dispatch.customer);
                    const jobType = await fetchJobType(dispatch.jobType);

                    let techs;
                    let jobTags;

                    if (dispatch.techs.length > 0) {
                        techs = await Promise.all(dispatch.techs.map(async (techId) => {
                            const tech = await fetchTechInfo(techId);

                            let user;

                            if (tech.user) {
                                user = await fetchUsers(tech.user);
                            }

                            return { ...tech, user };
                        }));
                    }

                    if (dispatch.jobTags.length > 0) {
                        jobTags = await Promise.all(dispatch.jobTags.map(async (itm) => {
                            const tags = await fetchJobTag(itm);

                            return { ...tags };
                        }));
                    }

                    return { ...dispatch, schedule, techs, customer, jobType, jobTags };

                }));

                setDispatch(schedulesAndTechs);
                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        return () => abctrl.abort();
    }, [deleteDispatch, fetchUsers, fetchCustomer, fetchJobTag, fetchTechInfo, fetchDispatch, fetchJobType, auth.user.company]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchDispatch(abctrl)
                .then(async (res) => {
                    const dispatchFilter = res.filter((dispatch) => dispatch.company === auth.user.company);

                    const schedulesAndTechs = await Promise.all(dispatchFilter.map(async (dispatch) => {
                        const schedule = await fetchSchedule(dispatch.schedule);
                        const customer = await fetchCustomer(dispatch.customer);
                        const jobType = await fetchJobType(dispatch.jobType);

                        let techs;
                        let jobTags;

                        if (dispatch.techs.length > 0) {
                            techs = await Promise.all(dispatch.techs.map(async (techId) => {
                                const tech = await fetchTechInfo(techId);

                                let user;

                                if (tech.user) {
                                    user = await fetchUsers(tech.user);
                                }

                                return { ...tech, user };
                            }));
                        }

                        if (dispatch.jobTags.length > 0) {
                            jobTags = await Promise.all(dispatch.jobTags.map(async (itm) => {
                                const tags = await fetchJobTag(itm);

                                return { ...tags };
                            }));
                        }

                        return { ...dispatch, schedule, techs, customer, jobType, jobTags };

                    }));

                    setDispatch(schedulesAndTechs);
                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [deleteDispatch, isLoading, fetchUsers, fetchCustomer, fetchJobTag, fetchTechInfo, fetchDispatch, fetchJobType, auth.user.company]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    const filterDispatch = (dispatching) => {

        const jobTypeName = dispatching.jobType ? dispatching.jobType.name : "";

        const associatedCustomer = dispatching.customer

        const customerName = associatedCustomer ? associatedCustomer.name : "";
        const customerLastName = associatedCustomer ? associatedCustomer.lastName : "";
        const customerPhone = associatedCustomer ? associatedCustomer.phone : "";
        const customerAddress = associatedCustomer ? associatedCustomer.address : "";
        const customerCity = associatedCustomer ? associatedCustomer.city : "";
        const customerState = associatedCustomer ? associatedCustomer.state : "";

        const tagsMatched = dispatching.jobTags && dispatching.jobTags.some(tagId => {

            const tagName = tagId ? tagId.name : "";
            return tagName.toLowerCase().includes(searchValue.toLowerCase());
        });

        const techsMatched = dispatching.techs.some(techId => {

            const techName = techId.user ? techId.user.name : "";
            const techLastName = techId.user ? techId.user.lastName : "";

            return (
                techName.toLowerCase().includes(searchValue.toLowerCase()) ||
                techLastName.toLowerCase().includes(searchValue.toLowerCase())
            );
        });

        const isSubmitted = dispatching.status === "Submitted";
        const isInProgress = dispatching.status === "In Progress";
        const isPending = dispatching.status === "Pending";
        const isDonePendingApproval = dispatching.status === "Pending Payment";

        const result =
            (!searchValue ||
                (customerName.toLowerCase().includes(searchValue.toLowerCase()) ||
                    customerLastName.toLowerCase().includes(searchValue.toLowerCase()) ||
                    customerPhone.toLowerCase().includes(searchValue.toLowerCase()) ||
                    jobTypeName.toLowerCase().includes(searchValue.toLowerCase()) ||
                    customerAddress.toLowerCase().includes(searchValue.toLowerCase()) ||
                    customerCity.toLowerCase().includes(searchValue.toLowerCase()) ||
                    customerState.toLowerCase().includes(searchValue.toLowerCase()) ||
                    tagsMatched ||
                    techsMatched)) &&
            (!selectedTab ||
                (selectedTab === "Submitted" && isSubmitted) ||
                (selectedTab === "In Progress" && isInProgress) ||
                (selectedTab === "Pending" && isPending) ||
                (selectedTab === "Pending Payment" && isDonePendingApproval));

        return result;
    };

    return (
        <div className="min-vh-100">
            {showConfirmation && (
                <DispatchDeletePopUpComponent
                    title='Delete this job?'
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancel}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <DispatchLinksComponent />
                </div>
                <div className="col-sm-10 ms-0 ps-0 mt-5 pt-5 pb-4">
                    <div className="row mb-3">
                        <div className="col">
                            <h1>Jobs</h1>
                        </div>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                className="input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Jobs.."
                                disabled={dispatch.length === 0}
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1 m-0 p-0">
                            <Link to={'/dispatch/other'} className="btn btn-sm btn-outline-primary">New Job</Link>
                        </div>
                    </div>
                    <div className="row d-flex mb-2 justify-content-start m-0 p-0">
                        <div className="col-sm-12 m-0 p-0">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${selectedTab === "Submitted" ? "active" : ""}`}
                                        onClick={() => handleTabChange("Submitted")}
                                    >
                                        Submitted ({dispatch.filter(job => job.status === "Submitted").length})
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${selectedTab === "In Progress" ? "active" : ""}`}
                                        onClick={() => handleTabChange("In Progress")}
                                    >
                                        In Progress ({dispatch.filter(job => job.status === "In Progress").length})
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${selectedTab === "Pending" ? "active" : ""}`}
                                        onClick={() => handleTabChange("Pending")}
                                    >
                                        Pending ({dispatch.filter(job => job.status === "Pending").length})
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${selectedTab === "Pending Payment" ? "active" : ""}`}
                                        onClick={() => handleTabChange("Pending Payment")}
                                    >
                                        Pending Payment ({dispatch.filter(job => job.status === "Pending Payment").length})
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {dispatch.filter(job => job.status === selectedTab).length > 0 ? (
                        <>
                            {isLoading ? (
                                <div className="d-flex justify-content-center m-5 p-5">
                                    <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            ) : (
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-center">ID</th>
                                            <th scope="col" className="text-start">Customer Name</th>
                                            <th scope="col" className="text-start">Job Tags</th>
                                            <th scope="col" className="text-start">Job Type</th>
                                            <th scope="col" className="text-start">Scheduled</th>
                                            <th scope="col" className="text-start">Customer Phone</th>
                                            <th scope="col" className="text-start">Technicians</th>
                                            <th scope="col" className="text-start">Customer Address</th>
                                            <th scope="col" className="text-center">Edit/Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dispatch
                                            .filter(filterDispatch)
                                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                            .map((dispatching, idx) => {
                                                const jobTypeName = dispatching.jobType ? dispatching.jobType.name : "";

                                                const tagsRendered = dispatching.jobTags && dispatching.jobTags.map((tagId) => {

                                                    const tagName = tagId.name;
                                                    const tagColor = tagId.color;

                                                    return (
                                                        <li>
                                                            <small
                                                                key={tagId}
                                                                className="smallTag"
                                                                style={{
                                                                    color: '#fff',
                                                                    backgroundColor: `${tagColor}`,
                                                                }}
                                                            >
                                                                {tagName}
                                                            </small>
                                                        </li>
                                                    );
                                                });

                                                const techRendered = dispatching.techs.map((techId) => {
                                                    const techName = techId.user && techId.user.name;
                                                    const techLastName = techId.user && techId.user.lastName;
                                                    const techColor = techId && techId.color;

                                                    return (
                                                        <li>
                                                            <small
                                                                key={techId}
                                                                className="smallTag"
                                                                style={{
                                                                    color: '#fff',
                                                                    backgroundColor: `${techColor}`,
                                                                }}
                                                            >
                                                                {techName} {techLastName}
                                                            </small>
                                                        </li>
                                                    );
                                                });

                                                const date = new Date(dispatching.schedule.start);

                                                const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                                                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                                                const formattedDate = `${daysOfWeek[date.getUTCDay()]} ${months[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()}`;

                                                return (
                                                    <tr key={idx}>
                                                        <td className="text-center align-middle fontSize-10">{dispatching.count}</td>
                                                        <td className="text-center align-middle fontSize-10">{dispatching.customer.name} {dispatching.customer.lastName}</td>
                                                        <td className="text-center tag">
                                                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                                {tagsRendered}
                                                            </ul>
                                                        </td>
                                                        <td className="text-center align-middle fontSize-10">{jobTypeName}</td>
                                                        <td className="text-center align-middle p-0 m-0 fontSize-10">{formattedDate} {dispatching.timeStart}</td>
                                                        <td className="text-center align-middle fontSize-10">{dispatching.customer.phone}</td>
                                                        <td className="text-center tag">
                                                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                                {techRendered}
                                                            </ul>
                                                        </td>
                                                        <td className="text-center align-middle fontSize-8">{dispatching.customer.address} {dispatching.customer.city}, {dispatching.customer.state}, {dispatching.customer.zip}</td>
                                                        <td className="text-center align-middle fontSize-10">
                                                            <Link to={`/dispatch/job/${dispatching._id}`}>
                                                                <button type="button" className="btn btn-sm btn-outline-primary">
                                                                    <GoPencil />
                                                                </button>
                                                            </Link>
                                                            {" / "}
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-outline-danger"
                                                                onClick={() => deleteHandler(dispatching._id)}
                                                            >
                                                                <BsTrash />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                    </tbody>
                                </table>
                            )}
                        </>
                    ) : (
                        <div className="mt-5">
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <MdOutlineInventory className="inventoryLogo fs-1" />
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center m-0 p-0">
                                <div className="col-sm-4 m-0 p-0 text-center">
                                    <span>Currently there is no jobs scheduled.</span> <br />
                                </div>
                            </div>
                        </div>
                    )}
                    {dispatch.filter(job => job.status === selectedTab).length > 0 && (
                        <div className="d-flex justify-content-center">
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-primary mr-2 me-3 loginButton"
                                disabled={page === 1}
                                onClick={handlePrevPage}
                            >
                                Previous
                            </button>
                            <span className="mr-2 me-3">
                                Page {page} of {Math.ceil(dispatch.filter(filterDispatch).length / rowsPerPage)}
                            </span>
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-primary loginButton"
                                disabled={dispatch.filter(filterDispatch).length <= page * rowsPerPage}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DispatchPanelJobComponent;