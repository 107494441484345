import axios from "axios";
import JobSourceComponent from "./components/jobSourceComponent";

const fetchJobSource = async (abctrl) => {
    const { data } = await axios.get("/api/jobSource/dispatch", { withCredentials: true });
    return data
}

const deleteJobSource = async (jobSourceId) => {
    const { data } = await axios.delete(`/api/jobSource/dispatch/${jobSourceId}`, { withCredentials: true });
    return data
}

const JobSourcePage = () => {

    return <JobSourceComponent fetchJobSource={fetchJobSource} deleteJobSource={deleteJobSource} />;
};

export default JobSourcePage;
