import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeClockLinksComponent from "../../../components/timeClock/timeClockLinksComponent";
import LoadingComponent from "../../../components/loadingComponet";
import RequestTimeOffPopComponent from "../../../components/timeClock/requestTimeOffPopComponent";
import { useNavigate } from "react-router-dom";
import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import { useEffect } from "react";
import paths from "../../../router/paths";
import TimeRequestDeleteComponent from "../../../components/timeClock/timeRequestDeleteComponent";
import RequestTimeOffStatComponent from "../../../components/timeClock/requestTimeOffStatComponent";
import RequestTimeOffEditComponent from "../../../components/timeClock/requestTimeOffEditComponent";
import { MdOutlineInventory } from "react-icons/md";
import { logout } from "../../../redux/actions/action";

const RequestTimeOffComponent = ({ getRequests, getSchedule, createTimeOffRequest, deleteTimeOff, updateTimeOffRequest, updateTimeOffRequestAll }) => {
    const auth = useSelector(state => state.auth)
    const [showConfirmationNew, setShowConfirmationNew] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [requests, setRequests] = useState([]);
    const [requestId, setRequestId] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertStat, setShowAlertStat] = useState(false);
    const [requestStat, setRequestStat] = useState("");
    const [showAlertEdit, setShowAlertEdit] = useState(false);
    const [requestEdit, setRequestEdit] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;

    useEffect(() => {
        if (!auth?.isAuthenticated) {
            dispatch(logout());
            navigate(paths.LOGIN);
        } else {
            setLoading(true);
            getRequests().then(scheduleData => {
                const filter = scheduleData.filter((itm) => itm.company === auth.user.company);
                const filterUser = scheduleData.filter((itm) => itm.userId._id === auth.user._id);

                if (isAdmin || isSuperAdmin) {
                    setRequests(filter);
                    setLoading(false);
                } else {
                    setRequests(filterUser);
                    setLoading(false);
                }

            }).catch(error => {
                console.error("Error fetching schedule data:", error);
                setLoading(false);
            });
        }
    }, [auth.user._id, getSchedule, auth.user.name, dispatch, navigate, auth.user?.token, auth.user.company, getRequests, auth.user.isAdmin, auth.user.isSuperAdmin]);

    useEffect(() => {
        if (loading) {
            if (!auth?.isAuthenticated) {
                dispatch(logout());
                navigate(paths.LOGIN);
            } else {
                getRequests().then(scheduleData => {
                    const filter = scheduleData.filter((itm) => itm.company === auth.user.company);
                    const filterUser = scheduleData.filter((itm) => itm.userId._id === auth.user._id);

                    if (isAdmin || isSuperAdmin) {
                        setRequests(filter);
                        setLoading(false);
                    } else {
                        setRequests(filterUser);
                        setLoading(false);
                    }

                }).catch(error => {
                    console.error("Error fetching schedule data:", error);
                    setLoading(false);
                });
            }
        }
    }, [loading, auth.user._id, getSchedule, auth.user.name, dispatch, navigate, auth.user?.token, auth.user.company, getRequests, auth.user.isAdmin, auth.user.isSuperAdmin]);

    const handleCancelNew = () => {
        setShowConfirmationNew(false);
    };

    const handleConfirmNew = async () => {
        setShowConfirmationNew(false);
        setLoading(true);
    }

    const newHandler = (workId) => {
        setShowConfirmationNew(true);
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
    };

    const formatDate = (isoString) => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const date = new Date(isoString);
        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();

        return `${dayOfWeek}, ${month} ${dayOfMonth}, ${year}`;
    }

    const deleteHandler = async (requestId) => {
        setRequestId(requestId)
        setShowAlert(true);
    };

    const handleCancelRemove = () => {
        setShowAlert(false);
    };

    const handleConfirmRemove = async () => {
        const data = await deleteTimeOff(requestId);
        if (data.message === 'Time Request deleted successfully') {
            setShowAlert(false);
            setLoading(true);
        }
    };

    const StatHandler = async (requestId, stat) => {
        setRequestId(requestId)
        setRequestStat(stat);
        setShowAlertStat(true);
    };

    const handleCancelStat = () => {
        setShowAlertStat(false);
    };

    const handleConfirmStat = () => {
        let stat = '';

        if (requestStat === 'approve') {
            stat = 'Approved';
        } else if (requestStat === 'deny') {
            stat = 'Denied';
        } else if (requestStat === 'cancel') {
            stat = 'Cancelled';
        }

        const formInputs = {
            status: stat,
        };

        updateTimeOffRequest(requestId, formInputs)
            .then((data) => {
                if (data.message === "Time off status updated") {
                    setShowAlertStat(false);
                    setLoading(true);
                }
            })
            .catch((error) => {
                console.error(error.response?.data?.message || error.response?.data || error.message);
            });
    };

    const EditHandler = async (item) => {
        setRequestEdit(item);
        setShowAlertEdit(true);
    };

    const handleCancelEdit = () => {
        setShowAlertEdit(false);
    };

    const handleConfirmEdit = () => {
        setShowAlertEdit(false);
        setLoading(true);
    };

    return (
        <div className="min-vh-100">
            <div className="container-fluid min-vh-100 m-0 p-0">
                {showAlert && (
                    <TimeRequestDeleteComponent
                        onConfirm={handleConfirmRemove}
                        onCancel={handleCancelRemove}
                    />
                )}
                {showAlertStat && (
                    <RequestTimeOffStatComponent
                        message={requestStat}
                        onConfirm={handleConfirmStat}
                        onCancel={handleCancelStat}
                    />
                )}
                {showAlertEdit && (
                    <RequestTimeOffEditComponent
                        updateTimeOffRequestAll={updateTimeOffRequestAll}
                        onConfirm={handleConfirmEdit}
                        onCancel={handleCancelEdit}
                        requestEdit={requestEdit}
                        getSchedule={getSchedule}
                    />
                )}
                {showConfirmationNew && (
                    <RequestTimeOffPopComponent
                        onConfirm={handleConfirmNew}
                        onCancel={handleCancelNew}
                        getSchedule={getSchedule}
                        createTimeOffRequest={createTimeOffRequest}
                    />
                )}
                <div className="row justify-content-md-center min-vh-100 m-0 p-0">
                    <div className="col-sm-2 m-0 p-0">
                        <TimeClockLinksComponent />
                    </div>
                    {loading && (
                        <div className="loading-overlay">
                            <LoadingComponent />
                        </div>
                    )}
                    <div className="col-sm-10 mt-5 pt-5">
                        <div className="row m-0 p-0 w-100 mb-3">
                            <div className="col-sm-7 m-0 p-0">
                                <h1>Time Off Requests</h1>
                            </div>
                            <div className="col-sm-3 align-end m-0 p-0">
                                <input
                                    type="text"
                                    className="mt-1 input-group table-filter w-100"
                                    data-table="order-table"
                                    placeholder="Search requests.."
                                    value={searchValue}
                                    disabled={requests.length === 0}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <div className="col-sm-2 text-center m-0 mt-1 p-0">
                                <button onClick={newHandler} className="btn btn-outline-primary btn-sm loginButton">Request Time Off</button>
                            </div>
                        </div>
                        {requests.length > 0 ? (
                            <>
                                {isAdmin || isSuperAdmin ? (
                                    <div className="table-responsive">
                                        <table className="table-hover table-bordered order-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-start">Employee Name</th>
                                                    <th className="text-start">Start Date</th>
                                                    <th className="text-start">End Date</th>
                                                    <th className="text-start">Full Days</th>
                                                    <th className="text-start">Reason</th>
                                                    <th className="text-start">Status</th>
                                                    <th className="text-center">Approve/Deny/Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {requests
                                                    .filter(
                                                        (itm) =>
                                                            itm.reason.toLowerCase().indexOf(searchValue) !== -1
                                                    )
                                                    .map((itm, idx) => (
                                                        <>
                                                            <tr key={itm._id + idx}>
                                                                <td className="align-middle text-start">{itm.userId.name} {itm.userId.lastName}</td>
                                                                <td className="align-middle text-center">
                                                                    {formatDate(itm.startDate)}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {formatDate(itm.endDate)}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {itm.allDay === true ? (
                                                                        <span className="">True</span>
                                                                    ) : (
                                                                        <span className="">False</span>
                                                                    )}
                                                                </td>
                                                                <td className="align-middle text-start ps-2" style={{ maxWidth: '30rem', overflowX: 'auto' }}>
                                                                    <div className="w-100" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                        {itm.reason}
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {itm.status}
                                                                </td>
                                                                {itm.status === 'Approved' ? (
                                                                    <td className="align-middle text-center">
                                                                        {itm.userId._id === auth.user._id ? (
                                                                            <button
                                                                                className="btn-sm btn btn-outline-success"
                                                                                disabled
                                                                            >
                                                                                <GoPencil className="requestLogo" />
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                className="btn-sm btn btn-outline-success"
                                                                                disabled
                                                                            >
                                                                                <IoMdCheckmark className="requestLogo" />
                                                                            </button>
                                                                        )}
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            disabled
                                                                        >
                                                                            <IoMdClose className="requestLogo" />
                                                                        </button>
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            disabled
                                                                        >
                                                                            <BsTrash className="requestLogo" />
                                                                        </button>
                                                                    </td>
                                                                ) : itm.status === 'Cancelled' ? (
                                                                    <td className="align-middle text-center">
                                                                        <button
                                                                            className="btn-sm btn btn-outline-success"
                                                                            disabled
                                                                        >
                                                                            <IoMdCheckmark className="requestLogo" />
                                                                        </button>
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            disabled
                                                                        >
                                                                            <IoMdClose className="requestLogo" />
                                                                        </button>
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            onClick={() => deleteHandler(itm._id)}
                                                                        >
                                                                            <BsTrash className="requestLogo" />
                                                                        </button>
                                                                    </td>
                                                                ) : itm.status === 'Denied' ? (
                                                                    <td className="align-middle text-center">
                                                                        <button
                                                                            className="btn-sm btn btn-outline-success"
                                                                            disabled
                                                                        >
                                                                            <IoMdCheckmark className="requestLogo" />
                                                                        </button>
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            disabled
                                                                        >
                                                                            <IoMdClose className="requestLogo" />
                                                                        </button>
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            onClick={() => deleteHandler(itm._id)}
                                                                        >
                                                                            <BsTrash className="requestLogo" />
                                                                        </button>
                                                                    </td>
                                                                ) : (
                                                                    <>
                                                                        {itm.userId._id === auth.user._id ? (
                                                                            <td className="align-middle text-center">
                                                                                <button
                                                                                    className="btn-sm btn btn-outline-primary"
                                                                                    onClick={() => EditHandler(itm)}
                                                                                >
                                                                                    <GoPencil className="requestLogo" />
                                                                                </button>
                                                                                {" / "}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-sm btn btn-outline-danger"
                                                                                    onClick={() => StatHandler(itm._id, 'cancel')}
                                                                                >
                                                                                    <IoMdClose className="requestLogo" />
                                                                                </button>
                                                                                {" / "}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-sm btn btn-outline-danger"
                                                                                    onClick={() => deleteHandler(itm._id)}
                                                                                >
                                                                                    <BsTrash className="requestLogo" />
                                                                                </button>
                                                                            </td>
                                                                        ) : (
                                                                            <td className="align-middle text-center">
                                                                                <button
                                                                                    className="btn-sm btn btn-outline-success"
                                                                                    onClick={() => StatHandler(itm._id, 'approve')}
                                                                                >
                                                                                    <IoMdCheckmark className="requestLogo" />
                                                                                </button>
                                                                                {" / "}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-sm btn btn-outline-danger"
                                                                                    onClick={() => StatHandler(itm._id, 'deny')}
                                                                                >
                                                                                    <IoMdClose className="requestLogo" />
                                                                                </button>
                                                                                {" / "}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-sm btn btn-outline-danger"
                                                                                    onClick={() => deleteHandler(itm._id)}
                                                                                >
                                                                                    <BsTrash className="requestLogo" />
                                                                                </button>
                                                                            </td>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </tr>
                                                        </>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="table-responsive">
                                        <table className="table-hover table-bordered order-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-start">Start Date</th>
                                                    <th className="text-start">End Date</th>
                                                    <th className="text-start">Full Days</th>
                                                    <th className="text-start">Reason</th>
                                                    <th className="text-start">Status</th>
                                                    <th className="text-center">Edit/Cancel</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {requests
                                                    .filter(
                                                        (itm) =>
                                                            itm.reason.toLowerCase().indexOf(searchValue) !== -1
                                                    )
                                                    .map((itm, idx) => (
                                                        <>
                                                            <tr key={itm._id + idx}>
                                                                <td className="align-middle text-center">
                                                                    {formatDate(itm.startDate)}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {formatDate(itm.endDate)}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {itm.allDay === true ? (
                                                                        <span className="">True</span>
                                                                    ) : (
                                                                        <span className="">False</span>
                                                                    )}
                                                                </td>
                                                                <td className="align-middle text-start ps-2" style={{ maxWidth: '30rem', overflowX: 'auto' }}>
                                                                    <div className="w-100" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                        {itm.reason}
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {itm.status}
                                                                </td>
                                                                {itm.status === 'Approved' ? (
                                                                    <td className="align-middle text-center">
                                                                        <button
                                                                            className="btn-sm btn btn-outline-primary"
                                                                            disabled
                                                                        >
                                                                            <GoPencil className="requestLogo" />
                                                                        </button>
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            onClick={() => StatHandler(itm._id, 'cancel')}
                                                                        >
                                                                            <IoMdClose className="requestLogo" />
                                                                        </button>
                                                                    </td>
                                                                ) : itm.status === 'Cancelled' ? (
                                                                    <td className="align-middle text-center">
                                                                        <button
                                                                            className="btn-sm btn btn-outline-primary"
                                                                            disabled
                                                                        >
                                                                            <GoPencil className="requestLogo" />
                                                                        </button>
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            disabled
                                                                        >
                                                                            <IoMdClose className="requestLogo" />
                                                                        </button>
                                                                    </td>
                                                                ) : itm.status === 'Denied' ? (
                                                                    <td className="align-middle text-center">
                                                                        <button
                                                                            className="btn-sm btn btn-outline-primary"
                                                                            disabled
                                                                        >
                                                                            <GoPencil className="requestLogo" />
                                                                        </button>
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            disabled
                                                                        >
                                                                            <IoMdClose className="requestLogo" />
                                                                        </button>
                                                                    </td>
                                                                ) : (
                                                                    <td className="align-middle text-center">
                                                                        <button
                                                                            className="btn-sm btn btn-outline-primary"
                                                                            onClick={() => EditHandler(itm)}
                                                                        >
                                                                            <GoPencil className="requestLogo" />
                                                                        </button>
                                                                        {" / "}
                                                                        <button
                                                                            type="button"
                                                                            className="btn-sm btn btn-outline-danger"
                                                                            onClick={() => StatHandler(itm._id, 'cancel')}
                                                                        >
                                                                            <IoMdClose className="requestLogo" />
                                                                        </button>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        </>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="mt-5">
                                <div className="row d-flex justify-content-center m-0 p-0">
                                    <div className="col-sm-4 m-0 p-0 text-center">
                                        <MdOutlineInventory className="inventoryLogo fs-1" />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center m-0 p-0">
                                    <div className="col-sm-4 m-0 p-0 text-center">
                                        <span>Currently no time off requests.<br /> Request time off by clicking on request time off button.</span> <br />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default RequestTimeOffComponent;