import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";
import stateOptions from "../../../components/dispatch/stateOptions";
import countryOptions from "../../../components/dispatch/countryOptions";

const CreateCustomerPageComponent = ({ createCustomerApiRequest }) => {
    const [validated, setValidated] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [email, setEmail] = useState('');

    const auth = useSelector(state => state.auth)
    const [createCustomerResponseState, setCreateCustomerResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            name: form.name.value,
            lastName: form.name1.value,
            address: form.address.value,
            city: form.city.value,
            state: selectedState,
            zip: form.zip.value,
            phone: phoneNumber,
            email: email,
            country: selectedCountry,
            company: auth.user.company,
            phoneExt: form.phoneExt.value,
        };
        if (event.currentTarget.checkValidity() === true) {
            createCustomerApiRequest(formInputs)
                .then((data) => {
                    if (data.message === "Customer created") navigate(`${paths.DISPATCHCUTOMER}`);
                })
                .catch((er) => {
                    setCreateCustomerResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }
        setValidated(true);
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
        setSelectedState('');
    }
    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
    }

    const formatPhoneNumber = (input) => {
        const cleaned = ('' + input).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return input;
    };

    const handlePhoneChange = (e) => {
        const inputPhone = e.target.value;
        const formatted = formatPhoneNumber(inputPhone);
        setPhoneNumber(formatted);

        setIsValidPhone(formatted.length === 14);
    };

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setIsValidEmail(validateEmail(newEmail));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className="pt-5 mt-3">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-6">
                        <h1 className="text-center">New Customer</h1>
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="row mt-4 g-3">
                            <div className="row m-0 p-0 w-100 mt-3">
                                <div className="col-sm m-0 p-0 me-3">
                                    <label htmlFor="name">First Name:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                    />
                                </div>
                                <div className="col-sm m-0 p-0">
                                    <label htmlFor="name1">Last Name:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="name1"
                                        name="name1"
                                    />
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="col-sm m-0 p-0 me-3">
                                    <label htmlFor="phone">Phone Number:</label>
                                    <input
                                        type="text"
                                        className={`form-control ${isValidPhone ? '' : 'is-invalid'}`}
                                        id="phone"
                                        placeholder="(xxx) xxx-xxxx"
                                        name="phone"
                                        onChange={handlePhoneChange}
                                        defaultValue={phoneNumber}
                                    />
                                    {!isValidPhone && (
                                        <div className="invalid-feedback">Please enter a valid phone number.</div>
                                    )}
                                </div>
                                <div className="col-sm m-0 p-0">
                                    <label htmlFor="phoneExt">Phone Extension:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="phoneExt"
                                        placeholder="Ext."
                                        name="phoneExt"
                                    />
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="col m-0 p-0">
                                    <label htmlFor="email">Email:</label>
                                    <input
                                        type="email"
                                        className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                                        id="email"
                                        placeholder="Email"
                                        name="email"
                                        onChange={handleEmailChange}
                                        defaultValue={email}
                                    />
                                    {!isValidEmail && (
                                        <div className="invalid-feedback">
                                            Please enter a valid email address.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="col-sm m-0 p-0">
                                    <label htmlFor="address">Customer Address:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="address"
                                        name="address" />
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3">
                                <div className="col-sm m-0 p-0 me-3">
                                    <label htmlFor="city">City:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="city"
                                        name="city" />
                                </div>
                                <div className="col-sm m-0 p-0">
                                    <label htmlFor="zip">Zip Code:</label>
                                    <input type="text"
                                        className="form-control"
                                        id="zip"
                                        name="zip" />
                                </div>
                            </div>
                            <div className="row m-0 p-0 mt-3 mb-4">
                                <div className="col-sm m-0 p-0 me-3">
                                    <label htmlFor="country">Country:</label>
                                    <select
                                        className="form-control"
                                        id="country"
                                        name="country"
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                    >
                                        <option value="" disabled>Select a country</option>
                                        {countryOptions.map((country) => (
                                            <option key={country} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm m-0 p-0">
                                    <label htmlFor="state">State:</label>
                                    <select
                                        className="form-control"
                                        id="state"
                                        name="state"
                                        value={selectedState}
                                        onChange={handleStateChange}
                                        disabled={!selectedCountry} // Disable the state select if no country is selected
                                    >
                                        <option value="" disabled>Select a state</option>
                                        {selectedCountry && stateOptions[selectedCountry]?.map((state) => (
                                            <option key={state.name} value={state.name}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <button type="submit" className="btn btn-outline-primary mb-3">Submit</button>
                                <Link to={paths.DISPATCHCUTOMER} className="btn btn-outline-success mb-3 ms-2">Go Back</Link>
                            </div>
                            {createCustomerResponseState.error ?? ""}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCustomerPageComponent;