import axios from "axios";
import { useDispatch } from "react-redux";
import CreateJobSourcePageComponent from "./components/newJobSourceComponent";

const createJobSourceApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/jobSource/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const CreateJobSourcePage = () => {
    const dispatch = useDispatch();

    return (
        <CreateJobSourcePageComponent
            createJobSourceApiRequest={createJobSourceApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default CreateJobSourcePage;