import axios from 'axios'
import { useDispatch } from "react-redux";
import InventoryEditComponent from './components/inventoryEditComponent';

const fetchInventory = async (inventoryId) => {
    const { data } = await axios.get(`/api/inventory/get-one/${inventoryId}`, { withCredentials: true });
    return data;
}

const updateInventoryApiRequest = async (inventoryId, formInputs) => {
    const { data } = await axios.put(`/api/inventory/admin/${inventoryId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const fetchUploads = async (imageId) => {
    const { data } = await axios.get(`/api/uploads/image/${imageId}`, { withCredentials: true });
    return data
}
const fetchLocation = async (abctrl) => {
    const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
    return data
}
const fetchMake = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleMake/", { withCredentials: true })
    return data;
}
const fetchModel = async (make) => {
    const { data } = await axios.get(`/api/vehicleModel?make=${make}`, { withCredentials: true })
    return data;
}
const fetchYear = async (model) => {
    const { data } = await axios.get(`/api/vehicleYear?model=${model}`, { withCredentials: true });
    return data
}
const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/admin", { withCredentials: true });
    return data
}
const fetchUsers = async (abctrl) => {
    const { data } = await axios.get("/api/users", { withCredentials: true });
    return data
}

const InventoryEditPage = () => {

    const reduxDispatch = useDispatch();


    return <InventoryEditComponent fetchTechInfo={fetchTechInfo} fetchUsers={fetchUsers} fetchLocation={fetchLocation} fetchMake={fetchMake} fetchModel={fetchModel} fetchYear={fetchYear} fetchUploads={fetchUploads} fetchInventory={fetchInventory} updateInventoryApiRequest={updateInventoryApiRequest} reduxDispatch={reduxDispatch} />;
};

export default InventoryEditPage;
