import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/action';
import { useNavigate, Link } from "react-router-dom";
import paths from '../../router/paths';

const LoginPageComponent = () => {
    const { error, loading } = useSelector(state => state.auth);
    const [rememberMe, setRememberMe] = useState(false);
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const loginHandler = async (e) => {
        e.preventDefault();

        const formData = {
            username: email,
            password: password,
            remember: rememberMe,
        };

        const responseMessage = await dispatch(login(formData, () => { }));

        if (responseMessage.message === 'Successful Login!') {
            navigate(paths.ROOT);
        } else if (responseMessage.message === '2FA code sent to your email!') {
            navigate(`/verify-otp/${responseMessage.user}`);
        } else {
            setMessage(responseMessage); // Set the message from the action response
        }
    };

    return (
        <div className="container-fluid mt-5 pt-5 min-vh-100 w-100">
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row margintop ps-4 pe-4 pt-5 mt-5 justify-content-md-center">
                    <div className="col-sm-6 col-md-4 col-lg-3 p-3 color-white">
                        <div className="text-start pb-3 mb-3">
                            <h1 className="loginTitleFont">Sign In</h1>
                            <span className="">or <Link to={paths.REGISTER}>create an account</Link></span>
                        </div>
                        {message && <p className="text-center" style={{ color: 'red' }}>{message}</p>}
                        <form onSubmit={loginHandler}>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control signinFormInput"
                                    placeholder="Username"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control signinFormInput"
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="exampleCheck1"
                                    checked={rememberMe}
                                    onChange={e => setRememberMe(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="exampleCheck1">
                                    Remember me
                                </label>
                            </div>
                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary w-100 loginButton"
                                    disabled={!email || !password}
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                        <div className="mt-3">
                            <Link to={paths.PASSWORDASSISTANCE}>Trouble logging in?</Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoginPageComponent;