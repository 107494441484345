import axios from "axios";
import AdminEditMainUserComponent from "./components/adminEditMainUserComponent";

const fetchUser = async (userId) => {
    const { data } = await axios.get(`/api/users/${userId}`, { withCredentials: true });
    return data;
}

const fetchTechInfo = async (abctrl) => {
    const { data } = await axios.get("/api/techInfo/admin", { withCredentials: true });
    return data
}

const updateUserApiRequest = async (userId, formInputs) => {
    const { data } = await axios.put(`/api/users/${userId}`, { ...formInputs }, { withCredentials: true });
    return data;
}

const updateTechApiRequest = async (techId, formInputTechEdit) => {
    const { data } = await axios.put(`/api/techInfo/admin/${techId}`, { ...formInputTechEdit }, { withCredentials: true });
    return data;
}

const AdminEditMainUserPage = () => {

    return <AdminEditMainUserComponent updateTechApiRequest={updateTechApiRequest} fetchTechInfo={fetchTechInfo} updateUserApiRequest={updateUserApiRequest} fetchUser={fetchUser} />;
};

export default AdminEditMainUserPage;
