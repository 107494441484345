import axios from "axios";
import AdminLocksmithInfoComponent from "./components/adminLocksmithInfoComponent";

const fetchLocksmithInfo = async (abctrl) => {
    const { data } = await axios.get("/api/locksmithInfo/admin", { withCredentials: true });
    return data
}

const deleteLocksmithInfo = async (locksmithInfoId) => {
    const { data } = await axios.delete(`/api/locksmithInfo/${locksmithInfoId}`, { withCredentials: true });
    return data
}

const AdminLocksmithInfoPage = () => {

    return <AdminLocksmithInfoComponent fetchLocksmithInfo={fetchLocksmithInfo} deleteLocksmithInfo={deleteLocksmithInfo} />;
};

export default AdminLocksmithInfoPage;
