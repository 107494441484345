import React from "react";
import { BsConeStriped } from 'react-icons/bs';

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel} // Close modal if clicked outside
            />
            <div className="confirmation-box">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row fw-bolder text-warning coneConfir mb-4">
                        <BsConeStriped />
                    </div>
                <text className="fw-bold fs-5">{message}</text><br />
                <div className="row justify-content-center mt-5 border-top pt-3 border-dark">
                    <div className="col-sm-2 me-5">
                         <button onClick={onConfirm} className="btn btn-outline-danger m-0 ps-4 pe-4">
                            Yes
                        </button>
                    </div>
                    <div className="col-sm-2 me-4">
                         <button className="btn btn-outline-primary" onClick={onCancel}>
                             Cancel
                         </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default ConfirmationModal;
