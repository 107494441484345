import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import '../assets/inventory.css';

const InventoryDispatchComponent = ({ fetchUploadsID, model, years, name }) => {
    const [inventory, setInventory] = useState([]);
    const [loading, setloading] = useState(false);

    const getModelAndPriceFromURL = useCallback(async () => {
        setloading(true);
        try {
            if (name && model && years) {
                const { data } = await axios.get(`/api/inventory?make=${name}&year=${years}&model=${model}`);

                setInventory(data);

                data.forEach(async (item) => {
                    if (item.image) {
                        const imageURL = await fetchUploadsID(item.image);
                        item.imageUrl = imageURL; // Add imageURL to the item
                    }
                });
                setloading(false);

            }
        } catch (error) {
            setInventory([
                { name: error.response?.data?.message || error.response?.data || 'An error occurred.' }
            ]);
        }
    }, [model, years, name, fetchUploadsID]);

    useEffect(() => {
        const abctrl = new AbortController();

        getModelAndPriceFromURL();

        return () => abctrl.abort();
    }, [years, model, getModelAndPriceFromURL]);

    useEffect(() => {
        const abctrl = new AbortController();
        if(loading) {
            getModelAndPriceFromURL();
        }

        return () => abctrl.abort();
    }, [years, model, getModelAndPriceFromURL, loading]);

    return (
        <div className="container-fluid mt-4 w-100 pt-3">
            <hr className="w-100" />
            {inventory.map((item, idx) => (
                <React.Fragment key={idx}>
                    <div className="row align-items-center">
                        <div className="col-sm-2 text-center d-flex justify-content-center">
                            {item.imageUrl && (
                                <img src={item.imageUrl.imageUrl} className="img-fluid-locksmith" alt={`Item ${idx}`} />
                            )}
                        </div>
                        <div className="col-sm-10">
                            <div className="row">
                                <div className="col-sm-12">
                                    <strong>Product:</strong> {item.name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <strong>Location:</strong> {item.location}
                                </div>
                            </div>
                            {item.dontStock === true ? (
                                <div className="row">
                                    <div className="col-sm-3">
                                        <strong>Stock:</strong>
                                        <span className="text-danger"> Special Order Key</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {item.stock === 0 ? (
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <strong>Stock:</strong>
                                                <span className="text-danger"> Out Of Stock</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <strong>Stock:</strong>
                                                <span className="text-success"> {item.stock}</span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {item.sku && (
                                <div className="row">
                                    <div className="col-sm-6">
                                        <strong>SKU:</strong> {item.sku}
                                    </div>
                                </div>
                            )}
                            {item.fccId && (
                                <div className="row">
                                    <div className="col-sm-6">
                                        <strong>FCC ID:</strong> {item.fccId}
                                    </div>
                                </div>
                            )}
                            {item.chip && (
                                <div className="row">
                                    <div className="col-sm-6">
                                        <strong>Chip ID:</strong> {item.chip}
                                    </div>
                                </div>
                            )}
                            {item.frequency && (
                                <div className="row">
                                    <div className="col-sm-3">
                                        <strong>Frequency:</strong> {item.frequency}
                                    </div>
                                </div>
                            )}
                           
                            {item.replacementPin && (
                                <div className="row">
                                    <div className="col-sm-12">
                                        <strong>Replaces PN:</strong> {item.replacementPin}
                                    </div>
                                </div>
                            )}
                            <div className="row pb-4">
                                <div className="col-sm-4">
                                    <strong>Selling Price:</strong> ${item.price}
                                </div>
                                <div className="col-sm-5">
                                    <strong>Our Cost:</strong> ${item.ourCost}
                                </div>
                               
                            </div>

                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default InventoryDispatchComponent;
