import jsPDF from 'jspdf';

export function InvoiceSendGeneratePDF(totalPay, dueTerm, uploadFileToDatabaseInvoice, companies, imgData, formatDateTime, invoice, filterJobTax, customer, jobItem, signatureData) {

    const doc = new jsPDF("p", "mm", "a4");

    const today = new Date();

    let dueDate = 'dueOn';

    if (dueTerm === "dueOn") {
        dueDate = today;
    } else if (dueTerm === "net15" || dueTerm === "net30" || dueTerm === "net45") {
        const netDays = parseInt(dueTerm.substring(3)); // Extract the number from the term
        dueDate = new Date(today); // Update the existing dueDate variable
        dueDate.setDate(dueDate.getDate() + netDays);
    } else {
        console.log("Invalid dueTerm:", dueTerm);
    }

    if (imgData) {
        doc.addImage(imgData, "PNG", 10, 5, 80, 60);
    }

    doc.setFontSize(25);
    doc.text("INVOICE", 160, 9);

    doc.setFontSize(10);
    doc.text(`${companies.name}`, 10, 80);
    doc.text(`${companies.address}`, 10, 85);
    doc.text(`${companies.city}, ${companies.state} ${companies.zip}, ${companies.country}`, 10, 90);
    doc.text(`${companies.email}`, 10, 95);
    doc.text(`${companies.phone}`, 10, 100);

    doc.setFontSize(10);
    doc.text(`Invoice #`, 139, 80);
    doc.text(`${invoice.count}`, 160, 80);
    doc.text(`Date`, 145, 85);
    doc.text(`${formatDateTime(invoice.updatedAt)}`, 160, 85);
    doc.text(`Balance`, 140, 90);
    doc.text(`Due On`, 140, 95);
    doc.text(`${dueDate.toDateString()}`, 160, 95);

    doc.setFontSize(12);
    doc.text(`Bill To:`, 10, 110);

    doc.setFontSize(10);
    doc.text(`${customer.name} ${customer.lastName}`, 12, 115);
    doc.text(`${customer.address}`, 12, 120);
    doc.text(`${customer.city}, ${customer.state} ${customer.zip}`, 12, 125);
    if (customer.phone) {
        doc.text(`${customer.phone}`, 12, 130);
    }
    if (customer.email) {
        doc.text(`${customer.email}`, 12, 135);
    }

    doc.setFontSize(12);
    doc.text(`Service Location:`, 100, 110);

    doc.setFontSize(10);
    doc.text(`${customer.name} ${customer.lastName}`, 102, 115);
    doc.text(`${customer.address}`, 102, 120);
    doc.text(`${customer.city}, ${customer.state} ${customer.zip}`, 102, 125);
    if (customer.phone) {
        doc.text(`${customer.phone}`, 102, 130);
    }
    if (customer.email) {
        doc.text(`${customer.email}`, 102, 135);
    }

    const tableData = [
        ['Description', 'QTY', 'Price', 'Amount'],
        ...jobItem.map((item) => {
            const amount = `$${(item.quantity * item.price).toFixed(2)}`;
            const isTaxable = item.taxable !== undefined ? item.taxable : true;
            const isOptional = item.optional !== undefined ? item.optional : false;

            let tax = 0;
            if (isTaxable && !isOptional) {
                tax = parseFloat(((item.quantity * item.price * filterJobTax.taxRate) / 100).toFixed(2));
            }

            return [
                `${item.name}\n${item.description}`,
                item.quantity,
                `$${item.price}`,
                `${amount}`,
                tax, // Add tax column
            ];
        }),
    ];

    const subtotal = jobItem.reduce((acc, row) => {
        const amount = parseFloat(row.quantity * row.price);
        return acc + amount;
    }, 0);

    const totalTax = tableData.reduce((acc, row) => acc + (row[4] || 0), 0); // Sum tax column from table data

    let lastTableRowY = 0;

    let discountValue = 0;
    if (invoice.discountType === 'percent') {
        // If the discount type is a percent, calculate the discount as a percentage of the subtotal
        discountValue = (invoice.discount / 100) * subtotal;
    } else if (invoice.discountType === 'amount') {
        // If the discount type is an amount, use the provided discount value
        discountValue = invoice.discount;
    }

    const taxValue = parseFloat(((subtotal - discountValue) * filterJobTax.taxRate / 100).toFixed(2));

    const total = parseFloat((subtotal - discountValue + totalTax).toFixed(2));


    doc.autoTable({
        startY: 140,
        head: tableData.slice(0, 1),
        body: tableData.slice(1),
        didDrawPage: function (data) {
            // Keep track of the Y position of the last row in the first table
            lastTableRowY = data.cursor.y + 10; // Adjust the 10 as needed for spacing
        },
    });
    let startY = lastTableRowY;

    var totalPayString = totalPay.toString();

    doc.text(totalPayString, 160, 90);

    doc.text(`Sub Total:`, 130, startY);
    doc.text(`$${subtotal.toFixed(2)}`, 160, startY);
    startY += 8;
    if (invoice.discount > 0) {
        doc.text(`Discount:`, 130, startY);
        doc.text(`$${discountValue.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Tax:`, 139, startY);
        doc.text(`${taxValue.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Tax Rate:`, 131, startY);
        doc.text(`${filterJobTax.taxRate}%`, 160, startY);
        startY += 8;
        doc.text(`Total:`, 137, startY);
        doc.text(`$${total.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Balance:`, 133, startY);
        doc.text(`$${totalPayString}`, 160, startY);
    } else {
        doc.text(`Tax:`, 139, startY);
        doc.text(`${taxValue.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Tax Rate:`, 131, startY);
        doc.text(`${filterJobTax.taxRate}%`, 160, startY);
        startY += 8;
        doc.text(`Total:`, 137, startY);
        doc.text(`$${total.toFixed(2)}`, 160, startY);
        startY += 8;
        doc.text(`Balance:`, 133, startY);
        doc.text(`$${totalPayString}`, 160, startY);
    }
    startY += 10;
    if (startY > 290) {
        doc.addPage(); // Add a new page
        startY = 15;
    }
    doc.setFontSize(12);
    doc.text("Terms:", 10, startY);
    startY += 5;
    doc.setFontSize(10);
    doc.text("By paying the due balance on invoices provided, the Client hereby acknowledges that all requested service", 14, startY);
    startY += 5;
    doc.text("items for this date and/or any other dates listed above in the description section of the table, have been", 14, startY);
    startY += 5;
    doc.text("performed and have been tested showing successful satisfactory install/repair, unless otherwise stated on", 14, startY);
    startY += 5;
    doc.text("the invoice, in which labor service charges still apply if any repairs have been made. By accepting this", 14, startY);
    startY += 5;
    doc.text("invoice, the Client agrees to pay in full the amount listed in the Total section of the invoice.", 14, startY);
    startY += 10;
    if (startY > 290) {
        doc.addPage();
        startY = 15;
    }
    doc.setFontSize(12);
    doc.text("Notes:", 10, startY);
    startY += 5;
    if (startY > 290) {
        doc.addPage();
        startY = 15;
    }
    if (invoice.notes) {
        doc.setFontSize(10);
        doc.text(`${invoice.notes}`, 14, startY);
    };
    startY += 30;

    if (startY > 290) {
        doc.addPage();
        startY = 15;
    }
    doc.setFontSize(25);
    doc.text("Thank You For Your Business!", 50, startY);

    startY += 25;


    if (signatureData.length > 0) {
        if (startY > 290) {
            doc.addPage();
            startY = 15;
        }
        const tableSignData = [
            ['Signature', 'Signed By', 'Signed'],
            ...signatureData.map((sign) => {
                //const imgData = sign.signature; // Assuming sign.signature contains base64 encoded image data
                //const imageWidth = 20; // Set the desired width of the image
                //const imageHeight = 15;


                // doc.addImage(imgData, "JPEG", 15, 25, imageWidth, imageHeight);

                return [
                    '',
                    `\n${sign.signatureName}\n`,
                    `\n${formatDateTime(sign.dateTime)}\n`,
                ];
            }),
        ];

        const styles = {
            cellPadding: 3,
            fontSize: 10,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [0, 0, 0],
        };

        doc.autoTable({
            startY: startY,
            head: tableSignData.slice(0, 1),
            //body: tableSignData.slice(1),
            html: '#mytable',
            columnStyles: {
                0: { cellWidth: 100 },
            },
            styles: styles,
            bodyStyles: { minCellHeight: 8 },
            didDrawCell: function (data) {
                if (data.cell.section === 'body') {
                    var td = data.cell.raw;
                    var colIndex = data.column.index;

                    if (colIndex === 0) {
                        // Handle the 1st column (image column)
                        var img = td.getElementsByTagName('img')[0];
                        var dim = data.cell.height - data.cell.padding('vertical');
                        var desiredWidth = dim * 5.5; // Adjust the scale factor as needed
                        var aspectRatio = img.width / img.height;
                        var desiredHeight = desiredWidth / aspectRatio;
                        var posX = data.cell.x + (data.cell.width - desiredWidth) / 20;
                        var posY = startY + 15 + (data.cell.height - desiredHeight);

                        doc.setFillColor(255, 255, 255); // Set fill color to white
                        doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // 'F' stands for 'Fill'
                        doc.addImage(img.src, posX, posY, desiredWidth, desiredHeight);
                    } else {
                        // Handle the 2nd and 3rd columns (text columns)
                        doc.setFillColor(255, 255, 255); // Set fill color to white
                        doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // 'F' stands for 'Fill'

                        // Calculate the position to center the text horizontally and vertically in the cell
                        var textX = data.cell.x + data.cell.padding('left');
                        var textY = data.cell.y + (data.cell.height / 2);

                        // Draw the text content on top of the white background
                        doc.setTextColor(0, 0, 0); // Set text color to black
                        doc.text(textX, textY, data.cell.text, { baseline: 'middle' });
                    }
                }
                if (data.cell.section === 'head' && data.row.index === 0) {
                    doc.setLineWidth(0.1); // Set the border line width as needed
                    doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                }
            }

        });
    }
    //const pdfBlob = doc.output("blob");
    //const pdfUrl = URL.createObjectURL(pdfBlob);
    // window.open(pdfUrl, "_blank");
    //URL.revokeObjectURL(pdfUrl);
    const pdfBlob = doc.output("blob");
    uploadFileToDatabaseInvoice(pdfBlob);
}
