import React, { useState, useEffect } from "react";

const TimeSelectComponent = ({
    setSelectedTimeStart,
    setSelectedTimeEnd,
    schedule,
    selectedTimeStart,
    selectedTimeEnd,
}) => {
    const [isStartTimeFocused, setIsStartTimeFocused] = useState(false);
    const [isEndTimeFocused, setIsEndTimeFocused] = useState(false);

    const renderTimeOptions = () => {
        const timeOptions = [];
        const startTime = new Date();
        startTime.setHours(0, 0, 0, 0); // Set the start time to 12:00 AM

        for (let i = 0; i < 24 * 4; i++) {
            const currentTime = new Date(startTime.getTime() + i * 15 * 60 * 1000);
            const formattedTime = formatTime(currentTime);
            timeOptions.push(
                <option key={formattedTime} value={formattedTime}>
                    {formattedTime}
                </option>
            );
        }
        return timeOptions;
    };


    const formatTime = (time) => {
        let hours = time.getHours() % 12 || 12; // Convert to 12-hour format
        let minutes = Math.round(time.getMinutes() / 15) * 15; // Round minutes to nearest 15

        if (minutes === 60) {
            // If minutes value is 60, adjust hours and reset minutes to 0
            hours += 1;
            minutes = 0;
        }

        const amPm = time.getHours() < 12 ? "AM" : "PM";
        return `${hours.toString()}:${minutes.toString().padStart(2, "0")} ${amPm}`;
    };

    useEffect(() => {
        const currentTime = new Date();
        const endCurrentTime = new Date(currentTime); // Create a new Date object to avoid mutating the original

        // Add an hour to the endCurrentTime
        endCurrentTime.setHours(currentTime.getHours() + 1);

        if (schedule && schedule.start && schedule.end) {
            // Parse the start and end times from the schedule
            const startTime = new Date(schedule.start);
            const endTime = new Date(schedule.end);

            // Format the times and set them as initial values
            setSelectedTimeStart(formatTime(startTime));
            setSelectedTimeEnd(formatTime(endTime));
        } else {
            // If schedule is not provided or does not have valid times, set defaults
            setSelectedTimeStart(formatTime(currentTime));
            setSelectedTimeEnd(formatTime(endCurrentTime)); // Format the endCurrentTime
        }
    }, [schedule, setSelectedTimeStart, setSelectedTimeEnd]);

    const handleTimeStartChange = (event) => {
        setSelectedTimeStart(event.target.value);
    };

    const handleTimeEndChange = (event) => {
        setSelectedTimeEnd(event.target.value);
    };

    return (
        <div className="col-sm">
            <div className={`mt-1 time-picker-container ${isStartTimeFocused ? "focused" : ""}`}>
                <select
                    value={selectedTimeStart}
                    onChange={handleTimeStartChange}
                    className="form-select time-picker-select"
                    onFocus={() => setIsStartTimeFocused(true)}
                    onBlur={() => setIsStartTimeFocused(false)}
                    name="startTime"
                >
                    {renderTimeOptions()}
                </select>
                <label htmlFor="startTime" className="time-picker-label">
                    At
                </label>
            </div>
            <div className={`mt-1 time-picker-container ${isEndTimeFocused ? "focused" : ""}`}>
                <select
                    value={selectedTimeEnd}
                    onChange={handleTimeEndChange}
                    className="form-select time-picker-select"
                    onFocus={() => setIsEndTimeFocused(true)}
                    onBlur={() => setIsEndTimeFocused(false)}
                    id="endTime"
                    name="endTime"
                >
                    {renderTimeOptions()}
                </select>
                <label htmlFor="endTime" className="time-picker-label">
                    At
                </label>
            </div>
        </div>
    );
};

export default TimeSelectComponent;
