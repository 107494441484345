import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

const CustomerInputWithAutocomplete = ({ customers, fullName, setFullName, selectedCustomerId, setPhoneNumber, setEmail, setSelectedAddress, setSelectedCity, setSelectedState, setSelectedZip, setSelectedCustomerId }) => {
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const inputRef = useRef(null);
    const auth = useSelector(state => state.auth)

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowAutocomplete(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const handleInputChange = (e) => {
        setFullName(e.target.value);
        setShowAutocomplete(true);
    };

    const handleOptionClick = (customer) => {
        setSelectedCustomerId(customer._id);
        setPhoneNumber(customer.phone);
        setEmail(customer.email);
        setSelectedAddress(customer.address);
        setSelectedCity(customer.city);
        setSelectedState(customer.state);
        setSelectedZip(customer.zip);
        setFullName(`${customer.name} ${customer.lastName}`);
        setShowAutocomplete(false);
    };

    const filteredCustomers = customers.filter(
        (customer) =>
            customer.company === auth.user.company &&
            `${customer.name} ${customer.lastName}`.toLowerCase().includes(fullName.toLowerCase())
    );

    const typedInputListItem = (
        <li
            key="typed-input"
            className="selected"
            onClick={() => {
                setShowAutocomplete(false);
            }}
        >
            {`Add New '${fullName}'`}
        </li>
    );
    return (
        <div className="col-sm-12 mt-4">
            <div ref={inputRef}>
                <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    placeholder="Full Name"
                    name="fullName"
                    value={fullName}
                    autoComplete="off" 
                    onChange={handleInputChange}
                />
                {showAutocomplete && (
                    <ul className="autocomplete-list" style={{ width: inputRef.current.offsetWidth }}>
                        {typedInputListItem}
                        {filteredCustomers.map((customer) => (
                            <li
                                key={customer.id}
                                onClick={() => handleOptionClick(customer)}
                                tabIndex={0}
                                role="option"
                                aria-selected={customer._id === selectedCustomerId}
                                className={customer._id === selectedCustomerId ? "selected" : ""}
                            >
                                {`${customer.name} ${customer.lastName}`}<br />
                                {`${customer.address}`}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default CustomerInputWithAutocomplete;
