import React, { useState, useEffect } from "react";
import {useSelector } from "react-redux";

const JobTypeSelectComponent = ({ jobType, setJobType, selectedJobType, setSelectedJobType }) => {
    const [filteredJobTypes, setFilteredJobTypes] = useState([]);
    const auth = useSelector(state => state.auth)

    const handleJobTypeChange = (event) => {
        const selectedJobType = event.target.value;
        setSelectedJobType(selectedJobType);
        setJobType(selectedJobType);
    };


    useEffect(() => {
        if (Array.isArray(jobType)) {
            setFilteredJobTypes(jobType.filter((jobType) => jobType.company === auth.user.company));
        }
    }, [jobType, auth.user.company]);

    return (
        <div>
            {jobType.length === 0 ? (
                <p className="mt-3">Loading Job types...</p>
            ) : (
                <select
                    className="form-select mt-3 text-dark"
                    id="jobType"
                    name="jobType"
                        value={selectedJobType} // Use selectedJobType to set the selected value
                        onChange={handleJobTypeChange}
                >
                    {/* Display only the job types for the selected company */}
                    <option value="">Job Type</option>
                    {filteredJobTypes.map((jobType) => (
                        <option key={jobType._id} value={jobType._id}>
                            {jobType.name}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};

export default JobTypeSelectComponent;
