import React from "react";

const DeletePaymentPopModal = ({ onConfirm, onCancel }) => {
    return (
        <>
            <div
                className="background-Confirmation"
                onClick={onCancel} 
            />
            <div className="confirmation-box-item">
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row justify-content-center">
                        <div className="col-sm-11 text-center pb-4">
                            <span className="fw-bold fs-5">Delete Payment?</span><br /><br />
                            <span className="pt-2">
                                Are you sure you want to delete without a refund? <br/>
                                This cannot be undone.
                            </span>
                        </div>
                    </div>
                    <div className="row deleteWidth bg-light justify-content-center border-top border-dark">
                        <div className="col-sm">
                            <button className="btn btn-outline-primary ps-2 pe-2" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                        <div className="col-sm">
                            <button onClick={onConfirm} className="btn btn-outline-danger m-0 ps-4 pe-4">
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeletePaymentPopModal;
