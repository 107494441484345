import axios from "axios";
import AdminModelComponent from "./components/adminModelComponent";

const fetchModel = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleModel/admin", { withCredentials: true });
    return data
}

const deleteModel = async (modelId) => {
    const { data } = await axios.delete(`/api/vehicleModel/${modelId}`, { withCredentials: true });
    return data
}

const AdminModelPage = () => {

    return <AdminModelComponent fetchModel={fetchModel} deleteModel={deleteModel} />;
};

export default AdminModelPage;
