import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocksmithHelpComponent from "../../components/locksmtihHelpComponent";

const LocksmithSelectComponent = ({ fetchVin, fetchMake, fetchModel, fetchYear }) => {
    const auth = useSelector(state => state.auth)
    const [make, setMake] = useState([]);
    const [selectedMake, setSelectedMake] = useState('');
    const [model, setModel] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [modelDisabled, setModelDisabled] = useState(true);
    const [yearDisabled, setYearDisabled] = useState(true);
    const [vinDisabled, setVinDisabled] = useState(true);
    const [vin, setVin] = useState('');
    const [vinLength, setVinLength] = useState(false);
    const [vinError, setVinError] = useState('');

    const navigate = useNavigate();

    const isAdmin = auth.user?.usertype?.includes('Admin') || false;
    const isDispatcher = auth.user?.usertype?.includes('European') || false;
    const isSuperAdmin = auth.user?.usertype?.includes('Developer') || false;

    useEffect(() => {
        const performSearch = () => {
            if (selectedMake && selectedModel && selectedYear) {
                const url = `/${selectedMake}/${encodeURIComponent(selectedModel)}/${selectedYear}`;
                //const dispatchUrl = `/dispatch/${selectedMake}/${encodeURIComponent(selectedModel)}/${selectedYear}`;
                const adminUrl = `/admin/${selectedMake}/${encodeURIComponent(selectedModel)}/${selectedYear}`;
                if (isDispatcher) {
                    navigate(adminUrl);
                } else if (!isDispatcher && (isAdmin || isSuperAdmin)) {
                    navigate(adminUrl);
                } else {
                    navigate(url);
                }
            }
        };

        performSearch();
    }, [navigate, selectedMake, selectedModel, selectedYear, isAdmin, isDispatcher, isSuperAdmin]);

    useEffect(() => {
        if (selectedMake) {
            fetchModel(selectedMake)
                .then((data) => {
                    setModel(data);
                    setModelDisabled(false);
                    setYearDisabled(true);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        } else {
            setModelDisabled(true);
            setYearDisabled(true);
        }
    }, [selectedMake, fetchModel]);

    useEffect(() => {
        if (selectedMake && selectedModel) {
            fetchYear(selectedModel)
                .then((data) => {
                    const matchingMake = data.filter((item) => item.make === selectedMake);
                    setYears(matchingMake);
                    setYearDisabled(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
    }, [selectedModel, selectedMake, fetchYear]);

    useEffect(() => {
        const abctrl = new AbortController();
        fetchMake(abctrl)
            .then((res) => setMake(res))
            .catch((er) =>
                setMake([
                    { name: er.response.data.message ? er.response.data.message : er.response.data }
                ]),
            );
        return () => abctrl.abort();
    }, [fetchMake]);

    const handleBlur = () => {
        const hasInvalidChars = /[OoiIqQ]/.test(vin);

        if (vin.length === 17) {
            if (hasInvalidChars) {
                setVinError('VIN cannot contain characters: O, I, or Q.');
                setVinDisabled(true);
                setVinLength(true);
            } else {
                setVinLength(false);
                setVinDisabled(false);
            }
        } else {
            setVinError('VIN must be exactly 17 characters long.');
            setVinDisabled(true);
            setVinLength(true);
        }
    };

    const handleVinChange = (event) => {
        const newVin = event.target.value;

        setVin(newVin);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const toProperCase = (str) => {
            return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
            });
        };


        fetchVin(vin)
            .then((data) => {
                const newData = data.Results;

                const findMake = newData.find((item) => item.Variable === "Make");
                const findModel = newData.find((item) => item.Variable === "Model");
                const findModelYear = newData.find((item) => item.Variable === "Model Year");

                if (findMake.Value === null) {
                    setVinError('The VIN does not return any vehicle manufacture.');
                    setVinDisabled(true);
                    setVinLength(true);

                } else {
                    const properCaseMake = toProperCase(findMake.Value);

                    if (findModel.Value.toLowerCase().startsWith(properCaseMake.toLowerCase())) {
                        const modelTrim = findModel.Value.substring(properCaseMake.length).trim();

                        const url = `/${properCaseMake}/${encodeURIComponent(modelTrim)}/${findModelYear.Value}/${vin}`;
                        // dispatchUrl = `/dispatch/${properCaseMake}/${encodeURIComponent(modelTrim)}/${findModelYear.Value}/${vin}`;
                        const adminUrl = `/admin/${properCaseMake}/${encodeURIComponent(modelTrim)}/${findModelYear.Value}/${vin}`;
                        if (isDispatcher) {
                            navigate(adminUrl);
                        } else if (!isDispatcher && (isAdmin || isSuperAdmin)) {
                            navigate(adminUrl);
                        } else {
                            navigate(url);
                        }
                    } else {
                        const url = `/${properCaseMake}/${encodeURIComponent(findModel.Value)}/${findModelYear.Value}/${vin}`;
                        //const dispatchUrl = `/dispatch/${properCaseMake}/${encodeURIComponent(findModel.Value)}/${findModelYear.Value}/${vin}`;
                        const adminUrl = `/admin/${properCaseMake}/${encodeURIComponent(findModel.Value)}/${findModelYear.Value}/${vin}`;
                        if (isDispatcher) {
                            navigate(adminUrl);
                        } else if (!isDispatcher && (isAdmin || isSuperAdmin)) {
                            navigate(adminUrl);
                        } else {
                            navigate(url);
                        }
                    }
                }
            })
            .catch((er) =>
                console.log(er)
            );

    };

    return (
        <>
            <div className="container-fluid margintop1 marginbottom1">
                <div className="row justify-content-center mt-5 mb-5 pb-5">
                    <div className="col-sm-8">
                        <table className="border border-dark table table-striped center">
                            <tbody>
                                <tr className="borderingTable align-items-center mb-5">
                                    <th colSpan="10" scope="row">
                                        <div className="row text-center mt-5 mb-5">
                                            <div className="col" />
                                            <div className="col-sm" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center" }}>
                                                <label htmlFor="make">Make:</label>
                                                <select
                                                    className="btn btn-outline-light text-dark"
                                                    id="make"
                                                    name="make"
                                                    value={selectedMake}
                                                    onChange={(e) => {
                                                        setSelectedMake(e.target.value);
                                                        setSelectedModel('');
                                                        setSelectedYear('');
                                                    }}
                                                >
                                                    <option value="">--Select Make--</option>
                                                    {make
                                                        .slice()
                                                        .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
                                                        .map((makes, m) => (
                                                            <option key={m} value={makes.id}>
                                                                {makes.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>

                                            <div className="col-sm" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center" }}>
                                                <label htmlFor="model">Model:</label>
                                                <select
                                                    className="btn btn-outline-light text-dark"
                                                    id="model"
                                                    name="model"
                                                    value={selectedModel}
                                                    onChange={(e) => {
                                                        setSelectedModel(e.target.value);
                                                        setSelectedYear('');
                                                    }}
                                                    disabled={modelDisabled}
                                                >
                                                    <option value="">--Select Model--</option>
                                                    {model
                                                        .slice()
                                                        .sort((a, b) => a.model.localeCompare(b.model))
                                                        .map((models, m) => (
                                                            <option key={m} value={models.id}>
                                                                {models.model}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className="col-sm" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center" }}>
                                                <label htmlFor="year">Year:</label>
                                                <select
                                                    className="btn btn-outline-light text-dark"
                                                    id="year"
                                                    name="year"
                                                    value={selectedYear}
                                                    onChange={(e) => setSelectedYear(e.target.value)}
                                                    disabled={yearDisabled}
                                                >
                                                    <option value="">--Select Year--</option>
                                                    {years.map((yearObj) => (
                                                        yearObj.year
                                                            .sort((a, b) => b - a)
                                                            .map((year) => (
                                                                <option key={year} value={year}>
                                                                    {year}
                                                                </option>
                                                            ))
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col" />
                                        </div>
                                    </th>
                                </tr>
                                <tr className="borderingTableNT align-items-center mb-5">
                                    <th colSpan="10" scope="row">
                                        <div className="row justify-content-center">
                                            <span className="text-center w-50 fancy-or">Or</span>
                                        </div>
                                        <form onSubmit={handleSubmit} className="row justify-content-center mt-5 mb-5">
                                            <div className="col-sm-4">
                                                <input
                                                    type="text"
                                                    name='VIN'
                                                    placeholder="Enter Your VIN Here"
                                                    className={`form-control ${vinLength && vin ? 'is-invalid' : ''}`}
                                                    id="VIN"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => { handleVinChange(e); }}
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <button type="submit" disabled={vinDisabled} className="btn btn-sm btn-outline-primary mb-3 loginButton">Submit</button>
                                            </div>
                                            {(vinLength && vin) && (
                                                <p className="text-danger text-center mt-2 ms-4 mb-3">
                                                    {vinError}
                                                </p>
                                            )}
                                        </form>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <LocksmithHelpComponent />
            </div>
        </>
    );
};

export default LocksmithSelectComponent;