import axios from "axios";
import CustomerComponent from "./components/customerComponent";

const fetchCustomer = async (abctrl) => {
    const { data } = await axios.get("/api/customer/dispatch", { withCredentials: true });
    return data
}

const deleteCustomer = async (customerId) => {
    const { data } = await axios.delete(`/api/customer/dispatch/${customerId}`, { withCredentials: true });
    return data
}

const CustomerPage = () => {

    return <CustomerComponent fetchCustomer={fetchCustomer} deleteCustomer={deleteCustomer} />;
};

export default CustomerPage;
