import axios from "axios";
import NewWorkScheduleComponent from "./components/newWorkScheduleComponent";

const CreateWorkSchedulePage = () => {

    const CreateWorkApiRequest = async ( formInputs) => {
        const { data } = await axios.post(`/api/workSchedule/work-schedule`, { ...formInputs }, { withCredentials: true });
        return data;
    }

    const fetchTechInfo = async (abctrl) => {
        const { data } = await axios.get("/api/techInfo/dispatch", { withCredentials: true });
        return data
    }

    const fetchUsers = async (abctrl) => {
        const { data } = await axios.get("/api/users", { withCredentials: true });
        return data
    }

    return <NewWorkScheduleComponent
        fetchTechInfo={fetchTechInfo}
        fetchUsers={fetchUsers}
        CreateWorkApiRequest={CreateWorkApiRequest}
    />
};

export default CreateWorkSchedulePage;