import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import paths from "../../../router/paths";
import { Link } from "react-router-dom";

const dayOfWeekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const getNextDayDate = (dayIndex) => {
    const date = new Date();
    date.setDate(date.getDate() + ((dayIndex + 7 - date.getDay()) % 7));
    return date;
};

const NewWorkScheduleComponent = ({ CreateWorkApiRequest, fetchTechInfo, fetchUsers }) => {
    const [daysOfWeek, setDaysOfWeek] = useState(new Array(7).fill(false));
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [users, setUsers] = useState([]);
    const [tech, setTech] = useState([]);
    const [selectedTech, setSelectedTech] = useState('');
    const [overTime, setOverTime] = useState(false);

    const navigate = useNavigate();
    const auth = useSelector(state => state.auth)

    useEffect(() => {
        const abctrl = new AbortController();

        fetchTechInfo(abctrl)
            .then((res) => {
                setTech(res);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        fetchUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                console.log("Error fetching users:", error);
            });

        return () => abctrl.abort();
    }, [fetchUsers, fetchTechInfo]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const scheduleEntries = daysOfWeek.map((selected, index) => {
            if (selected) {
                const dayDate = getNextDayDate(index);
                const startDateTime = new Date(dayDate);
                const endDateTime = new Date(dayDate);

                startDateTime.setHours(...startTime.split(':').map(Number));
                endDateTime.setHours(...endTime.split(':').map(Number));

                return {
                    day: dayOfWeekNames[index],
                    startTime: startDateTime,
                    endTime: endDateTime
                };
            }
            return null;
        }).filter(entry => entry !== null); // Filter out unselected days

        const formInputs = {
            schedule: scheduleEntries,
            sick: false,
            company: auth.user.company,
            userId: auth.user._id,
            timeOff: false,
            techId: selectedTech,
            overTime: overTime,
        };

        CreateWorkApiRequest(formInputs)
            .then((data) => {
                if (data.message === "Schedule created successfully") {
                    navigate(paths.WORKSCHEDULE);
                }
            })
            .catch((error) => {
                console.error(error.response?.data?.message || error.response?.data || error.message);
            });
    };

    const toggleDay = index => {
        const newDaysOfWeek = [...daysOfWeek];
        newDaysOfWeek[index] = !newDaysOfWeek[index];
        setDaysOfWeek(newDaysOfWeek);
    };

    const getTechName = (techId) => {
        const techUser = users.find((user) => user._id === techId);
        return techUser ? techUser.name : "";
    };

    const handleTechChange = (event) => {
        const selectedValue = JSON.parse(event.target.value);

        setSelectedTech(selectedValue._id);
    };

    return (
        <div className="mt-5 pt-4">
            <div className="container mb-5 pb-5">
                <div className="row justify-content-md-center mt-5">
                    <div className="col-sm-7">
                        <h1 className="text-center">Add Schedule</h1>
                        <form onSubmit={handleSubmit} className="row pt-4 g-3">
                            <div className="row mt-3 m-0 p-0">
                                <label className="text-start">Scheduled Days:</label>
                                <div className="days-of-week-container">
                                    {dayOfWeekNames.map((name, index) => (
                                        <div key={name} className="days-of-week-checkbox">
                                            <input
                                                type="checkbox"
                                                id={name}
                                                checked={daysOfWeek[index]}
                                                onChange={() => toggleDay(index)}
                                            />
                                            <label className="ms-2" htmlFor={name}>{name}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col m-0 p-0">
                                    <label>Start Time:</label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        name="startTime"
                                        value={startTime}
                                        onChange={e => setStartTime(e.target.value)}
                                    />
                                </div>
                                <div className="col ms-4 m-0 p-0">
                                    <label>End Time:</label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        name="endTime"
                                        value={endTime}
                                        onChange={e => setEndTime(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row mt-4 mb-2">
                                <div className="col">
                                    <div className="days-of-week-checkbox">
                                        <input
                                            type="checkbox"
                                            id='overTime'
                                            checked={overTime}
                                            onChange={() => setOverTime(!overTime)}
                                        />
                                        <label className="ms-2" htmlFor="editFull">Clock in on days off?</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <select
                                    className="form-select mt-3 text-dark"
                                    id="techs"
                                    name="techs"
                                    onChange={handleTechChange}
                                    defaultValue={""}
                                >
                                    <option defaultValue="">Assign Team Members</option>
                                    {tech.map((techItem) => (
                                        <option key={techItem._id} value={JSON.stringify(techItem)}>
                                            {getTechName(techItem.user)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="text-center pt-4">
                                <button disabled={!daysOfWeek.some(day => day) || !startTime || !endTime} type="submit" className="btn btn-outline-primary mb-3 loginButton">Submit</button>
                                <Link to={paths.WORKSCHEDULE} className="btn btn-outline-success mb-3 ms-2 loginButton">Go Back</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewWorkScheduleComponent;