import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import estimateImage from '../assets/estimateImage.svg';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link } from "react-router-dom";
import DeleteJobItemPopModal from "./deleteJobItemPopComponent";
import { BsTrash } from 'react-icons/bs';

const EstimateTabComponent = ({ createEstimateAPI, deleteEstimate, fetchEstimates, setEstimateRowCount, id, updateDispatchEstimatesApiRequest, dispatching }) => {
    const auth = useSelector(state => state.auth)
    const navigate = useNavigate();
    const [estimate, setEstimate] = useState([]);
    const [showAlertDelete, setShowAlertDelete] = useState(false);
    const [estimateId, setEstimateId] = useState([]);
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
        const abctrl = new AbortController();

        fetchEstimates(abctrl)
            .then((res) => {
                const filteredEstimates = res.filter((item) => item.jobId === dispatching._id);
                setEstimate(filteredEstimates ? filteredEstimates : "");
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );

        return () => abctrl.abort();
    }, [dispatching, fetchEstimates]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (loading) {
            fetchEstimates(abctrl)
                .then((res) => {
                    const filteredEstimates = res.filter((item) => item.jobId === dispatching._id);
                    setEstimate(filteredEstimates ? filteredEstimates : "");
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [dispatching, fetchEstimates, loading]);

    const handleSubmitClick = () => {
        const formInputs = {
            jobId: dispatching._id,
            customer: dispatching.customer,
            status: 'Unsent',
            discount: dispatching.discount || '',
            discountType: dispatching.discountType || '',
            tax: dispatching.tax || '',
            count: dispatching.count,
            company: auth.user.company,
        };
        const formInputsEst = {
            estimates: '',
        };
        createEstimateAPI(formInputs)
            .then((data) => {
                if (data.message === "estimate saved") {
                    const newEstimateId = data._id;
                    const formInputWithEst = {
                        ...formInputsEst,
                        estimates: newEstimateId,
                    };
                    updateDispatchEstimatesApiRequest(id, formInputWithEst)
                        .then((data) => {
                            if (data.message === "Dispatch job updated") {
                                navigate(`/root/estimate/${data.estimates}`);
                            }
                        })
                        .catch((er) => {
                            console.log({
                                error: er.response.data.message
                                    ? er.response.data.message
                                    : er.response.data
                            });
                        });
                }
            })
    };

    useEffect(() => {
        setEstimateRowCount(estimate.length);
    }, [estimate, setEstimateRowCount]);

    const deleteEstimateClick = async (estimateId) => {
        setEstimateId(estimateId)
        setShowAlertDelete(true);
    };
    const handleCancelRemoveEstimate = async () => {
        setShowAlertDelete(false);
    };

    const deleteEstimateHandler = async () => {
        const data = await deleteEstimate(estimateId);
        if (data.message === 'Estimate deleted successfully') {
            setLoading(true);
            setShowAlertDelete(false);
        }
    };

    function formatDateTime(dateTime) {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };

        return new Date(dateTime).toLocaleDateString(undefined, options);
    }

    return (
        <>
            {showAlertDelete && (
                <DeleteJobItemPopModal
                    onConfirm={deleteEstimateHandler}
                    onCancel={handleCancelRemoveEstimate}
                />
            )}
            {estimate.length === 0 ? (
                <div className="row mt-5">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-sm-6 text-center">
                            <img className="m-0 p-0" src={estimateImage} alt="Estimate Logo" /><br />
                            <h3 className="estimateText mt-4">You don't have any estimates yet</h3>
                        </div>
                    </div>
                    <div className="row mt-3 justify-content-center">
                        <div className="col-sm-6 text-center">
                            <button className="btn btn-outline-primary" onClick={() => handleSubmitClick()} type="button"><AiOutlinePlus className="mb-1" /> Add Estimate</button>
                        </div>
                    </div>
                </div>
            ) : (
                    <div className="row justify-content-center">
                        <div className="col-sm-10 mt-5">
                            <div className="row p-0 m-0 mt-3 justify-content-between">
                                <div className="col">
                                    <h3>Estimates</h3>
                                </div>
                                <div className="col-sm-2 text-center">
                                    <button className="btn btn-outline-primary" onClick={() => handleSubmitClick()} type="button"><AiOutlinePlus className="mb-1" /> Add Estimate</button>
                                </div>
                            </div>
                            <table className="table table-hover mt-5">
                                <thead className="border-bottom border-dark">
                            <tr>
                                        <th scope="col" style={{ width: "40%" }}>Estimate</th>
                                        <th scope="col" style={{ width: "20%" }}>Created</th>
                                        <th scope="col" style={{ width: "10%" }}>Status</th>
                                        <th scope="col" style={{ width: "8%" }}>Total</th>
                                        <th scope="col" style={{ width: "10%" }}>Actions</th>
                            </tr>
                                </thead>
                                <tbody className="border border-bottom border-right border-left border-dotted">
                            {estimate.map((estimates, index) => (
                                <tr key={index}>
                                    <td className="border border-dotted border-bottom border-right border-left">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Link to={`/root/estimate/${estimates._id}`}>Estimate {index + 1}</Link> / Estimate No. {dispatching.count}-{estimates.count}
                                        </div>
                                    </td>
                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                        {formatDateTime(estimates.createdAt)}
                                    </td>
                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                        {estimates.status}
                                    </td>
                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                        ${(estimates.total).toFixed(2)}
                                    </td>
                                    <td className="border text-center border-dotted border-bottom border-right border-left">
                                        <button
                                            className="dropdown-item fs-5 text-danger" 
                                            onClick={() => deleteEstimateClick(estimates._id)} 
                                        >
                                            <BsTrash/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
            )}
        </>
    );
};

export default EstimateTabComponent;
