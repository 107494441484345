import axios from "axios";
import InventoryItemTechComponent from "./components/inventoryItemTechComponent";

const fetchInventory = async (abctrl) => {
    const { data } = await axios.get("/api/inventory/all", { withCredentials: true });
    return data
}
const fetchTechInfo = async (techId) => {
    const { data } = await axios.get(`/api/techInfo/graph`, { withCredentials: true });
    return data
}
const fetchUsers = async () => {
    const { data } = await axios.get(`/api/users/inventory/`, { withCredentials: true });
    return data
}
const fetchLocation = async (abctrl) => {
    const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
    return data
}
const fetchRequest = async (abctrl) => {
    const { data } = await axios.get("/api/request/", { withCredentials: true });
    return data
}
const InventoryItemTechPage = () => {

    return <InventoryItemTechComponent
        fetchInventory={fetchInventory}
        fetchRequest={fetchRequest }
        fetchTechInfo={fetchTechInfo}
        fetchUsers={fetchUsers}
        fetchLocation={fetchLocation }
    />;
};

export default InventoryItemTechPage;
