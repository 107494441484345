import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import paths from "../../../router/paths";
import { useSelector } from "react-redux";
import '../../../components/assets/user.css';

const AdminEditMainUserComponent = ({ fetchTechInfo, updateTechApiRequest, updateUserApiRequest, fetchUser }) => {
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState([]);
    const [tech, setTech] = useState([]);
    const auth = useSelector(state => state.auth);
    const [value, setValue] = useState(0);
    const [updateUserResponseState, setUpdateUserResponseState] = useState({ message: "", error: "" });
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
    const [selectedColor, setSelectedColor] = useState('');
    const [booleanValue, setBooleanValue] = useState(false); // State for the boolean dropdown
    const navigate = useNavigate();
    const [type, setType] = useState('percent');

    const { id } = useParams();

    // Conditionally include "Developer" in the roles array
    const roles = ['Admin', 'Office', 'Inventory', 'Dispatcher', 'Technician'];
    if (auth.user.usertype.includes('Developer')) {
        roles.push('Developer', 'European');
    }

    useEffect(() => {
        fetchUser(id)
            .then(data => {
                setUser(data);
                setSelectedRoles(data.usertype);
                setFormattedPhoneNumber(data.phoneNumber);

                fetchTechInfo()
                    .then(res => {
                        const filter = res.find((itm) => itm.user === id);

                        if (filter) {
                            setTech(filter);
                            setSelectedColor(filter.color);
                            setBooleanValue(true);
                        }
                    })
                    .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
            })
            .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
    }, [id, fetchUser, fetchTechInfo])

    const initialColors = [
        '#7fffd4',
        '#1e90ff',
        '#6495ed',
        '#008b8b',
        '#5f9ea0',
        '#00008b',
        '#2e8b57',
        '#556b2f',
        '#8fbc8f',
        '#9acd32',
        '#bdb76b',
        '#808000',
        '#deb887',
        '#d2b48c',
        '#cd853f',
        '#b8860b',
        '#d2691e',
        '#a52a2a',
        '#ffa500',
        '#ff8c00',
        '#ff6347',
        '#ff4500',
        '#dc143c',
        '#8b0000',
        '#ee82ee',
        '#da70d6',
        '#db7093',
        '#bc8f8f',
        '#8a2be2',
        '#191970',
        '#3cb371',
        '#008000',
        '#20b2aa',
        '#4169e1',
        '#2f4f4f',
        '#708090',
        '#f0e68c',
        '#000',
        '#5e5e5e',
    ];

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleColorSelect = (color) => {
        setSelectedColor(color);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;

        const formInputsTech = {
            company: auth.user.company,
            user: id,
            color: selectedColor,
        };

        const formInputs = {
            name: capitalizeFirstLetter(form.name.value),
            lastName: capitalizeFirstLetter(form.lastName.value),
            username: form.username.value,
            email: form.email.value,
            phoneNumber: formattedPhoneNumber,
            company: auth.user.company,
            usertype: selectedRoles,
        };

        updateUserApiRequest(id, formInputs)
            .then(data => {
                if (data.success === "user updated") {

                    updateTechApiRequest(tech._id, formInputsTech)
                        .then((data) => {
                            if (data.message === "Tech info updated") {
                                navigate(`${paths.ADMINROOTUSERS}`);
                            };
                        })
                        .catch((er) => {
                            console.log({
                                error: er.response.data.message
                                    ? er.response.data.message
                                    : er.response.data,
                            });
                        });
                }
            })
            .catch(er => {
                setUpdateUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
            });
        setValidated(true);
    };

    const handleChange = (event) => {
        const phoneNumber = event.target.value.replace(/\D/g, '');
        const formattedNumber = phoneNumber.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1)-$2-$3'
        );
        setFormattedPhoneNumber(formattedNumber);
    };

    const handleRoleChange = (event) => {
        const { value } = event.target;
        if (value === 'Admin') {
            setSelectedRoles(['Admin']);
            setIsDropdownDisabled(true);
        } else if (value === 'Developer') {
            setSelectedRoles(['Developer']);
            setIsDropdownDisabled(true);
        } else {
            setSelectedRoles(prevRoles => [...prevRoles.filter(role => role !== 'Admin'), value]);
        }
    };

    const handleRemoveRole = (role) => {
        const newSelectedRoles = selectedRoles.filter(r => r !== role);
        setSelectedRoles(newSelectedRoles);
        if (role === 'Admin') {
            setIsDropdownDisabled(false);
        }
        if (role === 'Developer') {
            setIsDropdownDisabled(false);
        }
    };

    const handleBooleanChange = (event) => {
        const value = event.target.value === 'true';
        setBooleanValue(value);
    };

    const handleSliderChange = (event) => {
        setValue(event.target.value);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value);
    };

    const handleTypeChange = (newType) => {
        setType(newType);
    };

    const availableRoleOptions = roles.filter((itm) => !selectedRoles.includes(itm));

    return (
        <div className="container-fluid mt-5 pt-5 ps-5 pe-5">
            <span className="fs-4 fw-bold">Edit User Info</span>
            <form className="pt-4" noValidate validated={validated.toString()} onSubmit={handleSubmit}>
                <div className="row justify-content-center mb-5">
                    <div className="col-sm-4">
                        <div className="row m-0 p-0">
                            <div className="col-sm-6 m-0 p-0 pe-3 mb-3">
                                <label htmlFor="firstName">First Name:</label>
                                <input
                                    id="firstName"
                                    name="name"
                                    required
                                    className="form-control"
                                    type="text"
                                    defaultValue={user.name}
                                />
                            </div>
                            <div className="col-sm-6 m-0 p-0 mb-3">
                                <label htmlFor="lastName">Last Name:</label>
                                <input
                                    id="lastName"
                                    name="lastName"
                                    required
                                    className="form-control"
                                    type="text"
                                    defaultValue={user.lastName}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="username">Username:</label>
                            <input
                                id="username"
                                name="username"
                                className="form-control"
                                required
                                type="text"
                                defaultValue={user.username}
                            />
                        </div>
                        <div className="row m-0 p-0">
                            <div className="col-sm-6 m-0 p-0 pe-3 mb-3">
                                <label htmlFor="email">Email:</label>
                                <input
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    required
                                    type="text"
                                    defaultValue={user.email}
                                />
                            </div>
                            <div className="col-sm-6 m-0 p-0 mb-3">
                                <label htmlFor="phoneNumber">Phone Number:</label>
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    required
                                    className="form-control"
                                    placeholder="(xxx)-xxx-xxxx"
                                    value={formattedPhoneNumber}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-4">
                        <div className="mb-3">
                            <label htmlFor="usertype">User Role:</label>
                            <div className="selected-roles mb-2">
                                {selectedRoles.length > 0 && (
                                    <div className="d-flex flex-wrap">
                                        {selectedRoles.map((role, index) => (
                                            <div key={index} className="selected-role mt-2 me-2">
                                                <span className="selected-role-name">
                                                    {role}
                                                    <button
                                                        type="button"
                                                        className="btn btn-link btn-remove-role"
                                                        onClick={() => handleRemoveRole(role)}
                                                    >
                                                        x
                                                    </button>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <select
                                className="form-select mt-3 text-dark"
                                id="usertype"
                                name="usertype"
                                onChange={handleRoleChange}
                                value=""
                                disabled={isDropdownDisabled}
                            >
                                <option value="" disabled>
                                    User Role
                                </option>
                                {availableRoleOptions.map(role => (
                                    <option key={role} value={role}>
                                        {role}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="booleanField">Field tech:</label>
                            <select
                                className="form-select mt-3 text-dark"
                                id="booleanField"
                                name="booleanField"
                                onChange={handleBooleanChange}
                                value={booleanValue.toString()}
                            >
                                <option value="" disabled>
                                    Select a value
                                </option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                        <span className="mb-2">Color Tag:</span>
                        <div className="row mt-1 justify-content-start">
                            {initialColors.map((color) => (
                                <div
                                    key={color}
                                    id="color"
                                    className="col-1 d-flex align-items-center justify-content-center"
                                    style={{
                                        backgroundColor: color,
                                        height: '20px',
                                        width: '20px',
                                        cursor: 'pointer',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        padding: '0',
                                        margin: '1px',
                                        border: selectedColor === color ? '1px solid #000' : 'none',
                                    }}
                                    onClick={() => handleColorSelect(color)}
                                >
                                    {selectedColor === color && <span>&#10004;</span>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row mt-5 pt-5">
                    <span className="text-center fs-4 fw-bold">Technicians Commission Options</span>
                    <div className="col-sm-12">
                        <div className="row mt-4">
                            <span className="text-start fs-5 fw-bold">Technician Base Rate:</span>
                            <div className="col-sm-12">
                                <div className="mt-3 with-padding">
                                    <input
                                        type="number"
                                        value={value}
                                        onChange={handleInputChange}
                                        className="input param-slider mid-margin-right param-ammount"
                                        size="2"
                                    />
                                    {type === 'percent' && (
                                        <div className="slider-container mt-4">
                                            <div className="slider-labels">
                                                {[0, 20, 40, 60, 80, 100].map((mark, index) => (
                                                    <span key={index} className="slider-label" style={{ left: `${mark}%` }}>
                                                        {mark}%
                                                    </span>
                                                ))}
                                            </div>
                                            <input
                                                type="range"
                                                min="0"
                                                max="100"
                                                step="1"
                                                value={value}
                                                onChange={handleSliderChange}
                                                className="slider"
                                            />
                                            <div className="slider-ticks">
                                                {[2, 21, 40.6, 59.5, 78.8, 98].map((mark, index) => (
                                                    <span key={index} className="slider-tick" style={{ left: `${mark}%` }}></span>
                                                ))}
                                            </div>
                                            <div className="slider-bar"></div>
                                        </div>
                                    )}
                                    <div className="btn-group ms-3" role="group" aria-label="Discount Type">
                                        <button
                                            type="button"
                                            className={`btn btn11 btn-sm ${type === 'percent' ? 'btn-selected' : 'btn-unselected'}`}
                                            onClick={() => handleTypeChange('percent')}
                                        >
                                            %
                                        </button>
                                        <button
                                            type="button"
                                            className={`btn btn11 btn-sm ${type === 'dollar' ? 'btn-selected' : 'btn-unselected'}`}
                                            onClick={() => handleTypeChange('dollar')}
                                        >
                                            $
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center fixed-bottom pt-3 pb-4 bg-light">
                    <Link to={paths.ADMINROOTUSERS} className="btn btn-outline-success me-3">
                        Go Back
                    </Link>
                    <button className="me-2 btn btn-outline-primary" type="submit">
                        UPDATE
                    </button>
                </div>
                {updateUserResponseState.error}
            </form>
        </div>
    );
};

export default AdminEditMainUserComponent;
