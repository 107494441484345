import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ItemProceBreakDownComponent = ({
    setCreateDispatchResponseState,
    discountValue,
    discountType,
    setShowAlert2,
    setLoading,
    selectedTax,
    setSelectedTax,
    setTotalPrice,
    dispatching,
    updateDispatchTaxApiRequest,
    id,
    updateDispatchTotal,
    setShowDiscountModal,
    jobTax,
    loading,
    totalPay,
    setJobLoading,
    jobItemSource,
    jobItems }) => {
    const [dueTerm, setDueTerm] = useState("dueOn"); // Initialize with "dueOn" as default
    const auth = useSelector(state => state.auth)
    const [previousTotal, setPreviousTotal] = useState(0);

    const handleSubmitTax = (selectedTaxId) => {

        setSelectedTax(selectedTaxId);

        const formInputsTax = {
            make: dispatching.make,
            model: dispatching.model,
            year: dispatching.year,
            status: dispatching.status,
            count: dispatching.count,
            user: auth.user._id,
            discount: dispatching.discount,
            discountType: dispatching.discountType,
            tax: selectedTaxId,
            company: auth.user.company
        };

        updateDispatchTaxApiRequest(id, formInputsTax)
            .then((data) => {
                if (data.message === "Dispatch tax updated") {
                    setShowAlert2(true);
                    setTimeout(() => {
                        setShowAlert2(false);
                    }, 3000);
                    setLoading(true);
                }
            })
            .catch((er) => {
                setCreateDispatchResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };
    const calculateSubTotal = () => {
        let subtotal = 0;
        for (const item of filteredJobItems) {
            subtotal += item.price * item.quantity;
        }
        return subtotal.toFixed(2);
    };

    const calculateDiscountAmount = () => {
        const subtotal = calculateSubTotal();

        if (discountType === 'percentage') {
            return (subtotal * (discountValue / 100));
        } else if (discountType === 'amount') {
            return parseFloat(discountValue); // Parse the discountValue as a float
        } else {
            return 0.00; // Return 0 for other types
        }
    };

    const calculateTaxableField = () => {
        const taxableItems = filteredJobItems.filter(item => item.taxable && !item.optional);

        if (taxableItems.length > 0) {
            // Calculate the subtotal only for taxable items
            const subtotal = taxableItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);

            const discount = parseFloat(calculatedDiscountAmount);

            const taxableAmount = subtotal - discount;

            return taxableAmount.toFixed(2);
        } else {
            return 0.00
        }
    };
    const calculateTaxableFieldNoTax = () => {
        const taxableItems = filteredJobItems.filter(item => item.taxable && !item.optional);
        const nonTaxableItems = filteredJobItems.filter(item => !item.taxable && !item.optional);

        if (taxableItems.length === 0 && nonTaxableItems.length > 0) {

            const subtotal = nonTaxableItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);
            const discount = parseFloat(calculatedDiscountAmount);

            const taxableAmount = subtotal - discount;

            return taxableAmount.toFixed(2);
        } else {
            const subtotal = nonTaxableItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);

            const taxableAmount = subtotal;

            return taxableAmount.toFixed(2);
        }
    };

    const calculateTax = () => {
        const taxableAmount = parseFloat(calculateTaxableField());
        const taxRate = selectedTax ? selectedTax.taxRate : 0; 
        const tax = (taxableAmount * taxRate) / 100;
        return tax.toFixed(2);
    };

    const calculateCostTotal = () => {
        let costTotal = 0;

        for (const item of filteredJobItems) {
            if (!item.optional) {
                costTotal += item.cost * item.quantity;
            }
        }
        return costTotal.toFixed(2);
    };

    useEffect(() => {
        setPreviousTotal(parseFloat(calculateTaxableFieldNoTax()) + parseFloat(calculateTaxableField()) + parseFloat(calculateTax()));
    }, []);


    const calculateTotal = () => {
        const nonTaxItems = parseFloat(calculateTaxableFieldNoTax());
        const taxableAmount = parseFloat(calculateTaxableField());
        const tax = parseFloat(calculateTax());
        const total = taxableAmount + tax + nonTaxItems;

        if (!loading) {
            setTotalPrice(total.toFixed(2));

            if (total !== previousTotal) {
                updateDispatchTotal(id, { total: total.toFixed(2) })
                    .then((data) => {
                        if (data.message === "total updated") {
                            setPreviousTotal(total);
                            setJobLoading(true)
                        }
                    })
                    .catch((er) => {
                        setCreateDispatchResponseState({
                            error: er.response.data.message
                                ? er.response.data.message
                                : er.response.data,
                        });
                    });
            }
        }
        return total.toFixed(2);
    };

    const calculateDueDate = () => {
        const today = new Date();

        if (dueTerm === "dueOn") {
            return today;
        } else if (dueTerm === "net15" || dueTerm === "net30" || dueTerm === "net45") {
            const netDays = parseInt(dueTerm.substring(3)); // Extract the number from the term
            const dueDate = new Date(today);
            dueDate.setDate(dueDate.getDate() + netDays); // Add net days to today's date
            return dueDate;
        }

        return null;
    };

    const filteredJobItems = jobItems.filter((item) => jobItemSource.includes(item._id));
    const filteredJobTax = jobTax.filter((item) => dispatching?.company?.includes(item.company));
    const calculatedDiscountAmount = calculateDiscountAmount();
    const formattedDiscountAmount = typeof calculatedDiscountAmount === 'number' ? calculatedDiscountAmount.toFixed(2) : discountValue;

    const formatDiscount = (amount, type, value) => {
        const subtotal = calculateSubTotal();
        const percentage = value / subtotal * 100;

        if (type === 'percentage') {
            return `${value}% ($${(amount)})`;
        } else if (type === 'amount') {
            const formattedPercentage = isNaN(percentage) ? '0.00%' : percentage.toFixed(2) + '%';
            return `$${value} (${formattedPercentage})`;
        }

        return '';
    };

    return (
        <>
            <div className="row justify-content-end mt-4">
                <div className="col-sm-2 d-flex flex-column align-items-end">
                    <div className="d-flex align-items-end">
                        <label htmlFor="total" className="form-label me-2">Total:</label>
                        <input
                            className="form-control form-control-sm formWidth"
                            id="total"
                            type="text"
                            value={`$${calculateTotal()}`}
                            aria-label="Disabled input example"
                            disabled
                            readOnly
                        />
                    </div>
                    <div className={`d-flex align-items-end mt-2`}>
                        <label htmlFor="due" className="form-label me-2">Due:</label>
                        <input
                            className="form-control form-control-sm formWidth"
                            id="due"
                            type="text"
                            value={`$${totalPay}`}
                            aria-label="Disabled input example"
                            disabled
                            style={{
                                color: '#000',
                                backgroundColor: totalPay > 0 || totalPay < 0 ? 'rgba(255, 0, 0, 0.4)' : 'inherit',
                                border: totalPay > 0 || totalPay < 0 ? '1px solid red' : '1px solid #ced4da'
                            }}
                            readOnly
                        />
                    </div>
                    <div className="d-flex align-items-end mt-2">
                        <label htmlFor="dueOn" className="form-label me-2">Due On:</label>
                        <div className="btn-group">
                            <button
                                type="button"
                                className="cursor-pointer form-control border-dark form-control-sm formWidth dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {dueTerm === "dueOn"
                                    ? calculateDueDate().toLocaleDateString()
                                    : `${calculateDueDate().toLocaleDateString()}`}
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg-end cursor-pointer">
                                <Link
                                    type="button"
                                    className="dropdown-item custom-dropdown-item"
                                    onClick={() => setDueTerm("dueOn")}
                                >
                                    Today
                                </Link>
                                <Link
                                    type="button"
                                    className="dropdown-item custom-dropdown-item"
                                    onClick={() => setDueTerm("net15")}
                                >
                                    Net 15
                                </Link>
                                <Link
                                    type="button"
                                    className="dropdown-item custom-dropdown-item"
                                    onClick={() => setDueTerm("net30")}
                                >
                                    Net 30
                                </Link>
                                <Link
                                    type="button"
                                    className="dropdown-item custom-dropdown-item"
                                    onClick={() => setDueTerm("net45")}
                                >
                                    Net 45
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-5 d-flex flex-column align-items-end">
                    <div className="d-flex align-items-end">
                        <label htmlFor="subTotal" className="form-label me-2">Sub Total:</label>
                        <input className="form-control form-control-sm formWidth" id="subTotal" type="text" value={`$${calculateSubTotal()}`} aria-label="Disabled input example" disabled readOnly />
                    </div>
                    <div className="d-flex align-items-end mt-2">
                        <label htmlFor="discount" className="form-label me-2">Discount:</label>
                        <input
                            className="form-control form-control-sm formWidth"
                            id="discount"
                            type="text"
                            value={formatDiscount(formattedDiscountAmount, discountType, discountValue)}
                            onClick={() => setShowDiscountModal(true)}
                            aria-label="Disabled input example"
                            readOnly
                        />

                    </div>
                    {dispatching.tax === null || selectedTax === "" ? (
                        <div className="d-flex align-items-end mt-2">
                            <label htmlFor="taxable" className="form-label me-2">Taxable:</label>
                            <input
                                className="form-control form-control-sm formWidth"
                                id="taxable"
                                type="text"
                                value="0"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                            />
                        </div>
                    ) : (
                        <div className="d-flex align-items-end mt-2">
                            <label htmlFor="taxable" className="form-label me-2">Taxable:</label>
                            <input
                                className="form-control form-control-sm formWidth"
                                id="taxable"
                                type="text"
                                value={`$${calculateTaxableField()}`}
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                            />
                        </div>
                    )}
                    <div className="d-flex align-items-end mt-2">
                        <label htmlFor="taxable" className="form-label me-2">Tax%:</label>
                        <select
                            className="form-control form-control-sm formWidth"
                            id="taxable"
                            value={selectedTax ? selectedTax._id : ""}
                            onChange={(event) => handleSubmitTax(event.target.value)} // Pass the selected tax ID
                        >
                            {filteredJobTax.map((tax) => (
                                <option key={tax._id} value={tax._id}>
                                    {`${tax.name} (${tax.taxRate}%)`}
                                </option>
                            ))}
                        </select>
                    </div>
                    {dispatching.tax === null || selectedTax === "" ? (
                        <div className="d-flex align-items-end mt-2">
                            <label htmlFor="taxable" className="form-label me-2">Tax:</label> {/* Replace 10% with your actual tax rate */}
                            <input
                                className="form-control form-control-sm formWidth"
                                id="taxable"
                                type="text"
                                value="0"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                            />
                        </div>
                    ) : (
                        <div className="d-flex align-items-end mt-2">
                            <label htmlFor="taxable" className="form-label me-2">Tax:</label>
                            <input
                                className="form-control form-control-sm formWidth"
                                id="taxable"
                                type="text"
                                value={`$${calculateTax()}`}
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                            />
                        </div>
                    )}
                    <div className="d-flex align-items-end mt-2">
                        <label htmlFor="taxable" className="form-label me-2">Item Cost:</label>
                        <input
                            className="form-control form-control-sm formWidth"
                            id="taxable"
                            type="text"
                            value={`$${calculateCostTotal()}`}
                            aria-label="Disabled input example"
                            disabled
                            readOnly
                        />
                    </div>
                    <div className="d-flex align-items-end mt-2">
                        <label htmlFor="taxable" className="form-label me-2">Tech Invoice:</label>
                        <input className="form-control form-control-sm formWidth" id="taxable" type="text" value='' aria-label="Disabled input example" disabled readOnly />
                    </div>
                </div>
                <div className="col-sm-1"></div>
            </div>
        </>
    );
};

export default ItemProceBreakDownComponent;
