import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../../../components/assets/estimate.css';
import { useParams, Link, useNavigate } from "react-router-dom";
import { FaRegThumbsUp } from 'react-icons/fa';
import { PiCaretDownBold } from 'react-icons/pi';
import { BsSend } from 'react-icons/bs';
import axios from 'axios';
import { BsPen } from 'react-icons/bs';
import { HiOutlineWrench } from 'react-icons/hi2';
import { BsTrash } from 'react-icons/bs';
import DeleteJobItemPopModal from '../../../components/dispatch/deleteJobItemPopComponent';
import 'jspdf-autotable';
import { BsDownload } from 'react-icons/bs';
import { AiOutlineEye } from 'react-icons/ai';
import { InvoiceGeneratePDF } from '../../../components/dispatch/invoiceGeneratePDFComponent';
import { InvoiceSendGeneratePDF } from '../../../components/dispatch/invoiceGeneratePDFSendComponent';
import InvoiceDescriptionComponent from '../../../components/dispatch/invoiceDescriptionComponent';
import InvoiceItemPanelComponent from '../../../components/dispatch/invoiceItemPanelComponent';
import InvoiceNotesComponent from '../../../components/dispatch/invoiceNotesComponent';
import InvoiceFileUploadComponent from '../../../components/dispatch/invoiceFileUploadComponent';
import InvoiceSignaturesComponent from '../../../components/dispatch/invoiceSignatureComponent';
import InvoiceEmailPopupComponent from '../../../components/dispatch/invoiceEmailPopupComponent';
import InvoicePaymentTabComponent from '../../../components/dispatch/invoicePaymentTabComponent';

const InvoiceComponent = ({ updateInvoiceDue, fetchPayments, deletePayment, fetchPaymentAll, fetchUploadsAll, fetchDispatch, updateInvoiceSign, updateInvoiceTotal, updateInvoiceTip, updateInvoiceNotes, deleteInvoice, fetchUploading, deleteJobItem, updateJobItemsApiRequest, updateDispatchItemApiRequest, fetchUploads, createJobItemApiRequest, updateInvoiceUpload, fetchCustomer, updateInvoiceStatus, fetchCompany, fetchInvoice, updateInvoiceDescription, getJobTaxById, fetchJobItem, fetchJobTax, updateInvoiceItem, updateInvoiceDiscount, updateInvoiceTax, }) => {
    const auth = useSelector(state => state.auth)
    const [invoice, setInvoice] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [companies, setCompanies] = useState([]);
    const { id } = useParams();
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [description, setDescription] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertEmail, setShowAlertEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState('');
    const [showNotes, setShowNotes] = useState(false);
    const [showSignAlert, setShowSignAlert] = useState(false);
    const [showAlertDelete, setShowAlertDelete] = useState(false);
    const navigate = useNavigate();
    const [jobItem, setJobItem] = useState([]);
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [signatureData, setSignatureData] = useState([{ signatureName: "", signature: "", dateTime: null }]);
    const [dueTerm, setDueTerm] = useState("dueOn");
    const [jobLoading, setJobLoading] = useState(false);
    const [totalPay, setTotalPay] = useState('0.00');
    const [uploadedLogo, setUploadedLogo] = useState(null);

    const [jobId, setJobId] = useState("");
    const [createInvoiceResponseState, setCreateInvoiceResponseState] =
        useState({
            message: "",
            error: ""
        });

    useEffect(() => {
        if (invoice && invoice.dueDate) {
            const today = new Date();
            const net15Date = new Date(today);
            const net30Date = new Date(today);
            const net45Date = new Date(today);

            net15Date.setDate(today.getDate() + 15);
            net30Date.setDate(today.getDate() + 30);
            net45Date.setDate(today.getDate() + 45);

            if (invoice.dueDate === today.toISOString()) {
                setDueTerm("dueOn");
            } else if (invoice.dueDate <= net15Date.toISOString()) {
                setDueTerm("net15");
            } else if (invoice.dueDate <= net30Date.toISOString()) {
                setDueTerm("net30");
            } else if (invoice.dueDate <= net45Date.toISOString()) {
                setDueTerm("net45");
            } else {
                setDueTerm("dueOn");
            }
        }
    }, [invoice]);

    useEffect(() => {
        const abctrl = new AbortController();

        const fetchInvoiceData = async () => {
            try {
                const [invoiceData, jobItemData, customerData, companyData, companyLogo] = await Promise.all([
                    fetchInvoice(id),
                    fetchJobItem(),
                    invoice.customer ? fetchCustomer(invoice.customer) : Promise.resolve(null),
                    fetchCompany(),
                    fetchUploadsAll(),
                ]);

                setInvoice(invoiceData);
                setDescription(invoiceData.description);
                setNotes(invoiceData.notes);
                setDueTerm(invoiceData.dueDate);
                setSignatureData(invoiceData.signature);
                setJobId(invoiceData.jobId);

                const filteredUploads = companyLogo.filter((item) => auth.user.company === item.company);
                const filteredUploadsFin = filteredUploads.find((item) => item.companyLogo === true);

                if (filteredUploadsFin) {
                    setUploadedLogo(filteredUploadsFin);
                } else {
                    setUploadedLogo(null);
                }

                const filterJobItem = jobItemData.filter((item) => item.jobId === invoiceData.jobId && !item.estimateId);
                setJobItem(filterJobItem);

                if (customerData) {
                    setCustomer(customerData);
                }

                const filteredCompany = companyData.find((item) => item._id === auth.user.company);
                setCompanies(filteredCompany);

                setLoading(false);
            } catch (error) {
                console.error(error.response?.data.message || error.response?.data || error.message || error);
            }
        };

        fetchInvoiceData();

        return () => abctrl.abort();
    }, [id, auth.user.company, fetchCompany, fetchCustomer, fetchInvoice, fetchJobItem, invoice.customer, fetchUploadsAll]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (loading) {
            const fetchInvoiceData = async () => {
                try {
                    const [invoiceData, jobItemData, customerData, companyData, companyLogo] = await Promise.all([
                        fetchInvoice(id),
                        fetchJobItem(),
                        invoice.customer ? fetchCustomer(invoice.customer) : Promise.resolve(null),
                        fetchCompany(),
                        fetchUploadsAll(),
                    ]);

                    setInvoice(invoiceData);
                    setDescription(invoiceData.description);
                    setNotes(invoiceData.notes);
                    setDueTerm(invoiceData.dueDate);
                    setSignatureData(invoiceData.signature);
                    setJobId(invoiceData.jobId);

                    const filteredUploads = companyLogo.filter((item) => auth.user.company === item.company);
                    const filteredUploadsFin = filteredUploads.find((item) => item.companyLogo === true);

                    if (filteredUploadsFin) {
                        setUploadedLogo(filteredUploadsFin);
                    } else {
                        setUploadedLogo(null);
                    }

                    const filterJobItem = jobItemData.filter((item) => item.jobId === invoiceData.jobId && !item.estimateId);
                    setJobItem(filterJobItem);

                    if (customerData) {
                        setCustomer(customerData);
                    }

                    const filteredCompany = companyData.find((item) => item._id === auth.user.company);
                    setCompanies(filteredCompany);

                    setLoading(false);
                } catch (error) {
                    console.error(error.response?.data.message || error.response?.data || error.message || error);
                }
            };

            fetchInvoiceData();
        }
        return () => abctrl.abort();
    }, [id, loading, auth.user.company, fetchCompany, fetchCustomer, fetchInvoice, fetchJobItem, invoice.customer, fetchUploadsAll]);

    const handleSubmitDescription = (inputValue) => {
        setDescription(inputValue);

        setShowDescriptionModal(false);

        const formInputsDesc = {
            description: inputValue,
            company: auth.user.company,
        };

        updateInvoiceDescription(id, formInputsDesc)
            .then((data) => {
                if (data.message === "invoice updated") {
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                }
            })
            .catch((er) => {
                setCreateInvoiceResponseState({
                    error: er.response.data.message
                        ? er.response.data.message
                        : er.response.data
                });
            });
    };

    const handleCancelDescription = () => {
        setShowDescriptionModal(false);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const deleteInvoiceClick = async () => {
        setShowAlertDelete(true);
    };
    const handleCancelRemoveInvoice = async () => {
        setShowAlertDelete(false);
    };

    const deleteInvoiceHandler = async () => {
        const jobId = invoice.jobId;
        const data = await deleteInvoice(id);
        if (data.message === 'invoice deleted successfully') {
            navigate(`/dispatch/job/${jobId}`)

        }
    };

    function getBase64FromBlob(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    const handleGeneratePDF = async () => {
        try {
            const data = await fetchJobTax();
            const filterJobTax = data.find((item) => item._id === invoice.tax);

            if (!filterJobTax) {
                console.error("Job tax not found:", invoice.tax);
                return;
            }

            let imgData = null;
            if (uploadedLogo) {
                try {
                    const response = await axios.get(`/api/uploads/download/${uploadedLogo.file}`, { responseType: 'blob' });
                    const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
                    imgData = await getBase64FromBlob(blob);
                } catch (error) {
                    console.error('Error fetching image:', error);
                    // Optionally handle the error (e.g., show a placeholder image)
                }
            }
            if (filterJobTax) {
                InvoiceGeneratePDF(
                    totalPay,
                    dueTerm,
                    companies,
                    imgData,
                    formatDateTime,
                    invoice,
                    filterJobTax,
                    customer,
                    jobItem,
                    signatureData,
                );
            } else {
                setTimeout(() => {
                    InvoiceGeneratePDF(
                        totalPay,
                        dueTerm,
                        companies,
                        imgData,
                        formatDateTime,
                        invoice,
                        filterJobTax,
                        customer,
                        jobItem,
                        signatureData,
                    );
                }, 3000);
            }
        } catch (error) {
            console.error("Error fetching or processing job tax:", error);
        }
    };

    const handleGeneratePDFSend = async () => {
        try {
            const data = await fetchJobTax();
            const filterJobTaxCom = data.filter((item) => item._id === invoice.tax);
            const filterJobTax = filterJobTaxCom.find((item) => item.company === auth.user.company);

            if (!filterJobTaxCom) {
                console.error("Job tax not found for invoice.tax:", invoice.tax);
                return;
            }

            let imgData = null;
            if (uploadedLogo) {
                try {
                    const response = await axios.get(`/api/uploads/download/${uploadedLogo.file}`, { responseType: 'blob' });
                    const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
                    imgData = await getBase64FromBlob(blob);
                } catch (error) {
                    console.error('Error fetching image:', error);
                    // Optionally handle the error (e.g., show a placeholder image)
                }
            }
                if (filterJobTaxCom) {
                    InvoiceSendGeneratePDF(
                        totalPay,
                        dueTerm,
                        uploadFileToDatabaseInvoice,
                        companies,
                        imgData,
                        formatDateTime,
                        invoice,
                        filterJobTax,
                        customer,
                        jobItem,
                        signatureData,
                    );
                } else {
                    setTimeout(() => {
                        InvoiceSendGeneratePDF(
                            totalPay,
                            dueTerm,
                            uploadFileToDatabaseInvoice,
                            companies,
                            imgData,
                            formatDateTime,
                            invoice,
                            filterJobTax, // Pass the filtered job tax directly
                            customer,
                            jobItem,
                            signatureData,
                        );
                    }, 3000);
                }
                setShowEmailPopup(true);
        } catch (error) {
            console.error("Error fetching or processing job tax:", error);
        }
    };

    function formatDateTime(dateTime) {
        const options = {
            weekday: "short",
            month: "short",
            day: "numeric",
            year: "numeric",
        };

        return new Date(dateTime).toLocaleDateString(undefined, options);
    }

    const uploadFileToDatabaseInvoice = (pdfBlob) => {

        const companyId = auth.user.company;
        const jobId = invoice.jobId;
        const invoiceId = id;

        const formData = new FormData();
        formData.append('file', pdfBlob, `invoice+${customer.name}+${customer.lastName}.pdf`); // Use pdfBlob here
        formData.append('company', companyId);
        formData.append('job', jobId);
        formData.append('invoice', invoiceId);

        axios.post('/api/uploads/add', formData)
            .then((res) => {
                if (res.data.message === "File Added") {
                    setSelectedFile(res.data.file);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleSendEmail = (email, subject, description, cc) => {
        if (!selectedFile) {
            console.log('No file selected for email attachment.');
            return;
        }
        const blob = new Blob([selectedFile]);

        const reader = new FileReader();
        reader.onload = (event) => {
            const fileContent = event.target.result.split(',')[1];

            const emailData = {
                to: email,
                subject: subject,
                invoiceId: id,
                estimateId: '',
                cc: cc,
                text: description,
                fileName: `invoice+${customer.name}+${customer.lastName}.pdf`,
                attachment: {
                    filename: selectedFile.file, // Use name property for filename
                    content: fileContent,
                },
            };

            axios.post('/api/email/send-email-invoice', emailData)
                .then((response) => {

                    updateInvoiceStatus(id, { status: true })
                        .then((data) => {
                            if (data.message === "invoice updated") {
                                axios.delete(`/api/uploads/${selectedFile._id}`)
                                    .then((res) => {
                                        setLoading(true);
                                        setShowAlertEmail(true);
                                        setTimeout(() => {
                                            setShowAlertEmail(false);
                                        }, 3000);
                                    })
                                    .catch((error) => {
                                        console.error('Error deleting file:', error);
                                    });

                            }
                        })
                        .catch((er) => {
                            setCreateInvoiceResponseState({
                                error: er.response.data.message
                                    ? er.response.data.message
                                    : er.response.data
                            });
                        });
                })
                .catch((error) => {
                    console.log('Error sending email:', error);
                });
        };

        reader.readAsDataURL(blob); // Read the selected file as a data URL
    };

    return (
        <div className="pt-4">
            <div className="container mt-5 pt-4 mb-5 pb-5">
                {showEmailPopup && (
                    <InvoiceEmailPopupComponent
                        invoice={invoice}
                        customer={customer}
                        companies={companies}
                        onClose={() => setShowEmailPopup(false)}
                        onSend={handleSendEmail}
                    />
                )}
                {showAlert && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Invoice Updated Successfully
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlertEmail && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Invoice Emailed Successfully
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlertDelete && (
                    <DeleteJobItemPopModal
                        onConfirm={deleteInvoiceHandler}
                        onCancel={handleCancelRemoveInvoice}
                    />
                )}
                {showDescriptionModal && (
                    <InvoiceDescriptionComponent
                        description={description}
                        onCancel={handleCancelDescription}
                        onConfirm={handleSubmitDescription}
                    />
                )}

                <div className="row invoiceHeader d-flex justify-content-between align-items-center">
                    <div className="col-sm-9 m-0 p-0">
                        <ul className="headerEstimateTextTitle mt-2">
                            <li className="clientTextInvoice">Client: {customer.name} {customer.lastName}</li>
                        </ul>
                    </div>
                    <div className="col-sm-3 d-flex justify-content-end">
                        <div className="me-2">
                            <div className="btngroupEstimate" role="group">
                                <button id="btnGroupDrop1" type="button" className="btn borderWhite btngroupEstimate dropdowntoggleEstimate" data-bs-toggle="dropdown" aria-expanded="false">
                                    <PiCaretDownBold />  Actions
                                </button>
                                <ul className="dropdown-menu dropdown-menu-lg-end mt-2" aria-labelledby="btnGroupDrop1">
                                    <li><Link className="dropdown-item" to={`/dispatch/job/${invoice.jobId}`}><HiOutlineWrench className="imgIconSM me-2" /> View Job</Link></li>
                                    <li><Link className="dropdown-item" target="_blank" to={`/clientPortal/invoice/${id}`}><AiOutlineEye className="me-2 imgIconSM" />Preview</Link></li>
                                    <li><Link className="dropdown-item" type="button" onClick={() => handleGeneratePDF()}><BsDownload className="me-2 imgIconSM" /> Download</Link></li>
                                    <li><span className="dropdown-item dropdownCurser" onClick={() => setShowSignAlert(true)}><BsPen className="me-2 imgIconSM" /> Sign</span></li>
                                    <li><Link className="dropdown-item" type="button" onClick={() => deleteInvoiceClick()}><BsTrash className="imgIconSM me-2" /> Delete</Link></li>
                                </ul>
                            </div>
                        </div>
                        <button className="btn btn-outline-primary estimateSendButton"
                            onClick={() => {
                                handleGeneratePDFSend();
                            }}
                        >
                            <BsSend className="me-1" />
                            Send
                        </button>
                    </div>
                </div>
                <div className="estimateBox">
                    <div className="row d-flex justify-content-between">
                        <div className="col-sm-2">
                            <div className="clientTextInvoice">
                                Bill To:
                            </div>
                            <div className="clientRenderText ms-2">
                                {customer.companyClient && (
                                    <>
                                        {customer.companyClient}
                                        <br />
                                    </>
                                )}
                                {customer.address}
                                <br />
                                {customer.city}, {customer.state} {customer.zip}
                                <br />
                                {customer.phone}
                                {customer.email && (
                                    <>
                                        <br />
                                        {customer.email}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="clientTextInvoice">
                                Service Address
                            </div>
                            <div className="clientRenderText ms-2">
                                {customer.companyClient && (
                                    <>
                                        {customer.companyClient}
                                        <br />
                                    </>
                                )}
                                {customer.address}
                                <br />
                                {customer.city}, {customer.state} {customer.zip}
                                <br />
                                {customer.phone}
                                {customer.email && (
                                    <>
                                        <br />
                                        {customer.email}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="estimateNoText">
                                <p className="clientTextInvoice">
                                    Invoice:
                                </p>
                                <p className="estimateNoTextNum ps-5">
                                    {invoice.count}
                                </p>
                            </div>
                            <div className="estimateNoText">
                                <p className="clientTextInvoice pe-4">
                                    Sent:
                                </p>
                                <p className="estimateNoTextNum ps-5">
                                    {invoice.status === false ? (
                                        <span className="text-danger">No</span>
                                    ) : (
                                        <span className="text-success">Yes</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <InvoiceItemPanelComponent totalPay={totalPay} updateInvoiceDue={updateInvoiceDue} fetchDispatch={fetchDispatch} customer={customer} fetchUploadsAll={fetchUploadsAll} updateInvoiceTotal={updateInvoiceTotal} updateInvoiceTip={updateInvoiceTip} updateDispatchItemApiRequest={updateDispatchItemApiRequest} deleteJobItem={deleteJobItem} updateJobItemsApiRequest={updateJobItemsApiRequest} createJobItemApiRequest={createJobItemApiRequest} getJobTaxById={getJobTaxById} id={id} invoice={invoice} fetchInvoice={fetchInvoice} fetchJobItem={fetchJobItem} fetchJobTax={fetchJobTax} updateInvoiceItem={updateInvoiceItem} updateInvoiceDiscount={updateInvoiceDiscount} updateInvoiceTax={updateInvoiceTax} />
                </div>
                <div className="notesEstimateWrapper mt-5">
                    <div className="notesWrapper">
                        <div className="notesTitle jobSectionSpan">Invoice Notes</div>
                        {showNotes ? (
                            <InvoiceNotesComponent setNotes={setNotes} setLoading={setLoading} id={id} setShowNotes={setShowNotes} updateInvoiceNotes={updateInvoiceNotes} notes={notes} />
                        ) : notes ? (
                            <div className="notesModuleWrapper">
                                <div className="notesText">
                                    {notes}
                                </div>
                                <div className="estimateAddPlus11" onClick={() => setShowNotes(true)}>
                                    (+ Edit)
                                </div>
                            </div>
                        ) : (
                            <div className="notesModuleWrapper">
                                <div className="notesHRWrapper">
                                    <hr className="hrNotes1Module" />
                                    <hr className="hrNotes1Module" />
                                    <hr className="hrNotes2Module" />
                                    <hr className="hrNotes1Module" />
                                    <hr className="hrNotes2Module" />
                                    <div className="estimateAddPlus" onClick={() => setShowNotes(true)}>
                                        (+ Add)
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="depositsWrapper">
                        <div className="depositTitle jobSectionSpan">Payments</div>
                        <InvoicePaymentTabComponent id={jobId} deletePayment={deletePayment} fetchDispatch={fetchDispatch} jobLoading={jobLoading} setJobLoading={setJobLoading} fetchPaymentAll={fetchPaymentAll} setCompanies={setCompanies} companies={companies} setTotalPay={setTotalPay} fetchPayments={fetchPayments} />
                    </div>
                </div>
                <div className="notesEstimateWrapper mt-5 mb-5 pb-5">
                    <div className="notesWrapper">
                        <InvoiceFileUploadComponent invoice={invoice} fetchUploading={fetchUploading} id={id} />
                    </div>
                    <div className="depositsWrapper">
                        <InvoiceSignaturesComponent setShowSignAlert={setShowSignAlert} showSignAlert={showSignAlert} fetchInvoice={fetchInvoice} customer={customer} updateInvoiceSign={updateInvoiceSign} />
                    </div>
                </div>
                {signatureData.length > 0 && (
                    <div className="row d-none mt-5 p-0 m-0 headerCard justify-content-center">
                        <div className="col-sm-12 p-0 m-0 ">
                            <table className="table table-striped" id="mytable">
                                <thead className="border-top border-bottom border-dark">
                                    <tr>
                                        <th scope="col" style={{ width: '55%' }}>Signature</th>
                                        <th scope="col" className="text-center">Signed By</th>
                                        <th scope="col" className="text-center">Signed</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-light">
                                    {signatureData.map((signatureItem, index) => (
                                        <tr key={index}>
                                            <td>
                                                <img src={signatureItem.signature} className="signatureImage" alt="Signature Data" />
                                            </td>
                                            <td className="text-center">{signatureItem.signatureName}</td>
                                            <td className="text-center">{formatDateTime(signatureItem.dateTime)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {createInvoiceResponseState.error ?? ""}
            </div>
        </div>
    );
};

export default InvoiceComponent;
