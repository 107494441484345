import { GoPencil } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { Link } from "react-router-dom";
import paths from "../../../router/paths";
import AdminDeletePopUpComponent from '../../../components/admin/adminDeletePopUpComponent';

const AdminCompanyComponent = ({ fetchCompany, deleteCompany }) => {
    const [company, setCompany] = useState([]);
    const [companyDeleted, setCompanyDeleted] = useState(false);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const rowsPerPage = 20;
    const [searchValue, setSearchValue] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [companyId, setCompanyId] = useState("");

    const handleConfirmRemove = async () => {
        const data = await deleteCompany(companyId);
        if (data.message === 'Company deleted successfully') {
            setCompanyDeleted(!companyDeleted)
            setShowConfirmation(false);
            setIsLoading(true);
        }
    }
    const deleteHandler = (companyId) => {
        setCompanyId(companyId);
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    useEffect(() => {
        const abctrl = new AbortController();
        setIsLoading(true);
        fetchCompany(abctrl)
            .then((res) => {
                setCompany(res);
                setIsLoading(false);
            })
            .catch((er) =>
                console.log(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
        return () => abctrl.abort();
    }, [deleteCompany, dispatch, fetchCompany]);

    useEffect(() => {
        const abctrl = new AbortController();
        if (isLoading) {
            fetchCompany(abctrl)
                .then((res) => {
                    setCompany(res);
                    setIsLoading(false);
                })
                .catch((er) =>
                    console.log(
                        er.response.data.message ? er.response.data.message : er.response.data
                    )
                );
        }
        return () => abctrl.abort();
    }, [deleteCompany, isLoading, dispatch, fetchCompany]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => prevPage - 1);
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value.toLowerCase());
        setPage(1);
    };

    return (
        <div className="min-vh-100">
            {showConfirmation && (
                <AdminDeletePopUpComponent
                    title='Delete this company?'
                    onConfirm={handleConfirmRemove}
                    onCancel={handleCancel}
                />
            )}
            <div className="row min-vh-100 m-0 p-0">
                <div className="col-sm-2 m-0 p-0">
                    <AdminLinksComponent />
                </div>
                <div className="col-sm-10 mt-5 pt-5">
                    <div className="row mb-3">
                        <div className="col-sm-8">
                            <h1>Company List</h1>
                        </div>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                className="mt-2 input-group table-filter w-100"
                                data-table="order-table"
                                placeholder="Search Companies.."
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="col-sm-1">
                            <Link to={paths.ADMINNEWCOMPANY} className="btn btn-outline-primary loginButton">New</Link>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center m-5 p-5">
                            <div className="spinner-border text-primary" style={{ width: 3 + 'rem', height: 3 + 'rem' }} role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <table className="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">Company Name</th>
                                    <th scope="col" className="text-center">Address</th>
                                    <th scope="col" className="text-center">Phone</th>
                                    <th scope="col" className="text-center">Contact Name</th>
                                    <th scope="col" className="text-center">Edit/Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {company
                                    .filter(
                                        (companys) =>
                                            companys.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                            companys.phone.toLowerCase().indexOf(searchValue) !== -1
                                    )
                                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                    .map((companys, idx) => (
                                        <tr key={idx}>
                                            <td className="text-center">{companys.name}</td>
                                            <td className="text-center">{companys.address} <br />{companys.city}, {companys.state}, {companys.zip}, {companys.country}</td>
                                            <td className="text-center">{companys.phone}</td>
                                            <td className="text-center">{companys.contactName}</td>
                                            <td className="text-center">
                                                <Link to={`/admin/edit-company/${companys._id}`}>
                                                    <button type="button" className="btn-sm btn btn-outline-primary">
                                                        <GoPencil />
                                                    </button>
                                                </Link>
                                                {" / "}
                                                <button
                                                    type="button"
                                                    className="btn-sm btn btn-outline-danger"
                                                    onClick={() => deleteHandler(companys._id)}
                                                >
                                                    <BsTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                    )}
                            </tbody>
                        </table>
                    )}
                    <div className="d-flex justify-content-center pb-4">
                        <button
                            type="button"
                            className="btn-sm btn btn-outline-primary mr-2 me-3 loginButton"
                            disabled={page === 1}
                            onClick={handlePrevPage}
                        >
                            Previous
                        </button>
                        <span className="mr-2 me-3">
                            Page {page} of {Math.ceil(company
                                .filter(
                                    (companys) =>
                                        companys.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                        companys.phone.toLowerCase().indexOf(searchValue) !== -1
                                ).length / rowsPerPage)}
                        </span>
                        <button
                            type="button"
                            className="btn-sm btn btn-outline-primary loginButton"
                            disabled={company
                                .filter(
                                    (companys) =>
                                        companys.name.toLowerCase().indexOf(searchValue) !== -1 ||
                                        companys.phone.toLowerCase().indexOf(searchValue) !== -1
                                ).length <= page * rowsPerPage}
                            onClick={handleNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminCompanyComponent;