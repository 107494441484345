import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaRegThumbsUp } from 'react-icons/fa';
import ProfileLinksComponent from "../../../components/profile/profileLinksComponent";
import ChangePasswordPopComponent from "../../../components/profile/changePasswordPopComponent";
import VerifyOTPPopComponent from "../../../components/profile/verifyOTPPopComponent";
import ChangeEmailUserPopComponent from "../../../components/profile/changeEmailUserPopComponent";

const UserProfilePageComponent = ({ updateUser2FAApiRequest, updateUserApiRequest, fetchUser, fetch2FA }) => {
    const [validated, setValidated] = useState(false);
    const [updateUserResponseState, setUpdateUserResponseState] = useState({ success: "", error: "" });
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const auth = useSelector(state => state.auth);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [showVerifyEmail, setShowVerifyEmail] = useState(false);
    const [showEmailEdit, setShowEmailEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formState, setFormState] = useState({
        name: "",
        lastName: "",
        username: "",
        email: "",
        phoneNumber: "",
    });

    useEffect(() => {
        fetchUser(auth.user?._id)
            .then((data) => {
                setFormState({
                    ...formState,
                    name: data.name,
                    lastName: data.lastName,
                    username: data.username,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                });
                setFormattedPhoneNumber(data.phoneNumber);
                setIs2FAEnabled(data.enable2FA);
            })
            .catch((er) => console.log(er));
    }, [auth.user?._id, fetchUser]);

    useEffect(() => {

        if (loading) {
            fetchUser(auth.user?._id)
                .then((data) => {
                    setFormState({
                        ...formState,
                        name: data.name,
                        lastName: data.lastName,
                        username: data.username,
                        email: data.email,
                        phoneNumber: data.phoneNumber,
                    });
                    setFormattedPhoneNumber(data.phoneNumber);
                    setIs2FAEnabled(data.enable2FA);
                })
                .catch((er) => console.log(er));
        }
    }, [auth.user?._id, loading, fetchUser]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value,
        });

        if (name === "phoneNumber") {
            const phoneNumber = value.replace(/\D/g, '');
            const formattedNumber = phoneNumber.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1)-$2-$3'
            );
            setFormattedPhoneNumber(formattedNumber);
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { name, lastName, phoneNumber, username } = formState;

        const formInputs = {
            name: capitalizeFirstLetter(name),
            lastName: capitalizeFirstLetter(lastName),
            phoneNumber: phoneNumber,
            email: auth.user.email,
            username: username,
            company: auth.user.company,
        };

        updateUserApiRequest(formInputs, auth.user._id).then(data => {
            setUpdateUserResponseState({ success: data.success, error: "" });

            setTimeout(() => {
                setUpdateUserResponseState({ success: "", error: "" });
            }, 3000);
        })
            .catch((er) => {
                setUpdateUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
                setTimeout(() => {
                    setUpdateUserResponseState({ success: "", error: "" });
                }, 3000);
            });

        setValidated(true);
    };

    const handleCloseAlert = () => {
        setUpdateUserResponseState({ success: "", error: "" });
    };

    const handleCloseAlertSuc = () => {
        setUpdateUserResponseState({ success: "", error: "" });
    };

    const handleChangePassword = () => {
        const formInputs = {
            email: auth.user.email,
        }

        fetch2FA(formInputs)
            .then(data => {

                if (data.message === "2FA code sent to your email!") {
                    setShowVerify(true)
                }
            })
            .catch((er) => {
                setUpdateUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
                setTimeout(() => {
                    setUpdateUserResponseState({ success: "", error: "" });
                }, 3000);
            });
    };

    const handleChangeEmail = () => {
        const formInputs = {
            email: auth.user.email,
        }

        fetch2FA(formInputs)
            .then(data => {

                if (data.message === "2FA code sent to your email!") {
                    setShowVerifyEmail(true)
                }
            })
            .catch((er) => {
                setUpdateUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
                setTimeout(() => {
                    setUpdateUserResponseState({ success: "", error: "" });
                }, 3000);
            });
    };

    const handleCancelVerify = () => {
        setShowVerify(false);
    };

    const handleConfirmVerify = () => {
        setShowVerify(false);
        setShowChangePassword(true);
    };

    const handleCancelChangePassword = () => {
        setShowChangePassword(false);
    };

    const handleConfirmChangePassword = () => {
        setUpdateUserResponseState({ success: 'user updated', error: "" });

        setTimeout(() => {
            setUpdateUserResponseState({ success: "", error: "" });
        }, 3000);

        setShowChangePassword(false);
    };

    const handleCheckboxChange = (e) => {
        setIs2FAEnabled(e.target.checked);

        const formInputs = {
            id: auth.user._id,
            enable2FA: e.target.checked,
        }

        updateUser2FAApiRequest(formInputs)
            .then(data => {
                setUpdateUserResponseState({ success: data.success, error: "" });

                setTimeout(() => {
                    setUpdateUserResponseState({ success: "", error: "" });
                }, 3000);
            })
    };

    const handleCancelVerifyEmail = () => {
        setShowVerifyEmail(false);
    };

    const handleConfirmVerifyEmail = () => {
        setShowVerifyEmail(false);
        setShowEmailEdit(true);
    };

    const handleCancelChangeEmail = () => {
        setShowEmailEdit(false);
    };

    const handleConfirmChangeEmail = () => {
        setUpdateUserResponseState({ success: 'user updated', error: "" });

        setTimeout(() => {
            setUpdateUserResponseState({ success: "", error: "" });
        }, 3000);

        setShowEmailEdit(false);
        setLoading(true);
    };

    return (
        <div className="min-vh-100">
            {showChangePassword && (
                <ChangePasswordPopComponent
                    onConfirm={handleConfirmChangePassword}
                    onCancel={handleCancelChangePassword}
                />
            )}
            {showVerifyEmail && (
                <VerifyOTPPopComponent
                    onConfirm={handleConfirmVerifyEmail}
                    onCancel={handleCancelVerifyEmail}
                />
            )}
            {showEmailEdit && (
                <ChangeEmailUserPopComponent
                    formStateMain={formState}
                    auth={auth}
                    updateUserApiRequest={updateUserApiRequest}
                    onConfirm={handleConfirmChangeEmail}
                    onCancel={handleCancelChangeEmail}
                />
            )}
            {showVerify && (
                <VerifyOTPPopComponent
                    onConfirm={handleConfirmVerify}
                    onCancel={handleCancelVerify}
                />
            )}
            {updateUserResponseState.error && (
                <div className="alertRed alert-danger" role="alert">
                    Something Went Wrong
                    <div className="alert-line-container">
                        <div className="alert-lineRed"></div>
                        <span className="alert-closeRed" onClick={handleCloseAlert}>
                            X
                        </span>
                    </div>
                </div>
            )}
            {updateUserResponseState.success === "user updated" && (
                <div className="alert alert-success" role="alert">
                    <FaRegThumbsUp className="me-3 mb-2" />
                    User Updated
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={handleCloseAlertSuc}>
                            X
                        </span>
                    </div>
                </div>
            )}
            <div className="container-fluid min-vh-100 m-0 p-0">
                <div className="row m-0 p-0 justify-content-md-center min-vh-100">
                    <div className="col-sm-2 m-0 p-0">
                        <ProfileLinksComponent />
                    </div>
                    <div className="col-sm-10 ms-0 ps-0 mt-5">
                        <h1 className="text-start ms-2">Welcome {auth.user?.name}</h1>
                        <div className="row mt-5 m-0 p-0 justify-content-md-between">
                            <div className="col-sm-4 ms-5 m-0 p-0">
                                <form validated={validated.toString()} onSubmit={handleSubmit} className="row mt-4 g-3">
                                    <div className="row m-0 p-0">
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="name">First Name</label>
                                            <input
                                                type="text"
                                                id="name"
                                                className="form-control"
                                                value={formState.name}
                                                name="name"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formState.lastName}
                                                name="lastName"
                                                id="lastName"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row m-0 p-0">
                                        <div className="col mb-3">
                                            <label htmlFor="username">Username:</label>
                                            <input
                                                className="form-control"
                                                value={formState.username}
                                                type="text"
                                                id="username"
                                                name="username"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row m-0 mt-2 p-0">
                                        <div className="col mb-3">
                                            <label htmlFor="email">Email:</label>
                                            <input
                                                className="form-control"
                                                value={formState.email}
                                                disabled
                                                type="text"
                                                id="email"
                                                name="email"
                                            />
                                        </div>
                                        <div className="col mb-3">
                                            <label htmlFor="phone">Phone Number</label>
                                            <input
                                                type="text"
                                                placeholder="(xxx)-xxx-xxxx"
                                                value={formattedPhoneNumber}
                                                onChange={handleChange}
                                                name="phoneNumber"
                                                className="form-control"
                                                id="phone"
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center mt-3 mb-4">
                                        <button className="btn btn-outline-success loginButton" type="submit">
                                            Update Profile
                                        </button>
                                    </div>

                                </form>
                            </div>
                            <div className="col-sm-5 border">
                                <h3 className="text-start mt-2 mb-2">User Security</h3>
                                <div className="row d-flex justify-content-start">
                                    <div className="col-sm-4 text-end mt-3 mb-4">
                                        <span>Change your email:</span>
                                    </div>
                                    <div className="col-sm-3 text-start mt-3 mb-4">
                                        <button className="btn btn-sm btn-outline-primary loginButton" onClick={() => handleChangeEmail()}>
                                            Change Email
                                        </button>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-start">
                                    <div className="col-sm-4 text-end mt-3 mb-4">
                                        <span>Change your password:</span>
                                    </div>
                                    <div className="col-sm-3 text-start mt-3 mb-4">
                                        <button className="btn btn-sm btn-outline-primary loginButton" onClick={() => handleChangePassword()}>
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-sm-4">
                                        <div className="form-check form-switch form-check-reverse">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexSwitchCheckReverse"
                                                checked={is2FAEnabled}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label pe-3" htmlFor="flexSwitchCheckReverse">Enable 2FA:</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfilePageComponent;