import { LOGOUT_USER } from '../constants/userConstants'
import axios from 'axios'

export const setReduxUserState = (email, password) => async (dispatch) => {
    try {
        const response = await fetch('/api/users/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        });
        const data = await response.json();

        if (data.message === 'Login successful') {

            if (data.userLoggedIn.name) localStorage.setItem("userInfo", JSON.stringify(data.userLoggedIn));
            else sessionStorage.setItem("userInfo", JSON.stringify(data.userLoggedIn));

            dispatch({ type: 'LOGIN_SUCCESS', payload: data });
            document.location.href = "/"
        } else {
            throw new Error(data.message);
        }
    } catch (error) {
        dispatch({ type: 'LOGIN_FAILURE', payload: error.message });

        return { success: false, message: error.message };
    }
};

export const logout = () => (dispatch) => {
    document.location.href = "/login";
    axios.get('/api/logout')
    localStorage.removeItem("userInfo");
    sessionStorage.removeItem("userInfo");
    dispatch({ type: LOGOUT_USER })
}