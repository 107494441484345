import React, { useEffect } from 'react';
import axios from "axios";
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { logout } from '../redux/actions/action';
import paths from '../router/paths';
//import { useMediaQuery } from './useMediaQuery';


const Layout = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isAuthChecked, setIsAuthChecked] = useState(null);

    const checkToken = async () => {
        try {
            const { data } = await axios.get(`/api/verify/token`, { withCredentials: true });

            if (data.message === 'No token provided') {
                setIsAuthChecked(false);
               // dispatch(logout());
                //navigate(paths.LOGIN);
            } else {
                setIsAuthChecked(true);
            }
        } catch (error) {
            console.error("Token validation error:", error);
            setIsAuthChecked(false);
           // dispatch(logout());
           // navigate(paths.LOGIN);
            return { isAuthenticated: false, error: error.response ? error.response.data.message : error.message };
        }
    };

    useEffect(() => {
        checkToken();
    }, [navigate]);

    //const isPageWide = useMediaQuery('(min-width: 992px)')
    //const isPageWide1 = useMediaQuery('(max-width: 991px)')
    const fetchInventory = async (abctrl) => {
        const { data } = await axios.get("/api/inventory/all", { withCredentials: true });
        return data
    }
    const fetchTechInfo = async (techId) => {
        const { data } = await axios.get(`/api/techInfo/graph`, { withCredentials: true });
        return data
    }
    const fetchUsers = async () => {
        const { data } = await axios.get(`/api/users/inventory/`, { withCredentials: true });
        return data
    }
    const fetchLocation = async (abctrl) => {
        const { data } = await axios.get("/api/inventoryLocation/", { withCredentials: true });
        return data
    }
    const fetchRequest = async (abctrl) => {
        const { data } = await axios.get("/api/request", { withCredentials: true });
        return data
    }
    const fetchTesk = async (abctrl) => {
        const { data } = await axios.get("/api/task/all", { withCredentials: true });
        return data
    }
    const fetchUsersData = async () => {
        const { data } = await axios.get(`/api/users/inventory/`, { withCredentials: true });
        return data
    }
    const clockStatus = async (userId) => {
        const { data } = await axios.get(`/api/timeEntry/status/${userId}`, { withCredentials: true });
        return data
    }
    const updateTaskTechApiRequest = async (taskId, formInputsTech) => {
        const { data } = await axios.put(`/api/task/tech/${taskId}`, { ...formInputsTech }, { withCredentials: true });
        return data;
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            {isAuthChecked && (
                <HeaderComponent
                    updateTaskTechApiRequest={updateTaskTechApiRequest}
                    clockStatus={clockStatus}
                    fetchUsersData={fetchUsersData}
                    fetchTesk={fetchTesk}
                    fetchInventory={fetchInventory}
                    fetchTechInfo={fetchTechInfo}
                    fetchRequest={fetchRequest}
                    fetchUsers={fetchUsers}
                    fetchLocation={fetchLocation}
                />
            )}
            <div className="min-vh-100">
                <main className="mt-5 pt-4 min-vh-100">{children}</main>
            </div>
            {isAuthChecked && (
                <FooterComponent />
            )}
        </div>
    );
};

export default Layout;