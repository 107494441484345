import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import paths from "../../router/paths";

const OtherJobSelectComponent = () => {
    const [selectedJob, setSelectedJob] = useState(""); // Initialize state for selected job
    const [selectedAutoJob, setSelectedAutoJob] = useState(false);
    const [noJob, setNoJob] = useState(false);

    const navigate = useNavigate();

    const handleLinkClick = (job) => {
        setSelectedJob(job);
        setNoJob(false);

        if (job === 'Automotive Lockout') {
            setSelectedAutoJob(true);
        } else if (job === 'Automotive Rekey') {
            setSelectedAutoJob(true);
        } else {
            setSelectedAutoJob(false);
        }
    };

    const handleSubmitClick = () => {
        if (selectedJob) {
            const encodedSelectedJob = encodeURIComponent(selectedJob);
            const dispatchUrl = `/dispatch/${encodedSelectedJob}`;
            navigate(dispatchUrl);
        } else {
            setNoJob(true);
        }
    };

    return (
        <>
            <div className="container margintop1 mb-5 pb-5">
                <div className="row justify-content-center">
                    <div className="col-sm-10 text-center">
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ${selectedJob === "Residential Lockout" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Residential Lockout")}
                        >
                            Residential Lockout
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Commercial Lockout" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Commercial Lockout")}
                        >
                            Commercial Lockout
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Automotive Lockout" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Automotive Lockout")}
                        >
                            Automotive Lockout
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Other Lockout" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Other Lockout")}
                        >
                            Other Lockout
                        </Link>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-sm-10 text-center">
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ${selectedJob === "Residential Rekey" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Residential Rekey")}
                        >
                            Residential Rekey
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Commercial Rekey" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Commercial Rekey")}
                        >
                            Commercial Rekey
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Automotive Rekey" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Automotive Rekey")}
                        >
                            Automotive Rekey
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Other Rekey" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Other Rekey")}
                        >
                            Other Rekey
                        </Link>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-sm-10 text-center">
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ${selectedJob === "Residential Diagnose" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Residential Diagnose")}
                        >
                            Residential Diagnose
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Residential Hardware Install/Repair" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Residential Hardware Install/Repair")}
                        >
                            Residential Hardware Install/Repair
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Residential Other" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Residential Other")}
                        >
                            Residential Other
                        </Link>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-sm-10 text-center">
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ${selectedJob === "Commercial Diagnose" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Commercial Diagnose")}
                        >
                            Commercial Diagnose
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Commercial Hardware Install/Repair" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Commercial Hardware Install/Repair")}
                        >
                            Commercial Hardware Install/Repair
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Commercial Other" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Commercial Other")}
                        >
                            Commercial Other
                        </Link>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-sm-10 text-center">
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Safe Open/Repair" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Safe Open/Repair")}
                        >
                            Safe Open/Repair
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Access Control" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Access Control")}
                        >
                            Access Control
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Surveillance" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Surveillance")}
                        >
                            Surveillance
                        </Link>
                        <Link
                            to={paths.OTHERJOB}
                            type="button"
                            className={`btn btn-outline-primary ms-3 ${selectedJob === "Other" ? "active" : ""}`}
                            onClick={() => handleLinkClick("Other")}
                        >
                            Other
                        </Link>
                    </div>
                </div>
                <div className="row mt-5 justify-content-center">
                    <div className="col-sm-4 text-center">
                        {selectedAutoJob === true ? (
                            <span className="">For all automotive services, please navigate to our homepage.</span>
                        ) : noJob === true ? (
                            <span className="">Please make your service selection from the choices provided above.</span>
                        ) : (
                            <button
                                type="button"
                                className={`btn btn-outline-primary`}
                                onClick={() => handleSubmitClick()}
                            >
                                Create Job
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default OtherJobSelectComponent;