import axios from "axios";
import { useDispatch } from "react-redux";
import CreateMakePageComponent from "./components/adminNewMakeComponent";

const createMakeApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/vehicleMake/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};

const AdminCreateMakePage = () => {
    const dispatch = useDispatch();

    return (
        <CreateMakePageComponent
            createMakeApiRequest={createMakeApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreateMakePage;