import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import '../assets/payment.css';
import { FaRegThumbsUp } from 'react-icons/fa';

const PaymentForm = ({ jobItems, estimateId, setLoading, companyId, totalPaid, onCancel, companies, dispatching }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentError, setPaymentError] = useState(null);
    const payment = totalPaid;
    const stripeId = companies.stripeAccountId;
    const jobId = dispatching._id;
    const [paymentType, setPaymentType] = useState('Credit Charge');
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertError, setAlertError] = useState(null);
    const [alertPaymentError, setAlertPaymentError] = useState(null);
    const [amountInput, setAmountInput] = useState(null);
    const [cardNameInput, setCardNameInput] = useState(null);
    const [zipInput, setZipInput] = useState(null);
    const [paymentDate, setPaymentDate] = useState('');
    const [otherPaymentCon, setOtherPaymentCon] = useState('');

    const roundToTwoDecimalPlaces = (value) => {
        return Math.round(value * 100);
    };

    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (paymentType === 'Credit Charge') {
                if (!stripe || !elements) {
                    throw new Error('Stripe.js has not loaded yet.');
                }

                const cardElement = elements.getElement(CardNumberElement);

                if (!cardElement) {
                    throw new Error('CardNumberElement not found.');
                }

                const amountInputValue = amountInput;
                const cardNameInputValue = cardNameInput.value;
                const zipInputValue = zipInput.value;
                const cardNumberElement = elements.getElement(CardNumberElement);
                const cardExpiryElement = elements.getElement(CardExpiryElement);
                const cardCvcElement = elements.getElement(CardCvcElement);

                console.log(amountInputValue)
                if (
                    !amountInputValue ||
                    !cardNameInputValue ||
                    !zipInputValue ||
                    !cardNumberElement ||
                    !cardExpiryElement ||
                    !cardCvcElement
                ) {
                    throw new Error('Please fill out all payment fields and ensure card details are valid.');
                }

                const { paymentMethod, error } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                    billing_details: {
                        name: cardNameInput.value,
                        address: {
                            postal_code: zipInput.value,
                        },
                    },
                });

                if (error) {
                    setPaymentError(error.message);
                    setShowAlertError(true);
                    setTimeout(() => {
                        setShowAlertError(false);
                    }, 3000);
                } else {
                    const response = await axios.post('/api/payment/process-payment', {
                        paymentMethodId: paymentMethod.id,
                        amount: roundToTwoDecimalPlaces(amountInputValue),
                        currency: 'usd',
                        companyId,
                        stripeId,
                        jobId,
                        cost: jobItems.toFixed(2),
                        estimateId,
                        type: paymentType,
                        status: 'Success',
                    });

                    if (response.data.success) {
                        setLoading(true);
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    } else {
                        setAlertError(response.data.error);
                        setShowAlertError(true);
                        setTimeout(() => {
                            setShowAlertError(false);
                        }, 3000);
                    }
                    onCancel();

                }
            } else if (paymentType === 'Cash') {
                const amountInputValue = amountInput;

                const response = await axios.post('/api/payment/process-payment', {
                    paymentMethodId: '',
                    amount: roundToTwoDecimalPlaces(amountInputValue),
                    currency: 'usd',
                    companyId,
                    stripeId,
                    jobId,
                    cost: jobItems.toFixed(2),
                    otherPaidOn: paymentDate || '',
                    estimateId,
                    type: paymentType,
                    status: 'Success',
                });

                if (response.data.success) {
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                    setLoading(true);
                } else {
                    setAlertError(response.data.error);
                    setShowAlertError(true);
                    setTimeout(() => {
                        setShowAlertError(false);
                    }, 3000);
                }
                onCancel();
            } else if (paymentType === 'Credit Offline' || paymentType === 'Check' || paymentType === 'bankTransfer' || paymentType === 'CashApp' || paymentType === 'ConsumerFin' || paymentType === 'Zelle' || paymentType === 'CashApp') {
                const amountInputValue = amountInput;

                const response = await axios.post('/api/payment/process-payment', {
                    paymentMethodId: '',
                    amount: roundToTwoDecimalPlaces(amountInputValue),
                    currency: 'usd',
                    companyId,
                    stripeId,
                    cost: jobItems.toFixed(2),
                    jobId,
                    otherPaidOn: paymentDate || '',
                    estimateId,
                    otherPaymentCon,
                    type: paymentType,
                    status: 'Success',
                });

                if (response.data.success) {
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                    setLoading(true);
                } else {
                    setAlertError(response.data.error);
                    setShowAlertError(true);
                    setTimeout(() => {
                        setShowAlertError(false);
                    }, 3000);
                }
                onCancel();
            }
        } catch (error) {
            setAlertPaymentError(error.message);
            setShowAlertError(true);
            setTimeout(() => {
                setShowAlertError(false);
            }, 3000);
        }
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    const handleCloseAlertError = () => {
        setShowAlertError(false);
    };

    useEffect(() => {
        const today = new Date();
        const options = { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = today.toLocaleDateString('en-US', options);
        setPaymentDate(formattedDate);

        const handleAmountChange1 = () => {
            let payment1 = payment;

            payment1 = isNaN(payment1) ? 0.00 : payment1;

            payment1 = payment1 < 0 ? 0.00 : payment1;

            setAmountInput(payment1);
        };
        handleAmountChange1();
    }, [payment]);

    const handleAmountChange = (event) => {
        let payment1 = event.target.value; 

        setAmountInput(payment1);
    };

    return (
        <>
            <div
                className="background-Confirmation1"
                onClick={onCancel} 
            />
            <div className="confirmation-box-item1">
                {showAlert && (
                    <div className="alert alert-success" role="alert">
                        <FaRegThumbsUp className="me-3 mb-2" />
                        Payment successful
                        <div className="alert-line-container">
                            <div className="alert-line"></div>
                            <span className="alert-close" onClick={handleCloseAlert}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                {showAlertError && (
                    <div className="alert1 alert-danger" role="alert">
                        {alertError} {alertPaymentError} {paymentError}
                        <div className="alert-line-container">
                            <div className="alert-lineRed"></div>
                            <span className="alert-closeRed" onClick={handleCloseAlertError}>
                                X
                            </span>
                        </div>
                    </div>
                )}
                <div className="alertWhite alert-light text-center" role="alert">
                    <div className="row justify-content-center">
                        <div className="row fw-bolder bg-light deleteWidth1 p-3 mb-4">
                            <div className="col-sm-12 fs-5">
                                Add Payment
                            </div>
                        </div>
                        <form className="card-form">
                            <div className="row text-start"> 
                                <div className="col m-0 me-2 p-0">
                                    <label htmlFor="amount">Amount</label>
                                    <input
                                        placeholder="Amount"
                                        onChange={handleAmountChange}
                                        value={amountInput}
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col m-0 me-2 p-0">
                                    <label htmlFor="payment-type">Payment Type:</label>
                                    <select
                                        id="payment-type"
                                        name="payment-type"
                                        className="card-input"
                                        value={paymentType}
                                        onChange={handlePaymentTypeChange}
                                    >
                                        <option value="Credit Charge">Credit Charge</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Credit Offline">Credit Offline</option>
                                        <option value="Check">Check</option>
                                        <option value="bankTransfer">Bank Transfer (Offline)</option>
                                        <option value="CashApp">Cash App</option>
                                        <option value="ConsumerFin">Customer Financing</option>
                                        <option value="Venmo">Venmo</option>
                                        <option value="Zelle">Zelle</option>
                                    </select>
                                </div>
                            </div>
                            {paymentType === 'Cash' ? (
                                <div className="form-floating text-start mt-3">
                                    <input
                                        type="text"
                                        id="payment-date"
                                        placeholder="Fri, January 5th, 2024"
                                        name="paymentDate"
                                        className="form-control"
                                        defaultValue={paymentDate}
                                        onChange={(e) => setPaymentDate(e.target.value)}
                                    />
                                    <label htmlFor="payment-date">Paid On:</label>
                                </div>
                            ) : (paymentType === 'Credit Offline' || paymentType === 'Check' || paymentType === 'bankTransfer' || paymentType === 'Zelle' || paymentType === 'CashApp' || paymentType === 'ConsumerFin' || paymentType === 'Venmo') ? (
                                <>
                                    <div className="form-floating mt-3">
                                        <input type="text"
                                            onChange={(e) => setOtherPaymentCon(e.target.value)}
                                            value={otherPaymentCon}
                                            className="form-control"
                                            id="floatingInputValue"
                                            placeholder="Confirmation Code" />
                                        <label for="floatingInputValue">Confirmation Code</label>
                                    </div>
                                    <div className="form-floating text-start mt-3">
                                        <input
                                            type="text"
                                            id="payment-date"
                                            placeholder="Fri, January 5th, 2024"
                                            name="paymentDate"
                                            className="form-control"
                                            defaultValue={paymentDate}
                                            onChange={(e) => setPaymentDate(e.target.value)}
                                        />
                                        <label htmlFor="payment-date">Paid On:</label>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="form-row text-start">
                                        <label htmlFor="card-name">Name on card</label>
                                        <input placeholder="Name on card" ref={(input) => setCardNameInput(input)} type="text" id="card-name" name="card-name" className="card-input" />
                                    </div>
                                    <div className="form-row text-start">
                                        <label htmlFor="card-number">Card number</label>
                                        <CardNumberElement id="card-number" className="card-input" />
                                    </div>
                                    <div className="row mb-4 text-start">
                                        <div className="col m-0 me-2 p-0">
                                            <label htmlFor="card-expiry">Expiration date</label>
                                            <CardExpiryElement id="card-expiry" className="card-input" />
                                        </div>
                                        <div className="col m-0 me-2 p-0">
                                            <label htmlFor="card-cvc">CVV</label>
                                            <CardCvcElement id="card-cvc" className="card-input" />
                                        </div>
                                        <div className="col m-0 p-0 me-2">
                                            <label htmlFor="zip">Zip Code</label>
                                            <input placeholder="Zip Code" ref={(input) => setZipInput(input)} type="text" id="zip" name="zip" className="card-input" />
                                        </div>
                                    </div>
                                </>
                            )}
                        </form>
                        <div className="row deleteWidth1 bg-light justify-content-center mt-5 border-top pt-3 pb-3 border-dark">
                            <div className="col-sm">
                                <button onClick={handleSubmit} className="btn btn-outline-success m-0 ps-5 pe-5">
                                    Submit
                                </button>
                            </div>
                            <div className="col-sm">
                                <button className="btn btn-outline-primary ps-5 pe-5" onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentForm;