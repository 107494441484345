import axios from "axios";
import { useDispatch } from "react-redux";
import CreateYearPageComponent from "./components/adminNewYearComponent";

const createYearApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/vehicleYear/admin`, { ...formInputs }, { withCredentials: true });
    return data;
};
const fetchMake = async (abctrl) => {
    const { data } = await axios.get("/api/vehicleMake/", { withCredentials: true })
    return data;
}
const fetchModel = async (make) => {
    const { data } = await axios.get(`/api/vehicleModel?make=${make}`, { withCredentials: true })
    return data;
}
const fetchYear = async (model) => {
    const { data } = await axios.get(`/api/vehicleYear?model=${model}`, { withCredentials: true });
    return data
}
const AdminCreateYearPage = () => {
    const dispatch = useDispatch();

    return (
        <CreateYearPageComponent
            fetchYear={fetchYear }
            fetchModel={fetchModel}
            fetchMake={fetchMake}
            createYearApiRequest={createYearApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreateYearPage;